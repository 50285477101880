import React, { useState, useEffect } from "react";
import { isValidInvestmentNumber } from "../../../Functions/functions.js";
import { formatInvestmentAmount } from "../../../GlobalFunctions/globalFunctions.js";
import "./InvestmentAmountFieldStyles/InvestmentAmountFieldStyles.css";

function InvestmentAmountField({
  placeholder,
  error,
  fieldValueChange,
  initialValue,
  disabled,
  maxInput
}) {
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [focus, setFocus] = useState(false);
  //const [error, setError] = useState(false);
  const [divClass, setDivClass] = useState("input-box-container");
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (!error) {
      setDivClass(focus ? "input-box-containerFocus" : "input-box-container");
    } else {
      setDivClass("input-box-containerError");
    }
  }, [focus, error]);

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    fieldValueChange(fieldValue);
  }, [fieldValue, fieldValueChange]);

  return (
    <div
      className={divClass}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="input-box">
        <input
          className={
            error === "This field is required."
              ? "internal-input-error-empty"
              : "internal-input"
          }
          placeholder={
            error === "This field is required." ? "Required" : inputPlaceholder
          }
          value={initialValue}
          onChange={(event) => {
            // if (isValidInvestmentNumber(event.target.value)) {
            
            if (event.target.value.replace(/[^\d]/g,"") <= maxInput)
            setFieldValue(formatInvestmentAmount(event.target.value));
            // }
          }}
          disabled={disabled}
        ></input>
      </div>
    </div>
  );
}
export default InvestmentAmountField;
