import React from "react";
import Header from "../../../Components/GlobalComponents/Header.js";

function ProfileHeader() {
  return (
    <React.Fragment>
      <div className="col-12 mt-5 title-text mb-4">My Profile</div>
      {/* <div className="col-12 mt-3 mb-4 subtitle-text">
        Feel free to edit your information
      </div> */}
    </React.Fragment>
  );
}

export default ProfileHeader;
