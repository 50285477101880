import React from "react";
import SearchBar from "../../../../Components/GlobalComponents/SearchBar.js";
import "./FilterRowStyles/FilterRowStyles.css";
import MinInvestmentDropdown from "../../../../Components/GlobalComponents/MinInvestmentDropdown/MinInvestmentDropdown.js";
import StageFilter from "../../../../Components/GlobalComponents/StageFilter/StageFilter.js";
import StrategyFilter from "../../../../Components/GlobalComponents/StrategyFilter/StrategyFilter.js";
function FilterRow({
  setQuery,
  minInvestment,
  setMinInvestment,
  defaultMinInvestment,
  strategyType,
  setStrategyType,
  defaultStrategyType,
  status,
  setStatus,
  defaultStatus,
}) {
  return (
    <div className="row gx-3 pb-3 mb-3 mt-2 ps-3 pe-3">
      <div className="col-xl-6 col-12 align-items-center mb-xl-0 mb-3">
        <div className="row g-0">
          <div className="col-12">
            <SearchBar
              placeholder="Search Fund Opportunities"
              setValue={setQuery}
            />
          </div>
        </div>
      </div>
      <div className="col-xxl-2 col-xl-2 col-lg-4 col-sm-4 col-6 align-items-center justify-content-center">
        <StageFilter
          text={status}
          setText={setStatus}
          defaultKey={defaultStatus}
        />
      </div>
      <div className="col-xxl-2 col-xl-2 col-lg-4 col-sm-4 col-6 align-items-center justify-content-center">
        <MinInvestmentDropdown
          text={minInvestment}
          setText={setMinInvestment}
          defaultKey={defaultMinInvestment}
        />
      </div>
      <div className="col-xxl-2 col-xl-2 col-lg-4 col-sm-4 col-12 align-items-center justify-content-center mt-sm-0 mt-3">
        <StrategyFilter
          text={strategyType}
          setText={setStrategyType}
          defaultKey={defaultStrategyType}
        />
      </div>
    </div>
  );
}
export default FilterRow;
