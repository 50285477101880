import React, { useRef, useState } from "react";
import "./DropdownContentStyles/DropdownContentStyles.scss";

function DropdownContent({ dropdownPreview, dropdownDetailedContent }) {
  const [selected, setSelected] = useState(false);
  const arrowRef = useRef(null);

  const handleSetState = (state) => {
    setSelected(state);
  };

  const rotate = () => {
    if (
      arrowRef !== null &&
      arrowRef !== undefined &&
      arrowRef.current !== null &&
      arrowRef.current !== undefined &&
      arrowRef.current.style !== null &&
      arrowRef.current.style !== undefined
    ) {
      if (!selected) {
        arrowRef.current.style.WebkitTransform = "rotate(" + 180 + "deg)";
        handleSetState(true);
      } else {
        arrowRef.current.style.WebkitTransform = "rotate(" + 90 + "deg)";
        handleSetState(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center mb-2">
        <div ref={arrowRef} className="custom-triangle" onClick={rotate} />
        <span className="preview-font ms-2" onClick={rotate}>
          {dropdownPreview}
        </span>
      </div>
      {selected && (
        <div
          style={{ paddingLeft: "19px" }}
          dangerouslySetInnerHTML={{
            __html: dropdownDetailedContent ? (
              dropdownDetailedContent
            ) : (
              <p>Loading...</p>
            ),
          }}
        ></div>
      )}
    </React.Fragment>
  );
}
export default DropdownContent;
