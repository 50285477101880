import React, { useState } from "react";
import InfiniteTabInput from "../../../../../../../../Components/GlobalComponents/InfiniteTabInput/InfiniteTabInput";
import InfiniteInputComponent from "../../../../../AddInvestmentAccount/InfiniteInputComponent/InfiniteInputComponent";
import { countryList } from "../../../../../../../../Components/countryList";
import { stateList } from "../../../../../../../../Components/GlobalComponents/stateList";
import NoRelatedParties from "../../../../NoRelatedParties/NoRelatedParties";

function BeneficialOwners({
  beneficialOwnerRelatedAccounts,
  setBeneficialOwnerRelatedAccounts,
  accountID,
  accountType,
}) {
  const [activeTab, setActiveTab] = useState("Beneficial Owner #1");
  const [activeSlide, setActiveSlide] = useState(0);
  const [hideButton, setHideButton] = useState(false);

  const handleRemoveFields = () => {
    return;
  };

  return (
    <>
      {beneficialOwnerRelatedAccounts.length !== 0 ? (
        <div className="row g-0 w-100 mb-2">
          <InfiniteTabInput
            tabs={beneficialOwnerRelatedAccounts.map(
              (elem, idx) => "Beneficial Owner #" + (idx + 1).toString()
            )}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <InfiniteInputComponent
            accountType={accountType}
            accountID={accountID}
            activeTab={activeTab}
            processedCountryList={countryList}
            processedStateList={stateList}
            inputFields={beneficialOwnerRelatedAccounts}
            setInputFields={setBeneficialOwnerRelatedAccounts}
            handleRemoveFields={handleRemoveFields}
            index={activeTab.match(/\d+/) - 1}
            setActiveTab={setActiveTab}
            setActiveSlide={setActiveSlide}
            disabled={true}
            setHideButton={setHideButton}
            role={"Beneficial Owner"}
            relatedPartyType="BO"
          />
        </div>
      ) : (
        <NoRelatedParties role={"Beneficial Owner"} />
      )}
    </>
  );
}
export default BeneficialOwners;
