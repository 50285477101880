import React from "react";
import axios from "axios";
import { HOST } from "../host-config";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";

export const fetchCapitalCalls = async () => {
  const requestParams = {"fund":[],"status":"","client":[]};
  const config = await getRequestHeaders();
  return axios.post(HOST + "/get-capital-calls", requestParams, config);
};
