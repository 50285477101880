import React, { useEffect, useState, useContext } from "react";
import {
  formatSSN,
  isValidCountry,
} from "../../../../../GlobalFunctions/globalFunctions";
import StyledTextInputField from "../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import StyledPhoneInputField from "../../../../../GlobalComponents/InputField/StyledPhoneInputField/StyledPhoneInputField";
import PhoneInputField from "../../../../../GlobalComponents/InputFields/PhoneInputField/PhoneInputField.js";
import { isDobValid } from "../../../../../Functions/functions.js";
import { isEmailValid } from "../../../../../Functions/functions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { UserContext } from "../../../../../GlobalContexts/UserContext";
import { updateRelatedPartyInformation } from "../../../../../Api/ProfileApi";
import uuid from "react-uuid";
import SelectField from "../../../../../GlobalComponents/InputField/SelectField/SelectField";
import StyledDateInputField from "../../../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import "./InfiniteInputComponentStyles/InfiniteInputComponentStyles.scss";
import { stateList } from "../../../../../Components/GlobalComponents/stateList";
import { countryList } from "../../../../../Components/countryList";
import { reducedCountryList } from "../../../../../Components/reducedCountryList";
import { newAccount } from "../../../../../Api/ProfileApi";
import EntityInputInformation from "./EntityInputInformation/EntityInputInformation.js";

function InfiniteInputComponent({
  accountID,
  inputFields,
  setInputFields,
  index,
  setActiveTab,
  processedCountryList,
  processedStateList,
  handleRemoveFields,
  setActiveSlide,
  role,
  disabled,
  supplyPercentOwned,
  hideButton,
  setHideButton,
  hideAlterButtons,
  setHideAlterButtons,
  relatedPartyType = "BO",
  accountType = "",
}) {
  const [trustees, setTrustees] = useState("");

  const { user, setUser } = useContext(UserContext);

  const inputField = inputFields[index];

  const handleChangeInput = (index, value, name) => {
    const values = [...inputFields];
    values[index][name] = value;
    setInputFields(values);
  };

  const handlePhoneChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index]["phoneNumber"] = event;
    values[index]["phoneNumberError"] = "";
    setInputFields(values);
  };

  useEffect(() => {
    if (inputFields.length === 0) {
      setHideButton(true);
    } else {
      setHideButton(false);
    }
  }, [inputFields]);

  const relatedPartyTypeOptions = [
    { value: "Person", label: "Person" },
    { value: "Entity", label: "Entity" },
  ];

  return (
    <div className="container">
      <div className="row g-0 w-100">
        <div className="col-12">
          <form>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-2" key={index}>
                  <div className="col-12">
                    <div className="row g-0 w-100 mt-3">
                      <div className="col pe-2">
                        <SelectField
                          value={inputField.relatedPartyType}
                          setValue={(event) => {
                            handleChangeInput(index, event, "relatedPartyType");
                            inputField.relatedPartyTypeError = "";
                          }}
                          valueError={inputField.relatedPartyTypeError}
                          setValueError={(e) => {
                            inputField.relatedPartyTypeError = e;
                          }}
                          selectOptions={relatedPartyTypeOptions}
                          required={true}
                          fullWidth={true}
                          size={"small"}
                          disabled={disabled}
                          shrink={true}
                          id={"related-party-type-select"}
                          label={"Related Party Type"}
                        />
                      </div>
                    </div>
                    {inputField.relatedPartyType === "Entity" && (
                      <EntityInputInformation
                        inputField={inputField}
                        handleChangeInput={handleChangeInput}
                        index={index}
                        disabled={disabled}
                        relatedPartyType={relatedPartyType}
                        accountType={accountType}
                      />
                    )}
                    {inputField.relatedPartyType === "Person" && (
                      <React.Fragment>
                        <div className="row g-0 w-100 mt-4 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <StyledTextInputField
                                value={inputField.firstName}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "firstName")
                                }
                                setValueError={(e) => {
                                  inputField.firstNameError = e;
                                }}
                                valueError={inputField.firstNameError}
                                label={"First Name"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              <StyledTextInputField
                                value={inputField.lastName}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "lastName")
                                }
                                setValueError={(e) => {
                                  inputField.lastNameError = e;
                                }}
                                valueError={inputField.lastNameError}
                                label={"Last Name"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <StyledTextInputField
                                value={inputField.email}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "email")
                                }
                                setValueError={(e) => {
                                  inputField.emailError = e;
                                }}
                                valueError={inputField.emailError}
                                label={"Email"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              <PhoneInputField
                                value={inputField.phoneNumber}
                                setValue={(event) => {
                                  handleChangeInput(
                                    index,
                                    event,
                                    "phoneNumber"
                                  );
                                  inputField.phoneNumberError = "";
                                }}
                                setValueError={(e) => {
                                  inputField.phoneNumberError = e;
                                }}
                                valueError={inputField.phoneNumberError}
                                label={"Phone Number"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                              {inputField.phoneNumberError &&
                                inputField.phoneNumberError !==
                                  "This field is required." && (
                                  <div className="SignupForm-field-error-text mt-1">
                                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                                      {inputField.phoneNumberError}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <div className="row g-0 w-100">
                              <div className="col-12">
                                <div className="align-self-end mt-1">
                                  <SelectField
                                    value={inputField.domicileCountry}
                                    setValue={(event) => {
                                      handleChangeInput(
                                        index,
                                        event,
                                        "domicileCountry"
                                      );
                                      inputField.domicileCountryError = "";
                                    }}
                                    valueError={inputField.domicileCountryError}
                                    setValueError={(e) => {
                                      inputField.domicileCountryError = e;
                                    }}
                                    selectOptions={countryList}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"formation-country-select"}
                                    label={"Domicile Country"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <span className="infinite-input-component-section-header">
                              Address
                            </span>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <div className="row g-0 w-100">
                              <div className="col-12">
                                <div className="align-self-end mt-1">
                                  <StyledTextInputField
                                    value={inputField.addressLine1}
                                    setValue={(event) =>
                                      handleChangeInput(
                                        index,
                                        event,
                                        "addressLine1"
                                      )
                                    }
                                    setValueError={(e) => {
                                      inputField.addressLine1Error = e;
                                    }}
                                    valueError={inputField.addressLine1Error}
                                    label={"Address Line 1"}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"account-name-input"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <div className="row g-0 w-100">
                              <div className="col-12">
                                <StyledTextInputField
                                  value={inputField.addressLine2}
                                  setValue={(event) =>
                                    handleChangeInput(
                                      index,
                                      event,
                                      "addressLine2"
                                    )
                                  }
                                  setValueError={(e) => {
                                    inputField.addressLine2Error = e;
                                  }}
                                  valueError={inputField.addressLine2Error}
                                  label={"Address Line 2"}
                                  required={true}
                                  fullWidth={true}
                                  size={"small"}
                                  disabled={disabled}
                                  shrink={true}
                                  id={"account-name-input"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div
                            className={
                              inputField.country === "United States"
                                ? "col pe-2"
                                : "col pe-0"
                            }
                          >
                            <div className="align-self-end mt-1">
                              <StyledTextInputField
                                value={inputField.city}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "city")
                                }
                                setValueError={(e) => {
                                  inputField.cityError = e;
                                }}
                                valueError={inputField.cityError}
                                label={"City"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                              {inputField.cityError &&
                                inputField.cityError !==
                                  "This field is required." && (
                                  <div className="SignupForm-field-error-text mt-1">
                                    <span className="">
                                      {inputField.cityError}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          {inputField.country === "United States" && (
                            <div className="col ps-2 pe-0">
                              <div className="align-self-end mt-1">
                                <SelectField
                                  value={inputField.state}
                                  setValue={(event) => {
                                    handleChangeInput(index, event, "state");
                                    inputField.stateError = "";
                                  }}
                                  valueError={inputField.stateError}
                                  setValueError={(e) => {
                                    inputField.stateError = e;
                                  }}
                                  selectOptions={stateList}
                                  required={true}
                                  fullWidth={true}
                                  size={"small"}
                                  disabled={disabled}
                                  shrink={true}
                                  id={"formation-country-select"}
                                  label={" State"}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <SelectField
                                value={inputField.country}
                                setValue={(event) => {
                                  handleChangeInput(index, event, "country");
                                  inputField.countryError = "";
                                }}
                                valueError={inputField.countryError}
                                setValueError={(e) => {
                                  inputField.countryError = e;
                                }}
                                selectOptions={countryList}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"formation-country-select"}
                                label={"Country"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              <StyledTextInputField
                                value={inputField.postalCode}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "postalCode")
                                }
                                setValueError={(e) => {
                                  inputField.postalCodeError = e;
                                }}
                                valueError={inputField.postalCodeError}
                                label={"Postal Code"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"postal-code-name-input"}
                                mask={{
                                  maskPattern: "999999999999",
                                  maskFormatter: (e) => e,
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <span className="infinite-input-component-section-header">
                              Personal Information
                            </span>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <StyledDateInputField
                                value={inputField.dateOfBirth}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "dateOfBirth")
                                }
                                valueError={inputField.dateOfBirthError}
                                setValueError={(e) => {
                                  inputField.dateOfBirthError = e;
                                }}
                                label={"Date Of Birth"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"individual-account-dob-input"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              {inputField.domicileCountry ===
                              "United States" ? (
                                <React.Fragment>
                                  <StyledTextInputField
                                    value={inputField.idNum}
                                    setValue={(event) => {
                                      handleChangeInput(index, event, "idNum");
                                      inputField.idNumError = "";
                                    }}
                                    setValueError={(e) => {
                                      inputField.idNumError = e;
                                    }}
                                    valueError={inputField.idNumError}
                                    label={"SSN"}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"account-name-input"}
                                    mask={{
                                      maskFormatter: formatSSN,
                                      maskPattern: "999-99-9999",
                                    }}
                                  />
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <StyledTextInputField
                                    value={inputField.idNum}
                                    setValue={(event) => {
                                      handleChangeInput(index, event, "idNum");
                                      inputField.idNumError = "";
                                    }}
                                    setValueError={(e) => {
                                      inputField.idNumError = e;
                                    }}
                                    valueError={inputField.idNumError}
                                    label={"Passport Number"}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"account-name-input"}
                                  />
                                  {inputField.idNumError &&
                                    inputField.idNumError !==
                                      "This field is required." && (
                                      <div className="SignupForm-field-error-text mt-1">
                                        <span className="">
                                          {inputField.idNumError}
                                        </span>
                                      </div>
                                    )}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <span className="infinite-input-component-section-header">
                              Additional Info
                            </span>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <StyledTextInputField
                                value={inputField.percentOwned}
                                setValue={(event) =>
                                  handleChangeInput(
                                    index,
                                    event,
                                    "percentOwned"
                                  )
                                }
                                setValueError={(e) => {
                                  inputField.percentOwnedError = e;
                                }}
                                valueError={inputField.percentOwnedError}
                                label={"Percent Owned"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={supplyPercentOwned ? false : disabled}
                                shrink={true}
                                id={"postal-code-name-input"}
                                mask={{
                                  maskPattern: "999",
                                  maskFormatter: (e) =>
                                    parseInt(e) <= 100 ? e : e.slice(0, 2),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <SelectField
                                value={inputField.citizenship}
                                setValue={(event) => {
                                  handleChangeInput(
                                    index,
                                    event,
                                    "citizenship"
                                  );
                                  inputField.citizenshipError = "";
                                }}
                                valueError={inputField.citizenshipError}
                                setValueError={(e) => {
                                  inputField.citizenshipError = e;
                                }}
                                selectOptions={reducedCountryList}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={supplyPercentOwned ? false : disabled}
                                shrink={true}
                                id={"formation-country-select"}
                                label={"Citizenship"}
                              />
                            </div>
                          </div>
                        </div>

                        {!hideAlterButtons &&
                          inputFields.length !== 1 &&
                          !disabled && (
                            <div className="row g-0 w-100 mb-3">
                              <div className="d-flex">
                                <button
                                  type="button"
                                  class="btn btn-lg btn-class"
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  <div className="row g-0 w-100">
                                    <div className="col d-flex align-items-center">
                                      <span className="justify-self-start align-self-center">
                                        Remove this person
                                      </span>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default InfiniteInputComponent;
