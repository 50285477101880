import React from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../../../../Components/countryList";
import { stateList } from "../../../../../../../../Components/GlobalComponents/stateList";

function IndividualAccountDetailsAddress({
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="individual-account-section-header-text">
          Mailing Address
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={addressLine1}
            setValue={setAddressLine1}
            valueError={addressLine1Error}
            setValueError={setAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"individual-account-details-address-line-1-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={addressLine2}
            setValue={setAddressLine2}
            valueError={addressLine2Error}
            setValueError={setAddressLine2Error}
            label={"Address Line 2"}
            required={false}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"individual-account-details-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className={country === "United States" ? "col pe-2" : "col pe-0"}>
          <StyledTextInputField
            value={city}
            setValue={setCity}
            valueError={cityError}
            setValueError={setCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"individual-account-details-city-input"}
          />
        </div>
        {country === "United States" && (
          <div className="col pe-0 ps-2">
            <SelectField
              value={state}
              setValue={setState}
              valueError={stateError}
              setValueError={setStateError}
              selectOptions={stateList}
              label={"State"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={true}
              shrink={true}
              id={"individual-account-details-state-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={country}
            setValue={setCountry}
            valueError={countryError}
            setValueError={setCountryError}
            label={"Country"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"individual-account-details-country-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={postalCode}
            setValue={setPostalCode}
            valueError={postalCodeError}
            setValueError={setPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"individual-account-details-postal-code-input"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default IndividualAccountDetailsAddress;
