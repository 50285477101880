import React from "react";
import TaxNumber from "./TaxNumber/TaxNumber";
import MailingAddress from "./MailingAddress/MailingAddress";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions";

function TrustAccountDetailsTaxInfo({
  countryOfFormation,
  trustType,
  idType,
  setIDType,
  idTypeError,
  setIDTypeError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          {trustType === "TRUST" && (
            <div className="row g-0 w-100 mb-4">
              <span className="trust-account-section-header-text">
                Tax Information
              </span>
            </div>
          )}
          {trustType === "TRUST" && (
            <TaxNumber
              countryOfFormation={countryOfFormation}
              idType={idType}
              setIDType={setIDType}
              idTypeError={idTypeError}
              setIDTypeError={setIDTypeError}
              idNumber={idNumber}
              setIDNumber={setIDNumber}
              idNumberError={idNumberError}
              setIDNumberError={setIDNumberError}
            />
          )}
          <MailingAddress
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine1Error={addressLine1Error}
            setAddressLine1Error={setAddressLine1Error}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine2Error={addressLine2Error}
            setAddressLine2Error={setAddressLine2Error}
            city={city}
            setCity={setCity}
            cityError={cityError}
            setCityError={setCityError}
            state={state}
            setState={setState}
            stateError={stateError}
            setStateError={setStateError}
            country={country}
            setCountry={setCountry}
            countryError={countryError}
            setCountryError={setCountryError}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
          />
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default TrustAccountDetailsTaxInfo;
