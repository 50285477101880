import React, { useEffect } from "react";
import BeneficialOwnerQuestion from "./BeneficialOwnerQuestion/BeneficialOwnerQuestion";
import BeneficialOwnerInfiniteInput from "./BeneficialOwnerInfiniteInput/BeneficialOwnerInfiniteInput";

function BeneficialOwnerSection({
  accountType,
  answer,
  setAnswer,
  hasBeneficialOwnersError,
  mountedStyle,
  unmountedStyle,
  showBeneficialOwnerSection,
  shouldRenderBeneficialOwnerSection,
  activeTab,
  setActiveTab,
  addNewTab,
  accountID,
  relatedAccounts,
  setRelatedAccounts,
  handleRemoveFields,
  setActiveSlide,
  hideButton,
  setHideButton,
  hideAlterButtons,
  setHideAlterButtons,
  supplyPercentOwned,
}) {
  useEffect(() => supplyPercentOwned && setAnswer(true), [supplyPercentOwned]);
  return (
    <React.Fragment>
      {!supplyPercentOwned && (
        <BeneficialOwnerQuestion
          questionNumber={1}
          questionText={
            "Excluding yourself, does this entity have any beneficial owners that own 25% or more?"
          }
          answer={answer}
          setAnswer={setAnswer}
          hasBeneficialOwnersError={hasBeneficialOwnersError}
        />
      )}
      <BeneficialOwnerInfiniteInput
        accountType={accountType}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        showBeneficialOwnerSection={showBeneficialOwnerSection}
        shouldRenderBeneficialOwnerSection={shouldRenderBeneficialOwnerSection}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        addNewTab={addNewTab}
        accountID={accountID}
        relatedAccounts={relatedAccounts}
        setRelatedAccounts={setRelatedAccounts}
        handleRemoveFields={handleRemoveFields}
        setActiveSlide={setActiveSlide}
        hideButton={hideButton}
        setHideButton={setHideButton}
        hideAlterButtons={hideAlterButtons}
        setHideAlterButtons={setHideAlterButtons}
        supplyPercentOwned={supplyPercentOwned}
      />
    </React.Fragment>
  );
}
export default BeneficialOwnerSection;
