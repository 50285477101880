import React, { useState, useEffect } from "react";
import "./InputField.css";
function InputField({
  placeholder,
  error,
  fieldValueChange,
  fieldInitialVal = "",
  disabled = false,
}) {
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [focus, setFocus] = useState(false);
  //const [error, setError] = useState(false);
  const [divClass, setDivClass] = useState("input-box-container");
  const [fieldValue, setFieldValue] = useState(fieldInitialVal);

  useEffect(() => {
    if (!error) {
      setDivClass(focus ? "input-box-containerFocus" : "input-box-container");
    } else {
      setDivClass("input-box-containerError");
    }
  }, [focus, error]);

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    fieldValueChange(fieldValue);
  }, [fieldValue, fieldValueChange]);
  return (
    <div
      className={divClass}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="input-box">
        <input
          className="internal-input"
          placeholder={inputPlaceholder}
          value={fieldValue}
          onChange={(event) => setFieldValue(event.target.value)}
          disabled={disabled}
        ></input>
      </div>
    </div>
  );
}
export default InputField;
