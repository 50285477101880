import { Auth } from "aws-amplify";

/**
 *
 */
export const getRequestHeaders = async () => {
  const config = {
    headers: {},
  };

  const accessToken = await Auth.currentSession().then(
    (response) => response.accessToken.jwtToken
  );
  if (accessToken !== undefined && accessToken !== null) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
};
