import axios from "axios";
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "./GlobalFunctions/RequestHeaders.js";

export const createUpdateInvestorFundInterest = async (fundId, interested) => {
  const config = await getRequestHeaders();

  return axios.post(
    HOST + "/create-update-investor-fund-interest/",
    {
      fund_id: fundId,
      interested: interested,
    },
    config
  );
};

export const isFeederAPI = async () => {
  //const config = await getRequestHeaders();
  return axios.get(HOST + "/is-feeder");
};
