import React, { useState, useEffect } from "react";
import "./AccountTypeSectionStyles/AccountTypeSectionStyles.scss";
import { useHistory } from "react-router-dom";
import StateBasedButton from "../../../../GlobalComponents/Button/StateBasedButton/StateBasedButton";

function AccountTypeSection({
  navigation,
  accountTypeIndividual,
  accountTypeTrust,
  accountTypeEntity,
  accountTypeJoint,
  setAccountTypeJoint,
  accountTypeRetirement,
  setAccountTypeIndividual,
  setAccountTypeTrust,
  setAccountTypeEntity,
  setAccountTypeRetirement,
}) {
  const [accountTypeIndividualFocus, setAccountTypeIndividualFocus] =
    useState(false);
  const [accountTypeTrustFocus, setAccountTypeTrustFocus] = useState(false);
  const [accountTypeEntityFocus, setAccountTypeEntityFocus] = useState(false);
  const [accountTypeRetirementFocus, setAccountTypeRetirementFocus] =
    useState(false);
  const [accountTypeJointFocus, setAccountTypeJointFocus] =
    useState(false);
  const [accountTypeIndividualDivClass, setAccountTypeIndividualDivClass] =
    useState("selection-1");
  const [accountTypeTrustDivClass, setAccountTypeTrustDivClass] =
    useState("selection-1");
  const [accountTypeEntityDivClass, setAccountTypeEntityDivClass] =
    useState("selection-1");
  const [accountTypeRetirementDivClass, setAccountTypeRetirementDivClass] =
    useState("selection-1");
    const [accountTypeJointDivClass, setAccountTypeJointDivClass] =
    useState("selection-1");
  const [accountSelectionError, setAccountSelectionError] = useState("");

  let history = useHistory();

  useEffect(() => {
    setAccountTypeIndividualFocus(accountTypeIndividual);
    setAccountTypeTrustFocus(accountTypeTrust);
    setAccountTypeEntityFocus(accountTypeEntity);
    setAccountTypeRetirementFocus(accountTypeRetirement);
    setAccountTypeJointFocus(accountTypeJoint)
  }, []);

  useEffect(() => {
    setAccountTypeIndividualDivClass(
      accountTypeIndividualFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeIndividual(accountTypeIndividualFocus);
    setAccountSelectionError("");
  }, [accountTypeIndividualFocus, setAccountTypeIndividual]);

  useEffect(() => {
    setAccountTypeTrustDivClass(
      accountTypeTrustFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeTrust(accountTypeTrustFocus);
    setAccountSelectionError("");
  }, [accountTypeTrustFocus, setAccountTypeTrust]);

  useEffect(() => {
    setAccountTypeEntityDivClass(
      accountTypeEntityFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeEntity(accountTypeEntityFocus);
    setAccountSelectionError("");
  }, [accountTypeEntityFocus, setAccountTypeEntity]);

  useEffect(() => {
    setAccountTypeRetirementDivClass(
      accountTypeRetirementFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeRetirement(accountTypeRetirementFocus);
    setAccountSelectionError("");
  }, [accountTypeRetirementFocus, setAccountTypeRetirement]);


  useEffect(() => {
    setAccountTypeJointDivClass(
      accountTypeJointFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeJoint(accountTypeJointFocus);
    setAccountSelectionError("");
  }, [accountTypeJointFocus, setAccountTypeJoint]);


  const validateAccountSelection = () => {
    let isValid = true;

    if (
      !accountTypeIndividual &&
      !accountTypeTrust &&
      !accountTypeEntity &&
      !accountTypeRetirement &&
      !accountTypeJoint
    ) {
      isValid = false;
    }

    if (isValid) {
      navigation.next();
    } else {
      setAccountSelectionError("Please select an account type from above.");
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid w-100 h-100 g-0 ms-0 me-0 fillScreen align-items-center justify-content-start d-flex">
        <div className="row g-0 w-100 align-items-center justify-content-start">
          <div
            className="col-6 d-none d-sm-none d-md-none d-lg-block"
            style={{ backgroundColor: "#F0F4FD", zIndex: 1 }}
          >
            <img
              src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
              style={{
                height: "100vh",
                backgroundSize: "cover !important",
                zIndex: 2,
              }}
              alt="community"
            ></img>
          </div>
          <div
            className="col-xxl-6 col-xl-6 col-lg-6 col-12"
            style={{ zIndex: 3, backgroundColor: "#f7f7fa", height: "100vh" }}
          >
            <div className="container-fluid g-0 h-100 align-items-center ms-5 pe-3">
              <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
                <div className="col-12 g-0">
                  <div className="row g-0 w-100 mb-2">
                    <div className="col-11">
                      <span className="join-text pe-5">
                        How would you like to invest on Aqua?
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-11">
                      <span className="sub-text pe-5">
                        Please select an account type from below:
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-90 mb-3 justify-content-start">
                    <div className="col-7">
                      <div
                        className={
                          accountTypeIndividualDivClass + " pt-3 pb-3 ps-3 pe-3"
                        }
                        onClick={() => {
                          setAccountTypeTrustFocus(false);
                          setAccountTypeEntityFocus(false);
                          setAccountTypeJointFocus(false);
                          setAccountTypeRetirementFocus(false);
                          setAccountTypeIndividualFocus(
                            !accountTypeIndividualFocus
                          );
                        }}
                      >
                        <div className="row g-0 w-100 align-items-center">
                          <div className="col align-items-center">
                            <div className="row g-0 w-100">
                              <img
                                src="https://d1swyfveypj49y.cloudfront.net/individual-account.svg"
                                alt="individual-account"
                                style={{ width: 25 }}
                                className="me-2"
                              />
                              <div className="col d-flex align-items-center">
                                <span className="align-self-center new-selection-1-text ps-2 pe-2">
                                  As an individual
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row g-0 w-90 mb-3 justify-content-start">
                    <div className="col-7">
                      <div
                        className={
                          accountTypeJointDivClass + " pt-3 pb-3 ps-3 pe-3"
                        }
                        onClick={() => {
                          setAccountTypeTrustFocus(false);
                          setAccountTypeEntityFocus(false);
                          setAccountTypeRetirementFocus(false);
                          setAccountTypeIndividualFocus(false);
                          setAccountTypeJointFocus(!accountTypeJointFocus);
                        }}
                      >
                        <div className="row g-0 w-100 align-items-center">
                          <div className="col align-items-center">
                            <div className="row g-0 w-100">
                              <img
                                src="https://d1swyfveypj49y.cloudfront.net/trust-account.svg"
                                alt="joint-account"
                                style={{ width: 25 }}
                                className="me-2"
                              />
                              <div className="col d-flex align-items-center">
                                <span className="align-self-center new-selection-1-text ps-2 pe-2">
                                  Through a joint account
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                      </div> */ }
                  <div className="row g-0 w-90 mb-3 justify-content-start">
                    <div className="col-7">
                      <div
                        className={
                          accountTypeJointDivClass + " pt-3 pb-3 ps-3 pe-3"
                        }
                        onClick={() => {
                          setAccountTypeTrustFocus(false);
                          setAccountTypeEntityFocus(false);
                          setAccountTypeRetirementFocus(false);
                          setAccountTypeIndividualFocus(false);
                          setAccountTypeJointFocus(!accountTypeJointFocus);
                        }}
                      >
                        <div className="row g-0 w-100 align-items-center">
                          <div className="col align-items-center">
                            <div className="row g-0 w-100">
                              <img
                                src="https://d1swyfveypj49y.cloudfront.net/trust-account.svg"
                                alt="joint-account"
                                style={{ width: 25 }}
                                className="me-2"
                              />
                              <div className="col d-flex align-items-center">
                                <span className="align-self-center new-selection-1-text ps-2 pe-2">
                                  Through a joint account
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-90 mb-3 justify-content-start">
                    <div className="col-7">
                      <div
                        className={
                          accountTypeTrustDivClass + " pt-3 pb-3 ps-3 pe-3"
                        }
                        onClick={() => {
                          setAccountTypeTrustFocus(!accountTypeTrustFocus);
                          setAccountTypeEntityFocus(false);
                          setAccountTypeRetirementFocus(false);
                          setAccountTypeIndividualFocus(false);
                          setAccountTypeJointFocus(false);
                        }}
                      >
                        <div className="row g-0 w-100 align-items-center">
                          <div className="col align-items-center">
                            <div className="row g-0 w-100">
                              <img
                                src="https://d1swyfveypj49y.cloudfront.net/trust-account.svg"
                                alt="individual-account"
                                style={{ width: 25 }}
                                className="me-2"
                              />
                              <div className="col d-flex align-items-center">
                                <span className="align-self-center new-selection-1-text ps-2 pe-2">
                                  Through a trust account
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-90 mb-3 justify-content-start">
                    <div className="col-7">
                      <div
                        className={
                          accountTypeEntityDivClass + " pt-3 pb-3 ps-3 pe-3"
                        }
                        onClick={() => {
                          setAccountTypeTrustFocus(false);
                          setAccountTypeEntityFocus(!accountTypeEntityFocus);
                          setAccountTypeRetirementFocus(false);
                          setAccountTypeIndividualFocus(false);
                          setAccountTypeJointFocus(false);

                        }}
                      >
                        <div className="row g-0 w-100 align-items-center">
                          <div className="col align-items-center">
                            <div className="row g-0 w-100">
                              <img
                                src="https://d1swyfveypj49y.cloudfront.net/entity-account.svg"
                                alt="entity-account"
                                style={{ width: 25 }}
                                className="me-2"
                              />
                              <div className="col d-flex align-items-center">
                                <span className="align-self-center new-selection-1-text ps-2 pe-2">
                                  Through another type of entity
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row g-0 w-90 mb-4 justify-content-center">
                    <div className="col-7">
                      <div
                        className={
                          accountTypeRetirementDivClass + " pt-3 pb-3 ps-3 pe-3"
                        }
                        onClick={() => {
                          setAccountTypeTrustFocus(false);
                          setAccountTypeEntityFocus(false);
                          setAccountTypeRetirementFocus(
                            !accountTypeRetirementFocus
                          );
                          setAccountTypeIndividualFocus(false);
                        }}
                      >
                        <div className="row g-0 w-100">
                          <img
                            src="https://d1swyfveypj49y.cloudfront.net/retirement-account.svg"
                            alt="retirement-account"
                            style={{ width: 25 }}
                            className="me-2"
                          />
                          <div className="col d-flex align-items-center">
                            <span className="align-self-center new-selection-1-text ps-2 pe-2">
                              Through a retirement account
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {accountSelectionError ? (
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-10">
                        <div className="row g-0 w-100">
                          <div className="col">
                            <img
                              src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                              alt="error"
                              width="15"
                              height="15"
                            />
                            <span className="ms-3 account-selection-error-text">
                              {accountSelectionError}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row g-0 w-100 mb-5">
                    <div className="col-10">
                      <div className="row g-0 w-100">
                        <div className="col-4 ms-0 me-auto">
                          <div
                            onClick={() => validateAccountSelection()}
                            style={{ cursor: "pointer" }}
                          >
                            <StateBasedButton
                              proceed={true}
                              text={"Next Step"}
                            />
                          </div>
                        </div>
                        <div className="col-4 align-self-end text-end">
                          <span
                            className="sign-in-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/login")}
                          >
                            <u>or Sign In</u>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100">
                    <div className="col-xxl-6 col-xl-6 col-lg-7 col-sm-6 col-8 pt-0 pe-0">
                      <div className="row gx-1 w-100">
                        <div className="row g-0 w-100">
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AccountTypeSection;
