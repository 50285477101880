import React, { useState, useCallback } from "react";
import "./TabV2TitleStyles/TabV2TitleStyles.scss";

function TabV2Title({ title, setSelectedTab, index, setSelected, selected }) {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <div
      className="row gx-0 w-100 h-100 align-items-center justify-content-center"
      onClick={() => setSelected(true)}
    >
      {/* pb-md-4 pb-2 mt-md-0 mt-2 */}
      <button className={"tab-text align-self-center"} onClick={onClick}>
        {title}
      </button>
      <div
        className={
          selected
            ? "active-indicator align-self-end w-100"
            : "inactive-indicator align-self-end w-100"
        }
      ></div>
    </div>
  );
}
export default TabV2Title;
