import React from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {
  utils,
} from "@hassanmojab/react-modern-calendar-datepicker";
import "./ToFilterStyles/ToFilterStyles.css";

function ToFilter({ selectedDay, setSelectedDay }) {
  const renderCustomInput = ({ ref }) => (
    <div className="input-container w-100 pe-1" ref={ref}>
      <div className="row g-0 h-100 w-100 align-items-center" ref={ref}>
        <div
          className="col-9 d-flex h-100 align-self-center align-items-center me-auto"
          ref={ref}
        >
          <input
            readOnly
            ref={ref} // necessary
            placeholder="To: "
            value={
              selectedDay
                ? `E: ${selectedDay.month}/${selectedDay.day}/${selectedDay.year}`
                : "To: "
            }
            className="my-custom-input-class" // a styling class
          />
        </div>
        <div className="col-2 h-100 d-flex align-self-center justify-self-end">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/Calendar.svg"
            className="align-self-center"
            alt="calendar"
          ></img>
        </div>
      </div>
    </div>
  );

  const clearDateSelection = () => {
    return (
      <div className="container pb-3">
        <div clasName="row g-0 w-100">
          <div className="col-12">
            <div className="d-flex w-100 justify-content-center">
              <button
                className="clear-selection-button"
                onClick={() => setSelectedDay(null)}
              >
                <span className="clear-selection-text">Clear Selection</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <DatePicker
      value={selectedDay}
      onChange={setSelectedDay}
      colorPrimary={"#0D47A1"}
      renderInput={renderCustomInput}
      renderFooter={clearDateSelection}
      calendarPopperPosition="bottom"
    />
  );
}
export default ToFilter;
