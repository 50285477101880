import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../GlobalContexts/UserContext.js";
import { useHistory } from "react-router-dom";
import Header from "../../../Components/GlobalComponents/Header.js";
import FundCard from "../../Fund/InvestPage/FundCard/FundCard.js";
import "../../Fund/InvestPage/FundCard/TargetPerformanceSection/TargetPerformanceSectionStyles/TargetPerformanceSectionStyles.css";
import "../../Fund/InvestPage/FundCard/FundCardStyles/FundCardStyles.css";
import InvestmentAmountField from "../../../Components/GlobalComponents/InvestmentAmountField/InvestmentAmountField.js";
import GetFundSubscriptionInfo from "../../../Adapters/GetFundSubscriptionInfo";
import GetInvestorFundInvestment from "../../../Adapters/GetInvestorFundInvestmentAdapter";
import GetAccountQualificationVerification from "../../../Adapters/GetAccountQualificationVerification.js";
import { createInvestment } from "../../../Api/ConfirmInvestmentApi/ConfirmInvestmentApi.js";
import Tabs from "../../../Components/GlobalComponents/InvestmentTabs/Tabs.js";
import Tab from "../../../Components/GlobalComponents/InvestmentTabs/Tab.js";
import ConfirmInvestmentButtonSection from "./ConfirmInvestmentButtonSection/ConfirmInvestmentButtonSection.js";
import ConfirmInvestmentProfileIncompleteSection from "./ConfirmInvestmentProfileIncompleteSection/ConfirmInvestmentProfileIncompleteSection.js";
import * as dayjs from "dayjs";
import GetFundInfo from "../../../Adapters/FundDetailsPageAdapters.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AccountPicker from "../../../GlobalComponents/AccountPicker/AccountPicker.js";
import GetAccountListAdapter from "../../../Adapters/GetAccountListAdapter.js";
import ConfirmInvestmentAccountDropdown from "../../../GlobalComponents/ConfirmInvestmentAccountDropdown/ConfirmInvestmentAccountDropdown.js";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import LoadingAnimation from "../../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation.js";
import KycInProgressModal from "./KycInProgressModal/KycInProgressModal.js";
import { getSubdocLink } from "./../../../Api/DocumentsApi.js";
import HelloSign from "hellosign-embedded";
import { useParams } from "react-router";
import { getAccountSummary } from "./../../../Api/ProfileApi.js";
import ConfirmTaxInformation from "./ConfirmTaxInformation/ConfirmTaxInformation.js";
import WireInstructions from "./WireInstructions/WireInstructions.js";
import SubDocs from "./SubDocs/SubDocs.js";
import SydecarMessagingModal from "./SydecarMessagingModal/SydecarMessagingModal.js";
import InvestmentConfirmationModal from "./InvestmentConfirmationModal/InvestmentConfirmationModal.js";

var utc = require("dayjs/plugin/utc");

function ConfirmInvestment() {
  const MAXIMUM_INPUT = 10 ** 12;
  // To-do: Abstract this into common tooltip component (also used on StructureSection)
  const PLACEMENT_COPY = `
  <strong>
   Administrative Fee
  </strong>
  <br />
  This is a 1%, one-time, fee that we charge on top of the amount that an investor invests. For example, if Investor A wants to invest $300K into Opportunity Z on Aqua, their administrative fee would be $3k. When wiring funds to us, the total that the investor would wire is the investment amount + administrative fee = $300K + $3k = $303K
`;

  const renderTooltip = (copy, props) => (
    <Tooltip id="button-tooltip" style={{ width: "600px" }} {...props}>
      <div dangerouslySetInnerHTML={{ __html: copy }} />
    </Tooltip>
  );

  let history = useHistory();
  let statuses = {
    P: "Pending Approval",
    PD: "Pending Sub Docs",
    PF: "Pending Funding",
    F: "Funded",
    A: "Approved",
    R: "Rejected",
  };

  const [specifyInvestmentAmountSelected, setSpecifyInvestmentAmountSelected] =
    useState(true);
  const [investmentConfirmationSelected, setInvestmentConfirmationSelected] =
    useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [confirmTaxInformationSelected, setConfirmTaxInformationSelected] =
    useState(false);
  const [wireInstructionsSelected, setWireInstructionsSelected] =
    useState(false);
  const [subDocsSelected, setSubDocsSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(0);
  const [investSelected, setInvestSelected] = useState(false);
  const [investorQualificationError, setInvestorQualificationError] =
    useState("");
  const [investorVerificationError, setInvestorVerificationError] =
    useState("");
  const [qualificationTypeError, setQualificationTypeError] = useState("");
  const [allowIraInvestments, setallowIraInvestments] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [investmentAmountError, setInvestmentAmountError] = useState("");
  const [fundName, setFundName] = useState("");
  const [refresh, setRefresh] = useState(0);
  const { id, account_id } = useParams();
  const { fund } = GetFundInfo(id, setLoading);
  const { fundSubscriptionInfo } = GetFundSubscriptionInfo(id);
  const [showInvestmentConfirmation, setShowInvestmentConfirmation] =
    useState(false);
  const { user } = useContext(UserContext);
  const { investorFundInvestmentInfo } = GetInvestorFundInvestment(
    id,
    refresh,
    account_id ? account_id : user ? user.selectedAccountID : null,
    setInitial,
    setShowInvestmentConfirmation
  );
  const { accountQualificationVerification } =
    GetAccountQualificationVerification(refresh, setLoading);
  const [investmentStatus, setInvestmentStatus] = useState("");

  const [investorProfileComplete, setInvestorProfileComplete] = useState(null);
  const [entityComplete, setEntityComplete] = useState(null);
  const [canInvest, setCanInvest] = useState(true);
  const [accountType, setAccountType] = useState(null);

  const { accounts } = GetAccountListAdapter(refresh, setLoading);

  const [showKycInProgress, setShowKycInProgress] = useState(false);

  const [accountID, setAccountID] = useState(null);

  const [summary, setSummary] = useState();

  const [pending, setPending] = useState(false);

  const [sydecarOpportunity, setSydecarOpportunity] = useState(true);
  const [showSydecarMessaging, setShowSydecarMessaging] = useState(false);

  useEffect(async () => {
    console.log(account_id);
    !account_id &&
      user &&
      user.selectedAccountID !== "" &&
      setAccountID(user.selectedAccountID);
    account_id && setAccountID(account_id);
    console.log(user.selectedAccountID);
    const accountSummary = await getAccountSummary(user.selectedAccountID);
    setSummary(accountSummary.data);
    fund &&
      fund.sydecar !== undefined &&
      setCanInvest(!fund.sydecar || accountSummary.data[0].sydecar_eligibility);
  }, [user, account_id]);

  useEffect(() => {
    setSpecifyInvestmentAmountSelected(initial === 0);
    setSubDocsSelected(initial === 1);
    //setConfirmTaxInformationSelected(initial === 2);
    //setWireInstructionsSelected(initial === 3);
    setInvestmentConfirmationSelected(
      initial === 4 || initial === 2 || initial === 3
    );
  }, [initial]);

  // const client = new HelloSign();
  // const openSig = async () => {
  //   const subdocLink = await getSubdocLink(accountID, fundSubscriptionInfo.id);
  //   client.open(subdocLink.data.msg, {
  //     clientId: "ee92b78dc5a4c9124758eb38095d1e82",
  //     skipDomainVerification: true,
  //   });
  // };

  useEffect(() => {
    setInvestorProfileComplete(
      accountQualificationVerification.investorProfileComplete
    );
    setEntityComplete(accountQualificationVerification.entityComplete);
    setAccountType(accountQualificationVerification.accountType);

    // setInvestorProfileComplete(
    //   accountQualificationVerification.entity_complete
    // );
    // setEntityComplete(accountQualificationVerification.account_type);
    // setAccountType(accountQualificationVerification.investor_profile_complete);
  }, [accountQualificationVerification]);

  useEffect(() => {
    setallowIraInvestments(
      fundSubscriptionInfo ? fundSubscriptionInfo.allowIraInvestments : ""
    );
    //
    setRefresh((prev) => prev + 1);
  }, [fundSubscriptionInfo]);

  const [loadingInvestmentData, setLoadingInvestmentData] = useState(true);

  useEffect(() => {
    // console.log(fundSubscriptionInfo, (fundSubscriptionInfo && !fundSubscriptionInfo.error), investorFundInvestmentInfo);
    investorFundInvestmentInfo &&
      setPending(investorFundInvestmentInfo.status === "P");
    investorFundInvestmentInfo &&
      console.log(investorFundInvestmentInfo.status === "P");
    fundSubscriptionInfo &&
      !fundSubscriptionInfo.error &&
      investorFundInvestmentInfo &&
      setLoadingInvestmentData(false);
    fundSubscriptionInfo &&
      !fundSubscriptionInfo.error &&
      investorFundInvestmentInfo &&
      setLoadingInvestmentData(false);
  }, [fundSubscriptionInfo, investorFundInvestmentInfo]);

  // useEffect(() => {
  //   setInvestmentStatus(
  //     investorFundInvestmentInfo
  //       ? statuses[investorFundInvestmentInfo.status]
  //       : "...Loading"
  //   );
  //   !(
  //     investorFundInvestmentInfo && investorFundInvestmentInfo.status === "PD"
  //   ) &&
  //     setInitial(
  //       investorFundInvestmentInfo &&
  //         Object.keys(investorFundInvestmentInfo).length !== 0
  //         ? 1
  //         : 0
  //     );
  //   investorFundInvestmentInfo &&
  //     investorFundInvestmentInfo.status === "PD" &&
  //     setInitial(2);
  // }, [investorFundInvestmentInfo]);

  useEffect(async () => {
    // setSpecifyInvestmentAmountSelected(initial === 0);
    // setInvestmentConfirmationSelected(initial === 1);
    // setSubDocsSelected(initial === 2);
    if (initial === 2 && user !== null && fundSubscriptionInfo !== null) {
      /* const subdocLink = await getSubdocLink(
        user.selectedAccountID,
        fundSubscriptionInfo.id
      );
      //console.log(subdocLink.data.msg)
      setDocumentLink(subdocLink.data.msg);*/
      console.log(initial);
    }
  }, [initial, fundSubscriptionInfo, user]);

  useEffect(() => {
    const stringArray = id.split("_");
    let name = "";

    for (let i = 0; i < stringArray.length; i++) {
      name += stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);
      if (i !== stringArray.length - 1) {
        name += " ";
      }
    }
    setFundName(name);
  }, []);

  useEffect(() => {
    setInvestmentAmountError("");
  }, [investmentAmount]);

  const submitInvestment = async () => {
    var errorsDetected = false;
    var minimumInvestment = fundSubscriptionInfo
      ? fundSubscriptionInfo.minimumInvestment
      : 1000000;
    var remainingAllocation = fundSubscriptionInfo
      ? fundSubscriptionInfo.remainingAllocation
      : 0;
    var maximumInvestment = fundSubscriptionInfo
      ? fundSubscriptionInfo.maximumInvestment
      : 0;
    var investmentAmountNumbersOnly = investmentAmount.replace(/[^\d]/g, "");
    if (investmentAmount === "" || investmentAmount === "$") {
      setInvestmentAmountError("This field is required.");
      errorsDetected = true;
    } else if (investmentAmountNumbersOnly < minimumInvestment) {
      setInvestmentAmountError(
        "You must invest at least the minimum investment"
      );
      errorsDetected = true;
    } else if (investmentAmountNumbersOnly > remainingAllocation) {
      setInvestmentAmountError(
        "You must not invest an amount greater than the remaining allocation."
      );
      errorsDetected = true;
    } else if (
      investmentAmountNumbersOnly > maximumInvestment &&
      maximumInvestment > 0
    ) {
      setInvestmentAmountError(
        "You must not invest an amount greater than the maximum investment."
      );
      errorsDetected = true;
    }

    if (errorsDetected) {
      setInvestSelected(false);
      return;
    } else {
      setInvestSelected(true);
    }
  };

  // setInitial(1);
  //                   setInvestmentConfirmationSelected(true);
  //                   setSpecifyInvestmentAmountSelected(false);

  const confirmInvestment = async () => {
    var errors = false;

    if (Object.keys(accountQualificationVerification).length === 0) {
      errors = true;
      return;
    } else {
      if (
        accountQualificationVerification.accountType === "IND" ||
        accountQualificationVerification.accountType === "TRT"
      ) {
        if (!accountQualificationVerification.investorProfileComplete) {
          errors = true;
        }
      } else if (
        accountQualificationVerification.accountType === "ENT" ||
        accountQualificationVerification.accountType === "TRT"
      ) {
        if (
          !accountQualificationVerification.investorProfileComplete ||
          !accountQualificationVerification.entityComplete
        ) {
          errors = true;
        }
      }
      // if (accountQualificationVerification.qualificationType !== "QP") {
      //   errors = true;
      //   setInvestorQualificationError(
      //     "Sorry, you must be a qualified purchaser to invest in this fund."
      //   );
      // }
      // if (accountQualificationVerification.verificationStatus !== "S") {
      //   errors = true;
      //   if (
      //     accountQualificationVerification.verificationStatus === "I" ||
      //     accountQualificationVerification.verificationStatus === "P"
      //   ) {
      //     setInvestorVerificationError(
      //       "Sorry, your KYC/AML check has not been completed yet. Please allow us 24-48 hours to complete this check and try again when it's complete."
      //     );
      //   }
      //   if (accountQualificationVerification.verificationStatus === "F") {
      //     setInvestorVerificationError(
      //       "Your KYC/AML check has failed. Contact admin@investwithaqua.com for help"
      //     );
      //   }
      // }
      if (!errors) {
        var investmentAmountNumbersOnly = investmentAmount.replace(
          /[^\d]/g,
          ""
        );
        if (investmentAmountNumbersOnly !== "") {
          investmentAmountNumbersOnly += ".00";
        }

        try {
          //create user fund investment
          let fundId = fundSubscriptionInfo ? fundSubscriptionInfo.id : "";
          await createInvestment(
            fundId,
            investmentAmountNumbersOnly,
            accountID
          );
          // console.log("Success");
          // setInitial(1);
          setRefresh((prev) => prev + 1);
          // setInvestmentConfirmationSelected(true);
          // setSpecifyInvestmentAmountSelected(false);
          if (sydecarOpportunity === false) {
            setShowKycInProgress(true);
          } else {
            setShowSydecarMessaging(true);
          }
        } catch (err) {
          if (process.env.REACT_APP_DEBUG === "1") console.error(err);
          return;
        }
      }
    }
    // else {
    //   var investmentAmountNumbersOnly = investmentAmount.replace(/[^\d]/g, "");
    //   if (investmentAmountNumbersOnly !== "") {
    //     investmentAmountNumbersOnly += ".00";
    //   }

    //   try {
    //     //create user fund investment
    //     let fundId = fundSubscriptionInfo ? fundSubscriptionInfo.id : "";
    //     await createInvestment(fundId, investmentAmountNumbersOnly);
    //     setInitial(1);
    //     setRefresh((prev) => prev + 1);
    //   } catch (err) {
    //     if (process.env.REACT_APP_DEBUG === "1") console.error(err);
    //   }
    // }
  };

  const confirmAltoIRAInvestment = async () => {
    var errors = false;
    if (Object.keys(accountQualificationVerification).length === 0) {
      errors = true;
      return;
    } else {
      if (
        accountQualificationVerification.accountType === "IND" ||
        accountQualificationVerification.accountType === "RTMT"
      ) {
        if (!accountQualificationVerification.investorProfileComplete) {
          errors = true;
        }
      } else if (
        accountQualificationVerification.accountType === "ENT" ||
        accountQualificationVerification.accountType === "TRT"
      ) {
        errors = true;
        if (
          !accountQualificationVerification.investorProfileComplete ||
          !accountQualificationVerification.entityComplete
        ) {
          errors = true;
        }
      }
      // if (accountQualificationVerification.qualificationType !== "QP") {
      //   errors = true;
      //   setInvestorQualificationError(
      //     "Sorry, you must be a qualified purchaser to invest in this fund."
      //   );
      // }
      // if (accountQualificationVerification.verificationStatus !== "S") {
      //   errors = true;
      //   if (
      //     accountQualificationVerification.verificationStatus === "I" ||
      //     accountQualificationVerification.verificationStatus === "P"
      //   ) {
      //     setInvestorVerificationError(
      //       "Sorry, your KYC/AML check has not been completed yet. Please allow us 24-48 hours to complete this check and try again when it's complete."
      //     );
      //   }
      //   if (accountQualificationVerification.verificationStatus === "F") {
      //     setInvestorVerificationError(
      //       "Your KYC/AML check has failed. Contact admin@investwithaqua.com for help"
      //     );
      //   }
      // }
      if (!errors) {
        var investmentAmountNumbersOnly = investmentAmount.replace(
          /[^\d]/g,
          ""
        );
        if (investmentAmountNumbersOnly !== "") {
          investmentAmountNumbersOnly += ".00";
        }

        try {
          //create user fund investment
          let fundId = fundSubscriptionInfo ? fundSubscriptionInfo.id : "";
          await createInvestment(
            fundId,
            investmentAmountNumbersOnly,
            accountID
          );
          // setInitial(1);
          setRefresh((prev) => prev + 1);
          // setInvestmentConfirmationSelected(true);
          // setSpecifyInvestmentAmountSelected(false);
          setShowKycInProgress(true);
        } catch (err) {
          errors = true;
          const message = err?.response?.data?.detail;
          if (message) setInvestmentAmountError(err?.response?.data?.detail);
          if (process.env.REACT_APP_DEBUG === "1") console.error(err);
          return;
        }
      }
    }
    // else {
    //   var investmentAmountNumbersOnly = investmentAmount.replace(/[^\d]/g, "");
    //   if (investmentAmountNumbersOnly !== "") {
    //     investmentAmountNumbersOnly += ".00";
    //   }

    //   try {
    //     //create user fund investment
    //     let fundId = fundSubscriptionInfo ? fundSubscriptionInfo.id : "";
    //     await createInvestment(fundId, investmentAmountNumbersOnly);
    //     setInitial(1);
    //     setRefresh((prev) => prev + 1);
    //   } catch (err) {
    //     if (process.env.REACT_APP_DEBUG === "1") console.error(err);
    //   }
    // }
  };

  return (
    <React.Fragment>
      <Header activePage={"invest"} />
      <div className="container-xxl w-90 ps-3 pe-3 pt-5 mt-5">
        <KycInProgressModal
          showKycInProgress={showKycInProgress}
          setShowKycInProgress={setShowKycInProgress}
          setRefresh={setRefresh}
          accountID={accountID}
        />
        <SydecarMessagingModal
          showSydecarMessagingModal={showSydecarMessaging}
          setShowSydecarMessagingModal={setShowSydecarMessaging}
          setRefresh={setRefresh}
          accountID={accountID}
        />
        <InvestmentConfirmationModal
          showInvestmentConfirmationModal={showInvestmentConfirmation}
          setShowInvestmentConfirmationModal={setShowInvestmentConfirmation}
          setRefresh={setRefresh}
          accountID={accountID}
        />
        <div className="row g-0 w-100 mt-3 mb-3">
          <div className="col-sm-5 d-flex">
            <span
              className="progress-text-bold align-self-center"
              onClick={() => history.push("/invest")}
              style={{ cursor: "pointer" }}
            >
              Invest
            </span>

            <img
              className="ms-1 align-self-center"
              src="https://d1swyfveypj49y.cloudfront.net/SideArrow.svg"
              alt="sidearrow"
            ></img>

            <span
              className="progress-text-bold ms-1"
              onClick={() => history.push("/invest/" + id)}
              style={{ cursor: "pointer" }}
            >
              Fund
            </span>

            <img
              className="ms-1 align-self-center"
              src="https://d1swyfveypj49y.cloudfront.net/SideArrow.svg"
              alt="sidearrow"
            ></img>

            <span className="progress-text-unbolded ms-1 align-self-center">
              Confirm Investment
            </span>
          </div>
        </div>
        {!loadingInvestmentData ? (
          <Tabs initial={initial} fullWidth={true}>
            <Tab
              title="Investment"
              selected={specifyInvestmentAmountSelected}
              setSelected={setSpecifyInvestmentAmountSelected}
            >
              {pending ? (
                <>
                  <div className="col-12">
                    <div className="investment-container">
                      <div className="row g-0 w-100 pt-4 mb-3">
                        <span className="investment-text ps-5 pe-3">
                          Account is pending verification.
                        </span>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-xl-6 col-md-8 col-12">
                            <div className="d-flex">
                              <span className="investment-note ps-5 pe-3">
                                You have invested in this opportunity. Documents
                                will be sent when your account is successfully
                                verified.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {fundSubscriptionInfo && !fundSubscriptionInfo.error ? (
                    <div className="row g-0 w-100">
                      <div className="col-12">
                        <div className="investment-container">
                          <div className="row g-0 w-100 pt-4 mb-3">
                            <span className="investment-text ps-5 pe-3">
                              How much would you like to invest in{" "}
                              {fundSubscriptionInfo
                                ? fundSubscriptionInfo.name
                                : ""}
                              ?
                            </span>
                          </div>
                          <div className="row g-0 w-100 mb-3">
                            <div className="col-xl-6 col-md-8 col-12">
                              <div className="d-flex">
                                <span className="investment-note ps-5 pe-3">
                                  If you have not completed your investor
                                  information and verified your identity, you
                                  will need to complete those sections before
                                  you may invest in opportunities through Aqua.
                                </span>
                              </div>
                              {fund &&
                                fund.link &&
                                [
                                  "bling_capital_fund_iv",
                                  "bling_capital_fund_iv_opps",
                                ].includes(fund.link) && (
                                  <div className="d-flex">
                                    <span className="investment-note ps-5 pe-3 pt-4">
                                      You MUST make an equal-sized investment in
                                      the other fund as well to get allocation.
                                      If you do not do this, you will NOT be
                                      able to invest in either vehicle. This is
                                      a Bling Capital requirement.
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row g-0 w-100 mb-2">
                            <div className="col-lg-12 col-10">
                              <div className="d-flex"></div>
                            </div>
                          </div>
                          <div className="row g-0">
                            {" "}
                            {
                              <div
                                class="row g-0 ps-5"
                                style={{ width: "500px" }}
                              >
                                {" "}
                                <div class="col-12">
                                  <div class="row g-0">
                                    <div class="col-8">
                                      <div class="row g-0 mb-3">
                                        <div class="row gx-0">
                                          <div
                                            class="two-by-two-grid rounded ps-3"
                                            style={{ position: "relative" }}
                                          >
                                            <div
                                              class={
                                                fundSubscriptionInfo &&
                                                fundSubscriptionInfo.maximumInvestment !==
                                                  0.0
                                                  ? "row border-between vertical-border"
                                                  : "row border-between"
                                              }
                                            >
                                              <div
                                                class={
                                                  fundSubscriptionInfo &&
                                                  fundSubscriptionInfo.maximumInvestment !==
                                                    0.0
                                                    ? "col-6 two-by-two-grid-item"
                                                    : "col-6 two-by-two-grid-item"
                                                }
                                              >
                                                {" "}
                                                <div class="fund-target-header-text">
                                                  Minimum Investment
                                                </div>{" "}
                                                <span class="info-text">
                                                  {" "}
                                                  <strong>
                                                    {fundSubscriptionInfo
                                                      ? "$" +
                                                        fundSubscriptionInfo.minimumInvestment.toLocaleString()
                                                      : "$ ...loading"}
                                                  </strong>
                                                </span>
                                              </div>
                                              {/* {fundSubscriptionInfo &&
                                            fundSubscriptionInfo.maximumInvestment !==
                                              0.0 && (
                                              <div class="col-4 two-by-two-grid-item">
                                                {" "}
                                                <div class="fund-target-header-text">
                                                  Maximum Allocation
                                                </div>{" "}
                                                <span class="info-text">
                                                  {" "}
                                                  <strong>
                                                    {fundSubscriptionInfo
                                                      ? "$" +
                                                        fundSubscriptionInfo.maximumInvestment.toLocaleString()
                                                      : "$ ...loading"}
                                                  </strong>
                                                </span>
                                              </div>
                                            )} */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                          <div className="row g-0 w-100 mb-3"></div>
                          {canInvest ? (
                            <>
                              {!loading &&
                                fundSubscriptionInfo &&
                                !fundSubscriptionInfo.error && (
                                  <React.Fragment>
                                    <div className="row g-0 w-100 mb-1 ps-5">
                                      <span class="investment-amount-title mb-2">
                                        Please select the account you would like
                                        to invest using
                                      </span>
                                    </div>
                                    <div className="row g-0 w-100 mb-4 ps-5">
                                      <ConfirmInvestmentAccountDropdown
                                        accounts={accounts}
                                        width={350}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}

                              <div class="row g-0 w-100 mb-4 me-3">
                                <div class="ps-5">
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "145px",
                                    }}
                                  >
                                    <div class="investment-amount-title mb-2">
                                      Amount
                                    </div>
                                    <InvestmentAmountField
                                      placeholder={"Enter Investment Amount"}
                                      error={investmentAmountError}
                                      initialValue={investmentAmount}
                                      fieldValueChange={setInvestmentAmount}
                                      disabled={investSelected}
                                      maxInput={MAXIMUM_INPUT}
                                    />
                                    {investmentAmountError &&
                                      investmentAmountError !==
                                        "This field is required." && (
                                        <div
                                          className="investment-amount-error-text mt-1"
                                          style={{ display: "table" }}
                                        >
                                          <span>{investmentAmountError}</span>
                                        </div>
                                      )}
                                  </div>
                                  {fundSubscriptionInfo &&
                                    fundSubscriptionInfo.legacyPricingStructure ===
                                      true && (
                                      <div
                                        style={{
                                          fontSize: "xx-large",
                                          display: "inline-block",
                                        }}
                                        class="ps-3 pe-3 mb-4"
                                      >
                                        +
                                      </div>
                                    )}
                                  {fundSubscriptionInfo &&
                                    fundSubscriptionInfo.legacyPricingStructure ===
                                      true && (
                                      <div
                                        class="pe-3"
                                        style={{ display: "inline-block" }}
                                      >
                                        <div class="investment-amount-title mb-2">
                                          <div className="d-flex">
                                            <span class="align-self-center">
                                              Administrative Fee{" "}
                                            </span>
                                            <div className="align-self-center d-flex align-items-center pt-auto pb-auto">
                                              <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 200, hide: 400 }}
                                                overlay={(props) =>
                                                  renderTooltip(
                                                    PLACEMENT_COPY,
                                                    props
                                                  )
                                                }
                                              >
                                                <img
                                                  src="https://d1swyfveypj49y.cloudfront.net/i.svg"
                                                  className="h-100"
                                                  style={{ paddingLeft: "5px" }}
                                                />
                                              </OverlayTrigger>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="two-by-two-grid rounded"
                                          style={{ padding: "10px" }}
                                        >
                                          <div class="row">
                                            <div class="two-by-two-grid-item">
                                              <span class="placement-total-text">
                                                <div class="internal-input">
                                                  <b
                                                    style={{
                                                      paddingRight: "10px",
                                                    }}
                                                  >
                                                    $
                                                  </b>
                                                  <b>
                                                    {fund
                                                      ? (
                                                          (fund.placementFee *
                                                            investmentAmount.replace(
                                                              /[^\d]/g,
                                                              ""
                                                            )) /
                                                          100
                                                        ).toLocaleString(
                                                          "en-us"
                                                        )
                                                      : ""}
                                                  </b>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  {fundSubscriptionInfo &&
                                    fundSubscriptionInfo.legacyPricingStructure ===
                                      true && (
                                      <div
                                        style={{
                                          fontSize: "xx-large",
                                          display: "inline-block",
                                        }}
                                        class="pe-3 mb-4"
                                      >
                                        =
                                      </div>
                                    )}
                                  {fundSubscriptionInfo &&
                                    fundSubscriptionInfo.legacyPricingStructure ===
                                      true && (
                                      <div
                                        class
                                        style={{ display: "inline-block" }}
                                      >
                                        <div class="investment-amount-title mb-2">
                                          Total to be wired
                                        </div>
                                        <div
                                          class="two-by-two-grid rounded"
                                          style={{ padding: "10px" }}
                                        >
                                          <div class="row">
                                            <div class="two-by-two-grid-item">
                                              {" "}
                                              <span class="placement-total-text">
                                                <div class="internal-input">
                                                  <b
                                                    style={{
                                                      paddingRight: "10px",
                                                    }}
                                                  >
                                                    $
                                                  </b>
                                                  <b>
                                                    {fund
                                                      ? (
                                                          (1 +
                                                            fund.placementFee /
                                                              100) *
                                                          investmentAmount.replace(
                                                            /[^\d]/g,
                                                            ""
                                                          )
                                                        ).toLocaleString(
                                                          "en-us"
                                                        )
                                                      : ""}
                                                  </b>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="row g-0 w-100 mb-3">
                              <div className="col-xl-6 col-md-8 col-12">
                                <div className="d-flex">
                                  <span className="investment-note ps-5 pe-3">
                                    Acccount is ineligible to invest.
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                          )}

                          {
                            !loading &&
                              canInvest &&
                              (accountType === "IND" ||
                              accountType === "RTMT" ||
                              accountType === "JOINT" ? (
                                investorProfileComplete ? (
                                  <ConfirmInvestmentButtonSection
                                    accountType={accountType}
                                    investSelected={investSelected}
                                    history={history}
                                    id={id}
                                    submitInvestment={submitInvestment}
                                    confirmInvestment={confirmInvestment}
                                    setInitial={setInitial}
                                    setInvestmentConfirmationSelected={
                                      setInvestmentConfirmationSelected
                                    }
                                    setSpecifyInvestmentAmountSelected={
                                      setSpecifyInvestmentAmountSelected
                                    }
                                    setInvestSelected={setInvestSelected}
                                    investorQualificationError={
                                      investorQualificationError
                                    }
                                    investorVerificationError={
                                      investorVerificationError
                                    }
                                    investmentConfirmationSelected={
                                      investmentConfirmationSelected
                                    }
                                    loading={loading}
                                    allowIraInvestments={allowIraInvestments}
                                    confirmAltoIRAInvestment={
                                      confirmAltoIRAInvestment
                                    }
                                  />
                                ) : (
                                  <ConfirmInvestmentProfileIncompleteSection
                                    history={history}
                                    accountType={accountType}
                                    investorProfileComplete={
                                      investorProfileComplete
                                    }
                                    entityComplete={entityComplete}
                                    accountID={accountID}
                                  />
                                )
                              ) : accountType === "TRT" ||
                                accountType === "ENT" ? (
                                !investorProfileComplete || !entityComplete ? (
                                  <ConfirmInvestmentProfileIncompleteSection
                                    history={history}
                                    accountType={accountType}
                                    investorProfileComplete={
                                      investorProfileComplete
                                    }
                                    entityComplete={entityComplete}
                                    accountID={accountID}
                                  />
                                ) : (
                                  <ConfirmInvestmentButtonSection
                                    accountType={accountType}
                                    investSelected={investSelected}
                                    history={history}
                                    id={id}
                                    submitInvestment={submitInvestment}
                                    confirmInvestment={confirmInvestment}
                                    setInitial={setInitial}
                                    setInvestmentConfirmationSelected={
                                      setInvestmentConfirmationSelected
                                    }
                                    setSpecifyInvestmentAmountSelected={
                                      setSpecifyInvestmentAmountSelected
                                    }
                                    setInvestSelected={setInvestSelected}
                                    investorQualificationError={
                                      investorQualificationError
                                    }
                                    investorVerificationError={
                                      investorVerificationError
                                    }
                                    investmentConfirmationSelected={
                                      investmentConfirmationSelected
                                    }
                                    loading={loading}
                                    allowIraInvestments={allowIraInvestments}
                                    confirmAltoIRAInvestment={
                                      confirmAltoIRAInvestment
                                    }
                                  />
                                )
                              ) : null)
                            // <div className="row g-0 w-75 pb-5">
                            //   <div className="col-xl-5 col-12">
                            //     <div className="button-group ms-5">
                            //       {investSelected ? (
                            //         <React.Fragment>
                            //           <button
                            //             type="button"
                            //             class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
                            //             onClick={async () => {
                            //               confirmInvestment();
                            //               setInitial(1);
                            //               setInvestmentConfirmationSelected(true);
                            //               setSpecifyInvestmentAmountSelected(false);
                            //             }}
                            //           >
                            //             Confirm Investment
                            //           </button>
                            //           <span
                            //             className="cancel-text align-self-center"
                            //             onClick={() => setInvestSelected(false)}
                            //           >
                            //             Cancel
                            //           </span>
                            //           {investorQualificationError ? (
                            //             <div className="row g-0 w-100 mb-4">
                            //               <div className="col-10">
                            //                 <div className="row g-0 w-100">
                            //                   <div className="col">
                            //                     <img
                            //                       src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                            //                       alt="error"
                            //                       width="15"
                            //                       height="15"
                            //                     />
                            //                     <span className="ms-3 qp-error-text">
                            //                       {investorQualificationError}
                            //                     </span>
                            //                   </div>
                            //                 </div>
                            //               </div>
                            //             </div>
                            //           ) : null}
                            //           {investorVerificationError ? (
                            //             <div className="row g-0 w-100 mb-4">
                            //               <div className="col-10">
                            //                 <div className="row g-0 w-100">
                            //                   <div className="col">
                            //                     <img
                            //                       src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                            //                       alt="error"
                            //                       width="15"
                            //                       height="15"
                            //                     />
                            //                     <span className="ms-3 qp-error-text">
                            //                       {investorVerificationError}
                            //                     </span>
                            //                   </div>
                            //                 </div>
                            //               </div>
                            //             </div>
                            //           ) : null}
                            //         </React.Fragment>
                            //       ) : (
                            //         <React.Fragment>
                            //           <button
                            //             type="button"
                            //             class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
                            //             onClick={() => {
                            //               submitInvestment();
                            //             }}
                            //             disabled={loading}
                            //           >
                            //             Invest
                            //           </button>
                            //           <span
                            //             className="cancel-text align-self-center"
                            //             onClick={() => history.push("/invest/" + id)}
                            //           >
                            //             Go Back
                            //           </span>
                            //         </React.Fragment>
                            //       )}
                            //     </div>
                            //   </div>
                            // </div>)}
                          }
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="investment-container">
                        <div className="row g-0 w-100 pt-4 mb-3">
                          <span className="investment-text ps-5 pe-3">
                            Account not authorized{" "}
                          </span>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-xl-6 col-md-8 col-12">
                            <div className="d-flex">
                              <span className="investment-note ps-5 pe-3">
                                The account selected is not qualified to access
                                this fund, please select another account.
                              </span>
                            </div>
                            <div className="row g-0 w-100 ps-5 mt-3">
                              {!loading && (
                                <ConfirmInvestmentAccountDropdown
                                  accounts={accounts}
                                  width={350}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-2">
                          <div className="col-lg-12 col-10">
                            <div className="d-flex"></div>
                          </div>
                        </div>
                        <div className="row g-0"> </div>
                      </div>{" "}
                    </div>
                  )}{" "}
                </>
              )}
            </Tab>
            <Tab
              title="Subdocs"
              selected={subDocsSelected}
              setSelected={setSubDocsSelected}
            >
              <SubDocs
                summary={summary}
                accountID={accountID}
                fundSubscriptionInfo={fundSubscriptionInfo}
                setRefresh={setRefresh}
              />
            </Tab>
            {/* <Tab
              title="Tax Information"
              selected={confirmTaxInformationSelected}
              setSelected={setConfirmTaxInformationSelected}
              large={true}
            >
              <ConfirmTaxInformation />
            </Tab>
            <Tab
              title="Wire Instructions"
              selected={wireInstructionsSelected}
              setSelected={setWireInstructionsSelected}
              large={true}
            >
              <WireInstructions fund={fund} />
                          </Tab> */}
            <Tab
              title="Confirmation"
              selected={investmentConfirmationSelected}
              setSelected={setInvestmentConfirmationSelected}
            >
              <div className="container-fluid ps-4 h-100">
                <div className="row g-0 w-100">
                  <div className="col-12 ps-2">
                    <div className="row g-0 w-100">
                      <div className="col-lg-7 col-12 h-100 pt-0">
                        <div className="row g-0 w-100 h-100 border-right pb-5">
                          <div className="col-12">
                            <div className="row g-0 w-100">
                              <div className="col-xxl-9 col-11 ps-0">
                                <div className="row gx-0 w-100 mb-5">
                                  <span className="pt-4 pb-3 request-received-text">
                                    Congratulations, you have successfully
                                    invested in this opportunity.
                                  </span>
                                </div>
                                <div className="row g-0 w-100 mb-2">
                                  <span className="response-text">
                                    You're all done with our investment process,
                                    so be sure to be on the lookout for future
                                    updates on your investment from us. You can
                                    refer to the portfolio page for updates on
                                    your investment as distributions roll in.
                                  </span>
                                </div>
                                <div className="row g-0 w-100 mb-4">
                                  {/* <span className="response-text">
                                    You will not be guaranteed an allocation in
                                    the opportunity until you have completed the
                                    subscription documents.
                                  </span> */}
                                </div>
                                <div className="row g-0 w-100">
                                  {/* <span className="no-response-text">
                                    If you don't hear from us within 48 hours,
                                    contact us at{" "}
                                    <a
                                      href="mailto:admin@investwithaqua.com"
                                      className="admin-email-text"
                                    >
                                      admin@investwithaqua.com
                                    </a>
                                  </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-12">
                        <div className="row g-0 w-100 justify-content-lg-center pb-lg-0 pb-3">
                          <div className="col-lg-10 col-12 pt-4 pe-lg-0 pe-4">
                            <div className="row g-0 w-100 mb-2">
                              <span className="investment-details-header-text pb-3">
                                Investment Details
                              </span>
                            </div>
                            <div className="row g-0 w-100 mb-1">
                              <span className="selected-fund-header-text">
                                Selected:
                              </span>
                            </div>
                            <div className="row g-0 w-100 mb-3">
                              <div className="col-12 rounded-edges">
                                <div className="row g-0 w-100 align-items-center">
                                  <div className="col-xl-2 col-lg-3 col-sm-2 col-3">
                                    <img
                                      src="https://d1swyfveypj49y.cloudfront.net/ThamesvilleLogo.svg"
                                      className="ms-2 mt-2 mb-2"
                                      height="40"
                                      alt="logo"
                                    ></img>
                                  </div>
                                  <div className="col-xl-8 col-lg-7 col-sm-8 col-7">
                                    <div className="row g-0 w-100">
                                      <span className="small-fund-name">
                                        {investorFundInvestmentInfo &&
                                        Object.keys(investorFundInvestmentInfo)
                                          .length !== 0
                                          ? investorFundInvestmentInfo.fundName
                                          : "Loading..."}
                                      </span>
                                    </div>
                                    {/* <div className="row g-0 w-100">
                                    <span className="small-firm-name">THM</span>
                                  </div> */}
                                  </div>
                                  <div className="col-2">
                                    <div className="d-flex justify-content-lg-center justify-content-end me-lg-0 me-2">
                                      <img
                                        src="https://d1swyfveypj49y.cloudfront.net/SideArrowButton.svg"
                                        height="36"
                                        alt="logo"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          history.push("/invest/" + id)
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row g-0 w-100 mb-1">
                              <div className="col-12">
                                <div className="d-flex">
                                  <span className="selected-fund-header-text justify-self-start">
                                    Date:
                                  </span>
                                  <span className="amount-text justify-self-end ms-auto me-0 text-end">
                                    {investorFundInvestmentInfo &&
                                    Object.keys(investorFundInvestmentInfo)
                                      .length !== 0
                                      ? dayjs(
                                          investorFundInvestmentInfo.investmentDate
                                        ).format("MMM D, YYYY")
                                      : "...loading"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row g-0 w-100 mb-1">
                              <div className="col-12">
                                <div className="d-flex">
                                  <span className="selected-fund-header-text justify-self-start">
                                    Amount:
                                  </span>
                                  <span className="amount-text justify-self-end ms-auto me-0 text-end">
                                    {investorFundInvestmentInfo &&
                                    Object.keys(investorFundInvestmentInfo)
                                      .length !== 0
                                      ? "$" +
                                        investorFundInvestmentInfo.investmentAmount.toLocaleString(
                                          "en-us"
                                        )
                                      : investmentAmount}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row g-0 w-100 mb-1">
                              <div className="col-12">
                                <div className="d-flex">
                                  <span className="selected-fund-header-text justify-self-start">
                                    Status:
                                  </span>
                                  <span className="amount-text justify-self-end ms-auto me-0 text-end">
                                    {investmentStatus}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <LoadingAnimation loading={true} />
        )}
      </div>
    </React.Fragment>
  );
}
export default ConfirmInvestment;
