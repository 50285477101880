import React, { useEffect } from "react";
import StyledDateInputField from "../../../../../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import StyledTextInputField from "../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import { formatSSN } from "../../../../../../../GlobalFunctions/globalFunctions.js";

function IndividualAccountPersonalInformationTaxSection({
  domicileCountry,
  dob,
  setDob,
  dobError,
  setDobError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="account-legal-information-step-subheader">
          Personal Information
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledDateInputField
            value={dob}
            setValue={setDob}
            valueError={dobError}
            setValueError={setDobError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"individual-account-dob-input"}
          />
        </div>
        <div className="col pe-0 ps-2">
          {domicileCountry === "United States" && (
            <StyledTextInputField
              value={idNumber}
              setValue={setIDNumber}
              valueError={idNumberError}
              setValueError={setIDNumberError}
              label={"SSN"}
              required={true}
              fullWidth={true}
              size={"small"}
              mask={{ maskFormatter: formatSSN, maskPattern: "999-99-9999" }}
              id={"individual-account-ssn-input"}
            />
          )}
          {domicileCountry !== "United States" && (
            <StyledTextInputField
              value={idNumber}
              setValue={setIDNumber}
              valueError={idNumberError}
              setValueError={setIDNumberError}
              label={"Passport Number"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"individual-account-passport-input"}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default IndividualAccountPersonalInformationTaxSection;
