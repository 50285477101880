import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
// import $ from "jquery";
// import Popper from "popper.js";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";

// var AWS = require("aws-sdk");

// AWS.config.accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
// AWS.config.secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY;
// AWS.config.region = process.env.REACT_APP_AWS_REGION;
Amplify.configure(awsmobile);
// Amplify.configure({
//   aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
//   aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
//   aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
//   aws_user_pools_web_client_id:
//     process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
//   aws_cognito_identity_pool_id:
//     process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
//   aws_user_files_s3_bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
//   aws_user_files_s3_bucket_region:
//     process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
