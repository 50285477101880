import React from "react";

function AccountQualification({
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
}) {
  return (
    <div className="row g-0 w-100 mb-4 entity-account-plain-text-header-container">
      <div className="col-12">
        <div className="row g-0 w-100 row-bottom-border">
          <div className="col col-right-border pe-2">
            <div
              className="row g-0 w-100 mb-2"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <span className="entity-account-plain-text-header">
                Account Number
              </span>
            </div>
            <div
              className="row g-0 w-100"
              style={{
                paddingBottom: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <span className="entity-account-plain-text-value">
                {accountNumber}
              </span>
            </div>
          </div>
          <div className="col ps-2 pe-0">
            <div
              className="row g-0 w-100 mb-2"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <span className="entity-account-plain-text-header">
                Date Added
              </span>
            </div>
            <div
              className="row g-0 w-100"
              style={{
                paddingBottom: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <span className="entity-account-plain-text-value">
                {dateAdded}
              </span>
            </div>
          </div>
        </div>
        <div className="row g-0 w-100">
        <div className="col pe-2" style={{textAlign: "center"}}>
            <div
              className="row g-0 w-100 mb-2"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <span className="individual-account-plain-text-header">
                Qualification Level
              </span>
            </div>
            <div
              className="row g-0 w-100 align-items-center"
              style={{
                paddingBottom: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <span className="individual-account-qualification-level-text">
                {qualificationLevel}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AccountQualification;
