import React, { useState, useEffect, useContext } from "react";
import "./DeliverableAddressSectionStyles/DeliverableAddressSectionStyles.scss";
import ProfileInputField from "../../../../Components/GlobalComponents/ProfileInputFields/ProfileInputField";
import StateInput from "../../../../Components/GlobalComponents/StateInput/StateInput";
import CountryInput from "../../../../Components/GlobalComponents/CountryInput/CountryInput";
import { UserContext } from "../../../../GlobalContexts/UserContext";

function DeliverableAddressSection({
  deliverableAddressLine1,
  deliverableAddressLine2,
  deliverableCity,
  deliverableState,
  deliverableCountry,
  deliverablePostalCode,
  deliverableAddressLine1Error,
  deliverableAddressLine2Error,
  deliverableCityError,
  deliverableStateError,
  deliverableCountryError,
  deliverablePostalCodeError,
  setDeliverableAddressLine1,
  setDeliverableAddressLine2,
  setDeliverableCity,
  setDeliverableState,
  setDeliverableCountry,
  setDeliverablePostalCode,
}) {
  const { user, setUser } = useContext(UserContext);

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mt-3 mb-3">
        <div className="col-12">
          <span className="header-text">Deliverable Address</span>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col-12">
          <div className="row gx-4 w-100">
            <div className="col-12">
              <span className="SignupForm-field-title-text align-self-start">
                Address Line 1
              </span>
              <div className="align-self-end mt-1">
                <ProfileInputField
                  placeholder={"32 Columbus Drive"}
                  error={deliverableAddressLine1Error}
                  fieldValueChange={setDeliverableAddressLine1}
                  initialValue={deliverableAddressLine1}
                  disabled={user.investorSecondaryAddressLine1 !== ""}
                />
                {deliverableAddressLine1Error &&
                  deliverableAddressLine1Error !==
                    "This field is required." && (
                    <div className="SignupForm-field-error-text mt-1">
                      <span className="">{deliverableAddressLine1Error}</span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col-12">
          <div className="row gx-4 w-100">
            <div className="col-12">
              <span className="SignupForm-field-title-text align-self-start">
                Address Line 2
              </span>
              <div className="align-self-end mt-1">
                <ProfileInputField
                  placeholder={"Apt. 3F"}
                  error={deliverableAddressLine2Error}
                  fieldValueChange={setDeliverableAddressLine2}
                  initialValue={deliverableAddressLine2}
                  disabled={user.investorSecondaryAddressLine1 !== ""}
                />
                {deliverableAddressLine2Error &&
                  deliverableAddressLine2Error !==
                    "This field is required." && (
                    <div className="SignupForm-field-error-text mt-1">
                      <span className="">{deliverableAddressLine2Error}</span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col-12">
          <div className="row gx-4 w-100">
            <div className="col">
              <span className="SignupForm-field-title-text align-self-start">
                City
              </span>
              <div className="align-self-end mt-1">
                <ProfileInputField
                  placeholder={"Albany"}
                  error={deliverableCityError}
                  fieldValueChange={setDeliverableCity}
                  initialValue={deliverableCity}
                  disabled={user.investorSecondaryAddressLine1 !== ""}
                />
                {deliverableCityError &&
                  deliverableCityError !== "This field is required." && (
                    <div className="SignupForm-field-error-text mt-1">
                      <span className="">{deliverableCityError}</span>
                    </div>
                  )}
              </div>
            </div>
            {deliverableCountry === "United States" && (
              <div className="col mt-md-0 mt-2">
                <span className="SignupForm-field-title-text">State</span>
                <div className="align-self-end mt-1">
                  <StateInput
                    value={deliverableState}
                    setValue={setDeliverableState}
                    placeholder={"Choose a state"}
                  ></StateInput>
                  {deliverableStateError && (
                    <div className="SignupForm-field-error-text align-self-end mt-1">
                      <span className="">{deliverableStateError}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col-12">
          <div className="row gx-4 w-100">
            <div className="col-md-6 mt-md-0 mt-2">
              <span className="SignupForm-field-title-text">Country</span>
              <div className="align-self-end mt-1">
                <CountryInput
                  value={deliverableCountry}
                  setValue={setDeliverableCountry}
                  placeholder={"Choose a country"}
                ></CountryInput>
                {deliverableCountryError && (
                  <div className="SignupForm-field-error-text mt-1">
                    <span className="">{deliverableCountryError}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mt-md-0 mt-2">
              <span className="SignupForm-field-title-text">Postal Code</span>
              <div className="align-self-end mt-1">
                <ProfileInputField
                  placeholder={"10036"}
                  error={deliverablePostalCodeError}
                  fieldValueChange={setDeliverablePostalCode}
                  initialValue={deliverablePostalCode}
                  disabled={user.investorSecondaryAddressLine1 !== ""}
                />
                {deliverablePostalCodeError &&
                  deliverablePostalCodeError !== "This field is required." && (
                    <div className="SignupForm-field-error-text mt-1">
                      <span className="">{deliverablePostalCodeError}</span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DeliverableAddressSection;
