import React, { useState } from "react";
import TabV2Title from "./TabV2Title.js";
import SwipeableViews from "react-swipeable-views";
import "./TabsV2Styles/TabsV2Styles.scss";

function TabsV2(props) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="col-12 justify-content-center d-flex">
      <div className="row g-0 w-100 justify-self-center">
        <div className="col-12 container-box">
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row gx-0 w-100 bottom">
                <div
                  className="container"
                  style={props.maxWidth ? { maxWidth: props.maxWidth } : null}
                >
                  <div className="col-12">
                    <div className="row g-0 w-100 justify-content-center">
                      {/* <SwipeableViews
                      axis={"x"}
                      index={selectedTab}
                      onChangeIndex={() => {
                        console.log("Tab changed.");
                      }}
                    > */}
                      {props.children.map((item, index) => (
                        // <div
                        //   className={
                        //     "d-flex h-100 w-100" + index !== 0 ? "ps-1" : ""
                        //   }
                        // >
                        <div className="col h-100">
                          <TabV2Title
                            key={index}
                            index={index}
                            setSelectedTab={setSelectedTab}
                            title={item.props.title}
                            selected={item.props.selected}
                            setSelected={item.props.setSelected}
                          />
                        </div>
                      ))}
                      {/* </SwipeableViews> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-12">
                  <SwipeableViews
                    axis={"x"}
                    index={selectedTab}
                    onChangeIndex={() => {
                      if (process.env.REACT_APP_DEBUG === "1") console.log("Tab changed.");
                    }}
                  >
                    {props.children}
                  </SwipeableViews>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TabsV2;
