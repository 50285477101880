import React, { useState, useEffect } from "react";

function BeneficialOwnerQuestion({
  questionNumber,
  questionText,
  answer,
  setAnswer,
  hasBeneficialOwnersError,
}) {
  const [answerYes, setAnswerYes] = useState("");
  const [answerNo, setAnswerNo] = useState("");

  useEffect(() => {
    if (answer !== "") {
      if (answer) {
        setAnswerYes(true);
        setAnswerNo(false);
      } else {
        setAnswerYes(false);
        setAnswerNo(true);
      }
    }
  }, [answer]);

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-2 fade-in-test">
        <span className="qualification-question-text">{questionText}</span>
      </div>
      <div className="row g-0 w-100 mb-2 fade-in-test">
        <div className="col-6">
          <label
            for={"myBOQ" + questionNumber + "YesRadioId"}
            className={"radio"}
          >
            <input
              type={"radio"}
              name={"myBOQ" + questionNumber + "YesRadio"}
              id={"myBOQ" + questionNumber + "YesRadioId"}
              className={"radio__input"}
              checked={answerYes}
              readOnly={true}
              onClick={() => {
                setAnswer(true);
              }}
            />
            <div className="radio__radio"></div>
            <span className="qualification-question-text ms-2">Yes</span>
          </label>
        </div>
        <div className="col-6">
          <label
            for={"myBOQ" + questionNumber + "NoRadioId"}
            className={"radio"}
          >
            <input
              type={"radio"}
              name={"myBOQ" + questionNumber + "NoRadio"}
              id={"myBOQ" + questionNumber + "NoRadioId"}
              className={"radio__input"}
              checked={answerNo}
              readOnly={true}
              onClick={() => {
                setAnswer(false);
              }}
            />
            <div className="radio__radio"></div>
            <span className="qualification-question-text ms-2">No</span>
          </label>
        </div>
      </div>
      {hasBeneficialOwnersError && (
        <div className="row g-0 w-100 mb-3">
          <div
            className="SignupForm-field-error-text"
            style={{
              fontFamily: "Montserrat",
              color: "#D32F2F",
              fontWeight: 500,
              fontSize: "12px",
            }}
          >
            <span className="">{hasBeneficialOwnersError}</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default BeneficialOwnerQuestion;
