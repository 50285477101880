import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import InputField from "../../../../../../../../GlobalComponents/InputField/InputField";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  qualificationDateVal,
  confidentialityAgreedIdVal,
  termsAgreementIdVal,
  emailVal,
  nameVal,
  userIDVal,
  jointQpQ1Yes,
  accessCodeVal,
  accreditedInvestorsAllowed,
  jointNetWorth1M,
  jointIncomeAccredited,
  jointInvestmentProfessional,
  jointAssets5M,
} from "../../../../../../../../atoms";
import {
  isEmailValid,
  isPasswordValid,
  isValidCountry,
} from "../../../../../../../../GlobalFunctions/globalFunctions";
import { Auth } from "aws-amplify";
import PasswordField from "../../../../../../../../GlobalComponents/InputField/PasswordField/PasswordField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import CountryInput from "../../../../../../../../Components/GlobalComponents/CountryInput/CountryInput";
import EntityAccountType from "../../../../../../../../Components/EntityAccountType/EntityAccountType";
import { signupLinkIndividualFeederAccount } from "../../../../../../../../Api/FeederFundSignupApi";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";

function JointSignup({ setActiveSlide }) {
  const firmName = "test";

  const accessCode = useRecoilValue(accessCodeVal);
  const [joint, setJoint] = useState(true);
  const [userId, setUserId] = useRecoilState(userIDVal);
  const individual5mInvestments = useRecoilValue(jointQpQ1Yes);
  const qualificationDate = useRecoilValue(qualificationDateVal);
  const confidentialityAgreementId = useRecoilValue(confidentialityAgreedIdVal);
  const confidentialityAgreementDateSigned = getDateUTC().toISOString();
  const termsAgreementId = useRecoilValue(termsAgreementIdVal);
  const termsAgreementDateSigned = getDateUTC().toISOString();
  const [email, setEmail] = useRecoilState(emailVal);
  const docSignatureName = useRecoilValue(nameVal);
  const [signUpError, setSignUpError] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [secFirstNameError, setSecFirstNameError] = useState("");
  const [secLastNameError, setSecLastNameError] = useState("");
  const [secEmailError, setSecEmailError] = useState("");

  const [secFirstName, setSecFirstName] = useState("");
  const [secLastName, setSecLastName] = useState("");
  const [secEmail, setSecEmail] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [countryOfResidenceError, setCountryOfResidenceError] = useState("");
  const [isUSCitizen, setIsUSCitizen] = useState("");
  const [isUSCitizenError, setIsUSCitizenError] = useState("");
  const [entityAccountType, setEntityAccountType] = useState("");
  const [entityAccountTypeError, setEntityAccountTypeError] = useState("");

  const accreditedAllowed = useRecoilValue(accreditedInvestorsAllowed);
  const netWorth1M = useRecoilValue(jointNetWorth1M);
  const incomeAccredited = useRecoilValue(jointIncomeAccredited);
  const investmentProfessional = useRecoilValue(jointInvestmentProfessional);
  const assets5M = useRecoilValue(jointAssets5M);

  let history = useHistory();

  useEffect(() => {
    setFirstNameError("");
  }, [firstName]);

  useEffect(() => {
    setLastNameError("");
  }, [lastName]);

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    setConfirmPasswordError("");
  }, [confirmPassword]);

  useEffect(() => {
    setPhoneNumberError("");
  }, [phoneNumber]);

  useEffect(() => {
    setCountryOfResidenceError("");
  }, [countryOfResidence]);

  useEffect(() => {
    setIsUSCitizenError("");
  }, [isUSCitizen]);

  const submit = async () => {
    let isDataValid = true;

    const fullName = (
      firstName.toLowerCase() +
      " " +
      lastName.toLowerCase()
    ).trim();

    // if (docSignatureName.toLowerCase() !== fullName.replace(/  +/g, " ")) {
    //   setFirstNameError(
    //     "Name entered does not match the name signed on the confidentiality agreement."
    //   );
    //   setLastNameError(
    //     "Name entered does not match the name signed on the confidentiality agreement."
    //   );
    //   isDataValid = false;
    // } else if (
    //   docSignatureName.toLowerCase() === fullName.replace(/  +/g, " ")
    // ) {
    //   setFirstNameError("");
    //   setLastNameError("");
    // }

    if (lastName.replace(/  +/g, " ").trim().length === 0) {
      setLastNameError("Please enter a last name.");
      isDataValid = false;
    }

    if (firstName.replace(/  +/g, " ").trim().length === 0) {
      console.log(firstName.replace(/  +/g, " ").trim());
      setFirstNameError("Please enter a first name.");
      isDataValid = false;
    }

    if (email.length === 0) {
      setEmailError("Please enter an email address.");
      isDataValid = false;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email address.");
      isDataValid = false;
    }

    if (joint) {
      console.log(isDataValid);
      console.log(secFirstName.replace(/  +/g, " ").trim());
      console.log(secLastName.replace(/  +/g, " ").trim());
      console.log(secEmail.length);

      if (secLastName.replace(/  +/g, " ").trim().length === 0) {
        setSecLastNameError("Please enter a last name.");
        isDataValid = false;
      }

      if (secFirstName.replace(/  +/g, " ").trim().length === 0) {
        setSecFirstNameError("Please enter a first name.");
        isDataValid = false;
      }

      if (secEmail.length === 0) {
        setSecEmailError("Please enter an email address.");
        isDataValid = false;
      } else if (!isEmailValid(secEmail)) {
        setSecEmailError("Please enter a valid email address.");
        isDataValid = false;
      }

      console.log(isDataValid);
    }

    if (password.length === 0) {
      setPasswordError("Please enter a password.");
      isDataValid = false;
    } else if (!isPasswordValid(password)) {
      setPasswordError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character."
      );
      isDataValid = false;
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError("Your passwords must match");
      isDataValid = false;
    }
    if (countryOfResidence === "") {
      setCountryOfResidenceError("Country is required.");
      isDataValid = false;
    } else if (!isValidCountry(countryOfResidence)) {
      isDataValid = false;
      setCountryOfResidenceError(
        "Please select a valid country from the dropdown."
      );
    } else if (countryOfResidence !== "United States") {
      isDataValid = false;
      setCountryOfResidenceError(
        "Sorry, we only support U.S. individuals at this time."
      );
    }

    if (phoneNumber.length === 0) {
      setPhoneNumberError("Please enter a phone number.");
      isDataValid = false;
    }

    if (!isDataValid) {
      return;
    } else {
      setLoading(true);
      try {
        //this part needs to change to hit the new signup endpoint configured for feeder association
        const cognitoUser = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
          },
          clientMetadata: { firm: firmName },
        });
        setUserId(cognitoUser.userSub);

        var newUser = {};

        if (accreditedAllowed === false) {
          newUser = {
            access_code: accessCode,
            username: cognitoUser.userSub,
            email: email.toLowerCase(),
            first_name: firstName.replace(/  +/g, " ").trim(),
            last_name: lastName.replace(/  +/g, " ").trim(),
            phone_number: phoneNumber,
            country: countryOfResidence,
            signer_name: docSignatureName,
            // confidentiality_agreement_id: confidentialityAgreementId,
            // confidentiality_agreement_date_signed:
            //   confidentialityAgreementDateSigned,
            terms_of_agreement_id: termsAgreementId,
            terms_of_agreement_date_signed: termsAgreementDateSigned,
            account_type: !joint ? "IND" : "JOINT",
            individual_q1_qp_5m_investments: individual5mInvestments,
            qualification_date: qualificationDate,
            ...(joint && {
              joint_email: secEmail,
              joint_first: secFirstName,
              joint_last: secLastName,
              joint_account_type: entityAccountType,
            }),
            ...(countryOfResidence === "United States" && {
              is_us_citizen: true,
            }),
          };
        } else {
          newUser = {
            accredited_signup: true,
            access_code: accessCode,
            username: cognitoUser.userSub,
            email: email.toLowerCase(),
            first_name: firstName.replace(/  +/g, " ").trim(),
            last_name: lastName.replace(/  +/g, " ").trim(),
            phone_number: phoneNumber,
            country: countryOfResidence,
            signer_name: docSignatureName,
            // confidentiality_agreement_id: confidentialityAgreementId,
            // confidentiality_agreement_date_signed:
            //   confidentialityAgreementDateSigned,
            terms_of_agreement_id: termsAgreementId,
            terms_of_agreement_date_signed: termsAgreementDateSigned,
            account_type: !joint ? "IND" : "JOINT",
            individual_q1_qp_5m_investments: assets5M,
            joint_net_worth_1m: netWorth1M,
            joint_income_accredited: incomeAccredited,
            joint_investment_professional: investmentProfessional,
            joint_assets_5m: assets5M,
            qualification_date: qualificationDate,
            ...(joint && {
              joint_email: secEmail,
              joint_first: secFirstName,
              joint_last: secLastName,
              joint_account_type: entityAccountType,
            }),
            ...(countryOfResidence === "United States" && {
              is_us_citizen: true,
            }),
          };
        }

        // const newUser = {
        //   access_code: accessCode,
        //   username: cognitoUser.userSub,
        //   email: email.toLowerCase(),
        //   first_name: firstName.replace(/  +/g, " ").trim(),
        //   last_name: lastName.replace(/  +/g, " ").trim(),
        //   phone_number: phoneNumber,
        //   country: countryOfResidence,
        //   signer_name: docSignatureName,
        //   confidentiality_agreement_id: confidentialityAgreementId,
        //   confidentiality_agreement_date_signed:
        //     confidentialityAgreementDateSigned,
        //   terms_of_agreement_id: termsAgreementId,
        //   terms_of_agreement_date_signed: termsAgreementDateSigned,
        //   account_type: !joint ? "IND" : "JOINT",
        //   individual_q1_qp_5m_investments: individual5mInvestments,
        //   qualification_date: qualificationDate,
        //   ...(joint && {
        //     joint_email: secEmail,
        //     joint_first: secFirstName,
        //     joint_last: secLastName,
        //     joint_account_type: entityAccountType,
        //   }),
        //   ...(countryOfResidence === "United States" && {
        //     is_us_citizen: true,
        //   }),
        // };
        await signupLinkIndividualFeederAccount(newUser);
        // await signupUser(newUser);
        setLoading(false);
        setActiveSlide((curSlide) => curSlide + 1);
      } catch (err) {
        setLoading(false);
        setSignUpError("Sign up failed. Please try again.");
        if (process.env.DEBUG) console.error(err);
      }
      //   navigation.next();
    }
  };

  return (
    <div
      className="container-fluid h-100 align-items-center g-0 ms-5 pe-3"
      style={{ overflow: "auto" }}
    >
      <div className="row g-0 h-100 align-items-center w-100">
        <div className="col-11">
          <div className="row g-0 w-100 mb-4 mt-3">
            <div className="col-12">
              <div
                onClick={() => setActiveSlide((curSlide) => curSlide - 1)}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-xxl-6 col-xl-6 col-lg-10 col-12">
              <span className="SignupForm-subheader-text">
                Please fill out all the fields
              </span>
            </div>
          </div>

          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    First Name
                  </span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">Last Name</span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <InputField
                    placeholder={"Nick"}
                    error={firstNameError}
                    fieldValueChange={setFirstName}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    placeholder={"Black"}
                    error={lastNameError}
                    fieldValueChange={setLastName}
                  />
                </div>
              </div>
              <div className="row gx-3 w-100">
                <div className="col-6">
                  {firstNameError && (
                    <span className="SignupForm-field-error-text">
                      {firstNameError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {lastNameError && (
                    <span className="SignupForm-field-error-text">
                      {lastNameError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0 w-100 mb-3 pe-3">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-12">
                  <span className="SignupForm-field-title-text">Email</span>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col-12">
                  <InputField
                    placeholder={"example@investwithaqua.com"}
                    error={emailError}
                    fieldValueChange={setEmail}
                    fieldInitialVal={email}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-12">
                  {emailError && (
                    <span className="SignupForm-field-error-text">
                      {emailError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {joint && (
            <>
              {" "}
              <div className="row g-0 w-100 mb-3">
                <div className="col-12">
                  <div className="row gx-3 w-100 mb-1">
                    <div className="col-6">
                      <span className="SignupForm-field-title-text">
                        Secondary Accountholder First Name
                      </span>
                    </div>
                    <div className="col-6">
                      <span className="SignupForm-field-title-text">
                        Secondary Accountholder Last Name
                      </span>
                    </div>
                  </div>
                  <div className="row gx-3 w-100 mb-1">
                    <div className="col-6">
                      <InputField
                        placeholder={"Nick"}
                        error={secFirstNameError}
                        fieldValueChange={setSecFirstName}
                      />
                    </div>
                    <div className="col-6">
                      <InputField
                        placeholder={"Black"}
                        error={secLastNameError}
                        fieldValueChange={setSecLastName}
                      />
                    </div>
                  </div>
                  <div className="row gx-3 w-100">
                    <div className="col-6">
                      {secFirstNameError && (
                        <span className="SignupForm-field-error-text">
                          {secFirstNameError}
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      {secLastNameError && (
                        <span className="SignupForm-field-error-text">
                          {secLastNameError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col-12">
                  <div className="row gx-3 w-100 mb-1">
                    <div className="col-6">
                      <span className="SignupForm-field-title-text">
                        Joint Account Type
                      </span>
                    </div>
                  </div>
                  <div className="row gx-3 w-100 mb-1">
                    <div className="col-12">
                      {
                        <EntityAccountType
                          value={entityAccountType}
                          setValue={setEntityAccountType}
                          placeholder={"Choose a joint account type"}
                          backgroundColor={"#f7f7fa"}
                          joint={joint}
                        />
                      }
                    </div>
                  </div>
                  <div className="row gx-3 w-100">
                    <div className="col-6">
                      {entityAccountTypeError && (
                        <span className="SignupForm-field-error-text">
                          {entityAccountTypeError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {joint && (
            <div className="row g-0 w-100 mb-3 pe-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-1">
                  <div className="col-12">
                    <span className="SignupForm-field-title-text">
                      Secondary Accountholder Email
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-12">
                    <InputField
                      placeholder={"example@investwithaqua.com"}
                      error={secEmailError}
                      fieldValueChange={setSecEmail}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100">
                  <div className="col-12">
                    {secEmailError && (
                      <span className="SignupForm-field-error-text">
                        {secEmailError}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">Password</span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Confirm Password
                  </span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <PasswordField
                    placeholder={"Password"}
                    error={passwordError}
                    fieldValueChange={setPassword}
                  />
                </div>
                <div className="col-6">
                  <PasswordField
                    placeholder={"Confirm Password"}
                    error={confirmPasswordError}
                    fieldValueChange={setConfirmPassword}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-6">
                  {passwordError && (
                    <span className="SignupForm-field-error-text">
                      {passwordError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {confirmPasswordError && (
                    <span className="SignupForm-field-error-text">
                      {confirmPasswordError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Phone Number
                  </span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Country of Residence
                  </span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6 outer-class">
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber(phone)}
                    style={{ width: "100%", height: "inherit" }}
                    containerStyle={{ backgroundColor: "#000000" }}
                  />
                </div>
                <div className="col-6">
                  <CountryInput
                    value={countryOfResidence}
                    setValue={setCountryOfResidence}
                    placeholder={"Choose a country"}
                    backgroundColor={"#f7f7fa"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-6">
                  {phoneNumberError && (
                    <span className="SignupForm-field-error-text">
                      {phoneNumberError}
                    </span>
                  )}
                  {signUpError && (
                    <span className="SignupForm-field-error-text">
                      {signUpError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {countryOfResidenceError && (
                    <span className="SignupForm-field-error-text">
                      {countryOfResidenceError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* {countryOfResidence === "United States" && (
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-3">
                  <span className="SignupForm-field-title-text">
                    Are you a U.S. citizen?
                  </span>
                </div>
                <div className="row g-0 w-100 mb-3">
                  <div className="col-11">
                    <div className="row g-0 w-100">
                      <div className="col-6">
                        <label for={"myUSCitizenRadioId"} className={"radio"}>
                          <input
                            type={"radio"}
                            name={"myUSCitizenRadioField"}
                            id={"myUSCitizenRadioId"}
                            className={"radio__input"}
                            checked={isUSCitizen === true}
                            readOnly={true}
                            onClick={() => {
                              setIsUSCitizen(true);
                            }}
                          />
                          <div className="radio__radio"></div>
                          <span className="selection-text ms-3">Yes</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <label for={"myUSCitizenRadioId2"} className={"radio"}>
                          <input
                            type={"radio"}
                            name={"myUSCitizenRadioField2"}
                            id={"myUSCitizenRadioId2"}
                            className={"radio__input"}
                            checked={isUSCitizen === false}
                            readOnly={true}
                            onClick={() => {
                              setIsUSCitizen(false);
                            }}
                          />
                          <div className="radio__radio"></div>
                          <span className="selection-text ms-3">No</span>
                        </label>
                      </div>
                    </div>
                    <div className="row g-0 w-100">
                      {isUSCitizenError && (
                        <span className="SignupForm-field-error-text">
                          {isUSCitizenError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div className="row g-0 w-100 mb-5 pe-3">
            <div className="col-12">
              <div className="row g-0 w-100">
                <div className="col ms-0 me-auto">
                  <div className="d-flex">
                    <button
                      type="button"
                      class="btn btn-lg btn-class"
                      onClick={async () => submit()}
                      disabled={loading}
                    >
                      {loading && (
                        <div className="row g-0 w-100">
                          <div className="col d-flex align-items-center">
                            <span className="justify-self-start align-self-center">
                              Sign Up
                            </span>
                            <span
                              class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </div>
                      )}
                      {!loading && <span>Sign Up</span>}
                    </button>
                  </div>
                </div>
                {/* <div className="col-4 align-self-end text-end">
                        <span
                          className="sign-in-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/login")}
                        >
                          <u>or Sign In</u>
                        </span>
                      </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default JointSignup;
