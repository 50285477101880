import React, { useState, useEffect } from "react";
import DocumentTypeFilter from "../../../../Components/GlobalComponents/DocumentTypeFilter/DocumentTypeFilter";
import MobileDocumentCard from "./MobileDocumentCard/MobileDocumentCard";
import "./MobileDocumentsSectionStyles/MobileDocumentsSectionStyles.css";

function MobileDocumentsSection({
  documentType,
  setDocumentType,
  accountStatements,
  closingDocuments,
  taxDocuments,
}) {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    if (documentType === "Statements") {
      setDocuments(accountStatements);
    } else if (documentType === "Closing") {
      setDocuments(closingDocuments);
    } else if (documentType === "Tax") {
      setDocuments(taxDocuments);
    }
  }, [documentType]);

  return (
    <div className="row g-0 w-100 mb-2 justify-content-center">
      <div className="col-md-9 col-12">
        <div className="row g-0 w-100 mb-3">
          <DocumentTypeFilter
            text={documentType}
            setText={setDocumentType}
            defaultKey={documentType}
          />
        </div>
        <div className="row g-0 w-100">
          <div className="col-12">
            {documents.map((document) => {
              return (
                <MobileDocumentCard
                  key={document.id}
                  documentName={document.documentName}
                  documentType={document.documentType}
                  documentDate={document.documentDate}
                  documentLink={document.documentLink}
                  accountName={document.accountName}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default MobileDocumentsSection;
