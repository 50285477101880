import React, { useState } from "react";
import "./Styles/Styles.scss";
import AlertSnackBar from "../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import MuiButton from "../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";

function VerifyCitizenship({ setActiveSlide }) {
  const [
    citizenshipQuestionNotAnsweredError,
    setCitizenshipQuestionNotAnsweredError,
  ] = useState(false);

  const [q1No, setQ1No] = useState(false);
  const [q1Yes, setQ1Yes] = useState(false);

  const validateCitizenshipResponse = () => {
    var errors = false;

    if (!q1No && !q1Yes) {
      errors = true;
    }

    if (!errors) {
      setActiveSlide((activeSlide) => activeSlide + 1);
    } else {
      setCitizenshipQuestionNotAnsweredError(true);
      return;
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="row g-0 w-100 mb-3">
          <span className="body-text-md-semibold">Are you a US citizen?</span>
        </div>
        <div className="row g-0 w-100 mb-3">
          <div className="col">
            <div className="d-flex">
              <div className="col-6">
                <label for={"myRadioId"} className={"radio"}>
                  <input
                    type={"radio"}
                    name={"myRadioField"}
                    id={"myRadioId"}
                    className={"radio__input"}
                    checked={q1Yes}
                    readOnly={true}
                    onClick={() => {
                      setQ1Yes(true);
                      setQ1No(false);
                    }}
                  />
                  <div className="radio__radio"></div>
                  <span className="selection-text ms-3">Yes</span>
                </label>

                <label for={"myRadioIdQ"} className={"radio"}>
                  <input
                    type={"radio"}
                    name={"myRadioFieldQ"}
                    id={"myRadioIdQ"}
                    className={"radio__input"}
                    checked={q1No}
                    readOnly={true}
                    onClick={() => {
                      setQ1No(true);
                      setQ1Yes(false);
                    }}
                  />
                  <div className="radio__radio"></div>
                  <span className="selection-text ms-3">No</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-0 w-100">
          <div className="col">
            <div className="d-flex">
              <MuiButton
                buttonText={"Submit"}
                onClick={() => validateCitizenshipResponse()}
                size={"medium"}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
      <AlertSnackBar
        snackbarState={citizenshipQuestionNotAnsweredError}
        setSnackbarState={setCitizenshipQuestionNotAnsweredError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must make a selection before proceeding."}
      />
    </React.Fragment>
  );
}
export default VerifyCitizenship;
