import React, { useState, useEffect } from "react";

function IndividualAccountBasicInformationQualificationQuestion({
  showIndividualQualificationSection,
  mountedStyle,
  unmountedStyle,
  questionNumber,
  questionText,
  answer,
  setAnswer,
}) {
  const [answerYes, setAnswerYes] = useState("");
  const [answerNo, setAnswerNo] = useState("");

  useEffect(() => {
    if (answer !== "") {
      if (answer) {
        setAnswerYes(true);
        setAnswerNo(false);
      } else {
        setAnswerYes(false);
        setAnswerNo(true);
      }
    }
  }, [answer]);

  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={
          showIndividualQualificationSection ? mountedStyle : unmountedStyle
        }
      >
        <span className="qualification-question-text">{questionText}</span>
      </div>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={
          showIndividualQualificationSection ? mountedStyle : unmountedStyle
        }
      >
        <div className="col-6">
          <label
            for={"myIndividualQualificationQ" + questionNumber + "YesRadioId"}
            className={"radio"}
          >
            <input
              type={"radio"}
              name={"myIndividualQualificationQ" + questionNumber + "YesRadio"}
              id={"myIndividualQualificationQ" + questionNumber + "YesRadioId"}
              className={"radio__input"}
              checked={answerYes}
              readOnly={true}
              onClick={() => {
                setAnswer(true);
              }}
            />
            <div className="radio__radio"></div>
            <span className="qualification-question-text ms-2">Yes</span>
          </label>
        </div>
        <div className="col-6">
          <label
            for={"myIndividualQualificationQ" + questionNumber + "NoRadioId"}
            className={"radio"}
          >
            <input
              type={"radio"}
              name={"myIndividualQualificationQ" + questionNumber + "NoRadio"}
              id={"myIndividualQualificationQ" + questionNumber + "NoRadioId"}
              className={"radio__input"}
              checked={answerNo}
              readOnly={true}
              onClick={() => {
                setAnswer(false);
              }}
            />
            <div className="radio__radio"></div>
            <span className="qualification-question-text ms-2">No</span>
          </label>
        </div>
      </div>
    </React.Fragment>
  );
}
export default IndividualAccountBasicInformationQualificationQuestion;
