import React from "react";

function DownArrowCircleButton({selected, setSelected}) {
    const rotateIcon = () => {
        setSelected(!selected);
    }
    return (
        <React.Fragment>
            {selected ? (<div className="d-flex align-items-center justify-content-end h-100">
                    <img src="https://d1swyfveypj49y.cloudfront.net/DownArrowCircleBorderSelected.svg" alt="Down arrow circle border button" className="me-3" onClick={() => rotateIcon()}></img>
                </div>) : (<div className="d-flex align-items-center justify-content-end h-100">
                    <img src="https://d1swyfveypj49y.cloudfront.net/DownArrowCircleBorder.svg" alt="Down arrow circle border button" className="me-3" onClick={() => rotateIcon()}></img>
                </div>)}
            {/* <div className="d-flex align-items-center justify-content-end h-100">
                    <img src="https://d1swyfveypj49y.cloudfront.net/DownArrowCircleBorder.svg" alt="Down arrow circle border button" className="me-3" onClick={() => rotateIcon()}></img>
                </div> */}
            {/* {selected ? (
                <div className="d-flex align-items-center justify-content-end h-100">
                    <img src="https://d1swyfveypj49y.cloudfront.net/DownArrowCircleBorderSelected.svg" alt="Down arrow circle border button" className="me-3" onClick={() => rotateIcon()}></img>
                </div>) : (
                <div className="d-flex align-items-center justify-content-end h-100">
                    <img src="https://d1swyfveypj49y.cloudfront.net/DownArrowCircleBorder.svg" alt="Down arrow circle border button" className="me-3"> onClick={() => rotateIcon()}</img>
                </div>
                ) } */}
                {/* <span>Something to show here.</span> */}
        </React.Fragment>
    )
}
export default DownArrowCircleButton;