import React from "react";
import "./Styles/Styles.scss";
import IndividualQualification from "./IndividualQualification/IndividualQualification";
import JointQualification from "./JointQualification/JointQualification";
import SMLLCQualification from "./SMLLCQualification/SMLLCQualification";
import TrustQualification from "./TrustQualification/TrustQualification";
import EntityQualification from "./EntityQualification/EntityQualification";
import { useRecoilValue } from "recoil";
import {
  accountTypeIndividualVal,
  accountTypeSMLLCVal,
  accountTypeJointVal,
  accountTypeTrustVal,
  accountTypeEntityVal,
  accreditedInvestorsAllowed,
} from "../../../../../../../atoms";
import AccreditationQualification from "./IndividualQualification/AccreditationQualification";
import JointAccreditationQualification from "./JointQualification/JointAccreditationQualification";
import SMLLCAccreditationQualification from "./SMLLCQualification/SMLLCAccreditationQualification";
import TrustAccreditationQualification from "./TrustQualification/TrustAccreditationQualification";
import EntityAccreditedQualification from "./EntityQualification/EntityAccreditedQualification";

function AccountQualification({ setActiveSlide }) {
  const accountTypeIndividual = useRecoilValue(accountTypeIndividualVal);
  const accountTypeSMLLC = useRecoilValue(accountTypeSMLLCVal);
  const accountTypeJoint = useRecoilValue(accountTypeJointVal);
  const accountTypeTrust = useRecoilValue(accountTypeTrustVal);
  const accountTypeEntity = useRecoilValue(accountTypeEntityVal);
  const accreditedAllowed = useRecoilValue(accreditedInvestorsAllowed);
  return (
    <React.Fragment>
      {accountTypeIndividual === true ? (
        accreditedAllowed === true ? (
          <AccreditationQualification setActiveSlide={setActiveSlide} />
        ) : (
          <IndividualQualification setActiveSlide={setActiveSlide} />
        )
      ) : accountTypeJoint === true ? (
        accreditedAllowed === true ? (
          <JointAccreditationQualification setActiveSlide={setActiveSlide} />
        ) : (
          <JointQualification setActiveSlide={setActiveSlide} />
        )
      ) : accountTypeSMLLC === true ? (
        accreditedAllowed === true ? (
          <SMLLCAccreditationQualification setActiveSlide={setActiveSlide} />
        ) : (
          <SMLLCQualification setActiveSlide={setActiveSlide} />
        )
      ) : accountTypeTrust === true ? (
        accreditedAllowed === true ? (
          <TrustAccreditationQualification setActiveSlide={setActiveSlide} />
        ) : (
          <TrustQualification setActiveSlide={setActiveSlide} />
        )
      ) : accountTypeEntity === true ? (
        accreditedAllowed === true ? (
          <EntityAccreditedQualification setActiveSlide={setActiveSlide} />
        ) : (
          <EntityQualification setActiveSlide={setActiveSlide} />
        )
      ) : null}
    </React.Fragment>
  );
}
export default AccountQualification;
