import React from "react";
import { FormControlLabel, Radio } from "@material-ui/core";
import "./Styles/styles.scss";

function RadioInput({ checked, radioValue, setValue, label, index }) {
  return (
    <div>
      <label for={"radioId" + index} className={"radio"}>
        <input
          type={"radio"}
          name={"radioField" + index}
          id={"radioId" + index}
          className={"radio__input"}
          checked={checked}
          readOnly={true}
          onClick={() => {
            console.log("Clicked the button");
            console.log("Radio value is: " + radioValue);
            setValue(radioValue);
          }}
        />
        <div className="radio__radio"></div>
        <span className="body-text-xs-semibold ms-3">{label}</span>
      </label>
    </div>
  );
}
export default RadioInput;
