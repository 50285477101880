import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Styles/Styles.scss";
import { verifySignupUser } from "../../../../../../../Api/FeederFundSignupApi";
import TextInputField from "../../../../../../../Components/GlobalComponents/TextInputField/TextInputField";
import AlertSnackBar from "../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import MuiButton from "../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import { isEmailValid } from "../../../../../../../GlobalFunctions/globalFunctions";
import PasswordInputField from "../../../../../../../GlobalComponents/PasswordInputField/PasswordInputField";
import { Auth } from "aws-amplify";
import {
  linkFeederUser,
  wmUserCheck,
} from "../../../../../../../Api/FeederFundSignupApi";
import { AccessCodeContext } from "../../../../../../../GlobalContexts/AccesCodeContext";
import { useRecoilState, useRecoilValue } from "recoil";
import { emailVal } from "../../../../../../../atoms";
import { accessCodeVal } from "../../../../../../../atoms";
import { UserContext } from "../../../../../../../GlobalContexts/UserContext";
import { updateIdentityId } from "../../../../../../../Api/ProfileApi";
import { getInvestorProfile } from "../../../../../../../Api/ProfileApi";
import { getInvestorSummary } from "../../../../../../../Api/ProfileApi";
import { getAccountList } from "../../../../../../../Api/ProfileApi";

function VerifySignupEmail({ setActiveSlide }) {
  const [email, setEmail] = useRecoilState(emailVal);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [noEmailError, setNoEmailError] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [erMsg, setErMsg] = useState(null);
  const [wm, setWm] = useState(false);

  const [feederLinkSuccessful, setFeederLinkSuccessful] = useState(false);
  let history = useHistory();

  //   const { accessCodeValue } = useContext(AccessCodeContext);
  const accessCodeValue = useRecoilValue(accessCodeVal);

  const { setUser } = useContext(UserContext);

  const verifyEmail = async () => {
    var errors = false;

    if (email === "") {
      errors = true;
      setNoEmailError(true);
    } else if (!isEmailValid(email)) {
      errors = true;
      setEmailError("Please enter a valid email");
    }

    if (!errors) {
      try {
        const isWm = await wmUserCheck(email);
        console.log(isWm);
        if (isWm.data.detail === true) {
          setWm(true);
          return;
        }
        const response = await verifySignupUser(email.toString().toLowerCase());
        var msg = null;
        msg = response.data.msg;

        if (msg === "You may proceed with signup.") {
          setActiveSlide((activeSlide) => activeSlide + 1);
        } else if (msg === "Please link your account.") {
          //   setActiveSlide((activeSlide) => activeSlide + 1);
          setShowSignIn(true);
        }

        console.log("Response is: " + JSON.stringify(response.data));
      } catch (err) {
        console.log(err);
      }
    } else {
      return;
    }
  };

  const authenticateAndLink = async () => {
    var localErrors = false;

    if (password === "") {
      localErrors = true;
      setPasswordError("This field is required.");
    }

    if (!localErrors) {
      try {
        const logUser = await Auth.signIn(
          email.toString().toLowerCase(),
          password
        );

        console.log("Access code is: " + accessCodeValue);

        const identityId = await Auth.currentCredentials();
        await updateIdentityId(identityId.identityId);
        const isAuthenticated = true;

        await linkFeederUser(accessCodeValue);

        const userID = email.toString().toLowerCase();
        const userProfile = await getInvestorProfile(); // profile-pii
        const investorSummary = await getInvestorSummary();
        const accountList = await getAccountList();
        var investorPrimaryAddressLine1 = "";
        var investorPrimaryAddressLine2 = "";
        var investorPrimaryCity = "";
        var investorPrimaryState = "";
        var investorPrimaryCountry = "";
        var investorPrimaryPostalCode = "";
        var investorPrimaryAddressType = "";

        var investorSecondaryAddressLine1 = "";
        var investorSecondaryAddressLine2 = "";
        var investorSecondaryCity = "";
        var investorSecondaryState = "";
        var investorSecondaryCountry = "";
        var investorSecondaryPostalCode = "";
        var investorSecondaryAddressType = "";

        if (userProfile.data[0].investor_addresses.length === 1) {
          investorPrimaryAddressLine1 =
            userProfile.data[0].investor_addresses[0].address_line_1;
          investorPrimaryAddressLine2 =
            userProfile.data[0].investor_addresses[0].address_line_2;
          investorPrimaryCity = userProfile.data[0].investor_addresses[0].city;
          investorPrimaryState =
            userProfile.data[0].investor_addresses[0].state;
          investorPrimaryCountry =
            userProfile.data[0].investor_addresses[0].country;
          investorPrimaryPostalCode =
            userProfile.data[0].investor_addresses[0].postal_code;
          investorPrimaryAddressType =
            userProfile.data[0].investor_addresses[0].address_type;
        } else if (userProfile.data[0].investor_addresses.length > 1) {
          userProfile.data[0].investor_addresses.forEach((investor_address) => {
            if (investor_address.address_type === "M") {
              investorSecondaryAddressLine1 = investor_address.address_line_1;
              investorSecondaryAddressLine2 = investor_address.address_line_2;
              investorSecondaryCity = investor_address.city;
              investorSecondaryState = investor_address.state;
              investorSecondaryCountry = investor_address.country;
              investorSecondaryPostalCode = investor_address.postal_code;
              investorSecondaryAddressType = investor_address.address_type;
            } else if (
              investor_address.address_type === "R" ||
              investor_address === "RM"
            ) {
              investorPrimaryAddressLine1 = investor_address.address_line_1;
              investorPrimaryAddressLine2 = investor_address.address_line_2;
              investorPrimaryCity = investor_address.city;
              investorPrimaryState = investor_address.state;
              investorPrimaryCountry = investor_address.country;
              investorPrimaryPostalCode = investor_address.postal_code;
              investorPrimaryAddressType = investor_address.address_type;
            }
          });
        }

        if (userProfile.data[0].investor_pii[0].citizenship === null) {
          setLoginError(true);
          setErMsg("Please provide citizenship information.");
          return;

          //setLoginErrorMessage("Please contact support.");
        } else if (
          userProfile.data[0].investor_pii[0].citizenship !== "United States"
        ) {
          setErMsg("Feeder aaccess restricted to US citizens.");
          setLoginError(true);
          return;
          //setLoginErrorMessage("Please contact support.");
        }

        setUser({
          id: userID,
          userProfileData: userProfile.data[0],
          accountListData: accountList.data,
          selectedAccountID:
            accountList && accountList.data.length !== 0
              ? accountList.data[0].id
              : "",
          investorSummaryData: investorSummary.data[0],
          investorPrimaryAddressLine1: investorPrimaryAddressLine1,
          investorPrimaryAddressLine2: investorPrimaryAddressLine2,
          investorPrimaryCity: investorPrimaryCity,
          investorPrimaryState: investorPrimaryState,
          investorPrimaryCountry: investorPrimaryCountry,
          investorPrimaryPostalCode: investorPrimaryPostalCode,
          investorPrimaryAddressType: investorPrimaryAddressType,
          investorSecondaryAddressLine1: investorSecondaryAddressLine1,
          investorSecondaryAddressLine2: investorSecondaryAddressLine2,
          investorSecondaryCity: investorSecondaryCity,
          investorSecondaryState: investorSecondaryState,
          investorSecondaryCountry: investorSecondaryCountry,
          investorSecondaryPostalCode: investorSecondaryPostalCode,
          investorSecondaryAddressType: investorSecondaryAddressType,
          isAuthenticated: isAuthenticated,
        });

        history.push({
          pathname: "/invest",
          state: { accountLinked: true },
        });
      } catch (err) {
        setLoginError(true);
        console.log(err);
      }
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      {showSignIn == false ? (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <span className="body-text-md-semibold">
              Please enter your email below:
            </span>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col">
              <div className="d-flex">
                <TextInputField
                  value={email}
                  setValue={setEmail}
                  valueError={emailError}
                  setValueError={setEmailError}
                  required={true}
                  label={"Email"}
                  size={"small"}
                  shrink={true}
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100">
            <div className="col">
              <div className="d-flex">
                <MuiButton
                  buttonText={"Submit"}
                  onClick={() => verifyEmail()}
                  size={"medium"}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <span className="body-text-md-semibold">
              Please authenticate yourself to link your existing Aqua account
              and gain access to this platform:
            </span>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col">
              <div className="d-flex">
                <TextInputField
                  value={email}
                  setValue={setEmail}
                  valueError={emailError}
                  setValueError={setEmailError}
                  required={true}
                  label={"Email"}
                  size={"small"}
                  shrink={true}
                  fullWidth={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col">
              <div className="d-flex">
                <PasswordInputField
                  value={password}
                  setValue={setPassword}
                  valueError={passwordError}
                  setValueError={setPasswordError}
                  label={"Password"}
                  required={true}
                  fullWidth={true}
                  shrink={true}
                  size={"small"}
                  id={"password-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100">
            <div className="col">
              <div className="d-flex">
                <MuiButton
                  buttonText={"Authenticate and Link Account"}
                  onClick={() => authenticateAndLink()}
                  size={"medium"}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <AlertSnackBar
        snackbarState={noEmailError}
        setSnackbarState={setNoEmailError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must provide an email."}
      />
      <AlertSnackBar
        snackbarState={wm}
        setSnackbarState={setWm}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Wealth management users cannot sign up for feeders through individual platform."
        }
      />
      <AlertSnackBar
        snackbarState={accountExists}
        setSnackbarState={setAccountExists}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "You already have an Aqua account. Please sign in to link your account and gain access to this platform."
        }
      />
      <AlertSnackBar
        snackbarState={loginError}
        setSnackbarState={setLoginError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          erMsg ||
          "There was an error authenticating you. Please provide valid credentials."
        }
      />
      {/* <AlertSnackBar
        snackbarState={feederLinkSuccessful}
        setSnackbarState={setFeederLinkSuccessful}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"success"}
        message={"You have successfully linked your account."}
      /> */}
    </React.Fragment>
  );
}
export default VerifySignupEmail;
