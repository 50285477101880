import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Button, Space } from "antd";
import { Select } from "antd";
import CheckBox from "../CheckBox.js";
import "./StageFilterStyles/StageFilterStyles.css";
import "antd/dist/antd.css";

function StageFilter({ text, setText, defaultKey }) {
  const menu = (
    <Menu
      style={{ width: "100%", paddingTop: 0, paddingBottom: 0 }}
      selectable
      onClick={(e) => setText(e.key)}
      defaultSelectedKeys={defaultKey}
    >
      <Menu.Item key={"Upcoming"} className="h-100">
        <span className="dropdown-text-style">Upcoming</span>
      </Menu.Item>
      <Menu.Item key={"Fundraising"} className="h-100">
        <span className="dropdown-text-style">Fundraising</span>
      </Menu.Item>
      <Menu.Item key={"Pending Closing"} className="h-100">
        <span className="dropdown-text-style">Pending Closing</span>
      </Menu.Item>
      <Menu.Item key={"Closed"} className="h-100">
        <span className="dropdown-text-style">Closed</span>
      </Menu.Item>
      <Menu.Item key={"All"} className="h-100">
        <span className="dropdown-text-style">All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Button
        style={{
          backgroundColor: "#ffffff !important",
          paddingLeft: 0,
          paddingRight: 0,
        }}
        className="d-flex ant-btn-style"
      >
        <div className="row g-0 h-100 w-100 align-items-center">
          <div className="col-10 d-flex h-100 align-items-center justify-content-center right-border-style text-break">
            <span className="align-text-center justify-text-center ms-2 my-custom-input-text ms-1 me-1 text-wrap">
              {"Stage: " + text}
            </span>
          </div>
          <div className="col-2 h-100 d-flex justify-self-end align-items-center justify-content-center">
            <img src="https://d1swyfveypj49y.cloudfront.net/PortfolioArrow.svg" alt="portfolio-arrow"></img>
          </div>
        </div>
      </Button>
    </Dropdown>
  );
}
export default StageFilter;
