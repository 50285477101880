import axios from "axios";
import { HOST } from "../host-config";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders";

export const verifyFeederSignupAccess = async () => {
  return axios.get(HOST + "/verify-feeder-signup-access");
};

export const verifyMagicCode = async (code) => {
  let bodyParams = {
    code: code,
  };
  return await axios.post(HOST + "/verify-magic-feeder-code", bodyParams);
  // await axios.post(HOST + "/signup/", payload);
};

export const verifySignupUser = async (email) => {
  let bodyParams = {
    email: email,
  };
  return await axios.post(HOST + "/verify-signup-user", bodyParams);
};

export const linkFeederUser = async (accessCode = null) => {
  const config = await getRequestHeaders();

  const params = {
    access_code: accessCode,
  };

  return axios.post(HOST + "/link-feeder-user/", params, config);
};

export const signupLinkIndividualFeederAccount = async (payload) => {
  // const config = await getRequestHeaders();

  return axios.post(HOST + "/signup-link-individual-feeder-account/", payload);
};

export const getAccreditedInvestorPermissions = async () => {
  return axios.get(HOST + "/get-accredited-investor-permissions");
};

export const wmUserCheck = async (email) => {
  return axios.get(HOST + `/wm-user-check?email=${encodeURIComponent(email)}`);
};
