import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

export default function GetFundDocs(fund, deckOnly) {
  const [fundDocs, setFundDocs] = useState([]);

  useEffect(() => {
    if (fund !== null && fund !== undefined) {

      async function getFundDocs() {
        if (
          fund.fundDocs !== [] &&
          fund.fundDocs !== null &&
          fund.fundDocs !== undefined
        ) {
          const deckDoc = {
            id: "deck",
            name: "Fund Deck",
            s3_document_link: fund.deckLink,
            display_document: true,
          };

          const unprocessedDocs = deckOnly ? [deckDoc] : fund.fundDocs;
          unprocessedDocs.map((doc) => {
              setFundDocs((prevState) => [
                ...prevState,
                {
                  id: doc.id,
                  documentName: doc.name,
                  documentLink: doc.s3_document_link,
                  display: doc.display_document,
                },
              ]);
            }
          );
        }
      }
      getFundDocs();
    }
  }, [fund]);

  return { fundDocs };
}
