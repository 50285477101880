import React from "react";
import "./PhoneInvestmentCardDetailedInvestmentSectionStyles/PhoneInvestmentCardDetailedInvestmentSectionStyles.css";
import * as dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");

function PhoneInvestmentCardDetailedInvestmentSection({
  selected,
  investment,
}) {
  function fundStrategyFormatter(strategy) {
    let stageMap = {
      STRUCTURED_CREDIT: "Private Credit",
      CLO_MANAGEMENT: "CLO Management",
      DIRECT_ACCESS: "Direct Access",
      PRIVATE_EQUITY: "Private Equity",
      CO_INVESTMENT: "Co-Investment",
      FUND_OF_FUNDS: "Fund of Funds",
    };
    return stageMap[strategy];
  }

  return (
    <React.Fragment>
      {selected && (
        <div className="row g-0 w-100 mb-4">
          <div className="col-12 detailed-investment-information-container">
            <div className="row g-0 w-100 mt-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-1">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Firm Name:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {investment ? investment.firmName : ""}
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Strategy:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {investment
                        ? fundStrategyFormatter(investment.strategy)
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Closing Date:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {investment
                        ? dayjs
                            .unix(investment.fundClosingDate)
                            .format("MMM D, YYYY")
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Target Fund Length:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {"8 years"}
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Investment Date:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {investment
                        ? dayjs
                            .unix(investment.investmentDate)
                            .format("MMM D, YYYY")
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Last Distribution Date:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {investment.lastDistributionDate === "N/A" || investment.lastDistributionDate === undefined
                        ? "N/A"
                        : dayjs
                            .unix(investment.lastDistributionDate)
                            .format("MMM D, YYYY")}
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 pb-3">
                  <div className="col-6 ps-3 pe-1">
                    <span className="detailed-investment-information-section-header">
                      Last Distribution Amount:{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="detailed-investment-information-section-value">
                      {"$ 0"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default PhoneInvestmentCardDetailedInvestmentSection;
