import { useState, useEffect } from "react";
import {
  verifyFeederSignupAccess,
  getAccreditedInvestorPermissions,
} from "../Api/FeederFundSignupApi.js";
import { useRecoilState } from "recoil";
import { accreditedInvestorsAllowed } from "../atoms.js";

export const VerifyFeederSignupAccess = (time, setLoading) => {
  const [feederSignupAccess, setFeederSignupAccess] = useState(null);

  useEffect(() => {
    async function verifyAccess() {
      console.log("This line is reached.");
      setLoading(true);
      try {
        console.log("Try block is reached.");
        const response = await verifyFeederSignupAccess();
        const feederSignupAccessData = response.data;
        console.log("Response is: " + JSON.stringify(response));

        if (feederSignupAccessData.msg === "OK") {
          setFeederSignupAccess("Allowed");
        } else {
          setFeederSignupAccess("Denied");
        }
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1")
          console.log("There is an error.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
        console.log("Error message is: " + JSON.stringify(err));
      }
      setLoading(false);
    }
    verifyAccess();
  }, [time]);
  return { feederSignupAccess };
};

export const GetAccreditedInvestorPermissions = (time, setLoading) => {
  const [accreditedAllowed, setAccreditedAllowed] = useRecoilState(
    accreditedInvestorsAllowed
  );
  const [domainError, setDomainError] = useState(false);

  useEffect(() => {
    async function getPermissions() {
      setLoading(true);
      try {
        const response = await getAccreditedInvestorPermissions();
        const accreditedInvestorPermissionsData = response.data;
        if (accreditedInvestorPermissionsData.msg === true) {
          setAccreditedAllowed(true);
        } else if (accreditedInvestorPermissionsData.msg === false) {
          setAccreditedAllowed(false);
        } else {
          setDomainError(true);
          throw new Error("Domain is invalid");
        }
        setLoading(false);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1")
          console.log("There is an error.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
        console.log("Error message is: " + JSON.stringify(err));
      }
    }
    getPermissions();
  }, [time]);

  return { accreditedAllowed, domainError };
};
