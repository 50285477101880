import React from "react";
import IndividualAccountBasicInformationQualificationQuestion from "./IndividualAccountBasicInformationQualificationQuestion/IndividualAccountBasicInformationQualificationQuestion";
import IndividualAccountBasicInformationQPModal from "./IndividualAccountBasicInformationQPModal/IndividualAccountBasicInformationQPModal";
import IndividualAccountBasicInformationQualificationErrorSection from "./IndividualAccountBasicInformationQualificationErrorSection/IndividualAccountBasicInformationQualificationErrorSection";

function IndividualAccountBasicInformationQualificationSection({
  showIndividualQualificationSection,
  mountedStyle,
  unmountedStyle,
  accountType,
  individualQualificationQ1,
  setIndividualQualificationQ1,
  individualAccountBasicInformationError,
}) {
  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={
          showIndividualQualificationSection ? mountedStyle : unmountedStyle
        }
      >
        <span className="add-account-step-subheader">
          Qualification Question
          {accountType === "IND" ? "" : "s"}
        </span>
      </div>
      <div
        className="row g-0 w-100 mb-4 fade-in-test"
        style={
          showIndividualQualificationSection ? mountedStyle : unmountedStyle
        }
      >
        <span className="add-account-qualification-subheader pe-1">
          Please confirm your{" "}
          <span style={{ fontWeight: 600 }}>Qualified Purchaser</span> status
        </span>
      </div>
      <IndividualAccountBasicInformationQualificationQuestion
        showIndividualQualificationSection={showIndividualQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={1}
        questionText={
          "Are you a natural person who alone (or together with his/her spouse, if investing jointly) owns not less than $5,000,000 in investments."
        }
        answer={individualQualificationQ1}
        setAnswer={setIndividualQualificationQ1}
      />
      <IndividualAccountBasicInformationQPModal
        showIndividualQualificationSection={showIndividualQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
      <IndividualAccountBasicInformationQualificationErrorSection
        individualAccountBasicInformationError={
          individualAccountBasicInformationError
        }
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
    </React.Fragment>
  );
}
export default IndividualAccountBasicInformationQualificationSection;
