import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import GetWireInstructions from "../../../../Adapters/GetWireInstructions";
import LoadingAnimation from "../../../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation";

function WireInstructions(fund) {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const { wireInstructions } = GetWireInstructions(fund, refresh, setLoading);
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [fundAddress, setFundAddress] = useState("");
  const [abaRoutingNumber, setAbaRoutingNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankContactName, setBankContactName] = useState("");
  const [bankContactEmail, setBankContactEmail] = useState("");
  const [bankContactPhoneNumber, setBankContactPhoneNumber] = useState("");

  useEffect(() => {
    if (wireInstructions !== null && wireInstructions !== undefined) {
      if (Object.keys(wireInstructions).length !== 0) {
        setAccountName(wireInstructions.account_name);
        setAccountNumber(wireInstructions.account_number);
        setFundAddress(wireInstructions.fund_address);
        setAbaRoutingNumber(wireInstructions.aba_routing_number);
        setSwiftCode(wireInstructions.swift_code);
        setBankName(wireInstructions.bank_name);
        setBankAddress(wireInstructions.bank_address);
        setBankContactName(
          wireInstructions.bank_contact !== null &&
            wireInstructions.bank_contact.first_name !== "" &&
            wireInstructions.bank_contact.last_name !== ""
            ? wireInstructions.bank_contact.first_name +
                " " +
                wireInstructions.bank_contact.last_name
            : ""
        );
        setBankContactEmail(
          wireInstructions.bank_contact !== null
            ? wireInstructions.bank_contact.email
            : ""
        );
        setBankContactPhoneNumber(
          wireInstructions.bank_contact !== null
            ? wireInstructions.bank_contact.phone_number
            : ""
        );
      }
    }
  }, [wireInstructions]);

  return (
    <div className="container-fluid ps-4 h-100">
      <div className="row g-0 w-100">
        <div className="col-12 ps-2 pe-4">
          <div className="row g-0 w-100">
            <div className="col-lg-12 col-12 h-100 pt-0">
              <div className="col-12">
                <div className="row g-0 w-100">
                  {loading ? (
                    <div className="col pt-5 pb-5">
                      <div className="row g-0 w-100 mt-5 mb-5 pt-5 pb-5 align-items-center justify-content-center">
                        <LoadingAnimation />
                      </div>
                    </div>
                  ) : (
                    <div className="col-xxl-12 col-11 ps-0">
                      <div className="row gx-0 w-100 mt-3 mb-3">
                        <span className="request-received-text">
                          Wire Instructions
                        </span>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            Account Name:
                          </span>
                          <span className="category-value">{accountName}</span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            Account Number:
                          </span>
                          <span className="category-value">
                            {accountNumber}
                          </span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            Fund Address:
                          </span>
                          <span className="category-value">{fundAddress}</span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            ABA/Routing Number:
                          </span>
                          <span className="category-value">
                            {abaRoutingNumber}
                          </span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            SWIFT Code:
                          </span>
                          <span className="category-value">{swiftCode}</span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">Bank Name:</span>
                          <span className="category-value">{bankName}</span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-3">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            Bank Address
                          </span>
                          <span className="category-value">{bankAddress}</span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <span className="request-received-text">
                          Bank Contact
                        </span>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">Name:</span>
                          <span className="category-value">
                            {bankContactName}
                          </span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">Email:</span>
                          <span className="category-value">
                            {bankContactEmail}
                          </span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-4">
                        <div className="d-flex align-items-center">
                          <span className="category-name pe-2">
                            Phone Number:
                          </span>
                          <span className="category-value">
                            {bankContactPhoneNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WireInstructions;
