import React from "react";

function UpdateInfoInstructions() {
  return (
    <div className="row g-0 w-100 contact-aqua-admin-container">
      <div className="d-flex align-items-center">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/newInfo.svg"
          style={{ marginRight: "14px" }}
        />
        <span className="contact-aqua-admin-text">
          If any of the above information is incorrect, please contact
          admin@investwithaqua.com to update your account information.
        </span>
      </div>
    </div>
  );
}
export default UpdateInfoInstructions;
