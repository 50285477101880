import React from "react";
import "./TrustAccountDetailsStyles/TrustAccountDetailsStyles.scss";
import TrustAccountDetailsHeader from "./TrustAccountDetailsHeader/TrustAccountDetailsHeader";
import TrustAccountDetailsInfoSection from "./TrustAccountDetailsInfoSection/TrustAccountDetailsInfoSection";

function TrustAccountDetails({
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  trustType,
  setTrustType,
  trustTypeError,
  setTrustTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  idType,
  setIDType,
  idTypeError,
  setIDTypeError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
  grantorFirstName,
  setGrantorFirstName,
  grantorFirstNameError,
  setGrantorFirstNameError,
  grantorLastName,
  setGrantorLastName,
  grantorLastNameError,
  setGrantorLastNameError,
  grantorPhoneNumber,
  setGrantorPhoneNumber,
  grantorPhoneNumberError,
  setGrantorPhoneNumberError,
  grantorEmail,
  setGrantorEmail,
  grantorEmailError,
  setGrantorEmailError,
  grantorDomicileCountry,
  setGrantorDomicileCountry,
  grantorDomicileCountryError,
  setGrantorDomicileCountryError,
  grantorAddressLine1,
  setGrantorAddressLine1,
  grantorAddressLine1Error,
  setGrantorAddressLine1Error,
  grantorAddressLine2,
  setGrantorAddressLine2,
  grantorAddressLine2Error,
  setGrantorAddressLine2Error,
  grantorCity,
  setGrantorCity,
  grantorCityError,
  setGrantorCityError,
  grantorState,
  setGrantorState,
  grantorStateError,
  setGrantorStateError,
  grantorCountry,
  setGrantorCountry,
  grantorCountryError,
  setGrantorCountryError,
  grantorPostalCode,
  setGrantorPostalCode,
  grantorPostalCodeError,
  setGrantorPostalCodeError,
  grantorDob,
  setGrantorDob,
  grantorDobError,
  setGrantorDobError,
  grantorIDNumber,
  setGrantorIDNumber,
  grantorIDNumberError,
  setGrantorIDNumberError,
  relatedAccounts,
  setRelatedAccounts,
  beneficialOwnerRelatedAccounts,
  setBeneficialOwnerRelatedAccounts,
}) {
  return (
    <React.Fragment>
      <TrustAccountDetailsHeader />
      <TrustAccountDetailsInfoSection
        accountNumber={accountNumber}
        dateAdded={dateAdded}
        qualificationLevel={qualificationLevel}
        verificationStatus={verificationStatus}
        trustType={trustType}
        setTrustType={setTrustType}
        trustTypeError={trustTypeError}
        setTrustTypeError={setTrustTypeError}
        accountName={accountName}
        setAccountName={setAccountName}
        accountNameError={accountNameError}
        setAccountNameError={setAccountNameError}
        countryOfFormation={countryOfFormation}
        setCountryOfFormation={setCountryOfFormation}
        countryOfFormationError={countryOfFormationError}
        setCountryOfFormationError={setCountryOfFormationError}
        idType={idType}
        setIDType={setIDType}
        idTypeError={idTypeError}
        setIDTypeError={setIDTypeError}
        idNumber={idNumber}
        setIDNumber={setIDNumber}
        idNumberError={idNumberError}
        setIDNumberError={setIDNumberError}
        addressLine1={addressLine1}
        setAddressLine1={setAddressLine1}
        addressLine1Error={addressLine1Error}
        setAddressLine1Error={setAddressLine1Error}
        addressLine2={addressLine2}
        setAddressLine2={setAddressLine2}
        addressLine2Error={addressLine2Error}
        setAddressLine2Error={setAddressLine2Error}
        city={city}
        setCity={setCity}
        cityError={cityError}
        setCityError={setCityError}
        state={state}
        setState={setState}
        stateError={stateError}
        setStateError={setStateError}
        country={country}
        setCountry={setCountry}
        countryError={countryError}
        setCountryError={setCountryError}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        postalCodeError={postalCodeError}
        setPostalCodeError={setPostalCodeError}
        grantorFirstName={grantorFirstName}
        setGrantorFirstName={setGrantorFirstName}
        grantorFirstNameError={grantorFirstNameError}
        setGrantorFirstNameError={setGrantorFirstNameError}
        grantorLastName={grantorLastName}
        setGrantorLastName={setGrantorLastName}
        grantorLastNameError={grantorLastNameError}
        setGrantorLastNameError={setGrantorLastNameError}
        grantorPhoneNumber={grantorPhoneNumber}
        setGrantorPhoneNumber={setGrantorPhoneNumber}
        grantorPhoneNumberError={grantorPhoneNumberError}
        setGrantorPhoneNumberError={setGrantorPhoneNumberError}
        grantorEmail={grantorEmail}
        setGrantorEmail={setGrantorEmail}
        grantorEmailError={grantorEmailError}
        setGrantorEmailError={setGrantorEmailError}
        grantorDomicileCountry={grantorDomicileCountry}
        setGrantorDomicileCountry={setGrantorDomicileCountry}
        grantorDomicileCountryError={grantorDomicileCountryError}
        setGrantorDomicileCountryError={setGrantorDomicileCountryError}
        grantorAddressLine1={grantorAddressLine1}
        setGrantorAddressLine1={setGrantorAddressLine1}
        grantorAddressLine1Error={grantorAddressLine1Error}
        setGrantorAddressLine1Error={setGrantorAddressLine1Error}
        grantorAddressLine2={grantorAddressLine2}
        setGrantorAddressLine2={setGrantorAddressLine2}
        grantorAddressLine2Error={grantorAddressLine2Error}
        setGrantorAddressLine2Error={setGrantorAddressLine2Error}
        grantorCity={grantorCity}
        setGrantorCity={setGrantorCity}
        grantorCityError={grantorCityError}
        setGrantorCityError={setGrantorCityError}
        grantorState={grantorState}
        setGrantorState={setGrantorState}
        grantorStateError={grantorStateError}
        setGrantorStateError={setGrantorStateError}
        grantorCountry={grantorCountry}
        setGrantorCountry={setGrantorCountry}
        grantorCountryError={grantorCountryError}
        setGrantorCountryError={setGrantorCountryError}
        grantorPostalCode={grantorPostalCode}
        setGrantorPostalCode={setGrantorPostalCode}
        grantorPostalCodeError={grantorPostalCodeError}
        setGrantorPostalCodeError={setGrantorPostalCodeError}
        grantorDob={grantorDob}
        setGrantorDob={setGrantorDob}
        grantorDobError={grantorDobError}
        setGrantorDobError={setGrantorDobError}
        grantorIDNumber={grantorIDNumber}
        setGrantorIDNumber={setGrantorIDNumber}
        grantorIDNumberError={grantorIDNumberError}
        setGrantorIDNumberError={setGrantorIDNumberError}
        relatedAccounts={relatedAccounts}
        setRelatedAccounts={setRelatedAccounts}
        beneficialOwnerRelatedAccounts={beneficialOwnerRelatedAccounts}
        setBeneficialOwnerRelatedAccounts={setBeneficialOwnerRelatedAccounts}
      />
    </React.Fragment>
  );
}
export default TrustAccountDetails;
