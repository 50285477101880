import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./PhoneFundDetailsPageTopSectionStyles/PhoneFundDetailsPageTopSectionStyles.css";
import { numFormatter } from "../../../../../Adapters/GlobalAdapters/FormatData.js";
import ProgressBar from "../../../../../Components/GlobalComponents/ProgressBar.js";
import { BoldOutlined } from "@ant-design/icons";

function PhoneFundDetailsPageTopSection({ fund, stage }) {
  const [stageBoxDiv, setStageBoxDiv] = useState("");
  const [stageTextDiv, setStageTextDiv] = useState("");
  const [firmLogoLoading, setFirmLogoLoading] = useState(true);

  const firmLogoOnLoad = () => {
    setFirmLogoLoading(false);
  };

  let history = useHistory();

  useEffect(() => {
    if (stage === "Upcoming") {
      setStageBoxDiv("upcoming-stage-box ms-3");
      setStageTextDiv("upcoming-stage-text");
    } else if (stage === "Fundraising") {
      setStageBoxDiv("currently-fundraising-stage-box ms-3");
      setStageTextDiv("currently-fundraising-stage-text");
    } else if (stage === "Pending Closing") {
      setStageBoxDiv("fundraising-complete-stage-box ms-3");
      setStageTextDiv("fundraising-complete-stage-text");
    } else if (stage === "Closed") {
      setStageBoxDiv("closed-stage-box ms-3");
      setStageTextDiv("closed-stage-text");
    } else if (stage === "Past") {
      setStageBoxDiv("past-stage-box ms-3");
      setStageTextDiv("past-stage-text");
    }
  }, [stage]);

  return (
    <div className="container-fluid w-100 p-0 mt-3">
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 h-100">
            <div className="fund-card">
              <div className="col-12">
                <div className="row g-0 w-100">
                  <div className="col-md-2 col-sm-3 col-12 mt-md-3 mt-sm-4 mt-3 justify-content-start">
                    <div className="row g-0 w-100 align-items-start justify-content-start">
                      <div className="d-flex align-items-center justify-self-start">
                        <div className={stageBoxDiv}>
                          <div className={stageTextDiv}>{stage}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100">
                  <div className="col-md-2 col-sm-3 col-5 pe-sm-0 pe-3 pt-3 ps-3 pb-3">
                    <img
                      className="rounded responsive"
                      src={fund ? fund.logoLink : ""}
                      alt="thamesville"
                      style={{
                        height: "80px",
                        width: "80px",
                        display: firmLogoLoading ? "none" : "block",
                      }}
                      onLoad={firmLogoOnLoad}
                    />
                    <div
                      className="animated-slow"
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "10px",
                        display: firmLogoLoading ? "block" : "none",
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-8 col-7 pe-sm-0 pe-2 mt-auto mb-auto">
                    <div className="row g-0 w-100 mb-2">
                      <span className="restriction-text">
                        {fund
                          ? fund.registrationType === "3C7"
                            ? "US Qualified Purchasers Only"
                            : "US Accredited Investors"
                          : ""}
                      </span>
                    </div>
                    <div className="row g-0 w-100 mb-2">
                      <span className="fund-name-text">
                        {fund ? fund.firmName : ""}
                      </span>
                    </div>
                    <div className="row g-0 w-100">
                      <span className="firm-name-text">
                        {fund ? fund.fundName : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100">
                  <div className="col-12 ps-3 pe-3">
                    <div className="row g-0 w-100 border-bottom mb-3 pb-2">
                      <div className="col-12 d-flex justify-content-center">
                        <div className="row g-0 w-100">
                          <div className="col-sm col-6 mb-sm-0 mb-2 pe-3">
                            <div className="row g-0">
                              <div className="col-12 align-self-start pb-1">
                                <span className="fund-detail-section-title">
                                  Target IRR
                                </span>
                              </div>
                              <div className="col-12 align-self-end">
                                <span className="fund-detail-section-value">
                                  {fund ? fund.targetIRR : "~"}%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm col-6 mb-sm-0 mb-2 pe-3">
                            <div className="row g-0">
                              <div className="col-12 align-self-start pb-1">
                                <span className="fund-detail-section-title">
                                  Target Length
                                </span>
                              </div>
                              <div className="col-12 align-self-end">
                                <span className="fund-detail-section-value">
                                  {fund
                                    ? fund.targetInvestmentPeriod / 12
                                    : "-"}{" "}
                                  years
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm col-6 mb-sm-0 mb-2 pe-3">
                            <div className="row g-0">
                              <div className="col-12 align-self-start pb-1">
                                <span className="fund-detail-section-title">
                                  Strategy
                                </span>
                              </div>
                              <div className="col-12 align-self-end">
                                <span className="fund-detail-section-value">
                                {fund
                        ? fund.fundStrategy.type === "STRUCTURED_CREDIT"
                        ? "Private Credit"
                        : fund.fundStrategy.type === "CLO_MANAGEMENT"
                        ? "CLO Management"
                        : fund.fundStrategy.type === "DIRECT_LANDING"
                        ? "Direct Landing"
                        : fund.fundStrategy.type === "PRIVATE_EQUITY"
                        ? "Private Equity"
                        : fund.fundStrategy.type === "CO_INVESTMENT"
                        ? "Co-Investment"
                        : fund.fundStrategy.type === "FUND_OF_FUNDS"
                        ? "Fund of Funds"
                        : fund.fundStrategy.type === "BUYOUT"
                        ? "Buyout"
                        : fund.fundStrategy.type === "GROWTH"
                        ? "Growth"
                        : fund.fundStrategy.type === "CREDIT"
                        ? "Credit"
                        : ""
                        : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm col-6 mb-sm-0 mb-2 pe-3">
                            <div className="row g-0">
                              <div className="col-12 align-self-start pb-1">
                                <span className="fund-detail-section-title">
                                  Min. Investment
                                </span>
                              </div>
                              <div className="col-12 align-self-end">
                                <span className="fund-detail-section-value">
                                  {fund
                                    ? "$ " +
                                      fund.minimumInvestment.toLocaleString(
                                        "en-US"
                                      )
                                    : "~"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm col-6 mb-sm-0 mb-2 pe-3">
                            <div className="row g-0">
                              <div className="col-12 align-self-start pb-1">
                                <span className="fund-detail-section-title">
                                  Fund Size
                                </span>
                              </div>
                              <div className="col-12 align-self-end">
                                <span className="fund-detail-section-value">
                                  $ {fund ? numFormatter(fund.fundSize) : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {fund &&
                  Math.round(
                    ((fund.amountInvested / fund.allocationSize) * 100 +
                      Number.EPSILON) *
                      100
                  ) /
                    100 >=
                    25 && (
                    <div className="row g-0 w-100 pb-3">
                      <div className="col-12 ps-3 pe-3">
                        <div className="row g-0 mb-2">
                          <div className="col-12">
                            <div className="allocation-filled-text">
                              Allocation Filled:{" "}
                              <span
                                style={{
                                  color: fund
                                    ? Math.round(
                                        ((fund.amountInvested /
                                          fund.allocationSize) *
                                          100 +
                                          Number.EPSILON) *
                                          100
                                      ) /
                                        100 >=
                                      75
                                      ? "#DD2447"
                                      : "#000000"
                                    : "#000000",
                                  fontWeight: fund
                                    ? Math.round(
                                        ((fund.amountInvested /
                                          fund.allocationSize) *
                                          100 +
                                          Number.EPSILON) *
                                          100
                                      ) /
                                        100 >=
                                      75
                                      ? 700
                                      : 500
                                    : 500,
                                }}
                              >
                                {fund
                                  ? Math.round(
                                      ((fund.amountInvested /
                                        fund.allocationSize) *
                                        100 +
                                        Number.EPSILON) *
                                        100
                                    ) / 100
                                  : 0}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100">
                          <div className="progressBar-height">
                            <ProgressBar
                              bgColor={
                                fund
                                  ? Math.round(
                                      ((fund.amountInvested /
                                        fund.allocationSize) *
                                        100 +
                                        Number.EPSILON) *
                                        100
                                    ) /
                                      100 >=
                                    75
                                    ? "#DD2447"
                                    : "#0091EA"
                                  : "#0091EA"
                              }
                              completed={
                                fund
                                  ? Math.round(
                                      ((fund.amountInvested /
                                        fund.allocationSize) *
                                        100 +
                                        Number.EPSILON) *
                                        100
                                    ) / 100
                                  : 0
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {stage === "Fundraising" && (
                  <div className="row g-0 w-100">
                    <div className="col-md-2 col-sm-2 col-10 mb-sm-1 mb-1 ms-lg-3 ms-sm-auto me-sm-auto ms-auto me-auto pb-3">
                      <div className="d-flex justify-content-center">
                        {stage === "Fundraising" ? (
                          <button
                            type="button"
                            class="btn btn-lg btn-class"
                            onClick={async () =>
                              history.push("/invest/" + fund.link + "/confirm")
                            }
                          >
                            Invest
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PhoneFundDetailsPageTopSection;
