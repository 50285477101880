import axios from "axios";
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "./GlobalFunctions/RequestHeaders.js";

//Dev environment endpoint
export const getUserStatements = async () => {
  // console.log("Account statement id: " + accountId);
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/user-statement-documents/", requestParams);
};

//Dev environment endpoint
export const getUserTaxDocuments = async () => {
  // console.log("Account tax id: " + accountId);
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/user-tax-documents/", requestParams);
};

//Dev environment endpoint
export const getUserFundDocuments = async () => {
  // console.log("Account fund id: " + accountId);
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/user-fund-documents/", requestParams);
};

export const resendMagicLink = async (code) => {
  const call = axios.get(HOST + `/resend-magic-link?code=${code}`);

  return call;
};



export const clickToSign = async (code) => {
  const requestParams = {
      code: code,
  };
  const call = axios.post(HOST + `/sydecar-doc-sign`, requestParams);

  return call;
};

export const getSubdocLink = async (accountId, fundId, attempts) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      tryCount: attempts,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  const call = axios.get(
    HOST + `/investment/subdoc-link?account_id=${accountId}&fund_id=${fundId}`,
    requestParams
  );

  return call;
};

export const getSubdocLinkFromCode = async (code) => {
  const call = axios.get(HOST + `/enqueue-subdoc-link-client?code=${code}`);

  return call;
};

export const getSubdocSignature = async (accountId, fundId, attempts) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      tryCount: attempts,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  const call = axios.get(
    HOST +
      `/investment/get-sub-doc-signed?account_id=${accountId}&fund_id=${fundId}`,
    requestParams
  );

  return call;
};
