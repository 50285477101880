import React from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../../../../Components/countryList";
import { stateList } from "../../../../../../../../Components/GlobalComponents/stateList";

function MailingAddress({
  grantorAddressLine1,
  setGrantorAddressLine1,
  grantorAddressLine1Error,
  setGrantorAddressLine1Error,
  grantorAddressLine2,
  setGrantorAddressLine2,
  grantorAddressLine2Error,
  setGrantorAddressLine2Error,
  grantorCity,
  setGrantorCity,
  grantorCityError,
  setGrantorCityError,
  grantorState,
  setGrantorState,
  grantorStateError,
  setGrantorStateError,
  grantorCountry,
  setGrantorCountry,
  grantorCountryError,
  setGrantorCountryError,
  grantorPostalCode,
  setGrantorPostalCode,
  grantorPostalCodeError,
  setGrantorPostalCodeError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="trust-account-section-header-text">
          Mailing Address
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={grantorAddressLine1}
            setValue={setGrantorAddressLine1}
            valueError={grantorAddressLine1Error}
            setValueError={setGrantorAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"trust-account-details-grantor-address-line-1-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorAddressLine2}
            setValue={setGrantorAddressLine2}
            valueError={grantorAddressLine2Error}
            setValueError={setGrantorAddressLine2Error}
            label={"Address Line 2"}
            required={false}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"trust-account-details-grantor-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div
          className={
            grantorCountry === "United States" ? "col pe-2" : "col pe-0"
          }
        >
          <StyledTextInputField
            value={grantorCity}
            setValue={setGrantorCity}
            valueError={grantorCityError}
            setValueError={setGrantorCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"trust-account-details-grantor-city-input"}
          />
        </div>
        {grantorCountry === "United States" && (
          <div className="col pe-0 ps-2">
            <SelectField
              value={grantorState}
              setValue={setGrantorState}
              valueError={grantorStateError}
              setValueError={setGrantorStateError}
              selectOptions={stateList}
              label={"State"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={true}
              shrink={true}
              id={"trust-account-details-grantor-state-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectField
            value={grantorCountry}
            setValue={setGrantorCountry}
            valueError={grantorCountryError}
            setValueError={setGrantorCountryError}
            selectOptions={countryList}
            label={"Country"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"trust-account-details-grantor-country-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorPostalCode}
            setValue={setGrantorPostalCode}
            valueError={grantorPostalCodeError}
            setValueError={setGrantorPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"trust-account-details-grantor-postal-code-input"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default MailingAddress;
