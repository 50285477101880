import React from "react";
import "./Styles/Styles.scss";
import LogoContainer from "./LogoContainer/LogoContainer";
import FirmAuthorizedSignup from "./FirmAuthorizedSignup/FirmAuthorizedSignup";

function SignupContent() {
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-10 mt-auto mb-auto align-items-center foreground-content">
      <div className="container-fluid g-0 ms-5 pe-3 h-100 align-items-center mt-auto mb-auto pt-auto pb-auto">
        <div className="row g-0 w-100 align-items-center h-100 mt-auto mb-auto pt-auto pb-auto">
          <div className="col-12 align-items-center mt-auto mb-auto pt-auto pb-auto">
            <LogoContainer />
            <FirmAuthorizedSignup />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignupContent;
