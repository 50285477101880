import React from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../../../../Components/countryList.js";

function ContactInfo({
  grantorFirstName,
  setGrantorFirstName,
  grantorFirstNameError,
  setGrantorFirstNameError,
  grantorLastName,
  setGrantorLastName,
  grantorLastNameError,
  setGrantorLastNameError,
  grantorPhoneNumber,
  setGrantorPhoneNumber,
  grantorPhoneNumberError,
  setGrantorPhoneNumberError,
  grantorEmail,
  setGrantorEmail,
  grantorEmailError,
  setGrantorEmailError,
  grantorDomicileCountry,
  setGrantorDomicileCountry,
  grantorDomicileCountryError,
  setGrantorDomicileCountryError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={grantorFirstName}
            setValue={setGrantorFirstName}
            valueError={grantorFirstNameError}
            setValueError={setGrantorFirstNameError}
            label={"First Name"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-account-details-grantor-first-name-input"}
            shrink={true}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorLastName}
            setValue={setGrantorLastName}
            valueError={grantorLastNameError}
            setValueError={setGrantorLastNameError}
            label={"Last Name"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-account-details-grantor-last-name-input"}
            shrink={true}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={grantorPhoneNumber}
            setValue={setGrantorPhoneNumber}
            valueError={grantorPhoneNumberError}
            setValueError={setGrantorPhoneNumberError}
            label={"Phone Number"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-account-details-grantor-phone-number-input"}
            shrink={true}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorEmail}
            setValue={setGrantorEmail}
            valueError={grantorEmailError}
            setValueError={setGrantorEmailError}
            label={"Email"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-account-details-grantor-email-input"}
            shrink={true}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-0">
          <SelectField
            value={grantorDomicileCountry}
            setValue={setGrantorDomicileCountry}
            valueError={grantorDomicileCountryError}
            setValueError={setGrantorDomicileCountryError}
            label={"Domicile Country"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-account-details-grantor-domicile-country-input"}
            shrink={true}
            selectOptions={countryList}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default ContactInfo;
