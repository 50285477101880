import React from "react";
import AccountQualification from "./AccountQualification/AccountQualification";
import AccountLegalStructure from "./AccountLegalStructure/AccountLegalStructure.js";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions";

function EntityAccountDetailsGeneralInfo({
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  entityType,
  setEntityType,
  entityTypeError,
  setEntityTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-4">
            <span className="trust-account-section-header-text">
              General Information
            </span>
          </div>
          <AccountQualification
            accountNumber={accountNumber}
            dateAdded={dateAdded}
            qualificationLevel={qualificationLevel}
            verificationStatus={verificationStatus}
          />
          <AccountLegalStructure
            entityType={entityType}
            setEntityType={setEntityType}
            entityTypeError={entityTypeError}
            setEntityTypeError={setEntityTypeError}
            accountName={accountName}
            setAccountName={setAccountName}
            accountNameError={accountNameError}
            setAccountNameError={setAccountNameError}
            countryOfFormation={countryOfFormation}
            setCountryOfFormation={setCountryOfFormation}
            countryOfFormationError={countryOfFormationError}
            setCountryOfFormationError={setCountryOfFormationError}
          />
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default EntityAccountDetailsGeneralInfo;
