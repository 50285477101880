import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./AccountStatementsStyles/AccountStatementsStyles.css";
function AccountStatements({ accountStatements }) {
  const newColumns = [
    {
      dataField: "documentName",
      text: "Document Name",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "documentType",
      text: "Document Type",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "accountName",
      text: "Account",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "documentDate",
      text: "Date",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "viewed",
      text: "Read/Unread",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      window.open(row.documentLink, "_blank").focus();
    },
  };

  return (
    <div className="row g-0 w-100 mb-3">
      <div className="table-style pt-3 pe-2 d-flex justify-content-center">
        <div className="w-98">
          <BootstrapTable
            keyField="id"
            data={accountStatements}
            columns={newColumns}
            bordered={false}
            rowClasses={"table-row-style"}
            rowEvents={rowEvents}
          />
          {accountStatements.length === 0 && (
            <div className=" w-100 pt-5 pb-5 text-center">
              <span className="no-documents-text">
                You don't have any statements to show.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default AccountStatements;
