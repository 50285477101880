import React from "react";

export const isNumber = (number) => {
  const regEx = /^[0-9()\s-]*$/;
  return regEx.test(number);
};

export const isValidInvestmentNumber = (number) => {
  if (number === "" || number === "$") {
    return true;
  }
  const regEx = /^[$]?[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
  return regEx.test(number);
};

export const isValidPhoneNumber = (number) => {
  const regEx = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
  return regEx.test(number);
};

export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isDobValid = (dob) => {
  const re = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/;
  return re.test(String(dob).toLocaleLowerCase());
};

export const isValidSSN = (ssn) => {
  const re = /^(?!000|666)[0-9]{3}([ -])(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;
  return re.test(String(ssn).toLowerCase());
};

export const isValidCountry = (country) => {
  let countryList = [
    "Afghanistan",
    "Åland Islands",
    // ('Afghanistan', 'Afghanistan'),
    // ('Åland Islands', 'Åland Islands'),
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua And Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia, Plurinational State Of",
    "Bonaire, Sint Eustatius And Saba",
    "Bosnia And Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic Of The",
    "Cook Islands",
    "Costa Rica",
    "Côte D'ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-bissau",
    "Guyana",
    "Haiti",
    "Heard Island And Mcdonald Islands",
    "Holy See (vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Isle Of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Republic Of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, TheFormer Yugoslav Republic Of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States Of",
    "Moldova, Republic Of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State Of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension And Tristan Da Cunha",
    "Saint Kitts And Nevis",
    "Saint Lucia",
    "Saint Martin (french Part)",
    "Saint Pierre And Miquelon",
    "Saint Vincent And The Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome And Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (dutch Part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia And The South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard And Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Taiwan, Province Of China",
    "Tajikistan",
    "Tanzania, United Republic Of",
    "Thailand",
    "Timor-leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad And Tobago",
    "Tunisia",
    "Turkmenistan",
    "Turks And Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic Of",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.s.",
    "Wallis And Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  let isValidCountryChoice = false;
  countryList.forEach((countryChoice) => {
    if (country === countryChoice) {
      isValidCountryChoice = true;
    }
  });

  return isValidCountryChoice;
};

export const titleCase = (unformattedString) => {
  return unformattedString
    .split(/ /g)
    .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
    .join(" ");
};

export const poll = ({ fn, interval, maxAttempts, setResultState }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    attempts++;
    try {
      const result = await fn(attempts);
      if (maxAttempts && attempts >= maxAttempts) {
        setResultState("MR");
        return resolve("MR");
      } else if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.msg !== null &&
        result.data.msg !== undefined &&
        (result.data.msg === "F" ||
          result.data.msg === "P" ||
          result.data.msg === "S")
      ) {
        setResultState(result.data.msg);
        return resolve(result.data.msg);
      } else if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.msg !== null &&
        result.data.msg !== undefined &&
        result.data.msg === "NF"
      ) {
        setResultState(result.data.msg);
        setTimeout(executePoll, interval, resolve, reject);
      }
    } catch (err) {
      console.log(err);
      setResultState("E");
    }
  };

  return new Promise(executePoll);
};

export const pollDocs = ({ fn, interval, maxAttempts, setResultState }) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    attempts++;
    try {
      const result = await fn(attempts);
      if (maxAttempts && attempts >= maxAttempts) {
        setResultState("MR");
        return resolve("MR");
      } else if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.msg !== null &&
        result.data.msg !== undefined &&
        (result.data.msg === "No subdoc exists." ||
          result.data.msg === "Enqueued" || result.data.msg === "Waiting")
      ) {
        setResultState(result.data.msg);
        setTimeout(executePoll, interval, resolve, reject);
      } else if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.msg !== null &&
        result.data.msg !== undefined
      ) {
        setResultState(result.data.msg);
        return resolve(result.data.msg);
      }
    } catch (err) {
      console.log(err);
      setResultState("E");
    }
  };

  return new Promise(executePoll);
};

export const pollSignatureCompletion = ({
  fn,
  interval,
  maxAttempts,
  setResultState,
}) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    attempts++;

    try {
      const result = await fn(attempts);
      if (maxAttempts && attempts >= maxAttempts) {
        setResultState("MR");
        return resolve("MR");
      } else if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.msg !== null &&
        result.data.msg !== undefined &&
        result.data.msg === "Pending"
      ) {
        setResultState(result.data.msg);
        setTimeout(executePoll, interval, resolve, reject);
      } else if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.msg !== null &&
        result.data.msg !== undefined &&
        result.data.msg === "Signed"
      ) {
        setResultState(result.data.msg);
        return resolve(result.data.msg);
      }
    } catch (err) {
      console.log(err);
      setResultState("E");
    }
  };

  return new Promise(executePoll);
};
