import { useState, useEffect, useContext } from "react";
import { getAccountList } from "../Api/ProfileApi";
import { UserContext } from "../GlobalContexts/UserContext";

export default function GetAccountListAdapter(refresh, setLoading) {
  const [accounts, setAccounts] = useState([]);

  const { user } = useContext(UserContext);

  useEffect(() => {
    async function getAccountListInfo() {
      setLoading(true);
      try {
        const response = await getAccountList();
        const accountListData = response.data;
        if (process.env.REACT_APP_DEBUG === "1") console.log("Data is: " + JSON.stringify(accountListData));
        setAccounts([]);
        let tempAccounts = accountListData.forEach((account, index) => {
          let accountTypes = {
            IND: "Individual",
            TRT: "Trust",
            ENT: "Entity",
            JOINT: "Joint"
          };
          var tempCell = account.number;
          var copyString = "X".repeat(tempCell.length - 4);
          // copyString += tempCell.slice(-4);
          setAccounts((prev) => [
            ...prev,
            {
              id: account.id,
              accountName: account.name,
              accountType: accountTypes[account.account_type],
              accountNumberObscured: account.number,
              primary: index === 0,
            },
          ]);
          setLoading(false);
        });
        // setAccounts(tempAccounts);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.log(err);
      }
    }
    getAccountListInfo();
  }, [refresh]);

  return { accounts };
}
