import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { UserContext } from "../GlobalContexts/UserContext.js";
import { ConsoleSqlOutlined } from "@ant-design/icons";
// import { Auth } from "aws-amplify";

export default function ClientRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { user } = useContext(UserContext);

  console.log(user);
  
  return (
    <Route {...rest}>
      {
        // user !== null ? (
        (user !== null && (user.id === 'ghersh@florencecapital.com' || user.id.endsWith('@investwithaqua.com'))) ? (
          children
        ) : (
          <Redirect to={`/login?redirect=${pathname}${search}`} />
        )
        // ) : null
        // <Redirect to={`/login?redirect=${pathname}${search}`} />
      }
    </Route>
  );
}
