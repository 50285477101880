import React, { useState, useEffect } from "react";
import TrustAccountBasicInformationQualificationQuestion from "./TrustAccountBasicInformationQualificationQuestion/TrustAccountBasicInformationQualificationQuestion";
import TrustAccountBasicInformationQPModal from "./TrustAccountBasicInformationQPModal/TrustAccountBasicInformationQPModal";
import TrustAccountBasicInformationQualificationError from "./TrustAccountBasicInformationQualificationError/TrustAccountBasicInformationQualificationError";

function TrustAccountBasicInformationQualificationSection({
  showTrustQualificationSection,
  mountedStyle,
  unmountedStyle,
  trustQualificationQ1,
  setTrustQualificationQ1,
  trustQualificationQ2,
  setTrustQualificationQ2,
  trustQualificationQ3,
  setTrustQualificationQ3,
  trustQualificationQ4,
  setTrustQualificationQ4,
  trustAccountBasicInformationQualificationQuestionError,
}) {
  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={showTrustQualificationSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-step-subheader">
          Qualification Questions
        </span>
      </div>
      <div
        className="row g-0 w-100 mb-4"
        style={showTrustQualificationSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-qualification-subheader pe-1">
          Please confirm the account's{" "}
          <span style={{ fontWeight: 600 }}>Qualified Purchaser</span> status
        </span>
      </div>
      <TrustAccountBasicInformationQualificationQuestion
        showTrustQualificationSection={showTrustQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={1}
        questionText={"Does the trust have at least $5M in investments?"}
        answer={trustQualificationQ1}
        setAnswer={setTrustQualificationQ1}
      />
      <TrustAccountBasicInformationQualificationQuestion
        showTrustQualificationSection={showTrustQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={2}
        questionText={
          "Is the trust owned by two or more close family members (spouses, siblings, descendants, and/or their respective spouses)?"
        }
        answer={trustQualificationQ2}
        setAnswer={setTrustQualificationQ2}
      />
      <TrustAccountBasicInformationQualificationQuestion
        showTrustQualificationSection={showTrustQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={3}
        questionText={
          "Was the trust formed for the specific purpose of investing in a particular fund?"
        }
        answer={trustQualificationQ3}
        setAnswer={setTrustQualificationQ3}
      />
      <TrustAccountBasicInformationQualificationQuestion
        showTrustQualificationSection={showTrustQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={4}
        questionText={
          "Are the trustee(s) and persons granting assets to the trust qualified purchasers (*Definition below)?"
        }
        answer={trustQualificationQ4}
        setAnswer={setTrustQualificationQ4}
      />
      <TrustAccountBasicInformationQPModal
        showTrustQualificationSection={showTrustQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
      <TrustAccountBasicInformationQualificationError
        trustAccountBasicInformationQualificationQuestionError={
          trustAccountBasicInformationQualificationQuestionError
        }
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
    </React.Fragment>
  );
}
export default TrustAccountBasicInformationQualificationSection;
