import React from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { Button } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";

function AlertSnackBar({
  snackbarState,
  setSnackbarState,
  vertical,
  horizontal,
  duration,
  sxProps,
  severity,
  message,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      open={snackbarState}
      autoHideDuration={duration}
      onClose={handleClose}
      action={action}
      sx={sxProps}
    >
      <Alert
        autoHideDuration={duration}
        onClose={handleClose}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
export default AlertSnackBar;
