import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import InputField from "../../../../../../../../GlobalComponents/InputField/InputField";
import TrustAccountType from "../../../../../../../../Components/GlobalComponents/TrustAccountType/TrustAccountType";
import CountryInput from "../../../../../../../../Components/GlobalComponents/CountryInput/CountryInput";
import StateInput from "../../../../../../../../Components/GlobalComponents/StateInput/StateInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  userIDVal,
  trustQpQ1,
  trustQpQ2,
  trustQpQ3,
  trustQpQ4,
  qualificationDateVal,
  confidentialityAgreedIdVal,
  termsAgreementIdVal,
  emailVal,
  nameVal,
  accessCodeVal,
  accreditedInvestorsAllowed,
  trustAssets5M,
  trustSophisticatedInvestor,
  trustFormationNotSpecificInvestment,
  trustGrantorsAccredited,
  trustOwnedByFamily,
  trustGrantorQP,
} from "../../../../../../../../atoms";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  isValidCountry,
  isValidState,
  isEmailValid,
  isValidTrustType,
  isPasswordValid,
} from "../../../../../../../../GlobalFunctions/globalFunctions";
import PasswordField from "../../../../../../../../GlobalComponents/InputField/PasswordField/PasswordField";
import { Auth } from "aws-amplify";
import { signupLinkIndividualFeederAccount } from "../../../../../../../../Api/FeederFundSignupApi";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";

function TrustSignup({ setActiveSlide }) {
  const firmName = "test";

  //     navigation,
  //   setUserId,
  //   trustQualificationQ1,
  //   trustQualificationQ2,
  //   trustQualificationQ3,
  //   trustQualificationQ4,
  //   qualificationDate,
  //   confidentialityAgreementId,
  //   confidentialityAgreementDateSigned,
  //   termsAgreementId,
  //   termsAgreementDateSigned,
  //   email,
  //   setEmail,
  //   docSignatureName,
  const accessCode = useRecoilValue(accessCodeVal);
  const [userId, setUserId] = useRecoilState(userIDVal);
  const trustQualificationQ1 = useRecoilValue(trustQpQ1);
  const trustQualificationQ2 = useRecoilValue(trustQpQ2);
  const trustQualificationQ3 = useRecoilValue(trustQpQ3);
  const trustQualificationQ4 = useRecoilValue(trustQpQ4);
  const qualificationDate = useRecoilValue(qualificationDateVal);
  const confidentialityAgreementId = useRecoilValue(confidentialityAgreedIdVal);
  const confidentialityAgreementDateSigned = getDateUTC().toISOString();
  const termsAgreementId = useRecoilValue(termsAgreementIdVal);
  const termsAgreementDateSigned = getDateUTC().toISOString();
  const [email, setEmail] = useRecoilState(emailVal);
  const docSignatureName = useRecoilValue(nameVal);
  const [signUpError, setSignUpError] = useState("");
  const [loading, setLoading] = useState(false);

  const [trustAccountNameError, setTrustAccountNameError] = useState("");
  const [trustAccountTypeError, setTrustAccountTypeError] = useState("");
  const [countryOfFormationError, setCountryOfFormationError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [trustAccountName, setTrustAccountName] = useState("");
  const [trustAccountType, setTrustAccountType] = useState("");
  const [countryOfFormation, setCountryOfFormation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [countryOfResidenceError, setCountryOfResidenceError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(0);

  const [stateOfIncorporation, setStateOfIncorporation] = useState("");
  const [stateOfIncorporationError, setStateOfIncorporationError] =
    useState("");

  const [isUSCitizen, setIsUSCitizen] = useState("");
  const [isUSCitizenError, setIsUSCitizenError] = useState("");

  const accreditedAllowed = useRecoilValue(accreditedInvestorsAllowed);
  const assets5M = useRecoilValue(trustAssets5M);
  const sophisticatedInvestor = useRecoilValue(trustSophisticatedInvestor);
  const formationNotSpecificInvestment = useRecoilValue(
    trustFormationNotSpecificInvestment
  );
  const grantorsAccredited = useRecoilValue(trustGrantorsAccredited);
  const ownedByFamily = useRecoilValue(trustOwnedByFamily);
  const grantorQP = useRecoilValue(trustGrantorQP);

  let history = useHistory();

  useEffect(() => {
    // console.log("Phone number is: " + phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    setTrustAccountNameError("");
  }, [trustAccountName]);

  useEffect(() => {
    setTrustAccountTypeError("");
  }, [trustAccountType]);

  useEffect(() => {
    setCountryOfFormationError("");
  }, [countryOfFormation]);

  useEffect(() => {
    setFirstNameError("");
  }, [firstName]);

  useEffect(() => {
    setLastNameError("");
  }, [lastName]);

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    setConfirmPasswordError("");
  }, [confirmPassword]);

  useEffect(() => {
    setPhoneNumberError("");
  }, [phoneNumber]);

  useEffect(() => {
    setCountryOfResidenceError("");
    // console.log("Country is: " + countryOfResidence);
  }, [countryOfResidence]);

  useEffect(() => {
    setStateOfIncorporationError("");
  }, [stateOfIncorporation]);

  useEffect(() => {
    setIsUSCitizenError("");
  }, [isUSCitizen]);

  const submit = async () => {
    let isDataValid = true;

    if (trustAccountName.length === 0) {
      isDataValid = false;
      setTrustAccountNameError("Please enter a trust account name.");
    }

    if (trustAccountType.length === 0) {
      isDataValid = false;
      setTrustAccountTypeError(
        "Please select a valid trust account type from the dropdown"
      );
    } else if (!isValidTrustType(trustAccountType)) {
      isDataValid = false;
      setTrustAccountTypeError(
        "Please select a valid trust account from the dropdown."
      );
    }

    if (countryOfFormation.length === 0) {
      setCountryOfFormationError("Country is required.");
      isDataValid = false;
    } else if (!isValidCountry(countryOfFormation)) {
      setCountryOfFormationError(
        "Please select a valid country from the dropdown."
      );
      isDataValid = false;
    } else if (countryOfFormation !== "United States") {
      isDataValid = false;
      setCountryOfFormationError(
        "Sorry, we only support U.S. accounts at this time."
      );
    }

    if (countryOfFormation === "United States" && stateOfIncorporation === "") {
      isDataValid = false;
      setStateOfIncorporationError("Please select a state of incorporation.");
    } else if (
      countryOfFormation === "United States" &&
      !isValidState(stateOfIncorporation)
    ) {
      isDataValid = false;
      setStateOfIncorporationError(
        "Please select a valid state from the dropdown."
      );
    }

    const fullName = (
      firstName.toLowerCase() +
      " " +
      lastName.toLowerCase()
    ).trim();

    // if (docSignatureName.toLowerCase() !== fullName.replace(/  +/g, " ")) {
    //   setFirstNameError(
    //     "Name entered does not match the name signed on the confidentiality agreement."
    //   );
    //   setLastNameError(
    //     "Name entered does not match the name signed on the confidentiality agreement."
    //   );
    //   isDataValid = false;
    // } else if (
    //   docSignatureName.toLowerCase() === fullName.replace(/  +/g, " ")
    // ) {
    //   setFirstNameError("");
    //   setLastNameError("");
    // }

    if (firstName.replace(/  +/g, " ").trim().length === 0) {
      setFirstNameError("Please enter a first name.");
      isDataValid = false;
    }

    if (lastName.replace(/  +/g, " ").trim().length === 0) {
      setLastNameError("Please enter a last name.");
      isDataValid = false;
    }

    if (email.length === 0) {
      setEmailError("Please enter an email address.");
      isDataValid = false;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email address.");
      isDataValid = false;
    }

    if (password.length === 0) {
      setPasswordError("Please enter a password.");
      isDataValid = false;
    } else if (!isPasswordValid(password)) {
      setPasswordError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character."
      );
      isDataValid = false;
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError("Your passwords must match");
      isDataValid = false;
    }
    if (countryOfResidence === "") {
      setCountryOfResidenceError("Country is required.");
      isDataValid = false;
    } else if (!isValidCountry(countryOfResidence)) {
      setCountryOfResidenceError(
        "Please select a valid country from the dropdown."
      );
      isDataValid = false;
    } else if (countryOfResidence !== "United States") {
      isDataValid = false;
      setCountryOfResidenceError(
        "Sorry, we can only support U.S. based accounts currently."
      );
    }

    if (phoneNumber.length === 0) {
      setPhoneNumberError("Please enter a phone number.");
      isDataValid = false;
    }

    if (!isDataValid) {
      return;
    } else {
      setLoading(true);
      try {
        const cognitoUser = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
          },
          clientMetadata: { firm: firmName },
        });
        setUserId(cognitoUser.userSub);
        // This needs to take into account the citizenship value, so it can be used for the purpose of the grantor information => When the investor profile is added, the grantor information can be taken into account automatically.

        var newUser = {};

        if (accreditedAllowed === false) {
          newUser = {
            access_code: accessCode,
            username: cognitoUser.userSub,
            email: email.toLowerCase(),
            first_name: firstName.replace(/  +/g, " ").trim(),
            last_name: lastName.replace(/  +/g, " ").trim(),
            phone_number: phoneNumber,
            country: countryOfResidence,
            signer_name: docSignatureName,
            // confidentiality_agreement_id: confidentialityAgreementId,
            // confidentiality_agreement_date_signed:
            //   confidentialityAgreementDateSigned,
            terms_of_agreement_id: termsAgreementId,
            terms_of_agreement_date_signed: termsAgreementDateSigned,
            account_type: "TRT",
            trust_q1_qp_5m_investments: trustQualificationQ1
              ? trustQualificationQ1 === "Yes"
                ? true
                : false
              : false,
            trust_q2_qp_owned_by_family: trustQualificationQ2
              ? trustQualificationQ2 === "Yes"
                ? true
                : false
              : false,
            trust_q3_qp_specific_purpose_invest_fund: trustQualificationQ3
              ? trustQualificationQ3 === "Yes"
                ? true
                : false
              : false,
            trust_q4_qp_trustee_grantor_qp: trustQualificationQ4
              ? trustQualificationQ4 === "Yes"
                ? true
                : false
              : false,
            qualification_date: qualificationDate,
            entity_legal_name: trustAccountName,
            entity_type: trustAccountType
              ? trustAccountType === "Irrevocable Trust"
                ? "TRUST"
                : trustAccountType === "Revocable Trust"
                ? "LT"
                : ""
              : "",
            country_of_formation: countryOfFormation,
            is_us_citizen: true,
            state_of_incorporation: stateOfIncorporation,
          };
        } else {
          newUser = {
            accredited_signup: true,
            access_code: accessCode,
            username: cognitoUser.userSub,
            email: email.toLowerCase(),
            first_name: firstName.replace(/  +/g, " ").trim(),
            last_name: lastName.replace(/  +/g, " ").trim(),
            phone_number: phoneNumber,
            country: countryOfResidence,
            signer_name: docSignatureName,
            // confidentiality_agreement_id: confidentialityAgreementId,
            // confidentiality_agreement_date_signed:
            //   confidentialityAgreementDateSigned,
            terms_of_agreement_id: termsAgreementId,
            terms_of_agreement_date_signed: termsAgreementDateSigned,
            account_type: "TRT",
            trust_q1_qp_5m_investments: assets5M,
            trust_q2_qp_owned_by_family: ownedByFamily,
            trust_q3_qp_specific_purpose_invest_fund:
              !formationNotSpecificInvestment,
            trust_q4_qp_trustee_grantor_qp: grantorQP,
            trust_sophisticated_investor: sophisticatedInvestor,
            trust_grantors_accredited: grantorsAccredited,
            qualification_date: qualificationDate,
            entity_legal_name: trustAccountName,
            entity_type: trustAccountType
              ? trustAccountType === "Irrevocable Trust"
                ? "TRUST"
                : trustAccountType === "Revocable Trust"
                ? "LT"
                : ""
              : "",
            country_of_formation: countryOfFormation,
            is_us_citizen: true,
            state_of_incorporation: stateOfIncorporation,
          };
        }

        // const newUser = {
        //   access_code: accessCode,
        //   username: cognitoUser.userSub,
        //   email: email.toLowerCase(),
        //   first_name: firstName.replace(/  +/g, " ").trim(),
        //   last_name: lastName.replace(/  +/g, " ").trim(),
        //   phone_number: phoneNumber,
        //   country: countryOfResidence,
        //   signer_name: docSignatureName,
        //   confidentiality_agreement_id: confidentialityAgreementId,
        //   confidentiality_agreement_date_signed:
        //     confidentialityAgreementDateSigned,
        //   terms_of_agreement_id: termsAgreementId,
        //   terms_of_agreement_date_signed: termsAgreementDateSigned,
        //   account_type: "TRT",
        //   trust_q1_qp_5m_investments: trustQualificationQ1
        //     ? trustQualificationQ1 === "Yes"
        //       ? true
        //       : false
        //     : false,
        //   trust_q2_qp_owned_by_family: trustQualificationQ2
        //     ? trustQualificationQ2 === "Yes"
        //       ? true
        //       : false
        //     : false,
        //   trust_q3_qp_specific_purpose_invest_fund: trustQualificationQ3
        //     ? trustQualificationQ3 === "Yes"
        //       ? true
        //       : false
        //     : false,
        //   trust_q4_qp_trustee_grantor_qp: trustQualificationQ4
        //     ? trustQualificationQ4 === "Yes"
        //       ? true
        //       : false
        //     : false,
        //   qualification_date: qualificationDate,
        //   entity_legal_name: trustAccountName,
        //   entity_type: trustAccountType
        //     ? trustAccountType === "Irrevocable Trust"
        //       ? "TRUST"
        //       : trustAccountType === "Revocable Trust"
        //       ? "LT"
        //       : ""
        //     : "",
        //   country_of_formation: countryOfFormation,
        //   is_us_citizen: isUSCitizen,
        //   state_of_incorporation: stateOfIncorporation,
        // };

        await signupLinkIndividualFeederAccount(newUser);
        // await signupUser(newUser);
        // const cognitoUser = await Auth.signUp({
        //   username: email,
        //   password: password,
        //   attributes: {
        //     email,
        //   },
        // });
        // setUserId(cognitoUser.userSub);

        // const newUser = {
        //   username: cognitoUser.userSub,
        //   email: email.toLowerCase(),
        //   password: password,
        //   first_name: firstName.charAt(0).toUpperCase() + firstName.slice(1),
        //   last_name: lastName.charAt(0).toUpperCase() + lastName.slice(1),
        //   investor_type: "I",
        //   phone_number: phoneNumber,
        //   confidentiality_agreement_id: confidentialityAgreementId,
        //   confidentiality_agreement_date_signed:
        //     confidentialityAgreementDateSigned,
        //   terms_of_agreement_id: termsAgreementId,
        //   terms_of_agreement_date_signed: termsAgreementDateSigned,
        //   qualification_date: qualificationDate,
        //   q1_qp_5m_investments: isQualifiedPurchaser,
        //   q5_accredited_1m_networth: accreditionQuestionNetWorth,
        //   q6_accredited_200k_individual_annual_income:
        //     accreditionQuestionAnnualIncome,
        //   q7_accredited_300k_joint_annual_income:
        //     accreditionQuestionAnnualIncome,
        //   q8_accredited_professional_license:
        //     accreditionQuestionProfessionalLicense,
        //   country: accreditionQuestionUSResident
        //     ? "United States"
        //     : "United States",
        // };
        // await signupUser(newUser);
        // console.log("Success.");
        setActiveSlide((curSlide) => curSlide + 1);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setSignUpError("Sign up failed. Please try again.");
        if (process.env.DEBUG) console.error(err);
      }
      //   navigation.next();
    }
  };

  return (
    <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
      <div className="row g-0 h-100 align-items-center w-100">
        <div className="col-11">
          <div className="row g-0 w-100 mb-4">
            <div className="col-12">
              <div
                onClick={() => setActiveSlide((curSlide) => curSlide - 1)}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-xxl-6 col-xl-6 col-lg-10 col-12">
              <span className="SignupForm-subheader-text">
                Please fill out all the fields
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Trust Account Name
                  </span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Trust Type
                  </span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <InputField
                    placeholder={"Legal Name of Trust Account"}
                    error={trustAccountNameError}
                    fieldValueChange={setTrustAccountName}
                  />
                </div>
                <div className="col-6">
                  <TrustAccountType
                    value={trustAccountType}
                    setValue={setTrustAccountType}
                    placeholder={"Choose a trust type"}
                    backgroundColor={"#f7f7fa"}
                  />
                </div>
              </div>
              <div className="row gx-3 w-100">
                <div className="col-6">
                  {trustAccountNameError && (
                    <span className="SignupForm-field-error-text">
                      {trustAccountNameError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {trustAccountTypeError && (
                    <span className="SignupForm-field-error-text">
                      {trustAccountTypeError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3 pe-3">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-12">
                  <span className="SignupForm-field-title-text">
                    Country Of Formation
                  </span>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col-12">
                  <CountryInput
                    value={countryOfFormation}
                    setValue={setCountryOfFormation}
                    placeholder={"Choose a country"}
                    backgroundColor={"#f7f7fa"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-12">
                  {countryOfFormationError && (
                    <span className="SignupForm-field-error-text">
                      {countryOfFormationError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {countryOfFormation === "United States" && (
            <div className="row g-0 w-100 mb-3 pe-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-1">
                  <div className="col-12">
                    <span className="SignupForm-field-title-text">
                      State Of Incorporation
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col-12">
                    <StateInput
                      value={stateOfIncorporation}
                      setValue={setStateOfIncorporation}
                      placeholder={"Choose a state"}
                      backgroundColor={"#f7f7fa"}
                    />
                  </div>
                </div>
                <div className="row g-0 w-100">
                  <div className="col-12">
                    {stateOfIncorporationError && (
                      <span className="SignupForm-field-error-text">
                        {stateOfIncorporationError}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    First Name
                  </span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">Last Name</span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <InputField
                    placeholder={"Nick"}
                    error={firstNameError}
                    fieldValueChange={setFirstName}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    placeholder={"Black"}
                    error={lastNameError}
                    fieldValueChange={setLastName}
                  />
                </div>
              </div>
              <div className="row gx-3 w-100">
                <div className="col-6">
                  {firstNameError && (
                    <span className="SignupForm-field-error-text">
                      {firstNameError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {lastNameError && (
                    <span className="SignupForm-field-error-text">
                      {lastNameError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3 pe-3">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-12">
                  <span className="SignupForm-field-title-text">Email</span>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col-12">
                  <InputField
                    placeholder={"example@investwithaqua.com"}
                    error={emailError}
                    fieldValueChange={setEmail}
                    fieldInitialVal={email}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-12">
                  {emailError && (
                    <span className="SignupForm-field-error-text">
                      {emailError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">Password</span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Confirm Password
                  </span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <PasswordField
                    placeholder={"Password"}
                    error={passwordError}
                    fieldValueChange={setPassword}
                  />
                </div>
                <div className="col-6">
                  <PasswordField
                    placeholder={"Confirm Password"}
                    error={confirmPasswordError}
                    fieldValueChange={setConfirmPassword}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-6">
                  {passwordError && (
                    <span className="SignupForm-field-error-text">
                      {passwordError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {confirmPasswordError && (
                    <span className="SignupForm-field-error-text">
                      {confirmPasswordError}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="col-12">
              <div className="row g-0 w-100 mb-1">
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Phone Number
                  </span>
                </div>
                <div className="col-6">
                  <span className="SignupForm-field-title-text">
                    Country of Residence
                  </span>
                </div>
              </div>
              <div className="row gx-3 w-100 mb-1">
                <div className="col-6">
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber(phone)}
                    style={{ width: "100%", height: "inherit" }}
                    containerStyle={{ backgroundColor: "#000000" }}
                  />
                </div>
                <div className="col-6">
                  <CountryInput
                    value={countryOfResidence}
                    setValue={setCountryOfResidence}
                    placeholder={"Choose a country"}
                    backgroundColor={"#f7f7fa"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-6">
                  {phoneNumberError && (
                    <span className="SignupForm-field-error-text">
                      {phoneNumberError}
                    </span>
                  )}
                  {signUpError && (
                    <span className="SignupForm-field-error-text">
                      {signUpError}
                    </span>
                  )}
                </div>
                <div className="col-6">
                  {countryOfResidenceError && (
                    <span className="SignupForm-field-error-text">
                      {countryOfResidenceError}
                    </span>
                  )}
                </div>
              </div>
              {/* {countryOfResidence === "United States" && (
                <div className="row g-0 w-100 mt-3">
                  <div className="col-12">
                    <div className="row g-0 w-100 mb-3">
                      <span className="SignupForm-field-title-text">
                        Are you a U.S. Citizen?
                      </span>
                    </div>
                    <div className="row g-0 w-100 mb-3">
                      <div className="col-11">
                        <div className="row g-0 w-100">
                          <div className="col-6">
                            <label
                              for={"myTrustUSCitizenRadioId"}
                              className={"radio"}
                            >
                              <input
                                type={"radio"}
                                name={"myTrustUSCitizenRadioField"}
                                id={"myTrustUSCitizenRadioId"}
                                className={"radio__input"}
                                checked={isUSCitizen === true}
                                readOnly={true}
                                onClick={() => {
                                  setIsUSCitizen(true);
                                }}
                              />
                              <div className="radio__radio"></div>
                              <span className="selection-text ms-3">Yes</span>
                            </label>
                          </div>
                          <div className="col-6">
                            <label
                              for={"myTrustUSCitizenRadioId2"}
                              className={"radio"}
                            >
                              <input
                                type={"radio"}
                                name={"myTrustUSCitizenRadioField2"}
                                id={"myTrustUSCitizenRadioId2"}
                                className={"radio__input"}
                                checked={isUSCitizen === false}
                                readOnly={true}
                                onClick={() => {
                                  setIsUSCitizen(false);
                                }}
                              />
                              <div className="radio__radio"></div>
                              <span className="selection-text ms-3">No</span>
                            </label>
                          </div>
                        </div>
                        <div className="row g-0 w-100">
                          {isUSCitizenError && (
                            <span className="SignupForm-field-error-text">
                              {isUSCitizenError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className="row g-0 w-100 mb-5 pe-3">
            <div className="col-12">
              <div className="row g-0 w-100">
                <div className="col ms-0 me-auto">
                  <div className="d-flex">
                    <button
                      type="button"
                      class="btn btn-lg btn-class"
                      onClick={async () => submit()}
                      disabled={loading}
                    >
                      {loading && (
                        <div className="row g-0 w-100">
                          <div className="col d-flex align-items-center">
                            <span className="justify-self-start align-self-center">
                              Sign Up
                            </span>
                            <span
                              class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </div>
                      )}
                      {!loading && <span>Sign Up</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TrustSignup;
