import React, { useEffect } from "react";
import BeneficialOwnerQuestion from "./BeneficialOwnerQuestion/BeneficialOwnerQuestion";
import BeneficialOwnerInfiniteInput from "./BeneficialOwnerInfiniteInput/BeneficialOwnerInfiniteInput";

const BeneficialOwnerSection = ({
  accountType,
  shouldRenderBeneficialOwnerSection,
  showBeneficialOwnerSection,
  setShowBeneficialOwnerSection,
  mountedStyle,
  unmountedStyle,
  answer,
  setAnswer,
  activeTab,
  setActiveTab,
  addNewTab,
  accountID,
  relatedAccounts,
  setRelatedAccounts,
  handleRemoveFields,
  setActiveSlide,
  hideButton,
  setHideButton,
  hideAlterButtons,
  setHideAlterButtons,
  hasBeneficialOwnersError,
  setHasBeneficialOwnersError,
  supplyPercentOwned,
}) => {
  useEffect(() => {
    if (answer !== "") {
      setShowBeneficialOwnerSection(answer);
      setHasBeneficialOwnersError("");
    }
  }, [answer]);

  return (
    <React.Fragment>
      <BeneficialOwnerQuestion
        questionNumber={1}
        questionText={"Does this trust have any beneficial owners?"}
        answer={answer}
        setAnswer={setAnswer}
        hasBeneficialOwnersError={hasBeneficialOwnersError}
      />
      <BeneficialOwnerInfiniteInput
        accountType={accountType}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        showBeneficialOwnerSection={showBeneficialOwnerSection}
        shouldRenderBeneficialOwnerSection={shouldRenderBeneficialOwnerSection}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        addNewTab={addNewTab}
        accountID={accountID}
        relatedAccounts={relatedAccounts}
        setRelatedAccounts={setRelatedAccounts}
        handleRemoveFields={handleRemoveFields}
        setActiveSlide={setActiveSlide}
        hideButton={hideButton}
        setHideButton={setHideButton}
        hideAlterButtons={hideAlterButtons}
        setHideAlterButtons={setHideAlterButtons}
        supplyPercentOwned={supplyPercentOwned}
      />
    </React.Fragment>
  );
};
export default BeneficialOwnerSection;
