import React from "react";
import BeneficialOwners from "./BeneficialOwners/BeneficialOwners";
import UpdateInfoInstructions from "../TrustAccountDetailsGeneralInfo/UpdateInfoInstructions/UpdateInfoInstructions";

function TrustAccountDetailsBeneficialOwnerInfo({
  accountType,
  accountID,
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  entityType,
  setEntityType,
  entityTypeError,
  setEntityTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  beneficialOwnerRelatedAccounts,
  setBeneficialOwnerRelatedAccounts,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-2">
            <span className="trust-account-section-header-text">
              Beneficial Owner Information
            </span>
          </div>
          <BeneficialOwners
            accountType={accountType}
            accountID={accountID}
            beneficialOwnerRelatedAccounts={beneficialOwnerRelatedAccounts}
            setBeneficialOwnerRelatedAccounts={
              setBeneficialOwnerRelatedAccounts
            }
          />
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default TrustAccountDetailsBeneficialOwnerInfo;
