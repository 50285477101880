import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import IndividualAccountPersonalInformationTaxSection from "./IndividualAccountPersonalInformationTaxSection/IndividualAccountPersonalInformationTaxSection";
import IndividualAccountPersonalInformationAddressSection from "./IndividualAccountPersonalInformationAddressSection/IndividualAccountPersonalInformationAddressSection";
import IndividualAccountPersonalInformationButton from "./IndividualAccountPersonalInformationButton/IndividualAccountPersonalInformationButton";
import { isValidSSN } from "../../../../../../Functions/functions.js";
import {
  updateInvestorAddress,
  updateInvestorPII,
} from "../../../../../../Api/ProfileApi.js";
import { UserContext } from "./../../../../../../GlobalContexts/UserContext";

function IndividualAccountPersonalInformation({
  accountID,
  processedCountryList,
  processedStateList,
  domicileCountry,
  setDomicileCountry,
  joint,
}) {
  let history = useHistory("");

  const [idNumber, setIDNumber] = useState("");
  const [idNumberError, setIDNumberError] = useState("");

  const [idType, setIDType] = useState("");

  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");

  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const [secIdNumber, setSecIDNumber] = useState("");
  const [secDomicileCountry, setSecDomicileCountry] = useState("");

  const [secIdNumberError, setSecIDNumberError] = useState("");

  const [secIdType, setSecIDType] = useState("");

  const [secDob, setSecDob] = useState("");
  const [secDobError, setSecDobError] = useState("");

  const [secAddressLine1, setSecAddressLine1] = useState("");
  const [secAddressLine1Error, setSecAddressLine1Error] = useState("");

  const [secAddressLine2, setSecAddressLine2] = useState("");
  const [secAddressLine2Error, setSecAddressLine2Error] = useState("");

  const [secCity, setSecCity] = useState("");
  const [secCityError, setSecCityError] = useState("");

  const [secState, setSecState] = useState("");
  const [secStateError, setSecStateError] = useState("");

  const [secCountry, setSecCountry] = useState("");
  const [secCountryError, setSecCountryError] = useState("");

  const [secPostalCode, setSecPostalCode] = useState("");
  const [secPostalCodeError, setSecPostalCodeError] = useState("");

  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    user &&
      user.investorSummaryData &&
      setDomicileCountry(user.investorSummaryData.country);
  }, [user]);

  useEffect(() => {
    if (domicileCountry === "United States") {
      setIDType("SSN");
    } else {
      setIDType("PASS");
    }
  }, [domicileCountry]);

  useEffect(() => {
    if (secDomicileCountry === "United States") {
      setSecIDType("SSN");
    } else {
      setSecIDType("PASS");
    }
  }, [secDomicileCountry]);

  useEffect(() => {
    setIDNumberError("");
  }, [idType]);

  const getAgeFromDob = (dob) => {
    var today = new Date();
    var todayUnix = today.getTime();
    var userDob = new Date(dob);
    var userDobUnix = userDob.getTime();
    var age = 0;
    if (todayUnix < userDobUnix) {
      return --age;
    } else {
      age = today.getFullYear() - userDob.getFullYear();
      var monthDif = today.getMonth() - userDob.getMonth();
      if (monthDif < 0) {
        --age;
      } else if (monthDif === 0) {
        var dayDif = today.getDate() - (userDob.getDate() + 1);
        if (dayDif < 0) {
          --age;
        }
      }
      return age;
    }
  };

  const submitIndividualAccountPersonalInformation = async () => {
    var errors = false;

    if (dob === "") {
      errors = true;
      setDobError("This field is required.");
    } else if (getAgeFromDob(dob) < 0) {
      errors = true;
      setDobError("Your date of birth can't be in the future.");
    } else if (getAgeFromDob(dob) < 18) {
      errors = true;
      setDobError("Sorry, you must be at least 18 to invest using Aqua.");
    }

    if (idNumber === "") {
      errors = true;
      setIDNumberError("This field is required.");
    } else if (domicileCountry === "United States" && !isValidSSN(idNumber)) {
      errors = true;
      setIDNumberError("Please enter a valid SSN.");
    }

    if (addressLine1 === "") {
      errors = true;
      setAddressLine1Error("Please enter an address line 1.");
    }

    if (city === "") {
      errors = true;
      setCityError("Please enter a city.");
    }

    if (country === "United States" && state === "") {
      errors = true;
      setStateError("Please enter a state.");
    }

    if (country === "") {
      errors = true;
      setCountryError("Please enter a country.");
    }

    if (postalCode === "") {
      errors = true;
      setPostalCodeError("Please enter a postal code.");
    }

    if (!errors) {
      let addressInformation = {};
      addressInformation.address_line_1 = addressLine1;
      addressInformation.address_line_2 = addressLine2;
      addressInformation.city = city;
      addressInformation.state = state;
      addressInformation.country = country;
      addressInformation.postal_code = postalCode;

      addressInformation.address_type = "RM";

      addressInformation.id_type =
        idType === "EIN" ? "EIN" : idType === "ITIN" ? "ITIN" : "FTID";
      addressInformation.id_number = idNumber;
      addressInformation.entity_id = accountID;

      try {
        setSubmissionInProgress(true);
        await updateInvestorAddress(addressInformation);
        await updateInvestorPII({
          date_of_birth: dob,
          id_type: idType,
          id_num: idNumber,
        });

        const updatedUser = user;
        let investorPII = {};
        investorPII.date_of_birth = dob;
        investorPII.id_type = idType;
        investorPII.id_num = idNumber;

        let updateInvestorPIIArray = [];

        updateInvestorPIIArray.push(investorPII);
        updatedUser.userProfileData.investor_pii = updateInvestorPIIArray;

        let investorAddress = {};
        investorAddress.address_line_1 = addressLine1;
        investorAddress.address_line_2 = addressLine2;
        investorAddress.city = city;
        investorAddress.country = country;
        investorAddress.state = state;
        investorAddress.postal_code = postalCode;

        let updateInvestorAddressArray = [];
        updateInvestorAddressArray.push(investorAddress);
        updatedUser.userProfileData.investor_addresses =
          updateInvestorAddressArray;

        updatedUser.investorPrimaryAddressLine1 = addressLine1;
        updatedUser.investorPrimaryAddressLine2 = addressLine2;
        updatedUser.investorPrimaryCity = city;
        updatedUser.investorPrimaryState = state;
        updatedUser.investorPrimaryCountry = country;
        updatedUser.investorPrimaryPostalCode = postalCode;
        updatedUser.investorPrimaryAddressType = "RM";
        updatedUser.investorSummaryData.address_complete = true;
        updatedUser.investorSummaryData.pii_complete = true;

        setUser(updatedUser);
        if (joint) {
          submitJointAccountPersonalInformation();
        }
        history.push("/investment-accounts");
      } catch (err) {
        console.log(err);
      }
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  const submitJointAccountPersonalInformation = async () => {
    var errors = false;

    if (secDob === "") {
      errors = true;
      setSecDobError("This field is required.");
    } else if (getAgeFromDob(secDob) < 0) {
      errors = true;
      setSecDobError("Your date of birth can't be in the future.");
    } else if (getAgeFromDob(secDob) < 18) {
      errors = true;
      setSecDobError("Sorry, you must be at least 18 to invest using Aqua.");
    }

    if (secIdNumber === "") {
      errors = true;
      setSecIDNumberError("This field is required.");
    } else if (
      secDomicileCountry === "United States" &&
      !isValidSSN(secIdNumber)
    ) {
      errors = true;
      setSecIDNumberError("Please enter a valid SSN.");
    }

    if (secAddressLine1 === "") {
      errors = true;
      setSecAddressLine1Error("Please enter an address line 1.");
    }

    if (secCity === "") {
      errors = true;
      setSecCityError("Please enter a city.");
    }

    if (secCountry === "United States" && secState === "") {
      errors = true;
      setSecStateError("Please enter a state.");
    }

    if (secCountry === "") {
      errors = true;
      setSecCountryError("Please enter a country.");
    } else if (secCountry !== "United States") {
      errors = true;
      setSecCountryError(
        "Sorry, only US investors are supported at this time."
      );
    }

    if (secPostalCode === "") {
      errors = true;
      setSecPostalCodeError("Please enter a postal code.");
    }

    if (!errors) {
      let addressInformation = {};
      addressInformation.address_line_1 = secAddressLine1;
      addressInformation.address_line_2 = secAddressLine2;
      addressInformation.city = secCity;
      addressInformation.state = secState;
      addressInformation.country = secCountry;
      addressInformation.postal_code = secPostalCode;

      addressInformation.address_type = "RM";

      addressInformation.id_type =
        idType === "EIN" ? "EIN" : idType === "ITIN" ? "ITIN" : "FTID";
      addressInformation.id_number = secIdNumber;
      addressInformation.entity_id = accountID;
      addressInformation.joint = true;

      try {
        setSubmissionInProgress(true);
        await updateInvestorAddress(addressInformation);
        await updateInvestorPII({
          date_of_birth: secDob,
          id_type: secIdType,
          id_num: secIdNumber,
          joint: true,
          citizenship: secCountry,
        });
        // Re-pull context
        //setUser(updatedUser);

        history.push("/investment-accounts");
      } catch (err) {
        setSubmissionInProgress(false);
        console.log(err);
      }
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  /*
            user &&
          user.investorSummaryData &&
          user.investorSummaryData.address_complete &&
          user.investorSummaryData.pii_complete*/

  return (
    <div className="row g-0 w-100 account-legal-information-container">
      <div className="col-12 account-legal-information-outer-column-padded">
        {user &&
          user.investorSummaryData &&
          !(
            user.investorSummaryData.pii_complete &&
            user.investorSummaryData.address_complete
          ) && (
            <>
              <IndividualAccountPersonalInformationTaxSection
                domicileCountry={domicileCountry}
                dob={dob}
                setDob={setDob}
                dobError={dobError}
                setDobError={setDobError}
                idNumber={idNumber}
                setIDNumber={setIDNumber}
                idNumberError={idNumberError}
                setIDNumberError={setIDNumberError}
              />
              <IndividualAccountPersonalInformationAddressSection
                addressLine1={addressLine1}
                setAddressLine1={setAddressLine1}
                addressLine1Error={addressLine1Error}
                setAddressLine1Error={setAddressLine1Error}
                addressLine2={addressLine2}
                setAddressLine2={setAddressLine2}
                addressLine2Error={addressLine2Error}
                setAddressLine2Error={setAddressLine2Error}
                city={city}
                setCity={setCity}
                cityError={cityError}
                setCityError={setCityError}
                state={state}
                setState={setState}
                stateError={stateError}
                setStateError={setStateError}
                processedStateList={processedStateList}
                country={country}
                setCountry={setCountry}
                countryError={countryError}
                setCountryError={setCountryError}
                processedCountryList={processedCountryList}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                postalCodeError={postalCodeError}
                setPostalCodeError={setPostalCodeError}
              />
            </>
          )}
        {joint && (
          <>
            <IndividualAccountPersonalInformationAddressSection
              joint={true}
              addressLine1={secAddressLine1}
              setAddressLine1={setSecAddressLine1}
              addressLine1Error={secAddressLine1Error}
              setAddressLine1Error={setSecAddressLine1Error}
              addressLine2={secAddressLine2}
              setAddressLine2={setSecAddressLine2}
              addressLine2Error={secAddressLine2Error}
              setAddressLine2Error={setSecAddressLine2Error}
              city={secCity}
              setCity={setSecCity}
              cityError={secCityError}
              setCityError={setSecCityError}
              state={secState}
              setState={setSecState}
              stateError={secStateError}
              setStateError={setSecStateError}
              processedStateList={processedStateList}
              country={secCountry}
              setCountry={setSecCountry}
              countryError={secCountryError}
              setCountryError={setSecCountryError}
              processedCountryList={processedCountryList}
              postalCode={secPostalCode}
              setPostalCode={setSecPostalCode}
              postalCodeError={secPostalCodeError}
              setPostalCodeError={setSecPostalCodeError}
            />
            <IndividualAccountPersonalInformationTaxSection
              domicileCountry={secCountry}
              dob={secDob}
              setDob={setSecDob}
              dobError={secDobError}
              setDobError={setSecDobError}
              idNumber={secIdNumber}
              setIDNumber={setSecIDNumber}
              idNumberError={secIdNumberError}
              setIDNumberError={setSecIDNumberError}
            />{" "}
          </>
        )}

        <IndividualAccountPersonalInformationButton
          loading={submissionInProgress}
          onClick={() =>
            user &&
            user.investorSummaryData &&
            !(
              user.investorSummaryData.pii_complete &&
              user.investorSummaryData.address_complete
            )
              ? submitIndividualAccountPersonalInformation()
              : submitJointAccountPersonalInformation()
          }
        />
      </div>
    </div>
  );
}
export default IndividualAccountPersonalInformation;
