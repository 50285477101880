import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import Downshift from "downshift";
import {
  Label,
  Menu,
  ControllerButton,
  Input,
  TransparentInput,
  Item,
  ArrowIcon,
  XIcon,
  css,
  getStringItems,
} from "./shared.js";

function CountryInput({ value, setValue, placeholder, backgroundColor }) {
  const [inputPlaceholder, setInputPlaceholder] = useState(
    placeholder ? placeholder : ""
  );

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);
  const handleStateChange = (changes) => {
    if (changes.hasOwnProperty("selectedItem")) {
      setValue(changes.selectedItem);
    } else if (changes.hasOwnProperty("inputValue")) {
      setValue(changes.inputValue);
    }
  };
  return (
    <React.Fragment>
      <div
        {...css({
          flexDirection: "column",
          marginTop: 0,
          textAlign: "center",
        })}
      >
        <Downshift selectedItem={value} onStateChange={handleStateChange}>
          {({
            getLabelProps,
            getInputProps,
            getToggleButtonProps,
            getMenuProps,
            getItemProps,
            isOpen,
            clearSelection,
            selectedItem,
            inputValue,
            highlightedIndex,
          }) => (
            <div {...css({ width: "100%", height: "56px", margin: "auto" })}>
              <div {...css({ position: "relative" })}>
                {backgroundColor ? (
                  <TransparentInput
                    {...getInputProps({
                      isOpen,
                      placeholder: inputPlaceholder ? inputPlaceholder : "",
                      background: backgroundColor
                        ? backgroundColor + " !important"
                        : "#ffffff",
                    })}
                  />
                ) : (
                  <Input
                    {...getInputProps({
                      isOpen,
                      placeholder: inputPlaceholder ? inputPlaceholder : "",
                      background: backgroundColor
                        ? backgroundColor + " !important"
                        : "#ffffff",
                    })}
                  />
                )}

                {selectedItem ? (
                  <ControllerButton
                    onClick={clearSelection}
                    aria-label="clear selection"
                  >
                    <XIcon />
                  </ControllerButton>
                ) : (
                  <ControllerButton {...getToggleButtonProps()}>
                    <ArrowIcon isOpen={isOpen} />
                  </ControllerButton>
                )}
              </div>
              <div {...css({ position: "relative" })}>
                <Menu {...getMenuProps({ isOpen })}>
                  {isOpen
                    ? getStringItems(inputValue).map((item, index) => (
                        <Item
                          key={item.id}
                          {...getItemProps({
                            item,
                            index,
                            isActive: highlightedIndex === index,
                            isSelected: selectedItem === item,
                          })}
                        >
                          {item}
                        </Item>
                      ))
                    : null}
                </Menu>
              </div>
            </div>
          )}
        </Downshift>
      </div>
    </React.Fragment>
  );
}
export default CountryInput;
