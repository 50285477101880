import React, { useState, useEffect } from "react";
import "./SignatureInputFieldStyles/SignatureInputFieldStyles.css";

function SignatureInputField({
  placeholder,
  error,
  fieldValueChange,
  initialValue,
  disabled,
}) {
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [focus, setFocus] = useState(false);
  //const [error, setError] = useState(false);
  const [divClass, setDivClass] = useState("input-box-container");
  const [fieldValue, setFieldValue] = useState(initialValue);

  useEffect(() => {
    if (!error) {
      setDivClass(focus ? "input-box-containerFocus" : "input-box-container");
    } else {
      setDivClass("input-box-containerError");
    }
  }, [focus, error]);

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    fieldValueChange(fieldValue);
  }, [fieldValue, fieldValueChange]);
  return (
    <div
      className={divClass}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="input-box">
        <input
          className={
            error === "This field is required."
              ? "internal-signature-input-error-empty"
              : "internal-signature-input"
          }
          placeholder={
            error === "This field is required." ? "Required" : inputPlaceholder
          }
          disabled={disabled}
          value={initialValue}
          onChange={(event) => setFieldValue(event.target.value)}
        ></input>
      </div>
    </div>
  );
}
export default SignatureInputField;
