import React from "react";
import "./Styles/Styles.scss";

function FlowNotAccessible() {
  return (
    <div className="row g-0 w-100">
      <h5>Sorry, this flow is not accessible from this domain.</h5>
    </div>
  );
}
export default FlowNotAccessible;
