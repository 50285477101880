import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  TableContainer,
  TablePagination,
  TextField,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import Checkbox from "@mui/material/Checkbox";
import Dropdown from 'react-bootstrap/Dropdown';
import Header from "../Components/GlobalComponents/Header.js";

import { Button, Modal, Form, ListGroup } from 'react-bootstrap';
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import { Auth } from "aws-amplify";

function investmentStatusFormatter(value) {
  if (value !== undefined && value !== null) {
    let investmentStatusMap = {
      P: "Pending Approval",
      PD: "Pending Sub Docs",
      PF: "Pending Funding",
      F: "Funded",
      A: "Approved",
      R: "Rejected",
    };
    return investmentStatusMap[value.toString()];
  }
  return "";
}

function DropDown({ deal, setDeal }) {
  const funds = ['SPV VIII', 'Arkon Energy SPV'];
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {deal ? deal : 'Deals'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {funds.map((e) => (<Dropdown.Item onClick={() => setDeal(e)}>{e}</Dropdown.Item>))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function formatDateTime(dateString) {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleString('en-US', options);
}




const AdminDashboard = () => {
// Example usage
const humanReadableDate = formatDateTime('2023-06-06T17:39:39.552423Z');
console.log(humanReadableDate);
const [email, setEmail] = useState(null);

/*async function loadUser() {
  const sessionInfo = await Auth.currentSession();
  let user = sessionInfo.idToken.payload.email;
  setEmail(user);
  console.log(user);
}

loadUser();*/
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [deal, setDeal] = useState(null)
  const [dealData, setDealData] = useState({});

  useEffect(async () => {
    async function call() {
      const config = await getRequestHeaders();
      return axios.post(HOST + "/client_dashboard", { "fund_name": deal, "options": ["investments", "documents"] }, config);
    };

    const resp = await call();
    setDealData(resp.data.msg)

  },
    [deal]);
  return (<>
    <Header activePage={"dashboard"}></Header>
    <div className="mt-5 pt-5"></div>
    <div className="container-xxl contain mt-xxl-2 mt-xl-2 mt-lg-2 mt-md-2 mt-sm-2 mt-4 pb-5">
      <h2>Deal dashboard</h2>
      <DropDown deal={deal} setDeal={setDeal} /><br />

      {deal && (Object.keys(dealData).length !== 0) && <>
        <h3>Deal info</h3>
        <ul>
          <li>Commitments: ${dealData.agg_data.signed.toLocaleString("en-US")} </li>
          <li>Funded: ${dealData.agg_data.funded.toLocaleString("en-US")} </li>
        </ul>
        <h3>Investments</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealData.investments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.account_name}</TableCell>
                  <TableCell>${row.investment_amount.toLocaleString("en-US")}</TableCell>
                  <TableCell>{formatDateTime(row.investment_date)}</TableCell>
                  <TableCell>{investmentStatusFormatter(row.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={dealData.investments.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </TableContainer>
        <br />
        <h3>Documents</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Related Object</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealData.documents.map((row) => (
                <TableRow key={row.id}>
                  <TableCell> {row.related_obj}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell> <Button variant="primary" href={row.url}>Download</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>}
    </div></>
  );
};

export default AdminDashboard;
