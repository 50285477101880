import React, { useState, useEffect, useContext } from "react";
import Header from "../../../Components/GlobalComponents/Header.js";
import "./PortfolioPageStyles/PortfolioPageStyles.css";
// import FromFilter from "../../../Components/GlobalComponents/DateFilters/FromFilter.js";
// import ToFilter from "../../../Components/GlobalComponents/DateFilters/ToFilter.js";
// import SingleSelectDropdown from "../../../Components/GlobalComponents/SingleSelectDropdown/SingleSelectDropdown.js";
import {
  getUserInvestments,
  getUserDistributions,
} from "../../../Api/PortfolioApi.js";
import { UserContext } from "../../../GlobalContexts/UserContext.js";
// import { numFormatter } from "../../../Adapters/GlobalAdapters/FormatData.js";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import BootstrapTable from "react-bootstrap-table-next";
// import Button from "../../../GlobalComponents/Button/Button.js";
// import { useHistory } from "react-router-dom";
import InvestmentTable from "./InvestmentTable.js";
import PortfolioFilterRow from "./PortfolioFilterRow.js";
// import TotalInvestmentDistributionSection from "./TotalInvestmentDistributionSection.js";
import PortfolioGraphSection from "./PortfolioGraphSection.js";
// import {
//   AreaChart,
//   Area,
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
//   Legend,
// } from "recharts";
import { userAuthenticatedState } from "../../../atoms";
import { useRecoilValue } from "recoil";
// import Media from "react-media";
// import PhoneInvestmentGrid from "./PhoneInvestmentGrid/PhoneInvestmentGrid.js";
import * as dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");

function PortfolioPage() {
  dayjs.extend(utc);
  const [fromDate, setFromDate] = useState(null);
  const [fundName, setFundName] = useState("All");
  const [toDate, setToDate] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [accountCreationDate, setAccountCreationDate] = useState("");
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalDistributed, setTotalDistributed] = useState(0);
  const [investments, setInvestments] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [text, setText] = useState("Current");
  const [defaultKey, setDefaultKey] = useState("");
  // const userAuthenticated = useRecoilValue(userAuthenticatedState);

  useEffect(() => {
    if (user !== undefined && user !== null) {
      setAccountCreationDate(
        dayjs(user.investorSummaryData.created_at)
          .utc("Z")
          .local()
          .format("MMM D, YYYY")
      );
    }
  }, [user]);

  useEffect(() => {
    async function getInvestments() {
      try {
        const response = await getUserInvestments(
          true,
          user ? user.selectedAccountID : null
        );

        setInvestments(response.data);
        // console.log("Investments are: " + JSON.stringify(response.data));
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
    getInvestments();
  }, []);

  useEffect(() => {
    async function getDistributions() {
      try {
        const response = await getUserDistributions(
          true,
          user ? user.selectedAccountID : null
        );
        setDistributions(response.data);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
    getDistributions();
  }, []);

  useEffect(() => {
    var totalInvestments = 0;
    var totalDistributions = 0;

    investments.forEach(({ investment_amount }) => {
      totalInvestments += investment_amount;
    });

    distributions.forEach(({ distribution_amount }) => {
      totalDistributions += distribution_amount;
    });

    setTotalInvested(totalInvestments);
    setTotalDistributed(totalDistributions);
  }, [investments, distributions]);

  useEffect(() => {
    var items = [];
    items.push(text);
    setDefaultKey(items);
  }, [text]);

  return (
    <React.Fragment>
      <Header activePage={"portfolio"} />
      <div className="mt-5 pt-5"></div>
      <div className="container-xxl container-fluid contain">
        <div className="row g-0 w-100">
          <div className="col-12">
            {/* <div className="row g-0 w-100 mt-2 mb-4">
              <div className="col-12">
                <span className="portfolio-title-text">Portfolio</span>
              </div>
            </div> */}
            <div className="row g-0 mt-3 w-100">
              <div className="col-12">
                {/* <TotalInvestmentDistributionSection
                  totalInvested={totalInvested}
                  totalDistributed={totalDistributed}
                /> */}
                <PortfolioFilterRow
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                  text={text}
                  setText={setText}
                  defaultKey={defaultKey}
                />
                <PortfolioGraphSection
                  fundName={fundName}
                  investments={investments}
                  distributions={distributions}
                  fromDate={fromDate}
                  toDate={toDate}
                  accountCreationDate={accountCreationDate}
                />
                <InvestmentTable
                  investments={investments}
                  distributions={distributions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default PortfolioPage;
