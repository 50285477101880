import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { QualifiedPurchaserModal } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { QualifiedPurchaserLink } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import StateBasedButton from "../../../../../../../../GlobalComponents/Button/StateBasedButton/StateBasedButton";
import { useRecoilState } from "recoil";
import {
  indQpQ1Yes,
  indQpQ1No,
  qualificationDateVal,
} from "../../../../../../../../atoms";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";
import AlertSnackBar from "../../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import MuiButton from "../../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";

function IndividualQualification({ setActiveSlide }) {
  const [showModal, setShowModal] = useState(false);
  const [selection1, setSelection1] = useRecoilState(indQpQ1Yes);
  const [selection2, setSelection2] = useRecoilState(indQpQ1No);
  const [qualificationDate, setQualificationDate] =
    useRecoilState(qualificationDateVal);
  const [selection1Focus, setSelection1Focus] = useState(false);
  const [selection2Focus, setSelection2Focus] = useState(false);
  const [divClass1, setDivClass1] = useState("selection-1");
  const [divClass2, setDivClass2] = useState("selection-2");
  const [proceed, setProceed] = useState(false);
  const [qpError, setQPError] = useState("");
  const [notQPError, setNotQPError] = useState(false);
  const [noSelectionMadeError, setNoSelectionMadeError] = useState(false);

  let history = useHistory();

  useEffect(() => {
    setSelection1Focus(selection1);
    setSelection2Focus(selection2);
  }, []);

  useEffect(() => {
    setDivClass1(selection1Focus ? "selection-1-selected" : "selection-1");
    setSelection1(selection1Focus);
    setQPError("");
    if (selection1Focus) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [selection1Focus, setSelection1]);

  useEffect(() => {
    setDivClass2(selection2Focus ? "selection-2-selected" : "selection-2");
    setSelection2(selection2Focus);
    setQPError("");
  }, [selection2Focus, setSelection2]);

  const validateQPStatus = () => {
    let isQP = true;

    if (!proceed) {
      isQP = false;
    }
    if (!selection1) {
      isQP = false;
    }

    if (isQP) {
      var utcDate = getDateUTC();
      setQualificationDate(utcDate.toISOString());
      setActiveSlide((curSlide) => curSlide + 1);
    } else {
      setQPError(
        "Unfortunately, our opportunities are only open to qualified purchasers at this time, but we'll have investment opportunities for you very soon."
      );
      setNotQPError(true);
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal show={showModal} setShowModal={setShowModal} />
      <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
        <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4 mt-3">
              <div className="col-12">
                <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <span className="body-text-md-semibold">
                  Please confirm your{" "}
                  <strong>Qualified Purchaser Status</strong>
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <span className="body-text-sm-regular">The Subscriber is:</span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-10">
                <div
                  className={divClass1}
                  onClick={() => {
                    setSelection2Focus(false);
                    setSelection1Focus(!selection1Focus);
                  }}
                >
                  <div className="col">
                    <div className="row g-0 w-100">
                      <span className="selection-1-text">
                        A natural person who alone (or together with his/her
                        spouse, if investing jointly) owns not less than
                        $5,000,000 in investments.
                      </span>
                    </div>
                    <div className="row g-0 w-100">
                      <span className="regulatory-info-text ps-2 pb-3">
                        *This definition of qualified purchaser is incomplete.
                        See below for the full definition.*
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-1">
              <div className="col-10">
                <div
                  className={divClass2}
                  onClick={() => {
                    setProceed(false);
                    setSelection1Focus(false);
                    setSelection2Focus(!selection2Focus);
                  }}
                >
                  <span className="selection-2-text">
                    None of the above (not a Qualified Purchaser)
                  </span>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col-10">
                <span className="regulatory-info-text">
                  The information provided will be used to assist us in
                  complying with SEC regulations.
                </span>
              </div>
            </div>

            <div className="row g-0 w-100 mb-3">
              <div className="col-10">
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Next step"}
                        onClick={() => validateQPStatus()}
                        size={"medium"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mt-3 pe-5">
              <div className="col-11">
                <QualifiedPurchaserLink setShowModal={setShowModal} />
              </div>
            </div>
            <AlertSnackBar
              snackbarState={notQPError}
              setSnackbarState={setNotQPError}
              vertical={"top"}
              horizontal={"right"}
              duration={5000}
              sxProps={{ paddingLeft: "3px" }}
              severity={"error"}
              message={
                "Sorry, your account does not qualify as a QP, and we only allow QPs on our platform at this time."
              }
            />
            <AlertSnackBar
              snackbarState={noSelectionMadeError}
              setSnackbarState={setNoSelectionMadeError}
              vertical={"top"}
              horizontal={"right"}
              duration={5000}
              sxProps={{ paddingLeft: "3px" }}
              severity={"error"}
              message={"You must make a selection."}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default IndividualQualification;
