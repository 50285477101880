import React from "react";
import "./NoBlogPostsFoundCardStyles/NoBlogPostsFoundCardStyles.scss";

function NoBlogPostsFoundCard() {
  return (
    <div className="container-xxl d-flex mt-5 pt-5 align-self-center justify-self-center align-items-center justify-content-center text-center">
      <span className="align-self-center blog-posts-not-found-text">
        Sorry, there were no blog posts that matched your search criteria.
        Please try a different search.
      </span>
    </div>
  );
}
export default NoBlogPostsFoundCard;
