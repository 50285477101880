import { useState, useEffect, useContext } from "react";
import { getUserTaxDocuments } from "../Api/DocumentsApi.js";
import { Storage } from "aws-amplify";
import * as dayjs from "dayjs";
import { UserContext } from "../GlobalContexts/UserContext.js";
// var utc = require("dayjs/plugin/utc");

export default function GetTaxDocuments(time) {
  const [taxDocuments, setTaxDocuments] = useState([]);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    async function getUserTaxDocumentsObject() {
      try {
        const response = await getUserTaxDocuments();
        const userTaxDocumentsData = response.data;

        const sortableTaxDocumentsData = userTaxDocumentsData.map((item) => {
          let date = new Date(item.year, 0);
          let unixDate = dayjs(date.toString()).unix();
          let formattedDate = dayjs.unix(unixDate).format("MMM, YYYY");
          return {
            id: item.id,
            name: item.name,
            category: item.category,
            upload_date: item.upload_date,
            year: item.year,
            month: 0,
            unixDate: unixDate,
            documentDate: formattedDate,
            s3_document_link: item.s3_document_link,
            display_document: item.display_document,
            viewed: "Unread",
          };
        });

        sortableTaxDocumentsData.sort((a, b) => {
          if (a.unixDate > b.unixDate) {
            return -1;
          }
          if (a.unixDate < b.unixDate) {
            return 1;
          }
          return 0;
        });

        sortableTaxDocumentsData.forEach((item) => {
          let formattedDate = dayjs.unix(item.unixDate).format("YYYY");
          let X = item.s3_document_link;
          let Y = "private/";
          let Z = X.slice(item.s3_document_link.indexOf(Y) + Y.length);
          let Q = "/";
          let A = Z;
          let intermediaryUrl = Z.slice(A.indexOf(Q) + Q.length);
          async function getSignedURL() {
            var tempURL = await Storage.get(intermediaryUrl, {
              level: "private",
            });

            setTaxDocuments((prevState) => [
              ...prevState,
              {
                id: item.id,
                documentName: item.name,
                documentType: item.category,
                accountName: item.account_name, 
                uploadDate: item.upload_date,
                year: item.year,
                month: item.month,
                documentDate: formattedDate,
                documentLink: item.s3_document_link,
                display: item.display_document,
                viewed: "Unread",
              },
            ]);
          }
          getSignedURL();
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err); }
      }
    }
    getUserTaxDocumentsObject();
  }, [time]);
  return { taxDocuments };
}
