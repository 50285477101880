import React from "react";
import BeneficialOwners from "./BeneficialOwners/BeneficialOwners";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions";

function EntityAccountDetailsBeneficialOwnerInfo({
  accountType,
  relatedAccounts,
  setRelatedAccounts,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-2">
            <span className="entity-account-section-header-text">
              Beneficial Owner Information
            </span>
          </div>
          <BeneficialOwners
            accountType={accountType}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
          />
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default EntityAccountDetailsBeneficialOwnerInfo;
