import React, { useState, useEffect } from "react";
import TabTitle from "./TabTitle.js";
import "./TabsStyles/TabsStyles.css";
function Tabs(props) {
  const [selectedTab, setSelectedTab] = useState(props.initial);

  useEffect(() => {
    setSelectedTab(props.initial);
  }, [props.initial]);

  return (
    <div className="col-12 mt-4 pb-5 mb-4 justify-content-center d-flex">
      <div className="row g-0 w-100 justify-self-center">
        <div className="col-12 container-box">
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row gx-0 w-100 me-3 pt-3 bottom">
                <div
                  className={
                    props.fullWidth
                      ? "col-xxl-7 col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12 ms-3 pe-3"
                      : "col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ms-3 pe-3"
                  }
                >
                  <div className="row g-0 w-100">
                    {props.children.map((item, index) => (
                      <div
                        className={
                          item.props.large
                            ? "col-lg-3 col-md-4 col-sm-4 col-6 h-100"
                            : "col-lg-2 col-md-3 col-sm-4 col-6 h-100"
                        }
                      >
                        <TabTitle
                          key={index}
                          index={index}
                          setSelectedTab={setSelectedTab}
                          title={item.props.title}
                          selected={item.props.selected}
                          setSelected={item.props.setSelected}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col-12">{props.children[selectedTab]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tabs;
