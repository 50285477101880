import React, { useEffect } from "react";
import TrusteeQuestion from "./TrusteeQuestion/TrusteeQuestion";
import TrusteeInfiniteInput from "./TrusteeInfiniteInput/TrusteeInfiniteInput";

function TrusteeSection({
  accountType,
  shouldRenderTrusteeSection,
  showTrusteeSection,
  setShowTrusteeSection,
  mountedStyle,
  unmountedStyle,
  answer,
  setAnswer,
  activeTab,
  setActiveTab,
  addNewTab,
  accountID,
  relatedAccounts,
  setRelatedAccounts,
  handleRemoveFields,
  setActiveSlide,
  hideButton,
  setHideButton,
  hideAlterButtons,
  setHideAlterButtons,
  hasTrusteesError,
  setHasTrusteesError,
  supplyPercentOwned,
}) {
  useEffect(() => supplyPercentOwned && setAnswer(true), [supplyPercentOwned]);
  useEffect(() => {
    if (answer !== "") {
      setShowTrusteeSection(answer);
      setHasTrusteesError("");
    }
  }, [answer]);

  return (
    <React.Fragment>
      {!supplyPercentOwned && (
        <TrusteeQuestion
          questionNumber={1}
          questionText={"Does this trust have any trustees?"}
          answer={answer}
          setAnswer={setAnswer}
          hasTrusteesError={hasTrusteesError}
        />
      )}
      <TrusteeInfiniteInput
        accountType={accountType}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        showTrusteeSection={showTrusteeSection}
        shouldRenderTrusteeSection={shouldRenderTrusteeSection}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        addNewTab={addNewTab}
        accountID={accountID}
        relatedAccounts={relatedAccounts}
        setRelatedAccounts={setRelatedAccounts}
        handleRemoveFields={handleRemoveFields}
        setActiveSlide={setActiveSlide}
        hideButton={hideButton}
        setHideButton={setHideButton}
        hideAlterButtons={hideAlterButtons}
        setHideAlterButtons={setHideAlterButtons}
        supplyPercentOwned={supplyPercentOwned}
      />
    </React.Fragment>
  );
}
export default TrusteeSection;
