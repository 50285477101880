import React, { useState } from "react";
import LoadingButton from "../../../../../../../Components/GlobalComponents/Loading/LoadingButton";

function IndividualAccountBasicInformationButton({
  loading,
  investorProfileComplete,
  showIndividualQualificationSection,
  mountedStyle,
  accountType,
  unmountedStyle,
  onClick,
}) {
  const [text, setText] = useState(
    (investorProfileComplete && accountType === "IND") ? "Add Account" : "Next Step"
  );

  return (
    <div
      className="row g-0 mt-3 w-100 fade-in-test"
      style={showIndividualQualificationSection ? mountedStyle : unmountedStyle}
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-lg btn-class"
          onClick={() => onClick()}
        >
          {loading && (
            <div className="row g-0 w-100">
              <div className="col d-flex align-items-center">
                <span className="justify-self-start align-self-center">
                  {text}
                </span>
                <span
                  class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          )}
          {!loading && <span>{text}</span>}
        </button>
      </div>
    </div>
  );
}
export default IndividualAccountBasicInformationButton;
