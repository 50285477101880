import React from "react";
import "./EntityAccountDetailsStyles/EntityAccountDetailsStyles.scss";
import EntityAccountDetailsHeader from "./EntityAccountDetailsHeader/EntityAccountDetailsHeader";
import EntityAccountDetailsInfo from "./EntityAccountDetailsInfo/EntityAccountDetailsInfo";

function EntityAccountDetails({
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  entityType,
  setEntityType,
  entityTypeError,
  setEntityTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  idType,
  setIDType,
  idTypeError,
  setIDTypeError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
  relatedAccounts,
  setRelatedAccounts,
}) {
  return (
    <React.Fragment>
      <EntityAccountDetailsHeader />
      <EntityAccountDetailsInfo
        accountNumber={accountNumber}
        dateAdded={dateAdded}
        qualificationLevel={qualificationLevel}
        verificationStatus={verificationStatus}
        entityType={entityType}
        setEntityType={setEntityType}
        entityTypeError={entityTypeError}
        setEntityTypeError={setEntityTypeError}
        accountName={accountName}
        setAccountName={setAccountName}
        accountNameError={accountNameError}
        setAccountNameError={setAccountNameError}
        countryOfFormation={countryOfFormation}
        setCountryOfFormation={setCountryOfFormation}
        countryOfFormationError={countryOfFormationError}
        setCountryOfFormationError={setCountryOfFormationError}
        idType={idType}
        setIDType={setIDType}
        idTypeError={idTypeError}
        setIDTypeError={setIDTypeError}
        idNumber={idNumber}
        setIDNumber={setIDNumber}
        idNumberError={idNumberError}
        setIDNumberError={setIDNumberError}
        addressLine1={addressLine1}
        setAddressLine1={setAddressLine1}
        addressLine1Error={addressLine1Error}
        setAddressLine1Error={setAddressLine1Error}
        addressLine2={addressLine2}
        setAddressLine2={setAddressLine2}
        addressLine2Error={addressLine2Error}
        setAddressLine2Error={setAddressLine2Error}
        city={city}
        setCity={setCity}
        cityError={cityError}
        setCityError={setCityError}
        state={state}
        setState={setState}
        stateError={stateError}
        setStateError={setStateError}
        country={country}
        setCountry={setCountry}
        countryError={countryError}
        setCountryError={setCountryError}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        postalCodeError={postalCodeError}
        setPostalCodeError={setPostalCodeError}
        relatedAccounts={relatedAccounts}
        setRelatedAccounts={setRelatedAccounts}
      />
    </React.Fragment>
  );
}
export default EntityAccountDetails;
