import React from "react";
import MobileTransactionCard from "./MobileTransactionCard/MobileTransactionCard";
import "./MobileTransactionSectionStyles/MobileTransactionSectionStyles.css";

function MobileTransactionSection({ transactions }) {
  return (
    <div className="row g-0 w-100 pe-2">
      <div className="col-12">
        {transactions.map((transaction, index) => {
          return (
            <MobileTransactionCard
              key={index}
              itemNum={index}
              transaction={transaction}
            />
          );
        })}
      </div>
    </div>
  );
}
export default MobileTransactionSection;
