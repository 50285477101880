import React from "react";
import "./ConfirmInvestmentButtonSectionStyles/ConfirmInvestmentButtonSectionStyles.scss";

function ConfirmInvestmentButtonSection({
  accountType,
  investSelected,
  confirmInvestment,
  history,
  id,
  submitInvestment,
  setInitial,
  setInvestmentConfirmationSelected,
  setSpecifyInvestmentAmountSelected,
  setInvestSelected,
  investorQualificationError,
  investorVerificationError,
  investmentConfirmationSelected,
  loading,
  allowIraInvestments,
  confirmAltoIRAInvestment,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-75 pb-5">
        <div className="col-xl-8 col-12">
          <div className="button-group ms-5">
            {investSelected ? (
              <React.Fragment>
                <button
                  type="button"
                  class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
                  onClick={async () => {
                    confirmInvestment();
                  }}
                >
                  Confirm Investment
                </button>
                {allowIraInvestments && accountType === "IND" && (
                  <button
                    type="button"
                    class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
                    onClick={() => {
                      confirmAltoIRAInvestment();
                      return null;
                      // confirmInvestment();
                    }}
                  >
                    Invest with Alto IRA
                  </button>
                )}
                <span
                  className="cancel-text align-self-center"
                  onClick={() => setInvestSelected(false)}
                >
                  Cancel
                </span>
                {investorQualificationError ? (
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-10">
                      <div className="row g-0 w-100">
                        <div className="col">
                          <img
                            src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                            alt="error"
                            width="15"
                            height="15"
                          />
                          <span className="ms-3 qp-error-text">
                            {investorQualificationError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {investorVerificationError ? (
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-10">
                      <div className="row g-0 w-100">
                        <div className="col">
                          <img
                            src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                            alt="error"
                            width="15"
                            height="15"
                          />
                          <span className="ms-3 qp-error-text">
                            {investorVerificationError}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  type="button"
                  class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
                  onClick={() => {
                    submitInvestment();
                  }}
                  disabled={loading}
                >
                  Invest
                </button>
                <span
                  className="cancel-text align-self-center"
                  onClick={() => history.push("/invest/" + id)}
                >
                  Go Back
                </span>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ConfirmInvestmentButtonSection;
