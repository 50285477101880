import React from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../../../../Components/countryList.js";

function AccountContactInfo({
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  email,
  setEmail,
  emailError,
  setEmailError,
  domicileCountry,
  setDomicileCountry,
  domicileCountryError,
  setDomicileCountryError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={firstName}
            setValue={setFirstName}
            valueError={firstNameError}
            setValueError={setFirstNameError}
            label={"First Name"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"individual-first-name-input"}
            shrink={true}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={lastName}
            setValue={setLastName}
            valueError={lastNameError}
            setValueError={setLastNameError}
            label={"Last Name"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"individual-last-name-input"}
            shrink={true}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={phoneNumber}
            setValue={setPhoneNumber}
            valueError={phoneNumberError}
            setValueError={setPhoneNumberError}
            label={"Phone Number"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"individual-phone-number-input"}
            shrink={true}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={email}
            setValue={setEmail}
            valueError={emailError}
            setValueError={setEmailError}
            label={"Email"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"individual-email-input"}
            shrink={true}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-0">
        <StyledTextInputField
            value={domicileCountry}
            setValue={setDomicileCountry}
            valueError={domicileCountryError}
            setValueError={setDomicileCountryError}
            label={"Domicile Country"}
            required={false}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"individual-domicile-country-input"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default AccountContactInfo;
