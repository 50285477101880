import React, { useState } from "react";
import "./Styles/Styles.scss";

function LogoContainer() {
  const [aquaLogoLoading, setAquaLogoLoading] = useState(true);

  const aquaLogoOnLoad = () => {
    setAquaLogoLoading(false);
  };

  return (
    <div className="row g-0 w-100 mb-4">
      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <div style={{ display: "inline-block" }}>
              <img
                src={
                  "https://d1swyfveypj49y.cloudfront.net/AquaPlatformHeaderLogo.svg"
                }
                height="36"
                style={{
                  display: aquaLogoLoading ? "none" : "inline-block",
                }}
                onLoad={aquaLogoOnLoad}
              />
              <div
                className="animated-slow"
                height="36"
                style={{
                  height: "36px",
                  width: "100px",
                  borderRadius: "10px",
                  display: aquaLogoLoading ? "inline-block" : "none",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LogoContainer;
