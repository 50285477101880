import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

export const getSignedURL = async (level, path) => {
  return path;
  // Below code is obseleted by backend changes
  let tempPath = path;
  let levelSpecification = level + "/";
  let cleanedPath = tempPath.slice(
    path.indexOf(levelSpecification) + levelSpecification.length
  );
  var signedURL = await Storage.get(cleanedPath, {
    level: level,
  });
  return signedURL;
};
