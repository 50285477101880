import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import Header from "../../../Components/GlobalComponents/Header";
import { styled } from "@mui/material/styles";
import HeaderV2 from "../../../Components/GlobalComponents/HeaderV2";
import * as dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./InvestmentAccountsPageStyles/InvestmentAccountsPageStyles.scss";
import { UserContext } from "./../../../GlobalContexts/UserContext.js";
import GetInvestmentAccountsAdapter from "../../../Adapters/GlobalAdapters/GetInvestmentAccountsAdapter";
import PageLoading from "../../../Components/GlobalComponents/PageLoading/PageLoading";
import CompleteAccountComponent from "../../../Components/GlobalComponents/CompleteAccountComponent/CompleteAccountComponent";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";

var utc = require("dayjs/plugin/utc");

function InvestmentAccountsPage() {
  let history = useHistory();
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useContext(UserContext);

  // const [investmentAccounts, setInvestmentAccounts] = useState([]);
  const { investmentAccounts } = GetInvestmentAccountsAdapter(
    refresh,
    setLoading
  );

  const AccountDetailsButton = styled(Button)({
    fontFamily: "Montserrat",
    fontSize: "12px",
    color: "#0D47A1",
    borderColor: "#0D47A1",
    "&:hover": {
      backgroundColor: "rgb(13, 71, 161, 0.05)",
      borderColor: "#0D47A1",
      boxShadow: "none",
    },
  });

  const CompleteAccountButton = styled(Button)({
    fontFamily: "Montserrat",
    fontSize: "12px",
    color: "#E59335",
    borderColor: "#E59335",
    "&:hover": {
      backgroundColor: "rgb(229, 147, 53, 0.05)",
      borderColor: "#E59335",
      boxShadow: "none",
    },
  });

  const statusCodes = {
    A: "Pending Information",
    I: "Pending Information",
    P: "Pending Verification",
    F: "Failed Verification",
    S: "Successful Verification",
  };

  const accountCodes = {
    IND: "Individual",
    TRT: "Trust",
    ENT: "Entity",
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1")
      console.log(
        "Investment accounts are: " + JSON.stringify(investmentAccounts)
      );
  }, [investmentAccounts]);

  // useEffect(() => {
  //   const processedAccountList =
  //     user &&
  //     user.accountListData.map((account, index) => {
  //       console.log("Account is: " + JSON.stringify(account));
  //       return {
  //         primaryAccount: index == 0,
  //         id: account.number, // To-do: make this UUID
  //         accountNumber: account.number,
  //         accountType: accountCodes[account.account_type],
  //         dateAdded: account.date_added, // To-do: add
  //         accountName: account.name,
  //         status:
  //           account.account_verifications.length === 0
  //             ? "Pending Verification"
  //             : statusCodes[account.account_verifications[0].status],
  //         action: (
  //           account.account_type == "IND"
  //             ? account.investor_profile_complete
  //             : account.entity_profile_complete
  //         )
  //           ? "View Account Details"
  //           : "Complete Pending Account",
  //       };
  //     });

  //   setInvestmentAccounts(processedAccountList);
  //   console.log(processedAccountList);
  // }, [user]);

  const toggleAccountNumber = (rowIndex) => {
    var tempInvestmentAccounts = [...investmentAccounts];
    var tempHideAccountNumber =
      tempInvestmentAccounts[rowIndex].hideAccountNumber;
    tempInvestmentAccounts[rowIndex].hideAccountNumber = !tempHideAccountNumber;
    // setInvestmentAccounts([...tempInvestmentAccounts]);
  };

  const onTableChange = (type, newState) => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log(type);
      console.log(newState);
    }
  };

  const columns = [
    // {
    //   dataField: "accountNumber",
    //   text: "#",
    //   classes: "table-data-style",
    //   headerClasses: "start-padding table-header-text",
    //   formatter: (cell, row, rowIndex) => {
    //     var tempCell = cell;
    //     var copyString = "X".repeat(tempCell.length - 4);
    //     copyString += tempCell.slice(-4);

    //     return (
    //       <div className="d-flex">
    //         <span className="account-button-text">
    //           {row.hideAccountNumber ? copyString : tempCell}
    //         </span>
    //         <span
    //           className="ps-3 account-button-text"
    //           onClick={() => {
    //             toggleAccountNumber(rowIndex);
    //           }}
    //         >
    //           {row.hideAccountNumber
    //             ? "Show Account Number"
    //             : "Hide Account Number"}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "primaryAccount",
      isDummyField: true,
      text: "",
      classes: "table-data-style align-items-center",
      headerClasses: "start-padding table-header-text",
      formatter: (cell, row, rowIndex) => {
        if (row.primaryAccount) {
          return (
            // <div className="row g-0 w-100 align-items-center">
            <img
              src="https://d1swyfveypj49y.cloudfront.net/PrimaryAccountIndicator.svg"
              alt="primary-account-indicator"
              style={{ height: "25px" }}
            />
            // </div>

            // <div
            //   className="star-vector"
            //   style={{ height: "16px", width: "16px" }}
            // />
          );
        } else {
          return null;
        }
      },
    },
    {
      dataField: "accountName",
      text: "Name",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "accountType",
      text: "Type",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    /*{
      dataField: "status",
      text: "Status",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: (cell, row, rowIndex) => {
        if (cell === "Successful Verification") {
          return (
            <div className="d-flex align-items-center">
              <div className="green-circle" style={{ marginRight: "10px" }} />
              <span>{cell}</span>
            </div>
          );
        } else if (
          cell === "Pending Verification" ||
          cell === "Pending Verification Refresh"
        ) {
          return (
            <div className="d-flex align-items-center">
              <div className="orange-circle" style={{ marginRight: "10px" }} />
              <span>{cell}</span>
            </div>
          );
        } else if (cell === "Pending Information") {
          return (
            <div className="d-flex align-items-center">
              <div className="blue-circle" style={{ marginRight: "10px" }} />
              <span>{cell}</span>
            </div>
          );
        } else if (cell === "Failed Verification") {
          return (
            <div className="d-flex align-items-center">
              <div className="red-circle" style={{ marginRight: "10px" }} />
              <span>{cell}</span>
            </div>
          );
        }
      },
    },
    {
      dataField: "dateAdded",
      text: "Date Added",
      classes: "table-data-style align-items-center",
      headerClasses: "start-padding table-header-text",
      formatter: (cell, row, rowIndex) => {
        return dayjs(cell.substring(0, 10)).format("MMM D, YYYY");
      },
    }, */
    {
      dataField: "",
      isDummyField: true,
      text: "",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: (cellContent, row) => {
        if (row.completed) {
          return (
            <AccountDetailsButton
              variant="outlined"
              onClick={() =>
                history.push("/investment-accounts/account-details/" + row.id)
              }
            >
              Account Details >
            </AccountDetailsButton>
          );
        } else if (row.indIncomplete) {
          return (
            <CompleteAccountButton
              variant="outlined"
              onClick={() => history.push("/profile")}
            >
              Complete Investor Profile >
            </CompleteAccountButton>
          );
        } else {
          return (
            <CompleteAccountButton
              variant="outlined"
              onClick={() => history.push("/investment-accounts/add/" + row.id)}
            >
              Complete Account >
            </CompleteAccountButton>
          );
        }
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //   window.open(row.documentLink, "_blank").focus();
      //   console.log("Clicked.");
    },
  };

  const rowClasses = (row) =>
    row.primaryAccount ? "table-row-style-blue" : "table-row-style";

  return loading ? (
    <React.Fragment>
      <Header activePage={"investment-accounts"} />
      <PageLoading />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Header activePage={"investment-accounts"} />
      {/* <HeaderV2 /> */}
      <div className="container-xxl">
        <div className="row g-0 w-100">
          <div className="col-12 pt-5 mt-5">
            <div className="row g-0 w-100 mb-4 mt-5">
              <span className="investment-accounts-page-title-text">
                Investment Accounts
              </span>
            </div>
            {!investmentAccounts[0].investorProfileComplete && (
              <div class="mb-2">
                {" "}
                <CompleteAccountComponent investmentAccountsPage />{" "}
              </div>
            )}
            <div className="row g-0 w-100 mb-3">
              <div className="table-style pt-3 pe-2 d-flex justify-content-center">
                <div className="w-98">
                  <BootstrapTable
                    keyField="id"
                    data={investmentAccounts}
                    columns={columns}
                    rowClasses={rowClasses}
                    rowEvents={rowEvents}
                  />
                  {/* <div className="row g-0 w-100 pt-3 pb-3 align-items-center justify-content-center text-center topBorderClass">
                    <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="btn btn-class add-account-button"
                          onClick={() =>
                            history.push("/investment-accounts/add")
                          }
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src="https://d1swyfveypj49y.cloudfront.net/PlusSign.png"
                              className="me-2 mt-auto mb-auto"
                              style={{ height: "20px", width: "20px" }}
                            ></img>
                            <span className="add-account-button align-self-center">
                              Add Account
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div> */}
                  {investmentAccounts.length === 0 && (
                    <div className=" w-100 pt-5 pb-5 text-center">
                      <span className="no-documents-text">
                        You don't have any statements to show.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default InvestmentAccountsPage;
