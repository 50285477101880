import React from "react";
import StyledTextInputField from "../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../GlobalComponents/InputField/SelectField/SelectField";

function TrustAccountLegalInformationAddressSection({
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  processedStateList,
  country,
  setCountry,
  countryError,
  setCountryError,
  processedCountryList,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="account-legal-information-step-subheader">
          Mailing Address
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledTextInputField
            value={addressLine1}
            setValue={setAddressLine1}
            valueError={addressLine1Error}
            setValueError={setAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"trust-account-address-line-1-input"}
          />
        </div>
        <div className="col pe-0 ps-2">
          <StyledTextInputField
            value={addressLine2}
            setValue={setAddressLine2}
            valueError={addressLine2Error}
            setValueError={setAddressLine2Error}
            label={"Address Line 2"}
            required={false}
            fullWidth={true}
            size={"small"}
            id={"trust-account-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className={country === "United States" ? "col pe-2" : "col pe-0"}>
          <StyledTextInputField
            value={city}
            setValue={setCity}
            valueError={cityError}
            setValueError={setCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"trust-account-address-city-input"}
          />
        </div>
        {country === "United States" && (
          <div className="col pe-0 ps-2">
            <SelectField
              value={state}
              setValue={setState}
              valueError={stateError}
              setValueError={setStateError}
              selectOptions={processedStateList}
              label={"State"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"trust-account-state-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectField
            value={country}
            setValue={setCountry}
            valueError={countryError}
            setValueError={setCountryError}
            label={"Country"}
            selectOptions={processedCountryList}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"trust-account-address-country-input"}
          />
        </div>
        <div className="col pe-0 ps-2">
          <StyledTextInputField
            value={postalCode}
            setValue={setPostalCode}
            valueError={postalCodeError}
            setValueError={setPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"trust-account-postal-code-input"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default TrustAccountLegalInformationAddressSection;
