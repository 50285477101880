import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import StateButtonLoading from "../../../../Components/GlobalComponents/StateButtonLoading/StateButtonLoading.js";
import ReactInputVerificationCode from "react-input-verification-code";
import AlertSnackBar from "../../../../GlobalComponents/AlertSnackBar/AlertSnackBar.js";
import "./VerificationSectionStyles/VerificationSectionStyles.css";

function VerificationSection({ navigation, userId, email }) {
  const [loading, setLoading] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [resendCodeSuccess, setResendCodeSuccess] = useState(false);
  const [resendCodeError, setResendCodeError] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  let history = useHistory();

  useEffect(() => {
    setUserEmail(email);
  }, [email]);

  useEffect(() => {
    if (verificationCode.length === 6) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [verificationCode]);

  const resendConfirmationCode = async () => {
    setResendCodeLoading(true);
    try {
      await Auth.resendSignUp(userEmail);
      setResendCodeSuccess(true);
      setResendCodeLoading(false);
    } catch (err) {
      console.log(err);
      setResendCodeError(true);
      setResendCodeLoading(false);
    }
  };

  const verifyUser = async () => {
    setLoading(true);
    try {
      var cognitoUser = await Auth.confirmSignUp(userEmail, verificationCode);
      // process.env.DEBUG ? console.log(cognitoUser) : null
      if (process.env.REACT_APP_DEBUG === "1") console.log(cognitoUser);
      // const user = {
      //   username: userId,
      //   account_status: "A",
      // };
      setLoading(false);
      history.push({
        pathname: "/login",
        state: { accountCreationSuccessful: true },
      });
    } catch (err) {
      // process.env.DEBUG ? console.error(err) : null
      if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      setVerificationCodeError(
        "Verification failed. Please enter a valid verification code."
      );
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 fillScreen align-items-center justify-content-center d-flex">
      <div className="row g-0 w-100 align-items-center justify-content-start">
        <div
          className="col-6 d-none d-sm-none d-md-none d-lg-block"
          style={{ backgroundColor: "#F0F4FD", zIndex: 1 }}
        >
          <img
            src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
            style={{
              height: "100vh",
              backgroundSize: "cover !important",
              zIndex: 2,
            }}
            alt="community"
          ></img>
        </div>
        <div
          className="col-xxl-6 col-xl-5 col-lg-6 col-12"
          style={{
            zIndex: 3,
            backgroundColor: "#f7f7fa",
            height: "100vh",
          }}
        >
          <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
            <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
              <div className="col-12">
                <div className="row g-0 w-100 mb-3">
                  <div className="col-12 text-lg-start text-start pe-3">
                    <span className="Verification-header-text">
                      Verify your email
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4 justify-content-lg-start justify-content-start">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-11 col-sm-11 col-11 text-lg-start text-start pe-3">
                    <span className="Verification-subheader-text">
                      We emailed you a six-digit code to:{" "}
                      <span class="badge bg-light text-dark">
                        {email.toLowerCase()}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-0">
                  <div className="col-11 text-lg-start text-center pe-3">
                    <span className="enter-message-text text-center">
                      Enter the code below to confirm your email address
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-2">
                  <div className="col-11 text-lg-start text-center pe-3">
                    <span
                      className="enter-message-text text-center"
                      style={{ fontSize: "12px", fontWeight: 400 }}
                    >
                      *Please check your spam mail if the code does not appear
                      in your normal inbox.
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-3 justify-content-lg-start justify-content-center">
                  <div className="col-10 d-flex justify-content-lg-start justify-content-center">
                    <div className="custom-styles shift-left d-flex justify-content-center">
                      <ReactInputVerificationCode
                        placeholder=""
                        length={6}
                        onChange={(value) => setVerificationCode(value)}
                      />
                      {verificationCodeError && (
                        <span className="SignupForm-field-error-text mt-1 pe-2">
                          {verificationCodeError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-4">
                  <div className="col-11 text-lg-start text-center pe-3">
                    {resendCodeLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        style={{ color: "#0D47A1" }}
                      ></span>
                    ) : (
                      <span
                        className="enter-message-text text-center"
                        style={{ fontSize: "12px", fontWeight: 400 }}
                      >
                        Never received the confirmation code? Click to
                        <span
                          style={{ cursor: "pointer", color: "#0D47A1" }}
                          onClick={() => resendConfirmationCode()}
                        >
                          {" "}
                          resend code.
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="row g-0 w-100 mb-5 justify-content-lg-start justify-content-center">
                  <StateButtonLoading
                    loading={loading}
                    proceed={proceed}
                    onClick={() => {
                      if (proceed) {
                        verifyUser();
                      }
                    }}
                  />
                </div>
                <div className="row g-0 w-100">
                  <div className="col-xxl-6 col-xl-6 col-lg-7 col-sm-6 col-8">
                    <div className="row gx-1 w-100">
                      <div className="row g-0 w-100">
                        <div className="col">
                          <div className="progress-bar-completed"></div>
                        </div>
                        <div className="col">
                          <div className="progress-bar-completed"></div>
                        </div>
                        <div className="col">
                          <div className="progress-bar-completed"></div>
                        </div>
                        <div className="col">
                          <div className="progress-bar-completed"></div>
                        </div>
                        <div className="col">
                          <div className="progress-bar-completed"></div>
                        </div>
                        <div className="col">
                          <div className="progress-bar-completed"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <AlertSnackBar
                  snackbarState={resendCodeSuccess}
                  setSnackbarState={setResendCodeSuccess}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={"Confirmation code resent successfully."}
                />
                <AlertSnackBar
                  snackbarState={resendCodeError}
                  setSnackbarState={setResendCodeError}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={
                    "There was an error sending a confirmation code to " +
                    userEmail +
                    ". Please confirm that you have signed up using a valid email that you have access to."
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VerificationSection;
