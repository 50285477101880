import React from "react";
import { useHistory } from "react-router-dom";
import "./NavLinksSectionStyles/NavLinksSectionStyles.css";

function NavLinksSection() {
  let history = useHistory();
  return (
    <div className="row">
      <div className="col-sm-4">
        <span
          className="progress-text-bold"
          onClick={() => history.push("/invest")}
          style={{ cursor: "pointer" }}
        >
          Invest
        </span>

        <img className="ms-1" src="https://d1swyfveypj49y.cloudfront.net/SideArrow.svg" alt="sidearrow"></img>
        <a className="disable-underline" href="/invest">
          <span className="progress-text-unbolded ms-1">Fund</span>
        </a>
      </div>
    </div>
  );
}
export default NavLinksSection;
