import React, { useEffect } from "react";
import StyledDateInputField from "../../../../../../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import { formatSSN } from "../../../../../../../../GlobalFunctions/globalFunctions";

function IndividualAccountDetailsPII({
  domicileCountry,
  dob,
  setDob,
  dobError,
  setDobError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
}) {
  return (
    <div className="row g-0 w-100 mb-4">
      <div className="col pe-2">
        <StyledDateInputField
          value={dob}
          setValue={setDob}
          valueError={dobError}
          setValueError={setDobError}
          label={"Date Of Birth"}
          required={true}
          fullWidth={true}
          size={"small"}
          disabled={true}
          shrink={true}
          id={"individual-account-details-dob-input"}
        />
      </div>
      <div className="col ps-2 pe-0">
        {domicileCountry === "United States" && (
          <StyledTextInputField
            value={idNumber}
            setValue={setIDNumber}
            valueError={idNumberError}
            setValueError={setIDNumberError}
            label={"SSN"}
            required={true}
            fullWidth={true}
            disabled={true}
            shrink={true}
            size={"small"}
            mask={{ maskFormatter: formatSSN, maskPattern: "999-99-9999" }}
            id={"individual-account-details-ssn-input"}
          />
        )}
        {domicileCountry !== "United States" && (
          <StyledTextInputField
            value={idNumber}
            setValue={setIDNumber}
            valueError={idNumberError}
            setValueError={setIDNumberError}
            label={"Passport Number"}
            required={true}
            fullWidth={true}
            disabled={true}
            shrink={true}
            size={"small"}
            id={"individual-account-details-passport-input"}
          />
        )}
      </div>
    </div>
  );
}
export default IndividualAccountDetailsPII;
