import React, { useState, useEffect } from "react";
import "./AccountLegalInformationStyles/AccountLegalInformationStyles.scss";
import IndividualAccountPersonalInformation from "./IndividualAccountPersonalInformation/IndividualAccountPersonalInformation";
import TrustAccountLegalInformation from "./TrustAccountLegalInformation/TrustAccountLegalInformation";
import EntityAccountLegalInformation from "./EntityAccountLegalInformation/EntityAccountLegalInformation";

function AccountLegalInformation({
  accountID,
  accountType,
  countryOfFormation,
  domicileCountry,
  setDomicileCountry,
  processedCountryList,
  processedStateList,
  setActiveSlide,
}) {
  const [componentAccountType, setComponentAccountType] = useState(accountType); //maybe this should be passed in from the parent, because some of this information will be available when that component is loaded the request to the backend can be made at that component
  const [trustType, setTrustType] = useState("LT");

  useEffect(() => {
    if (accountType !== "") {
      setComponentAccountType(accountType);
    }
  }, [accountType]);

  useEffect(() => {
    if (componentAccountType === "LT") {
      setTrustType("LT");
    } else if (componentAccountType === "TRUST") {
      setTrustType("TRUST");
    } else {
      setTrustType("");
    }
  }, [componentAccountType]);
  //will need to make a request to the backend to pull in some of this information if the user is returning to complete the account information

  const idTypeOptions = [
    { value: "EIN", label: "EIN" },
    { value: "FTID", label: "Foreign Tax ID" },
    { value: "ITIN", label: "ITIN" },
  ];
  return componentAccountType === "IND" ? (
    <IndividualAccountPersonalInformation
      accountID={accountID}
      processedCountryList={processedCountryList}
      processedStateList={processedStateList}
      domicileCountry={domicileCountry}
      setDomicileCountry={setDomicileCountry}
    />
  ) :
  componentAccountType === "JOINT" ? (
    <IndividualAccountPersonalInformation
    accountID={accountID}
    joint={true}
    processedCountryList={processedCountryList}
    processedStateList={processedStateList}
    domicileCountry={domicileCountry}
    setDomicileCountry={setDomicileCountry}
  />   
  ) 
  : componentAccountType === "TRT" ||
    componentAccountType === "TRUST" ||
    componentAccountType === "LT" ? (
    <TrustAccountLegalInformation
      accountID={accountID}
      countryOfFormation={countryOfFormation}
      trustType={trustType}
      idTypeOptions={idTypeOptions}
      processedCountryList={processedCountryList}
      processedStateList={processedStateList}
      setActiveSlide={setActiveSlide}
    />
  ) : componentAccountType === "LLC" ||
    componentAccountType === "C-CORP" ||
    componentAccountType === "S-CORP" ||
    componentAccountType === "LP" ||
    componentAccountType === "GP" ||
    componentAccountType === "SM_LLC" || 
    componentAccountType === "FE" ? (
    <EntityAccountLegalInformation
      accountID={accountID}
      componentAccountType={componentAccountType}
      countryOfFormation={countryOfFormation}
      idTypeOptions={idTypeOptions}
      processedStateList={processedStateList}
      processedCountryList={processedCountryList}
      setActiveSlide={setActiveSlide}
    />
  ) : null;
}
export default AccountLegalInformation;
