import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";

import "./BlogPostCardStyles/BlogPostCardStyles.scss";

function BlogPostCard({ blogPost }) {
  let history = useHistory();
  const isLargeScreen = useMediaQuery({ query: `(min-width: 1200px)` });
  const isExtraSmallScreen = useMediaQuery({
    minWidth: 300,
    maxWidth: 425,
  });

  const isSmallScreen = useMediaQuery({ minWidth: 550, maxWidth: 775 });

  const [thumbnailLoading, setThumbnailLoading] = useState(true);
  const [authorPicLoading, setAuthorPicLoading] = useState(true);

  const thumbNailOnLoad = () => {
    setThumbnailLoading(false);
  };

  const authorPicOnLoad = () => {
    setAuthorPicLoading(false);
  };

  return (
    <div className="col">
      <div
        className="card h-100 blog-post-card"
        onClick={() => history.push("/learn/" + blogPost.link)}
      >
        <img
          src={blogPost.thumbnailLink}
          className="card-img-top animated"
          style={{ display: thumbnailLoading ? "none" : "block" }}
          alt="thumbnail"
          onLoad={thumbNailOnLoad}
        />
        <div
          className="card-img-top animated"
          style={{
            width: "100%",
            minHeight:
              isLargeScreen || isExtraSmallScreen
                ? "150px"
                : isSmallScreen
                ? "275px"
                : "200px",
            display: thumbnailLoading ? "inline-block" : "none",
          }}
        />
        <div className="card-body">
          <h5
            className="card-title pb-1"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontStyle: "bold",
              color: "#171721",
            }}
          >
            {blogPost.postTitle}
          </h5>
          <p
            className="card-text pb-2"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "300, Light",
              fontStyle: "normal",
              color: "#8083A3",
            }}
          >
            {blogPost.postSubtitle}
          </p>
        </div>
        <div style={{"padding": "1rem" }} className="row g-0 w-100 align-items-center">
            <div className="d-flex align-items-center">
              <img
                src={blogPost.authorProfilePicLink}
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  display: authorPicLoading ? "none" : "block",
                }}
                onLoad={authorPicOnLoad}
              />
              <div
                className="animated-slow"
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  display: authorPicLoading ? "block" : "none",
                }}
              />
              <div
                className="card-text ps-3"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600, Semi Bold",
                  fontStyle: "normal",
                  color: "#171721",
                }}
              >
                {blogPost.authorName}
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
export default BlogPostCard;
