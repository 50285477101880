import React from "react";
import SelectField from "../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import StyledTextInputField from "../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";

function EntityAccountLegalInformationTaxSection({
  countryOfFormation,
  idType,
  setIDType,
  idTypeError,
  setIDTypeError,
  idTypeOptions,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="account-legal-information-step-subheader">
          Tax Information
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        {countryOfFormation !== "United States" && (
          <div className="col pe-2">
            <SelectField
              value={idType}
              setValue={setIDType}
              valueError={idTypeError}
              setValueError={setIDTypeError}
              label={"Tax ID Type"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"entity-account-id-type-select"}
              selectOptions={idTypeOptions}
            />
          </div>
        )}
        <div
          className={
            countryOfFormation === "United States"
              ? "col pe-0"
              : "col pe-0 ps-2"
          }
        >
          {countryOfFormation === "United States" && (
            <StyledTextInputField
              value={idNumber}
              setValue={setIDNumber}
              valueError={idNumberError}
              setValueError={setIDNumberError}
              label={"EIN"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"entity-account-ein-input"}
            />
          )}
          {countryOfFormation !== "United States" && (
            <StyledTextInputField
              value={idNumber}
              setValue={setIDNumber}
              valueError={idNumberError}
              setValueError={setIDNumberError}
              label={"Tax ID Number"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"entity-account-tax-id-input"}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default EntityAccountLegalInformationTaxSection;
