import React, {useEffect, useState} from 'react';
// import useWindowDimensions from '../../../../../Components/GlobalComponents/WindowDimensions/useWindowDimensions';
// import FundOverviewReferenceCard from './FundOverviewReferenceCard';
import GreyBorderBoxRow from '../../../../../Components/GlobalComponents/GreyBorderBoxRow/GreyBorderBoxRow';
import GreyBorderWidgetRow from '../../../../../Components/GlobalComponents/GreyBorderWidgetRow/GreyBorderWidgetRow';
import './OverviewSectionStyles/OverviewSectionStyles.scss';

const FundOverviewReferenceSection = ({referenceTerms}) => {
    const [displayTerms, setDisplayTerms] = useState([])

    useEffect(() => {
        //console.log('Reference Terms section: ', referenceTerms)
        let terms = referenceTerms.filter(term => term.display_term === true)   
        setDisplayTerms(terms);
    },[])

  return (
  <> 
    {/* <GreyBorderBoxRow cardItems={displayTerms} /> */}
    <GreyBorderWidgetRow cardItems={displayTerms} />
  </>) ;
};

export default FundOverviewReferenceSection;
