import axios from "axios";
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import Search from "antd/lib/transfer/search";

export const getBlogPostByLink = async (blogPostLink) => {
  const link = blogPostLink.toString();
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      link: link,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return axios.get(HOST + "/blog", requestParams);
};

export const searchBlogPosts = async (searchKeywords) => {
  // const link = blogPostLink.toString();
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      query: searchKeywords,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return axios.get(HOST + "/search-blogs", requestParams);
};
