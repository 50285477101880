import axios from "axios";
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "./GlobalFunctions/RequestHeaders.js";

const djangoApi = axios.create({
  baseUrl: HOST,
});

//Dev environment endpoint
export const getUserTransactions = async (
  fromDate,
  toDate,
  status,
  transactionType,
  accountId
) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {},
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  if (accountId !== null) {
    requestParams.params.account_id = accountId;
  }

  if (fromDate !== null) {
    var date = new Date(fromDate.year, fromDate.month - 1, fromDate.day);
    var constructedFromDate = date.toISOString();
    requestParams.params.start_date = constructedFromDate;
  }

  if (toDate !== null) {
    var date2 = new Date(toDate.year, toDate.month - 1, toDate.day);
    var constructedToDate = date2.toISOString();
    requestParams.params.end_date = constructedToDate;
  }

  if (status !== "All") {
    let statuses = {
      Pending: "PENDING",
      Processing: "IN_PROCESS",
      Completed: "COMPLETED",
      Failed: "FAILED",
    };
    requestParams.params.transaction_status = statuses[status];
  }

  if (transactionType !== "All") {
    let types = {
      Deposit: "DEPOSIT",
      Disbursement: "DISBURSEMENT",
    };
    requestParams.params.transaction_type = types[transactionType];
  }
  return axios.get(HOST + "/investor-transactions-by-date/", requestParams);
};
