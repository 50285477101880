import React from "react";
import "./Styles/styles.scss";

function RadioInputGroup(props) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-3">
            <span className="body-text-sm-bold pe-3">{props.radioText}</span>
          </div>
          <div className="row g-0 w-100">
            <div className="d-flex">
              {props.children.map((child, index) => {
                return index !== props.children.length - 1 ? (
                  <div className="me-5">{child}</div>
                ) : (
                  <div>{child}</div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default RadioInputGroup;
