import React from "react";

function CoverImage() {
  return (
    <div className="col-6 h-100 d-none d-sm-none d-md-none d-lg-block cover">
      <img
        src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
        style={{
          height: "100vh",
          backgroundSize: "cover !important",
          zIndex: 2,
        }}
        alt="community"
      ></img>
    </div>
  );
}
export default CoverImage;
