import React, { useState, useEffect } from "react";
import "./StateBasedButton.css";

function StateBasedButton({ proceed, text }) {
  const [buttonText, setButtonText] = useState("");
  const [divClass, setDivClass] = useState("button-box-gray ps-1 pe-1");
  const [textClass, setTextClass] = useState("button-text-gray");

  useEffect(() => {
    setDivClass(proceed ? "button-box ps-1 pe-1" : "button-box-gray ps-1 pe-1");
    setTextClass(proceed ? "button-text" : "button-text-gray");
  }, [proceed]);
  useEffect(() => {
    setButtonText(text);
  }, [text]);
  return (
    <div className={divClass}>
      <span className={textClass}>{buttonText}</span>
    </div>
  );
}
export default StateBasedButton;
