import React, { useState, useEffect } from "react";
import "./FAQsStyles/FAQsStyles.css";
import Header from "../../../Components/GlobalComponents/Header.js";
import SpinnerFullPage from "../../../Components/GlobalComponents/Loading/SpinnerFullPage";
import AccordionCard from "../../../Components/GlobalComponents/Accordion/AccordionCard";
import GetFaqsAdapter from "../../../Adapters/FaqsAdapter.js";
let FAQS = {}

function FAQs() {
  //   const timeStamp = Date.now();
  const [initialApiRequest, setInitialApiRequest] = useState(false);
  const [general, setGeneral] = useState([])
  const [platform, setPlatform] = useState([])
  const { generalFaqs, platformFaqs, peBasicsFaqs, glossaryFaqs} =
    GetFaqsAdapter(initialApiRequest);
	// , platformFaqs, peBasicsFaqs, glossaryFaqs

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log("generalFaqs " + JSON.stringify(generalFaqs));
	  console.log('initial api req: ', initialApiRequest)
    }
  }, [generalFaqs]);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
		console.log("platformFaqs " + JSON.stringify(platformFaqs));
	  }
  }, [platformFaqs]);


  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log("peBasicsFaqs " + JSON.stringify(peBasicsFaqs));
    }
  }, [peBasicsFaqs]);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log("glossaryFaqs " + JSON.stringify(glossaryFaqs));
    }
  }, [glossaryFaqs]);

//   useEffect(() => {
// 	setGeneral(generalFaqs)
// 	console.log('general faq: ', general)
//   },[])

  let comingSoon = (
	<>
	<div className="row g-0 w-100">
		<div className="coming-soon-container h-100 align-self-center pb-5">
			<div className="row g-0 w-100 h-100 justify-content-center text-center">
				<div className="col">
					<div className="row d-flex align-items-center align-self-center justify-content-center text-center">
						<span className="coming-soon-text mt-5 h-25">
							Coming Soon
						</span>
						<span className="message mt-3 w-75">
							The FAQs page is currently being
							built out by our engineering
							team and will be released soon.
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	</>
)

let faqCards = (
	<>
	{generalFaqs.length > 0 ? (
		<AccordionCard  title={'General'} items={generalFaqs}/>
	) : (null)}

	{ platformFaqs.length > 0 ? (
		<AccordionCard  title={'Platform'} items={platformFaqs}/>
	) : (null)}

	{ peBasicsFaqs.length > 0 ? (
		<AccordionCard  title={'PE Basics'} items={peBasicsFaqs}/>
	) : (null)}

	{ glossaryFaqs.length > 0 ? (
		<AccordionCard  title={'Glossary'} items={glossaryFaqs}/>
	) : (null)}
	</>
)

// ): (<SpinnerFullPage btnClass={'primary'} />)}
let dynamicFAQ = (
	<>
	 <div className="container-xxl container-fluid pt-5 mt-5 ps-xxl-0 pe-xxl-0 ps-5 pe-5">
				<div className="row g-0 w-100"> 
					<div className="col-12">
						<div className="row g-0 w-100 mb-4 mt-4 text-center">
							<span className="default-header-text-500">Frequently Asked Questions</span>
						</div>
						<div className="row g-0 w-100"></div>
						{faqCards}	
	 					</div>
				</div>
		</div>
	</>
)

  return (
    <React.Fragment>
      <Header activePage={"faq"} />
      	{dynamicFAQ}
    </React.Fragment>
  );
}
export default FAQs;

      