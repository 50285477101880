import React, { useEffect } from "react";
import { css as emoCSS } from "@emotion/css";
import styled from "@emotion/styled";
import starWarsNames from "starwars-names";
import { matchSorter } from "match-sorter";
import { Global, css as newcss } from "@emotion/react";
import "./TrustAccountTypeStyles/CountryInputStyles.css";

const GlobalStyles = newcss`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

  @font-face {
    font-family: "Local Font";
    src: url("fonts/DancingScript-Regular.ttf");
  }

  * {
    text-align: center;
  }
`;

// console.log("All items are: " + JSON.stringify(starWarsNames));

const allItems = starWarsNames.all.map((s) => ({
  name: s,
  id: s.toLowerCase(),
}));
const countryList = [
  "Afghanistan",
  "Åland Islands",
  // ('Afghanistan', 'Afghanistan'),
  // ('Åland Islands', 'Åland Islands'),
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua And Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  // "Bangladesh",
  "Barbados",
  // "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia, Plurinational State Of",
  "Bonaire, Sint Eustatius And Saba",
  "Bosnia And Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  // "Central African Republic",
  "Chad",
  "Chile",
  // "China",
  "Christmas Island",
  "Cocos (keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  // "Congo, The Democratic Republic Of The",
  "Cook Islands",
  "Costa Rica",
  "Côte D'ivoire",
  "Croatia",
  // "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  // "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island And Mcdonald Islands",
  "Holy See (vatican City State)",
  "Honduras",
  // "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  // "Iran, Islamic Republic Of",
  // "Iraq",
  "Ireland",
  "Isle Of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Republic Of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  // "Lebanon",
  "Lesotho",
  // "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, TheFormer Yugoslav Republic Of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  // "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States Of",
  "Moldova, Republic Of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  // "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  // "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State Of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena, Ascension And Tristan Da Cunha",
  "Saint Kitts And Nevis",
  "Saint Lucia",
  "Saint Martin (french Part)",
  "Saint Pierre And Miquelon",
  "Saint Vincent And The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome And Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (dutch Part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  // "Somalia",
  "South Africa",
  "South Georgia And The South Sandwich Islands",
  // "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard And Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Taiwan, Province Of China",
  "Tajikistan",
  "Tanzania, United Republic Of",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad And Tobago",
  "Tunisia",
  "Turkmenistan",
  "Turks And Caicos Islands",
  "Tuvalu",
  "Uganda",
  // "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  // "Venezuela, Bolivarian Republic Of",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.s.",
  "Wallis And Futuna",
  "Western Sahara",
  // "Yemen",
  "Zambia",
  // "Zimbabwe",
];
// const countryList = ["United States"];
// const countryList = [
//   "Afghanistan",
//   "Albania",
//   "Algeria",
//   "American Samoa",
//   "Andorra",
//   "Angola",
//   "Anguilla",
//   "Antarctica",
//   "Antigua and Barbuda",
//   "Argentina",
//   "Armenia",
//   "Aruba",
//   "Australia",
//   "Austria",
//   "Azerbaijan",
//   "Bahamas (the)",
//   "Bahrain",
//   "Bangladesh",
//   "Barbados",
//   "Belarus",
//   "Belgium",
//   "Belize",
//   "Benin",
//   "Bermuda",
//   "Bhutan",
//   "Bolivia (Plurinational State of)",
//   "Bonaire, Sint Eustatius and Saba",
//   "Bosnia and Herzegovina",
//   "Botswana",
//   "Bouvet Island",
//   "Brazil",
//   "British Indian Ocean Territory (the)",
//   "Brunei Darussalam",
//   "Bulgaria",
//   "Burkina Faso",
//   "Burundi",
//   "Cabo Verde",
//   "Cambodia",
//   "Cameroon",
//   "Canada",
//   "Cayman Islands (the)",
//   "Central African Republic (the)",
//   "Chad",
//   "Chile",
//   "China",
//   "Christmas Island",
//   "Cocos (Keeling) Islands (the)",
//   "Colombia",
//   "Comoros (the)",
//   "Congo (the Democratic Republic of the)",
//   "Congo (the)",
//   "Cook Islands (the)",
//   "Costa Rica",
//   "Croatia",
//   "Cuba",
//   "Curaçao",
//   "Cyprus",
//   "Czechia",
//   "Côte d'Ivoire",
//   "Denmark",
//   "Djibouti",
//   "Dominica",
//   "Dominican Republic (the)",
//   "Ecuador",
//   "Egypt",
//   "El Salvador",
//   "Equatorial Guinea",
//   "Eritrea",
//   "Estonia",
//   "Eswatini",
//   "Ethiopia",
//   "Falkland Islands (the) [Malvinas]",
//   "Faroe Islands (the)",
//   "Fiji",
//   "Finland",
//   "France",
//   "French Guiana",
//   "French Polynesia",
//   "French Southern Territories (the)",
//   "Gabon",
//   "Gambia (the)",
//   "Georgia",
//   "Germany",
//   "Ghana",
//   "Gibraltar",
//   "Greece",
//   "Greenland",
//   "Grenada",
//   "Guadeloupe",
//   "Guam",
//   "Guatemala",
//   "Guernsey",
//   "Guinea",
//   "Guinea-Bissau",
//   "Guyana",
//   "Haiti",
//   "Heard Island and McDonald Islands",
//   "Holy See (the)",
//   "Honduras",
//   "Hong Kong",
//   "Hungary",
//   "Iceland",
//   "India",
//   "Indonesia",
//   "Iran (Islamic Republic of)",
//   "Iraq",
//   "Ireland",
//   "Isle of Man",
//   "Israel",
//   "Italy",
//   "Jamaica",
//   "Japan",
//   "Jersey",
//   "Jordan",
//   "Kazakhstan",
//   "Kenya",
//   "Kiribati",
//   "Korea (the Democratic People's Republic of)",
//   "Korea (the Republic of)",
//   "Kuwait",
//   "Kyrgyzstan",
//   "Lao People's Democratic Republic (the)",
//   "Latvia",
//   "Lebanon",
//   "Lesotho",
//   "Liberia",
//   "Libya",
//   "Liechtenstein",
//   "Lithuania",
//   "Luxembourg",
//   "Macao",
//   "Madagascar",
//   "Malawi",
//   "Malaysia",
//   "Maldives",
//   "Mali",
//   "Malta",
//   "Marshall Islands (the)",
//   "Martinique",
//   "Mauritania",
//   "Mauritius",
//   "Mayotte",
//   "Mexico",
//   "Micronesia (Federated States of)",
//   "Moldova (the Republic of)",
//   "Monaco",
//   "Mongolia",
//   "Montenegro",
//   "Montserrat",
//   "Morocco",
//   "Mozambique",
//   "Myanmar",
//   "Namibia",
//   "Nauru",
//   "Nepal",
//   "Netherlands (the)",
//   "New Caledonia",
//   "New Zealand",
//   "Nicaragua",
//   "Niger (the)",
//   "Nigeria",
//   "Niue",
//   "Norfolk Island",
//   "Northern Mariana Islands (the)",
//   "Norway",
//   "Oman",
//   "Pakistan",
//   "Palau",
//   "Palestine, State of",
//   "Panama",
//   "Papua New Guinea",
//   "Paraguay",
//   "Peru",
//   "Philippines (the)",
//   "Pitcairn",
//   "Poland",
//   "Portugal",
//   "Puerto Rico",
//   "Qatar",
//   "Republic of North Macedonia",
//   "Romania",
//   "Russian Federation (the)",
//   "Rwanda",
//   "Réunion",
//   "Saint Barthélemy",
//   "Saint Helena, Ascension and Tristan da Cunha",
//   "Saint Kitts and Nevis",
//   "Saint Lucia",
//   "Saint Martin (French part)",
//   "Saint Pierre and Miquelon",
//   "Saint Vincent and the Grenadines",
//   "Samoa",
//   "San Marino",
//   "Sao Tome and Principe",
//   "Saudi Arabia",
//   "Senegal",
//   "Serbia",
//   "Seychelles",
//   "Sierra Leone",
//   "Singapore",
//   "Sint Maarten (Dutch part)",
//   "Slovakia",
//   "Slovenia",
//   "Solomon Islands",
//   "Somalia",
//   "South Africa",
//   "South Georgia and the South Sandwich Islands",
//   "South Sudan",
//   "Spain",
//   "Sri Lanka",
//   "Sudan (the)",
//   "Suriname",
//   "Svalbard and Jan Mayen",
//   "Sweden",
//   "Switzerland",
//   "Syrian Arab Republic",
//   "Taiwan",
//   "Tajikistan",
//   "Tanzania, United Republic of",
//   "Thailand",
//   "Timor-Leste",
//   "Togo",
//   "Tokelau",
//   "Tonga",
//   "Trinidad and Tobago",
//   "Tunisia",
//   "Turkey",
//   "Turkmenistan",
//   "Turks and Caicos Islands (the)",
//   "Tuvalu",
//   "Uganda",
//   "Ukraine",
//   "United Arab Emirates (the)",
//   "United Kingdom of Great Britain and Northern Ireland (the)",
//   "United States Minor Outlying Islands (the)",
//   "United States of America (the)",
//   "Uruguay",
//   "Uzbekistan",
//   "Vanuatu",
//   "Venezuela (Bolivarian Republic of)",
//   "Viet Nam",
//   "Virgin Islands (British)",
//   "Virgin Islands (U.S.)",
//   "Wallis and Futuna",
//   "Western Sahara",
//   "Yemen",
//   "Zambia",
//   "Zimbabwe",
//   "Åland Islands",
// ];
// const list = [
//   "Alabama",
//   "Alaska",
//   "American Samoa",
//   "Arizona",
//   "Arkansas",
//   "California",
//   "Colorado",
//   "Connecticut",
//   "Delaware",
//   "District of Columbia",
//   "Federated States of Micronesia",
//   "Florida",
//   "Georgia",
//   "Guam",
//   "Hawaii",
//   "Idaho",
//   "Illinois",
//   "Indiana",
//   "Iowa",
//   "Kansas",
//   "Kentucky",
//   "Louisiana",
//   "Maine",
//   "Marshall Islands",
//   "Maryland",
//   "Massachusetts",
//   "Michigan",
//   "Minnesota",
//   "Mississippi",
//   "Missouri",
//   "Montana",
//   "Nebraska",
//   "Nevada",
//   "New Hampshire",
//   "New Jersey",
//   "New Mexico",
//   "New York",
//   "North Carolina",
//   "North Dakota",
//   "Northern Mariana Islands",
//   "Ohio",
//   "Oklahoma",
//   "Oregon",
//   "Palau",
//   "Pennsylvania",
//   "Puerto Rico",
//   "Rhode Island",
//   "South Carolina",
//   "South Dakota",
//   "Tennessee",
//   "Texas",
//   "Utah",
//   "Vermont",
//   "Virgin Island",
//   "Virginia",
//   "Washington",
//   "West Virginia",
//   "Wisconsin",
//   "Wyoming",
// ];
const trustList = ["Irrevocable Trust", "Revocable Trust"];

// const listOfCountries = countryList.map((s) => ({
//   name: s,
//   label: s.toLowerCase(),
// }));

const listOfTrustTypes = trustList.map((s) => ({
  name: s,
  label: s.toLowerCase(),
}));

// const listOfStates = [
//   "Alabama",
//   "Alaska",
//   "American Samoa",
//   "Arizona",
//   "Arkansas",
//   "California",
//   "Colorado",
//   "Connecticut",
//   "Delaware",
//   "District of Columbia",
//   "Federated States of Micronesia",
//   "Florida",
//   "Georgia",
//   "Guam",
//   "Hawaii",
//   "Idaho",
//   "Illinois",
//   "Indiana",
//   "Iowa",
//   "Kansas",
//   "Kentucky",
//   "Louisiana",
//   "Maine",
//   "Marshall Islands",
//   "Maryland",
//   "Massachusetts",
//   "Michigan",
//   "Minnesota",
//   "Mississippi",
//   "Missouri",
//   "Montana",
//   "Nebraska",
//   "Nevada",
//   "New Hampshire",
//   "New Jersey",
//   "New Mexico",
//   "New York",
//   "North Carolina",
//   "North Dakota",
//   "Northern Mariana Islands",
//   "Ohio",
//   "Oklahoma",
//   "Oregon",
//   "Palau",
//   "Pennsylvania",
//   "Puerto Rico",
//   "Rhode Island",
//   "South Carolina",
//   "South Dakota",
//   "Tennessee",
//   "Texas",
//   "Utah",
//   "Vermont",
//   "Virgin Island",
//   "Virginia",
//   "Washington",
//   "West Virginia",
//   "Wisconsin",
//   "Wyoming",
// ].map((s) => ({
//   name: s,
//   id: s.toLowerCase(),
// }));

const css = (...args) => ({ className: emoCSS(...args) });

const Item = styled("li")(
  {
    position: "relative",
    cursor: "pointer",
    display: "block",
    border: "none",
    height: "auto",
    textAlign: "left",
    borderTop: "none",
    lineHeight: "1em",
    color: "#262A3D",
    fontFamily: "Montserrat",
    fontSize: "15px",
    textTransform: "none",
    fontWeight: "400",
    boxShadow: "none",
    padding: ".8rem 1.1rem",
    whiteSpace: "normal",
    wordWrap: "normal",
  },
  ({ isActive, isSelected }) => {
    const styles = [];
    if (isActive) {
      styles.push({
        color: "rgba(0,0,0,.95)",
        background: "rgba(0,0,0,.03)",
      });
    }
    if (isSelected) {
      styles.push({
        color: "rgba(0,0,0,.95)",
        fontWeight: "700",
      });
    }
    return styles;
  }
);
const onAttention = " &:focus";
const TransparentInput = styled("input")(
  {
    width: "100%", // full width - icon width/2 - border
    fontFamily: "Montserrat",
    fontSize: "15px",
    wordWrap: "break-word",
    lineHeight: "1em",
    outline: 0,
    whiteSpace: "normal",
    minHeight: "3em",
    height: "56px",
    background: "#f7f7fa",
    display: "inline-block",
    padding: "1em 2em 1em 1em",
    color: "rgba(0,0,0,.87)",
    boxShadow: "none",
    border: "1px solid rgba(34,36,38,.15)",
    borderRadius: "4px",
    transition: "box-shadow .1s ease,width .1s ease",
    [onAttention]: {
      border: "1px solid #0D47A1",
      boxShadow: "0 2px 3px 0 rgba(34,36,38,.15)",
    },
  },
  ({ isOpen }) =>
    isOpen
      ? {
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          [onAttention]: {
            boxShadow: "none",
          },
        }
      : null
);

const Input = styled("input")(
  {
    width: "100%", // full width - icon width/2 - border
    fontFamily: "Montserrat",
    fontSize: "15px",
    wordWrap: "break-word",
    lineHeight: "1em",
    outline: 0,
    whiteSpace: "normal",
    minHeight: "2em",
    height: "56px",
    background: "#fff",
    display: "inline-block",
    padding: "1em 2em 1em 1em",
    color: "rgba(0,0,0,.87)",
    boxShadow: "none",
    border: "1px solid rgba(34,36,38,.15)",
    borderRadius: "4px",
    transition: "box-shadow .1s ease,width .1s ease",
    [onAttention]: {
      border: "1px solid #0D47A1",
      boxShadow: "0 2px 3px 0 rgba(34,36,38,.15)",
    },
  },
  ({ isOpen }) =>
    isOpen
      ? {
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          [onAttention]: {
            boxShadow: "none",
          },
        }
      : null
);

const Label = styled("label")({
  fontFamily: "Montserrat",
  color: "#262A3D",
  fontWeight: "normal",
  display: "block",
  marginBottom: 10,
});

const BaseMenu = styled("ul")(
  {
    padding: 0,
    marginTop: 0,
    position: "absolute",
    backgroundColor: "white",
    width: "100%",
    zIndex: 7,
    maxHeight: "20rem",
    overflowY: "auto",
    overflowX: "hidden",
    outline: "0",
    transition: "opacity .1s ease",
    borderRadius: "0 0 .28571429rem .28571429rem",
    boxShadow: "0 2px 3px 0 rgba(34,36,38,.15)",
    borderColor: "#0D47A1",
    borderTopWidth: "0",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: "solid",
  },
  ({ isOpen }) => ({
    border: isOpen ? null : "none",
  })
);

const Menu = React.forwardRef((props, ref) => (
  <BaseMenu innerRef={ref} {...props} />
));

const ControllerButton = styled("button")({
  backgroundColor: "transparent",
  border: "none",
  position: "absolute",
  right: 0,
  top: 0,
  cursor: "pointer",
  width: 47,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={16}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
      transform={isOpen ? "rotate(180)" : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

function getItems(filter) {
  return filter
    ? matchSorter(listOfTrustTypes, filter, {
        keys: ["name"],
      })
    : listOfTrustTypes;
}

function getStringItems(filter) {
  return getItems(filter).map(({ name }) => name);
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function getItemsAsync(filter, { reject }) {
  await sleep(Math.random() * 2000);
  if (reject) {
    // this is just so we can have examples that show what happens
    // when there's a request failure.
    throw new Error({ error: "request rejected" });
  }
  return getItems(filter);
}

const itemToString = (i) => (i ? i.name : "");

const items = [
  "Neptunium",
  "Plutonium",
  "Americium",
  "Curium",
  "Berkelium",
  "Californium",
  "Einsteinium",
  "Fermium",
  "Mendelevium",
  "Nobelium",
  "Lawrencium",
  "Rutherfordium",
  "Dubnium",
  "Seaborgium",
  "Bohrium",
  "Hassium",
  "Meitnerium",
  "Darmstadtium",
  "Roentgenium",
  "Copernicium",
  "Nihonium",
  "Flerovium",
  "Moscovium",
  "Livermorium",
  "Tennessine",
  "Oganesson",
];

const menuStyles = {
  maxHeight: 80,
  maxWidth: 150,
  overflowY: "scroll",
  backgroundColor: "#eee",
  padding: 0,
  listStyle: "none",
  position: "relative",
};

const menuMultipleStyles = {
  maxHeight: "180px",
  overflowY: "auto",
  width: "135px",
  margin: 0,
  borderTop: 0,
  background: "white",
  position: "absolute",
  zIndex: 1000,
  listStyle: "none",
  padding: 0,
  left: "340px",
};

const selectedItemStyles = {
  marginLeft: "5px",
  backgroundColor: "aliceblue",
  borderRadius: "10px",
};

const selectedItemIconStyles = { cursor: "pointer" };

const comboboxStyles = { display: "inline-block", marginLeft: "5px" };

const comboboxWrapperStyles = {
  display: "inline-flex",
  flexWrap: "wrap",
};

export {
  menuMultipleStyles,
  items,
  menuStyles,
  comboboxStyles,
  comboboxWrapperStyles,
  selectedItemIconStyles,
  selectedItemStyles,
  Menu,
  ControllerButton,
  Input,
  TransparentInput,
  Item,
  ArrowIcon,
  XIcon,
  Label,
  css,
  itemToString,
  getItems,
  getStringItems,
  getItemsAsync,
};
