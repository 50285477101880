import React from "react";
import ContactInfo from "./ContactInfo/ContactInfo";
import MailingAddress from "./MailingAddress/MailingAddress";
import PII from "./PII/PII";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions";

function TrustAccountDetailsGrantorInfo({
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  grantorFirstName,
  setGrantorFirstName,
  grantorFirstNameError,
  setGrantorFirstNameError,
  grantorLastName,
  setGrantorLastName,
  grantorLastNameError,
  setGrantorLastNameError,
  grantorPhoneNumber,
  setGrantorPhoneNumber,
  grantorPhoneNumberError,
  setGrantorPhoneNumberError,
  grantorEmail,
  setGrantorEmail,
  grantorEmailError,
  setGrantorEmailError,
  grantorDomicileCountry,
  setGrantorDomicileCountry,
  grantorDomicileCountryError,
  setGrantorDomicileCountryError,
  grantorAddressLine1,
  setGrantorAddressLine1,
  grantorAddressLine1Error,
  setGrantorAddressLine1Error,
  grantorAddressLine2,
  setGrantorAddressLine2,
  grantorAddressLine2Error,
  setGrantorAddressLine2Error,
  grantorCity,
  setGrantorCity,
  grantorCityError,
  setGrantorCityError,
  grantorState,
  setGrantorState,
  grantorStateError,
  setGrantorStateError,
  grantorCountry,
  setGrantorCountry,
  grantorCountryError,
  setGrantorCountryError,
  grantorPostalCode,
  setGrantorPostalCode,
  grantorPostalCodeError,
  setGrantorPostalCodeError,
  grantorDob,
  setGrantorDob,
  grantorDobError,
  setGrantorDobError,
  grantorIDNumber,
  setGrantorIDNumber,
  grantorIDNumberError,
  setGrantorIDNumberError,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-4">
            <span className="individual-account-section-header-text">
              Grantor Information
            </span>
          </div>
          <ContactInfo
            grantorFirstName={grantorFirstName}
            setGrantorFirstName={setGrantorFirstName}
            grantorFirstNameError={grantorFirstNameError}
            setGrantorFirstNameError={setGrantorFirstNameError}
            grantorLastName={grantorLastName}
            setGrantorLastName={setGrantorLastName}
            grantorLastNameError={grantorLastNameError}
            setGrantorLastNameError={setGrantorLastNameError}
            grantorPhoneNumber={grantorPhoneNumber}
            setGrantorPhoneNumber={setGrantorPhoneNumber}
            grantorPhoneNumberError={grantorPhoneNumberError}
            setGrantorPhoneNumberError={setGrantorPhoneNumberError}
            grantorEmail={grantorEmail}
            setGrantorEmail={setGrantorEmail}
            grantorEmailError={grantorEmailError}
            setGrantorEmailError={setGrantorEmailError}
            grantorDomicileCountry={grantorDomicileCountry}
            setGrantorDomicileCountry={setGrantorDomicileCountry}
            grantorDomicileCountryError={grantorDomicileCountryError}
            setGrantorDomicileCountryError={setGrantorDomicileCountryError}
          />
          <MailingAddress
            grantorAddressLine1={grantorAddressLine1}
            setGrantorAddressLine1={setGrantorAddressLine1}
            grantorAddressLine1Error={grantorAddressLine1Error}
            setGrantorAddressLine1Error={setGrantorAddressLine1Error}
            grantorAddressLine2={grantorAddressLine2}
            setGrantorAddressLine2={setGrantorAddressLine2}
            grantorAddressLine2Error={grantorAddressLine2Error}
            setGrantorAddressLine2Error={setGrantorAddressLine2Error}
            grantorCity={grantorCity}
            setGrantorCity={setGrantorCity}
            grantorCityError={grantorCityError}
            setGrantorCityError={setGrantorCityError}
            grantorState={grantorState}
            setGrantorState={setGrantorState}
            grantorStateError={grantorStateError}
            setGrantorStateError={setGrantorStateError}
            grantorCountry={grantorCountry}
            setGrantorCountry={setGrantorCountry}
            grantorCountryError={grantorCountryError}
            setGrantorCountryError={setGrantorCountryError}
            grantorPostalCode={grantorPostalCode}
            setGrantorPostalCode={setGrantorPostalCode}
            grantorPostalCodeError={grantorPostalCodeError}
            setGrantorPostalCodeError={setGrantorPostalCodeError}
          />
          <PII
            grantorDomicileCountry={grantorDomicileCountry}
            grantorDob={grantorDob}
            setGrantorDob={setGrantorDob}
            grantorDobError={grantorDobError}
            setGrantorDobError={setGrantorDobError}
            grantorIDNumber={grantorIDNumber}
            setGrantorIDNumber={setGrantorIDNumber}
            grantorIDNumberError={grantorIDNumberError}
            setGrantorIDNumberError={setGrantorIDNumberError}
          />
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default TrustAccountDetailsGrantorInfo;
