import React from "react";
import StyledTextInputField from "../StyledTextInputField/StyledTextInputField";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";

const StyledPhoneInputField = ({
  setValue,
  valueError,
  value,
  disabled,
  ...props
}) => {
  return (
    <>
      {!disabled ? (
        <PhoneInput
          country={"us"}
          value={"us"}
          disabled={disabled}
          label="Phone Number"
          inputStyle={{ height: "40px" }}
          onChange={setValue}
          inputComponent={
            <StyledTextInputField
              value={value}
              setValue={setValue}
              setValueError={(e) => {}}
              valueError={valueError}
              label={"Phone Number"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={disabled}
              shrink={disabled}
              id={"account-name-input"}
            />
          }
        />
      ) : (
        <StyledTextInputField
          value={value}
          setValue={setValue}
          setValueError={(e) => {}}
          valueError={valueError}
          label={"Phone Number"}
          required={true}
          fullWidth={true}
          size={"small"}
          disabled={disabled}
          shrink={disabled}
          id={"account-name-input"}
        />
      )}
    </>
  );
};

export default StyledPhoneInputField;
