import React, { useState, useEffect } from "react";
import CheckBox from "../../../../Components/GlobalComponents/CheckBox.js";
import StateBasedButton from "../../../../GlobalComponents/Button/StateBasedButton/StateBasedButton.js";
//import { Link } from "react-router-dom";
import "./ConfidentialityAgreementSectionStyles/ConfidentialityAgreementSectionStyles.scss";
import { useHistory } from "react-router-dom";
//import { getInvestorConfidentialityAgreement } from "../../../../Api/SignupApi.js";
import GetInvestorConfidentialityAgreement from "../../../../Adapters/ConfidentialityAgreementAdapters.js";
import GetInvestorTermsOfAgreement from "../../../../Adapters/TermsOfAgreementAdapters.js";
import SignatureInputField from "../../../../Components/GlobalComponents/SignatureInputField/SignatureInputField.js";
import AlertSnackBar from "../../../../GlobalComponents/AlertSnackBar/AlertSnackBar.js";

function ConfidentialityAgreementSection({
  navigation,
  confidentialityAgreed,
  setConfidentialityAgreed,
  setConfidentialityAgreementId,
  name,
  setName,
}) {
  const [documentHasBeenRead, setDocumentHasBeenRead] = useState(false);
  const [checked, setChecked] = useState(confidentialityAgreed);
  const [proceed, setProceed] = useState(false);
  const timeStamp = Date.now();
  const [time, setTime] = useState(timeStamp);
  const { investorConfidentialityAgreement } =
    GetInvestorConfidentialityAgreement(time);
  const { investorTermsOfAgreement } = GetInvestorTermsOfAgreement(time);
  // const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [nameInvalid, setNameInvalid] = useState(false);

  let history = useHistory();

  // useEffect(() => {
  //   console.log(JSON.stringify(investorTermsOfAgreement));
  // }, [investorTermsOfAgreement]);

  useEffect(() => {
    setConfidentialityAgreementId(
      investorConfidentialityAgreement
        ? investorConfidentialityAgreement.id
        : ""
    );
  }, [investorConfidentialityAgreement, setConfidentialityAgreementId]);

  useEffect(() => {
    setNameError("");
  }, [name]);

  useEffect(() => {
    setChecked(confidentialityAgreed);
    setProceed(confidentialityAgreed);
  }, [confidentialityAgreed]);

  useEffect(() => {
    if (documentHasBeenRead && checked && name !== "") {
      setConfidentialityAgreed(true);
      setProceed(true);
    } else if (!checked) {
      setProceed(false);
      setConfidentialityAgreed(false);
      if (name === "") {
        setNameError("This field is required.");
      } else if (name.split(" ").filter((word) => word !== "").length < 2) {
        setNameInvalid(true);
        setNameError("Please enter your full name (first and last name)");
      }
    }
  }, [documentHasBeenRead, checked, setConfidentialityAgreed]);

  var handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) <=
      e.target.clientHeight;
    if (true || process.env.DEBUG) console.log("bottom: ", bottom);
    if (true || process.env.DEBUG) console.log("document read: ", documentHasBeenRead);
    if (bottom && !documentHasBeenRead) {
      setDocumentHasBeenRead(true);
    }
  };

  let documentLoadingSpinner = (
    <React.Fragment>
      <div className="row g-0 w-100 align-items-center">
        <div className="col-4"></div>
        <div className="col-4 align-items-center text-center">
          <button class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        </div>
        <div className="col-4"></div>
      </div>
    </React.Fragment>
  );

  let scrollBasedSubmitButton =
    checked && documentHasBeenRead ? (
      <StateBasedButton proceed={proceed} text={"Next Step"} />
    ) : (
      <StateBasedButton proceed={proceed} text={"Next Step"} />
    );

  let scrollWarning = (
    <span class="badge bg-light text-dark">
      Please Read and Sign Document Before Continuing
    </span>
  );

  return (
    <React.Fragment>
      <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-y-hidden fillScreen align-items-center justify-content-start d-flex">
        <div className="row g-0 w-100 align-items-center justify-content-start">
          <div
            className="col-6 d-none d-sm-none d-md-none d-lg-block"
            style={{ backgroundColor: "#F0F4FD", zIndex: 1 }}
          >
            <img
              src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
              style={{
                height: "100vh",
                backgroundSize: "cover !important",
                zIndex: 2,
              }}
              alt="community"
            ></img>
          </div>
          <div
            className="col-xxl-6 col-xl-5 col-lg-6 col-12"
            style={{ zIndex: 3, backgroundColor: "#f7f7fa", height: "100vh" }}
          >
            <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
              <div className="row g-0 w-100 h-100 align-items-center">
                <div className="col-11">
                  <div className="row g-0 w-100 mb-4 mt-5">
                    <div className="col-12">
                      <div onClick={() => navigation.previous()}>
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-11">
                      <span className="Confidentiality-header-text">
                        NON-DISCLOSURE & CONFIDENTIALITY AGREEMENT
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col-11">
                      <div
                        className="document-container"
                        onScroll={(event) => handleScroll(event)}
                      >
                        <div className="container-fluid">
                          <span
                            className="document-text-content"
                            dangerouslySetInnerHTML={{
                              __html: investorConfidentialityAgreement ? (
                                investorConfidentialityAgreement.agreement
                              ) : (
                                <p>Loading...</p>
                              ),
                            }}
                          >
                            {/* {investorConfidentialityAgreement
                              ? investorConfidentialityAgreement.agreement
                              : documentLoadingSpinner} */}
                          </span>
                        </div>
                        {investorConfidentialityAgreement ? (
                          <React.Fragment>
                            <div className="row g-0 w-100 mb-4">
                              <div className="col-12">
                                <div className="row g-0 w-100 align-items-center">
                                  <div className="col-6">
                                    <span className="signature-font">
                                      <b>Rohan Marwaha</b>
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    <span>
                                      <b>Aqua Platform Inc., CEO</b>
                                    </span>
                                  </div>
                                </div>
                                <div className="row g-0 w-100 align-items-center">
                                  <div className="col-6">
                                    <span>Disclosing Party Signature</span>
                                  </div>
                                  <div className="col-6">
                                    <span>
                                      Disclosing Party Full Name & Title
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row g-0 w-100 mb-4">
                              <div className="col-12">
                                {/* <div className="row g-0 w-100 align-items-center">
                                  <div className="col-6">
                                    <span className="signature-font">
                                      <b>Rohan Marwaha</b>
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    <span>
                                      <b>Rohan Marwaha, CEO</b>
                                    </span>
                                  </div>
                                </div> */}
                                {/* <div className="row g-0 w-100 align-items-center">
                                  <div className="col-6">
                                    <span>
                                      Disclosing Party Representative Signature
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    <span>
                                      Disclosing Party Full Name & Title
                                    </span>
                                  </div>
                                </div> */}
                                {/* <div className="row g-0 w-100 mt-4 mb-3">
                                  <div className="col-12">
                                    <div className="row g-0 w-100">
                                      <div className="col-6">
                                        <span>[RECIPIENT PARTY SIGNATURE]</span>
                                      </div>
                                      <div className="col-6">
                                        <span>[RECIPIENT PARTY FULL NAME]</span>
                                      </div>
                                    </div>
                                    <div className="row g-0 w-100">
                                      <div className="col-6">
                                        <span>Recipient Party Signature</span>
                                      </div>
                                      <div className="col-6">
                                        <span>Recipient Party Full Name</span>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="row g-0 w-100 mt-2">
                                  <span className="mb-3">
                                    <b>
                                      Entering your name in the box below will
                                      be legally considered as a proxy to your
                                      signature of acceptance to the terms
                                      described above in the Non-Dislosure and
                                      Confidentiality Agreement.
                                    </b>
                                  </span>
                                </div>
                                {/* Input goes here */}
                                <div className="row g-0 w-100 mb-1">
                                  <div className="col-6">
                                    <SignatureInputField
                                      placeholder={"Enter your name here."}
                                      fieldValueChange={setName}
                                      initialValue={name}
                                      error={nameError}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                <div className="row g-0 w-100">
                                  <span>Recipient Party Full Name</span>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}
                        {/* <div className=""></div> */}
                      </div>
                    </div>
                    {!checked || !documentHasBeenRead ? (
                      scrollWarning
                    ) : (
                      <span class="badge bg-light text-dark"></span>
                    )}
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-11">
                      <div className="row g-0 w-100 align-items-center">
                        <div className="col-1">
                          <div
                            className=" ms-0 checkBox-container"
                            onClick={() => {
                              if (documentHasBeenRead && name !== "") {
                                setChecked(!checked);
                              } else if (checked) {
                                setChecked(false);
                              }
                            }}
                          >
                            <CheckBox selected={checked} />
                          </div>
                        </div>
                        <div className="col-11">
                          <span className="checkBox-label">
                            I have read, understood, and agree to all terms
                            noted in the{" "}
                            <strong>Confidentiality Agreement</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-5">
                    <div className="col-11">
                      <div className="row g-0 w-100">
                        <div className="col-4 ms-0 me-auto">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (proceed) {
                                if (name !== "") {
                                  if (
                                    name
                                      .split(" ")
                                      .filter((word) => word !== "").length > 1
                                  ) {
                                    setName(name.replace(/  +/g, " ").trim());
                                    navigation.next();
                                  } else {
                                    setNameInvalid(true);
                                    setNameError(
                                      "Please enter your full name (first and last)"
                                    );
                                  }
                                } else {
                                  setNameInvalid(true);
                                  setNameError("This field is required.");
                                }
                              }
                            }}
                          >
                            {scrollBasedSubmitButton}
                          </div>
                        </div>
                        <div className="col-4 align-self-end text-end">
                          <span
                            className="sign-in-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/login")}
                          >
                            <u>or Sign In</u>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AlertSnackBar
                    snackbarState={nameInvalid}
                    setSnackbarState={setNameInvalid}
                    vertical={"top"}
                    horizontal={"right"}
                    duration={5000}
                    sxProps={{ paddingLeft: "3px" }}
                    severity={"warning"}
                    message={
                      "Please enter your full name (first and last) in the signature box."
                    }
                  />
                  <div className="row g-0 w-100">
                    <div className="col-xxl-6 col-xl-6 col-lg-7 col-sm-6 col-8">
                      <div className="row gx-1 w-100">
                        <div className="row g-0 w-100">
                          <div className="col">
                            <div className="progress-bar-completed"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-completed"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ConfidentialityAgreementSection;
