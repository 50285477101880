import React, { useEffect, useState } from "react";
import InfiniteTabInput from "../../../../../../../../Components/GlobalComponents/InfiniteTabInput/InfiniteTabInput";
import InfiniteInputComponent from "../../../../InfiniteInputComponent/InfiniteInputComponent";
import { countryList } from "../../../../../../../../Components/countryList";
import { stateList } from "../../../../../../../../Components/GlobalComponents/stateList";
import { getAccountSummary } from "./../../../../../../../../Api/ProfileApi.js";

function TrusteeInfiniteInput({
  accountType,
  mountedStyle,
  unmountedStyle,
  showTrusteeSection,
  shouldRenderTrusteeSection,
  activeTab,
  setActiveTab,
  addNewTab,
  accountID,
  relatedAccounts,
  setRelatedAccounts,
  handleRemoveFields,
  setActiveSlide,
  hideButton,
  hideAlterButtons,
  setHideButton,
  setHideAlterButtons,
}) {
  const supplyPercentOwned = true;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function popRelatedParties() {
      if (supplyPercentOwned === true) {
        const accountSummaryResp = await getAccountSummary(accountID);
        const accountSummaryData = accountSummaryResp.data[0];

        relatedAccounts.length !== 1 &&
          relatedAccounts.firstName !== "" &&
          setRelatedAccounts(
            accountSummaryData.related_parties
              .filter(
                (relatedParty) =>
                  relatedParty.role === "G" || relatedParty.role === "TST"
              )
              .map((relatedParty) => ({
                id: relatedParty.id,
                firstName: relatedParty.first_name,
                lastName: relatedParty.last_name,
                email: relatedParty.email,
                phoneNumber: relatedParty.phone_number,
                addressLine1: relatedParty.addresses[0].address_line_1,
                addressLine2: relatedParty.addresses[0].address_line_2,
                city: relatedParty.addresses[0].city,
                country: relatedParty.addresses[0].country,
                state: relatedParty.addresses[0].state,
                postalCode: relatedParty.addresses[0].postal_code,
                dateOfBirth: relatedParty.pii[0].date_of_birth,
                idNum: relatedParty.pii[0].id_number,
                domicileCountry: relatedParty.domicile_country,
                idType: relatedParty.pii[0].id_type,
                citizenshipError: "",
              }))
          );
        //ToDo: Update the above to pull in the new data and populate into the related party map
      }
    }
    popRelatedParties();
  }, [supplyPercentOwned]);

  useEffect(() => {
    console.log(relatedAccounts);
  }, [relatedAccounts]);

  return (
    shouldRenderTrusteeSection && (
      <div
        className={"row g-0 w-100 fade-in-test"}
        style={showTrusteeSection ? unmountedStyle : mountedStyle}
      >
        <InfiniteTabInput
          tabs={relatedAccounts.map(
            (elem, idx) => "Trustee #" + (idx + 1).toString()
          )}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addNewTab={addNewTab}
          addMore
        />
        <InfiniteInputComponent
          accountType={accountType}
          accountID={accountID}
          activeTab={activeTab}
          processedCountryList={countryList}
          processedStateList={stateList}
          inputFields={relatedAccounts}
          setInputFields={setRelatedAccounts}
          handleRemoveFields={handleRemoveFields}
          index={activeTab.match(/\d+/) - 1}
          setActiveTab={setActiveTab}
          setActiveSlide={setActiveSlide}
          role={"Trustee"}
          hideButton={hideButton}
          setHideButton={setHideButton}
          hideAlterButtons={hideAlterButtons}
          setHideAlterButtons={setHideAlterButtons}
          supplyPercentOwned={supplyPercentOwned}
          disabled={false}
          relatedPartyType="TRT"
        />
      </div>
    )
  );
}
export default TrusteeInfiniteInput;
