import axios from "axios";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import { HOST } from "../host-config.js";
// const backendApi = axios.create({
//   baseUrl: HOST,
// });

//Dev environment endpoint
// export const getUserProfile = async () => {
//   const config = await getRequestHeaders();
//   return axios.get(HOST + "/investor-profile-details/", config);
// };
export const getInvestorProfile = async () => {
  const config = await getRequestHeaders();
  // return axios.get(HOST + "/investor-profile-fast/", config);
  return axios.get(HOST + "/profile-pii", config);
};

export const getInvestorSummary = async () => {
  const config = await getRequestHeaders();
  return axios.get(HOST + "/investor-summary", config);
};

export const getAccountList = async () => {
  const config = await getRequestHeaders();
  return axios.get(HOST + "/account-list", config);
};

export const getAccountSummary = async (accountId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + `/account-summary?account_id=${accountId}`, requestParams);
  
};

export const newAccount = async (payload) => {
  const config = await getRequestHeaders();

  return axios.post(
    HOST + "/new-account",
    payload,
    config
  );
};

//Dev environment endpoint
export const updateIdentityId = async (identityId) => {
  const config = await getRequestHeaders();

  const userParams = {
    identity_id: identityId,
  };
  return axios.post(
    HOST + "/investor-profile/identity-id/",
    userParams,
    config
  );
};

//Dev environment endpoint
export const updateBasicProfileInfo = async (basicProfileInfo) => {
  const config = await getRequestHeaders();

  return axios.post(
    HOST + "/investor-profile/update-basic-profile-info/",
    basicProfileInfo,
    config
  );
};

export const updateInvestorAddress = async (investorAddress) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/update-investor-address/",
    investorAddress,
    config
  );
};

export const updateInvestorPII = async (investorPII) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/update-investor-pii/",
    investorPII,
    config
  );
};

//Dev environment endpoint
export const updateInvestorInformation = async (investorInformation) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/update-investor-info",
    investorInformation,
    config
  );
};

export const updateTrustInformation = async (trustInformation) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/update-entity-information/",
    trustInformation,
    config
  );
};

export const additionalEntityInformation = async (entityInformation) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/additional-entity-information/",
    entityInformation,
    config
  );
};

export const updateEntityInformation = async (entityInformation) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/update-entity-information/",
    entityInformation,
    config
  );
};

export const addGrantorInformation = async (grantorInformation) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/add-trust-grantor/",
    grantorInformation,
    config
  );
};

export const updateRelatedPartyInformation = async (
  relatedPartyInformation
) => {
  const config = await getRequestHeaders();
  return axios.post(
    HOST + "/investor-profile/update-related-party-information/",
    relatedPartyInformation,
    config
  );
};

//Dev environment endpoint
export const updateIsQualifiedPurchaser = async (isQualifiedPurchaser) => {
  const config = await getRequestHeaders();

  const params = {
    q1_qp_5m_investments: isQualifiedPurchaser,
  };

  return axios.post(
    HOST + "/investor-profile/update-is-qualified-purchaser/",
    params,
    config
  );
};

//Dev environment endpoint
export const completeInvestorProfile = async (completed) => {
  const config = await getRequestHeaders();
  const params = {
    completed: completed,
  };

  return axios.post(HOST + "/investor-profile/complete/", params, config);
};

//Dev environment endpoint
export const verifyUserProfile = async (verified, token) => {
  const config = await getRequestHeaders();

  var params = { verified: verified };

  if (token !== "") {
    params.token = token;
  }

  return axios.post(HOST + "/investor-profile/verify/", params, config);
};
