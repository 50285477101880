import React, { useState, useMemo, useEffect, useContext } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Routes from "./Routes/Routes";
import { UserContext } from "./GlobalContexts/UserContext.js";
import { Auth } from "aws-amplify";
import {
  getAccountList,
  getInvestorSummary,
  getInvestorProfile,
} from "./Api/ProfileApi.js";
import { RecoilRoot } from "recoil";
import { HOST } from "./host-config";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "7695fb14233d4feeabfd07c5ac2e736cTz01OTE1OCxFPTE3MDcwODEyMzMyODMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

require("dotenv").config();

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setIsLoading(true);
      let sessionInfo = await Auth.currentSession();

      try {
        // const cognitoUser = await Auth.currentUserInfo();
        const userID = sessionInfo.idToken.payload.email;
        // const userID = cognitoUser.attributes.email;

        // if (sessionInfo !== null && cognitoUser !== undefined) {
        try {
          let getProfileStartTime = new Date().getTime();
          let getProfileEndTime = new Date().getTime();
          let getProfileExecutionTime = getProfileEndTime - getProfileStartTime;

          const userProfile = await getInvestorProfile(); // profile-pii
          const investorSummary = await getInvestorSummary();
          const accountList = await getAccountList();

          var investorPrimaryAddressLine1 = "";
          var investorPrimaryAddressLine2 = "";
          var investorPrimaryCity = "";
          var investorPrimaryState = "";
          var investorPrimaryCountry = "";
          var investorPrimaryPostalCode = "";
          var investorPrimaryAddressType = "";

          var investorSecondaryAddressLine1 = "";
          var investorSecondaryAddressLine2 = "";
          var investorSecondaryCity = "";
          var investorSecondaryState = "";
          var investorSecondaryCountry = "";
          var investorSecondaryPostalCode = "";
          var investorSecondaryAddressType = "";

          if (userProfile.data[0].investor_addresses.length === 1) {
            investorPrimaryAddressLine1 =
              userProfile.data[0].investor_addresses[0].address_line_1;
            investorPrimaryAddressLine2 =
              userProfile.data[0].investor_addresses[0].address_line_2;
            investorPrimaryCity =
              userProfile.data[0].investor_addresses[0].city;
            investorPrimaryState =
              userProfile.data[0].investor_addresses[0].state;
            investorPrimaryCountry =
              userProfile.data[0].investor_addresses[0].country;
            investorPrimaryPostalCode =
              userProfile.data[0].investor_addresses[0].postal_code;
            investorPrimaryAddressType =
              userProfile.data[0].investor_addresses[0].address_type;
          } else if (userProfile.data[0].investor_addresses.length > 1) {
            userProfile.data[0].investor_addresses.forEach(
              (investor_address) => {
                if (investor_address.address_type === "M") {
                  investorSecondaryAddressLine1 =
                    investor_address.address_line_1;
                  investorSecondaryAddressLine2 =
                    investor_address.address_line_2;
                  investorSecondaryCity = investor_address.city;
                  investorSecondaryState = investor_address.state;
                  investorSecondaryCountry = investor_address.country;
                  investorSecondaryPostalCode = investor_address.postal_code;
                  investorSecondaryAddressType = investor_address.address_type;
                } else if (
                  investor_address.address_type === "R" ||
                  investor_address === "RM"
                ) {
                  investorPrimaryAddressLine1 = investor_address.address_line_1;
                  investorPrimaryAddressLine2 = investor_address.address_line_2;
                  investorPrimaryCity = investor_address.city;
                  investorPrimaryState = investor_address.state;
                  investorPrimaryCountry = investor_address.country;
                  investorPrimaryPostalCode = investor_address.postal_code;
                  investorPrimaryAddressType = investor_address.address_type;
                }
              }
            );
          }
          const isAuthenticated = true;
          setUser({
            id: userID,
            userProfileData: userProfile.data[0],
            accountListData: accountList.data,
            selectedAccountID:
              accountList && accountList.data.length !== 0
                ? accountList.data[0].id
                : "",
            investorSummaryData: investorSummary.data[0],
            investorPrimaryAddressLine1: investorPrimaryAddressLine1,
            investorPrimaryAddressLine2: investorPrimaryAddressLine2,
            investorPrimaryCity: investorPrimaryCity,
            investorPrimaryState: investorPrimaryState,
            investorPrimaryCountry: investorPrimaryCountry,
            investorPrimaryPostalCode: investorPrimaryPostalCode,
            investorPrimaryAddressType: investorPrimaryAddressType,
            investorSecondaryAddressLine1: investorSecondaryAddressLine1,
            investorSecondaryAddressLine2: investorSecondaryAddressLine2,
            investorSecondaryCity: investorSecondaryCity,
            investorSecondaryState: investorSecondaryState,
            investorSecondaryCountry: investorSecondaryCountry,
            investorSecondaryPostalCode: investorSecondaryPostalCode,
            investorSecondaryAddressType: investorSecondaryAddressType,
            isAuthenticated: isAuthenticated,
          });
          setIsLoading(false);
          // setUser({
          //   id: userID,
          //   userProfileData: userProfile.data[0],
          //   investorPrimaryAddressLine1:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].address_line_1
          //       : "",
          //   investorPrimaryAddressLine2:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].address_line_2
          //       : "",
          //   investorPrimaryCity:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].city
          //       : "",
          //   investorPrimaryState:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].state
          //       : "",
          //   investorPrimaryCountry:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].country
          //       : "",
          //   investorPrimaryPostalCode:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].postal_code
          //       : "",
          //   investorPrimaryAddressType:
          //     userProfile.data[0].investor_addresses.length !== 0
          //       ? userProfile.data[0].investor_addresses[0].address_type
          //       : "",
          //   investorSecondaryAddressLine1:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].address_line_1
          //       : "",
          //   investorSecondaryAddressLine2:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].address_line_2
          //       : "",
          //   investorSecondaryCity:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].city
          //       : "",
          //   investorSecondaryState:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].state
          //       : "",
          //   investorSecondaryCountry:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].country
          //       : "",
          //   investorSecondaryPostalCode:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].postal_code
          //       : "",
          //   investorSecondaryAddressType:
          //     userProfile.data[0].investor_addresses.length >= 2
          //       ? userProfile.data[0].investor_addresses[1].address_type
          //       : "",
          //   isAuthenticated: isAuthenticated,
          // });
          // console.log("User profile is: " + JSON.stringify(userProfile));
        } catch (error) {
          setIsLoading(false);
          if (process.env.DEBUG)
            console.error("Error getting current user profile.");
        }
        // }
      } catch (err) {
        setIsLoading(false);
        console.error("Error getting cognitoUser.");
      }
    } catch (e) {
      setIsLoading(false);
      if (e !== "No current user") {
        if (process.env.DEBUG) console.error("Error getting current session.");
      }
    }
  }

  const value = useMemo(() => {
    return {
      user: user,
      setUser: setUser,
    };
  }, [user]);

  return (
    <RecoilRoot>
      <UserContext.Provider value={value}>
        <Routes isLoading={isLoading} />
      </UserContext.Provider>
    </RecoilRoot>
  );
};

export default App;
