import React from "react";
import "./Styles/Styles.scss";
import IndividualSignup from "./IndividualSignup/IndividualSignup";
import JointSignup from "./JointSignup/JointSignup";
import TrustSignup from "./TrustSignup/TrustSignup";
import EntitySignup from "./EntitySignup/EntitySignup";
import SMLLCSignup from "./SMLLCSignup/SMLLCSignup";
import { useRecoilValue } from "recoil";
import {
  accountTypeIndividualVal,
  accountTypeSMLLCVal,
  accountTypeJointVal,
  accountTypeTrustVal,
  accountTypeEntityVal,
} from "../../../../../../../atoms";

function AccountSignupForm({ setActiveSlide }) {
  const accountTypeIndividual = useRecoilValue(accountTypeIndividualVal);
  const accountTypeSMLLC = useRecoilValue(accountTypeSMLLCVal);
  const accountTypeJoint = useRecoilValue(accountTypeJointVal);
  const accountTypeTrust = useRecoilValue(accountTypeTrustVal);
  const accountTypeEntity = useRecoilValue(accountTypeEntityVal);

  return accountTypeIndividual === true ? (
    <IndividualSignup setActiveSlide={setActiveSlide} />
  ) : accountTypeJoint === true ? (
    <JointSignup setActiveSlide={setActiveSlide} />
  ) : accountTypeTrust === true ? (
    <TrustSignup setActiveSlide={setActiveSlide} />
  ) : accountTypeEntity === true ? (
    <EntitySignup setActiveSlide={setActiveSlide} />
  ) : accountTypeSMLLC === true ? (
    <SMLLCSignup setActiveSlide={setActiveSlide} />
  ) : null;
}
export default AccountSignupForm;
