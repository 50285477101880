import React from "react";

function TrustAccountBasicInformationButton({
  loading,
  showTrustQualificationSection,
  mountedStyle,
  unmountedStyle,
  onClick,
}) {
  return (
    <div
      className="row g-0 mt-3 w-100 fade-in-test"
      style={showTrustQualificationSection ? mountedStyle : unmountedStyle}
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-lg btn-class"
          onClick={() => onClick()}
        >
          {loading && (
            <div className="row g-0 w-100">
              <div className="col d-flex align-items-center">
                <span className="justify-self-start align-self-center">
                  Next Step
                </span>
                <span
                  class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          )}
          {!loading && <span>Next Step</span>}
        </button>
      </div>
    </div>
  );
}
export default TrustAccountBasicInformationButton;
