import React, { useState, useEffect } from "react";
import TabsV2 from "../../../../../../Components/GlobalComponents/TabsV2/TabsV2";
import TabV2 from "../../../../../../Components/GlobalComponents/TabsV2/TabV2";
import TrustAccountDetailsGeneralInfo from "./TrustAccountDetailsGeneralInfo/TrustAccountDetailsGeneralInfo";
import TrustAccountDetailsTaxInfo from "./TrustAccountDetailsTaxInfo/TrustAccountDetailsTaxInfo";
import TrustAccountDetailsGrantorInfo from "./TrustAccountDetailsGrantorInfo/TrustAccountDetailsGrantorInfo";
import TrustAccountDetailsTrusteeInfo from "./TrustAccountDetailsTrusteeInfo/TrustAccountDetailsTrusteeInfo";
import TrustAccountDetailsBeneficialOwnerInfo from "./TrustAccountDetailsBeneficialOwnerInfo/TrustAccountDetailsBeneficialOwnerInfo";

function TrustAccountDetailsInfoSection({
  accountID,
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  trustType,
  setTrustType,
  trustTypeError,
  setTrustTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  idType,
  setIDType,
  idTypeError,
  setIDTypeError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
  grantorFirstName,
  setGrantorFirstName,
  grantorFirstNameError,
  setGrantorFirstNameError,
  grantorLastName,
  setGrantorLastName,
  grantorLastNameError,
  setGrantorLastNameError,
  grantorPhoneNumber,
  setGrantorPhoneNumber,
  grantorPhoneNumberError,
  setGrantorPhoneNumberError,
  grantorEmail,
  setGrantorEmail,
  grantorEmailError,
  setGrantorEmailError,
  grantorDomicileCountry,
  setGrantorDomicileCountry,
  grantorDomicileCountryError,
  setGrantorDomicileCountryError,
  grantorAddressLine1,
  setGrantorAddressLine1,
  grantorAddressLine1Error,
  setGrantorAddressLine1Error,
  grantorAddressLine2,
  setGrantorAddressLine2,
  grantorAddressLine2Error,
  setGrantorAddressLine2Error,
  grantorCity,
  setGrantorCity,
  grantorCityError,
  setGrantorCityError,
  grantorState,
  setGrantorState,
  grantorStateError,
  setGrantorStateError,
  grantorCountry,
  setGrantorCountry,
  grantorCountryError,
  setGrantorCountryError,
  grantorPostalCode,
  setGrantorPostalCode,
  grantorPostalCodeError,
  setGrantorPostalCodeError,
  grantorDob,
  setGrantorDob,
  grantorDobError,
  setGrantorDobError,
  grantorIDNumber,
  setGrantorIDNumber,
  grantorIDNumberError,
  setGrantorIDNumberError,
  relatedAccounts,
  setRelatedAccounts,
  beneficialOwnerRelatedAccounts,
  setBeneficialOwnerRelatedAccounts,
}) {
  const [generalInformationSelected, setGeneralInformationSelected] =
    useState(true);
  const [taxInformationSelected, setTaxInformationSelected] = useState(false);
  const [grantorInformationSelected, setGrantorInformationSelected] =
    useState(false);
  const [trusteeInformationSelected, setTrusteeInformationSelected] =
    useState(false);
  const [
    beneficialOwnerInformationSelected,
    setBeneficialOwnerInformationSelected,
  ] = useState(false);

  useEffect(() => {
    if (generalInformationSelected) {
      setTaxInformationSelected(false);
      setGrantorInformationSelected(false);
      setTrusteeInformationSelected(false);
      setBeneficialOwnerInformationSelected(false);
    }
  }, [generalInformationSelected]);

  useEffect(() => {
    if (taxInformationSelected) {
      setGeneralInformationSelected(false);
      setGrantorInformationSelected(false);
      setTrusteeInformationSelected(false);
      setBeneficialOwnerInformationSelected(false);
    }
  }, [taxInformationSelected]);

  useEffect(() => {
    if (grantorInformationSelected) {
      setGeneralInformationSelected(false);
      setTaxInformationSelected(false);
      setTrusteeInformationSelected(false);
      setBeneficialOwnerInformationSelected(false);
    }
  }, [grantorInformationSelected]);

  useEffect(() => {
    if (trusteeInformationSelected) {
      setGeneralInformationSelected(false);
      setTaxInformationSelected(false);
      setGrantorInformationSelected(false);
      setBeneficialOwnerInformationSelected(false);
    }
  }, [trusteeInformationSelected]);

  useEffect(() => {
    if (beneficialOwnerInformationSelected) {
      setGeneralInformationSelected(false);
      setTaxInformationSelected(false);
      setGrantorInformationSelected(false);
      setTrusteeInformationSelected(false);
    }
  }, [beneficialOwnerInformationSelected]);

  return (
    <React.Fragment>
      <div className="row g-0 w-100">
        <TabsV2>
          <TabV2
            title="General Information"
            selected={generalInformationSelected}
            setSelected={setGeneralInformationSelected}
          >
            <TrustAccountDetailsGeneralInfo
              accountNumber={accountNumber}
              dateAdded={dateAdded}
              qualificationLevel={qualificationLevel}
              verificationStatus={verificationStatus}
              trustType={trustType}
              setTrustType={setTrustType}
              trustTypeError={trustTypeError}
              setTrustTypeError={setTrustTypeError}
              accountName={accountName}
              setAccountName={setAccountName}
              accountNameError={accountNameError}
              setAccountNameError={setAccountNameError}
              countryOfFormation={countryOfFormation}
              setCountryOfFormation={setCountryOfFormation}
              countryOfFormationError={countryOfFormationError}
              setCountryOfFormationError={setCountryOfFormationError}
            />
          </TabV2>
          <TabV2
            title="Tax Information"
            selected={taxInformationSelected}
            setSelected={setTaxInformationSelected}
          >
            <TrustAccountDetailsTaxInfo
              trustType={trustType}
              countryOfFormation={countryOfFormation}
              idType={idType}
              setIDType={setIDType}
              idTypeError={idTypeError}
              setIDTypeError={setIDTypeError}
              idNumber={idNumber}
              setIDNumber={setIDNumber}
              idNumberError={idNumberError}
              setIDNumberError={setIDNumberError}
              addressLine1={addressLine1}
              setAddressLine1={setAddressLine1}
              addressLine1Error={addressLine1Error}
              setAddressLine1Error={setAddressLine1Error}
              addressLine2={addressLine2}
              setAddressLine2={setAddressLine2}
              addressLine2Error={addressLine2Error}
              setAddressLine2Error={setAddressLine2Error}
              city={city}
              setCity={setCity}
              cityError={cityError}
              setCityError={setCityError}
              state={state}
              setState={setState}
              stateError={stateError}
              setStateError={setStateError}
              country={country}
              setCountry={setCountry}
              countryError={countryError}
              setCountryError={setCountryError}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              postalCodeError={postalCodeError}
              setPostalCodeError={setPostalCodeError}
            />
          </TabV2>
          <TabV2
            title="Grantor Information"
            selected={grantorInformationSelected}
            setSelected={setGrantorInformationSelected}
          >
            <TrustAccountDetailsGrantorInfo
              grantorFirstName={grantorFirstName}
              setGrantorFirstName={setGrantorFirstName}
              grantorFirstNameError={grantorFirstNameError}
              setGrantorFirstNameError={setGrantorFirstNameError}
              grantorLastName={grantorLastName}
              setGrantorLastName={setGrantorLastName}
              grantorLastNameError={grantorLastNameError}
              setGrantorLastNameError={setGrantorLastNameError}
              grantorPhoneNumber={grantorPhoneNumber}
              setGrantorPhoneNumber={setGrantorPhoneNumber}
              grantorPhoneNumberError={grantorPhoneNumberError}
              setGrantorPhoneNumberError={setGrantorPhoneNumberError}
              grantorEmail={grantorEmail}
              setGrantorEmail={setGrantorEmail}
              grantorEmailError={grantorEmailError}
              setGrantorEmailError={setGrantorEmailError}
              grantorDomicileCountry={grantorDomicileCountry}
              setGrantorDomicileCountry={setGrantorDomicileCountry}
              grantorDomicileCountryError={grantorDomicileCountryError}
              setGrantorDomicileCountryError={setGrantorDomicileCountryError}
              grantorAddressLine1={grantorAddressLine1}
              setGrantorAddressLine1={setGrantorAddressLine1}
              grantorAddressLine1Error={grantorAddressLine1Error}
              setGrantorAddressLine1Error={setGrantorAddressLine1Error}
              grantorAddressLine2={grantorAddressLine2}
              setGrantorAddressLine2={setGrantorAddressLine2}
              grantorAddressLine2Error={grantorAddressLine2Error}
              setGrantorAddressLine2Error={setGrantorAddressLine2Error}
              grantorCity={grantorCity}
              setGrantorCity={setGrantorCity}
              grantorCityError={grantorCityError}
              setGrantorCityError={setGrantorCityError}
              grantorState={grantorState}
              setGrantorState={setGrantorState}
              grantorStateError={grantorStateError}
              setGrantorStateError={setGrantorStateError}
              grantorCountry={grantorCountry}
              setGrantorCountry={setGrantorCountry}
              grantorCountryError={grantorCountryError}
              setGrantorCountryError={setGrantorCountryError}
              grantorPostalCode={grantorPostalCode}
              setGrantorPostalCode={setGrantorPostalCode}
              grantorPostalCodeError={grantorPostalCodeError}
              setGrantorPostalCodeError={setGrantorPostalCodeError}
              grantorDob={grantorDob}
              setGrantorDob={setGrantorDob}
              grantorDobError={grantorDobError}
              setGrantorDobError={setGrantorDobError}
              grantorIDNumber={grantorIDNumber}
              setGrantorIDNumber={setGrantorIDNumber}
              grantorIDNumberError={grantorIDNumberError}
              setGrantorIDNumberError={setGrantorIDNumberError}
            />
          </TabV2>
          <TabV2
            title="Trustee Information"
            selected={trusteeInformationSelected}
            setSelected={setTrusteeInformationSelected}
          >
            <TrustAccountDetailsTrusteeInfo
              accountType={trustType}
              accountID={accountID}
              relatedAccounts={relatedAccounts}
              setRelatedAccounts={setRelatedAccounts}
            />
          </TabV2>
          <TabV2
            title="Beneficial Owner Information"
            selected={beneficialOwnerInformationSelected}
            setSelected={setBeneficialOwnerInformationSelected}
          >
            <TrustAccountDetailsBeneficialOwnerInfo
              accountType={trustType}
              accountID={accountID}
              beneficialOwnerRelatedAccounts={beneficialOwnerRelatedAccounts}
              setBeneficialOwnerRelatedAccounts={
                setBeneficialOwnerRelatedAccounts
              }
            />
          </TabV2>
        </TabsV2>
      </div>
    </React.Fragment>
  );
}
export default TrustAccountDetailsInfoSection;
