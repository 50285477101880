import { useState, useEffect } from "react";
import { getFaqs } from "../Api/FaqsApi.js";

export default function GetFaqsAdapter(time) {
  const [generalFaqs, setGeneralFaqs] = useState([]);
  const [platformFaqs, setPlatformFaqs] = useState([]);
  const [peBasicsFaqs, setPEBasicsFaqs] = useState([]);
  const [glossaryFaqs, setGlossaryFaqs] = useState([]);

  useEffect(() => {
    async function getAllFaqs() {
      try {
        const response = await getFaqs();
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("RAW RESPONSE: ", response.data);
        }
        const faqResponse = response.data;
        const displayFaqResponse = faqResponse.filter(
          (faq) => faq.display_faq === true
        );

        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("RAW RESPONSE DISPLAY: ", displayFaqResponse);
        }

        const generalFAQS = displayFaqResponse.filter(
          (displayfaq) => displayfaq.category === "GENERAL"
        );
        const platformFAQS = displayFaqResponse.filter(
          (displayfaq) => displayfaq.category === "PLATFORM"
        );
        const peBasicsFAQS = displayFaqResponse.filter(
          (displayfaq) => displayfaq.category === "PE_BASICS"
        );
        const glossaryFAQS = displayFaqResponse.filter(
          (displayfaq) => displayfaq.category === "GLOSSARY"
        );

        setGeneralFaqs(generalFAQS);
        setPlatformFaqs(platformFAQS);
        setPEBasicsFaqs(peBasicsFAQS);
        setGlossaryFaqs(glossaryFAQS);
        // faqResponse.map((faq) => {
        //   if (faq.display_faq === true) {
        //     if (faq.category === "GENERAL") {
        //       setGeneralFaqs((prevState) => [
        //         ...prevState,
        //         {
        //           id: faq.id,
        //           category: faq.category,
        //           question: faq.question,
        //           answer: faq.answer,
        //         },
        //       ]);
        //     } else if (faq.category === "PLATFORM") {
        //       setPlatformFaqs((prevState) => [
        //         ...prevState,
        //         {
        //           id: faq.id,
        //           category: faq.category,
        //           question: faq.question,
        //           answer: faq.answer,
        //         },
        //       ]);
        //     } else if (faq.category === "PE_BASICS") {
        //       setPEBasicsFaqs((prevState) => [
        //         ...prevState,
        //         {
        //           id: faq.id,
        //           category: faq.category,
        //           question: faq.question,
        //           answer: faq.answer,
        //         },
        //       ]);
        //     } else if (faq.category === "GLOSSARY") {
        //       setGlossaryFaqs((prevState) => [
        //         ...prevState,
        //         {
        //           id: faq.id,
        //           category: faq.category,
        //           question: faq.question,
        //           answer: faq.answer,
        //         },
        //       ]);
        //     }
        //   }
        // });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG) console.error(err);
      }
    }
    getAllFaqs();
  }, [time]);
  return { generalFaqs, platformFaqs, peBasicsFaqs, glossaryFaqs };
}
