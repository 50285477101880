import React from "react";
import "./AccordionStyles/AccordionStyles.scss";

const AccordionItem = ({ item, itemid }) => {
  let idCollapseSource = `#c${item.id.slice(-8)}`;
  let idCollapseTarget = `c${item.id.slice(-8)}`;
  let stackParent = `#${itemid}`;
  // console.log(stackParent)
  // console.log(itemid)
  return (
    <>
      <div className="accordion-item" key={item.id}>
        <h2
          className="accordion-header default-subheader-text-600"
          id={item.id.slice(-8)}
        >
          <button
            className="accordion-button border-0 collapsed default-subheader-text-600"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={idCollapseSource}
            aria-expanded="false"
            aria-controls={idCollapseTarget}
          >
            {item.question}
          </button>
        </h2>
        <div
          id={idCollapseTarget}
          className="accordion-collapse collapse"
          aria-labelledby={item.id.slice(-8)}
          data-bs-parent={stackParent}
        >
          <div className="accordion-body default-section-text">
            {item.answer}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionItem;
