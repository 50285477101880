import React, { useState, useEffect } from "react";
import useDebounce from "../CustomHooks/useDebounce";
import axios from "axios";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders";
import { HOST } from "../host-config";
import { searchBlogPosts } from "../Api/BlogPostApi.js";
import { getSignedURL } from "../Api/GlobalFunctions/SignedURL";

export default function BlogPostAdapter(searchKeywords, setLoading) {
  const [blogPosts, setBlogPosts] = useState([]);

  const debouncedSearchKeywords = useDebounce(searchKeywords, 200);

  // useEffect(() => {
  //   setBlogPosts([]);
  // }, []);

  useEffect(async () => {
    // setBlogPosts([]);
    // setLoading(true);
    const queryParams = {};
    queryParams.query = debouncedSearchKeywords;

    try {
      const response = await searchBlogPosts(debouncedSearchKeywords);

      if (response.data !== null && response.data !== undefined) {
        // setLoading(true);
        setBlogPosts([]);
        const blogPostResults = response.data.list.map((blog_post) => {
          async function getUsableURLS() {
            setLoading(true);
            setBlogPosts((prevState) => [
              ...prevState,
              {
                id: blog_post.id,
                link: blog_post.link,
                postTitle: blog_post.title,
                postSubtitle: blog_post.subtitle,
                thumbnailLink: blog_post.thumbnail,
                authorProfilePicLink: blog_post.author.image,
                authorName: blog_post.author.name,
              },
            ]);
            setLoading(false);
          }
          getUsableURLS();
          return {
            id: blog_post.id,
            link: blog_post.link,
            postTitle: blog_post.title,
            postSubtitle: blog_post.subtitle,
            thumbnailLink: blog_post.thumbnail,
            authorProfilePicLink: blog_post.author.image,
            authorName: blog_post.author.name,
          };
        });
        // setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (process.env.REACT_APP_DEBUG === "1") console.log(error);
    }
    // setLoading(false);
  }, [debouncedSearchKeywords]);

  return { blogPosts };
}
