import React, { useState } from "react";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";
import MuiButton from "../../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import {
  QualifiedPurchaserLink,
  AccreditedInvestorLink,
} from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import AlertSnackBar from "../../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import { QualifiedPurchaserModal } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { useRecoilState } from "recoil";
import {
  qualificationDateVal,
  indNetWorth1M,
  indIncomeAccredited,
  indInvestmentProfessional,
  indAssets5M,
} from "../../../../../../../../atoms";
import RadioInputGroup from "../../../../../../../../Components/GlobalComponents/RadioInputs/RadioInputGroup";
import RadioInput from "../../../../../../../../Components/GlobalComponents/RadioInputs/RadioInput/RadioInput";
import AccreditedInvestorModal from "./AccreditedInvestorModal/AccreditedInvestorModal";

function AccreditationQualification({ setActiveSlide }) {
  const [showQPModal, setShowQPModal] = useState(false);
  const [showAccreditedModal, setShowAccreditedModal] = useState(false);
  const [qualificationDate, setQualificationDate] =
    useRecoilState(qualificationDateVal);

  const [netWorth1M, setNetWorth1M] = useRecoilState(indNetWorth1M);
  const [incomeAccredited, setIncomeAccredited] =
    useRecoilState(indIncomeAccredited);
  const [investmentProfessional, setInvestmentProfessional] = useRecoilState(
    indInvestmentProfessional
  );
  const [qualifiedPurchaser, setQualifiedPurchaser] =
    useRecoilState(indAssets5M);

  const [questionsIncompleteError, setQuestionsIncompleteError] =
    useState(false);
  const [notAccreditedError, setNotAccreditedError] = useState(false);

  const validateAccreditationStatus = () => {
    let isAccredited = true;
    let errors = false;

    if (
      netWorth1M === "" ||
      incomeAccredited === "" ||
      investmentProfessional === "" ||
      qualifiedPurchaser === ""
    ) {
      errors = true;
    } else if (!netWorth1M && !incomeAccredited && !investmentProfessional) {
      isAccredited = false;
    }

    if (errors === true) {
      setQuestionsIncompleteError(true);
    } else if (isAccredited === false) {
      setNotAccreditedError(true);
    } else {
      var utcDate = getDateUTC();
      setQualificationDate(utcDate.toISOString());
      setActiveSlide((curSlide) => curSlide + 1);
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal
        show={showQPModal}
        setShowModal={setShowQPModal}
      />
      <AccreditedInvestorModal
        show={showAccreditedModal}
        setShowModal={setShowAccreditedModal}
      />
      <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
        <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4 mt-3">
              <div className="col-12">
                <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <span className="body-text-md-semibold">
                  Please confirm your <strong>Qualification Status</strong>
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Do you have a net worth over $1M excluding your primary residence?"
                }
              >
                <RadioInput
                  checked={netWorth1M === true}
                  setValue={setNetWorth1M}
                  radioValue={true}
                  label={"Yes"}
                  index={0}
                />
                <RadioInput
                  checked={netWorth1M === false}
                  setValue={setNetWorth1M}
                  radioValue={false}
                  label={"No"}
                  index={1}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mb-3">
              <RadioInputGroup
                radioText={
                  "Was your income over $200,000 individually (if investing individually) or $300,000 household (if investing jointly) in each of the past 2 years with a reasonable expectation for the same to hold true in the coming year?"
                }
              >
                <RadioInput
                  checked={incomeAccredited === true}
                  setValue={setIncomeAccredited}
                  radioValue={true}
                  label={"Yes"}
                  index={2}
                />
                <RadioInput
                  checked={incomeAccredited === false}
                  setValue={setIncomeAccredited}
                  radioValue={false}
                  label={"No"}
                  index={3}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mb-3">
              <RadioInputGroup
                radioText={
                  "Are you an investment professional in good standing that currently holds a Series 7, Series 65 or Series 82 license?"
                }
              >
                <RadioInput
                  checked={investmentProfessional === true}
                  setValue={setInvestmentProfessional}
                  radioValue={true}
                  label={"Yes"}
                  index={4}
                />
                <RadioInput
                  checked={investmentProfessional === false}
                  setValue={setInvestmentProfessional}
                  radioValue={false}
                  label={"No"}
                  index={5}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mb-3">
              <RadioInputGroup
                radioText={
                  "Are you a natural person who alone (or together with his/her spouse, if investing jointly) owns not less than $5,000,000 in investments."
                }
              >
                <RadioInput
                  checked={qualifiedPurchaser === true}
                  setValue={setQualifiedPurchaser}
                  radioValue={true}
                  label={"Yes"}
                  index={6}
                />
                <RadioInput
                  checked={qualifiedPurchaser === false}
                  setValue={setQualifiedPurchaser}
                  radioValue={false}
                  label={"No"}
                  index={7}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mb-3">
              <span className="body-text-xs-semibold">
                *The definitions of accredited investors and qualified
                purchasers are incomplete above. You can find the full
                definitions below.
              </span>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-10">
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Next step"}
                        onClick={() => validateAccreditationStatus()}
                        size={"medium"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-0 w-100 mt-3 pe-5">
              <div className="d-flex">
                <div className="me-5">
                  <AccreditedInvestorLink
                    setShowModal={setShowAccreditedModal}
                  />
                </div>

                <QualifiedPurchaserLink setShowModal={setShowQPModal} />
              </div>
            </div>
            <AlertSnackBar
              snackbarState={notAccreditedError}
              setSnackbarState={setNotAccreditedError}
              vertical={"top"}
              horizontal={"right"}
              duration={5000}
              sxProps={{ paddingLeft: "3px" }}
              severity={"error"}
              message={
                "Sorry, your account does not qualify as an accredited investor, and we only allow accredited investors on our platform at this time."
              }
            />
            <AlertSnackBar
              snackbarState={questionsIncompleteError}
              setSnackbarState={setQuestionsIncompleteError}
              vertical={"top"}
              horizontal={"right"}
              duration={5000}
              sxProps={{ paddingLeft: "3px" }}
              severity={"error"}
              message={
                "You must select a response to each of the questions below."
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AccreditationQualification;
