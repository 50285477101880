import React from "react";
import SelectField from "../../../../../GlobalComponents/InputField/SelectField/SelectField";
import StyledTextInputField from "../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import "./AccountBasicInformationStyles/AccountBasicInformationStyles.scss";
import IndividualAccountBasicInformation from "./IndividualAccountBasicInformation/IndividualAccountBasicInformation";
import TrustAccountBasicInformation from "./TrustAccountBasicInformation/TrustAccountBasicInformation";
import EntityAccountBasicInformation from "./EntityAccountBasicInformation/EntityAccountBasicInformation";

function AccountBasicInformation({
  setAccountID,
  accountType,
  setAccountType,
  accountTypeError,
  setAccountTypeError,
  accountTypeOptions,
  showLegalAccountNameField,
  legalAccountName,
  setLegalAccountName,
  legalAccountNameError,
  setLegalAccountNameError,
  investorProfileComplete,
  shouldRenderIndividualQualificationSection,
  showIndividualQualificationSection,
  individualQualificationQ1,
  setIndividualQualificationQ1,
  shouldRenderTrustQualificationSection,
  showTrustQualificationSection,
  trustQualificationQ1,
  setTrustQualificationQ1,
  trustQualificationQ2,
  setTrustQualificationQ2,
  trustQualificationQ3,
  setTrustQualificationQ3,
  trustQualificationQ4,
  setTrustQualificationQ4,
  countryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  setCountryOfFormation,
  processedCountryList,
  shouldRenderEntityQualificationSection,
  showEntityQualificationSection,
  entityQualificationQ1,
  setEntityQualificationQ1,
  entityQualificationQ2,
  setEntityQualificationQ2,
  entityQualificationQ3,
  setEntityQualificationQ3,
  entityQualificationQ4,
  setEntityQualificationQ4,
  entityQualificationQ5,
  setEntityQualificationQ5,
  mountedStyle,
  unmountedStyle,
  setActiveSlide,
}) {
  return (
    <div className="row g-0 w-100 add-account-wizard-container">
      <div className="col-12 add-account-outer-column-padded">
        <div className="row g-0 w-100 mb-4">
          <span className="add-account-step-subheader">Account Info</span>
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-2">
            <SelectField
              value={accountType}
              setValue={setAccountType}
              valueError={accountTypeError}
              setValueError={setAccountTypeError}
              selectOptions={accountTypeOptions}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"account-type-select"}
              label={"Account Type"}
            />
          </div>
          {showLegalAccountNameField !== "IND" && (
            <div
              className="col pe-0 ps-2"
              style={showLegalAccountNameField ? mountedStyle : unmountedStyle}
            >
              <StyledTextInputField
                value={legalAccountName}
                setValue={setLegalAccountName}
                valueError={legalAccountNameError}
                setValueError={setLegalAccountNameError}
                label={"Legal Account Name"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"account-name-input"}
              />
            </div>
          )}
        </div>
        <div className="row g-0 w-100">
          <IndividualAccountBasicInformation
            countryOfFormation={countryOfFormation}
            setCountryOfFormation={setCountryOfFormation}
            processedCountryList={processedCountryList}
            countryOfFormationError={countryOfFormationError}
            setCountryOfFormationError={setCountryOfFormationError}          
            setAccountID={setAccountID}
            legalAccountName={legalAccountName}
            investorProfileComplete={investorProfileComplete}
            shouldRenderIndividualQualificationSection={
              shouldRenderIndividualQualificationSection
            }
            showIndividualQualificationSection={
              showIndividualQualificationSection
            }
            mountedStyle={mountedStyle}
            unmountedStyle={unmountedStyle}
            accountType={accountType}
            individualQualificationQ1={individualQualificationQ1}
            setIndividualQualificationQ1={setIndividualQualificationQ1}
            setActiveSlide={setActiveSlide}
            setLegalAccountNameError={setLegalAccountNameError}
          />
          <TrustAccountBasicInformation
            setAccountID={setAccountID}
            shouldRenderTrustQualificationSection={
              shouldRenderTrustQualificationSection
            }
            accountType={accountType}
            showTrustQualificationSection={showTrustQualificationSection}
            legalAccountName={legalAccountName}
            setLegalAccountNameError={setLegalAccountNameError}
            countryOfFormation={countryOfFormation}
            setCountryOfFormation={setCountryOfFormation}
            countryOfFormationError={countryOfFormationError}
            setCountryOfFormationError={setCountryOfFormationError}
            processedCountryList={processedCountryList}
            mountedStyle={mountedStyle}
            unmountedStyle={unmountedStyle}
            trustQualificationQ1={trustQualificationQ1}
            setTrustQualificationQ1={setTrustQualificationQ1}
            trustQualificationQ2={trustQualificationQ2}
            setTrustQualificationQ2={setTrustQualificationQ2}
            trustQualificationQ3={trustQualificationQ3}
            setTrustQualificationQ3={setTrustQualificationQ3}
            trustQualificationQ4={trustQualificationQ4}
            setTrustQualificationQ4={setTrustQualificationQ4}
            setActiveSlide={setActiveSlide}
          />
          <EntityAccountBasicInformation
            setAccountID={setAccountID}
            accountType={accountType}
            shouldRenderEntityQualificationSection={
              shouldRenderEntityQualificationSection
            }
            showEntityQualificationSection={showEntityQualificationSection}
            mountedStyle={mountedStyle}
            unmountedStyle={unmountedStyle}
            legalAccountName={legalAccountName}
            setLegalAccountNameError={setLegalAccountNameError}
            countryOfFormation={countryOfFormation}
            setCountryOfFormation={setCountryOfFormation}
            countryOfFormationError={countryOfFormationError}
            setCountryOfFormationError={setCountryOfFormationError}
            processedCountryList={processedCountryList}
            entityQualificationQ1={entityQualificationQ1}
            setEntityQualificationQ1={setEntityQualificationQ1}
            entityQualificationQ2={entityQualificationQ2}
            setEntityQualificationQ2={setEntityQualificationQ2}
            entityQualificationQ3={entityQualificationQ3}
            setEntityQualificationQ3={setEntityQualificationQ3}
            entityQualificationQ4={entityQualificationQ4}
            setEntityQualificationQ4={setEntityQualificationQ4}
            entityQualificationQ5={entityQualificationQ5}
            setEntityQualificationQ5={setEntityQualificationQ5}
            setActiveSlide={setActiveSlide}
          />
        </div>
      </div>
    </div>
  );
}
export default AccountBasicInformation;
