import React, { useState, useEffect } from "react";
import { useForm, useStep } from "react-hooks-helper";
import QualifiedPurchaserQuestionnaireSection from "./QualifiedPurchaserQuestionnaireSection/QualifiedPurchaserQuestionnaireSection.js";
import ConfidentialityAgreementSection from "./ConfidentialityAgreementSection/ConfidentialityAgreementSection.js";
import TermsAndAgreementSection from "./TermsAndAgreementSection/TermsAndAgreementSection.js";
import SignupFormSection from "./SignupFormSection/SignupFormSection.js";
import VerificationSection from "./VerificationSection/VerificationSection.js";
import AccreditedInvestorQuestionnaireSection from "./AccreditedInvestorQuestionnaireSection/AccreditedInvestorQuestionnaireSection.js";
import AccountTypeSection from "./AccountTypeSection/AccountTypeSection.js";
import QualificationSection from "./QualificationSection/QualificationSection.js";
import AccountSignupFormSection from "./AccountSignupFormSection/AccountSignupFormSection.js";
import { getDateUTC } from "../../../GlobalFunctions/globalFunctions.js";

function SignupPage() {
  const steps = [
    { id: "accountTypeSection" },
    { id: "qualificationSection" },
    { id: "confidentialityAgreement" },
    { id: "termsAgreement" },
    { id: "accountSignupFormSection" },
    { id: "verificationPage" },
  ];
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [docSignatureName, setDocSignatureName] = useState("");
  // const [selection1, setSelection1] = useState(false);
  // const [selection2, setSelection2] = useState(false);
  const [accountTypeIndividual, setAccountTypeIndividual] = useState(false);
  const [accountTypeTrust, setAccountTypeTrust] = useState(false);
  const [accountTypeEntity, setAccountTypeEntity] = useState(false);
  const [accountTypeRetirement, setAccountTypeRetirement] = useState(false);
  const [accountTypeJoint, setAccountTypeJoint] = useState(false);
  const [trustQualificationQ1, setTrustQualificationQ1] = useState("");
  const [trustQualificationQ2, setTrustQualificationQ2] = useState("");
  const [trustQualificationQ3, setTrustQualificationQ3] = useState("");
  const [trustQualificationQ4, setTrustQualificationQ4] = useState("");
  const [entityQualificationQ1, setEntityQualificationQ1] = useState("");
  const [entityQualificationQ2, setEntityQualificationQ2] = useState("");
  const [entityQualificationQ3, setEntityQualificationQ3] = useState("");
  const [entityQualificationQ4, setEntityQualificationQ4] = useState("");
  const [entityQualificationQ5, setEntityQualificationQ5] = useState("");
  // const [isQualifiedPurchaser, setIsQualifiedPurchaser] = useState(false);
  const [confidentialityAgreed, setConfidentialityAgreed] = useState(false);
  const [confidentialityAgreementId, setConfidentialityAgreementId] =
    useState("");
  const [
    confidentialityAgreementDateSigned,
    setConfidentialityAgreementDateSigned,
  ] = useState("");
  const [qualificationDate, setQualificationDate] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [termsAgreementId, setTermsAgreementId] = useState("");
  const [termsAgreementDateSigned, setTermsAgreementDateSigned] = useState("");

  const [individual5mInvestments, setIndividual5mInvestments] = useState(false);
  const [individualNot5mInvestments, setIndividualNot5mInvestments] =
    useState(false);

  // useEffect(() => {
  //   console.log("Doc signature name is: " + docSignatureName);
  // }, [docSignatureName]);

  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  // useEffect(() => {
  //   if (selection1) {
  //     setIsQualifiedPurchaser(true);
  //   } else {
  //     setIsQualifiedPurchaser(false);
  //   }
  // }, [selection1]);

  useEffect(() => {
    if (confidentialityAgreed) {
      var utcDate = getDateUTC();
      setConfidentialityAgreementDateSigned(utcDate.toISOString());
    } else {
      setConfidentialityAgreementDateSigned("");
    }
  }, [confidentialityAgreed]);

  useEffect(() => {
    if (termsAgreed) {
      var utcDate = getDateUTC();
      setTermsAgreementDateSigned(utcDate.toISOString());
    } else {
      setTermsAgreementDateSigned("");
    }
  }, [termsAgreed]);

  switch (step.id) {
    case "accountTypeSection":
      return (
        <AccountTypeSection
          navigation={navigation}
          accountTypeIndividual={accountTypeIndividual}
          accountTypeTrust={accountTypeTrust}
          accountTypeEntity={accountTypeEntity}
          accountTypeRetirement={accountTypeRetirement}
          accountTypeJoint={accountTypeJoint}
          setAccountTypeJoint={setAccountTypeJoint}
          setAccountTypeIndividual={setAccountTypeIndividual}
          setAccountTypeTrust={setAccountTypeTrust}
          setAccountTypeEntity={setAccountTypeEntity}
          setAccountTypeRetirement={setAccountTypeRetirement}
        />
      );
    case "qualificationSection":
      return (
        <QualificationSection
          navigation={navigation}
          accountTypeIndividual={accountTypeIndividual}
          accountTypeTrust={accountTypeTrust}
          accountTypeJoint={accountTypeJoint}
          accountTypeEntity={accountTypeEntity}
          accountTypeRetirement={accountTypeRetirement}
          individual5mInvestments={individual5mInvestments}
          setIndividual5mInvestments={setIndividual5mInvestments}
          individualNot5mInvestments={individualNot5mInvestments}
          setIndividualNot5mInvestments={setIndividualNot5mInvestments}
          trustQualificationQ1={trustQualificationQ1}
          trustQualificationQ2={trustQualificationQ2}
          trustQualificationQ3={trustQualificationQ3}
          trustQualificationQ4={trustQualificationQ4}
          setTrustQualificationQ1={setTrustQualificationQ1}
          setTrustQualificationQ2={setTrustQualificationQ2}
          setTrustQualificationQ3={setTrustQualificationQ3}
          setTrustQualificationQ4={setTrustQualificationQ4}
          entityQualificationQ1={entityQualificationQ1}
          entityQualificationQ2={entityQualificationQ2}
          entityQualificationQ3={entityQualificationQ3}
          entityQualificationQ4={entityQualificationQ4}
          entityQualificationQ5={entityQualificationQ5}
          setEntityQualificationQ1={setEntityQualificationQ1}
          setEntityQualificationQ2={setEntityQualificationQ2}
          setEntityQualificationQ3={setEntityQualificationQ3}
          setEntityQualificationQ4={setEntityQualificationQ4}
          setEntityQualificationQ5={setEntityQualificationQ5}
          setQualificationDate={setQualificationDate}
        />
      );
    case "confidentialityAgreement":
      return (
        <ConfidentialityAgreementSection
          navigation={navigation}
          setConfidentialityAgreementId={setConfidentialityAgreementId}
          confidentialityAgreed={confidentialityAgreed}
          setConfidentialityAgreed={setConfidentialityAgreed}
          name={docSignatureName}
          setName={setDocSignatureName}
        />
      );
    case "termsAgreement":
      return (
        <TermsAndAgreementSection
          navigation={navigation}
          setTermsAgreementId={setTermsAgreementId}
          termsAgreed={termsAgreed}
          setTermsAgreed={setTermsAgreed}
        />
      );
    case "accountSignupFormSection":
      return (
        <AccountSignupFormSection
          navigation={navigation}
          setUserId={setUserId}
          accountTypeIndividual={accountTypeIndividual}
          accountTypeTrust={accountTypeTrust}
          accountTypeEntity={accountTypeEntity}
          accountTypeJoint={accountTypeJoint}
          accountTypeRetirement={accountTypeRetirement}
          individual5mInvestments={individual5mInvestments}
          trustQualificationQ1={trustQualificationQ1}
          trustQualificationQ2={trustQualificationQ2}
          trustQualificationQ3={trustQualificationQ3}
          trustQualificationQ4={trustQualificationQ4}
          entityQualificationQ1={entityQualificationQ1}
          entityQualificationQ2={entityQualificationQ2}
          entityQualificationQ3={entityQualificationQ3}
          entityQualificationQ4={entityQualificationQ4}
          entityQualificationQ5={entityQualificationQ5}
          qualificationDate={qualificationDate}
          confidentialityAgreementId={confidentialityAgreementId}
          confidentialityAgreementDateSigned={
            confidentialityAgreementDateSigned
          }
          termsAgreementId={termsAgreementId}
          termsAgreementDateSigned={termsAgreementDateSigned}
          email={email}
          setEmail={setEmail}
          docSignatureName={docSignatureName}
        />
      );
    case "verificationPage":
      return (
        <VerificationSection
          navigation={navigation}
          userId={userId}
          email={email}
        />
      );
    default:
      return (
        <AccountTypeSection
          navigation={navigation}
          accountTypeIndividual={accountTypeIndividual}
          accountTypeTrust={accountTypeTrust}
          accountTypeEntity={accountTypeEntity}
          accountTypeRetirement={accountTypeRetirement}
          setAccountTypeIndividual={setAccountTypeIndividual}
          setAccountTypeTrust={setAccountTypeTrust}
          setAccountTypeEntity={setAccountTypeEntity}
          setAccountTypeRetirement={setAccountTypeRetirement}
        />
      );
  }
}
export default SignupPage;
