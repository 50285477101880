import React, { useState, useEffect } from "react";
import "./InfiniteTextInputFieldStyles/InfiniteTextInputFieldStyles.scss";

function InfiniteTextInputField({
  placeholder,
  name,
  error,
  index,
  inputFields,
  initialValue,
  setInputFields,
  disabled,
}) {
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [focus, setFocus] = useState(false);
  //const [error, setError] = useState(false);
  const [divClass, setDivClass] = useState("input-box-container");
  //   const [fieldValue, setFieldValue] = useState("");

  const handleChangeInput = (index, event) => {
    // console.log("Name is:" + console.log(event.target.name));
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    values[index][event.target.name + "Error"] = "";
    setInputFields(values);
  };

  useEffect(() => {
    if (!error) {
      setDivClass(focus ? "input-box-containerFocus" : "input-box-container");
    } else {
      setDivClass("input-box-containerError");
    }
  }, [focus, error]);

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);

  //   useEffect(() => {
  //     fieldValueChange(index, fieldValue);
  //   }, [fieldValue, fieldValueChange]);
  return (
    <div
      className={divClass}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="input-box">
        <input
          className={
            error === "This field is required."
              ? "internal-input-error-empty"
              : "internal-input"
          }
          name={name}
          placeholder={
            error === "This field is required." ? "Required" : inputPlaceholder
          }
          disabled={disabled}
          value={initialValue}
          onChange={(event) => {
            // console.log("Event is: " + event.target.value);
            handleChangeInput(index, event);
          }}
        ></input>
      </div>
    </div>
  );
}
export default InfiniteTextInputField;
