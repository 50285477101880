import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

function TrustAccountBasicInformationQPModal({
  showTrustQualificationSection,
  mountedStyle,
  unmountedStyle,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontFamily: "Montserrat" }}
          >
            Qualified Purchaser
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="regulatory-info-text pe-5 pb-5">
            <b>Qualified Purchaser: </b>
            (51) (A) “Qualified purchaser” means— (i) any natural person
            (including any person who holds a joint, community property, or
            other similar shared ownership interest in an issuer that is
            excepted under section 80a–3(c)(7) of this title with that person’s
            qualified purchaser spouse) who owns not less than $5,000,000 in
            investments, as defined by the Commission; (ii) any company that
            owns not less than $5,000,000 in investments and that is owned
            directly or indirectly by or for 2 or more natural persons who are
            related as siblings or spouse (including former spouses), or direct
            lineal descendants by birth or adoption, spouses of such persons,
            the estates of such persons, or foundations, charitable
            organizations, or trusts established by or for the benefit of such
            persons; (iii) any trust that is not covered by clause (ii) and that
            was not formed for the specific purpose of acquiring the securities
            offered, as to which the trustee or other person authorized to make
            decisions with respect to the trust, and each settlor or other
            person who has contributed assets to the trust, is a person
            described in clause (i), (ii), or (iv); or (iv) any person, acting
            for its own account or the accounts of other qualified purchasers,
            who in the aggregate owns and invests on a discretionary basis, not
            less than $25,000,000 in investments. (B) The Commission may adopt
            such rules and regulations applicable to the persons and trusts
            specified in clauses (i) through (iv) of subparagraph (A) as it
            determines are necessary or appropriate in the public interest or
            for the protection of investors. (C) The term “qualified purchaser”
            does not include a company that, but for the exceptions provided for
            in paragraph (1) or (7) of section 80a–3(c) of this title , would be
            an investment company (hereafter in this paragraph referred to as an
            “excepted investment company”), unless all beneficial owners of its
            outstanding securities (other than short-term paper), determined in
            accordance with section 80a–3(c)(1)(A) of this title , that acquired
            such securities on or before April 30, 1996 (hereafter in this
            paragraph referred to as “pre-amendment beneficial owners”), and all
            pre-amendment beneficial owners of the outstanding securities (other
            than short-term paper) of any excepted investment company that,
            directly or indirectly, owns any outstanding securities of such
            excepted investment company, have consented to its treatment as a
            qualified purchaser. Unanimous consent of all trustees, directors,
            or general partners of a company or trust referred to in clause (ii)
            or (iii) of subparagraph (A) shall constitute consent for purposes
            of this subparagraph.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-lg btn-class justify-self-start"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Got it
          </button>
        </Modal.Footer>
      </Modal>
      <div
        style={showTrustQualificationSection ? mountedStyle : unmountedStyle}
        class="d-flex fade-in-test mt-2"
      >
        <div style={{ display: "inlineBlock", marginRight: "20px" }}>
          <img
            src="https://d1swyfveypj49y.cloudfront.net/GearIcon.svg"
            height="40"
          />
        </div>
        <div
          class="align-self-center"
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginBottom: "5px",
          }}
        >
          <div class="qualified-purchaser">Qualified Purchaser</div>
          <div
            style={{
              textDecoration: "underline",
              fontFamily: "Montserrat",
              fontSize: "12px",
              opacity: 1.0,
              cursor: "pointer",
            }}
            class="view-full-def mt-1"
            onClick={() => setShowModal(true)}
          >
            View Full Definition
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default TrustAccountBasicInformationQPModal;
