import { useState, useEffect, useContext } from "react";
import { getInvestorFundInvestment } from "../Api/ConfirmInvestmentApi/ConfirmInvestmentApi.js";
import { UserContext } from "../GlobalContexts/UserContext.js";

export default function GetInvestorFundInvestment(
  fundId,
  refresh,
  accountID,
  setInitial,
  setShowInvestmentConfirmation
) {
  const [investorFundInvestmentInfo, setInvestorFundInvestmentInfo] =
    useState(null);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    async function getInvestorFundInvestmentInfo() {
      try {
        const response = await getInvestorFundInvestment(fundId, accountID);
        const investorFundInvestmentData = response.data;
        if (investorFundInvestmentData.length !== 0) {
          setInvestorFundInvestmentInfo({
            id: fundId,
            investmentDate: investorFundInvestmentData.investment_date,
            investmentAmount: investorFundInvestmentData.investment_amount,
            fundName: investorFundInvestmentData.fund.name,
            status: investorFundInvestmentData.status,
          });
          if (
            investorFundInvestmentData.status == "PD" &&
            !investorFundInvestmentData.subdocs_signed
          ) {
            setInitial(1);
          } else if (investorFundInvestmentData.status == "PD") {
            setInitial(2);
          } else if (investorFundInvestmentData.status == "PF") {
            setInitial(2);
            setShowInvestmentConfirmation(true);
          } else if (investorFundInvestmentData.status == "F") {
            setInitial(2);
            setShowInvestmentConfirmation(true);
          }
        } else {
          setInvestorFundInvestmentInfo({});
          setInitial(0);
        }
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.log(err);
      }
    }
    getInvestorFundInvestmentInfo();
  }, [fundId, setInvestorFundInvestmentInfo, refresh]);

  return { investorFundInvestmentInfo };
}
