import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import "./Styles/Styles.scss";

function InvestmentConfirmationModal({
  showInvestmentConfirmationModal,
  setShowInvestmentConfirmationModal,
  setRefresh,
  accountID,
}) {
  let history = useHistory();

  return (
    <Modal
      show={showInvestmentConfirmationModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={() => setShowInvestmentConfirmationModal(false)}
      onEntered={() => console.log("Entered.")}
      onExited={() => setRefresh((prevState) => prevState + 1)}
    >
      <Modal.Body closeButton>
        <Modal.Header>
          <div className="d-flex align-items-center">
            <span className="modal-title-font">Thanks for Investing!</span>
          </div>
        </Modal.Header>
        <div className="col-12">
          <div className="row g-0 w-100 mt-4 justify-content-center">
            <p className="modal-body-text">
              {
                "You're all set with your investment! Please keep an eye out for capital call notifications from us via email, so you can be sure to fund your capital calls on time."
              }
            </p>
            <p className="modal-body-text">
              {
                "You can monitor your investment performance from the portfolio section of our platform."
              }
            </p>
          </div>
          <div className="row g-0 w-100">
            <span className="contact-us-text">
              Please reach out to{" "}
              <a href="mailto: ops@investwithaqua.com">
                admin@investwithaqua.com
              </a>{" "}
              if you have any questions.
            </span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row g-0 w-100">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-lg btn-class justify-self-start"
              onClick={() => {
                history.push("/portfolio");
              }}
            >
              Go to Portfolio
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default InvestmentConfirmationModal;
