import React, { useEffect } from "react";
import { css as emoCSS } from "@emotion/css";
import styled from "@emotion/styled";
import starWarsNames from "starwars-names";
import { matchSorter } from "match-sorter";
import { Global, css as newcss } from "@emotion/react";
import "./InfiniteStateInputFieldStyles/InfiniteStateInputFieldStyles.scss";

const GlobalStyles = newcss`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

  @font-face {
    font-family: "Local Font";
    src: url("fonts/DancingScript-Regular.ttf");
  }

  * {
    text-align: center;
  }
`;

const allItems = starWarsNames.all.map((s) => ({
  name: s,
  id: s.toLowerCase(),
}));
const list = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const listOfStates = list.map((s) => ({
  name: s,
  label: s.toLowerCase(),
}));

// const listOfStates = [
//   "Alabama",
//   "Alaska",
//   "American Samoa",
//   "Arizona",
//   "Arkansas",
//   "California",
//   "Colorado",
//   "Connecticut",
//   "Delaware",
//   "District of Columbia",
//   "Federated States of Micronesia",
//   "Florida",
//   "Georgia",
//   "Guam",
//   "Hawaii",
//   "Idaho",
//   "Illinois",
//   "Indiana",
//   "Iowa",
//   "Kansas",
//   "Kentucky",
//   "Louisiana",
//   "Maine",
//   "Marshall Islands",
//   "Maryland",
//   "Massachusetts",
//   "Michigan",
//   "Minnesota",
//   "Mississippi",
//   "Missouri",
//   "Montana",
//   "Nebraska",
//   "Nevada",
//   "New Hampshire",
//   "New Jersey",
//   "New Mexico",
//   "New York",
//   "North Carolina",
//   "North Dakota",
//   "Northern Mariana Islands",
//   "Ohio",
//   "Oklahoma",
//   "Oregon",
//   "Palau",
//   "Pennsylvania",
//   "Puerto Rico",
//   "Rhode Island",
//   "South Carolina",
//   "South Dakota",
//   "Tennessee",
//   "Texas",
//   "Utah",
//   "Vermont",
//   "Virgin Island",
//   "Virginia",
//   "Washington",
//   "West Virginia",
//   "Wisconsin",
//   "Wyoming",
// ].map((s) => ({
//   name: s,
//   id: s.toLowerCase(),
// }));

const css = (...args) => ({ className: emoCSS(...args) });

const Item = styled("li")(
  {
    position: "relative",
    cursor: "pointer",
    display: "block",
    border: "none",
    height: "auto",
    textAlign: "left",
    borderTop: "none",
    lineHeight: "1em",
    color: "#262A3D",
    fontFamily: "Montserrat",
    fontSize: "15px",
    textTransform: "none",
    fontWeight: "400",
    boxShadow: "none",
    padding: ".8rem 1.1rem",
    whiteSpace: "normal",
    wordWrap: "normal",
  },
  ({ isActive, isSelected }) => {
    const styles = [];
    if (isActive) {
      styles.push({
        color: "rgba(0,0,0,.95)",
        background: "rgba(0,0,0,.03)",
      });
    }
    if (isSelected) {
      styles.push({
        color: "rgba(0,0,0,.95)",
        fontWeight: "700",
      });
    }
    return styles;
  }
);
const onAttention = " &:focus";
const Input = styled("input")(
  {
    width: "100%", // full width - icon width/2 - border
    fontFamily: "Montserrat",
    fontSize: "15px",
    wordWrap: "break-word",
    lineHeight: "1em",
    outline: 0,
    whiteSpace: "normal",
    minHeight: "2em",
    height: "56px",
    background: "#fff",
    display: "inline-block",
    padding: "1em 2em 1em 1em",
    color: "rgba(0,0,0,.87)",
    boxShadow: "none",
    border: "1px solid rgba(34,36,38,.15)",
    borderRadius: "4px",
    transition: "box-shadow .1s ease,width .1s ease",
    [onAttention]: {
      border: "1px solid #0D47A1",
      boxShadow: "0 2px 3px 0 rgba(34,36,38,.15)",
    },
  },
  ({ isOpen }) =>
    isOpen
      ? {
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          [onAttention]: {
            boxShadow: "none",
          },
        }
      : null
);

const Label = styled("label")({
  fontFamily: "Montserrat",
  color: "#262A3D",
  fontWeight: "normal",
  display: "block",
  marginBottom: 10,
});

const BaseMenu = styled("ul")(
  {
    padding: 0,
    marginTop: 0,
    position: "absolute",
    backgroundColor: "white",
    width: "100%",
    maxHeight: "20rem",
    overflowY: "auto",
    overflowX: "hidden",
    outline: "0",
    transition: "opacity .1s ease",
    borderRadius: "0 0 .28571429rem .28571429rem",
    boxShadow: "0 2px 3px 0 rgba(34,36,38,.15)",
    borderColor: "#0D47A1",
    borderTopWidth: "0",
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: "solid",
  },
  ({ isOpen }) => ({
    border: isOpen ? null : "none",
  })
);

const Menu = React.forwardRef((props, ref) => (
  <BaseMenu innerRef={ref} {...props} />
));

const ControllerButton = styled("button")({
  backgroundColor: "transparent",
  border: "none",
  position: "absolute",
  right: 0,
  top: 0,
  cursor: "pointer",
  width: 47,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={16}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
      transform={isOpen ? "rotate(180)" : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

function getItems(filter) {
  return filter
    ? matchSorter(listOfStates, filter, {
        keys: ["name"],
      })
    : listOfStates;
}

function getStringItems(filter) {
  return getItems(filter).map(({ name }) => name);
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function getItemsAsync(filter, { reject }) {
  await sleep(Math.random() * 2000);
  if (reject) {
    // this is just so we can have examples that show what happens
    // when there's a request failure.
    throw new Error({ error: "request rejected" });
  }
  return getItems(filter);
}

const itemToString = (i) => (i ? i.name : "");

const items = [
  "Neptunium",
  "Plutonium",
  "Americium",
  "Curium",
  "Berkelium",
  "Californium",
  "Einsteinium",
  "Fermium",
  "Mendelevium",
  "Nobelium",
  "Lawrencium",
  "Rutherfordium",
  "Dubnium",
  "Seaborgium",
  "Bohrium",
  "Hassium",
  "Meitnerium",
  "Darmstadtium",
  "Roentgenium",
  "Copernicium",
  "Nihonium",
  "Flerovium",
  "Moscovium",
  "Livermorium",
  "Tennessine",
  "Oganesson",
];

const menuStyles = {
  maxHeight: 80,
  maxWidth: 150,
  overflowY: "scroll",
  backgroundColor: "#eee",
  padding: 0,
  listStyle: "none",
  position: "relative",
};

const menuMultipleStyles = {
  maxHeight: "180px",
  overflowY: "auto",
  width: "135px",
  margin: 0,
  borderTop: 0,
  background: "white",
  position: "absolute",
  zIndex: 1000,
  listStyle: "none",
  padding: 0,
  left: "340px",
};

const selectedItemStyles = {
  marginLeft: "5px",
  backgroundColor: "aliceblue",
  borderRadius: "10px",
};

const selectedItemIconStyles = { cursor: "pointer" };

const comboboxStyles = { display: "inline-block", marginLeft: "5px" };

const comboboxWrapperStyles = {
  display: "inline-flex",
  flexWrap: "wrap",
};

export {
  menuMultipleStyles,
  items,
  menuStyles,
  comboboxStyles,
  comboboxWrapperStyles,
  selectedItemIconStyles,
  selectedItemStyles,
  Menu,
  ControllerButton,
  Input,
  Item,
  ArrowIcon,
  XIcon,
  Label,
  css,
  itemToString,
  getItems,
  getStringItems,
  getItemsAsync,
};
