import React from "react";
import EntityAccountRelatedPartyInformation from "./EntityAccountRelatedPartyInformation/EntityAccountRelatedPartyInformation";
import TrustAccountRelatedPartyInformation from "./TrustAccountRelatedPartyInformation/TrustAccountRelatedPartyInformation";
import "./AccountRelatedPartyInformationStyles/AccountRelatedPartyInformationStyles.scss";

const AccountRelatedPartyInformation = ({
  accountType,
  processedCountryList,
  processedStateList,
  setActiveSlide,
  accountID,
  mountedStyle,
  unmountedStyle,
  supplyPercentOwned,
}) => {
  return (
    <React.Fragment>
      {(accountType === "TRUST" || accountType === "LT") && (
        <TrustAccountRelatedPartyInformation
          processedCountryList={processedCountryList}
          processedStateList={processedStateList}
          setActiveSlide={setActiveSlide}
          accountID={accountID}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          supplyPercentOwned={supplyPercentOwned}
          accountType={accountType}
        />
      )}
      {(accountType === "LLC" ||
        accountType === "C-CORP" ||
        accountType === "S-CORP" ||
        accountType === "LP" ||
        accountType === "GP" ||
        accountType === "FE") && (
        <EntityAccountRelatedPartyInformation
          accountType={accountType}
          processedCountryList={processedCountryList}
          processedStateList={processedStateList}
          setActiveSlide={setActiveSlide}
          accountID={accountID}
          supplyPercentOwned={supplyPercentOwned}
        />
      )}
    </React.Fragment>
  );
};

export default AccountRelatedPartyInformation;
