import React, { useState, useEffect } from "react";
import "./InvestPageStyles/InvestPageStyles.css";
import Header from "../../../Components/GlobalComponents/Header.js";
import InvestPageHeader from "./InvestPageHeader/InvestPageHeader.js";
import FilterRow from "./FilterRow/FilterRow.js";
import FundCard from "./FundCard/FundCard.js";
import useFundSearch from "../../../Api/GlobalFunctions/useFundSearch.js";
import LoadingAnimation from "../../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation.js";
import NoFundsFoundCard from "./FundCard/NoFundsFoundCard/NoFundsFoundCard.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AlertSnackBar from "../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import { isFeederAPI } from "../../../Api/FundCardApi.js";

function InvestPage() {
  const [searchKeyWords, setSearchKeyWords] = useState("");
  const [fundMinimumAllocation, setFundMinimumAllocation] = useState("");
  const [minInvestment, setMinInvestment] = useState("All");
  const [defaultMinInvestment, setDefaultMinInvestment] = useState([]);
  const [strategyType, setStrategyType] = useState("All");
  const [defaultStrategyType, setDefaultStrategyType] = useState("All");
  const [status, setStatus] = useState("All");
  const [defaultStatus, setDefaultStatus] = useState(["All"]);
  const [loading, setLoading] = useState(false);
  const [feeder, setFeeder] = useState(null);

  const location = useLocation();

  const [accountLinkSuccessful, setAccountLinkSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.accountLinked !== null &&
      location.state.accountLinked !== undefined
      ? location.state.accountLinked
      : false
  );

  const [initialVal, setInitialVal] = useState(0);

  useEffect(() => {
    async function isFeeder() {
      var value = await isFeederAPI();
      if (value.data.msg === true) {
        setFeeder(true);
      } else if (value.data.msg === false) {
        setFeeder(false);
      }
      // isFeeder.data.msg === true && setFeeder(true);
    }
    isFeeder();
  }, [initialVal]);

  useEffect(() => {
    let minInvestmentFilterItems = "";
    let defaultSelected = [];
    if (minInvestment !== "") {
      defaultSelected.push(minInvestment);
    }
    if (minInvestment === "$10,000") {
      minInvestmentFilterItems = 10000;
    } else if (minInvestment === "$50,000") {
      minInvestmentFilterItems = 50000;
    } else if (minInvestment === "$100,000") {
      minInvestmentFilterItems = 100000;
    }
    setDefaultMinInvestment(defaultSelected);
    setFundMinimumAllocation(minInvestmentFilterItems);
  }, [minInvestment]);

  useEffect(() => {
    let defaultItems = [];
    let statusArray = [];
    if (status !== "All") {
      statusArray.push(status);
    }
    defaultItems.push(status);
    setDefaultStatus(defaultItems);
  }, [status]);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log("Strategy type is: " + strategyType);
    }
    setDefaultStrategyType(strategyType);
  }, [strategyType]);

  const { funds } = useFundSearch(
    searchKeyWords,
    fundMinimumAllocation,
    status,
    strategyType,
    setLoading
  );

  return (
    <React.Fragment>
      <Header activePage={"invest"}></Header>
      <div className="pt-5 mt-3"></div>
      <div className="container justify-content-center">
        <div className="row g-0 justify-content-center">
          <div className="row g-0 w-100 justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 g-0">
              <InvestPageHeader />
            </div>
          </div>
          <div className="row g-0 w-100 justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row g-0 w-100">
                <div className="container overflow-hidden">
                  <FilterRow
                    setQuery={setSearchKeyWords}
                    minInvestment={minInvestment}
                    setMinInvestment={setMinInvestment}
                    defaultMinInvestment={defaultMinInvestment}
                    status={status}
                    setStatus={setStatus}
                    defaultStatus={defaultStatus}
                    strategyType={strategyType}
                    setStrategyType={setStrategyType}
                    defaultStrategyType={defaultStrategyType}
                  />
                  <LoadingAnimation loading={loading}>
                    {!loading && funds.length === 0 ? (
                      <NoFundsFoundCard />
                    ) : (
                      <div className="row gx-0 gy-4 mt-2 mb-5">
                        {funds.map((fund) => {
                          return (
                            <FundCard
                              key={fund.id}
                              fund={fund}
                              isFeeder={feeder}
                            />
                          );
                        })}
                      </div>
                    )}
                  </LoadingAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertSnackBar
        snackbarState={accountLinkSuccessful}
        setSnackbarState={setAccountLinkSuccessful}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"success"}
        message={"You have successfully linked your account."}
      />
    </React.Fragment>
  );
}
export default InvestPage;
