import React, { useEffect } from "react";
import IndividualSignupForm from "./IndividualSignupForm/IndividualSignupForm";
import TrustSignupForm from "./TrustSignupForm/TrustSignupForm";
import EntitySignupForm from "./EntitySignupForm/EntitySignupForm";

function AccountSignupFormSection({
  navigation,
  setUserId,
  accountTypeIndividual,
  accountTypeTrust,
  accountTypeEntity,
  accountTypeRetirement,
  accountTypeJoint,
  individual5mInvestments,
  trustQualificationQ1,
  trustQualificationQ2,
  trustQualificationQ3,
  trustQualificationQ4,
  entityQualificationQ1,
  entityQualificationQ2,
  entityQualificationQ3,
  entityQualificationQ4,
  entityQualificationQ5,
  qualificationDate,
  confidentialityAgreementId,
  confidentialityAgreementDateSigned,
  termsAgreementId,
  termsAgreementDateSigned,
  email,
  setEmail,
  docSignatureName,
}) {
  useEffect(() => {
    // console.log("Account individual is: " + accountTypeIndividual);
    // console.log("Account trust is: " + accountTypeTrust);
  }, []);
  return (accountTypeIndividual||accountTypeJoint) ? (
    <IndividualSignupForm
      joint={accountTypeJoint}
      navigation={navigation}
      email={email}
      setEmail={setEmail}
      setUserId={setUserId}
      individual5mInvestments={individual5mInvestments}
      qualificationDate={qualificationDate}
      confidentialityAgreementId={confidentialityAgreementId}
      confidentialityAgreementDateSigned={confidentialityAgreementDateSigned}
      termsAgreementId={termsAgreementId}
      termsAgreementDateSigned={termsAgreementDateSigned}
      docSignatureName={docSignatureName}
    />
  ) : accountTypeTrust ? (
    <TrustSignupForm
      navigation={navigation}
      email={email}
      setEmail={setEmail}
      setUserId={setUserId}
      trustQualificationQ1={trustQualificationQ1}
      trustQualificationQ2={trustQualificationQ2}
      trustQualificationQ3={trustQualificationQ3}
      trustQualificationQ4={trustQualificationQ4}
      qualificationDate={qualificationDate}
      confidentialityAgreementId={confidentialityAgreementId}
      confidentialityAgreementDateSigned={confidentialityAgreementDateSigned}
      termsAgreementId={termsAgreementId}
      termsAgreementDateSigned={termsAgreementDateSigned}
      docSignatureName={docSignatureName}
    />
  ) : accountTypeEntity ? (
    <EntitySignupForm
      navigation={navigation}
      email={email}
      setEmail={setEmail}
      setUserId={setUserId}
      entityQualificationQ1={entityQualificationQ1}
      entityQualificationQ2={entityQualificationQ2}
      entityQualificationQ3={entityQualificationQ3}
      entityQualificationQ4={entityQualificationQ4}
      entityQualificationQ5={entityQualificationQ5}
      qualificationDate={qualificationDate}
      confidentialityAgreementId={confidentialityAgreementId}
      confidentialityAgreementDateSigned={confidentialityAgreementDateSigned}
      termsAgreementId={termsAgreementId}
      termsAgreementDateSigned={termsAgreementDateSigned}
      docSignatureName={docSignatureName}
    />
  ) : null;
}
export default AccountSignupFormSection;
