import React, { useState, useEffect } from "react";
import StateBasedButton from "../../../../../GlobalComponents/Button/StateBasedButton/StateBasedButton";
// import { Link } from "react-router-dom";
import "./IndividualQualificationSectionStyles/IndividualQualificationSectionStyles.scss";
import { useHistory } from "react-router-dom";
import { getDateUTC } from "../../../../../GlobalFunctions/globalFunctions.js";
import Modal from "react-bootstrap/Modal";

function QualifiedPurchaserModal({ show, setShowModal }) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontFamily: "Montserrat" }}
        >
          Qualified Purchaser
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="regulatory-info-text pe-5 pb-5">
          <b>Qualified Purchaser: </b>
          (51) (A) “Qualified purchaser” means— (i) any natural person
          (including any person who holds a joint, community property, or other
          similar shared ownership interest in an issuer that is excepted under
          section 80a–3(c)(7) of this title with that person’s qualified
          purchaser spouse) who owns not less than $5,000,000 in investments, as
          defined by the Commission; (ii) any company that owns not less than
          $5,000,000 in investments and that is owned directly or indirectly by
          or for 2 or more natural persons who are related as siblings or spouse
          (including former spouses), or direct lineal descendants by birth or
          adoption, spouses of such persons, the estates of such persons, or
          foundations, charitable organizations, or trusts established by or for
          the benefit of such persons; (iii) any trust that is not covered by
          clause (ii) and that was not formed for the specific purpose of
          acquiring the securities offered, as to which the trustee or other
          person authorized to make decisions with respect to the trust, and
          each settlor or other person who has contributed assets to the trust,
          is a person described in clause (i), (ii), or (iv); or (iv) any
          person, acting for its own account or the accounts of other qualified
          purchasers, who in the aggregate owns and invests on a discretionary
          basis, not less than $25,000,000 in investments. (B) The Commission
          may adopt such rules and regulations applicable to the persons and
          trusts specified in clauses (i) through (iv) of subparagraph (A) as it
          determines are necessary or appropriate in the public interest or for
          the protection of investors. (C) The term “qualified purchaser” does
          not include a company that, but for the exceptions provided for in
          paragraph (1) or (7) of section 80a–3(c) of this title , would be an
          investment company (hereafter in this paragraph referred to as an
          “excepted investment company”), unless all beneficial owners of its
          outstanding securities (other than short-term paper), determined in
          accordance with section 80a–3(c)(1)(A) of this title , that acquired
          such securities on or before April 30, 1996 (hereafter in this
          paragraph referred to as “pre-amendment beneficial owners”), and all
          pre-amendment beneficial owners of the outstanding securities (other
          than short-term paper) of any excepted investment company that,
          directly or indirectly, owns any outstanding securities of such
          excepted investment company, have consented to its treatment as a
          qualified purchaser. Unanimous consent of all trustees, directors, or
          general partners of a company or trust referred to in clause (ii) or
          (iii) of subparagraph (A) shall constitute consent for purposes of
          this subparagraph.
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-lg btn-class justify-self-start"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Got it
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function QualifiedPurchaserLink({ setShowModal }) {
  return (
    <div style={{ marginBottom: "15px" }} class="d-flex">
      <div style={{ display: "inlineBlock", marginRight: "20px" }}>
        <img src="QualifiedPurchaser.svg" height="64" />
      </div>
      <div
        class="align-self-center"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginBottom: "5px",
        }}
      >
        <div class="qualified-purchaser">Qualified Purchaser</div>
        <div
          style={{
            textDecoration: "underline",
            fontFamily: "Montserrat",
            fontSize: "12px",
            opacity: 1.0,
            cursor: "pointer",
          }}
          class="view-full-def mt-1"
          onClick={() => setShowModal(true)}
        >
          View Full Definition
        </div>
      </div>
    </div>
  );
}

function AccreditedInvestorLink({ setShowModal }) {
  return (
    <div style={{ marginBottom: "15px" }} class="d-flex">
      <div style={{ display: "inlineBlock", marginRight: "20px" }}>
        <img src="QualifiedPurchaser.svg" height="64" />
      </div>
      <div
        class="align-self-center"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginBottom: "5px",
        }}
      >
        <div class="qualified-purchaser">Accredited Investor</div>
        <div
          style={{
            textDecoration: "underline",
            fontFamily: "Montserrat",
            fontSize: "12px",
            opacity: 1.0,
            cursor: "pointer",
          }}
          class="view-full-def mt-1"
          onClick={() => setShowModal(true)}
        >
          View Full Definition
        </div>
      </div>
    </div>
  );
}

function IndividualQualificationSection({
  navigation,
  selection1,
  selection2,
  setSelection1,
  setSelection2,
  setQualificationDate,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selection1Focus, setSelection1Focus] = useState(false);
  const [selection2Focus, setSelection2Focus] = useState(false);
  const [divClass1, setDivClass1] = useState("selection-1");
  const [divClass2, setDivClass2] = useState("selection-2");
  const [proceed, setProceed] = useState(false);
  const [qpError, setQPError] = useState("");

  let history = useHistory();

  useEffect(() => {
    setSelection1Focus(selection1);
    setSelection2Focus(selection2);
  }, []);

  useEffect(() => {
    setDivClass1(selection1Focus ? "selection-1-selected" : "selection-1");
    setSelection1(selection1Focus);
    setQPError("");
    if (selection1Focus) {
      setProceed(true);
    } else {
      setProceed(false);
    }
  }, [selection1Focus, setSelection1]);

  useEffect(() => {
    setDivClass2(selection2Focus ? "selection-2-selected" : "selection-2");
    setSelection2(selection2Focus);
    setQPError("");
  }, [selection2Focus, setSelection2]);

  const validateQPStatus = () => {
    let isQP = true;
    if (!proceed) {
      isQP = false;
    }
    if (!selection1) {
      isQP = false;
    }

    if (isQP) {
      var utcDate = getDateUTC();
      setQualificationDate(utcDate.toISOString());
      navigation.next();
    } else {
      setQPError(
        "Unfortunately, our opportunities are only open to qualified purchasers at this time, but we'll have investment opportunities for you very soon."
      );
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal show={showModal} setShowModal={setShowModal} />
      <div className="container-fluid w-100 h-100 g-0 ms-0 me-0 fillScreen align-items-center justify-content-start d-flex">
        <div className="row g-0 w-100 align-items-center justify-content-start">
          <div
            className="col-6 d-none d-sm-none d-md-none d-lg-block"
            style={{ backgroundColor: "#F0F4FD", zIndex: 1 }}
          >
            <img
              src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
              style={{
                height: "100vh",
                backgroundSize: "cover !important",
                zIndex: 2,
              }}
              alt="community"
            ></img>
          </div>
          {/* <div className="col-6 d-none d-sm-none d-md-none d-lg-block"></div> */}
          <div
            className="col-xxl-6 col-xl-6 col-lg-6 col-12"
            style={{ zIndex: 3, backgroundColor: "#f7f7fa", height: "100vh" }}
          >
            <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
              <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
                <div className="col-12">
                  <div className="row g-0 w-100 mb-4 mt-3">
                    <div className="col-12">
                      <div onClick={() => navigation.previous()}>
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-2">
                    <div className="col-12">
                      <span className="join-text">Join Aqua</span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-12">
                      <span className="sub-text">
                        Please confirm your{" "}
                        <strong>Qualified Purchaser Status</strong>
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-12">
                      <span className="sub-text-gray">The Subscriber is:</span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-10">
                      <div
                        className={divClass1}
                        onClick={() => {
                          setSelection2Focus(false);
                          setSelection1Focus(!selection1Focus);
                        }}
                      >
                        <div className="col">
                          <div className="row g-0 w-100">
                            <span className="selection-1-text">
                              A natural person who alone (or together with
                              his/her spouse, if investing jointly) owns not
                              less than $5,000,000 in investments.
                            </span>
                          </div>
                          <div className="row g-0 w-100">
                            <span className="regulatory-info-text ps-2 pb-3">
                              *This definition of qualified purchaser is
                              incomplete. See below for the full definition.*
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col-10">
                      <div
                        className={divClass2}
                        onClick={() => {
                          setProceed(false);
                          setSelection1Focus(false);
                          setSelection2Focus(!selection2Focus);
                        }}
                      >
                        <span className="selection-2-text">
                          None of the above (not a Qualified Purchaser)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-10">
                      <span className="regulatory-info-text">
                        The information provided will be used to assist us in
                        complying with SEC regulations.
                      </span>
                    </div>
                  </div>
                  {qpError ? (
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-10">
                        <div className="row g-0 w-100">
                          <div className="col">
                            <img
                              src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                              alt="error"
                              width="15"
                              height="15"
                            />
                            <span className="ms-3 qp-error-text">
                              {qpError}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row g-0 w-100 mb-5">
                    <div className="col-10">
                      <div className="row g-0 w-100">
                        <div className="col-4 ms-0 me-auto">
                          <div
                            onClick={() => validateQPStatus()}
                            style={{ cursor: "pointer" }}
                          >
                            <StateBasedButton
                              proceed={true}
                              text={"Next Step"}
                            />
                          </div>
                        </div>
                        <div className="col-4 align-self-end text-end">
                          <span
                            className="sign-in-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/login")}
                          >
                            <u>or Sign In</u>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100">
                    <div className="col-xxl-6 col-xl-6 col-lg-7 col-sm-6 col-8">
                      <div className="row gx-1 w-100">
                        <div className="row g-0 w-100">
                          <div className="col">
                            <div className="progress-bar-completed"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mt-5 pe-5">
                    <div className="col-11">
                      <QualifiedPurchaserLink setShowModal={setShowModal} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export {
  QualifiedPurchaserModal,
  QualifiedPurchaserLink,
  AccreditedInvestorLink,
  IndividualQualificationSection,
};
