import React, { useState, useEffect } from "react";
import Header from "../../../Components/GlobalComponents/Header.js";
import "./DocumentsPageStyles/DocumentsPageStyles.css";
import LargeVerticalTabs from "../../../Components/GlobalComponents/VerticalTabs/LargeVerticalTabs.js";
import VerticalTab from "../../../Components/GlobalComponents/VerticalTabs/VerticalTab.js";
// import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useHistory } from "react-router-dom";
// import { Storage } from "aws-amplify";
import AccountStatements from "./AccountStatements.js";
import TaxDocuments from "./TaxDocuments.js";
import UserFundDocuments from "./UserFundDocuments.js";
import GetAccountStatement from "../../../Adapters/AccountStatementAdapters.js";
import GetUserTaxDocuments from "../../../Adapters/TaxDocumentsAdapter.js";
// import * as dayjs from "dayjs";
import GetUserFundDocuments from "../../../Adapters/UserFundDocuments.js";
import Media from "react-media";
import MobileDocumentsSection from "./MobileDocumentsSection/MobileDocumentsSection.js";
// var utc = require("dayjs/plugin/utc");

function DocumentsPage() {
  // let history = useHistory();
  // const timeStamp = Date.now();
  const [retrieveInfo, setRetrieveInfo] = useState(false);
  const { accountStatements } = GetAccountStatement(retrieveInfo);
  const { taxDocuments } = GetUserTaxDocuments(retrieveInfo);
  const { userFundDocuments } = GetUserFundDocuments(retrieveInfo);

  const [accountStatementsSelected, setAccountStatementsSelected] =
    useState(true);
  const [closingDocumentsSelected, setClosingDocumentsSelected] =
    useState(false);
  const [taxDocumentsSelected, setTaxDocumentsSelected] = useState(false);
  const [documentType, setDocumentType] = useState("Statements");

  useEffect(() => {
    if (accountStatementsSelected) {
      setClosingDocumentsSelected(false);
      setTaxDocumentsSelected(false);
    }
  }, [accountStatementsSelected]);

  useEffect(() => {
    if (closingDocumentsSelected) {
      setAccountStatementsSelected(false);
      setTaxDocumentsSelected(false);
    }
  }, [closingDocumentsSelected]);

  useEffect(() => {
    if (taxDocumentsSelected) {
      setAccountStatementsSelected(false);
      setClosingDocumentsSelected(false);
    }
  }, [taxDocumentsSelected]);

  useEffect(() => {
    if (documentType === "Statements") {
      setAccountStatementsSelected(true);
      setClosingDocumentsSelected(false);
      setTaxDocumentsSelected(false);
    } else if (documentType === "Closing") {
      setAccountStatementsSelected(false);
      setClosingDocumentsSelected(true);
      setTaxDocumentsSelected(false);
    } else if (documentType === "Tax") {
      setAccountStatementsSelected(false);
      setClosingDocumentsSelected(false);
      setTaxDocumentsSelected(true);
    }
  }, [documentType]);

  return (
    <React.Fragment>
      <Header activePage={""} />
      <div className="mt-5 pt-5"></div>
      <div className="container-xxl contain">
        <div className="row g-0 mt-4 w-100">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4">
              <div className="col-12">
                <span className="page-title">Documents</span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-5">
              <div className="col-12">
                <Media
                  queries={{
                    medium: { maxWidth: 768 },
                    large: { minWidth: 992 },
                  }}
                >
                  {(matches) =>
                    matches.large ? (
                      <LargeVerticalTabs initial={0}>
                        <VerticalTab
                          title={"Account Statements"}
                          selected={accountStatementsSelected}
                          setSelected={setAccountStatementsSelected}
                        >
                          <AccountStatements
                            accountStatements={accountStatements}
                          />
                        </VerticalTab>
                        <VerticalTab
                          title={"Closing Documents"}
                          selected={closingDocumentsSelected}
                          setSelected={setClosingDocumentsSelected}
                        >
                          <UserFundDocuments
                            userFundDocuments={userFundDocuments}
                          />
                        </VerticalTab>
                        <VerticalTab
                          title={"Tax Documents"}
                          selected={taxDocumentsSelected}
                          setSelected={setTaxDocumentsSelected}
                        >
                          <TaxDocuments taxDocuments={taxDocuments} />
                        </VerticalTab>
                      </LargeVerticalTabs>
                    ) : (
                      <MobileDocumentsSection
                        documentType={documentType}
                        setDocumentType={setDocumentType}
                        accountStatements={accountStatements}
                        closingDocuments={userFundDocuments}
                        taxDocuments={taxDocuments}
                      />
                    )
                  }
                </Media>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DocumentsPage;
