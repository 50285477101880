import React, { useState, useEffect } from "react";
import "./DocsSectionStyles/DocsSectionStyles.css";
import DocumentBox from "./DocumentBox/DocumentBox.js";
import GetFundDocs from "../../../../../Adapters/DocsSectionAdapter.js";
require("dayjs/plugin/utc");

function DocsSection({ fund, deckOnly }) {
  const {fundDocs} = GetFundDocs(fund, deckOnly);
  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    setAllDocs(fundDocs);
  }, [fundDocs]);

  return (
    <div className="row g-0 ms-4 me-4 mt-4">
      {!deckOnly && <div className="col-12 docs-header-text">Docs</div> }
      <div className="container">
        <div className="row mt-2 gx-3 gy-2">
          {fundDocs.map((document, index) => {
            return (
              <DocumentBox
                key={index}
                documentName={document.documentName}
                signedLink={document.documentLink}
                displayDocument={document.display}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default DocsSection;
