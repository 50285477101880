import React from "react";
import FromFilter from "../../../Components/GlobalComponents/DateFilters/FromFilter.js";
import ToFilter from "../../../Components/GlobalComponents/DateFilters/ToFilter.js";
import SingleSelectDropdown from "../../../Components/GlobalComponents/SingleSelectDropdown/SingleSelectDropdown.js";
import "./PortfolioFilterRowStyles/PortfolioFilterRowStyles.css";

function PortfolioFilterRow({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  text,
  setText,
  defaultKey,
}) {
  return (
    <div className="row g-0 w-100 mb-4">
      <div className="col-12">
        <div className="row gx-4 w-100 align-items-center">
          <div className=" col-xl-2 col-lg-2 col-12 me-md-auto mb-lg-0 mb-3">
            <span className="portfolio-title-text">Portfolio</span>
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-sm d-flex justify-self-end justify-content-end">
            <div className="row g-0 w-100">
              <FromFilter selectedDay={fromDate} setSelectedDay={setFromDate} />
            </div>
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-sm mt-sm-0 mt-3 d-flex justify-self-end justify-content-end">
            <div className="row g-0 w-100">
              <ToFilter selectedDay={toDate} setSelectedDay={setToDate} />
            </div>
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md col-sm-5 col-12 mt-sm-0 mt-3 h-100 justify-self-end justify-content-end align-items-center">
            <div className="h-100 w-100">
              <SingleSelectDropdown
                text={text}
                setText={setText}
                defaultKey={defaultKey}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PortfolioFilterRow;
