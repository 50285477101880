import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function StyledPasswordInputField({
  value,
  setValue,
  valueError,
  setValueError,
  label,
  required,
  fullWidth,
  size,
  id,
  disabled,
  shrink,
}) {
  useEffect(() => {
    setValueError("");
  }, [value]);

  const [isMasked, setIsMasked] = useState(true);

  return (
    <Box sx={{ minWidth: 120 }}>
      <TextField
        error={valueError !== ""}
        type={isMasked ? "password" : "text"}
        id={id}
        label={label}
        helperText={valueError}
        value={value}
        size={size}
        disabled={disabled}
        onChange={(event) => setValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsMasked(true)}
                onMouseDown={(e) => {
                  setIsMasked(false);
                  e.preventDefault();
                }}
                edge="end"
              >
                {!isMasked ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: "Montserrat",
            color: "#262A3D",
            fontSize: "14px",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#0D47A1",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: "#0D47A1",
              color: "#0D47A1",
            },
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "#DADDE1",
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            "& > fieldset": {
              borderColor: "#DADDE1",
            },
          },
          "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
            "& > fieldset": {
              borderColor: valueError === "" ? "#DADDE1" : "#D32F2F",
            },
          },
        }}
        required={required}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: shrink }}
      />
    </Box>
  );
}
export default StyledPasswordInputField;
