import React from "react";
import Button from "@mui/material/Button";
import "./Styles/Styles.scss";
import { CircularProgress } from "@mui/material";

const MuiButton = ({
  buttonText,
  onClick,
  size,
  loading,
  disabled = false,
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      className="Button"
      disableElevation
      size={size}
      disabled={loading || disabled}
    >
      <span className="body-text-sm-medium">{buttonText}</span>
      {loading && <CircularProgress color={"primary"} size={"14px"} />}
    </Button>
  );
};

export default MuiButton;
