import React from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../../../../Components/countryList.js";

function AccountLegalStructure({
  entityType,
  setEntityType,
  entityTypeError,
  setEntityTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
}) {
  const entityTypeOptions = [
    { value: "LLC", label: "LLC" },
    { value: "SM_LLC", label: "Single Member LLC" },
    { value: "C-CORP", label: "C Corporation" },
    { value: "S-CORP", label: "S Corporation" },
    { value: "LP", label: "Limited Partnership" },
    { value: "GP", label: "General Partnership" },
    { value: "FE", label: "Foreign Entity" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectField
            value={entityType}
            setValue={setEntityType}
            valueError={entityTypeError}
            setValueError={setEntityTypeError}
            label={"Entity Type"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            selectOptions={entityTypeOptions}
            id={"entity-type-input"}
            shrink={true}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={accountName}
            setValue={setAccountName}
            valueError={accountNameError}
            setValueError={setAccountNameError}
            label={"Entity Name"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"entity-name-input"}
            shrink={true}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-0">
          <SelectField
            value={countryOfFormation}
            setValue={setCountryOfFormation}
            valueError={countryOfFormationError}
            setValueError={setCountryOfFormationError}
            label={"Country Of Formation"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"entity-country-of-formation-input"}
            shrink={true}
            selectOptions={countryList}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default AccountLegalStructure;
