import axios from "axios";
import { HOST } from "../host-config.js";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";

//Dev environment endpoint
export const getUserInvestments = async (active, accountId) => {
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      active_funds_only: active,
      account_id: accountId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/investor-fund-investments-by-date/", requestParams);
};

//Dev environment endpoint
export const getUserDistributions = async (active, accountId) => {
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      active_funds_only: active,
      account_id: accountId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return axios.get(
    HOST + "/investor-fund-distributions-by-date/",
    requestParams
  );
};
