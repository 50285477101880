import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;
  const containerStyles = {
    height: "100%",
    width: "100%",
    backgroundColor: props
      ? props.bgColor === "#DD2447"
        ? "rgb(221, 36, 71, 0.1)"
        : "rgb(0,145,234, 0.1)"
      : "rgb(0,145,234, 0.1)",
    borderRadius: 10,
    margin: 0,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: props ? props.bgColor : "#0091EA",
    borderRadius: "inherit",
    zIndex: 2,
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
