import React from "react";
import AccountQualification from "./AccountQualification/AccountQualification.js";
import AccountContactInfo from "./AccountContactInfo/AccountContactInfo.js";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions.js";

function IndividualAccountDetailsBasicInfo({
  joint,
  jointInfo,
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  email,
  setEmail,
  emailError,
  setEmailError,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  domicileCountry,
  setDomicileCountry,
  domicileCountryError,
  setDomicileCountryError,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-4">
            <span className="individual-account-section-header-text">
              {!joint ? <>Basic Information</> : <>Basic Information - Primary Accountholder</> }
            </span>
          </div>
          <AccountQualification
            accountNumber={accountNumber}
            dateAdded={dateAdded}
            qualificationLevel={qualificationLevel}
            verificationStatus={verificationStatus}
          />
          <AccountContactInfo
            firstName={firstName}
            setFirstName={setFirstName}
            firstNameError={firstNameError}
            setFirstNameError={setFirstNameError}
            lastName={lastName}
            setLastName={setLastName}
            lastNameError={lastNameError}
            setLastNameError={setLastNameError}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            domicileCountry={domicileCountry}
            setDomicileCountry={setDomicileCountry}
            domicileCountryError={domicileCountryError}
            setDomicileCountryError={setDomicileCountryError}
          />
          <div className="row g-0 w-100 mb-4">
            <span className="individual-account-section-header-text">
              {joint && <>Basic Information - Secondary Accountholder</> }
            </span>
            </div>
          {joint && (
            <AccountContactInfo
              joint={joint}
              firstName={jointInfo.jointFirstName}
              setFirstName={jointInfo.setJointFirstName}
              firstNameError={firstNameError}
              setFirstNameError={()=>{}}
              lastName={jointInfo.jointLastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
              setLastNameError={() => {}}
              phoneNumber={jointInfo.jointPhoneNumber}
              setPhoneNumber={() => {}}
              phoneNumberError={phoneNumberError}
              setPhoneNumberError={() => {}}
              email={jointInfo.jointEmail}
              setEmail={() => {}}
              emailError={emailError}
              setEmailError={() => {}}
              domicileCountry={jointInfo.jointCountry}
              setDomicileCountry={() => {}}
              domicileCountryError={domicileCountryError}
              setDomicileCountryError={() => {}}
            />
          )}
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default IndividualAccountDetailsBasicInfo;
