import React, { useEffect } from "react";
import InfiniteTabInput from "../../../../../../../../Components/GlobalComponents/InfiniteTabInput/InfiniteTabInput";
import InfiniteInputComponent from "../../../../InfiniteInputComponent/InfiniteInputComponent";
import { countryList } from "../../../../../../../../Components/countryList";
import { stateList } from "../../../../../../../../Components/GlobalComponents/stateList";
import { getAccountSummary } from "./../../../../../../../../Api/ProfileApi.js";

function BeneficialOwnerInfiniteInput({
  accountType,
  shouldRenderBeneficialOwnerSection,
  showBeneficialOwnerSection,
  unmountedStyle,
  mountedStyle,
  activeTab,
  setActiveTab,
  addNewTab,
  accountID,
  relatedAccounts,
  setRelatedAccounts,
  handleRemoveFields,
  setActiveSlide,
  hideButton,
  setHideButton,
  hideAlterButtons,
  setHideAlterButtons,
  supplyPercentOwned,
}) {
  useEffect(() => {
    async function popRelatedParties() {
      if (supplyPercentOwned === true) {
        const accountSummaryResp = await getAccountSummary(accountID);
        const accountSummaryData = accountSummaryResp.data[0];
        setRelatedAccounts(
          accountSummaryData.related_parties
            .filter((relatedParty) => relatedParty.role === "BO")
            .map((relatedParty) => ({
              id: relatedParty.id,
              firstName: relatedParty.first_name,
              lastName: relatedParty.last_name,
              email: relatedParty.email,
              phoneNumber: relatedParty.phone_number,
              addressLine1: relatedParty.addresses[0].address_line_1,
              addressLine2: relatedParty.addresses[0].address_line_2,
              city: relatedParty.addresses[0].city,
              country: relatedParty.addresses[0].country,
              state: relatedParty.addresses[0].state,
              postalCode: relatedParty.addresses[0].postal_code,
              dateOfBirth: relatedParty.pii[0].date_of_birth,
              idNum: relatedParty.pii[0].id_number,
              domicileCountry: relatedParty.domicile_country,
              idType: relatedParty.pii[0].id_type,
              citizenshipError: "",
            }))
        );
      }
    }
    popRelatedParties();
  }, [supplyPercentOwned]);
  return (
    shouldRenderBeneficialOwnerSection && (
      <div
        className={"row g-0 w-100 fade-in-test"}
        style={showBeneficialOwnerSection ? mountedStyle : unmountedStyle}
      >
        <InfiniteTabInput
          tabs={relatedAccounts.map(
            (elem, idx) => "Beneficial Owner #" + (idx + 1).toString()
          )}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addNewTab={addNewTab}
        />
        <InfiniteInputComponent
          accountType={accountType}
          accountID={accountID}
          activeTab={activeTab}
          processedCountryList={countryList}
          processedStateList={stateList}
          inputFields={relatedAccounts}
          setInputFields={setRelatedAccounts}
          handleRemoveFields={handleRemoveFields}
          index={activeTab.match(/\d+/) - 1}
          setActiveTab={setActiveTab}
          setActiveSlide={setActiveSlide}
          role={"Beneficial Owner"}
          hideButton={hideButton}
          setHideButton={setHideButton}
          hideAlterButtons={hideAlterButtons}
          setHideAlterButtons={setHideAlterButtons}
          disabled={supplyPercentOwned ? true : false}
          supplyPercentOwned={supplyPercentOwned}
          relatedPartyType="BO"
        />
      </div>
    )
  );
}
export default BeneficialOwnerInfiniteInput;
