import React from "react";
import "./MobileDocumentCardStyles/MobileDocumentCardStyles.css";

function MobileDocumentCard({
  documentName,
  documentType,
  documentDate,
  documentLink,
  accountName
}) {
  return (
    <div
      className="row g-0 w-100 document-section-container mb-3"
      onClick={() => window.open(documentLink, "_blank").focus()}
    >
      <div className="col-12">
        <div className="row g-0 w-100 mb-2 mt-2">
          <div className="col-12">
            <div className="row g-0 w-100 mb-2">
              <div className="col-sm-2 col-3 ps-3 pe-1">
                <span className="document-section-header me-2 justify-self-start">
                  Name:
                </span>
              </div>
              <div className="col">
                <span className="document-section-value justify-self-start">
                  {documentName}
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-2">
              <div className="col-sm-2 col-3 ps-3 pe-1">
                <span className="document-section-header me-2 justify-self-start">
                  Type:
                </span>
              </div>
              <div className="col">
                <span className="document-section-value justify-self-start">
                  {documentType}
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-2">
              <div className="col-sm-2 col-3 ps-3 pe-1">
                <span className="document-section-header me-2 justify-self-start">
                  Account:
                </span>
              </div>
              <div className="col">
                <span className="document-section-value justify-self-start">
                  {accountName}
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-2">
              <div className="col-sm-2 col-3 ps-3 pe-1">
                <span className="document-section-header me-2 justify-self-start">
                  Date:
                </span>
              </div>
              <div className="col">
                <span className="document-section-value justify-self-start">
                  {documentDate}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MobileDocumentCard;
