import React from "react";
import Header from "../../Components/GlobalComponents/Header.js";
import "./PageNotFoundStyles/PageNotFoundStyles.css";
import { useHistory } from "react-router-dom";

function PageNotFound() {
  let history = useHistory();

  return (
    <React.Fragment>
      <Header activePage={""} />
      <div className="container-xxl pt-5 mt-5">
        <div className="row g-0 w-100">
          <div className="container w-50 justify-self-center text-center justify-content-center align-items-center">
            <div className="row g-0 w-100 mt-5 pt-5 text-center">
              <span className="page-404-text">404</span>
              <span className="content-not-found-text">
                Sorry, the page you are looking for can't be found. Please check
                the link you entered.
              </span>
              <div className="row g-0 w-100">
                {/* <div className="d-flex"> */}
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-lg btn-class mt-3"
                    onClick={() => history.push("/invest")}
                  >
                    Go Home
                  </button>
                  {/* </div> */}
                  {/* <div className="d-flex"> */}
                  <button
                    type="button"
                    className="btn btn-lg page-404-button mt-3 ms-3"
                  >
                    {" "}
                    Contact us
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default PageNotFound;
