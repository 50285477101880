import axios from "axios";
import { HOST } from "../host-config.js";

const baseURL = "https://api.investwithaqua.com";

const djangoApi = axios.create({
  baseUrl: HOST,
});

//Dev environment endpoint
export const signupUser = async (payload) => {
  let response = await axios.post(HOST + "/signup/", payload);
  // await axios.post(HOST + "/signup/", payload);
};

//Dev environment endpoint
export const getInvestorConfidentialityAgreement = async () => {
  // console.log("Host is: " + HOST);
  return axios.get(HOST + "/investor-confidentiality-agreement/");
};

export const getInvestorTermsOfAgreement = async () => {
  return axios.get(HOST + "/investor-terms-of-agreement/");
};

//Dev environment endpoint
export const signUpUser = async (payload) => {
  try {
    djangoApi.post("/signup/", payload);
  } catch (error) {
    if (process.env.DEBUG) console.error("Error is: " + error);
  }
};

//Dev environment endpoint
export const activateUser = async (payload) => {
  let response = await axios.post(
    HOST + "/investor-profile/account-status/",
    payload
  );
};
