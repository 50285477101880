import React, { useState } from "react";
import VerticalTabTitle from "./VerticalTabTitle.js";
import "./VerticalTabsStyles/VerticalTabsStyles.css";
function LargeVerticalTabs(props) {
  const [selectedTab, setSelectedTab] = useState(props.initial);

  return (
    <div className="col-md-12 col-sm-11 col-12 contain-content">
      <div className="row g-0 h-100 w-100">
        <div className="col-12 h-100">
          <div className="row g-0 h-100 w-100">
            <div className="col-lg-2 col-sm-3 col-4 right-border">
              {props.children.map((item, index) =>
                index === 0 ? (
                  <div className="row g-0 mt-3 w-100 mb-2">
                    <VerticalTabTitle
                      key={index}
                      index={index}
                      setSelectedTab={setSelectedTab}
                      title={item.props.title}
                      selected={item.props.selected}
                      setSelected={item.props.setSelected}
                    />
                  </div>
                ) : (
                  <div className="row g-0 w-100 mb-2">
                    <VerticalTabTitle
                      key={index}
                      index={index}
                      setSelectedTab={setSelectedTab}
                      title={item.props.title}
                      selected={item.props.selected}
                      setSelected={item.props.setSelected}
                    />
                  </div>
                )
              )}
            </div>
            <div className="col-lg-10 col-sm-9 col-8 justify-self-end">
              <div className="row g-0 w-100 mt-2">
                {props.children[selectedTab]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LargeVerticalTabs;
