import axios from "axios";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import { HOST } from "../../host-config.js";
// const backendApi = axios.create({
//   baseUrl: HOST,
// });

//Dev environment endpoint
export const getFundSubscriptionStats = async (fundLink, accountID) => {
  const link = fundLink.toString();
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      account_id: accountID,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(
    HOST + "/fund/" + link + "/subscription-info/",
    requestParams
  );
};

//Dev environment endpoint
export const createInvestment = async (
  fundId,
  investmentAmount,
  accountId,
  iraPartnerName = null
) => {
  const config = await getRequestHeaders();

  const params = {
    ...(iraPartnerName && { ira_partner_name: iraPartnerName }),
    ...{ fund_id: fundId, amount: investmentAmount, account_id: accountId },
  };

  if (process.env.REACT_APP_DEBUG === "1") {
    console.log(
      "Create investment request made with parameters: " +
        JSON.stringify(params)
    );
  }
  return axios.post(
    HOST + "/create-update-investor-fund-investment/",
    params,
    config
  );
};

//Dev environment endpoint
export const getInvestorFundInvestment = async (fundId, accountId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      fund_id: fundId,
      account_id: accountId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/investor-fund-investment/", requestParams);
};

export const getAccountQualificationVerification = async (accountId) => {
  const config = await getRequestHeaders();
  const requestParams = {
    params: {
      account_id: accountId,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(
    HOST + "/investor-profile-account-qualification-verification/",
    requestParams
  );
};

export const pollKYC = async (accountID, requestCount) => {
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      account_id: accountID,
      request_num: requestCount,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return axios.get(HOST + "/poll-kyc", requestParams);
};

export const getInvestorWireInstructions = async (fundId) => {
  const config = await getRequestHeaders();

  const requestParams = {
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };

  return axios.get(
    HOST + "/get-wire-instructions/" + fundId + "/",
    requestParams
  );
};
