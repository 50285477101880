import { atom } from "recoil";

export const userAuthenticatedState = atom({
  key: "userAuthenticatedState",
  default: false,
});

export const accessCodeVal = atom({
  key: "accessCodeVal",
  default: "",
});

export const termsAgreedVal = atom({
  key: "termsAgreed",
  default: false,
});

export const termsAgreementIdVal = atom({
  key: "termsAgreementId",
  default: "",
});

export const confidentialityAgreedVal = atom({
  key: "confidentialityAgreedVal",
  default: false,
});

export const confidentialityAgreedIdVal = atom({
  key: "confidentialityAgreementIdVal",
  default: "",
});

export const nameVal = atom({
  key: "nameVal",
  default: "",
});

export const accountTypeIndividualVal = atom({
  key: "accountTypeIndividualVal",
  default: false,
});

export const accountTypeTrustVal = atom({
  key: "accountTypeTrustVal",
  default: false,
});

export const accountTypeEntityVal = atom({
  key: "accountTypeEntityVal",
  default: false,
});

export const accountTypeJointVal = atom({
  key: "accountTypeJointVal",
  default: false,
});

export const accountTypeSMLLCVal = atom({
  key: "accountTypeSMLLCVal",
  default: false,
});

export const indQpQ1Yes = atom({
  key: "indQpQ1Yes",
  default: false,
});

export const indQpQ1No = atom({
  key: "indQpQ1No",
  default: false,
});

export const qualificationDateVal = atom({
  key: "qualificationDateVal",
  default: "",
});

export const jointQpQ1Yes = atom({
  key: "jointQpQ1Yes",
  default: false,
});

export const jointQpQ1No = atom({
  key: "jointQpQ1No",
  default: false,
});

export const smLLCQpQ1Yes = atom({
  key: "smLLCQpQ1Yes",
  default: false,
});

export const smLLCQpQ1No = atom({
  key: "smLLCQpQ1No",
  default: false,
});

export const trustQpQ1 = atom({
  key: "trustQpQ1",
  default: "",
});

export const trustQpQ2 = atom({
  key: "trustQpQ2",
  default: "",
});

export const trustQpQ3 = atom({
  key: "trustQpQ3",
  default: "",
});

export const trustQpQ4 = atom({
  key: "trustQpQ4",
  default: "",
});

export const entityQpQ1 = atom({
  key: "entityQpQ1",
  default: "",
});

export const entityQpQ2 = atom({
  key: "entityQpQ2",
  default: "",
});

export const entityQpQ3 = atom({
  key: "entityQpQ3",
  default: "",
});

export const entityQpQ4 = atom({
  key: "entityQpQ4",
  default: "",
});

export const entityQpQ5 = atom({
  key: "entityQpQ5",
  default: "",
});

export const emailVal = atom({
  key: "emailVal",
  default: "",
});

export const userIDVal = atom({
  key: "userIDVal",
  default: "",
});

export const indNetWorth1M = atom({
  key: "indNetWorth1M",
  default: "",
});

export const indIncomeAccredited = atom({
  key: "indIncomeAccredited",
  default: "",
});

export const indInvestmentProfessional = atom({
  key: "indInvestmentProfessional",
  default: "",
});

export const indAssets5M = atom({
  key: "indAssets5M",
  default: "",
});

export const jointNetWorth1M = atom({
  key: "jointNetWorth1M",
  default: "",
});

export const jointIncomeAccredited = atom({
  key: "jointIncomeAccredited",
  default: "",
});

export const jointInvestmentProfessional = atom({
  key: "jointInvestmentProfessional",
  default: "",
});

export const jointAssets5M = atom({
  key: "jointAssets5M",
  default: "",
});

export const smLLCAssets5M = atom({
  key: "smLLCAssets5M",
  default: "",
});

export const smLLCOwnerAccredited = atom({
  key: "smLLCOwnerAccredited",
  default: "",
});

export const trustAssets5M = atom({
  key: "trustAssets5M",
  default: "",
});

export const trustSophisticatedInvestor = atom({
  key: "trustSophisticatedInvestor",
  default: "",
});

export const trustFormationNotSpecificInvestment = atom({
  key: "trustFormationNotSpecificInvestment",
  default: "",
});

export const trustGrantorsAccredited = atom({
  key: "trustGrantorsAccredited",
  default: "",
});

export const trustOwnedByFamily = atom({
  key: "trustOwnedByFamily",
  default: "",
});

export const trustGrantorQP = atom({
  key: "trustGrantorQP",
  default: "",
});

export const entityAssets5M = atom({
  key: "entityAssets5M",
  default: "",
});

export const entityNotFormedForInvestment = atom({
  key: "entityNotFormedForInvestment",
  default: "",
});

export const entityOwnersAccredited = atom({
  key: "entityOwnersAccredited",
  default: "",
});

export const entityOwnedByFamily = atom({
  key: "entityOwnedByFamily",
  default: "",
});

export const entityAssets25M = atom({
  key: "entityAssets25M",
  default: "",
});

export const entityOwnersQP = atom({
  key: "entityOwnersQP",
  default: "",
});

export const accreditedInvestorsAllowed = atom({
  key: "accreditedInvestorsAllowed",
  default: false,
});
