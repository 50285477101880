import React from "react";
import AccordionItem from "./AccordionItem";
// import "../../../Styles/Variables/GlobalStyles.scss"
import "./AccordionStyles/AccordionStyles.scss";

const Accordion = ({ items, accordionid }) => {
  // console.log('accordion: ', accordionid)
  let accordionItems = (
    <>
      {items.map((item) => (
        // <>
        //     <div className="accordion-item border-0" key={item.id}>
        //     <h2 className="accordion-header default-subheader-text-600" id={item.id.slice(-8)}>
        //         <button className="accordion-button collapsed default-subheader-text-600 " type="button" data-bs-toggle="collapse" data-bs-target={`#c${item .id.slice(-8)}`} aria-expanded="false" aria-controls={`c${item .id.slice(-8)}`}>
        //             {item.question}
        //         </button>
        //     </h2>
        //     <div id={`c${item .id.slice(-8)}`} className="accordion-collapse collapse" aria-labelledby={item.id.slice(-8)} data-bs-parent={`#${title}`}>
        //         <div className="accordion-body default-section-text">
        //             {item.answer}
        //         </div>
        //     </div>
        // </div>
        // </>
        <AccordionItem item={item} itemid={accordionid} />
      ))}
    </>
  );
  return (
    <>
      <div
        className="accordion accordion-flush rounded"
        aria-expanded="false"
        id={accordionid}
      >
        {accordionItems}
      </div>
    </>
  );
};

export default Accordion;
