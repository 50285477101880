import React, { useState, useEffect } from "react";
import "./InfiniteIDInputFieldStyles/InfiniteIDInputFieldStyles.scss";
function InfiniteIDInputField({
  placeholder,
  name,
  error,
  index,
  inputFields,
  setInputFields,
  initialValue,
  disabled,
}) {
  const [inputPlaceholder, setInputPlaceholder] = useState("");
  const [focus, setFocus] = useState(false);
  //const [error, setError] = useState(false);
  const [divClass, setDivClass] = useState("input-box-container");
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (!error) {
      setDivClass(focus ? "input-box-containerFocus" : "input-box-container");
    } else {
      setDivClass("input-box-containerError");
    }
  }, [focus, error]);

  useEffect(() => {
    setInputPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    // if (fieldValue.length > 14) {
    // console.log(fieldValue.substring(0, 14));
    //   setFieldValue(fieldValue.substring(0, 14));
    // }
    const values = [...inputFields];
    values[index][name] = fieldValue;
    values[index][name + "Error"] = "";
    setInputFields(values);
    // fieldValueChange(fieldValue);
  }, [fieldValue]);
  return (
    <div
      className={divClass}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <div className="input-box">
        <input
          type={"number"}
          className={
            error === "This field is required."
              ? "internal-input-error-empty"
              : "internal-input"
          }
          placeholder={
            error === "This field is required." ? "Required" : inputPlaceholder
          }
          value={initialValue}
          disabled={disabled}
          onChange={(event) => {
            //if (isNumber(event.target.value)) {
            setFieldValue(event.target.value);
            //}
          }}
        ></input>
      </div>
    </div>
  );
}
export default InfiniteIDInputField;
