import React, { useEffect, useState } from "react";
import "../../Styles/Components/GlobalComponents/CheckBox/CheckBox.css";

function CheckBox({ selected }) {
  const [divClass, setDivClass] = useState("checkBox-border-unselected");
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setDivClass(
      selected ? "checkBox-border-selected" : "checkBox-border-unselected"
    );
    setChecked(selected);
  }, [selected]);

  return (
    <div className={divClass}>
      {checked && (
        <svg
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.808 0.584682L11.4149 0.19161C11.2914 0.068 11.1267 0 10.9509 0C10.7751 0 10.6103 0.068 10.4868 0.19161L4.96361 5.71473L1.51278 2.2638C1.38927 2.14039 1.22449 2.07229 1.04868 2.07229C0.872976 2.07229 0.708293 2.14039 0.584683 2.2638L0.191512 2.65678C0.0679024 2.78058 0 2.94546 0 3.12107C0 3.29678 0.0679024 3.46156 0.191512 3.58517L4.08771 7.48116C4.09288 7.48848 4.09834 7.49531 4.10468 7.50155L4.49785 7.88819C4.62137 8.01131 4.78615 8.07287 4.96332 8.07287H4.96537C5.14127 8.07287 5.30605 8.01131 5.42937 7.88819L5.82263 7.49814C5.82898 7.4919 5.83434 7.48672 5.83756 7.48146L11.8079 1.51171C12.064 1.25619 12.064 0.840487 11.808 0.584682Z"
            fill="#0D47A1"
          />
        </svg>
      )}
    </div>
  );
}
export default CheckBox;
