import React from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../../../../Components/countryList.js";

function AccountLegalStructure({
  trustType,
  setTrustType,
  trustTypeError,
  setTrustTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
}) {
  const trustTypeOptions = [
    { value: "LT", label: "Revocable Trust" },
    { value: "TRUST", label: "Irrevocable Trust" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectField
            value={trustType}
            setValue={setTrustType}
            valueError={trustTypeError}
            setValueError={setTrustTypeError}
            label={"Trust Type"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            selectOptions={trustTypeOptions}
            id={"trust-type-input"}
            shrink={true}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={accountName}
            setValue={setAccountName}
            valueError={accountNameError}
            setValueError={setAccountNameError}
            label={"Trust Name"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-name-input"}
            shrink={true}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-0">
          <SelectField
            value={countryOfFormation}
            setValue={setCountryOfFormation}
            valueError={countryOfFormationError}
            setValueError={setCountryOfFormationError}
            label={"Country Of Formation"}
            required={false}
            fullWidth={true}
            disabled={true}
            size={"small"}
            id={"trust-country-of-formation-input"}
            shrink={true}
            selectOptions={countryList}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
export default AccountLegalStructure;
