import React, { useState, useEffect, useContext } from "react";
import Header from "../../../Components/GlobalComponents/Header.js";
import "./TransactionsPageStyles/TransactionsPageStyles.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DisbursementDepositFilter from "../../../Components/GlobalComponents/DisbursementDepostFilter/DisbursementDepositFilter.js";
import StatusByDropdown from "../../../Components/GlobalComponents/StatusByDropdown/StatusByDropdown.js";
import FromFilter from "../../../Components/GlobalComponents/DateFilters/FromFilter.js";
import ToFilter from "../../../Components/GlobalComponents/DateFilters/ToFilter.js";
import { getUserTransactions } from "../../../Api/TransactionsApi.js";
import * as dayjs from "dayjs";
import Media from "react-media";
import MobileTransactionSection from "./MobileTransactionSection/MobileTransactionSection.js";
import { UserContext } from "../../../GlobalContexts/UserContext.js";
// var utc = require("dayjs/plugin/utc");

function TransactionsPage() {
  const [transactions, setTransactions] = useState([]);
  const [text, setText] = useState("All");
  const [statusText, setStatusText] = useState("All");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { user, setUser } = useContext(UserContext);

  function amountFormatter(cell, row) {
    if (cell !== undefined && cell !== null) {
      if (row.transactionType === "Deposit") {
        let integer = parseInt(cell.toString(), 10);
        return "- $" + integer.toLocaleString("en-US");
      } else if (row.transactionType === "Disbursement") {
        let integer = parseInt(cell.toString(), 10);
        return "+ $" + integer.toLocaleString("en-US");
      }
    }
  }

  useEffect(() => {
    if (fromDate !== null) {
      var currentDate = new Date();
      var constructedFromDate = new Date(
        fromDate.year,
        fromDate.month - 1,
        fromDate.day
      );
    }
    if (toDate !== null) {
      var secondCurrentDate = new Date();
      var constructedToDate = new Date(
        toDate.year,
        toDate.month - 1,
        toDate.day
      );
    }
  }, [fromDate, toDate]);

  const newColumns = [
    {
      dataField: "fundName",
      text: "Fund Name",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "firmName",
      text: "Firm Name",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "transactionType",
      text: "Type",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "transactionStatus",
      text: "Status",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "transactionMethod",
      text: "Method",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "transactionAquaAccount",
      text: "Method",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "transactionAmount",
      text: "Amount",
      formatter: amountFormatter,
      //classes: "table-data-style",
      classes: (cell, row, rowIndex, colIndex) => {
        if (row.transactionType === "Deposit") {
          return "transaction-font-style-deposit";
        } else {
          return "transaction-font-style-disbursement";
        }
      },
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "transactionDate",
      text: "Date",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
  ];

  useEffect(() => {
    async function getTransactions() {
      try {
        const response = await getUserTransactions(
          fromDate,
          toDate,
          statusText,
          text,
          user ? user.selectedAccountID : null
        );
        var transactionsArray = response.data;

        var mappedTransactions = transactionsArray.map((item) => {
          let transactionTypes = {
            DEPOSIT: "Deposit",
            DISBURSEMENT: "Disbursement",
          };

          let transactionStatuses = {
            PENDING: "Pending",
            IN_PROCESS: "Processing",
            COMPLETED: "Completed",
            FAILED: "Failed",
          };

          let transactionMethods = {
            ACH: "ACH",
            WIRE: "Wire",
            ELECTRONIC_CHEQUE: "Electronic Check",
            MAIL_CHEQUE: "Mail Check",
          };

          let dateNoTime = dayjs(dayjs(item.date).format("MMM D, YYYY"));

          let unixDate = dayjs(dateNoTime).unix();

          let formattedDate = dayjs.unix(unixDate).format("MMM, YYYY");

          return {
            id: item.id,
            fundName: item.fund.name,
            fundLink: item.fund.link,
            firmName: item.fund.firm.name,
            logoLink: item.fund.firm.logo_link,
            transactionType: transactionTypes[item.type],
            transactionStatus: transactionStatuses[item.status],
            transactionDate: formattedDate,
            transactionMethod: transactionMethods[item.method],
            transactionDateUnix: unixDate,
            transactionAmount: item.amount,
            transactionAccount: item.bank_account,
            transactionInstitution: item.institution,
            transactionAquaAccount: item.account_name
          };
        });

        mappedTransactions.sort((a, b) => {
          if (a.transactionDateUnix > b.transactionDateUnix) {
            return -1;
          }
          if (a.transactionDateUnix < b.transactionDateUnix) {
            return 1;
          }
          return 0;
        });

        setTransactions(mappedTransactions);
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
    getTransactions();
  }, [fromDate, toDate, statusText, text]);

  return (
    <React.Fragment>
      <Header activePage={""} />
      <div className="mt-5 pt-5"></div>
      <div className="container-xxl contain">
        <div className="row g-0 mt-4 w-100 pe-0">
          <div className="col-12 pe-0">
            <div className="row g-0 w-100 mb-4 pe-0">
              <div className="col-12 pe-0">
                <span className="page-title">Transactions</span>
              </div>
            </div>
            <div className="row pe-0 w-100 mb-4 g-0">
              <div className="col-12 p-0">
                <div className="row gx-4 w-100">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md col-sm-6 col-12 mt-sm-0 mt-2 h-100 justify-self-end justify-content-end align-items-center pe-0">
                    <div className="h-100 w-100">
                      <DisbursementDepositFilter
                        text={text}
                        setText={setText}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md col-sm-6 col-12 mt-sm-0 mt-2 h-100 justify-self-end justify-content-end align-items-center pe-0">
                    <div className="h-100 w-100">
                      <StatusByDropdown
                        text={statusText}
                        setText={setStatusText}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md col-sm-6 mt-md-0 mt-2 d-flex justify-self-end justify-content-end pe-0">
                    <div className="row g-0 w-100">
                      <FromFilter
                        selectedDay={fromDate}
                        setSelectedDay={setFromDate}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md col-sm-6 mt-md-0 mt-2 d-flex justify-self-end justify-content-end pe-0">
                    <div className="row g-0 w-100">
                      <ToFilter
                        selectedDay={toDate}
                        setSelectedDay={setToDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Media
              queries={{
                medium: { maxWidth: 768 },
                large: { minWidth: 992 },
              }}
            >
              {(matches) =>
                !matches.large ? (
                  <MobileTransactionSection transactions={transactions} />
                ) : (
                  <div className="row g-0 w-100 pe-2 mb-3">
                    <div className="table-style pt-3 pe-2 d-flex justify-content-center">
                      <div className="w-98">
                        <BootstrapTable
                          keyField="id"
                          data={transactions}
                          columns={newColumns}
                          bordered={false}
                          rowClasses={"table-row-style"}
                        />
                        {transactions.length === 0 && (
                          <div className=" w-100 pt-5 pb-5 text-center">
                            <span className="no-transactions-text">
                              You don't have any transactions to show.
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default TransactionsPage;
