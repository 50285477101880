import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function StyledDateInputField({
  value,
  setValue,
  valueError,
  setValueError,
  label,
  required,
  fullWidth,
  size,
  id,
  disabled,
}) {
  useEffect(() => {
    setValueError("");
  }, [value]);

  const formatDate = (value) => {
    if (!value) return null; 
    const date = value.split("-");
    return [date[1], date[2], date[0]].join("-"); 
  }
  
  const formatDateInverse = (value) => value ? formatDate(formatDate(value)) : null;

  return (
    <Box sx={{ minWidth: 120 }}>
      <TextField
        error={valueError !== ""}
        id={id}
        label={label}
        type={"date"}
        helperText={valueError}
        value={formatDateInverse(value)}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => setValue( formatDate(event.target.value) )}
        onBlur={() => {
          if (required && value === "") {
            setValueError("This field is required.");
          }
        }}
        size={size}
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: "Montserrat",
            color: "#262A3D",
            fontSize: "14px",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#0D47A1",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: "#0D47A1",
              color: "#0D47A1",
            },
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "#DADDE1",
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            "& > fieldset": {
              borderColor: "#DADDE1",
            },
          },
          "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
            "& > fieldset": {
              borderColor: valueError === "" ? "#DADDE1" : "#D32F2F",
            },
          },
        }}
        required={required}
        fullWidth={fullWidth}
      />
    </Box>
  );
}
export default StyledDateInputField;
