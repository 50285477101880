import { useState, useEffect, useContext } from "react";
import { getFundSubscriptionStats } from "../Api/ConfirmInvestmentApi/ConfirmInvestmentApi.js";
import { UserContext } from "../GlobalContexts/UserContext.js";

export default function GetFundSubscriptionInfo(fundLink) {
  const [fundSubscriptionInfo, setFundSubscriptionInfo] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function getFundSubscriptionInfo() {
      try {
        let accountID = "";

        if (user && user.selectedAccountID !== "") {
          accountID = user.selectedAccountID;
        }

        const response = await getFundSubscriptionStats(fundLink, accountID);
        const fundSubscriptionData = response.data;

        setFundSubscriptionInfo({
          id: fundSubscriptionData.id,
          name: fundSubscriptionData.name,
          link: fundSubscriptionData.link,
          minimumInvestment:
            fundSubscriptionData.fund_terms[0].minimum_allocation_amount,
          maximumInvestment:
            fundSubscriptionData.fund_terms[0].maximum_allocation_amount,
          remainingAllocation:
            fundSubscriptionData.subscription_stats[0]
              .remaining_allocation_amount,
          status: fundSubscriptionData.investment_status,
          allowIraInvestments: fundSubscriptionData.allow_ira_investments,
          legacyPricingStructure:
            fundSubscriptionData.fund_terms[0].legacy_pricing_structure,
        });
      } catch (err) {
        setFundSubscriptionInfo({ error: err });
      }
    }
    getFundSubscriptionInfo();
  }, [fundLink, setFundSubscriptionInfo, user]);

  return { fundSubscriptionInfo };
}
