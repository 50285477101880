import React from "react";
import { useHistory } from "react-router";

function IndividualAccountDetailsHeader({joint}) {
  let history = useHistory();
  return (
    <React.Fragment>
      <div
        className="individual-account-details-back-section"
        onClick={() => history.push("/investment-accounts")}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/gray-back.svg"
            alt="gray-back-arrow"
            style={{
              marginRight: "8px",
              colorAdjust: "#979797",
            }}
          />
          <span className="individual-account-details-back-section-text">
            Back
          </span>
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <span
          className="individual-account-name-text"
          style={{ marginBottom: "8px" }}
        >
          Account Details
        </span>
        <span className="individual-account-type-text">{!joint ? <>Individual</> : <>Joint</>}</span>
      </div>
    </React.Fragment>
  );
}
export default IndividualAccountDetailsHeader;
