import React from "react";
import { Menu, Dropdown, Button } from "antd";
import "./StrategyFilterStyles/StrategyFilterStyles.css";
import "antd/dist/antd.css";

function StrategyFilter({ text, setText, defaultKey }) {
  const menu = (
    <Menu
      style={{ width: "100%", paddingTop: 0, paddingBottom: 0 }}
      selectable
      onClick={(e) => setText(e.key)}
      defaultSelectedKeys={defaultKey}
    >
      <Menu.Item key={"Private Equity"} className="h-100">
        <span className="dropdown-text-style">Private Equity</span>
      </Menu.Item>
      {/* <Menu.Item key={"Direct Landing"} className="h-100">
        <span className="dropdown-text-style">Direct Landing</span>
      </Menu.Item> */}
      {/* <Menu.Item key={"CLO Management"} className="h-100">
        <span className="dropdown-text-style">CLO Management</span>
      </Menu.Item> */}
      <Menu.Item key={"Structured Credit"} className="h-100">
        <span className="dropdown-text-style">Private Credit</span>
      </Menu.Item>
      <Menu.Item key={"Co-Investment"} className="h-100">
        <span className="dropdown-text-style">Co-Investment</span>
      </Menu.Item>
      <Menu.Item key={"Fund of Funds"} className="h-100">
        <span className="dropdown-text-style">Fund of Funds</span>
      </Menu.Item>
      {/* Complete this with rest of the strategies */}
      <Menu.Item key={"Buyout"} className="h-100">
        <span className="dropdown-text-style">Buyout</span> 
      </Menu.Item>
      <Menu.Item key={"Growth"} className="h-100">
        <span className="dropdown-text-style">Growth</span>
      </Menu.Item>
      <Menu.Item key="Credit" className="h-100">
        <span className="dropdown-text-style">Credit</span>
      </Menu.Item>
      <Menu.Item key={"All"} className="h-100">
        <span className="dropdown-text-style">All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <Button
        style={{
          backgroundColor: "#ffffff !important",
          paddingLeft: 0,
          paddingRight: 0,
        }}
        className="d-flex ant-btn-style"
      >
        <div className="row g-0 h-100 w-100 align-items-center">
          <div className="col-10 d-flex h-100 align-items-center justify-content-center right-border-style text-break">
            <span className="align-text-center justify-text-center ms-2 my-custom-input-text ms-1 me-1 text-wrap">
              {"Strategy: "}
            </span>
          </div>
          <div className="col-2 h-100 d-flex justify-self-end align-items-center justify-content-center">
            <img
              src="https://d1swyfveypj49y.cloudfront.net/PortfolioArrow.svg"
              alt="portfolio-arrow"
            ></img>
          </div>
        </div>
      </Button>
    </Dropdown>
  );
}
export default StrategyFilter;
