import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./IndividualAccountDetailsStyles/IndividualAccountDetailsStyles.scss";
import TabV2 from "../../../../../Components/GlobalComponents/TabsV2/TabV2";
import TabsV2 from "../../../../../Components/GlobalComponents/TabsV2/TabsV2";
import StyledTextInputField from "../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import SelectField from "../../../../../GlobalComponents/InputField/SelectField/SelectField";
import { countryList } from "../../../../../Components/countryList";
import IndividualAccountDetailsHeader from "./IndividualAccountDetailsHeader/IndividualAccountDetailsHeader";
import IndividualAccountDetailsInfoSection from "./IndividualAccountDetailsInfoSection/IndividualAccountDetailsInfoSection";

function IndividualAccountDetails({
  joint,
  accountNumber,
  setAccountNumber,
  dateAdded,
  setDateAdded,
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  email,
  setEmail,
  emailError,
  setEmailError,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  domicileCountry,
  setDomicileCountry,
  domicileCountryError,
  setDomicileCountryError,
  qualificationLevel,
  verificationStatus,
  dob,
  setDob,
  dobError,
  setDobError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
  jointInfo
}) {
  let history = useHistory();

  return (
    <React.Fragment>
      <IndividualAccountDetailsHeader joint={joint}/>
      <IndividualAccountDetailsInfoSection
        joint={joint}
        accountNumber={accountNumber}
        dateAdded={dateAdded}
        qualificationLevel={qualificationLevel}
        verificationStatus={verificationStatus}
        firstName={firstName}
        setFirstName={setFirstName}
        firstNameError={firstNameError}
        setFirstNameError={setFirstNameError}
        lastName={lastName}
        setLastName={setLastName}
        lastNameError={lastNameError}
        setLastNameError={setLastNameError}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        phoneNumberError={phoneNumberError}
        setPhoneNumberError={setPhoneNumberError}
        email={email}
        setEmail={setEmail}
        emailError={emailError}
        setEmailError={setEmailError}
        domicileCountry={domicileCountry}
        setDomicileCountry={setDomicileCountry}
        domicileCountryError={domicileCountryError}
        setDomicileCountryError={setDomicileCountryError}
        dob={dob}
        setDob={setDob}
        dobError={dobError}
        setDobError={setDobError}
        idNumber={idNumber}
        setIDNumber={setIDNumber}
        idNumberError={idNumberError}
        setIDNumberError={setIDNumberError}
        addressLine1={addressLine1}
        setAddressLine1={setAddressLine1}
        addressLine1Error={addressLine1Error}
        setAddressLine1Error={setAddressLine1Error}
        addressLine2={addressLine2}
        setAddressLine2={setAddressLine2}
        addressLine2Error={addressLine2Error}
        setAddressLine2Error={setAddressLine2Error}
        city={city}
        setCity={setCity}
        cityError={cityError}
        setCityError={setCityError}
        state={state}
        setState={setState}
        stateError={stateError}
        setStateError={setStateError}
        country={country}
        setCountry={setCountry}
        countryError={countryError}
        setCountryError={setCountryError}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        postalCodeError={postalCodeError}
        setPostalCodeError={setPostalCodeError}
        jointInfo={jointInfo}
      />
    </React.Fragment>
  );
}
export default IndividualAccountDetails;
