import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { QualifiedPurchaserModal } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { QualifiedPurchaserLink } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { useRecoilState } from "recoil";
import {
  qualificationDateVal,
  entityQpQ1,
  entityQpQ2,
  entityQpQ3,
  entityQpQ4,
  entityQpQ5,
} from "../../../../../../../../atoms";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";
import AlertSnackBar from "../../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import MuiButton from "../../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function EntityQualification({ setActiveSlide }) {
  let history = useHistory();
  const [entityQualificationQ1, setEntityQualificationQ1] =
    useRecoilState(entityQpQ1);
  const [entityQualificationQ2, setEntityQualificationQ2] =
    useRecoilState(entityQpQ2);
  const [entityQualificationQ3, setEntityQualificationQ3] =
    useRecoilState(entityQpQ3);
  const [entityQualificationQ4, setEntityQualificationQ4] =
    useRecoilState(entityQpQ4);
  const [entityQualificationQ5, setEntityQualificationQ5] =
    useRecoilState(entityQpQ5);
  const [qualificationDate, setQualificationDate] =
    useRecoilState(qualificationDateVal);
  const [showModal, setShowModal] = useState(false);
  const [entityQualificationError, setEntityQualificationError] = useState("");
  const [q1Yes, setQ1Yes] = useState(
    entityQualificationQ1
      ? entityQualificationQ1 === "Yes"
        ? true
        : false
      : false
  );
  const [q1Empty, setQ1Empty] = useState(entityQualificationQ1 ? false : true);
  const [q1No, setQ1No] = useState(
    entityQualificationQ1
      ? entityQualificationQ1 === "Yes"
        ? false
        : true
      : false
  );
  const [q2Yes, setQ2Yes] = useState(
    entityQualificationQ2
      ? entityQualificationQ2 === "Yes"
        ? true
        : false
      : false
  );
  const [q2Empty, setQ2Empty] = useState(entityQualificationQ2 ? false : true);
  const [q2No, setQ2No] = useState(
    entityQualificationQ2
      ? entityQualificationQ2 === "Yes"
        ? false
        : true
      : false
  );
  const [q3Yes, setQ3Yes] = useState(
    entityQualificationQ3
      ? entityQualificationQ3 === "Yes"
        ? true
        : false
      : false
  );
  const [q3Empty, setQ3Empty] = useState(entityQualificationQ3 ? false : true);
  const [q3No, setQ3No] = useState(
    entityQualificationQ3
      ? entityQualificationQ3 === "Yes"
        ? false
        : true
      : false
  );
  const [q4Yes, setQ4Yes] = useState(
    entityQualificationQ4
      ? entityQualificationQ4 === "Yes"
        ? true
        : false
      : false
  );
  const [q4Empty, setQ4Empty] = useState(entityQualificationQ4 ? false : true);
  const [q4No, setQ4No] = useState(
    entityQualificationQ4
      ? entityQualificationQ4 === "Yes"
        ? false
        : true
      : false
  );

  const [q5Yes, setQ5Yes] = useState(
    entityQualificationQ5
      ? entityQualificationQ5 === "Yes"
        ? true
        : false
      : false
  );
  const [q5Empty, setQ5Empty] = useState(entityQualificationQ5 ? false : true);
  const [q5No, setQ5No] = useState(
    entityQualificationQ5
      ? entityQualificationQ5 === "Yes"
        ? false
        : true
      : false
  );

  const [notAllQuestionsAnsweredError, setNotAllQuestionsAnsweredError] =
    useState(false);
  const [notQPError, setNotQPError] = useState(false);

  useEffect(() => {
    setQ1Yes(
      entityQualificationQ1
        ? entityQualificationQ1 === "Yes"
          ? true
          : false
        : false
    );
    setQ1No(
      entityQualificationQ1
        ? entityQualificationQ1 === "Yes"
          ? false
          : true
        : false
    );
    setQ1Empty(entityQualificationQ1 ? false : true);
    setEntityQualificationError("");
  }, [entityQualificationQ1]);

  useEffect(() => {
    setQ2Yes(
      entityQualificationQ2
        ? entityQualificationQ2 === "Yes"
          ? true
          : false
        : false
    );
    setQ2No(
      entityQualificationQ2
        ? entityQualificationQ2 === "Yes"
          ? false
          : true
        : false
    );
    setQ2Empty(entityQualificationQ2 ? false : true);
    setEntityQualificationError("");
  }, [entityQualificationQ2]);

  useEffect(() => {
    setQ3Yes(
      entityQualificationQ3
        ? entityQualificationQ3 === "Yes"
          ? true
          : false
        : false
    );
    setQ3No(
      entityQualificationQ3
        ? entityQualificationQ3 === "Yes"
          ? false
          : true
        : false
    );
    setQ3Empty(entityQualificationQ3 ? false : true);
    setEntityQualificationError("");
  }, [entityQualificationQ3]);

  useEffect(() => {
    setQ4Yes(
      entityQualificationQ4
        ? entityQualificationQ4 === "Yes"
          ? true
          : false
        : false
    );
    setQ4No(
      entityQualificationQ4
        ? entityQualificationQ4 === "Yes"
          ? false
          : true
        : false
    );
    setQ4Empty(entityQualificationQ4 ? false : true);
    setEntityQualificationError("");
  }, [entityQualificationQ4]);

  useEffect(() => {
    setQ5Yes(
      entityQualificationQ5
        ? entityQualificationQ5 === "Yes"
          ? true
          : false
        : false
    );
    setQ5No(
      entityQualificationQ5
        ? entityQualificationQ5 === "Yes"
          ? false
          : true
        : false
    );
    setQ5Empty(entityQualificationQ5 ? false : true);
    setEntityQualificationError("");
  }, [entityQualificationQ5]);

  const validateEntityQPStatus = () => {
    if (
      entityQualificationQ1 !== "" &&
      entityQualificationQ2 !== "" &&
      entityQualificationQ3 !== "" &&
      entityQualificationQ4 !== "" &&
      entityQualificationQ5 !== ""
    ) {
      if (q1No) {
        if (q2Yes && q3Yes) {
          var utcDate = getDateUTC();
          setQualificationDate(utcDate.toISOString());
          //   navigation.next();
          setActiveSlide((curSlide) => curSlide + 1);
        } else if (q2Yes && q4Yes) {
          var utcDate = getDateUTC();
          setQualificationDate(utcDate.toISOString());
          //   navigation.next();
          setActiveSlide((curSlide) => curSlide + 1);
        } else if (q3No && q4No && q5Yes) {
          var utcDate = getDateUTC();
          setQualificationDate(utcDate.toISOString());
          //   navigation.next();
          setActiveSlide((curSlide) => curSlide + 1);
        } else if (q3Yes && q4No && q5Yes) {
          var utcDate = getDateUTC();
          setQualificationDate(utcDate.toISOString());
          //   navigation.next();
          setActiveSlide((curSlide) => curSlide + 1);
        } else {
          setEntityQualificationError(
            "Sorry, your entity doesn't qualify as a Qualified Purchaser. Unfortunately, our opportunities are only open to qualified purchasers at this time, but we'll have investment opportunities for you very soon."
          );
          setNotQPError(true);
        }
      } else {
        setEntityQualificationError(
          "Sorry, your entity doesn't qualify as a Qualified Purchaser. Unfortunately, our opportunities are only open to qualified purchasers at this time, but we'll have investment opportunities for you very soon."
        );
        setNotQPError(true);
      }
    } else {
      setEntityQualificationError("Please answer all qualification questions.");
      setNotAllQuestionsAnsweredError(true);
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal show={showModal} setShowModal={setShowModal} />
      <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-5">
        <div className="row g-0 w-100 h-100 align-items-center mt-4 justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4 mt-3">
              <div className="col-12">
                <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="accredited-investor-questionnaire-section-header-text">
                  Entity Qualification
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="accredited-investor-questionnaire-gray-text">
                  The Securities and Exchange Commision (SEC) requires all Aqua
                  users to be Qualified Purchasers.
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Was the entity formed for the specific purpose of investing in
                  a particular fund?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField"}
                        id={"myRadioId"}
                        className={"radio__input"}
                        checked={q1Yes}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ1("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId2"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField2"}
                        id={"myRadioId2"}
                        className={"radio__input"}
                        checked={q1No}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ1("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Does the entity have at least $5M in investments?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId3"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField3"}
                        id={"myRadioId3"}
                        className={"radio__input"}
                        checked={q2Yes}
                        readOnly={true}
                        onClick={() => {
                          //   setQ2Yes(true);
                          //   setQ2No(false);
                          //   setQ2Empty(false);
                          setEntityQualificationQ2("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId4"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField4"}
                        id={"myRadioId4"}
                        className={"radio__input"}
                        checked={q2No}
                        readOnly={true}
                        onClick={() => {
                          //   setQ2Yes(false);
                          //   setQ2No(true);
                          //   setQ2Empty(false);
                          setEntityQualificationQ2("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Is the entity owned directly or indirectly by 2 or more
                  natural persons who are related as siblings or spouse
                  (including former spouses), or direct lineal descendants by
                  birth or adoption, or spouses of such persons, the estates of
                  such persons, or foundations, charitable organizations, or
                  trusts established by or for the benefit of such persons?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId5"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField5"}
                        id={"myRadioId5"}
                        className={"radio__input"}
                        checked={q3Yes}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ3("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId6"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField6"}
                        id={"myRadioId6"}
                        className={"radio__input"}
                        checked={q3No}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ3("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Does the entity have more than $25M in investments that are
                  invested on its own account or on behalf of other individuals?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId7"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField7"}
                        id={"myRadioId7"}
                        className={"radio__input"}
                        checked={q4Yes}
                        readOnly={true}
                        onClick={() => {
                          //   setQ4Yes(true);
                          //   setQ4No(false);
                          //   setQ4Empty(false);
                          setEntityQualificationQ4("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId8"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField8"}
                        id={"myRadioId8"}
                        className={"radio__input"}
                        checked={q4No}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ4("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Are all beneficial owners of the entity qualified purchasers
                  (*Definition below)?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId9"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField9"}
                        id={"myRadioId9"}
                        className={"radio__input"}
                        checked={q5Yes}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ5("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId10"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField10"}
                        id={"myRadioId10"}
                        className={"radio__input"}
                        checked={q5No}
                        readOnly={true}
                        onClick={() => {
                          setEntityQualificationQ5("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mt-4 mb-5 pe-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Next step"}
                        onClick={() => validateEntityQPStatus()}
                        size={"medium"}
                      />
                    </div>
                  </div>
                  {/* <div className="col-4 ms-0 me-auto">
                          <div
                            onClick={() => validateEntityQPStatus()}
                            style={{ cursor: "pointer" }}
                          >
                            <StateBasedButton
                              proceed={true}
                              text={"Next Step"}
                            />
                          </div>
                        </div> */}
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mt-5 pe-5">
              <div className="col-11">
                <QualifiedPurchaserLink setShowModal={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertSnackBar
        snackbarState={notQPError}
        setSnackbarState={setNotQPError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Sorry, your account does not qualify as a QP, and we only allow QPs on our platform at this time."
        }
      />
      <AlertSnackBar
        snackbarState={notAllQuestionsAnsweredError}
        setSnackbarState={setNotAllQuestionsAnsweredError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must answer all questions."}
      />
    </React.Fragment>
  );
}
export default EntityQualification;
