import axios from "axios";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import { HOST } from "../../host-config.js";

// const backendApi = axios.create({
//   baseUrl: HOST,
// });

//Dev environment endpoint
export const getFund = async (fundLink, accountID) => {
  const link = fundLink.toString();
  const config = await getRequestHeaders();

  const requestParams = {
    params: {
      account_id: accountID,
    },
    headers: {
      Authorization: config.headers["Authorization"],
    },
  };
  return axios.get(HOST + "/fund/" + link + "/", requestParams);
};
