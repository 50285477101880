import React, { useState, useEffect } from "react";
import Header from "../../../Components/GlobalComponents/Header.js";
import SearchBar from "../../../Components/GlobalComponents/SearchBar.js";
import BlogPostPreviewSection from "./BlogPostCardSection/BlogPostCardSection.js";
import BlogPostAdapter from "../../../Adapters/BlogPostAdapter.js";
import "./LearnStyles/LearnStyles.css";

function Learn() {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { blogPosts } = BlogPostAdapter(searchQuery, setLoading);

  return (
    <React.Fragment>
      <Header activePage={"learn"} />
      <div className="container-xxl container-fluid pt-5 mt-5 ps-xxl-0 pe-xxl-0 ps-5 pe-5">
        <div className="row g-0 w-100">
          <div className="col-12">
            <div className="row g-0 w-100 mb-3 pe-4">
              <span className="page-header">Learn</span>
            </div>
            <div className="row g-0 w-100 mb-4 pe-4">
              <SearchBar
                placeholder={"Search blogs by keywords"}
                setValue={setSearchQuery}
              />
            </div>
            <BlogPostPreviewSection blogPosts={blogPosts} loading={loading} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Learn;
