import React, { useState, useEffect } from "react";
import TabsV2 from "../../../../../../Components/GlobalComponents/TabsV2/TabsV2";
import TabV2 from "../../../../../../Components/GlobalComponents/TabsV2/TabV2";
import IndividualAccountDetailsBasicInfo from "./IndividualAccountDetailsBasicInfo/IndividualAccountDetailsBasicInfo";
import IndividualAccountDetailsPersonalInfo from "./IndividualAccountDetailsPersonalInfo/IndividualAccountDetailsPersonalInfo";

function IndividualAccountDetailsInfoSection({
  joint,
  jointInfo,
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  email,
  setEmail,
  emailError,
  setEmailError,
  domicileCountry,
  setDomicileCountry,
  domicileCountryError,
  setDomicileCountryError,
  dob,
  setDob,
  dobError,
  setDobError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
}) {
  const [basicInformationSelected, setBasicInformationSelected] =
    useState(true);
  const [personalInformationSelected, setPersonalInformationSelected] =
    useState(false);

  useEffect(() => {
    if (basicInformationSelected) {
      setPersonalInformationSelected(false);
    }
  }, [basicInformationSelected]);

  useEffect(() => {
    if (personalInformationSelected) {
      setBasicInformationSelected(false);
    }
  }, [personalInformationSelected]);

  return (
    <React.Fragment>
      <div className="row g-0 w-100">
        <TabsV2 maxWidth="700px">
          <TabV2
            title="Basic Information"
            selected={basicInformationSelected}
            setSelected={setBasicInformationSelected}
          >
            <IndividualAccountDetailsBasicInfo
              accountNumber={accountNumber}
              dateAdded={dateAdded}
              qualificationLevel={qualificationLevel}
              verificationStatus={verificationStatus}
              firstName={firstName}
              setFirstName={setFirstName}
              firstNameError={firstNameError}
              setFirstNameError={setFirstNameError}
              lastName={lastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
              setLastNameError={setLastNameError}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              phoneNumberError={phoneNumberError}
              setPhoneNumberError={setPhoneNumberError}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              domicileCountry={domicileCountry}
              setDomicileCountry={setDomicileCountry}
              domicileCountryError={domicileCountryError}
              setDomicileCountryError={setDomicileCountryError}
              joint={joint}
              jointInfo={jointInfo}
            />
          </TabV2>
          <TabV2
            title="Personal Information"
            selected={personalInformationSelected}
            setSelected={setPersonalInformationSelected}
          >
            <IndividualAccountDetailsPersonalInfo
              domicileCountry={domicileCountry}
              dob={dob}
              setDob={setDob}
              dobError={dobError}
              setDobError={setDobError}
              idNumber={idNumber}
              setIDNumber={setIDNumber}
              idNumberError={idNumberError}
              setIDNumberError={setIDNumberError}
              addressLine1={addressLine1}
              setAddressLine1={setAddressLine1}
              addressLine1Error={addressLine1Error}
              setAddressLine1Error={setAddressLine1Error}
              addressLine2={addressLine2}
              setAddressLine2={setAddressLine2}
              addressLine2Error={addressLine2Error}
              setAddressLine2Error={setAddressLine2Error}
              city={city}
              setCity={setCity}
              cityError={cityError}
              setCityError={setCityError}
              state={state}
              setState={setState}
              stateError={stateError}
              setStateError={setStateError}
              country={country}
              setCountry={setCountry}
              countryError={countryError}
              setCountryError={setCountryError}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              postalCodeError={postalCodeError}
              setPostalCodeError={setPostalCodeError}
              joint={joint}
              jointInfo={jointInfo}
            />
          </TabV2>
        </TabsV2>
      </div>
    </React.Fragment>
  );
}
export default IndividualAccountDetailsInfoSection;
