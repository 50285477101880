import React, { useEffect } from "react";
import "./StructureSectionStyles/StructureSectionStyles.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function StructureSection({ fund }) {
  const PLACEMENT_COPY = `
  <strong>
   Administrative Fee
  </strong>
  <br />
  This is a 1%, one-time, fee that we charge on top of the amount that an investor invests. For example, if Investor A wants to invest $300K into Opportunity Z on Aqua, their administrative fee would be $3k. When wiring funds to us, the total that the investor would wire is the investment amount + administrative fee = $300K + $3k = $303K
`;
  const MANAGEMENT_COPY = `
  <strong>
   Annual Management Fee
  </strong>
  <br />
  This is a 0.5% annual fee that we collect for managing your capital in an opportunity. This fee is calculated based on your investment amount and is collected anually.
`;

  const CARRIED_INTEREST_COPY = `
  <strong>
   Carried Interest
  </strong>
  <br />
  This is a 1% fee on carry (i.e. profits from the investment). This fee is automatically deducted from distributions that an investor receives after the investor’s principal investment amount has been recouped.
`;

  const renderTooltip = (copy, props) => (
    <Tooltip id="button-tooltip" style={{ width: "600px" }} {...props}>
      <div dangerouslySetInnerHTML={{ __html: copy }} />
    </Tooltip>
  );

  useEffect(() => {
    console.log("Fund is: " + JSON.stringify(fund));
  }, [fund]);
  return (
    <div className="row g-0 ms-4 me-4 mt-4">
      <div className="col-12">
        <div className="row g-0 w-100 mb-4">
          <span className="structure-header-text">Structure</span>
        </div>
        <div className="row g-0 w-100 mb-3">
          <span className="investor-restrictions-text">
            Investor Restrictions
          </span>
        </div>
        <div className="row g-0 w-100 mb-4">
          <span className="investor-restrictions-value-text">
            {fund
              ? fund.registrationType
                ? fund.registrationType === "3C7"
                  ? "Qualified Purchasers Only"
                  : "Accredited Investors"
                : ""
              : ""}
          </span>
        </div>
        <div className="row g-0 w-100">
          <div className="col-10">
            <div className="row gx-4 w-100">
              <div className="col-lg col-sm-6 col-12">
                <div className="row g-0 w-100">
                  <div className="d-flex">
                    <span class="align-self-center investor-restrictions-text">
                      {fund && fund.legacyPricingStructure === true
                        ? "Administrative Fee "
                        : "Annual Management Fee "}
                    </span>
                    <div className="align-self-center d-flex align-items-center pt-auto pb-auto">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 400 }}
                        overlay={(props) => {
                          if (fund && fund.legacyPricingStructure === true) {
                            return renderTooltip(PLACEMENT_COPY, props);
                          } else {
                            return renderTooltip(MANAGEMENT_COPY, props);
                          }
                        }}
                      >
                        <img
                          src="https://d1swyfveypj49y.cloudfront.net/i.svg"
                          className="h-100"
                          style={{ paddingLeft: "5px" }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  <span className="align-self-center investor-restrictions-value-text mt-3">
                    {fund ? fund.placementFee + "%" : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg col-sm-6 col-12 mt-sm-0 mt-3">
                <div className="row g-0 w-100">
                  <div className="d-flex">
                    <span class="align-self-center investor-restrictions-text">
                      Carried Interest{" "}
                    </span>
                    <div className="align-self-center d-flex align-items-center pt-auto pb-auto">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 200 }}
                        overlay={(props) =>
                          renderTooltip(CARRIED_INTEREST_COPY, props)
                        }
                      >
                        <img
                          src="https://d1swyfveypj49y.cloudfront.net/i.svg"
                          className="h-100"
                          style={{ paddingLeft: "5px" }}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  <span className="align-self-center investor-restrictions-value-text mt-3">
                    {fund ? fund.distributionFee + "%" : ""}
                  </span>
                </div>
              </div>
              <div className="col-lg col-sm-6 col-12 mt-lg-0 mt-3">
                <div className="row g-0 w-100">
                  <div className="d-flex">
                    <span className="align-self-start investor-restrictions-text">
                      Target Fund Length
                    </span>
                  </div>

                  <span className="align-self-center investor-restrictions-value-text mt-3">
                    {fund ? fund.targetFundLength / 12 + " years" : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StructureSection;
