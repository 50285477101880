import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

function SelectField({
  value,
  setValue,
  valueError,
  setValueError,
  selectOptions,
  label,
  size,
  required,
  fullWidth,
  id,
  disabled,
  shrink,
}) {
  useEffect(() => {
    setValueError("");
  }, [value]);
  console.log(label)
  return (
    <Box sx={{ minWidth: 120 }}>
      {
        !disabled ?
          (<TextField
            error={valueError !== ""}
            id={id}
            label={label}
            helperText={valueError}
            value={value}
            disabled={disabled}
            onChange={(event) => setValue(event.target.value)}
            onBlur={() => {
              if (required && value === "") {
                setValueError("This field is required.");
              }
            }}
            size={size}
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Montserrat",
                color: "#262A3D",
                fontSize: "14px",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#0D47A1",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "#0D47A1",
                  color: "#0D47A1",
                },
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: "#DADDE1",
                },
              },
              "& .MuiOutlinedInput-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: "#DADDE1",
                },
              },
              "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
                "& > fieldset": {
                  borderColor: valueError === "" ? "#DADDE1" : "#D32F2F",
                },
              },
            }}
            required={required}
            InputLabelProps={{ shrink: shrink }}
            fullWidth={fullWidth}
            select
          >
            {selectOptions.map((option) => {
              return <MenuItem value={option.value}>{option.label}</MenuItem>;
            })}
          </TextField>) :
          (<TextField
            error={valueError !== ""}
            id={id}
            label={label}
            helperText={valueError}
            value={value}
            disabled={true}
            onChange={(event) => setValue(event.target.value)}
            onBlur={() => {
              if (required && value === "") {
                setValueError("This field is required.");
              }
            }}
            size={size}
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Montserrat",
                color: "#262A3D",
                fontSize: "14px",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#0D47A1",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "#0D47A1",
                  color: "#0D47A1",
                },
              },
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: "#DADDE1",
                },
              },
              "& .MuiOutlinedInput-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: "#DADDE1",
                },
              },
              "& > .MuiOutlinedInput-root:not(.Mui-focused):hover": {
                "& > fieldset": {
                  borderColor: valueError === "" ? "#DADDE1" : "#D32F2F",
                },
              },
            }}
            required={required}
            InputLabelProps={{ shrink: shrink }}
            fullWidth={fullWidth}
          />)}

    </Box>
  );
}
export default SelectField;
