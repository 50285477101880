import React from "react";
import Trustees from "./Trustees/Trustees";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions";

function TrustAccountDetailsTrusteeInfo({
  accountType,
  accountID,
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  entityType,
  setEntityType,
  entityTypeError,
  setEntityTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  relatedAccounts,
  setRelatedAccounts,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-2">
            <span className="trust-account-section-header-text">
              Trustee Information
            </span>
          </div>
          <Trustees
            accountType={accountType}
            accountID={accountID}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
          />
          <UpdateInfoInstructions />
        </div>
      </div>
    </div>
  );
}
export default TrustAccountDetailsTrusteeInfo;
