import { useState, useEffect } from "react";
import { getAccountList } from "../../Api/ProfileApi.js";

export default function GetInvestmentAccountsAdapter(refresh, setLoading) {
  const [investmentAccounts, setInvestmentAccounts] = useState([]);

  useEffect(() => {
    async function getInvestmentAccountsInfo() {
      setLoading(true);
      try {
        const response = await getAccountList();
        const accountListData = response.data;

        setInvestmentAccounts([]);
        accountListData.forEach((account, index) => {
          let accountTypes = {
            IND: "Individual",
            TRT: "Trust",
            ENT: "Entity",
            JOINT: "Joint"
          };

          var tempCell = account.number;
          var copyString = "X".repeat(tempCell.length - 4);
          // copyString += tempCell.slice(-4);

          const statusCodes = {
            A: "Pending Information",
            R: "Pending Verification Refresh",
            I: "Pending Verification",
            P: "Pending Verification",
            F: "Failed Verification",
            S: "Successful Verification",
          };


          setInvestmentAccounts((prev) => [
            ...prev,
            {
              primaryAccount: index == 0,
              id: account.id,
              accountNumber: account.number,
              investorProfileComplete: account.investor_profile_complete,
              accountType: accountTypes[account.account_type],
              dateAdded: account.date_added,
              accountName: account.name,
              status:
                account.account_verifications.length === 0
                  ? "Pending Verification"
                  : statusCodes[account.account_verifications[0].status],
              indIncomplete: (account.investor_profile_complete === false),
              completed:
                (account.investor_profile_complete &&
                  account.account_type === "IND") ||
                account.entity_complete || (account.secondary_investor_profile !== null && account.secondary_investor_profile.address_complete&&account.secondary_investor_profile.pii_complete && account.account_type === "JOINT"),
            },
          ]);
          setLoading(false);
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.log(err);
      }
    }
    getInvestmentAccountsInfo();
  }, [refresh]);

  return { investmentAccounts };
}
