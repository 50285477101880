import React from "react";
import Alert from "@mui/material/Alert";

function IndividualAccountBasicInformationQualificationErrorSection({
  individualAccountBasicInformationError,
  mountedStyle,
  unmountedStyle,
}) {
  return (
    individualAccountBasicInformationError !== "" && (
      <div
        className="row g-0 w-100 mt-3 fade-in-test"
        style={
          individualAccountBasicInformationError ? mountedStyle : unmountedStyle
        }
      >
        <Alert severity="error">{individualAccountBasicInformationError}</Alert>
      </div>
    )
  );
}
export default IndividualAccountBasicInformationQualificationErrorSection;
