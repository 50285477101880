import { useState, useEffect } from "react";
import { getBlogPostByLink } from "../Api/BlogPostApi.js";
import { getSignedURL } from "../Api/GlobalFunctions/SignedURL";

export default function GetBlogPostByLinkAdapter(link, setLoading) {
  const [blogPost, setBlogPost] = useState([]);

  useEffect(() => {
    setBlogPost([]);
  }, []);

  useEffect(async () => {
    setBlogPost([]);
    setLoading(true);

    try {
      const response = await getBlogPostByLink(link);
      if (response.data !== null && response.data !== undefined) {
        try {
          var postImageURL = "";
          var authorProfilePicURL = "";

          async function getUsableURLs() {
            setBlogPost({
              id: response.data.id,
              postTitle: response.data.title,
              postSubtitle: response.data.subtitle,
              postContent: response.data.content,
              postImageLink: response.data.thumbnail,
              authorName: response.data.author.name,
              authorEmail: response.data.author.email,
              authorProfilePicLink: response.data.author.image,
              display: response.data.display,
            });
            setLoading(false);
          }
          getUsableURLs();
        } catch (error) {
          if (process.env.REACT_APP_DEBUG === "1") console.log(error);
          setLoading(false);
        }
        // setLoading(false);
      }
    } catch (error) {
      if (process.env.REACT_APP_DEBUG === "1") console.log(error);
    }
  }, [link]);

  return { blogPost };
}
