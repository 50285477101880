import React, { useEffect } from "react";
import { Tabs, Tab } from "@mui/material";

const InfiniteTabInput = ({tabs, addNewTab, activeTab, setActiveTab}) => {
  return (
    <Tabs
      value={activeTab}
      onChange={(event, newTab) => {
        setActiveTab(newTab);
      }}
      indicatorColor="primary"
      textColor="primary"
      variant={"scrollable"}
      scrollButtons={"auto"}    
    >
      {tabs.map((tab, index) => (
        <Tab key={index} label={tab} value={tab} />
      ))}
      { addNewTab && <Tab key={tabs.length+2} label={"+"} onClick={addNewTab} /> }
    </Tabs>
  );
};

export default InfiniteTabInput; 