import React, { useEffect, useState } from "react";
import "./FundDetailsPageMainContentSectionStyles/FundDetailsPageMainContentSectionStyles.css";
import OverviewSection from "./OverviewSection/OverviewSection.js";
import DeckSection from "./DeckSection/DeckSection.js";
import StructureSection from "./StructureSection/StructureSection.js";
import DocsSection from "./DocsSection/DocsSection.js";
import LearnMoreSection from "./LearnMoreSection/LearnMoreSection.js";
import Tabs from "../../../../Components/GlobalComponents/Tabs/Tabs.js";
import Tab from "../../../../Components/GlobalComponents/Tabs/Tab.js";

function FundDetailsPageMainContentSection({ fund }) {
  const [overviewSelected, setOverviewSelected] = useState(true);
  const [deckSelected, setDeckSelected] = useState(false);
  const [structureSelected, setStructureSelected] = useState(false);
  const [docsSelected, setDocsSelected] = useState(false);
  const [learnMoreSelected, setLearnMoreSelected] = useState(false);

  useEffect(() => {
    if (overviewSelected) {
      setDeckSelected(false);
      setStructureSelected(false);
      setDocsSelected(false);
      setLearnMoreSelected(false);
    }
  }, [overviewSelected]);

  useEffect(() => {
    if (deckSelected) {
      setOverviewSelected(false);
      setStructureSelected(false);
      setDocsSelected(false);
      setLearnMoreSelected(false);
    }
  }, [deckSelected]);

  useEffect(() => {
    if (structureSelected) {
      setDeckSelected(false);
      setOverviewSelected(false);
      setDocsSelected(false);
      setLearnMoreSelected(false);
    }
  }, [structureSelected]);

  useEffect(() => {
    if (docsSelected) {
      setDeckSelected(false);
      setStructureSelected(false);
      setOverviewSelected(false);
      setLearnMoreSelected(false);
    }
  }, [docsSelected]);

  useEffect(() => {
    if (learnMoreSelected) {
      setDeckSelected(false);
      setStructureSelected(false);
      setDocsSelected(false);
      setOverviewSelected(false);
    }
  }, [learnMoreSelected]);

  return (
    <React.Fragment>
      <Tabs>
        <Tab
          title="Overview"
          selected={overviewSelected}
          setSelected={setOverviewSelected}
        >
          <OverviewSection fund={fund} />
        </Tab>
        <Tab title="Deck" selected={deckSelected} setSelected={setDeckSelected}>
          <DeckSection fund={fund} />
        </Tab>
        {/* <Tab
          title="Structure"
          selected={structureSelected}
          setSelected={setStructureSelected}
        >
          <StructureSection fund={fund} />
        </Tab> */}
        <Tab title="Docs" selected={docsSelected} setSelected={setDocsSelected}>
          <DocsSection fund={fund} />
        </Tab>
        {/* <Tab
          title="Learn More"
          selected={learnMoreSelected}
          setSelected={setLearnMoreSelected}
        >
          <LearnMoreSection fund={fund} />
        </Tab> */}
      </Tabs>
    </React.Fragment>
  );
}
export default FundDetailsPageMainContentSection;
