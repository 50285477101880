import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Header from "../../../../Components/GlobalComponents/Header";
import { Breadcrumb } from "antd";
import { useParams } from "react-router";
import PageLoading from "../../../../Components/GlobalComponents/PageLoading/PageLoading";
import GetBlogPostByLinkAdapter from "../../../../Adapters/GetBlogPostByLinkAdapter";
import "./BlogPostPageStyles/BlogPostPageStyles.scss";

function BlogPostPage() {
  let history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [postImageLoading, setPostImageLoading] = useState(true);
  const [authorPicLoading, setAuthorPicLoading] = useState(true);
  const { blogPost } = GetBlogPostByLinkAdapter(id, setLoading);

  const postImageOnLoad = () => {
    setPostImageLoading(false);
  };

  const authorPicOnLoad = () => {
    setAuthorPicLoading(false);
  };

  return loading ? (
    <PageLoading />
  ) : (
    <React.Fragment>
      <Header activePage="learn" />
      <div className="container-xxl container-fluid pt-5 mt-5 ps-xxl-0 pe-xxl-0 ps-5 pe-5 justify-content-center d-flex">
        <div className="col-9 justify-self-center">
          <div className="row g-0 w-100 mt-3 mb-4 justify-content-center">
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/learn");
                }}
              >
                <span className="breadcrumb-previous-text">Blog</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span className="breadcrumb-current-text">
                  {blogPost.postTitle}
                </span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="row g-0 w-100 justify-content-center p-0 mb-2">
            <div className="post-title-text ps-0">{blogPost.postTitle}</div>
          </div>
          <div className="row g-0 w-100 justify-content-center p-0 mb-2">
            <div className="post-subtitle-text ps-0">
              {blogPost.postSubtitle}
            </div>
          </div>
          <div className="row g-0 w-100 justify-content-center mb-4">
            <div className="d-flex align-items-center">
              <img
                src={blogPost.authorProfilePicLink}
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  display: authorPicLoading ? "none" : "block",
                }}
                onLoad={authorPicOnLoad}
              />
              <div
                className="animated-slow"
                style={{
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  display: authorPicLoading ? "inline-block" : "none",
                }}
              />
              <div
                className="card-text ps-3"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600, Semi Bold",
                  fontStyle: "normal",
                  color: "#171721",
                }}
              >
                {blogPost.authorName}
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 pb-4 justify-content-center">
            <img
              src={blogPost.postImageLink}
              className="img-fluid"
              style={{
                borderRadius: "10px",
                display: postImageLoading ? "none" : "block",
              }}
              onLoad={postImageOnLoad}
            />
            <div
              className="img-fluid animated"
              style={{
                width: "100%",
                minHeight: "600px",
                borderRadius: "10px",
                display: postImageLoading ? "inline-block" : "none",
              }}
            />
          </div>
          <div className="row g-0 w-100 pb-5">
            <span
              className="post-content-text"
              dangerouslySetInnerHTML={{
                __html: blogPost ? blogPost.postContent : <p>Loading...</p>,
              }}
            ></span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default BlogPostPage;
