import React, { useState } from "react";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";
import MuiButton from "../../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import {
  QualifiedPurchaserLink,
  AccreditedInvestorLink,
} from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import AlertSnackBar from "../../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import { QualifiedPurchaserModal } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { useRecoilState } from "recoil";
import {
  qualificationDateVal,
  smLLCAssets5M,
  smLLCOwnerAccredited,
} from "../../../../../../../../atoms";
import RadioInputGroup from "../../../../../../../../Components/GlobalComponents/RadioInputs/RadioInputGroup";
import RadioInput from "../../../../../../../../Components/GlobalComponents/RadioInputs/RadioInput/RadioInput";
import AccreditedInvestorModal from "../IndividualQualification/AccreditedInvestorModal/AccreditedInvestorModal";

function SMLLCAccreditationQualification({ setActiveSlide }) {
  const [showQPModal, setShowQPModal] = useState(false);
  const [showAccreditedModal, setShowAccreditedModal] = useState(false);
  const [qualificationDate, setQualificationDate] =
    useRecoilState(qualificationDateVal);

  const [smLLCAssets, setSMLLCAssets] = useRecoilState(smLLCAssets5M);
  const [smLLCAccreditedOwners, setSMLLCAccreditedOwners] =
    useRecoilState(smLLCOwnerAccredited);

  const [questionsIncompleteError, setQuestionsIncompleteError] =
    useState(false);
  const [notAccreditedError, setNotAccreditedError] = useState(false);

  const validateAccreditationStatus = () => {
    let isAccredited = true;
    let errors = false;

    if (smLLCAssets === "" || smLLCAccreditedOwners === "") {
      errors = true;
    } else if (!smLLCAssets && !smLLCAccreditedOwners) {
      isAccredited = false;
    }

    if (errors === true) {
      setQuestionsIncompleteError(true);
    } else if (isAccredited === false) {
      setNotAccreditedError(true);
    } else {
      var utcDate = getDateUTC();
      setQualificationDate(utcDate.toISOString());
      setActiveSlide((curSlide) => curSlide + 1);
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal
        show={showQPModal}
        setShowModal={setShowQPModal}
      />
      <AccreditedInvestorModal
        show={showAccreditedModal}
        setShowModal={setShowAccreditedModal}
      />
      <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
        <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4 mt-3">
              <div className="col-12">
                <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <span className="body-text-md-semibold">
                  Please confirm your <strong>Qualification Status</strong>
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Are you as the owner of the Single-member LLC an accredited investor? *Full definition below"
                }
              >
                <RadioInput
                  checked={smLLCAccreditedOwners === true}
                  setValue={setSMLLCAccreditedOwners}
                  radioValue={true}
                  label={"Yes"}
                  index={0}
                />
                <RadioInput
                  checked={smLLCAccreditedOwners === false}
                  setValue={setSMLLCAccreditedOwners}
                  radioValue={false}
                  label={"No"}
                  index={1}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mb-3">
              <RadioInputGroup
                radioText={
                  "Does the entity have more than $5M in assets and wasn’t created for the sole purpose of investing in opportunities offered on our platform?"
                }
              >
                <RadioInput
                  checked={smLLCAssets === true}
                  setValue={setSMLLCAssets}
                  radioValue={true}
                  label={"Yes"}
                  index={2}
                />
                <RadioInput
                  checked={smLLCAssets === false}
                  setValue={setSMLLCAssets}
                  radioValue={false}
                  label={"No"}
                  index={3}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mb-3">
              <span className="body-text-xs-semibold">
                *The definitions of accredited investors and qualified
                purchasers are incomplete above. You can find the full
                definitions below.
              </span>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-10">
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Next step"}
                        onClick={() => validateAccreditationStatus()}
                        size={"medium"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-0 w-100 mt-3 pe-5">
              <div className="d-flex">
                <div className="me-5">
                  <AccreditedInvestorLink
                    setShowModal={setShowAccreditedModal}
                  />
                </div>

                <QualifiedPurchaserLink setShowModal={setShowQPModal} />
              </div>
            </div>
            <AlertSnackBar
              snackbarState={notAccreditedError}
              setSnackbarState={setNotAccreditedError}
              vertical={"top"}
              horizontal={"right"}
              duration={5000}
              sxProps={{ paddingLeft: "3px" }}
              severity={"error"}
              message={
                "Sorry, your account does not qualify as an accredited investor, and we only allow accredited investors on our platform at this time."
              }
            />
            <AlertSnackBar
              snackbarState={questionsIncompleteError}
              setSnackbarState={setQuestionsIncompleteError}
              vertical={"top"}
              horizontal={"right"}
              duration={5000}
              sxProps={{ paddingLeft: "3px" }}
              severity={"error"}
              message={
                "You must select a response to each of the questions below."
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default SMLLCAccreditationQualification;
