import React, { useEffect, useState } from "react";
//import "./FundNameSectionStyles/FundNameSectionStyles.css";

function FundNameSection({ fundName, firmName, logoLink }) {
  const [firmLogoLoading, setFirmLogoLoading] = useState(true);
  console.log(logoLink);
  const firmLogoOnLoad = () => {
    setFirmLogoLoading(false);
  };

  useEffect(() => {
    console.log("Logo link is: " + logoLink);
  }, [logoLink]);

  return (
    <div class="container">
      <div
        style={{ marginTop: "15px", marginBottom: "15px", marginLeft: "15px" }}
        className="d-flex"
      >
        <div style={{ display: "inline-block", marginRight: "20px" }}>
          <img
            src={logoLink}
            height="75"
            style={{ display: firmLogoLoading ? "none" : "inline-block" }}
            onLoad={firmLogoOnLoad}
          />
          <div
            className="animated-slow"
            height="75"
            style={{
              height: "75px",
              width: "75px",
              borderRadius: "10px",
              display: firmLogoLoading ? "inline-block" : "none",
            }}
          />
        </div>
        <div
          style={{ display: "inline-block", "vertical-align": "middle" }}
          className="align-self-center"
        >
          <div class="fund-card-name-text-new">{fundName}</div>
          <div class="firm-name-text-new" style={{ marginTop: "5px" }}>
            {firmName}
          </div>
        </div>
      </div>
    </div>
  );
}
export default FundNameSection;
