import React from "react";
import "./OverviewSectionStyles/OverviewSectionStyles.scss";
import FundOverviewReferenceSection from "./FundOverviewReferenceSection";
import DropdownContent from "../../../../../Components/GlobalComponents/DropdownContent/DropdownContent";

function OverviewSection({ fund }) {
  const classes =
    fund && fund.tagline
      ? "row g-0 ms-4 me-4 mt-4 mb-2"
      : "row g-0 ms-4 me-4 mb-2";
  const hostName = window.location.hostname;

  let fundOverviewContent = fund ? (
    <React.Fragment>
      <div className={classes}>
        <div className="col-12 default-header-text ">
          {fund && fund.tagline ? (
            fund.tagline
          ) : !fund ? (
            <span className="badge badge-light">Fund Not Found</span>
          ) : (
            <></>
          )}
        </div>
        {fund && fund.fundOverview ? (
          <div
            className="col-12 mt-2"
            dangerouslySetInnerHTML={{
              __html: fund ? fund.fundOverview : <p>Loading...</p>,
            }}
          ></div>
        ) : null}
        {fund.referenceTerms.length > 0 ? (
          <FundOverviewReferenceSection referenceTerms={fund.referenceTerms} />
        ) : null}
      </div>
      {fund.fundDueDiligenceQuestions !== null &&
        fund.fundDueDiligenceQuestions !== undefined &&
        fund.fundDueDiligenceQuestions.length !== 0 && (
          <React.Fragment>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12 ps-5 pe-4">
                <span className="default-header-text">
                  Due Diligence Questions
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12 ps-5 pe-5">
                {fund.fundDueDiligenceQuestions.map((element, index) => {
                  console.log("Element is: " + JSON.stringify(element));
                  return index === fund.fundDueDiligenceQuestions.length - 1 ? (
                    <div className="row g-0 ps-4 w-100">
                      <DropdownContent
                        key={index}
                        dropdownPreview={element.question}
                        dropdownDetailedContent={element.answer}
                      />
                    </div>
                  ) : (
                    <div className="row g-0 ps-4 w-100 mb-1">
                      <DropdownContent
                        key={index}
                        dropdownPreview={element.question}
                        dropdownDetailedContent={element.answer}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        )}
      {hostName !== null &&
        hostName !== undefined &&
        (hostName === "localhost" ||
          hostName === "localhost:3000" ||
          hostName === "accollective.investwithaqua.com" ||
          hostName === "abcollective.investwithaqua.com") && 
          ( fund && fund.link && fund.link === 'aix_ventures_fund_ii' ) &&
          (
          <React.Fragment>
            <div className="row g-0 w-100 mt-5 mb-3">
              <div className="col-12 ps-5 pe-5">
                <span className="default-header-text">Vehicle Fees:</span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3 ps-4">
              <div className="col-12 ps-5 pe-5">
                <div className="d-flex">
                  <div className="preview-font me-2">
                    <span>One-time management fee:</span>
                  </div>
                  <div className="preview-font">
                    <span>1.0%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3 ps-4">
              <div className="col-12 ps-5 pe-5">
                <div className="d-flex">
                  <div className="preview-font me-2">
                    <span>Carry:</span>
                  </div>
                  <div className="preview-font">
                    <span>5.0%</span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  ) : (
    <span className="badge badge-light text-dark">
      Overview Not Found. Please check again later.
    </span>
  );

  let fundOverviewCard = fund ? (
    fundOverviewContent
  ) : (
    <span className="badge badge-light text-dark">
      Fund overview not found. Please check again later.
    </span>
  );
  return fundOverviewCard;
}
export default OverviewSection;
