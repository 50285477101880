import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import Header from "../../../../Components/GlobalComponents/Header";
import Carousel from "react-bootstrap/Carousel";
import "./AddInvestmentAccountStyles/AddInvestmentAccountStyles.scss";
import useDelayUnmount from "../../../../CustomHooks/useDelayUnmount.js";
import AccountBasicInformation from "./AccountBasicInformation/AccountBasicInformation";
import AccountLegalInformation from "./AccountLegalInformation/AccountLegalInformation";
import AccountRelatedPartyInformation from "./AccountRelatedPartyInformation/AccountRelatedPartyInformation";
import AdditionalQuestionsEnt from "./AdditionalQuestionsEnt.js";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router";
import { stateList } from "../../../../Components/GlobalComponents/stateList";
import { countryList } from "../../../../Components/countryList";
import { UserContext } from "../../../../GlobalContexts/UserContext";
import GetIncompleteInvestmentAccountAdapter from "../../../../Adapters/GetIncompleteInvestmentAccountAdapter";
import AccountComplete from "./AccountComplete/AccountComplete";

function AddInvestmentAccount() {
  let history = useHistory();

  const { id } = useParams();

  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const [activeSlide, setActiveSlide] = useState(0);

  const [accountType, setAccountType] = useState("");
  const [accountTypeError, setAccountTypeError] = useState("");

  const [legalAccountName, setLegalAccountName] = useState("");
  const [legalAccountNameError, setLegalAccountNameError] = useState("");

  const [domicileCountry, setDomicileCountry] = useState("");
  const [domicileCountryError, setDomicileCountryError] = useState("");

  const [countryOfFormation, setCountryOfFormation] = useState("");
  const [countryOfFormationError, setCountryOfFormationError] = useState("");

  const [individualQualificationQ1, setIndividualQualificationQ1] =
    useState("");

  const [trustQualificationQ1, setTrustQualificationQ1] = useState("");
  const [trustQualificationQ2, setTrustQualificationQ2] = useState("");
  const [trustQualificationQ3, setTrustQualificationQ3] = useState("");
  const [trustQualificationQ4, setTrustQualificationQ4] = useState("");

  const [entityQualificationQ1, setEntityQualificationQ1] = useState("");
  const [entityQualificationQ2, setEntityQualificationQ2] = useState("");
  const [entityQualificationQ3, setEntityQualificationQ3] = useState("");
  const [entityQualificationQ4, setEntityQualificationQ4] = useState("");
  const [entityQualificationQ5, setEntityQualificationQ5] = useState("");

  const [alertError, setAlertError] = useState("");
  const [accountID, setAccountID] = useState(null);

  const [smLlc, setSmLlc] = useState(null);
  const [smLlcError, setSmLlcError] = useState("");

  const [grantorUSCitizen, setGrantorUSCitizen] = useState(null);
  const [grantorError, setGrantorError] = useState("");

  const [stateOfIncorp, setStateOfIncorp] = useState("");
  const [stateOfIncorpError, setStateOfIncorpError] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessPhoneError, setBusinessPhoneError] = useState("");

  const [qualQ1, setQualQ1] = useState("");
  const [qualError, setQualError] = useState("");

  const mountedStyle = {
    opacity: 1,
    transition: "opacity 1.5s ease-out, transform 400ms ease-out",
  };
  const unmountedStyle = { opacity: 0, transition: "opacity 400ms ease-in" };

  const [investorProfileComplete, setInvestorProfileComplete] = useState(false);

  const [supplyPercentOwned, setSupplyPercentOwned] = useState(false);

  const { investmentAccountData } = GetIncompleteInvestmentAccountAdapter(
    investorProfileComplete,
    setInvestorProfileComplete,
    id,
    setLoading,
    accountType,
    setAccountType,
    activeSlide,
    setActiveSlide,
    legalAccountName,
    setLegalAccountName,
    domicileCountry,
    setDomicileCountry,
    countryOfFormation,
    setCountryOfFormation,
    accountID,
    setAccountID,
    setSupplyPercentOwned
  );

  const [
    showIndividualQualificationSection,
    setShowIndividualQualificationSection,
  ] = useState(false);

  const hasIndividualAcct = user.accountListData.some(
    (account) => account.account_type === "IND"
  );

  const hasJointAcct = user.accountListData.some(
    (account) => account.account_type === "JOINT"
  );

  const shouldRenderIndividualQualificationSection = useDelayUnmount(
    showIndividualQualificationSection,
    400,
    true
  );
  const [showTrustQualificationSection, setShowTrustQualificationSection] =
    useState(false);
  const shouldRenderTrustQualificationSection = useDelayUnmount(
    showTrustQualificationSection,
    400,
    true
  );
  const [showEntityQualificationSection, setShowEntityQualificationSection] =
    useState(false);
  const shouldRenderEntityQualificationSection = useDelayUnmount(
    showEntityQualificationSection,
    400,
    true
  );
  const showLegalAccountNameField = useDelayUnmount(
    !["IND", "JOINT"].includes(accountType),
    400,
    false
  );

  const accountTypeOptions = [
    { value: "LT", label: "Revocable Trust" },
    { value: "TRUST", label: "Irrevocable Trust" },
    { value: "LLC", label: "LLC" },
    { value: "C-CORP", label: "C Corporation" },
    { value: "S-CORP", label: "S Corporation" },
    { value: "LP", label: "Limited Partnership" },
    { value: "GP", label: "General Partnership" },
    { value: "FE", label: "Foreign Entity" },
    { value: "SM_LLC", label: "Single-Member LLC" },
    ...(false ? [{ value: "JOINT", label: "Joint Account" }] : []),
    ...(!hasIndividualAcct
      ? [{ value: "IND", label: "Individual Account" }]
      : []),
  ];

  useEffect(() => {
    if (id !== undefined) {
      setAccountID(id);
    }
  }, [id]);

  useEffect(() => {
    if (["IND", "JOINT", "SM_LLC"].includes(accountType)) {
      setShowIndividualQualificationSection(true);
      setShowTrustQualificationSection(false);
      setShowEntityQualificationSection(false);
      setAlertError("");
    } else if (accountType === "LT" || accountType === "TRUST") {
      setShowTrustQualificationSection(true);
      setShowIndividualQualificationSection(false);
      setShowEntityQualificationSection(false);
      setAlertError("");
    } else if (accountType !== "") {
      setShowEntityQualificationSection(true);
      setShowTrustQualificationSection(false);
      setShowIndividualQualificationSection(false);
      setAlertError("");
    }
  }, [accountType]);

  const nextButtonValidation = () => {
    if (accountType === "IND") {
    } else if (accountType === "LT" || accountType === "TRUST") {
    } else if (accountType !== "") {
    } else {
      setAlertError("Please make an account type selection.");
    }
  };

  return (
    <React.Fragment>
      <Header activePage={"investment-accounts"} />
      <div className="container-xxl pt-5 mb-5" style={{ maxWidth: "628px" }}>
        <div className="row g-0 w-100 mt-5"></div>
        <div className="row g-0 w-100 mt-5" style={{ marginBottom: "28px" }}>
          <div className="d-flex align-items-center">
            <img
              src="https://d1swyfveypj49y.cloudfront.net/NewBackArrow.svg"
              style={{
                width: "16px",
                height: "14px",
                cursor: "pointer",
                marginRight: "12px",
              }}
              onClick={() => history.push("/investment-accounts")}
            />
            <span className="add-account-page-title-text">Add Account</span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-4">
          <Carousel
            indicators={false}
            controls={false}
            interval={null}
            activeIndex={activeSlide}
          >
            <Carousel.Item>
              <AccountBasicInformation
                setAccountID={setAccountID}
                accountType={accountType}
                setAccountType={setAccountType}
                accountTypeError={accountTypeError}
                setAccountTypeError={setAccountTypeError}
                accountTypeOptions={accountTypeOptions}
                showLegalAccountNameField={showLegalAccountNameField}
                legalAccountName={legalAccountName}
                setLegalAccountName={setLegalAccountName}
                legalAccountNameError={legalAccountNameError}
                setLegalAccountNameError={setLegalAccountNameError}
                investorProfileComplete={investorProfileComplete}
                shouldRenderIndividualQualificationSection={
                  shouldRenderIndividualQualificationSection
                }
                showIndividualQualificationSection={
                  showIndividualQualificationSection
                }
                individualQualificationQ1={individualQualificationQ1}
                setIndividualQualificationQ1={setIndividualQualificationQ1}
                shouldRenderTrustQualificationSection={
                  shouldRenderTrustQualificationSection
                }
                showTrustQualificationSection={showTrustQualificationSection}
                trustQualificationQ1={trustQualificationQ1}
                setTrustQualificationQ1={setTrustQualificationQ1}
                trustQualificationQ2={trustQualificationQ2}
                setTrustQualificationQ2={setTrustQualificationQ2}
                trustQualificationQ3={trustQualificationQ3}
                setTrustQualificationQ3={setTrustQualificationQ3}
                trustQualificationQ4={trustQualificationQ4}
                setTrustQualificationQ4={setTrustQualificationQ4}
                countryOfFormation={countryOfFormation}
                countryOfFormationError={countryOfFormationError}
                setCountryOfFormationError={setCountryOfFormationError}
                setCountryOfFormation={setCountryOfFormation}
                processedCountryList={countryList}
                shouldRenderEntityQualificationSection={
                  shouldRenderEntityQualificationSection
                }
                showEntityQualificationSection={showEntityQualificationSection}
                entityQualificationQ1={entityQualificationQ1}
                setEntityQualificationQ1={setEntityQualificationQ1}
                entityQualificationQ2={entityQualificationQ2}
                setEntityQualificationQ2={setEntityQualificationQ2}
                entityQualificationQ3={entityQualificationQ3}
                setEntityQualificationQ3={setEntityQualificationQ3}
                entityQualificationQ4={entityQualificationQ4}
                setEntityQualificationQ4={setEntityQualificationQ4}
                entityQualificationQ5={entityQualificationQ5}
                setEntityQualificationQ5={setEntityQualificationQ5}
                mountedStyle={mountedStyle}
                unmountedStyle={unmountedStyle}
                setActiveSlide={setActiveSlide}
              />
              {alertError !== "" && (
                <div className="row g-0 w-100 mt-3">
                  <Alert severity="error">{alertError}</Alert>
                </div>
              )}
            </Carousel.Item>
            <Carousel.Item>
              <AccountLegalInformation
                accountID={accountID}
                accountType={accountType}
                domicileCountry={domicileCountry}
                setDomicileCountry={setDomicileCountry}
                countryOfFormation={countryOfFormation}
                processedCountryList={countryList}
                processedStateList={stateList}
                setActiveSlide={setActiveSlide}
              />
            </Carousel.Item>
            <Carousel.Item>
              <AccountRelatedPartyInformation
                accountType={accountType}
                processedCountryList={countryList}
                processedStateList={stateList}
                setActiveSlide={setActiveSlide}
                accountID={accountID}
                supplyPercentOwned={supplyPercentOwned}
              />
            </Carousel.Item>
            <Carousel.Item>
              <AccountComplete accountID={accountID} />
            </Carousel.Item>
            <Carousel.Item>
              <AdditionalQuestionsEnt
                accountType={accountType}
                processedCountryList={countryList}
                processedStateList={stateList}
                setActiveSlide={setActiveSlide}
                accountID={accountID}
                answer={smLlc}
                setAnswer={setSmLlc}
                error={smLlcError}
                setError={setSmLlcError}
                businessPhone={businessPhone}
                businessPhoneError={businessPhoneError}
                setBusinessPhone={setBusinessPhone}
                setBusinessPhoneError={setBusinessPhoneError}
                stateOfIncorp={stateOfIncorp}
                stateOfIncorpError={stateOfIncorpError}
                setStateOfIncorp={setStateOfIncorp}
                setStateOfIncorpError={setStateOfIncorpError}
                countryOfFormation={countryOfFormation}
                qualQ1={qualQ1}
                setQualQ1={setQualQ1}
                qualError={qualError}
                setQualError={setQualError}
                mountedStyle={mountedStyle}
                unmountedStyle={unmountedStyle}
                grantorUSCitizen={grantorUSCitizen}
                setGrantorUSCitizen={setGrantorUSCitizen}
                grantorError={grantorError}
                setGrantorError={setGrantorError}
              />{" "}
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AddInvestmentAccount;
