import React, { useEffect, useState } from "react";
import InfiniteTabInput from "../../../../../../Components/GlobalComponents/InfiniteTabInput/InfiniteTabInput";
import InfiniteInputComponent from "../../InfiniteInputComponent/InfiniteInputComponent";
import BeneficialOwnerSection from "./BeneficialOwnerSection/BeneficialOwnerSection";
import useDelayUnmount from "../../../../../../CustomHooks/useDelayUnmount";
import { isValidCountry } from "../../../../../../GlobalFunctions/globalFunctions";
import { isDobValid } from "../../../../../../Functions/functions";
import { isEmailValid } from "../../../../../../Functions/functions";
import { isValidSSN } from "../../../../../../Functions/functions";
import { updateRelatedPartyInformation } from "../../../../../../Api/ProfileApi";
import { useHistory } from "react-router";
import { stripOutDigits } from "../../../../../../utilities/functions/functions";

function EntityAccountRelatedPartyInformation({
  processedCountryList,
  processedStateList,
  setActiveSlide,
  accountType,
  accountID,
  mountedStyle,
  unmountedStyle,
  supplyPercentOwned,
}) {
  let history = useHistory();
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [hasBeneficialOwners, setHasBeneficialOwners] = useState("");
  const [hasBeneficialOwnersError, setHasBeneficialOwnersError] = useState("");

  const [showBeneficialOwnerSection, setShowBeneficialOwnerSection] =
    useState(false);

  const shouldRenderBeneficialOwnerSection = useDelayUnmount(
    showBeneficialOwnerSection,
    400,
    true
  );

  const [hideButton, setHideButton] = useState(false);
  const [hideAlterButtons, setHideAlterButtons] = useState(false);

  //   const [activeTab, setActiveTab] = useState("Trustee #1");

  const [relatedAccounts, setRelatedAccounts] = useState([
    {
      relatedPartyType: "",
      relatedPartyTypeError: "",
      entityPercentOwned: "",
      entityPercentOwnedError: "",
      entityName: "",
      entityNameError: "",
      entityAddressLine1: "",
      entityAddressLine1Error: "",
      entityAddressLine2: "",
      entityAddressLine2Error: "",
      entityCity: "",
      entityCityError: "",
      entityState: "",
      entityStateError: "",
      entityPostalCode: "",
      entityPostalCodeError: "",
      entityTaxIDNumber: "",
      entityTaxIDNumberError: "",
      entityPhoneNumber: "",
      entityPhoneNumberError: "",
      entityStateOfIncorporation: "",
      entityStateOfIncorporationError: "",
      entityMailingAddressDifferent: "",
      entityMailingAddressDifferentError: "",
      entityMailingAddressLine1: "",
      entityMailingAddressLine1Error: "",
      entityMailingAddressLine2: "",
      entityMailingAddressLine2Error: "",
      entityMailingCity: "",
      entityMailingCityError: "",
      entityMailingState: "",
      entityMailingStateError: "",
      entityMailingPostalCode: "",
      entityMailingPostalCodeError: "",
      entityType: "",
      entityTypeError: "",
      entityControlPersonFirstName: "",
      entityControlPersonFirstNameError: "",
      entityControlPersonLastName: "",
      entityControlPersonLastNameError: "",
      entityControlPersonEmail: "",
      entityControlPersonEmailError: "",
      entityControlPersonIsUSBased: "",
      entityControlPersonIsUSBasedError: "",
      entityControlPersonIsUSCitizen: "",
      entityControlPersonIsUSCitizenError: "",
      entityControlPersonPIIAvailable: "",
      entityControlPersonPIIAvailableError: "",
      entityControlPersonAddressLine1: "",
      entityControlPersonAddressLine1Error: "",
      entityControlPersonAddressLine2: "",
      entityControlPersonAddressLine2Error: "",
      entityControlPersonCity: "",
      entityControlPersonCityError: "",
      entityControlPersonState: "",
      entityControlPersonStateError: "",
      entityControlPersonPostalCode: "",
      entityControlPersonPostalCodeError: "",
      entityControlPersonPhoneNumber: "",
      entityControlPersonPhoneNumberError: "",
      entityControlPersonTaxIDNumber: "",
      entityControlPersonTaxIDNumberError: "",
      entityControlPersonDOB: "",
      entityControlPersonDOBError: "",
      entityCertificateOfFormation: "",
      entityCertificateOfFormationError: "",
      entityProofOfAddress: "",
      entityProofOfAddressError: "",
      entityControlPersonPassportDocument: "",
      entityControlPersonPassportDocumentError: "",
      entityControlPersonProofOfAddress: "",
      entityControlPersonProofOfAddressError: "",
      entityIsTrustManagementCompany: "",
      entityIsTrustManagementCompanyError: "",
      entityHasAlternateSignatory: "",
      entityHasAlternateSignatoryError: "",
      entityAlternateSignatoryFullName: "",
      entityAlternateSignatoryFullNameError: "",
      entityAlternateSignatoryEmail: "",
      entityAlternateSignatoryEmailError: "",
      entityAlternateSignatoryTitle: "",
      entityAlternateSignatoryTitleError: "",
      entityControlPersonRole: "",
      entityControlPersonRoleError: "",
      entityNestedControlEntityTrusteeType: "",
      entityNestedControlEntityTrusteeTypeError: "",
      entityNestedControlEntityName: "",
      entityNestedControlEntityNameError: "",
      entityNestedControlEntityType: "",
      entityNestedControlEntityTypeError: "",
      entityNestedControlEntityPercentOwned: "",
      entityNestedControlEntityPercentOwnedError: "",
      entityNestedControlEntityStateOfIncorporation: "",
      entityNestedControlEntityStateOfIncorporationError: "",
      entityNestedControlEntityTaxIDNumber: "",
      entityNestedControlEntityTaxIDNumberError: "",
      entityNestedControlEntityPhoneNumber: "",
      entityNestedControlEntityPhoneNumberError: "",
      entityNestedControlEntityAddressLine1: "",
      entityNestedControlEntityAddressLine1Error: "",
      entityNestedControlEntityAddressLine2: "",
      entityNestedControlEntityAddressLine2Error: "",
      entityNestedControlEntityCity: "",
      entityNestedControlEntityCityError: "",
      entityNestedControlEntityState: "",
      entityNestedControlEntityStateError: "",
      entityNestedControlEntityPostalCode: "",
      entityNestedControlEntityPostalCodeError: "",
      entityNestedControlEntityIsTrustManagementCompany: "",
      entityNestedControlEntityIsTrustManagementCompanyError: "",
      entityNestedControlEntityAddressType: "",
      entityNestedControlEntityAddressTypeError: "",
      entityNestedControlEntityCertificateOfFormation: "",
      entityNestedControlEntityCertificateOfFormationError: "",
      entityNestedControlEntityProofOfAddress: "",
      entityNestedControlEntityProofOfAddressError: "",
      entityNestedControlEntityControlPersonFirstName: "",
      entityNestedControlEntityControlPersonFirstNameError: "",
      entityNestedControlEntityControlPersonLastName: "",
      entityNestedControlEntityControlPersonLastNameError: "",
      entityNestedControlEntityControlPersonEmail: "",
      entityNestedControlEntityControlPersonEmailError: "",
      entityNestedControlEntityControlPersonIsUSBased: "",
      entityNestedControlEntityControlPersonIsUSBasedError: "",
      entityNestedControlEntityControlPersonIsUSCitizen: "",
      entityNestedControlEntityControlPersonIsUSCitizenError: "",
      entityNestedControlEntityControlPersonPhoneNumber: "",
      entityNestedControlEntityControlPersonPhoneNumberError: "",
      entityNestedControlEntityControlPersonPIIAvailable: "",
      entityNestedControlEntityControlPersonPIIAvailableError: "",
      entityNestedControlEntityControlPersonAddressLine1: "",
      entityNestedControlEntityControlPersonAddressLine1Error: "",
      entityNestedControlEntityControlPersonAddressLine2: "",
      entityNestedControlEntityControlPersonAddressLine2Error: "",
      entityNestedControlEntityControlPersonCity: "",
      entityNestedControlEntityControlPersonCityError: "",
      entityNestedControlEntityControlPersonState: "",
      entityNestedControlEntityControlPersonStateError: "",
      entityNestedControlEntityControlPersonPostalCode: "",
      entityNestedControlEntityControlPersonPostalCodeError: "",
      entityNestedControlEntityControlPersonTaxIDNumber: "",
      entityNestedControlEntityControlPersonTaxIDNumberError: "",
      entityNestedControlEntityControlPersonDOB: "",
      entityNestedControlEntityControlPersonDOBError: "",
      entityNestedControlEntityControlPersonPassportDocument: "",
      entityNestedControlEntityControlPersonPassportDocumentError: "",
      entityNestedControlEntityControlPersonProofOfAddress: "",
      entityNestedControlEntityControlPersonProofOfAddressError: "",
      entityNestedControlEntityControlPersonAddressType: "",
      entityNestedControlEntityControlPersonAddressTypeError: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressType: "",
      deliverableAddressLine1: "",
      deliverableAddressLine2: "",
      deliverableCity: "",
      deliverableState: "",
      deliverableCountry: "",
      deliverablePostalCode: "",
      deliverableAddressType: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idType: "",
      idNum: "",
      idNumError: "",
      domicileCountry: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      addressLine1Error: "",
      addressLine2Error: "",
      cityError: "",
      stateError: "",
      countryError: "",
      postalCodeError: "",
      deliverableAddressLine1Error: "",
      deliverableAddressLine2Error: "",
      deliverableCityError: "",
      deliverableStateError: "",
      deliverableCountryError: "",
      deliverablePostalCodeError: "",
      primaryAddressDeliverable: "",
      domicileCountryError: "",
      deliverableSelectionError: "",
      percentOwned: "",
      percentOwnedError: "",
      citizenship: "",
      citizenshipError: "",
    },
  ]);
  const [activeTab, setActiveTab] = useState("Beneficial Owner #1");
  // To-do: track idx instead of tab name?

  const addNewTab = () => {
    setRelatedAccounts([
      ...relatedAccounts,
      {
        relatedPartyType: "",
        relatedPartyTypeError: "",
        entityPercentOwned: "",
        entityPercentOwnedError: "",
        entityName: "",
        entityNameError: "",
        entityAddressLine1: "",
        entityAddressLine1Error: "",
        entityAddressLine2: "",
        entityAddressLine2Error: "",
        entityCity: "",
        entityCityError: "",
        entityState: "",
        entityStateError: "",
        entityPostalCode: "",
        entityPostalCodeError: "",
        entityTaxIDNumber: "",
        entityTaxIDNumberError: "",
        entityPhoneNumber: "",
        entityPhoneNumberError: "",
        entityStateOfIncorporation: "",
        entityStateOfIncorporationError: "",
        entityMailingAddressDifferent: "",
        entityMailingAddressDifferentError: "",
        entityMailingAddressLine1: "",
        entityMailingAddressLine1Error: "",
        entityMailingAddressLine2: "",
        entityMailingAddressLine2Error: "",
        entityMailingCity: "",
        entityMailingCityError: "",
        entityMailingState: "",
        entityMailingStateError: "",
        entityMailingPostalCode: "",
        entityMailingPostalCodeError: "",
        entityType: "",
        entityTypeError: "",
        entityControlPersonFirstName: "",
        entityControlPersonFirstNameError: "",
        entityControlPersonLastName: "",
        entityControlPersonLastNameError: "",
        entityControlPersonEmail: "",
        entityControlPersonEmailError: "",
        entityControlPersonIsUSBased: "",
        entityControlPersonIsUSBasedError: "",
        entityControlPersonIsUSCitizen: "",
        entityControlPersonIsUSCitizenError: "",
        entityControlPersonPIIAvailable: "",
        entityControlPersonPIIAvailableError: "",
        entityControlPersonAddressLine1: "",
        entityControlPersonAddressLine1Error: "",
        entityControlPersonAddressLine2: "",
        entityControlPersonAddressLine2Error: "",
        entityControlPersonCity: "",
        entityControlPersonCityError: "",
        entityControlPersonState: "",
        entityControlPersonStateError: "",
        entityControlPersonPostalCode: "",
        entityControlPersonPostalCodeError: "",
        entityControlPersonPhoneNumber: "",
        entityControlPersonPhoneNumberError: "",
        entityControlPersonTaxIDNumber: "",
        entityControlPersonTaxIDNumberError: "",
        entityControlPersonDOB: "",
        entityControlPersonDOBError: "",
        entityCertificateOfFormation: "",
        entityCertificateOfFormationError: "",
        entityProofOfAddress: "",
        entityProofOfAddressError: "",
        entityControlPersonPassportDocument: "",
        entityControlPersonPassportDocumentError: "",
        entityControlPersonProofOfAddress: "",
        entityControlPersonProofOfAddressError: "",
        entityIsTrustManagementCompany: "",
        entityIsTrustManagementCompanyError: "",
        entityHasAlternateSignatory: "",
        entityHasAlternateSignatoryError: "",
        entityAlternateSignatoryFullName: "",
        entityAlternateSignatoryFullNameError: "",
        entityAlternateSignatoryEmail: "",
        entityAlternateSignatoryEmailError: "",
        entityAlternateSignatoryTitle: "",
        entityAlternateSignatoryTitleError: "",
        entityControlPersonRole: "",
        entityControlPersonRoleError: "",
        entityNestedControlEntityTrusteeType: "",
        entityNestedControlEntityTrusteeTypeError: "",
        entityNestedControlEntityName: "",
        entityNestedControlEntityNameError: "",
        entityNestedControlEntityType: "",
        entityNestedControlEntityTypeError: "",
        entityNestedControlEntityPercentOwned: "",
        entityNestedControlEntityPercentOwnedError: "",
        entityNestedControlEntityStateOfIncorporation: "",
        entityNestedControlEntityStateOfIncorporationError: "",
        entityNestedControlEntityTaxIDNumber: "",
        entityNestedControlEntityTaxIDNumberError: "",
        entityNestedControlEntityPhoneNumber: "",
        entityNestedControlEntityPhoneNumberError: "",
        entityNestedControlEntityAddressLine1: "",
        entityNestedControlEntityAddressLine1Error: "",
        entityNestedControlEntityAddressLine2: "",
        entityNestedControlEntityAddressLine2Error: "",
        entityNestedControlEntityCity: "",
        entityNestedControlEntityCityError: "",
        entityNestedControlEntityState: "",
        entityNestedControlEntityStateError: "",
        entityNestedControlEntityPostalCode: "",
        entityNestedControlEntityPostalCodeError: "",
        entityNestedControlEntityIsTrustManagementCompany: "",
        entityNestedControlEntityIsTrustManagementCompanyError: "",
        entityNestedControlEntityAddressType: "",
        entityNestedControlEntityAddressTypeError: "",
        entityNestedControlEntityCertificateOfFormation: "",
        entityNestedControlEntityCertificateOfFormationError: "",
        entityNestedControlEntityProofOfAddress: "",
        entityNestedControlEntityProofOfAddressError: "",
        entityNestedControlEntityControlPersonFirstName: "",
        entityNestedControlEntityControlPersonFirstNameError: "",
        entityNestedControlEntityControlPersonLastName: "",
        entityNestedControlEntityControlPersonLastNameError: "",
        entityNestedControlEntityControlPersonEmail: "",
        entityNestedControlEntityControlPersonEmailError: "",
        entityNestedControlEntityControlPersonIsUSBased: "",
        entityNestedControlEntityControlPersonIsUSBasedError: "",
        entityNestedControlEntityControlPersonIsUSCitizen: "",
        entityNestedControlEntityControlPersonIsUSCitizenError: "",
        entityNestedControlEntityControlPersonPhoneNumber: "",
        entityNestedControlEntityControlPersonPhoneNumberError: "",
        entityNestedControlEntityControlPersonPIIAvailable: "",
        entityNestedControlEntityControlPersonPIIAvailableError: "",
        entityNestedControlEntityControlPersonAddressLine1: "",
        entityNestedControlEntityControlPersonAddressLine1Error: "",
        entityNestedControlEntityControlPersonAddressLine2: "",
        entityNestedControlEntityControlPersonAddressLine2Error: "",
        entityNestedControlEntityControlPersonCity: "",
        entityNestedControlEntityControlPersonCityError: "",
        entityNestedControlEntityControlPersonState: "",
        entityNestedControlEntityControlPersonStateError: "",
        entityNestedControlEntityControlPersonPostalCode: "",
        entityNestedControlEntityControlPersonPostalCodeError: "",
        entityNestedControlEntityControlPersonTaxIDNumber: "",
        entityNestedControlEntityControlPersonTaxIDNumberError: "",
        entityNestedControlEntityControlPersonDOB: "",
        entityNestedControlEntityControlPersonDOBError: "",
        entityNestedControlEntityControlPersonPassportDocument: "",
        entityNestedControlEntityControlPersonPassportDocumentError: "",
        entityNestedControlEntityControlPersonProofOfAddress: "",
        entityNestedControlEntityControlPersonProofOfAddressError: "",
        entityNestedControlEntityControlPersonAddressType: "",
        entityNestedControlEntityControlPersonAddressTypeError: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
        percentOwned: "",
        percentOwnedError: "",
        citizenship: "",
        citizenshipError: "",
      },
    ]);
    setActiveTab(
      "Beneficial Owner #" + (relatedAccounts.length + 1).toString()
    );
  };

  const submit = async () => {
    let values = [...relatedAccounts];
    let requestData = [];
    var errors = false;
    setSubmissionInProgress(true);

    if (hasBeneficialOwners === "") {
      setHasBeneficialOwnersError("Please make a selection.");
      errors = true;
    }

    if (hasBeneficialOwners === true) {
      requestData = relatedAccounts.map((inputField, index) => {
        if (inputField.relatedPartyType === "Person") {
          if (inputField.firstName === "") {
            values[index]["firstNameError"] = "This field is required.";
            errors = true;
          }
          if (inputField.lastName === "") {
            values[index]["lastNameError"] = "This field is required.";
            errors = true;
          }
          if (inputField.email === "") {
            values[index]["emailError"] = "This field is required.";
            errors = true;
          } else if (!isEmailValid(inputField.email)) {
            values[index]["emailError"] = "Please enter a valid email.";
            errors = true;
          }
          //Add email validation
          if (inputField.phoneNumber === "") {
            values[index]["phoneNumberError"] =
              "Please enter a valid phone number.";
          } else if (inputField.phoneNumber.length < 7) {
            values[index][inputField.phoneNumberError] =
              "Please enter a valid phone number";
            errors = true;
          }
          if (inputField.addressLine1 === "") {
            values[index]["addressLine1Error"] = "This field is required.";
            errors = true;
          }
          if (inputField.city === "") {
            values[index]["cityError"] = "This field is required.";
            errors = true;
          }

          // if (inputField.citizenship !== "United States") {
          //   values[index]["citizenshipError"] = "Sorry, we only support US citizens at this time.";
          //   errors = true; }

          if (inputField.citizenship === "") {
            values[index]["citizenshipError"] =
              "Please enter your country of citizenship.";
            errors = true;
          }

          if (inputField.country === "United States") {
            if (inputField.state === "") {
              values[index]["stateError"] =
                "Please enter a state from the list.";
              errors = true;
            }
          }
          if (inputField.country === "") {
            values[index]["countryError"] =
              "Please enter a country from the list.";
            errors = true;
          } else if (!isValidCountry(inputField.country)) {
            values[index]["countryError"] =
              "Please enter a country from the list.";
            errors = true;
          }

          if (inputField.percentOwned === "") {
            values[index]["percentOwnedError"] = "This field is required";
            errors = true;
          }

          if (inputField.postalCode === "") {
            values[index]["postalCodeError"] = "This field is required.";
            errors = true;
          }

          if (inputField.domicileCountry === "") {
            values[index]["domicileCountryError"] =
              "Please enter a country from the list.";
            errors = true;
          } else if (!isValidCountry(inputField.domicileCountry)) {
            values[index]["domicileCountryError"] =
              "Please enter a country from the list.";
            errors = true;
          }

          if (inputField.dateOfBirth === "") {
            values[index]["dateOfBirthError"] = "This field is required.";
            errors = true;
          } else {
            const date = inputField.dateOfBirth;
            const notValid = !isDobValid(date);
            if (notValid) {
              values[index]["dateOfBirthError"] =
                "Please enter a valid date of birth.";
              errors = true;
            }
          }
          if (inputField.idNum === "") {
            values[index]["idNumError"] = "This field is required.";
            errors = true;
          } else if (
            inputField.domicileCountry === "United States" &&
            !isValidSSN(inputField.idNum)
          ) {
            errors = true;
            values[index]["idNumError"] = "Please enter a valid ssn.";
          }
          console.log(inputField.id);
          return {
            first_name: inputField.firstName,
            last_name: inputField.lastName,
            email: inputField.email,
            phone_number: inputField.phoneNumber,
            address_line_1: inputField.addressLine1,
            address_line_2: inputField.addressLine2,
            city: inputField.city,
            state:
              inputField.country === "United States"
                ? inputField.state.length !== 0
                  ? inputField.state
                  : ""
                : "",
            country: inputField.country,
            postal_code: inputField.postalCode,
            address_type: "RM",
            date_of_birth: inputField.dateOfBirth,
            id_type:
              inputField.domicileCountry === "United States" ? "SSN" : "PASS",
            id_number: inputField.idNum,
            domicile_country: inputField.domicileCountry,
            role: "BO",
            address2_line_1: "",
            address2_line_2: "",
            address2_city: "",
            address2_state: "",
            address2_country: "",
            address2_postal_code: "",
            address2_type: "",
            percent_ownership: inputField.percentOwned,
            citizenship: inputField.citizenship,
            ...(supplyPercentOwned && { account_id: inputField.id }),
          };
        } else {
          if (inputField.relatedPartyType === "Entity") {
            if (inputField.entityType === "") {
              errors = true;
              values[index]["entityTypeError"] = "This field is required.";
            }

            if (inputField.entityName === "") {
              errors = true;
              values[index]["entityNameError"] = "This field is required.";
            }

            if (inputField.entityPercentOwned === "") {
              errors = true;
              values[index]["entityPercentOwnedError"] =
                "This field is required.";
            } else if (parseInt(inputField.entityPercentOwned) === 0) {
              errors = true;
              values[index]["entityPercentOwnedError"] =
                "Percent owned must be a value greater than 0.";
            }

            if (inputField.entityStateOfIncorporation === "") {
              errors = true;
              values[index]["entityStateOfIncorporationError"] =
                "This field is required.";
            }

            if (
              inputField.entityType !== "LT" &&
              inputField.entityType !== "SM_LLC" &&
              inputField.entityTaxIDNumber === ""
            ) {
              errors = true;
              values[index]["entityTaxIDNumberError"] =
                "This field is required.";
            }

            if (inputField.entityPhoneNumber === "") {
              errors = true;
              values[index]["entityPhoneNumberError"] =
                "This field is required.";
            }

            if (inputField.entityAddressLine1 === "") {
              errors = true;
              values[index]["entityAddressLine1Error"] =
                "This field is required.";
            }

            if (inputField.entityCity === "") {
              errors = true;
              values[index]["entityCityError"] = "This field is required.";
            }

            if (inputField.entityState === "") {
              errors = true;
              values[index]["entityStateError"] = "This field is required.";
            }

            if (inputField.entityPostalCode === "") {
              errors = true;
              values[index]["entityPostalCodeError"] =
                "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "") {
              errors = true;
              values[index]["entityMailingAddressDifferentError"] =
                "This field is required.";
            }

            if (inputField.entityMailingAddressDifferent === "Yes") {
              if (inputField.entityMailingAddressLine1 === "") {
                errors = true;
                values[index]["entityMailingAddressLine1Error"] =
                  "This field is required.";
              }

              if (inputField.entityMailingCity === "") {
                errors = true;
                values[index]["entityMailingCityError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingState === "") {
                errors = true;
                values[index]["entityMailingStateError"] =
                  "This field is required.";
              }

              if (inputField.entityMailingPostalCode === "") {
                errors = true;
                values[index]["entityMailingPostalCodeError"] =
                  "This field is required.";
              }
            }

            // if (inputField.entityIsTrustManagementCompany === "") {
            //   errors = true;
            //   beneficialOwnerValues[index]["entityIsTrustManagementCompanyError"] =
            //     "This field is required.";
            // }

            if (inputField.entityType === "TRUST") {
              if (inputField.entityNestedControlEntityTrusteeType === "") {
                errors = true;
                values[index]["entityNestedControlEntityTrusteeTypeError"] =
                  "This field is required.";
              } else if (
                inputField.entityNestedControlEntityTrusteeType === "Person"
              ) {
                if (inputField.entityControlPersonFirstName === "") {
                  errors = true;
                  values[index]["entityControlPersonFirstNameError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonLastName === "") {
                  errors = true;
                  values[index]["entityControlPersonLastNameError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonEmail === "") {
                  errors = true;
                  values[index]["entityControlPersonEmailError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonPhoneNumber === "") {
                  errors = true;
                  values[index]["entityControlPersonPhoneNumberError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonIsUSBased === "") {
                  errors = true;
                  values[index]["entityControlPersonIsUSBasedError"] =
                    "This field is required.";
                } else if (inputField.entityControlPersonIsUSBased === "No") {
                  errors = true;
                  values[index]["entityControlPersonIsUSBasedError"] =
                    "We only support US-based individuals at this time.";
                }

                if (inputField.entityControlPersonIsUSCitizen === "") {
                  errors = true;
                  values[index]["entityControlPersonIsUSCitizenError"] =
                    "This field is required.";
                } else if (inputField.entityControlPersonIsUSCitizen === "No") {
                  errors = true;
                  values[index]["entityControlPersonIsUSCitizenError"] =
                    "We only support US citizens at this time.";
                }

                if (inputField.entityControlPersonAddressLine1 === "") {
                  errors = true;
                  values[index]["entityControlPersonAddressLine1Error"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonCity === "") {
                  errors = true;
                  values[index]["entityControlPersonCityError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonState === "") {
                  errors = true;
                  values[index]["entityControlPersonStateError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonPostalCode === "") {
                  errors = true;
                  values[index]["entityControlPersonPostalCodeError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonDOB === "") {
                  errors = true;
                  values[index]["entityControlPersonDOBError"] =
                    "This field is required.";
                } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                  errors = true;
                  values[index]["entityControlPersonDOBError"] =
                    "Please enter a valid dob.";
                }

                if (inputField.entityControlPersonTaxIDNumber === "") {
                  errors = true;
                  values[index]["entityControlPersonTaxIDNumberError"] =
                    "This field is required.";
                } else if (
                  !isValidSSN(inputField.entityControlPersonTaxIDNumber)
                ) {
                  errors = true;
                  values[index]["entityControlPersonTaxIDNumberError"] =
                    "Please enter a valid ssn.";
                }

                if (inputField.entityControlPersonPassportDocument === "") {
                  errors = true;
                  values[index]["entityControlPersonPassportDocumentError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonProofOfAddress === "") {
                  errors = true;
                  values[index]["entityControlPersonProofOfAddressError"] =
                    "This field is required.";
                }

                if (inputField.entityCertificateOfFormation === "") {
                  errors = true;
                  values[index]["entityCertificateOfFormationError"] =
                    "This field is required.";
                }

                if (inputField.entityProofOfAddress === "") {
                  errors = true;
                  values[index]["entityProofOfAddressError"] =
                    "This field is required.";
                }
              } else {
                if (inputField.entityNestedControlEntityType === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityTypeError"] =
                    "This field is required.";
                }

                if (inputField.entityNestedControlEntityName === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityNameError"] =
                    "This field is required.";
                }

                if (inputField.entityNestedControlEntityPercentOwned === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityPercentOwnedError"] =
                    "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityStateOfIncorporation ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityStateOfIncorporationError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityPhoneNumber === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityPhoneNumberError"] =
                    "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityTaxIDNumber === "" &&
                  inputField.entityNestedControlEntityType !== "SM_LLC"
                ) {
                  errors = true;
                  values[index]["entityNestedControlEntityTaxIDNumberError"] =
                    "This field is required.";
                }

                if (inputField.entityNestedControlEntityAddressLine1 === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityAddressLine1Error"] =
                    "This field is required.";
                }

                if (inputField.entityNestedControlEntityCity === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityCityError"] =
                    "This field is required.";
                }

                if (inputField.entityNestedControlEntityState === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityStateError"] =
                    "This field is required.";
                }

                if (inputField.entityNestedControlEntityPostalCode === "") {
                  errors = true;
                  values[index]["entityNestedControlEntityPostalCodeError"] =
                    "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityIsTrustManagementCompany ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityIsTrustManagementCompanyError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonFirstName ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonFirstNameError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonLastName ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonLastNameError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonEmail === ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonEmailError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonPhoneNumber ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonPhoneNumberError"
                  ] = "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonIsUSBased ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonIsUSBasedError"
                  ] = "This field is required.";
                } else if (
                  inputField.entityNestedControlEntityControlPersonIsUSBased ===
                  "No"
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonIsUSBasedError"
                  ] = "We only support US-based individuals at this time.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonIsUSCitizen ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonIsUSCitizenError"
                  ] = "This field is required.";
                } else if (
                  inputField.entityNestedControlEntityControlPersonIsUSCitizen ===
                  "No"
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonIsUSCitizenError"
                  ] = "We only support US citizens at this time.";
                }

                if (
                  inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityControlPersonPIIAvailableError"
                  ] = "This field is required.";
                } else if (
                  inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                  "Yes"
                ) {
                  if (
                    inputField.entityNestedControlEntityControlPersonTaxIDNumber ===
                    ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonTaxIDNumberError"
                    ] = "This field is required.";
                  } else if (
                    !isValidSSN(
                      inputField.entityNestedControlEntityControlPersonTaxIDNumber
                    )
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonTaxIDNumberError"
                    ] = "Please enter a valid ssn.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonDOB === ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonDOBError"
                    ] = "This field is required.";
                  } else if (
                    !isDobValid(
                      inputField.entityNestedControlEntityControlPersonDOB
                    )
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonDOBError"
                    ] = "Please enter a valid dob.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonAddressLine1 ===
                    ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonAddressLine1Error"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonCity === ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonCityError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonState ===
                    ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonStateError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonPostalCode ===
                    ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonPostalCodeError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonPassportDocument ===
                    ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonPassportDocumentError"
                    ] = "This field is required.";
                  }

                  if (
                    inputField.entityNestedControlEntityControlPersonProofOfAddress ===
                    ""
                  ) {
                    errors = true;
                    values[index][
                      "entityNestedControlEntityControlPersonProofOfAddressError"
                    ] = "This field is required.";
                  }
                }

                if (inputField.entityCertificateOfFormation === "") {
                  errors = true;
                  values[index]["entityCertificateOfFormationError"] =
                    "This field is required.";
                }

                if (inputField.entityProofOfAddress === "") {
                  errors = true;
                  values[index]["entityProofOfAddressError"] =
                    "This field is required.";
                }

                if (
                  inputField.entityNestedControlEntityCertificateOfFormation ===
                  ""
                ) {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityCertificateOfFormationError"
                  ] = "This field is required.";
                }

                if (inputField.entityNestedControlEntityProofOfAddress === "") {
                  errors = true;
                  values[index][
                    "entityNestedControlEntityProofOfAddressError"
                  ] = "This field is required.";
                }
              }
            } else {
              if (inputField.entityControlPersonFirstName === "") {
                errors = true;
                values[index]["entityControlPersonFirstNameError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonLastName === "") {
                errors = true;
                values[index]["entityControlPersonLastNameError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonEmail === "") {
                errors = true;
                values[index]["entityControlPersonEmailError"] =
                  "This field is required.";
              } else if (!isEmailValid(inputField.entityControlPersonEmail)) {
                errors = true;
                values[index]["entityControlPersonEmailError"] =
                  "Please enter a valid email.";
              }

              if (inputField.entityControlPersonPhoneNumber === "") {
                errors = true;
                values[index]["entityControlPersonPhoneNumberError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonIsUSBased === "") {
                errors = true;
                values[index]["entityControlPersonIsUSBasedError"] =
                  "This field is required.";
              } else if (inputField.entityControlPersonIsUSBased === "No") {
                errors = true;
                values[index]["entityControlPersonIsUSBasedError"] =
                  "We only support US-based individuals at this time.";
              }

              if (inputField.entityControlPersonIsUSCitizen === "") {
                errors = true;
                values[index]["entityControlPersonIsUSCitizenError"] =
                  "This field is required.";
              } else if (inputField.entityControlPersonIsUSCitizen === "No") {
                errors = true;
                values[index]["entityControlPersonIsUSCitizenError"] =
                  "We only support US citizens at this time.";
              }

              if (inputField.entityControlPersonPIIAvailable === "") {
                errors = true;
                values[index]["entityControlPersonPIIAvailableError"] =
                  "This field is required.";
              }

              if (inputField.entityControlPersonPIIAvailable === "Yes") {
                if (inputField.entityControlPersonAddressLine1 === "") {
                  errors = true;
                  values[index]["entityControlPersonAddressLine1Error"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonCity === "") {
                  errors = true;
                  values[index]["entityControlPersonCityError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonState === "") {
                  errors = true;
                  values[index]["entityControlPersonStateError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonPostalCode === "") {
                  errors = true;
                  values[index]["entityControlPersonPostalCodeError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonDOB === "") {
                  errors = true;
                  values[index]["entityControlPersonDOBError"] =
                    "This field is required.";
                } else if (!isDobValid(inputField.entityControlPersonDOB)) {
                  errors = true;
                  values[index]["entityControlPersonDOBError"] =
                    "Please enter a valid dob.";
                }

                if (inputField.entityControlPersonTaxIDNumber === "") {
                  errors = true;
                  values[index]["entityControlPersonTaxIDNumberError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonPassportDocument === "") {
                  errors = true;
                  values[index]["entityControlPersonPassportDocumentError"] =
                    "This field is required.";
                }

                if (inputField.entityControlPersonProofOfAddress === "") {
                  errors = true;
                  values[index]["entityControlPersonProofOfAddressError"] =
                    "This field is required.";
                }
              }

              if (inputField.entityCertificateOfFormation === "") {
                errors = true;
                values[index]["entityCertificateOfFormationError"] =
                  "This field is required.";
              }

              if (inputField.entityProofOfAddress === "") {
                errors = true;
                values[index]["entityProofOfAddressError"] =
                  "This field is required.";
              }
            }

            return {
              rp_type: "Entity",
              role: "BO",
              entity_type: inputField.entityType,
              entity_name: inputField.entityName,
              entity_percent_owned: inputField.entityPercentOwned,
              entity_state_of_incorporation:
                inputField.entityStateOfIncorporation,
              ...(inputField.entityType !== "LT" &&
                inputField.entityType !== "SM_LLC" && {
                  entity_tax_id_number: inputField.entityTaxIDNumber,
                }),
              entity_phone_number: stripOutDigits(inputField.entityPhoneNumber),
              entity_address_line_1: inputField.entityAddressLine1,
              entity_address_line_2: inputField.entityAddressLine2,
              entity_city: inputField.entityCity,
              entity_state: inputField.entityState,
              entity_postal_code: inputField.entityPostalCode,
              address_type:
                inputField.entityMailingAddressDifferent === "Yes" ? "R" : "RM",
              entity_mailing_address_line_1:
                inputField.entityMailingAddressLine1,
              entity_mailing_address_line_2:
                inputField.entityMailingAddressLine2,
              entity_mailing_city: inputField.entityMailingCity,
              entity_mailing_state: inputField.entityMailingState,
              entity_mailing_postal_code: inputField.entityMailingPostalCode,
              ...(inputField.entityType !== "TRUST" && {
                entity_control_person_first_name:
                  inputField.entityControlPersonFirstName,
                entity_control_person_last_name:
                  inputField.entityControlPersonLastName,
                entity_control_person_email:
                  inputField.entityControlPersonEmail,
                entity_control_person_phone_number: stripOutDigits(
                  inputField.entityControlPersonPhoneNumber
                ),
                entity_control_person_is_us_based:
                  inputField.entityControlPersonIsUSBased,
                entity_control_person_is_us_citizen:
                  inputField.entityControlPersonIsUSCitizen,
                ...(inputField.entityControlPersonPIIAvailable === "Yes"
                  ? {
                      entity_control_person_address_line_1:
                        inputField.entityControlPersonAddressLine1,
                      entity_control_person_address_line_2:
                        inputField.entityControlPersonAddressLine2,
                      entity_control_person_city:
                        inputField.entityControlPersonCity,
                      entity_control_person_state:
                        inputField.entityControlPersonState,
                      entity_control_person_postal_code:
                        inputField.entityControlPersonPostalCode,
                      entity_control_person_dob:
                        inputField.entityControlPersonDOB,
                      entity_control_person_tax_id_number:
                        inputField.entityControlPersonTaxIDNumber,
                    }
                  : {}),
                documents: [
                  {
                    category: "Entity Proof of Address",
                    b64_string: inputField.entityProofOfAddress.b64_string,
                    name: "Entity Proof of Address",
                  },
                  {
                    category: "Entity Certificate of Formation",
                    b64_string:
                      inputField.entityCertificateOfFormation.b64_string,
                    name: "Entity Certificate of Formation",
                  },
                  ...(inputField.entityControlPersonPIIAvailable === "Yes"
                    ? [
                        {
                          category: "Entity Control Person Passport Document",
                          b64_string:
                            inputField.entityControlPersonPassportDocument
                              .b64_string,
                          name: "Entity Control Person Passport Document",
                        },
                        {
                          category: "Entity Control Person Proof of Address",
                          b64_string:
                            inputField.entityControlPersonProofOfAddress
                              .b64_string,
                          name: "Entity Control Person Proof of Address",
                        },
                      ]
                    : []),
                ],
              }),
              ...(inputField.entityType === "TRUST" && {
                ...(inputField.entityNestedControlEntityTrusteeType ===
                  "Person" && {
                  entity_control_person_first_name:
                    inputField.entityControlPersonFirstName,
                  entity_control_person_last_name:
                    inputField.entityControlPersonLastName,
                  entity_control_person_email:
                    inputField.entityControlPersonEmail,
                  entity_control_person_phone_number: stripOutDigits(
                    inputField.entityControlPersonPhoneNumber
                  ),
                  entity_control_person_is_us_based:
                    inputField.entityControlPersonIsUSBased,
                  entity_control_person_is_us_citizen:
                    inputField.entityControlPersonIsUSCitizen,
                  entity_control_person_address_line_1:
                    inputField.entityControlPersonAddressLine1,
                  entity_control_person_address_line_2:
                    inputField.entityControlPersonAddressLine2,
                  entity_control_person_city:
                    inputField.entityControlPersonCity,
                  entity_control_person_state:
                    inputField.entityControlPersonState,
                  entity_control_person_postal_code:
                    inputField.entityControlPersonPostalCode,
                  entity_control_person_dob: inputField.entityControlPersonDOB,
                  entity_control_person_tax_id_number:
                    inputField.entityControlPersonTaxIDNumber,
                  documents: [
                    {
                      category: "Entity Proof of Address",
                      b64_string: inputField.entityProofOfAddress.b64_string,
                      name: "Entity Proof of Address",
                    },
                    {
                      category: "Entity Certificate of Formation",
                      b64_string:
                        inputField.entityCertificateOfFormation.b64_string,
                      name: "Entity Certificate of Formation",
                    },

                    {
                      category: "Entity Control Person Passport Document",
                      b64_string:
                        inputField.entityControlPersonPassportDocument
                          .b64_string,
                      name: "Entity Control Person Passport Document",
                    },
                    {
                      category: "Entity Control Person Proof of Address",
                      b64_string:
                        inputField.entityControlPersonProofOfAddress.b64_string,
                      name: "Entity Control Person Proof of Address",
                    },
                  ],
                }),
                ...(inputField.entityNestedControlEntityTrusteeType ===
                  "Entity" && {
                  nested_rp_type: "Entity",
                  nested_role: "TST",
                  nested_entity_type: inputField.entityNestedControlEntityType,
                  nested_entity_name: inputField.entityNestedControlEntityName,
                  nested_entity_state_of_incorporation:
                    inputField.entityNestedControlEntityStateOfIncorporation,
                  nested_entity_percent_owned:
                    inputField.entityNestedControlEntityPercentOwned,
                  nested_entity_phone_number: stripOutDigits(
                    inputField.entityNestedControlEntityPhoneNumber
                  ),
                  nested_entity_tax_id_number:
                    inputField.entityNestedControlEntityTaxIDNumber,
                  nested_entity_address_type: "RM",
                  nested_entity_address_line_1:
                    inputField.entityNestedControlEntityAddressLine1,
                  nested_entity_address_line_2:
                    inputField.entityNestedControlEntityAddressLine2,
                  nested_entity_city: inputField.entityNestedControlEntityCity,
                  nested_entity_state:
                    inputField.entityNestedControlEntityState,
                  nested_entity_postal_code:
                    inputField.entityNestedControlEntityPostalCode,
                  nested_entity_is_trust_management_company:
                    inputField.entityNestedControlEntityIsTrustManagementCompany,
                  nested_entity_mailing_address_line_1: "",
                  nested_entity_mailing_address_line_2: "",
                  nested_entity_mailing_city: "",
                  nested_entity_mailing_state: "",
                  nested_entity_mailing_postal_code: "",
                  nested_entity_control_person_first_name:
                    inputField.entityNestedControlEntityControlPersonFirstName,
                  nested_entity_control_person_last_name:
                    inputField.entityNestedControlEntityControlPersonLastName,
                  nested_entity_control_person_email:
                    inputField.entityNestedControlEntityControlPersonEmail,
                  nested_entity_control_person_phone_number: stripOutDigits(
                    inputField.entityNestedControlEntityControlPersonPhoneNumber
                  ),
                  nested_entity_control_person_is_us_based:
                    inputField.entityNestedControlEntityControlPersonIsUSBased,
                  nested_entity_control_person_is_us_citizen:
                    inputField.entityNestedControlEntityControlPersonIsUSCitizen,
                  nested_entity_control_person_address_line_1:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonAddressLine1
                      : "",
                  nested_entity_control_person_address_line_2:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityAddressLine2
                      : "",
                  nested_entity_control_person_city:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonCity
                      : "",
                  nested_entity_control_person_state:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonState
                      : "",
                  nested_entity_control_person_postal_code:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonPostalCode
                      : "",
                  nested_entity_control_person_dob:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonDOB
                      : "",
                  nested_entity_control_person_tax_id_number:
                    inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? inputField.entityNestedControlEntityControlPersonTaxIDNumber
                      : "",
                  documents: [
                    {
                      category: "Entity Proof of Address",
                      b64_string: inputField.entityProofOfAddress.b64_string,
                      name: "Entity Proof of Address",
                    },
                    {
                      category: "Entity Certificate of Formation",
                      b64_string:
                        inputField.entityCertificateOfFormation.b64_string,
                      name: "Entity Certificate of Formation",
                    },
                  ],
                  nested_documents: [
                    {
                      category: "Trustee Certificate of Formation",
                      b64_string:
                        inputField
                          .entityNestedControlEntityCertificateOfFormation
                          .b64_string,
                      name: "Trust Certificate of Formation",
                    },
                    {
                      category: "Trustee Proof of Address",
                      b64_string:
                        inputField.entityNestedControlEntityProofOfAddress
                          .b64_string,
                      name: "Trust Proof of Address",
                    },
                    ...(inputField.entityNestedControlEntityControlPersonPIIAvailable ===
                    "Yes"
                      ? [
                          {
                            category:
                              "Trustee Entity Control Person Passport Document",
                            b64_string:
                              inputField
                                .entityNestedControlEntityControlPersonPassportDocument
                                .b64_string,
                            name: "Trustee Entity Control Person Passport Document",
                          },
                          {
                            category:
                              "Trustee Entity Control Person Proof of Address",
                            b64_string:
                              inputField
                                .entityNestedControlEntityControlPersonProofOfAddress
                                .b64_string,
                            name: "Trustee Entity Control Person Proof of Address",
                          },
                        ]
                      : []),
                  ],
                }),
              }),
            };
          }
        }
      });
      setRelatedAccounts(values);
    }

    // if (relatedAccounts.length >= 1) {
    if (errors) {
      // setRelatedAccounts(values);
      setSubmissionInProgress(false);
      return;
    } else {
      // make request to backend with grantor and trustee information here

      const requestInformation = {
        data: requestData,
        account_id: accountID,
        has_related_party: hasBeneficialOwners,
      };
      try {
        await updateRelatedPartyInformation(requestInformation);

        setHideButton(true);
        setHideAlterButtons(true);
        // Make sure that the active slide is progressed to the next page on success. Don't want the user to be able to adjust the information that they supplied.
        setActiveSlide((prevState) => prevState + 2);
      } catch (err) {
        console.log(err);
        setSubmissionInProgress(false);
      }
    }
  };

  const handleRemoveFields = (index) => {
    const nextIdx = index === 0 ? 0 : index - 1;
    setActiveTab("Beneficial Owner #" + (nextIdx + 1).toString());
    setRelatedAccounts((relatedAccounts) =>
      relatedAccounts.filter((elem, idx) => idx !== index)
    );
  };

  useEffect(() => {
    if (hasBeneficialOwners === true) {
      setShowBeneficialOwnerSection(true);
    } else if (hasBeneficialOwners === false) {
      setShowBeneficialOwnerSection(false);
    }
    setHasBeneficialOwnersError("");
  }, [hasBeneficialOwners]);

  return (
    <div className="row g-0 w-100 add-account-wizard-container">
      <div className="col-12 add-account-outer-column-padded">
        <BeneficialOwnerSection
          shouldRenderBeneficialOwnerSection={
            shouldRenderBeneficialOwnerSection
          }
          showBeneficialOwnerSection={showBeneficialOwnerSection}
          setShowBeneficialOwnerSection={setShowBeneficialOwnerSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          answer={hasBeneficialOwners}
          setAnswer={setHasBeneficialOwners}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          addNewTab={supplyPercentOwned ? false : addNewTab}
          accountID={accountID}
          relatedAccounts={relatedAccounts}
          setRelatedAccounts={setRelatedAccounts}
          handleRemoveFields={handleRemoveFields}
          setActiveSlide={setActiveSlide}
          hideButton={hideButton}
          setHideButton={setHideButton}
          hideAlterButtons={hideAlterButtons}
          setHideAlterButtons={setHideAlterButtons}
          hasBeneficialOwnersError={hasBeneficialOwnersError}
          setHasBeneficialOwnersError={setHasBeneficialOwnersError}
          supplyPercentOwned={supplyPercentOwned}
          accountType={accountType}
        />
        {!hideButton && (
          <div className="row g-0 w-100">
            <div className="col">
              <div className="d-flex">
                <button
                  type="button"
                  class="btn btn-lg btn-class"
                  onClick={() => {
                    submit();
                  }}
                >
                  {submissionInProgress && (
                    <div className="row g-0 w-100">
                      <div className="col d-flex align-items-center">
                        <span className="justify-self-start align-self-center">
                          Submit
                        </span>
                        <span
                          class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  )}
                  {!submissionInProgress && <span>Submit</span>}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default EntityAccountRelatedPartyInformation;
