import React, { useState, useEffect } from "react";
import "./Button.css";

function Button({ text }) {
  const [buttonText, setButtonText] = useState("");

  useEffect(() => {
    setButtonText(text);
  }, [text]);
  return (
    <div className="button-box">
      <span className="button-text">{buttonText}</span>
    </div>
  );
}
export default Button;
