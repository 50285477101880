import React, { useState, useEffect, useContext } from "react";
import SelectField from "../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import EntityAccountBasicInformationQualificationSection from "./EntityAccountBasicInformationQualificationSection/EntityAccountBasicInformationQualificationSection";
import EntityAccountBasicInformationButton from "./EntityAccountBasicInformationButton/EntityAccountBasicInformationButton";
import { getDateUTC } from "../../../../../../GlobalFunctions/globalFunctions.js";
import { newAccount, getAccountList } from "../../../../../../Api/ProfileApi";
import { UserContext } from "../../../../../../GlobalContexts/UserContext";

function EntityAccountBasicInformation({
  setAccountID,
  accountType,
  shouldRenderEntityQualificationSection,
  showEntityQualificationSection,
  mountedStyle,
  unmountedStyle,
  legalAccountName,
  setLegalAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  processedCountryList,
  entityQualificationQ1,
  setEntityQualificationQ1,
  entityQualificationQ2,
  setEntityQualificationQ2,
  entityQualificationQ3,
  setEntityQualificationQ3,
  entityQualificationQ4,
  setEntityQualificationQ4,
  entityQualificationQ5,
  setEntityQualificationQ5,
  setActiveSlide,
}) {
  const { user, setUser } = useContext(UserContext);
  const [
    entityAccountBasicInformationQualificationQuestionError,
    setEntityAccountBasicInformationQualificationQuestionError,
  ] = useState("");

  const [qualificationDate, setQualificationDate] = useState("");
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  useEffect(() => {
    setEntityAccountBasicInformationQualificationQuestionError("");
  }, [
    entityQualificationQ1,
    entityQualificationQ2,
    entityQualificationQ3,
    entityQualificationQ4,
    entityQualificationQ5,
  ]);

  const isEntityAccountQualifiedPurchaser = (q1, q2, q3, q4, q5) => {
    var isQualifiedPurchaser = false;
    if (!q1) {
      if (q2 && q3) {
        isQualifiedPurchaser = true;
        let utcDate = getDateUTC();
        setQualificationDate(utcDate);
      } else if (q2 && q4) {
        isQualifiedPurchaser = true;
        let utcDate = getDateUTC();
        setQualificationDate(utcDate);
      } else if (!q3 && !q4 && q5) {
        isQualifiedPurchaser = true;
        let utcDate = getDateUTC();
        setQualificationDate(utcDate);
      } else if (q3 && !q4 && q5) {
        isQualifiedPurchaser = true;
        let utcDate = getDateUTC();
        setQualificationDate(utcDate);
      } else {
        isQualifiedPurchaser = false;
        setQualificationDate("");
      }
    } else {
      isQualifiedPurchaser = false;
      setQualificationDate("");
    }

    return isQualifiedPurchaser;
  };

  const submitEntityAccountBasicInformation = async () => {
    var errors = false;
    setSubmissionInProgress(true);

    if (legalAccountName === "") {
      errors = true;
      setLegalAccountNameError("This field is required.");
    }

    if (countryOfFormation === "") {
      errors = true;
      setCountryOfFormationError("This field is required.");
    }

    if (
      entityQualificationQ1 === "" ||
      entityQualificationQ2 === "" ||
      entityQualificationQ3 === "" ||
      entityQualificationQ4 === "" ||
      entityQualificationQ5 === ""
    ) {
      errors = true;
      setEntityAccountBasicInformationQualificationQuestionError(
        "Please answer all qualification questions."
      );
    } else if (
      !isEntityAccountQualifiedPurchaser(
        entityQualificationQ1,
        entityQualificationQ2,
        entityQualificationQ3,
        entityQualificationQ4,
        entityQualificationQ5
      )
    ) {
      errors = true;
      setEntityAccountBasicInformationQualificationQuestionError(
        "Sorry, your entity doesn't qualify as a qualified purchaser (QP). At this time, we only allow QPs on Aqua, so you will be unable to add this account."
      );
    }

    if (!errors) {
      // Code to make request to the backend goes here
      const newAccountResp = await newAccount({
        account_type: "ENT",
        qualification_date: getDateUTC(),
        entity_q1_qp_specific_purpose_invest_fund: entityQualificationQ1,
        entity_q2_qp_5m_investments: entityQualificationQ2,
        entity_q3_qp_owned_by_family: entityQualificationQ3,
        entity_q4_qp_25m_investments: entityQualificationQ4,
        entity_q5_qp_all_owners_qp: entityQualificationQ5,
        entity_legal_name: legalAccountName,
        entity_type: accountType,
        country_of_formation: countryOfFormation,
      });
      setAccountID(newAccountResp.data.detail);
      const accountList = await getAccountList();
      setUser((user) => ({ ...user, accountListData: accountList.data }));

      setActiveSlide((prevState) => prevState + 1);
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  return (
    shouldRenderEntityQualificationSection && (
      <React.Fragment>
        <div className="row g-0 w-100 mb-3">
          <div className="col-6 pe-2">
            <SelectField
              value={countryOfFormation}
              setValue={setCountryOfFormation}
              valueError={countryOfFormationError}
              setValueError={setCountryOfFormationError}
              selectOptions={processedCountryList}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"formation-country-select"}
              label={"Country of Formation"}
            />
          </div>
        </div>
        <EntityAccountBasicInformationQualificationSection
          showEntityQualificationSection={showEntityQualificationSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          entityAccountBasicInformationQualificationQuestionError={
            entityAccountBasicInformationQualificationQuestionError
          }
          entityQualificationQ1={entityQualificationQ1}
          setEntityQualificationQ1={setEntityQualificationQ1}
          entityQualificationQ2={entityQualificationQ2}
          setEntityQualificationQ2={setEntityQualificationQ2}
          entityQualificationQ3={entityQualificationQ3}
          setEntityQualificationQ3={setEntityQualificationQ3}
          entityQualificationQ4={entityQualificationQ4}
          setEntityQualificationQ4={setEntityQualificationQ4}
          entityQualificationQ5={entityQualificationQ5}
          setEntityQualificationQ5={setEntityQualificationQ5}
        />
        <EntityAccountBasicInformationButton
          loading={submissionInProgress}
          showEntityQualificationSection={showEntityQualificationSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          onClick={() => submitEntityAccountBasicInformation()}
        />
      </React.Fragment>
    )
  );
}
export default EntityAccountBasicInformation;
