import React, { useState, useEffect, useContext } from "react";
import "./SendQuestionSectionStyles/SendQuestionSectionStyles.css";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { UserContext } from "../../../../../../GlobalContexts/UserContext.js";

function SendQuestionSection({ fund }) {
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const { user } = useContext(UserContext);
  init(process.env.REACT_APP_EMAILJS_USER_ID);

  useEffect(() => {
    setSubjectError("");
  }, [subject]);

  useEffect(() => {
    setMessageError("");
  }, [message]);

  const submitMessage = async () => {
    var errorsDetected = false;

    if (subject.length === 0) {
      setSubjectError("Subject is required.");
      errorsDetected = true;
    }

    if (message.length === 0) {
      setMessageError("Message is required.");
      errorsDetected = true;
    }

    if (errorsDetected) {
      return;
    } else {
      try {
        await emailjs.send("service_kyyw60a", "template_2b08rzj", {
          subject_line: subject,
          fund_name: fund ? fund.fundName : "",
          from_name: user
            ? user.investorSummaryData.first_name +
              " " +
              user.investorSummaryData.last_name
            : "",
          message: message,
          reply_to: user ? user.id : "aquaplatformbeta@gmail.com",
        });
        setSubject("");
        setMessage("");
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
  };

  return (
    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="row g-0">
        <div className="col-12 learn-more-header-text">
          Do you have any additional questions?
        </div>
        <div className="col-12 mt-3 learn-more-subheader-text">
          Please email us using the form below
        </div>
        <div className="col-12 mt-4 mb-3 learn-more-smallHeader-text">
          Subject
        </div>
        <div className="col-md-6 col-12">
          <div class="input-group">
            <div className="row g-0 w-100">
              <input
                type="text"
                class="form-control form-font"
                placeholder="Subject"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {subjectError && subjectError !== "This field is required." && (
                <div className="question-field-error-text mt-1">
                  <span className="">{subjectError}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 mb-4">
          <div class="form-group">
            <label
              for="exampleFormControlTextarea1"
              className="learn-more-smallHeader-text mb-3"
            >
              Message
            </label>
            <textarea
              class="form-control form-font"
              id="exampleFormControlTextarea1"
              placeholder="Your message goes here."
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            {messageError && messageError !== "This field is required." && (
              <div className="question-field-error-text mt-1">
                <span className="">{messageError}</span>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex">
          <button
            type="button"
            class="btn btn-lg btn-class"
            onClick={async () => submitMessage()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
export default SendQuestionSection;
