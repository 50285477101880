import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { getRequestHeaders } from "../GlobalFunctions/RequestHeaders.js";
import useDebounce from "../../CustomHooks/useDebounce.js";
import { HOST } from "../../host-config.js";
import { getSignedURL } from "./SignedURL.js";
import { UserContext } from "../../GlobalContexts/UserContext.js";

const CancelToken = axios.CancelToken;

//Dev environment function
export default function useFundSearch(
  searchKeywords,
  fundMinimumAllocation,
  status,
  strategyType,
  setLoading
) {
  const [funds, setFunds] = useState([]);
  const cancelSource = useRef(null);
  const debouncedSearchTerm = useDebounce(searchKeywords, 200);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setFunds([]);
  }, []);

  useEffect(() => {
    setFunds([]);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setFunds([]);
  }, [status]);

  useEffect(() => {
    setFunds([]);
  }, [fundMinimumAllocation]);

  useEffect(() => {
    setFunds([]);
  }, [strategyType]);

  useEffect(() => {
    cancelSource.current = CancelToken.source();
    setFunds([]);
    setLoading(true);
    const queryParams = {};
    let fundStrategies = {
      "Private Equity": "PRIVATE_EQUITY",
      "Direct Landing": "DIRECT_LANDING",
      "CLO Management": "CLO_MANAGEMENT",
      "Structured Credit": "STRUCTURED_CREDIT",
      "Co-Investment": "CO_INVESTMENT",
      "Fund of Funds": "FUND_OF_FUNDS",
      Buyout: "BUYOUT",
      Growth: "GROWTH",
      Credit: "CREDIT",
    };
    queryParams.search_keyword = debouncedSearchTerm;

    if (status !== "All") {
      queryParams.fund_investment_status = status;
    }

    if (fundMinimumAllocation !== "") {
      queryParams.fund_minimum_allocation = fundMinimumAllocation;
    }

    if (
      user &&
      user.accountListData.length !== 0 &&
      user.selectedAccountID !== ""
    ) {
      queryParams.account_id = user.selectedAccountID;
    }

    if (strategyType !== "All") {
      if (process.env.REACT_APP_DEBUG === "1") {
        console.log(fundStrategies[strategyType]);
      }
      queryParams.strategy_type = fundStrategies[strategyType];
    }

    async function getFunds(endPoint, queryParams, config) {
      setLoading(true);
      return axios
        .post(endPoint, queryParams, config)
        .then((response) => {
          const tempObject = response.data;

          if (response.data === null) {
            setFunds([]);
            setLoading(false);
          } else {
            setFunds([]);
            tempObject.map((fund) => {
              async function getUsableURLs() {
                console.log("Fund to get is: " + JSON.stringify(fund));


                const fundInfo = {
                  id: fund.id,
                  link: fund.link,
                  fundName: fund.name ? fund.name : "ABCDE Fund IV",
                  logoLink: fund ? (fund.logo_link ? fund.logo_link : "") : "",
                  fundLogoLink: fund.fund_logo ? fund.fund_logo : "",
                  investorInterested:
                    fund.investor_fund_interested === null
                      ? false
                      : fund.investor_fund_interested,
                  firm: fund.firm ? fund.firm : "Thamesville Securities",
                  strategy: fund.strategy_type
                    ? fund.strategy_type
                    : "PRIVATE_EQUITY",
                  fundRestrictions: fund.restrictions
                    ? fund.restrictions
                    : "Nothing",
                  fundTerms: fund.fund_terms[0],
                  fundSize: fund.fund_terms[0].fund_size
                    ? fund.fund_terms[0].fund_size
                    : 2000000,
                  fundLength: 120,
                  fundInvestmentStatus: fund.investment_status,
                  amountInvested: fund.fund_subscription_stats[0]
                    .committed_allocation_amount
                    ? fund.fund_subscription_stats[0]
                        .committed_allocation_amount
                    : 0,
                  minimumInvestment: fund.fund_terms[0]
                    .minimum_allocation_amount
                    ? fund.fund_terms[0].minimum_allocation_amount
                    : 0,
                  targetIRR: fund.fund_terms[0].target_return_upper_percent
                    ? fund.fund_terms[0].target_return_upper_percent
                    : 19,
                };
                setFunds((prevFunds) => [...prevFunds, fundInfo]);
                setLoading(false);
              }
              getUsableURLs();
            });
          }
        })
        .catch((error) => {
          setFunds([]);
          setLoading(false);
          if (process.env.REACT_APP_DEBUG === "1") console.log(error);
        });
    }
    getRequestHeaders().then((config) => {
      config.cancelToken = cancelSource.current.token;
      getFunds(HOST + "/ultra-fast-search-funds/", queryParams, config).then();
    });

    return () => {
      cancelSource.current.cancel();
    };
  }, [debouncedSearchTerm, status, fundMinimumAllocation, strategyType, user]);

  return { funds };
}
