import React from "react";

function NoRelatedParties({role}) {
  return (
    <div className="container-xxl d-flex align-self-center justify-self-center align-items-center justify-content-center text-center">
      <span className="align-self-center funds-not-found-text pb-3" style={{fontSize: "16px"}}>
        Sorry, there were no { role === "Beneficial Owner" ? "beneficial owners" : "trustees"}.
      </span>
    </div>
  );
}
export default NoRelatedParties;
