import React from "react";
import DownArrowCircleButton from "../../../../../../Components/GlobalComponents/DownArrowCircleButton/DownArrowCircleButton";
import "./PhoneInvestmentCardMainSectionStyles/PhoneInvestmentCardMainSectionStyles.css";

function PhoneInvestmentCardMainSection({selected, setSelected, investment}) {
    function investmentStatusFormatter(investmentStatus) {
        if (investmentStatus !== undefined && investmentStatus !== null) {
          let investmentStatusMap = {
            P: "Pending Approval",
            A: "Approved",
            R: "Rejected",
          };
          return investmentStatusMap[investmentStatus];
        }
        return "";
      }
    
    function moneyFormatter(amount) {
        if (amount === undefined || amount === null) {
          return "$ 0";
        }
        var integer = parseInt(amount.toString(), 10);
        return "$ " + integer.toLocaleString("en-US");
    }

    return (
        <React.Fragment>
            <div className="col-12 investment-card">
                <div className="row g-0 w-100 pt-3 mb-3">
                    <div className="col-sm-2 col-3 justify-self-start">
                        <img src="./ThamesvilleLogo.svg" className="ms-3" height="40" alt="logo"></img>
                    </div>
                    <div className="col justify-self-start">
                        <div className="d-flex align-items-center h-100">
                            <span className="investment-card-fund-name-text">{investment ? investment.fundName : ""}</span>
                        </div>
                    </div>
                    <div className="col justify-self-end">
                        <DownArrowCircleButton selected={selected} setSelected={setSelected}/>
                    </div>
                </div>
                <div className="row g-0 w-100 mb-2">
                    <div className="col-12">
                        <div className="row g-0 w-100 mb-1">
                            <div className="col-6 ps-3 pe-1">
                                <span className="investment-card-subsection-header text-wrap">Investment:</span>
                            </div>
                            <div className="col-6">
                                <span className="investment-card-subsection-value text-wrap">{investment ? moneyFormatter(investment.investmentAmount) : ""}</span>
                            </div>
                        </div>
                        <div className="row g-0 w-100 mb-1">
                            <div className="col-6 ps-3 pe-1">
                                <span className="investment-card-subsection-header text-wrap">Distributed:</span>
                            </div>
                            <div className="col-6">
                                <span className="investment-card-subsection-value text-wrap">{"$0"}</span>
                            </div>
                        </div>
                        <div className="row g-0 w-100 mb-1">
                            <div className="col-6 ps-3 pe-1">
                                <span className="investment-card-subsection-header text-wrap">Opportunity Stage:</span>
                            </div>
                            <div className="col-6">
                                <span className="investment-card-subsection-value text-wrap">{investment ? investment.opportunityStatus : ""}</span>
                            </div>
                        </div>
                        <div className="row g-0 w-100 mb-1">
                            <div className="col-6 ps-3 pe-1">
                                <span className="investment-card-subsection-header text-wrap">Investment Stage:</span>
                            </div>
                            <div className="col-6">
                                <span className="investment-card-subsection-value text-wrap">{investment ? investmentStatusFormatter(investment.investmentStage) : ""}</span>
                            </div>
                        </div>
                        <div className="row g-0 w-100 pb-3">
                            <div className="col-6 ps-3 pe-1">
                                <span className="investment-card-subsection-header text-wrap">Returns:</span>
                            </div>
                            <div className="col-6">
                                <span className="investment-card-subsection-value text-wrap">{"N/A%"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PhoneInvestmentCardMainSection;