import { isValidElement } from "react";

export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isPasswordValid = (password) => {
  const re =
    /^(?=.{10,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]).*$/;
  return re.test(String(password));
};

export const isPhoneNumberValid = (phoneNumber) => {
  const re = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  return re.test(String(phoneNumber));
};

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatDateOfBirth = (value) => {
  if (!value) return value;

  const dob = value.replace(/[^\d]/g, "");

  const dobLength = dob.length;

  if (dobLength < 3) return dob;

  if (dobLength < 5) {
    return `${dob.slice(0, 2)}-${dob.slice(2)}`;
  }
  return `${dob.slice(0, 2)}-${dob.slice(2, 4)}-${dob.slice(4, 8)}`;
};

export const formatSSN = (value) => {
  if (!value) return value;

  const ssn = value.replace(/[^\d]/g, "");

  const ssnLength = ssn.length;

  if (ssnLength < 4) return ssn;

  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
};

export const getDateUTC = () => {
  var date = new Date();
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(now_utc);
};

export const formatInvestmentAmount = (value) => {
  if (!value) return value;
  if (value === "" || value === "$") {
    return "";
  }
  if (
    value.length === 1 &&
    value !== "0" &&
    value !== "1" &&
    value !== "2" &&
    value !== "3" &&
    value !== "4" &&
    value !== "5" &&
    value !== "6" &&
    value !== "7" &&
    value !== "8" &&
    value !== "9"
  ) {
    return "";
  }

  var investmentAmount = value.replace(/[^\d]/g, "");
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return "$" + parseInt(investmentAmount).toLocaleString("en-US");
};

export const reverseFormatNumber = (val, locale) => {
  var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
  var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
  var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
  reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
  return Number.isNaN(reversedVal) ? 0 : reversedVal;
};

export const isValidState = (state) => {
  let list = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Island",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  let isValidState = false;
  list.forEach((stateChoice) => {
    if (state === stateChoice) {
      isValidState = true;
    }
  });

  return isValidState;
};

export const isValidCountry = (country) => {
  let countryList = [
    "Afghanistan",
    "Åland Islands",
    // ('Afghanistan', 'Afghanistan'),
    // ('Åland Islands', 'Åland Islands'),
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua And Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    // "Bangladesh",
    "Barbados",
    // "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia, Plurinational State Of",
    "Bonaire, Sint Eustatius And Saba",
    "Bosnia And Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    // "Central African Republic",
    "Chad",
    "Chile",
    // "China",
    "Christmas Island",
    "Cocos (keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    // "Congo, The Democratic Republic Of The",
    "Cook Islands",
    "Costa Rica",
    "Côte D'ivoire",
    "Croatia",
    // "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    // "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-bissau",
    "Guyana",
    "Haiti",
    "Heard Island And Mcdonald Islands",
    "Holy See (vatican City State)",
    "Honduras",
    // "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    // "Iran, Islamic Republic Of",
    // "Iraq",
    "Ireland",
    "Isle Of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Republic Of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    // "Lebanon",
    "Lesotho",
    // "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, TheFormer Yugoslav Republic Of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    // "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States Of",
    "Moldova, Republic Of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    // "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    // "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State Of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension And Tristan Da Cunha",
    "Saint Kitts And Nevis",
    "Saint Lucia",
    "Saint Martin (french Part)",
    "Saint Pierre And Miquelon",
    "Saint Vincent And The Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome And Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (dutch Part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    // "Somalia",
    "South Africa",
    "South Georgia And The South Sandwich Islands",
    // "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard And Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Taiwan, Province Of China",
    "Tajikistan",
    "Tanzania, United Republic Of",
    "Thailand",
    "Timor-leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad And Tobago",
    "Tunisia",
    "Turkmenistan",
    "Turks And Caicos Islands",
    "Tuvalu",
    "Uganda",
    // "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    // "Venezuela, Bolivarian Republic Of",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.s.",
    "Wallis And Futuna",
    "Western Sahara",
    // "Yemen",
    "Zambia",
    // "Zimbabwe",
  ];

  let isValidCountryChoice = false;
  countryList.forEach((countryChoice) => {
    if (country === countryChoice) {
      isValidCountryChoice = true;
    }
  });

  return isValidCountryChoice;
};

export const isValidTrustType = (type) => {
  let trustTypes = ["Irrevocable Trust", "Revocable Trust"];
  let isValid = false;

  trustTypes.forEach((typeChoice) => {
    if (type === typeChoice) {
      isValid = true;
    }
  });

  return isValid;
};

export const isValidEntityType = (type) => {
  let entityTypes = [
    "LLC",
    "Limited Partnership",
    "C Corporation",
    "S Corporation",
    "General Partnership",
    "Foreign Entity",
    "Single-Member LLC"
  ];
  let isValid = false;

  entityTypes.forEach((typeChoice) => {
    if (type === typeChoice) {
      isValid = true;
    }
  });

  return isValid;
};
