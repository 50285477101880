import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Styles/Styles.scss";
import GetInvestorTermsOfAgreement from "../../../../../../../Adapters/TermsOfAgreementAdapters";
import StateBasedButton from "../../../../../../../GlobalComponents/Button/StateBasedButton/StateBasedButton";
import CheckBox from "../../../../../../../Components/GlobalComponents/CheckBox";
import MuiButton from "../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import AlertSnackBar from "../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import { useRecoilState } from "recoil";
import {
  termsAgreedVal,
  termsAgreementIdVal,
} from "../../../../../../../atoms";

function TermsOfUse({ setActiveSlide }) {
  const [documentHasBeenRead, setDocumentHasBeenRead] = useState(false);
  const [termsAgreed, setTermsAgreed] = useRecoilState(termsAgreedVal);
  const [termsAgreementId, setTermsAgreementId] =
    useRecoilState(termsAgreementIdVal);
  const [checked, setChecked] = useState(termsAgreed);
  const [proceed, setProceed] = useState(false);
  const timeStamp = Date.now();
  const [time, setTime] = useState(timeStamp);
  const { investorTermsOfAgreement } = GetInvestorTermsOfAgreement(time);

  const [readAgreementError, setReadAgreementError] = useState(false);

  let history = useHistory();

  useEffect(() => {
    setTermsAgreementId(
      investorTermsOfAgreement ? investorTermsOfAgreement.id : ""
    );
  }, [investorTermsOfAgreement, setTermsAgreementId]);

  useEffect(() => {
    setChecked(termsAgreed);
    setProceed(termsAgreed);
  }, [termsAgreed]);

  useEffect(() => {
    if (documentHasBeenRead && checked) {
      setTermsAgreed(true);
      setProceed(true);
    } else if (!checked) {
      setProceed(false);
      setTermsAgreed(false);
    }
  }, [documentHasBeenRead, checked, setTermsAgreed]);

  var handleScroll = (e) => {
    const bottom = (e.target.scrollHeight - Math.round(e.target.scrollTop) - e.target.clientHeight) <= 100; 
    if (bottom && !documentHasBeenRead) {
      setDocumentHasBeenRead(true);
    }
  };

  let scrollWarning = (
    <span class="badge bg-white text-dark">
      Please Read and Agree Before Continuing
    </span>
  );

  const validatedProceed = () => {
    if (!proceed) {
      setReadAgreementError(true);
    }
  };

  return (
    <div className="container-fluid h-100 align-items-center g-0 ps-3 pe-3">
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col-12">
          <span className="body-text-md-semibold">Terms of Use Agreement</span>
        </div>
      </div>
      <div className="row g-0 w-100 mb-1">
        <div className="col-12">
          <div
            className="document-container"
            onScroll={(event) => handleScroll(event)}
          >
            <div className="container-fluid">
              <span
                className="document-text-content"
                dangerouslySetInnerHTML={{
                  __html: investorTermsOfAgreement ? (
                    investorTermsOfAgreement.agreement
                  ) : (
                    <p>Loading...</p>
                  ),
                }}
              ></span>
            </div>
          </div>
        </div>
        {!checked || !documentHasBeenRead ? scrollWarning : <span></span>}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col-11">
          <div className="row g-0 w-100 align-items-center">
            <div className="col-1">
              <div
                className=" ms-0 checkBox-container"
                onClick={() => {
                  if (documentHasBeenRead) {
                    setChecked(!checked);
                  } else if (checked) {
                    setChecked(false);
                  }

                  if (!documentHasBeenRead) {
                    setReadAgreementError(true);
                  }
                }}
              >
                <CheckBox selected={checked} />
              </div>
            </div>
            <div className="col-11">
              <span className="checkBox-label">
                I have read, understood, and agree to all terms noted in the{" "}
                <strong>Terms and Policies Agreement</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0 w-100 mb-5">
        <div className="col">
          <div className="d-flex" onClick={() => validatedProceed()}>
            <MuiButton
              buttonText={"Next step"}
              disabled={!proceed}
              onClick={() => {
                console.log("Clicked");
                setActiveSlide((curSlide) => curSlide + 1);
              }}
              size={"medium"}
            />
          </div>
        </div>
      </div>
      <AlertSnackBar
        snackbarState={readAgreementError}
        setSnackbarState={setReadAgreementError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Please read and agree to our terms of use before continuing."}
      />
    </div>
  );
}
export default TermsOfUse;
