import React from "react";
import EntityAccountBasicInformationQualificationQuestion from "./EntityAccountBasicInformationQualificationQuestion/EntityAccountBasicInformationQualificationQuestion.js";
import EntityAccountBasicInformationQPModal from "./EntityAccountBasicInformationQPModal/EntityAccountBasicInformationQPModal.js";
import EntityAccountBasicInformationQualificationError from "./EntityAccountBasicInformationQualificationError/EntityAccountBasicInformationQualificationError.js";

function EntityAccountBasicInformationQualificationSection({
  showEntityQualificationSection,
  mountedStyle,
  unmountedStyle,
  entityAccountBasicInformationQualificationQuestionError,
  entityQualificationQ1,
  setEntityQualificationQ1,
  entityQualificationQ2,
  setEntityQualificationQ2,
  entityQualificationQ3,
  setEntityQualificationQ3,
  entityQualificationQ4,
  setEntityQualificationQ4,
  entityQualificationQ5,
  setEntityQualificationQ5,
}) {
  return (
    <React.Fragment>
      <div
        className="row g-0 w-100 mb-2 fade-in-test"
        style={showEntityQualificationSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-step-subheader">
          Qualification Questions
        </span>
      </div>
      <div
        className="row g-0 w-100 mb-4"
        style={showEntityQualificationSection ? mountedStyle : unmountedStyle}
      >
        <span className="add-account-qualification-subheader pe-1">
          Please confirm the account's{" "}
          <span style={{ fontWeight: 600 }}>Qualified Purchaser</span> status
        </span>
      </div>
      <EntityAccountBasicInformationQualificationQuestion
        showEntityQualificationSection={showEntityQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={1}
        questionText={
          "Was the entity formed for the specific purpose of investing in a particular fund?"
        }
        answer={entityQualificationQ1}
        setAnswer={setEntityQualificationQ1}
      />
      <EntityAccountBasicInformationQualificationQuestion
        showEntityQualificationSection={showEntityQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={2}
        questionText={"Does the entity have at least $5M in investments?"}
        answer={entityQualificationQ2}
        setAnswer={setEntityQualificationQ2}
      />
      <EntityAccountBasicInformationQualificationQuestion
        showEntityQualificationSection={showEntityQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={3}
        questionText={
          "Is the entity owned directly or indirectly by 2 or more natural persons who are related as siblings or spouse (including former spouses), or direct lineal descendants by birth or adoption, or spouses of such persons, the estates of such persons, or foundations, charitable organizations, or trusts established by or for the benefit of such persons?"
        }
        answer={entityQualificationQ3}
        setAnswer={setEntityQualificationQ3}
      />
      <EntityAccountBasicInformationQualificationQuestion
        showEntityQualificationSection={showEntityQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={4}
        questionText={
          "Does the entity have more than $25M in investments that are invested on its own account or on behalf of other individuals?"
        }
        answer={entityQualificationQ4}
        setAnswer={setEntityQualificationQ4}
      />
      <EntityAccountBasicInformationQualificationQuestion
        showEntityQualificationSection={showEntityQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
        questionNumber={5}
        questionText={
          "Are all beneficial owners of the entity qualified purchasers (*Definition below)?"
        }
        answer={entityQualificationQ5}
        setAnswer={setEntityQualificationQ5}
      />
      <EntityAccountBasicInformationQPModal
        showEntityQualificationSection={showEntityQualificationSection}
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
      <EntityAccountBasicInformationQualificationError
        entityAccountBasicInformationQualificationQuestionError={
          entityAccountBasicInformationQualificationQuestionError
        }
        mountedStyle={mountedStyle}
        unmountedStyle={unmountedStyle}
      />
    </React.Fragment>
  );
}
export default EntityAccountBasicInformationQualificationSection;
