import React, { useState, useEffect } from "react";
import Header from "../../Components/GlobalComponents/Header.js";
import LoadingAnimation from "../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation";

import { fetchCapitalCalls } from "./../../Api/CapitalCallApi.js";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import Chip from "@mui/material/Chip";
import Modal from "react-bootstrap/Modal";

import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";


const MuiButton = ({ buttonText, onClick, size, loading }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      className="Button"
      disableElevation
      size={size}
      disabled={loading}
    >
      <span className="body-text-sm-medium">{buttonText}</span>
      {loading && <CircularProgress color={"primary"} size={"14px"} />}
    </Button>
  );
};

function moneyFormatter(amount) {
  if (amount === undefined || amount === null) {
    return "$ 0";
  }
  var integer = parseInt(amount.toString(), 10);
  return "$ " + integer.toLocaleString("en-US");
}

function investmentStatusFormatter(value) {
  if (value !== undefined && value !== null) {
    let investmentStatusMap = {
      P: "Pending Approval",
      PD: "Pending Sub Docs",
      PF: "Pending Funding",
      F: "Funded",
      A: "Approved",
      R: "Rejected",
    };
    return investmentStatusMap[value.toString()];
  }
  return "";
}

function statusFormatter(value) {
  if (value !== undefined && value !== null) {
    let statusMap = {
      X: "Partial Fulfillment",
      D: "Delinquent",
      F: "Fulfilled",
      P: "Pending Fulfillment",
      O: "Overfunded",
      L: "Late"
    };
    return statusMap[value.toString()];
  }
  return "";
}

function CapitalCallsTable({ rows, modalOpen, setModalOpen, setSelected, selected }) {

  const apiRef = useGridApiRef();
  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "fundName",
      headerName: "Opportunity Name",
      headerClassName: "stylish-header",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "account",
      headerClassName: "stylish-header",
      headerName: "Account Number",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "capitalPaidIn",
      headerClassName: "stylish-header",
      headerName: "Paid In Capital",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "status",
      headerClassName: "stylish-header",
      headerName: "Status",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "amount",
      headerClassName: "stylish-header",
      headerName: "Amount",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fulfilledOn",
      headerClassName: "stylish-header",
      headerName: "Fulfilled On",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fulfillBy",
      headerClassName: "stylish-header",
      headerName: "Fulfill By",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      headerClassName: "stylish-header",
      headerName: "Wire Instructions",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      field: "showWire",
      renderCell: (params) => {
        return (
          <MuiButton
            size="large"
            buttonText={"Show"
            }
            onClick={() => {
              setSelected(params.row);
              setModalOpen(true);
              apiRef.current.toggleDetailPanel(params.id);
            }}
          />
        );
      },
    }
  ];

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      initialState={{
        columns: {
          columnVisibilityModel: {
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            id: false,
          },
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
    />
  )
};


function WireCard({ props }) {
  return (
    <div className="row g-0 w-100 mb-1">
      <div className="col-12 p-3">
        <div className="border-container">
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Account Number:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.accNum} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Routing Number:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.routNum} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Bank Name:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.bankName} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">Bank Address:</div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.bankAddress} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">
                    Beneficiary Name:
                  </div>
                  <div>
                    <div className="d-flex">
                      <Chip label={props.beneficiaryName} variant="outlined" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-4">
            <div className="bottom-divider-border" />
          </div>
          <div className="row g-0 w-100">
            <div className="col-12">
              <div className="row g-0 w-100 align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div className="body-text-sm-bold me-3">
                    Beneficiary Address:
                  </div>
                  <div>
                    <div className="d-flex">
                      <Chip
                        label={props.beneficiaryAddress}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b className="ps-3">Wire instructions are unique for each client’s investment.</b>
    </div>
  );
}


function CapitalCalls({ }) {

  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState();
  useEffect(() => {
    // Fetch capital call

    async function fetchData() {
      const resp = await fetchCapitalCalls();
      console.log(resp.data.data);
      var formattedRowData = Object.keys(resp.data.data).map((item, idx) => {
        return {
          id: idx,
          ...resp.data.data[item],
          status: statusFormatter(resp.data.data[item].status),
          amount: moneyFormatter(resp.data.data[item].amount),
          capitalPaidIn: moneyFormatter(resp.data.data[item].capitalPaidIn),
          fulfillBy:
            resp.data.data[item].fulfillBy
              ? resp.data.data[item].fulfillBy.slice(0, 10)
              : "N/A",
          fulfilledOn:
            resp.data.data[item].fulfilledOn
              ? resp.data.data[item].fulfilledOn
              : "N/A",
        };
      });
      setData(formattedRowData);
      console.log(formattedRowData);
      setLoading(false);

    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h5>Wire Instructions</h5>
        </Modal.Header>
        <Modal.Body>
          <WireCard props={selected} />
        </Modal.Body>
        <Modal.Footer>
          <MuiButton
            size="large"
            buttonText={"Close"}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </Modal.Footer>
      </Modal>
      <Header activePage={"capital-calls"}></Header>{" "}
      <div className="container-xxl">
        <div className="container-fluid w-90">
          <div className="row g-0 w-100 mt-4 mb-4 d-flex justify-content-center">
            <div className="row g-0 w-100">
              <div className="col-12 pt-5 mt-5">
                <div className="row g-0 w-100 mb-4 mt-5">
                  <div>
                    {data && data.length !== 0 ? (
                      <div className="d-flex justify-content-center" style={{ "backgroundColor": "white", "borderRadius": "10px", "paddingLeft": "0 !important" }}>
                        <CapitalCallsTable rows={data} modalOpen={modalOpen} setModalOpen={setModalOpen} setSelected={setSelected} />
                      </div>
                    ) : (!loading ?
                      <>
                        <div className="container-xxl d-flex align-self-center justify-self-center align-items-center justify-content-center text-center">
                          <span className="align-self-center funds-not-found-text">
                            No capital calls were found.
                          </span>
                        </div>
                      </> : <LoadingAnimation loading={true} />
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CapitalCalls;
