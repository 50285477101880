import React from "react";
import "../Styles/Styles.scss";
import { Modal } from "react-bootstrap";

function AccreditedInvestorModal({ show, setShowModal }) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontFamily: "Montserrat" }}
        >
          Accredited Investor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="regulatory-info-text pe-5 pb-5">
          <b>Accredited Investor: </b>
          (51) (A) “Accredited Investor” means— (i) a bank, savings and loan
          association, insurance company, registered investment company,
          business development company, or small business investment company or
          rural business investment company (ii) an SEC-registered
          broker-dealer, SEC- or state-registered investment adviser, or exempt
          reporting adviser (iii) a plan established and maintained by a state,
          its political subdivisions, or any agency or instrumentality of a
          state or its political subdivisions, for the benefit of its employees,
          if such plan has total assets in excess of $5 million (iv) an employee
          benefit plan (within the meaning of the Employee Retirement Income
          Security Act) if a bank, insurance company, or registered investment
          adviser makes the investment decisions, or if the plan has total
          assets in excess of $5 million (v) a tax exempt charitable
          organization, corporation, limited liability corporation, or
          partnership with assets in excess of $5 million (vi) a director,
          executive officer, or general partner of the company selling the
          securities, or any director, executive officer, or general partner of
          a general partner of that company (vii) an enterprise in which all the
          equity owners are accredited investors (viii) an individual with a net
          worth or joint net worth with a spouse or spousal equivalent of at
          least $1 million, not including the value of his or her primary
          residence (ix) an individual with income exceeding $200,000 in each of
          the two most recent calendar years or joint income with a spouse or
          spousal equivalent exceeding $300,000 for those years and a reasonable
          expectation of the same income level in the current year or (x) a
          trust with assets exceeding $5 million, not formed only to acquire the
          securities offered, and whose purchases are directed by a person who
          meets the legal standard of having sufficient knowledge and experience
          in financial and business matters to be capable of evaluating the
          merits and risks of the prospective investment (xi) an entity of a
          type not otherwise qualifying as accredited that own investments in
          excess of $5 million (xii) an individual holding in good standing any
          of the general securities representative license (Series 7), the
          investment adviser representative license (Series 65), or the private
          securities offerings representative license (Series 82) (xiii) a
          knowledgeable employee, as defined in rule 3c-5(a)(4) under the
          Investment Company Act, of the issuer of securities where that issuer
          is a 3(c)(1) or 3(c)(7) private fund or (xiv) a family office and its
          family clients if the family office has assets under management in
          excess of $5 million and whose prospective investments are directed by
          a person who has such knowledge and experience in financial and
          business matters that such family office is capable of evaluating the
          merits and risks of the prospective investment
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-lg btn-class justify-self-start"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Got it
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AccreditedInvestorModal;
