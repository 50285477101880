import { useState, useEffect, useContext } from "react";
import { getUserStatements } from "../Api/DocumentsApi.js";
import { Storage } from "aws-amplify";
import * as dayjs from "dayjs";
import { UserContext } from "../GlobalContexts/UserContext.js";
// var utc = require("dayjs/plugin/utc");

export default function GetAccountStatement(time) {
  const [accountStatements, setAccountStatements] = useState([]);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    async function getUserAccountStatementsObject() {
      try {
        const response = await getUserStatements();
        // console.log(JSON.stringify(response.data));
        const userAccountStatementsData = response.data;

        const sortableStatementData = userAccountStatementsData.map((item) => {
          let date = new Date(item.year, item.month - 1);
          let unixDate = dayjs(date.toString()).unix();
          let formattedDate = dayjs.unix(unixDate).format("MMM, YYYY");
          return {
            id: item.id,
            name: item.name,
            category: item.category,
            upload_date: item.upload_date,
            year: item.year,
            month: item.month,
            unixDate: unixDate,
            documentDate: formattedDate,
            s3_document_link: item.s3_document_link,
            display_document: item.display_document,
            viewed: "Unread",
          };
        });

        sortableStatementData.sort((a, b) => {
          if (a.unixDate > b.unixDate) {
            return -1;
          }
          if (a.unixDate < b.unixDate) {
            return 1;
          }
          return 0;
        });

        sortableStatementData.forEach((item) => {
          let formattedDate = dayjs.unix(item.unixDate).format("MMM, YYYY");
          async function getSignedURL() {

            setAccountStatements((prevState) => [
              ...prevState,
              {
                id: item.id,
                documentName: item.name,
                documentType: item.category,
                accountName: item.account_name, 
                uploadDate: item.upload_date,
                year: item.year,
                month: item.month,
                documentDate: formattedDate,
                documentLink: item.s3_document_link,
                display: item.display_document,
                viewed: "Unread",
              },
            ]);
          }
          getSignedURL();
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err); }
      }
    }
    getUserAccountStatementsObject();
  }, [time]);
  return { accountStatements };
}
