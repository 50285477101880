import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import "./Styles/Styles.scss";

function SydecarMessagingModal({
  showSydecarMessagingModal,
  setShowSydecarMessagingModal,
  setRefresh,
  accountID,
}) {
  let history = useHistory();

  return (
    <Modal
      show={showSydecarMessagingModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={() => setShowSydecarMessagingModal(false)}
      onEntered={() => console.log("Entered.")}
      onExited={() => setRefresh((prevState) => prevState + 1)}
    >
      <Modal.Body closeButton>
        <Modal.Header>
          <div className="d-flex align-items-center">
            <span className="modal-title-font">Thanks for Investing!</span>
          </div>
        </Modal.Header>
        <div className="col-12">
          <div className="row g-0 w-100 mt-4 justify-content-center">
            <p className="modal-body-text">
              {
                "Your investment has been created in our system. We're now working on verifying your account. In most instances, we'll be able to verify your account in the next few minutes, but sometimes it can take a bit longer."
              }
            </p>
            <p className="modal-body-text">
              {
                "Once your account is verified, you will receive a link to sign your subscription documents via email. If you don't get anything in the next 24 hours, please contact us via email. In the meantime, feel free to continue browsing opportunities on Aqua."
              }
            </p>
          </div>
          <div className="row g-0 w-100">
            <span className="contact-us-text">
              Please reach out to{" "}
              <a href="mailto: ops@investwithaqua.com">
                admin@investwithaqua.com
              </a>{" "}
              if you have any questions.
            </span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row g-0 w-100">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-lg btn-class justify-self-start"
              onClick={() => {
                history.push("/invest");
              }}
            >
              Browse Opportunities
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default SydecarMessagingModal;
