import React from "react";
import "./DocumentBoxStyles/DocumentBoxStyles.css";

function DocumentBox({ documentName, signedLink, displayDocument }) {
  return (
    <div className="col-lg-4 col-sm-6 col-12">
      <div
        className="row g-0 h-100 document-box"
        onClick={() => window.open(signedLink, "_blank").focus()}
      >
        <div className="row g-0 ps-3 pt-2 pb-2">
          <div className="col-8 pe-1 d-flex align-items-center document-title-text always-border-right">
            {documentName}
          </div>
          <div className="col-4 d-flex align-items-center justify-content-center">
            <img src="https://d1swyfveypj49y.cloudfront.net/download.svg" alt="download"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DocumentBox;
