import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Modal from "react-bootstrap/Modal";
import "./KycInProgressModalStyles/KycInProgressModalStyles.scss";
import { pollKYC } from "../../../../Api/ConfirmInvestmentApi/ConfirmInvestmentApi";
import { poll } from "../../../../Functions/functions.js";

const LoadingSpinnerAnimation = () => {
  return (
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const PendingReviewMessage = () => {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 justify-content-center mt-3">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/research.svg"
          style={{ maxWidth: "125px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-3 justify-content-center">
        <p className="modal-body-text">
          {
            "Sorry, your account was not automatically verified and will be manually reviewed by our team within 24-48 hrs."
          }
        </p>
        <p className="modal-body-text">
          {
            " You will receive a notification once your account is successfully verified and your subscription documents are ready to sign."
          }
        </p>
      </div>
      <div className="row g-0 w-100">
        <span className="contact-us-text">
          Reach out to{" "}
          <a href="mailto: ops@investwithaqua.com">ops@investwithaqua.com</a> if
          you have any questions.
        </span>
      </div>
    </React.Fragment>
  );
};

const SuccessfulVerificationMessage = () => {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 justify-content-center mt-4">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/green-check.svg"
          style={{ maxWidth: "75px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <p className="modal-body-text">
          {
            "Congrats, your account was successfully verified, and your investment was approved. Please proceed to review and sign subscription documents."
          }
        </p>
      </div>
      <div className="row g-0 w-100">
        <span className="contact-us-text">
          Reach out to{" "}
          <a href="mailto: ops@investwithaqua.com">ops@investwithaqua.com</a> if
          you have any questions.
        </span>
      </div>
    </React.Fragment>
  );
};

const FailedVerificationMessage = () => {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 justify-content-center mt-4">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/error-icon.svg"
          style={{ maxWidth: "75px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <p className="modal-body-text">
          {
            "Your account failed our KYC/AML checks, so your investments will unfortunately not be approved."
          }
        </p>
      </div>
      <div className="row g-0 w-100">
        <span className="contact-us-text">
          If you think this is a mistake or would like further clarificiation,
          reach out to{" "}
          <a href="mailto: ops@investwithaqua.com">ops@investwithaqua.com</a>.
        </span>
      </div>
    </React.Fragment>
  );
};

const RetrievalErrorMessage = () => {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 justify-content-center mt-4">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/error-icon.svg"
          style={{ maxWidth: "75px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <p className="modal-body-text">
          {
            "There was an error retrieving your account verification status. We'll take a look at what went wrong and reach out if we need more information from you in order to verify your account."
          }
        </p>
        <p className="modal-body-text">
          {
            "Once your account is verified, your investment will be approved and you will be able to sign your subscription documents. In the meantime, feel free to continue browsing opportunities on Aqua."
          }
        </p>
      </div>
      <div className="row g-0 w-100">
        <span className="contact-us-text">
          Please reach out to{" "}
          <a href="mailto: ops@investwithaqua.com">ops@investwithaqua.com</a> if
          you have any questions.
        </span>
      </div>
    </React.Fragment>
  );
};

const ResponseTakingTooLongMessage = () => {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 justify-content-center mt-4">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/clock.svg"
          style={{ maxWidth: "100px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <p className="modal-body-text">
          {
            "It's taking longer than usual to verify your account. We'll continue verifying your account on our end and approve your investment and share subscription documents once your account is verified."
          }
        </p>
        <p className="modal-body-text">
          {
            "In the meantime, feel free to continue browsing opportunities on Aqua."
          }
        </p>
      </div>
      <div className="row g-0 w-100">
        <span className="contact-us-text">
          Please reach out to{" "}
          <a href="mailto: ops@investwithaqua.com">ops@investwithaqua.com</a> if
          you have any questions.
        </span>
      </div>
    </React.Fragment>
  );
};

function KycInProgressModal({
  showKycInProgress,
  setShowKycInProgress,
  setRefresh,
  accountID,
}) {
  let history = useHistory();

  const [pollKYCResponse, setPollKYCResponse] = useState("NF");

  const pollKycEndpoint = async (requestCount) => {
    return await pollKYC(accountID, requestCount);
  };

  const runPolling = () => {
    try {
      const response = poll({
        fn: pollKycEndpoint,
        interval: 1000,
        maxAttempts: 10,
        setResultState: setPollKYCResponse,
      });
    } catch (error) {
      console.log("Run polling failed.");
      console.log("Error is: " + error);
    }
  };

  return (
    <Modal
      show={showKycInProgress}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      onHide={() => setShowKycInProgress(false)}
      onEntered={() => runPolling()}
      onExited={() => setRefresh((prevState) => prevState + 1)}
    >
      <Modal.Body closeButton>
        <Modal.Header>
          <div className="d-flex align-items-center">
            <span className="modal-title-font">
              {pollKYCResponse === "NF"
                ? "Please wait while your account is being verified"
                : pollKYCResponse === "F"
                ? "Your account failed verification"
                : pollKYCResponse === "P"
                ? "Your account is pending review"
                : pollKYCResponse === "S"
                ? "Your account was successfully verified"
                : pollKYCResponse === "E"
                ? "There was an error retrieving your verification status"
                : pollKYCResponse === "MR"
                ? "Sorry, this is taking longer than usual"
                : ""}
            </span>
            {pollKYCResponse === "P" && (
              <span className="ms-2 pending-review-indicator" />
            )}
            {pollKYCResponse === "S" && (
              <span className="ms-2 successfully-verified-indicator" />
            )}
            {pollKYCResponse === "F" && (
              <span className="ms-2 failed-verification-indicator" />
            )}
          </div>
        </Modal.Header>
        <div className="col-12">
          {pollKYCResponse === "NF" && (
            <div className="row g-0 w-100 justify-content-center mb-3 mt-4">
              <LoadingSpinnerAnimation />
            </div>
          )}
          {pollKYCResponse === "P" && <PendingReviewMessage />}
          {pollKYCResponse === "S" && <SuccessfulVerificationMessage />}
          {pollKYCResponse === "F" && <FailedVerificationMessage />}
          {pollKYCResponse === "E" && <RetrievalErrorMessage />}
          {pollKYCResponse === "MR" && <ResponseTakingTooLongMessage />}
        </div>
      </Modal.Body>
      {pollKYCResponse !== "NF" && (
        <Modal.Footer>
          <div className="row g-0 w-100">
            <div className="d-flex justify-content-end">
              {pollKYCResponse === "S" && (
                <button
                  type="button"
                  class="btn btn-lg btn-class justify-self-start"
                  onClick={() => {
                    setShowKycInProgress(false);
                  }}
                >
                  Proceed
                </button>
              )}
              {pollKYCResponse === "P" ||
                pollKYCResponse === "MR" ||
                (pollKYCResponse === "E" && (
                  <button
                    type="button"
                    class="btn btn-lg btn-class justify-self-start"
                    onClick={() => {
                      history.push("/invest");
                      setShowKycInProgress(false);
                    }}
                  >
                    Browse Opportunities
                  </button>
                ))}
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
export default KycInProgressModal;
