import { useState, useEffect, useContext } from "react";
import { getUserFundDocuments } from "../Api/DocumentsApi.js";
import { Storage } from "aws-amplify";
import * as dayjs from "dayjs";
import { UserContext } from "../GlobalContexts/UserContext.js";
// var utc = require("dayjs/plugin/utc");

export default function GetUserFundDocuments(time) {
  const [userFundDocuments, setUserFundDocuments] = useState([]);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    async function getUserFundDocumentsObject() {
      try {
        const response = await getUserFundDocuments();
        const userFundDocumentsData = response.data;

        const sortableUserFundDocumentsData = userFundDocumentsData.map(
          (item) => {
            let unixUserFundDocumentDateNoTime = dayjs(
              dayjs(item.upload_date).format("MMM D, YYYY")
            );
            let unixUserFundDocumentDate = dayjs(
              unixUserFundDocumentDateNoTime
            ).unix();

            let formattedDate = dayjs
              .unix(unixUserFundDocumentDate)
              .format("MMM, YYYY");
            return {
              id: item.id,
              name: item.name,
              category: item.category,
              account_name: item.account_name,
              upload_date: item.upload_date,
              unixDate: unixUserFundDocumentDate,
              documentDate: formattedDate,
              s3_document_link: item.s3_document_link,
              display_document: item.display_document,
              viewed: "Unread",
            };
          }
        );

        sortableUserFundDocumentsData.sort((a, b) => {
          if (a.unixDate > b.unixDate) {
            return -1;
          }
          if (a.unixDate < b.unixDate) {
            return 1;
          }
          return 0;
        });

        sortableUserFundDocumentsData.forEach((item) => {
          let formattedDate = dayjs.unix(item.unixDate).format("MMM, YYYY");
          let X = item.s3_document_link;
          let Y = "private/";
          let Z = X.slice(item.s3_document_link.indexOf(Y) + Y.length);
          let Q = "/";
          let A = Z;
          let intermediaryUrl = Z.slice(A.indexOf(Q) + Q.length);
          async function getSignedURL() {
            var tempURL = await Storage.get(intermediaryUrl, {
              level: "private",
            });

            setUserFundDocuments((prevState) => [
              ...prevState,
              {
                id: item.id,
                documentName: item.name,
                documentType: item.category,
                accountName: item.account_name, 
                uploadDate: item.upload_date,
                documentDate: formattedDate,
                documentLink: item.s3_document_link,
                display: item.display_document,
                viewed: "Unread",
              },
            ]);
          }
          getSignedURL();
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("There is an error.");
          console.error(err); }
      }
    }
    getUserFundDocumentsObject();
  }, [time]);
  return { userFundDocuments };
}
