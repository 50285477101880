import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./InvestPageHeaderStyles/InvestPageHeaderStyles.css";
import Stepper from "react-stepper-horizontal";
import GetAccountQualificationVerification from "../../../../Adapters/GetAccountQualificationVerification";
import CompleteAccountComponent from "../../../../Components/GlobalComponents/CompleteAccountComponent/CompleteAccountComponent";
import AccountPicker from "../../../../GlobalComponents/AccountPicker/AccountPicker";
import GetAccountListAdapter from "../../../../Adapters/GetAccountListAdapter";
import { UserContext } from "../../../../GlobalContexts/UserContext";

function InvestPageHeader() {
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(0);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [accountID, setAccountID] = useState("");
  const [steps, setSteps] = useState([]);
  const [progressMessage, setProgressMessage] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accountPickerLoading, setAccountPickerLoading] = useState(true);
  const { accountQualificationVerification } =
    GetAccountQualificationVerification(refresh, setLoading);

  const { accounts } = GetAccountListAdapter(refresh, setAccountPickerLoading);

  const [accountType, setAccountType] = useState("");
  const [investorProfileComplete, setInvestorProfileComplete] = useState("");
  const [entityComplete, setEntityComplete] = useState("");

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") console.log("Accounts are: " + JSON.stringify(accounts));
  }, [accounts]);

  useEffect(() => {
    if (Object.keys(accountQualificationVerification).length === 0) {
      return;
    } else {
      if (process.env.REACT_APP_DEBUG === "1") console.log(
        "Account qualification verification is: " +
          JSON.stringify(accountQualificationVerification)
      );
      var show = false;
      setAccountID(accountQualificationVerification.id);
      setAccountType(accountQualificationVerification.accountType);

      setInvestorProfileComplete(
        accountQualificationVerification.investorProfileComplete
      );
      setEntityComplete(accountQualificationVerification.entityComplete);

      if (
        accountQualificationVerification.accountType === "IND" &&
        !accountQualificationVerification.investorProfileComplete
      ) {
        show = true;
      } else if (
        accountQualificationVerification.accountType !== "IND" &&
        (!accountQualificationVerification.investorProfileComplete ||
          !accountQualificationVerification.entityComplete)
      ) {
        show = true;
      }
      if (process.env.REACT_APP_DEBUG === "1") console.log("Show is: " + show);
      setShowProgressBar(show);
    }
  }, [accountQualificationVerification, user]);

  return (
    <React.Fragment>
      {showProgressBar && (
        <div className="row g-0 w-100 mt-5 mb-3 pe-3 justify-content-end">
          {!loading && (
            <CompleteAccountComponent
              accountType={accountType}
              investorProfileComplete={investorProfileComplete}
              entityComplete={entityComplete}
              id={accountID}
            />
          )}
        </div>
      )}
      <div
        className={
          showProgressBar
            ? "row g-0 w-100 ps-3 pe-3 mb-4 align-items-center"
            : "row g-0 w-100 ps-3 pe-3 mt-5 mb-4 align-items-center"
        }
      >
        <div className="col-md-4 col-12">
          <div className="row g-0 w-100 mb-3">
            <span className="title-text">Invest</span>
          </div>
          <div className="row g-0 w-100">
            <span className="subtitle-text">Browse Current Opportunities</span>
          </div>
        </div>
        <div className="col-md-8 col-12">
          {!accountPickerLoading && (
            <div className="row g-0 w-100 mt-md-0 mt-3 justify-content-end">
              <AccountPicker accounts={accounts} width={350} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default InvestPageHeader;
