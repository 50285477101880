import React from "react";
import Alert from "@mui/material/Alert";

function EntityAccountBasicInformationQualificationError({
  entityAccountBasicInformationQualificationQuestionError,
  mountedStyle,
  unmountedStyle,
}) {
  return (
    entityAccountBasicInformationQualificationQuestionError !== "" && (
      <div
        className="row g-0 w-100 mt-3 fade-in-test"
        style={
          entityAccountBasicInformationQualificationQuestionError
            ? mountedStyle
            : unmountedStyle
        }
      >
        <Alert severity="error">
          {entityAccountBasicInformationQualificationQuestionError}
        </Alert>
      </div>
    )
  );
}
export default EntityAccountBasicInformationQualificationError;
