import React, { useEffect, useState } from "react";
import PhoneInvestmentCardMainSection from "./PhoneInvestmentCardMainSection/PhoneInvestmentCardMainSection";
import PhoneInvestmentCardDetailedInvestmentSection from "./PhoneInvestmentCardDetailedInvestmentSection/PhoneInvestmentCardDetailedInvestmentSection";
import "./PhoneInvestmentCardStyles/PhoneInvestmentCardStyles.css";

function PhoneInvestmentCard ({investment}) {
    const [selected, setSelected] = useState(false);
    
    return (
        <React.Fragment>
            <div className="row g-0 w-100 mb-3">
                <PhoneInvestmentCardMainSection selected={selected} setSelected={setSelected} investment={investment}/>
                <div className="mt-3">
                    <PhoneInvestmentCardDetailedInvestmentSection selected={selected} investment={investment}/>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PhoneInvestmentCard;