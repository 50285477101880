import React from "react";
import "./StateButtonLoadingStyles/StateButtonLoadingStyles.css";

function StateButtonLoading({ loading, proceed, onClick }) {
  return (
    <div className="d-flex">
      <button
        type="button"
        class="btn btn-lg btn-class"
        onClick={onClick}
        disabled={loading || !proceed}
      >
        {loading && (
          <div className="row g-0 w-100">
            <div className="col d-flex align-items-center">
              <span className="justify-self-start align-self-center">
                Confirm Code
              </span>
              <span
                class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        )}
        {!loading && <span>Confirm Code</span>}
      </button>
    </div>
  );
}
export default StateButtonLoading;
