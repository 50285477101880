import { useState, useEffect } from "react";
import { getInvestorTermsOfAgreement } from "../Api/SignupApi.js";

export default function GetInvestorTermsOfAgreement(time) {
  const [investorTermsOfAgreement, setInvestorTermsOfAgreement] =
    useState(null);

  useEffect(() => {
    async function getInvestorTermsOfAgreementObject() {
      try {
        const response = await getInvestorTermsOfAgreement();
        // console.log(response.data);
        const investorTermsOfAgreementData = response.data[0];
        setInvestorTermsOfAgreement({
          id: investorTermsOfAgreementData.id,
          version: investorTermsOfAgreementData.version,
          agreement: investorTermsOfAgreementData.agreement,
          status: investorTermsOfAgreementData.status,
          startDate: investorTermsOfAgreementData.startDate,
        });
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1")
          console.log("There is an error.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
    getInvestorTermsOfAgreementObject();
  }, [time]);
  return { investorTermsOfAgreement };
}
