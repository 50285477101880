import React from "react";
import IndividualAccountDetailsPII from "./IndividualAccountDetailsPII/IndividualAccountDetailsPII";
import IndividualAccountDetailsAddress from "./IndividualAccountDetailsAddress/IndividualAccountDetailsAddress";
import UpdateInfoInstructions from "./UpdateInfoInstructions/UpdateInfoInstructions";

function IndividualAccountDetailsPersonalInfo({
  joint,
  jointInfo,
  domicileCountry,
  dob,
  setDob,
  dobError,
  setDobError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
}) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "700px",
        paddingTop: "32px",
        paddingBottom: "32px",
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <div className="row g-0 w-100">
        <div className="col-12">
          <div className="row g-0 w-100 mb-4">
            <span className="individual-account-section-header-text">
              Personal Information { joint && <> - Primary Accountholder</>}
            </span>
          </div>
          <IndividualAccountDetailsPII
            domicileCountry={domicileCountry}
            dob={dob}
            setDob={setDob}
            dobError={dobError}
            setDobError={setDobError}
            idNumber={idNumber}
            setIDNumber={setIDNumber}
            idNumberError={idNumberError}
            setIDNumberError={setIDNumberError}
          />
          <IndividualAccountDetailsAddress
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine1Error={addressLine1Error}
            setAddressLine1Error={setAddressLine1Error}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine2Error={addressLine2Error}
            setAddressLine2Error={setAddressLine2Error}
            city={city}
            setCity={setCity}
            cityError={cityError}
            setCityError={setCityError}
            state={state}
            setState={setState}
            stateError={stateError}
            setStateError={setStateError}
            country={country}
            setCountry={setCountry}
            countryError={countryError}
            setCountryError={setCountryError}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
          />
        </div>

        {joint && 
        <div className="col-12">
          <div className="row g-0 w-100 mb-4">
            <span className="individual-account-section-header-text">
              Personal Information - Secondary Accountholder
            </span>
          </div>
          <IndividualAccountDetailsPII
            domicileCountry={jointInfo.jointCountry}
            dob={jointInfo.jointDob}
            setDob={setDob}
            dobError={dobError}
            setDobError={setDobError}
            idNumber={jointInfo.jointIDNumber}
            setIDNumber={setIDNumber}
            idNumberError={idNumberError}
            setIDNumberError={setIDNumberError}
          />
          <IndividualAccountDetailsAddress
            addressLine1={jointInfo.jointAddressLine1}
            setAddressLine1={setAddressLine1}
            addressLine1Error={addressLine1Error}
            setAddressLine1Error={setAddressLine1Error}
            addressLine2={jointInfo.jointAddressLine2}
            setAddressLine2={setAddressLine2}
            addressLine2Error={addressLine2Error}
            setAddressLine2Error={setAddressLine2Error}
            city={jointInfo.jointCity}
            setCity={setCity}
            cityError={cityError}
            setCityError={setCityError}
            state={jointInfo.jointState}
            setState={setState}
            stateError={stateError}
            setStateError={setStateError}
            country={jointInfo.jointCountry}
            setCountry={setCountry}
            countryError={countryError}
            setCountryError={setCountryError}
            postalCode={jointInfo.jointPostalCode}
            setPostalCode={setPostalCode}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
    /> </div>}

          <UpdateInfoInstructions />
    </div>         </div>
  );
}
export default IndividualAccountDetailsPersonalInfo;
