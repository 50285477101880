import React from "react";
import Alert from "@mui/material/Alert";

function TrustAccountBasicInformationQualificationError({
  trustAccountBasicInformationQualificationQuestionError,
  mountedStyle,
  unmountedStyle,
}) {
  return (
    trustAccountBasicInformationQualificationQuestionError !== "" && (
      <div
        className="row g-0 w-100 mt-3 fade-in-test"
        style={
          trustAccountBasicInformationQualificationQuestionError
            ? mountedStyle
            : unmountedStyle
        }
      >
        <Alert severity="error">
          {trustAccountBasicInformationQualificationQuestionError}
        </Alert>
      </div>
    )
  );
}
export default TrustAccountBasicInformationQualificationError;
