import React from 'react';
import Accordion from './Accordion';
import "../../../Styles/Variables/GlobalStyles.scss"

const AccordionCard = ({title, items}) => {
  return (<>
        <div className="card mb-4 border-0 backgroundGrey">
            <div className="card-body">
                <h5 className="card-title default-header-text">{title}</h5>    
                <Accordion items={items} accordionid={title.replace(" ", "_")}/>
            </div>
        </div>
  </>);
};

export default AccordionCard;
