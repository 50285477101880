import React, { useEffect } from "react";
import BlogPostCard from "./BlogPostCard/BlogPostCard.js";
import NoBlogPostsFoundCard from "./NoBlogPostsFoundCard/NoBlogPostsFoundCard.js";
import LoadingAnimation from "../../../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation.js";
function BlogPostPreviewSection({ blogPosts, loading }) {
  return (
    <LoadingAnimation loading={loading}>
      {!loading && blogPosts.length === 0 ? (
        <NoBlogPostsFoundCard />
      ) : (
        <div className="row w-100 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4 ps-0 pe-0 mb-4">
          {blogPosts.map((blogPost) => {
            return <BlogPostCard blogPost={blogPost} />;
          })}
        </div>
      )}
    </LoadingAnimation>
  );
}
export default BlogPostPreviewSection;
