import React, { useState, useEffect, useContext } from "react";
import "./Profile.css";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import DeliverableAddressSection from "./DeliverableAddressSection/DeliverableAddressSection";
import ProfileInputField from "../../../Components/GlobalComponents/ProfileInputFields/ProfileInputField.js";
import ProfileDobField from "../../../Components/GlobalComponents/ProfileInputFields/ProfileDobField/ProfileDobField.js";
import ProfileSSNField from "../../../Components/GlobalComponents/ProfileInputFields/ProfileSSNField/ProfileSSNField.js";
import ProfileIDField from "../../../Components/GlobalComponents/ProfileInputFields/ProfileIDField/ProfileIDField";
import StateInput from "../../../Components/GlobalComponents/StateInput/StateInput.js";
import CountryInput from "../../../Components/GlobalComponents/CountryInput/CountryInput.js";
import { isDobValid } from "../../../Functions/functions.js";
import { isValidSSN } from "../../../Functions/functions.js";
import { isValidCountry } from "../../../GlobalFunctions/globalFunctions";
import { UserContext } from "../../../GlobalContexts/UserContext.js";
import { useLocation, useHistory } from "react-router-dom";
import {
  updateInvestorAddress,
  updateInvestorPII,
} from "../../../Api/ProfileApi.js";
import StyledTextInputField from "../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import StyledDateInputField from "../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import { stateList } from "../../../Components/GlobalComponents/stateList";
import { countryList } from "../../../Components/countryList";
import { reducedCountryList } from "../../../Components/reducedCountryList";
import SelectField from "../../../GlobalComponents/InputField/SelectField/SelectField";
import { formatSSN } from "../../../GlobalFunctions/globalFunctions";
import { error } from "jquery";

function InvestorInformation({ accountSummaryData, setAccountSummaryData }) {
  const [piiLoading, setPIILoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [deliverableSelectionError, setDeliverableSelectionError] =
    useState("");
  const [primaryAddressDeliverable, setPrimaryAddressDeliverable] =
    useState("Yes");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryStateError, setCountryStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [nationalIdError, setNationalIdError] = useState("");
  const [dobError, setDobError] = useState("");
  const [address, setAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [countryState, setCountryState] = useState([]);
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [addressType, setAddressType] = useState("");
  const [hideDeliverableQuestion, setHideDeliverableQuestion] = useState(false);
  const [deliverableAddressLine1, setDeliverableAddressLine1] = useState("");
  const [deliverableAddressLine2, setDeliverableAddressLine2] = useState("");
  const [deliverableCity, setDeliverableCity] = useState("");
  const [deliverableState, setDeliverableState] = useState("");
  const [deliverableCountry, setDeliverableCountry] = useState("");
  const [deliverablePostalCode, setDeliverablePostalCode] = useState("");
  const [deliverableAddressLine1Error, setDeliverableAddressLine1Error] =
    useState("");
  const [deliverableAddressLine2Error, setDeliverableAddressLine2Error] =
    useState("");
  const [citizenship, setCitizenship] = useState("");
  const [citizenshipError, setCitizenshipError] = useState("");
  const [deliverableCityError, setDeliverableCityError] = useState("");
  const [deliverableStateError, setDeliverableStateError] = useState("");
  const [deliverableCountryError, setDeliverableCountryError] = useState("");
  const [deliverablePostalCodeError, setDeliverablePostalCodeError] =
    useState("");
  const [nationalId, setNationalId] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportNumberError, setPassportNumberError] = useState("");
  const [idType, setIDType] = useState("");
  const [dob, setDob] = useState("");
  const location = useLocation();
  const from = location ? (location.state ? location.state.from : "") : "";
  let history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const list = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Island",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    setAddress(user ? user.investorPrimaryAddressLine1 : "");

    setAddressLine2(user ? user.investorPrimaryAddressLine2 : "");

    setCity(user ? user.investorPrimaryCity : "");

    setCountryState(user ? user.investorPrimaryState : "");

    setCountry(user ? user.investorPrimaryCountry : "");

    setPostalCode(user ? user.investorPrimaryPostalCode : "");

    setAddressType(user ? user.investorPrimaryAddressType : "");

    setHideDeliverableQuestion(user.investorPrimaryAddressLine1 !== "");

    setIDType(
      user
        ? user.userProfileData.investor_pii.length !== 0
          ? user.userProfileData.investor_pii[0].id_type
          : ""
        : ""
    );

    setDeliverableAddressLine1(user ? user.investorSecondaryAddressLine1 : "");

    setDeliverableAddressLine2(user ? user.investorSecondaryAddressLine2 : "");

    setDeliverableCity(user ? user.investorSecondaryCity : "");

    setDeliverableState(user ? user.investorSecondaryState : "");

    setDeliverableCountry(user ? user.investorSecondaryCountry : "");

    setDeliverablePostalCode(user ? user.investorSecondaryPostalCode : "");

    setNationalId(
      user
        ? user.userProfileData.investor_pii.length !== 0
          ? user.userProfileData.investor_pii[0].id_type === "SSN"
            ? user.userProfileData.investor_pii[0].id_num
            : ""
          : ""
        : ""
    );

    setPassportNumber(
      user
        ? user.userProfileData.investor_pii.length !== 0
          ? user.userProfileData.investor_pii[0].id_type === "PASS"
            ? user.userProfileData.investor_pii[0].id_num
            : ""
          : ""
        : ""
    );

    setCitizenship(
      user
        ? user.userProfileData.investor_pii.length !== 0
          ? user.userProfileData.investor_pii[0].citizenship
          : ""
        : ""
    );

    setDob(
      user
        ? user.userProfileData.investor_pii.length !== 0
          ? user.userProfileData.investor_pii[0].date_of_birth
          : ""
        : ""
    );
  }, [user]);

  useEffect(() => {
    setCountryStateError("");
  }, [countryState]);

  useEffect(() => {
    setAddressError("");
  }, [address]);

  useEffect(() => {
    setAddressLine2Error("");
  }, [addressLine2]);

  useEffect(() => {
    setPostalCodeError("");
  }, [postalCode]);

  useEffect(() => {
    setDobError("");
  }, [dob]);

  useEffect(() => {
    setNationalIdError("");
    setPassportNumberError("");
    setIDType("SSN");
  }, [nationalId]);

  useEffect(() => {
    setPassportNumberError("");
    setNationalIdError("");
    setIDType("PASS");
  }, [passportNumber]);

  useEffect(() => {
    setCityError("");
  }, [city]);

  useEffect(() => {
    setCountryError("");
  }, [country]);

  useEffect(() => {
    setDeliverableAddressLine1Error("");
  }, [deliverableAddressLine1]);

  useEffect(() => {
    setDeliverableAddressLine2Error("");
  }, [deliverableAddressLine2]);

  useEffect(() => {
    setDeliverableCityError("");
  }, [deliverableCity]);

  useEffect(() => {
    setDeliverableStateError("");
  }, [deliverableState]);

  useEffect(() => {
    setDeliverableCountryError("");
  }, [deliverableCountry]);

  useEffect(() => {
    setDeliverablePostalCodeError("");
  }, [deliverablePostalCode]);

  useEffect(() => {
    setDeliverableSelectionError("");
  }, [primaryAddressDeliverable]);

  const submitAddressPII = async () => {
    var investorInformation = {};
    var errorPresent = false;

    if (address === "") {
      setAddressError("Please enter an address line 1.");
      errorPresent = true;
    }
    if (city === "") {
      setCityError("Please enter a city.");
      errorPresent = true;
    }
    if (country === "United States" && countryState === "") {
      setCountryStateError("Please enter a state.");
      errorPresent = true;
    }
    if (country === "") {
      setCountryError("Please enter a country.");
      errorPresent = true;
    }
    if (postalCode === "") {
      setPostalCodeError("Please enter a postal code.");
      errorPresent = true;
    }
    if (primaryAddressDeliverable === "") {
      setDeliverableSelectionError("Please make a selection.");
      errorPresent = true;
    }
    if (!isValidCountry(country)) {
      setCountryError("Please enter a valid country from the dropdown.");
      errorPresent = true;
    }
    if (country === "United States" && !list.includes(countryState)) {
      setCountryStateError("Please enter a valid state from the dropdown.");
      errorPresent = true;
    }

    if (citizenship === "") {
      setCitizenshipError("Please enter a citizenship.");
      errorPresent = true;
    }

    // else if (citizenship !== "United States")  {
    //   errorPresent = true;
    //   setCitizenshipError("Sorry, we are only accepting US citizens at this time.");
    // }

    if (dob === "") {
      setDobError("Please enter a date of birth.");
      errorPresent = true;
    } else if (!isDobValid(dob)) {
      setDobError("Please enter a valid date of birth.");
      errorPresent = true;
    }

    if (
      user.investorSummaryData.country !== "United States" &&
      passportNumber === ""
    ) {
      setPassportNumberError("Please enter a passport number.");
      errorPresent = true;
    }

    if (
      user.investorSummaryData.country === "United States" &&
      nationalId === ""
    ) {
      setNationalIdError("Please enter a ssn.");
      errorPresent = true;
    } else if (
      user.investorSummaryData.country === "United States" &&
      !isValidSSN(nationalId)
    ) {
      setNationalIdError("Please enter a valid ssn.");
      errorPresent = true;
    }
    if (errorPresent) {
      return;
    } else {
      investorInformation.citizenship = citizenship;
      investorInformation.date_of_birth = dob;
      if (user.investorSummaryData.country === "United States") {
        investorInformation.id_num = nationalId;
        investorInformation.id_type = "SSN";
      } else {
        investorInformation.id_num = passportNumber;
        investorInformation.id_type = "PASS";
      }

      let addressInformation = {};
      addressInformation.address_line_1 = address;
      addressInformation.address_line_2 = addressLine2;
      addressInformation.city = city;
      addressInformation.state = countryState;
      addressInformation.country = country;
      addressInformation.postal_code = postalCode;
      addressInformation.address_type = "RM";

      try {
        setPIILoading(true);
        await updateInvestorAddress(addressInformation);
        await updateInvestorPII(investorInformation);

        const updatedUser = user;
        let investorPII = {};
        investorPII.date_of_birth = dob;
        investorPII.id_type = idType;
        investorPII.id_num = investorInformation.id_num;

        let updateInvestorPIIArray = [];

        updateInvestorPIIArray.push(investorPII);
        updatedUser.userProfileData.investor_pii = updateInvestorPIIArray;

        let investorAddress = {};
        investorAddress.address_line_1 = address;
        investorAddress.address_line_2 = addressLine2;
        investorAddress.city = city;
        investorAddress.country = country;
        investorAddress.state = countryState;
        investorAddress.postal_code = postalCode;

        let updateInvestorAddressArray = [];
        updateInvestorAddressArray.push(investorAddress);
        updatedUser.userProfileData.investor_addresses =
          updateInvestorAddressArray;

        updatedUser.investorPrimaryAddressLine1 = address;
        updatedUser.investorPrimaryAddressLine2 = addressLine2;
        updatedUser.investorPrimaryCity = city;
        updatedUser.investorPrimaryState = countryState;
        updatedUser.investorPrimaryCountry = country;
        updatedUser.investorPrimaryPostalCode = postalCode;
        updatedUser.investorPrimaryAddressType =
          primaryAddressDeliverable === "Yes" ? "RM" : "R";
        updatedUser.investorSummaryData.address_complete = true;
        updatedUser.investorSummaryData.pii_complete = true;
        setAccountSummaryData({
          ...accountSummaryData,
          ...{ investor_profile_complete: true },
        });
        setUser(updatedUser);
        setPIILoading(false);
      } catch (err) {
        setPIILoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid w-100 ps-sm-5 ps-3 pe-sm-3 pe-2">
        <div className="row g-0 w-100">
          <div className="col-lg-11 col-12">
            <div className="row g-0 w-100 mt-3 mb-3">
              <div className="col-12">
                <span className="header-text" style={{ fontSize: "18px" }}>
                  Investor Information
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mt-4 mb-4">
              <div className="col ps-0 pe-2">
                <StyledTextInputField
                  value={address}
                  setValue={setAddress}
                  setValueError={setAddressError}
                  valueError={addressError}
                  label={"Address Line 1"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={user.investorPrimaryAddressLine1 !== ""}
                  id={"investor-profile-address-line-1-input"}
                />
              </div>
              <div className="col ps-2 pe-0">
                <StyledTextInputField
                  value={addressLine2}
                  setValue={setAddressLine2}
                  setValueError={setAddressLine2Error}
                  valueError={addressLine2Error}
                  label={"Address Line 2"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={user.investorPrimaryAddressLine1 !== ""}
                  id={"investor-profile-address-line-2-input"}
                />
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div
                className={
                  country === "United States"
                    ? "col ps-0 pe-2"
                    : "col ps-0 pe-0"
                }
              >
                <StyledTextInputField
                  value={city}
                  setValue={setCity}
                  setValueError={setCityError}
                  valueError={cityError}
                  label={"City"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={user.investorPrimaryAddressLine1 !== ""}
                  id={"investor-profile-city-input"}
                />
              </div>
              {country === "United States" && (
                <div className="col ps-2 pe-0">
                  <SelectField
                    value={countryState}
                    setValue={setCountryState}
                    valueError={countryStateError}
                    setValueError={setCountryStateError}
                    selectOptions={stateList}
                    label={"State"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={user.investorPrimaryAddressLine1 !== ""}
                    shrink={true}
                    id={"investor-profile-address-state-input"}
                  />
                </div>
              )}
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col ps-0 pe-2">
                {user.investorPrimaryAddressLine1 === "" ? (
                  <SelectField
                    value={country}
                    setValue={setCountry}
                    valueError={countryError}
                    setValueError={setCountryError}
                    selectOptions={countryList}
                    label={"Country"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={user.investorPrimaryAddressLine1 !== ""}
                    shrink={true}
                    id={"investor-profile-address-country-input"}
                  />
                ) : (
                  <StyledTextInputField
                    value={country}
                    setValue={setCountry}
                    valueError={countryError}
                    setValueError={setCountryError}
                    label={"Country"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={user.investorPrimaryAddressLine1 !== ""}
                    shrink={true}
                    id={"investor-profile-address-country-input"}
                  />
                )}
              </div>
              <div className="col ps-2 pe-0">
                <StyledTextInputField
                  value={postalCode}
                  setValue={setPostalCode}
                  setValueError={setPostalCodeError}
                  valueError={postalCodeError}
                  label={"Postal Code"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={user.investorPrimaryAddressLine1 !== ""}
                  id={"investor-profile-postal-code-input"}
                />
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col pe-2">
                <StyledDateInputField
                  value={dob}
                  setValue={setDob}
                  valueError={dobError}
                  setValueError={setDobError}
                  label={"Date Of Birth"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={
                    user
                      ? user.userProfileData.investor_pii.length !== 0
                        ? user.userProfileData.investor_pii[0].date_of_birth !==
                          ""
                          ? true
                          : false
                        : false
                      : false
                  }
                  shrink={true}
                  id={"investor-profile-dob-input"}
                />
              </div>
              <div className="col ps-2 pe-0">
                {user.investorSummaryData.country === "United States" && (
                  <StyledTextInputField
                    value={nationalId}
                    setValue={setNationalId}
                    valueError={nationalIdError}
                    setValueError={setNationalIdError}
                    label={"SSN"}
                    required={true}
                    fullWidth={true}
                    disabled={
                      user
                        ? user.userProfileData.investor_pii.length !== 0
                          ? user.userProfileData.investor_pii[0].id_num !== ""
                            ? true
                            : false
                          : false
                        : false
                    }
                    shrink={true}
                    size={"small"}
                    mask={{
                      maskFormatter: formatSSN,
                      maskPattern: "999-99-9999",
                    }}
                    id={"investor-profile-ssn-input"}
                  />
                )}
                {user.investorSummaryData.country !== "United States" && (
                  <StyledTextInputField
                    value={passportNumber}
                    setValue={setPassportNumber}
                    valueError={passportNumberError}
                    setValueError={setPassportNumberError}
                    label={"Passport Number"}
                    required={true}
                    fullWidth={true}
                    disabled={
                      user
                        ? user.userProfileData.investor_pii.length !== 0
                          ? user.userProfileData.investor_pii[0].id_type ===
                            "PASS"
                            ? user.userProfileData.investor_pii[0].id_num !== ""
                              ? true
                              : false
                            : false
                          : false
                        : false
                    }
                    shrink={true}
                    size={"small"}
                    id={"investor-profile-passport-input"}
                  />
                )}
              </div>{" "}
              <div className="col ps-2 pe-0">
                {user.investorPrimaryAddressLine1 === "" ? (
                  <SelectField
                    value={citizenship}
                    setValue={setCitizenship}
                    valueError={citizenshipError}
                    setValueError={setCitizenshipError}
                    label={"Citizenship"}
                    selectOptions={reducedCountryList}
                    required={true}
                    fullWidth={true}
                    disabled={
                      user && user.userProfileData.investor_pii.length !== 0
                        ? user.userProfileData.investor_pii[0].citizenship !==
                          null
                        : false
                    }
                    shrink={true}
                    size={"small"}
                    id={"investor-profile-passport-input"}
                  />
                ) : (
                  <StyledTextInputField
                    value={citizenship}
                    valueError={citizenshipError}
                    setValue={setCitizenship}
                    setValueError={setCitizenshipError}
                    label={"Citizenship"}
                    required={true}
                    fullWidth={true}
                    disabled={
                      user && user.userProfileData.investor_pii.length !== 0
                        ? user.userProfileData.investor_pii[0].citizenship !==
                          null
                        : false
                    }
                    shrink={true}
                    size={"small"}
                    id={"investor-profile-passport-input"}
                  />
                )}
              </div>
            </div>
            {/* <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col-12">
                    <span className="SignupForm-field-title-text align-self-start">
                      Address Line 1
                    </span>
                    <div className="align-self-end mt-1">
                      <ProfileInputField
                        placeholder={"Address Line 1"}
                        error={addressError}
                        fieldValueChange={setAddress}
                        initialValue={address}
                        disabled={user.investorPrimaryAddressLine1 !== ""}
                      />
                      {addressError &&
                        addressError !== "This field is required." && (
                          <div className="SignupForm-field-error-text mt-1">
                            <span className="">{addressError}</span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col-12">
                    <span className="SignupForm-field-title-text align-self-start">
                      Address Line 2
                    </span>
                    <div className="align-self-end mt-1">
                      <ProfileInputField
                        placeholder={"Address Line 2"}
                        error={addressLine2Error}
                        fieldValueChange={setAddressLine2}
                        initialValue={addressLine2}
                        disabled={user.investorPrimaryAddressLine1 !== ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col">
                    <span className="SignupForm-field-title-text align-self-start">
                      City
                    </span>
                    <div className="align-self-end mt-1">
                      <ProfileInputField
                        placeholder={"City"}
                        error={cityError}
                        fieldValueChange={setCity}
                        initialValue={city}
                        disabled={user.investorPrimaryAddressLine1 !== ""}
                      />
                      {cityError && cityError !== "This field is required." && (
                        <div className="SignupForm-field-error-text mt-1">
                          <span className="">{cityError}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {country === "United States" && (
                    <div className="col mt-md-0 mt-2">
                      <span className="SignupForm-field-title-text">State</span>
                      <div className="align-self-end mt-1">
                        <StateInput
                          value={countryState}
                          setValue={setCountryState}
                          placeholder={"Choose a state"}
                        ></StateInput>
                        {countryStateError && (
                          <div className="SignupForm-field-error-text align-self-end mt-1">
                            <span className="">{countryStateError}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
            {/* <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col-md-6 mt-md-0 mt-2">
                    <span className="SignupForm-field-title-text">Country</span>
                    <div className="align-self-end mt-1">
                      <CountryInput
                        value={country}
                        setValue={setCountry}
                        placeholder={"Choose a country"}
                      ></CountryInput>
                      {countryError && countryError && (
                        <div className="SignupForm-field-error-text mt-1">
                          <span className="">{countryError}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mt-md-0 mt-2">
                    <span className="SignupForm-field-title-text">
                      Postal Code
                    </span>
                    <div className="align-self-end mt-1">
                      <ProfileInputField
                        placeholder={"Postal Code"}
                        error={postalCodeError}
                        fieldValueChange={setPostalCode}
                        initialValue={postalCode}
                        disabled={user.investorPrimaryAddressLine1 !== ""}
                      />
                      {postalCodeError &&
                        postalCodeError !== "This field is required." && (
                          <div className="SignupForm-field-error-text mt-1">
                            <span className="">{postalCodeError}</span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {(primaryAddressDeliverable === "No" ||
              user.investorSecondaryAddressLine1 !== "") && (
              <DeliverableAddressSection
                deliverableAddressLine1={deliverableAddressLine1}
                deliverableAddressLine2={deliverableAddressLine2}
                deliverableCity={deliverableCity}
                deliverableState={deliverableState}
                deliverableCountry={deliverableCountry}
                deliverablePostalCode={deliverablePostalCode}
                deliverableAddressLine1Error={deliverableAddressLine1Error}
                deliverableAddressLine2Error={deliverableAddressLine2Error}
                deliverableCityError={deliverableCityError}
                deliverableStateError={deliverableStateError}
                deliverableCountryError={deliverableCountryError}
                deliverablePostalCodeError={deliverablePostalCodeError}
                setDeliverableAddressLine1={setDeliverableAddressLine1}
                setDeliverableAddressLine2={setDeliverableAddressLine2}
                setDeliverableCity={setDeliverableCity}
                setDeliverableState={setDeliverableState}
                setDeliverableCountry={setDeliverableCountry}
                setDeliverablePostalCode={setDeliverablePostalCode}
              />
            )}

            {/* <div className="row g-0 w-100 mb-4">
              <div className="col-12">
                <div className="row gx-4 w-100">
                  <div className="col-md-6 mt-md-0 mt-2">
                    <span className="SignupForm-field-title-text">DOB</span>
                    <div className="align-self-end mt-1">
                      <ProfileDobField
                        placeholder={"MM-DD-YYYY"}
                        error={dobError}
                        initialValue={dob}
                        fieldValueChange={setDob}
                        disabled={
                          user
                            ? user.userProfileData.investor_pii.length !== 0
                              ? user.userProfileData.investor_pii[0]
                                  .date_of_birth !== ""
                                ? true
                                : false
                              : false
                            : false
                        }
                      />
                      {dobError && dobError !== "This field is required." && (
                        <div className="SignupForm-field-error-text mt-1">
                          <span className="">{dobError}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mt-md-0 mt-2">
                    <span className="SignupForm-field-title-text">
                      {user.investorSummaryData.country === "United States"
                        ? "SSN"
                        : "Passport Number"}
                    </span>
                    <div className="align-self-end mt-1">
                      {user.investorSummaryData.country === "United States" ? (
                        <ProfileSSNField
                          placeholder={"XXX-XX-XXXX"}
                          error={nationalIdError}
                          fieldValueChange={setNationalId}
                          initialValue={nationalId}
                          disabled={
                            user
                              ? user.userProfileData.investor_pii.length !== 0
                                ? user.userProfileData.investor_pii[0]
                                    .id_num !== ""
                                  ? true
                                  : false
                                : false
                              : false
                          }
                        />
                      ) : (
                        <ProfileIDField
                          placeholder={"Enter your passport number"}
                          error={passportNumberError}
                          fieldValueChange={setPassportNumber}
                          initialValue={passportNumber}
                          disabled={
                            user
                              ? user.userProfileData.investor_pii.length !== 0
                                ? user.userProfileData.investor_pii[0]
                                    .id_type === "PASS"
                                  ? user.userProfileData.investor_pii[0]
                                      .id_num !== ""
                                    ? true
                                    : false
                                  : false
                                : false
                              : false
                          }
                        />
                      )}
                      {nationalIdError &&
                        nationalIdError !== "This field is required." && (
                          <div className="SignupForm-field-error-text mt-1">
                            <span className="">{nationalIdError}</span>
                          </div>
                        )}
                      {passportNumberError &&
                        passportNumberError !== "This field is required." && (
                          <div className="SignupForm-field-error-text mt-1">
                            <span className="">{passportNumberError}</span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {user &&
            (user.userProfileData.investor_pii.length === 0 ||
              user.userProfileData.investor_pii[0].date_of_birth === "" ||
              user.userProfileData.investor_pii[0].id_num === "") ? (
              <div className="row g-0 w-100 mb-4">
                <div className="d-flex">
                  <button
                    type="button"
                    class="btn btn-lg btn-class"
                    onClick={() => submitAddressPII()}
                    disabled={piiLoading}
                  >
                    {piiLoading && (
                      <div className="row g-0 w-100">
                        <div className="col d-flex align-items-center">
                          <span className="justify-self-start align-self-center">
                            Save Personal Information
                          </span>
                          <span
                            class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                    )}
                    {!piiLoading && <span>Save Personal Information</span>}
                  </button>
                </div>
              </div>
            ) : (
              <div className="row g-0 w-100 mt-4 mb-4 contact-aqua-admin-container">
                <div className="d-flex align-items-center">
                  <img
                    src="https://d1swyfveypj49y.cloudfront.net/newInfo.svg"
                    style={{ marginRight: "14px" }}
                  />
                  <span className="contact-aqua-admin-text">
                    If any of the above information is incorrect, please contact
                    admin@investwithaqua.com to update your account information.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InvestorInformation;
