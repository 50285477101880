import React from "react";
import StyledDateInputField from "../../../../../../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import { formatSSN } from "../../../../../../../../GlobalFunctions/globalFunctions";

function PII({
  grantorDomicileCountry,
  grantorDob,
  setGrantorDob,
  grantorDobError,
  setGrantorDobError,
  grantorIDNumber,
  setGrantorIDNumber,
  grantorIDNumberError,
  setGrantorIDNumberError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="trust-account-section-header-text">
          Identification Information
        </span>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <StyledDateInputField
            value={grantorDob}
            setValue={setGrantorDob}
            valueError={grantorDobError}
            setValueError={setGrantorDobError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"trust-account-details-grantor-dob-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          {grantorDomicileCountry === "United States" && (
            <StyledTextInputField
              value={grantorIDNumber}
              setValue={setGrantorIDNumber}
              valueError={grantorIDNumberError}
              setValueError={setGrantorIDNumberError}
              label={"SSN"}
              required={true}
              fullWidth={true}
              disabled={true}
              shrink={true}
              size={"small"}
              mask={{ maskFormatter: formatSSN, maskPattern: "999-99-9999" }}
              id={"trust-account-details-grantor-ssn-input"}
            />
          )}
          {grantorDomicileCountry !== "United States" && (
            <StyledTextInputField
              value={grantorIDNumber}
              setValue={setGrantorIDNumber}
              valueError={grantorIDNumberError}
              setValueError={setGrantorIDNumberError}
              label={"Passport Number"}
              required={true}
              fullWidth={true}
              disabled={true}
              shrink={true}
              size={"small"}
              id={"individual-account-details-grantor-passport-input"}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default PII;
