import React from "react";
import "./NoFundsFoundCardStyles/NoFundsFoundCardStyles.css";

function NoFundsFoundCard() {
  return (
    <div className="container-xxl d-flex mt-5 pt-5 align-self-center justify-self-center align-items-center justify-content-center text-center">
      <span className="align-self-center funds-not-found-text">
        Sorry, there were no funds that matched your search criteria. Please try
        a different search.
      </span>
    </div>
  );
}
export default NoFundsFoundCard;
