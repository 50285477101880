import React, { useState, useEffect } from "react";
import CheckBox from "../../../../Components/GlobalComponents/CheckBox.js";
import StateBasedButton from "../../../../GlobalComponents/Button/StateBasedButton/StateBasedButton.js";
//import Alert from "../../../../Components/GlobalComponents/Alert/Alert.js";
//import { Link } from "react-router-dom";
//import "./TermsAndAgreementSectionStyles/TermsAndAgreementSectionStyles.css";
import "../ConfidentialityAgreementSection/ConfidentialityAgreementSectionStyles/ConfidentialityAgreementSectionStyles.css";
import { useHistory } from "react-router-dom";
//import { getInvestorConfidentialityAgreement } from "../../../../Api/SignupApi.js";
// import GetInvestorConfidentialityAgreement from "../../../../Adapters/ConfidentialityAgreementAdapters.js";
import GetInvestorTermsOfAgreement from "../../../../Adapters/TermsOfAgreementAdapters.js";

function TermsAndAgreementSection({
  navigation,
  termsAgreed,
  setTermsAgreed,
  setTermsAgreementId,
}) {
  const [documentHasBeenRead, setDocumentHasBeenRead] = useState(false);
  const [checked, setChecked] = useState(termsAgreed);
  const [proceed, setProceed] = useState(false);
  const timeStamp = Date.now();
  const [time, setTime] = useState(timeStamp);
  const { investorTermsOfAgreement } = GetInvestorTermsOfAgreement(time);

  let history = useHistory();

  //   let termsOfAgreementMessage;

  useEffect(() => {
    setTermsAgreementId(
      investorTermsOfAgreement ? investorTermsOfAgreement.id : ""
    );
  }, [investorTermsOfAgreement, setTermsAgreementId]);

  //   if (investorTermsOfAgreement) {
  //     termsOfAgreementMessage = investorTermsOfAgreement.agreement
  // } else {
  //     termsOfAgreementMessage = Alert('danger', 'Error Retreiving Terms of Agreement. Please contact us or check back later.')
  //     console.error('No Terms Of Agreement Found')
  // }

  useEffect(() => {
    setChecked(termsAgreed);
    setProceed(termsAgreed);
  }, [termsAgreed]);

  useEffect(() => {
    if (documentHasBeenRead && checked) {
      setTermsAgreed(true);
      setProceed(true);
    } else if (!checked) {
      setProceed(false);
      setTermsAgreed(false);
    }
  }, [documentHasBeenRead, checked, setTermsAgreed]);

  var handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) <=
      e.target.clientHeight;
    if (bottom && !documentHasBeenRead) {
      setDocumentHasBeenRead(true);
    }
  };

  let documentLoadingSpinner = (
    <React.Fragment>
      <div className="row g-0 w-100 align-items-center">
        <div className="col-4"></div>
        <div className="col-4 align-items-center text-center">
          <button class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        </div>
        <div className="col-4"></div>
      </div>
    </React.Fragment>
  );

  let scrollBasedSubmitButton =
    checked && documentHasBeenRead ? (
      <StateBasedButton proceed={proceed} text={"Next Step"} />
    ) : (
      <StateBasedButton proceed={proceed} text={"Next Step"} />
    );

  let scrollWarning = (
    <span class="badge bg-light text-dark">
      Please Read and Agree Before Continuing
    </span>
  );

  return (
    <React.Fragment>
      <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-y-hidden fillScreen align-items-center justify-content-start d-flex">
        <div className="row g-0 w-100 align-items-center justify-content-start">
          <div
            className="col-6 d-none d-sm-none d-md-none d-lg-block"
            style={{ backgroundColor: "#F0F4FD", zIndex: 1 }}
          >
            <img
              src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
              style={{
                height: "100vh",
                backgroundSize: "cover !important",
                zIndex: 2,
              }}
              alt="community"
            ></img>
          </div>
          <div
            className="col-xxl-6 col-xl-5 col-lg-6 col-12"
            style={{ zIndex: 3, backgroundColor: "#f7f7fa", height: "100vh" }}
          >
            <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
              <div className="row g-0 w-100 h-100 align-items-center">
                <div className="col-11">
                  <div className="row g-0 w-100 mb-4 mt-5">
                    <div className="col-12">
                      <div onClick={() => navigation.previous()}>
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-11">
                      <span className="Confidentiality-header-text">
                        Terms of Use Agreement
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col-11">
                      <div
                        className="document-container"
                        onScroll={(event) => handleScroll(event)}
                      >
                        <div className="container-fluid">
                          <span
                            className="document-text-content"
                            dangerouslySetInnerHTML={{
                              __html: investorTermsOfAgreement ? (
                                investorTermsOfAgreement.agreement
                              ) : (
                                <p>Loading...</p>
                              ),
                            }}
                          >
                            {/* {investorTermsOfAgreement
                              ? investorTermsOfAgreement.agreement
                              : documentLoadingSpinner} */}
                          </span>
                        </div>
                      </div>
                    </div>
                    {!checked || !documentHasBeenRead ? (
                      scrollWarning
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="row g-0 w-100 mb-4">
                    <div className="col-11">
                      <div className="row g-0 w-100 align-items-center">
                        <div className="col-1">
                          <div
                            className=" ms-0 checkBox-container"
                            onClick={() => {
                              if (documentHasBeenRead) {
                                setChecked(!checked);
                              } else if (checked) {
                                setChecked(false);
                              }
                            }}
                          >
                            <CheckBox selected={checked} />
                          </div>
                        </div>
                        <div className="col-11">
                          <span className="checkBox-label">
                            I have read, understood, and agree to all terms
                            noted in the{" "}
                            <strong>Terms and Policies Agreement</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-5">
                    <div className="col-11">
                      <div className="row g-0 w-100">
                        <div className="col-4 ms-0 me-auto">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (proceed) {
                                navigation.next();
                              }
                            }}
                          >
                            {scrollBasedSubmitButton}
                          </div>
                        </div>
                        <div className="col-4 align-self-end text-end">
                          <span
                            className="sign-in-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push("/login")}
                          >
                            <u>or Sign In</u>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100">
                    <div className="col-xxl-6 col-xl-6 col-lg-7 col-sm-6 col-8">
                      <div className="row gx-1 w-100">
                        <div className="row g-0 w-100">
                          <div className="col">
                            <div className="progress-bar-completed"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-completed"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-completed"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                          <div className="col">
                            <div className="progress-bar-empty"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default TermsAndAgreementSection;
