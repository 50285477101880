import React, { useState, useEffect } from "react";
import "./AccountRelatedPartyInformation/AccountRelatedPartyInformationStyles/AccountRelatedPartyInformationStyles.scss";
import AdditionalQuestionsEntQuestion from "./AdditionalQuestionsEntQuestion";
import StyledPhoneInputField from "../../../../GlobalComponents/InputField/StyledPhoneInputField/StyledPhoneInputField";
import { stateList } from "../../../../Components/GlobalComponents/stateList";
import SelectField from "../../../../GlobalComponents/InputField/SelectField/SelectField";
import IndividualAccountBasicInformationQualificationSection from "./AccountBasicInformation/IndividualAccountBasicInformation/IndividualAccountBasicInformationQualificationSection/IndividualAccountBasicInformationQualificationSection";
import IndividualAccountBasicInformationQualificationErrorSection from "./AccountBasicInformation/IndividualAccountBasicInformation/IndividualAccountBasicInformationQualificationSection/IndividualAccountBasicInformationQualificationErrorSection/IndividualAccountBasicInformationQualificationErrorSection";
import {
  isValidPhoneNumber,
  isEmailValid,
} from "../../../../Functions/functions.js";

import { additionalEntityInformation } from "../../../../Api/ProfileApi";

const AdditionalQuestionsEnt = ({
  accountType,
  processedCountryList,
  processedStateList,
  setActiveSlide,
  accountID,
  mountedStyle,
  unmountedStyle,
  answer,
  setAnswer,
  error,
  setError,
  businessPhone,
  setBusinessPhone,
  businessPhoneError,
  setBusinessPhoneError,
  stateOfIncorp,
  setStateOfIncorp,
  stateOfIncorpError,
  setStateOfIncorpError,
  countryOfFormation,
  qualQ1,
  setQualQ1,
  qualError,
  setQualError,
  grantorUSCitizen,
  setGrantorUSCitizen,
  grantorError,
  setGrantorError,
}) => {
  useEffect(() => {
    if (qualQ1 === false) {
      setQualError("You don't qualify.");
    } else {
      setQualError("");
    }
  }, [qualQ1]);

  useEffect(() => {
    setError("");
  }, [answer]);

  const submit = async () => {
    var errors = false;
    setSubmissionInProgress(true);

    if (stateOfIncorp === "" && countryOfFormation === "United States") {
      errors = true;
      setStateOfIncorpError("Please select a state.");
    }

    // isValidPhone does not work for this component, use func from react-phone-input
    if (businessPhone === "") {
      errors = true;
      setBusinessPhoneError("Please enter a business phone number.");
    }

    if (grantorUSCitizen === "" && ["LT", "RT"].includes(accountType)) {
      errors = true;
      setGrantorError("This field is required.");
    }

    if (!errors) {
      //

      let payload = {};
      payload.account_id = accountID;
      payload.is_sm_llc = accountType === "SM_LLC";
      payload.business_phone = businessPhone;
      payload.qual_question = qualQ1;
      payload.grantor_us_citizen = grantorUSCitizen;

      payload.state_of_incorporation = stateOfIncorp;

      await additionalEntityInformation(payload);

      setActiveSlide((prevState) => prevState - 1);
    }

    setSubmissionInProgress(false);
    return;
  };

  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  return (
    <React.Fragment>
      <div className="row g-0 w-100 add-account-wizard-container">
        <div className="col-12 add-account-outer-column-padded">
          <div className="row g-0 w-100 mb-2">
            <div className="col-12">
              <h4>Additional Info Required</h4>
              <div className="row g-0 w-100 mb-2">
                <p class="add-account-qualification-subheader">
                  Please supply business phone number
                  {countryOfFormation === "United States" ? (
                    <> and state of incorporation.</>
                  ) : (
                    <>.</>
                  )}
                </p>
                <div className="col-6 ps-0 pe-2">
                  <StyledPhoneInputField
                    value={businessPhone}
                    valueError={businessPhoneError}
                    setValue={setBusinessPhone}
                    setValueError={setBusinessPhoneError}
                    label={"Business Phone"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    id={"account-name-input"}
                  />
                </div>
                {countryOfFormation === "United States" && (
                  <div className="col-6 pe-0">
                    <SelectField
                      value={stateOfIncorp}
                      setValue={setStateOfIncorp}
                      valueError={stateOfIncorpError}
                      setValueError={setStateOfIncorpError}
                      selectOptions={stateList}
                      label={"State of Incorporation"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"individual-account-state-confirm"}
                    />
                  </div>
                )}
              </div>
            </div>
            {["LT", "RT"].includes(accountType) && false && (
              <AdditionalQuestionsEntQuestion
                questionNumber={1}
                questionText={"Is the grantor a US citizen?"}
                answer={grantorUSCitizen}
                setAnswer={setGrantorUSCitizen}
                hasBeneficialOwnersError={grantorError}
              />
            )}
            {answer === true && (
              <IndividualAccountBasicInformationQualificationSection
                showIndividualQualificationSection={accountType === "LLC"}
                mountedStyle={mountedStyle}
                unmountedStyle={unmountedStyle}
                accountType={accountType}
                individualQualificationQ1={qualQ1}
                setIndividualQualificationQ1={setQualQ1}
                individualAccountBasicInformationError={qualError}
              />
            )}
            {qualError === "" && (
              <div className="row g-0 w-100">
                <div className="col">
                  <div className="d-flex">
                    <button
                      type="button"
                      class="btn btn-lg btn-class"
                      onClick={() => {
                        submit();
                      }}
                    >
                      {submissionInProgress && (
                        <div className="row g-0 w-100">
                          <div className="col d-flex align-items-center">
                            <span className="justify-self-start align-self-center">
                              Submit
                            </span>
                            <span
                              class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </div>
                      )}
                      {!submissionInProgress && <span>Submit</span>}
                    </button>
                  </div>
                </div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdditionalQuestionsEnt;
