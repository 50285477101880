import React, { useState, useEffect, useContext } from "react";
import SelectField from "../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import TrustAccountBasicInformationQualificationSection from "./TrustAccountBasicInformationQualificationSection/TrustAccountBasicInformationQualificationSection";
import TrustAccountBasicInformationButton from "./TrustAccountBasicInformationButton/TrustAccountBasicInformationButton";
import { getDateUTC } from "../../../../../../GlobalFunctions/globalFunctions.js";
import { newAccount, getAccountList } from "../../../../../../Api/ProfileApi";
import { UserContext } from "../../../../../../GlobalContexts/UserContext";

function TrustAccountBasicInformation({
  setAccountID,
  accountType,
  shouldRenderTrustQualificationSection,
  showTrustQualificationSection,
  legalAccountName,
  setLegalAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  processedCountryList,
  mountedStyle,
  unmountedStyle,
  trustQualificationQ1,
  setTrustQualificationQ1,
  trustQualificationQ2,
  setTrustQualificationQ2,
  trustQualificationQ3,
  setTrustQualificationQ3,
  trustQualificationQ4,
  setTrustQualificationQ4,
  setActiveSlide,
}) {
  const { user, setUser } = useContext(UserContext);

  const [
    trustAccountBasicInformationQualificationQuestionError,
    setTrustAccountBasicInformationQualificationQuestionError,
  ] = useState("");
  const [qualificationDate, setQualificationDate] = useState("");
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const isTrustAccountQualifiedPurchaser = (q1, q2, q3, q4) => {
    var isQualifiedPurchaser = false;

    if (q1 && q2) {
      isQualifiedPurchaser = true;
      var utcDate = getDateUTC();
      setQualificationDate(utcDate);
    } else if (!q3 && q4) {
      isQualifiedPurchaser = true;
      var utcDate = getDateUTC();
      setQualificationDate(utcDate);
    } else {
      isQualifiedPurchaser = false;
      setQualificationDate("");
    }
    return isQualifiedPurchaser;
  };

  const submitTrustAccountBasicInformation = async () => {
    var errors = false;
    setSubmissionInProgress(true);

    if (legalAccountName === "") {
      errors = true;
      setLegalAccountNameError("This field is required.");
    }
    if (countryOfFormation === "") {
      errors = true;
      setCountryOfFormationError("This field is required.");
    }
    if (
      trustQualificationQ1 === "" ||
      trustQualificationQ2 === "" ||
      trustQualificationQ3 === "" ||
      trustQualificationQ4 === ""
    ) {
      errors = true;
      setTrustAccountBasicInformationQualificationQuestionError(
        "Please answer all qualification questions."
      );
    } else if (
      !isTrustAccountQualifiedPurchaser(
        trustQualificationQ1,
        trustQualificationQ2,
        trustQualificationQ3,
        trustQualificationQ4
      )
    ) {
      errors = true;
      setTrustAccountBasicInformationQualificationQuestionError(
        "Sorry, your trust doesn't qualify as a qualified purchaser(QP). At this time, we only allow QPs on Aqua, so you will be unable to add this account."
      );
    }

    if (!errors) {
      //Code to make request to the backend will go here.
      const newAccountResp = await newAccount({
        account_type: "TRT",
        qualification_date: getDateUTC(),
        trust_q1_qp_5m_investments: trustQualificationQ1,
        trust_q2_qp_owned_by_family: trustQualificationQ2,
        trust_q3_qp_specific_purpose_invest_fund: trustQualificationQ3,
        trust_q4_qp_trustee_grantor_qp: trustQualificationQ4,
        legal_name: legalAccountName,
        entity_type: accountType,
        country_of_formation: countryOfFormation,
      });

      setAccountID(newAccountResp.data.detail);
      const accountList = await getAccountList();
      setUser((user) => ({ ...user, accountListData: accountList.data }));

      setActiveSlide((prevState) => prevState + 1);
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  useEffect(() => {
    setTrustAccountBasicInformationQualificationQuestionError("");
  }, [
    trustQualificationQ1,
    trustQualificationQ2,
    trustQualificationQ3,
    trustQualificationQ4,
  ]);

  return (
    shouldRenderTrustQualificationSection && (
      <React.Fragment>
        <div className="row g-0 w-100 mb-3">
          <div className="col-6 pe-2">
            <SelectField
              value={countryOfFormation}
              setValue={setCountryOfFormation}
              valueError={countryOfFormationError}
              setValueError={setCountryOfFormationError}
              selectOptions={processedCountryList}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"formation-country-select"}
              label={"Country of Formation"}
            />
          </div>
        </div>
        <TrustAccountBasicInformationQualificationSection
          showTrustQualificationSection={showTrustQualificationSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          trustQualificationQ1={trustQualificationQ1}
          setTrustQualificationQ1={setTrustQualificationQ1}
          trustQualificationQ2={trustQualificationQ2}
          setTrustQualificationQ2={setTrustQualificationQ2}
          trustQualificationQ3={trustQualificationQ3}
          setTrustQualificationQ3={setTrustQualificationQ3}
          trustQualificationQ4={trustQualificationQ4}
          setTrustQualificationQ4={setTrustQualificationQ4}
          trustAccountBasicInformationQualificationQuestionError={
            trustAccountBasicInformationQualificationQuestionError
          }
        />
        <TrustAccountBasicInformationButton
          loading={submissionInProgress}
          showTrustQualificationSection={showTrustQualificationSection}
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          onClick={() => submitTrustAccountBasicInformation()}
        />
      </React.Fragment>
    )
  );
}
export default TrustAccountBasicInformation;
