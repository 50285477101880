import React, { useState, useEffect, useContext } from "react";
import "./AccountDetailsPageStyles/AccountDetailsPageStyles.scss";
import IndividualAccountDetails from "./IndividualAccountDetails/IndividualAccountDetails";
import TrustAccountDetails from "./TrustAccountDetails/TrustAccountDetails";
import EntityAccountDetails from "./EntityAccountDetails/EntityAccountDetails";
import Header from "../../../../Components/GlobalComponents/Header";
import { getAccountSummary } from "./../../../../Api/ProfileApi.js";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../GlobalContexts/UserContext";
import * as dayjs from "dayjs";

function AccountDetailsPage() {
  const { user, setUser } = useContext(UserContext);

  let { id } = useParams();

  //make sure that the date received from the backend is converted into this format before updating the state
  const [dob, setDob] = useState(null);
  const [dobError, setDobError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [qualificationLevel, setQualificationLevel] = useState("");

  const [accountNumber, setAccountNumber] = useState("");
  const [dateAdded, setDateAdded] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [domicileCountry, setDomicileCountry] = useState("");
  const [domicileCountryError, setDomicileCountryError] = useState("");

  const [idNumber, setIDNumber] = useState("");
  const [idNumberError, setIDNumberError] = useState("");

  const [verificationStatus, setVerificationStatus] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");

  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const [trustType, setTrustType] = useState("");
  const [trustTypeError, setTrustTypeError] = useState("");

  const [accountNameError, setAccountNameError] = useState("");

  const [countryOfFormation, setCountryOfFormation] = useState("");
  const [countryOfFormationError, setCountryOfFormationError] = useState("");

  const [idType, setIDType] = useState("");
  const [idTypeError, setIDTypeError] = useState("");

  const [grantorFirstName, setGrantorFirstName] = useState("");
  const [grantorFirstNameError, setGrantorFirstNameError] = useState("");

  const [grantorLastName, setGrantorLastName] = useState("");
  const [grantorLastNameError, setGrantorLastNameError] = useState("");

  const [grantorPhoneNumber, setGrantorPhoneNumber] = useState("");
  const [grantorPhoneNumberError, setGrantorPhoneNumberError] = useState("");

  const [grantorEmail, setGrantorEmail] = useState("");
  const [grantorEmailError, setGrantorEmailError] = useState("");

  const [grantorDomicileCountry, setGrantorDomicileCountry] = useState("");
  const [grantorDomicileCountryError, setGrantorDomicileCountryError] =
    useState("");

  const [grantorAddressLine1, setGrantorAddressLine1] = useState("");
  const [grantorAddressLine1Error, setGrantorAddressLine1Error] = useState("");

  const [grantorAddressLine2, setGrantorAddressLine2] = useState("");
  const [grantorAddressLine2Error, setGrantorAddressLine2Error] = useState("");

  const [grantorCity, setGrantorCity] = useState("");
  const [grantorCityError, setGrantorCityError] = useState("");

  const [grantorState, setGrantorState] = useState("");
  const [grantorStateError, setGrantorStateError] = useState("");

  const [grantorCountry, setGrantorCountry] = useState("");
  const [grantorCountryError, setGrantorCountryError] = useState("");

  const [grantorPostalCode, setGrantorPostalCode] = useState("");
  const [grantorPostalCodeError, setGrantorPostalCodeError] = useState("");

  const [grantorDob, setGrantorDob] = useState("");
  const [grantorDobError, setGrantorDobError] = useState("");

  const [grantorIDNumber, setGrantorIDNumber] = useState("");
  const [grantorIDNumberError, setGrantorIDNumberError] = useState("");

  const [entityType, setEntityType] = useState("");
  const [entityTypeError, setEntityTypeError] = useState("");

  const [jointFirstName, setJointFirstName] = useState("");
  const [jointLastName, setJointLastName] = useState("");
  const [jointEmail, setJointEmail] = useState("");
  const [jointDomicileCountry, setJointDomicileCountry] = useState("");
  const [jointAddressLine1, setJointAddressLine1] = useState("");
  const [jointAddressLine2, setJointAddressLine2] = useState("");
  const [jointCity, setJointCity] = useState("");
  const [jointCountry, setJointCountry] = useState("");

  const [jointState, setJointState] = useState("");
  const [jointPostalCode, setJointPostalCode] = useState("");
  const [jointIDNumber, setJointIDNumber] = useState("");
  const [jointIDType, setJointIDType] = useState("");
  const [jointDob, setJointDob] = useState("");
  const [jointPhoneNumber, setJointPhoneNumber] = useState("");

  const jointInfo = {
    jointFirstName,
    setJointFirstName,
    jointLastName,
    setJointLastName,
    setJointEmail,
    jointEmail,
    jointDomicileCountry,
    setJointDomicileCountry,
    jointAddressLine1,
    setJointAddressLine1,
    jointAddressLine2,
    setJointAddressLine2,
    jointCity,
    setJointCity,
    jointCountry,
    setJointCountry,
    jointState,
    setJointState,
    jointPostalCode,
    setJointPostalCode,
    jointIDNumber,
    setJointIDNumber,
    jointIDType,
    setJointIDType,
    jointPhoneNumber,
    setJointPhoneNumber,
    jointDob,
    setJointDob,
  };

  const [relatedAccounts, setRelatedAccounts] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressType: "",
      deliverableAddressLine1: "",
      deliverableAddressLine2: "",
      deliverableCity: "",
      deliverableState: "",
      deliverableCountry: "",
      deliverablePostalCode: "",
      deliverableAddressType: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idType: "",
      idNum: "",
      idNumError: "",
      domicileCountry: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      addressLine1Error: "",
      addressLine2Error: "",
      cityError: "",
      stateError: "",
      countryError: "",
      postalCodeError: "",
      deliverableAddressLine1Error: "",
      deliverableAddressLine2Error: "",
      deliverableCityError: "",
      deliverableStateError: "",
      deliverableCountryError: "",
      deliverablePostalCodeError: "",
      primaryAddressDeliverable: "",
      domicileCountryError: "",
      deliverableSelectionError: "",
    },
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      addressType: "",
      deliverableAddressLine1: "",
      deliverableAddressLine2: "",
      deliverableCity: "",
      deliverableState: "",
      deliverableCountry: "",
      deliverablePostalCode: "",
      deliverableAddressType: "",
      dateOfBirth: "",
      dateOfBirthError: "",
      idType: "",
      idNum: "",
      idNumError: "",
      domicileCountry: "",
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      addressLine1Error: "",
      addressLine2Error: "",
      cityError: "",
      stateError: "",
      countryError: "",
      postalCodeError: "",
      deliverableAddressLine1Error: "",
      deliverableAddressLine2Error: "",
      deliverableCityError: "",
      deliverableStateError: "",
      deliverableCountryError: "",
      deliverablePostalCodeError: "",
      primaryAddressDeliverable: "",
      domicileCountryError: "",
      deliverableSelectionError: "",
    },
  ]);

  const [beneficialOwnerRelatedAccounts, setBeneficialOwnerRelatedAccounts] =
    useState([
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
      },
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
      },
    ]);

  const statusCodes = {
    A: "Pending Information",
    I: "Pending Verification",
    P: "Pending Verification",
    F: "Failed Verification",
    S: "Successful Verification",
  };

  const qualCodes = {
    AC: "Accredited",
    QP: "Qualified Purchaser",
    NA: "Not Authorized",
  };

  useEffect(() => {
    async function parseAccountSummaryData() {
      const accountListData = user.accountListData.find(
        (account) => account.id === id
      );

      user &&
        user.investorSummaryData &&
        accountType === "IND" &&
        setDomicileCountry(user.investorSummaryData.country);

      let dateNoTime =
        user &&
        accountListData &&
        dayjs(
          dayjs(accountListData.date_added.substring(0, 10)).format(
            "MMM D, YYYY"
          )
        );
      let unixDate = user && dayjs(dateNoTime).unix();
      let formattedDate = user && dayjs.unix(unixDate).format("MMM D, YYYY");
      setDateAdded(formattedDate);

      const accountSummaryResp = await getAccountSummary(id);
      const accountSummaryData = accountSummaryResp.data[0];

      setEntityType(
        user
          ? user.accountListData.length !== 0
            ? accountSummaryData.entities.length !== 0
              ? accountSummaryData.entities[0].type
              : ""
            : ""
          : ""
      );

      setAccountType(accountSummaryData.account_type);
      setAccountNumber(accountSummaryData.number);
      setQualificationLevel(
        qualCodes[
          accountSummaryData.account_qualifications[0].qualification_type
        ]
      );
      setVerificationStatus(
        accountSummaryData.account_verifications.length === 0
          ? "Pending Verification"
          : statusCodes[accountSummaryData.account_verifications[0].status]
      );

      if (
        accountSummaryData.account_type === "IND" ||
        accountSummaryData.account_type === "JOINT"
      ) {
        setFirstName(user ? user.investorSummaryData.first_name : "");
        setLastName(user ? user.investorSummaryData.last_name : "");
        setEmail(user ? user.investorSummaryData.email : "");
        setPhoneNumber(user ? user.investorSummaryData.phone_number : "");
        setDomicileCountry(user ? user.investorSummaryData.country : "");

        setDob(
          user
            ? user.userProfileData.investor_pii
              ? user.userProfileData.investor_pii[0].date_of_birth
              : ""
            : ""
        );

        setAddressLine1(
          user
            ? user.userProfileData.investor_addresses
              ? user.userProfileData.investor_addresses[0].address_line_1
              : ""
            : ""
        );
        setAddressLine2(
          user
            ? user.userProfileData.investor_addresses
              ? user.userProfileData.investor_addresses[0].address_line_2
              : ""
            : ""
        );
        setCity(
          user
            ? user.userProfileData.investor_addresses
              ? user.userProfileData.investor_addresses[0].city
              : ""
            : ""
        );

        setCountry(
          user
            ? user.userProfileData.investor_addresses
              ? user.userProfileData.investor_addresses[0].country
              : ""
            : ""
        );

        setState(
          user
            ? user.userProfileData.investor_addresses
              ? user.userProfileData.investor_addresses[0].state
              : ""
            : ""
        );

        setPostalCode(
          user
            ? user.userProfileData.investor_addresses
              ? user.userProfileData.investor_addresses[0].postal_code
              : ""
            : ""
        );

        setIDNumber(
          user
            ? user.userProfileData.investor_pii
              ? user.userProfileData.investor_pii[0].id_num
              : ""
            : ""
        );

        setIDType(
          user
            ? user.userProfileData.investor_pii
              ? user.userProfileData.investor_pii[0].id_type
              : ""
            : ""
        );
      } else if (accountSummaryData.account_type === "TRT") {
        let grantorData = {};
        if (accountSummaryData.related_parties.length !== 0) {
          accountSummaryData.related_parties.forEach((relatedParty) => {
            if (relatedParty.role === "G") {
              grantorData = relatedParty;
            }
          });
        }

        if (
          Object.keys(grantorData).length !== 0 &&
          grantorData.addresses.length !== 0
        ) {
          if (grantorData.addresses.length === 1) {
            setGrantorAddressLine1(grantorData.addresses[0].address_line_1);
            setGrantorAddressLine2(grantorData.addresses[0].address_line_2);
            setGrantorCity(grantorData.addresses[0].city);
            setGrantorState(grantorData.addresses[0].state);
            setGrantorCountry(grantorData.addresses[0].country);
            setGrantorPostalCode(grantorData.addresses[0].postal_code);
          } else {
            grantorData.addresses.forEach((address) => {
              setGrantorAddressLine1(address.address_line_1);
              setGrantorAddressLine2(address.address_line_2);
              setGrantorCity(address.city);
              setGrantorState(address.state);
              setGrantorCountry(address.country);
              setGrantorPostalCode(address.postal_code);
            });
          }
        }

        if (Object.keys(grantorData).length !== 0) {
          if (grantorData.pii.length !== 0) {
            setGrantorDob(grantorData.pii[0].date_of_birth);
            setGrantorIDNumber(grantorData.pii[0].id_number);
          }
          setGrantorFirstName(grantorData.first_name);
          setGrantorLastName(grantorData.last_name);
          setGrantorEmail(grantorData.email);
          setGrantorPhoneNumber(grantorData.phone_number);
          setGrantorDomicileCountry(grantorData.domicile_country);
        }

        if (
          user &&
          accountSummaryData &&
          user.accountListData.length !== 0 &&
          accountSummaryData.entities.length !== 0 &&
          accountSummaryData.entities[0].addresses.length !== 0
        ) {
          if (accountSummaryData.entities[0].addresses.length === 1) {
            setAddressLine1(
              accountSummaryData.entities[0].addresses[0].address_line_1
            );
            setAddressLine2(
              accountSummaryData.entities[0].addresses[0].address_line_2
            );
            setCity(accountSummaryData.entities[0].addresses[0].city);
            setState(accountSummaryData.entities[0].addresses[0].state);
            setCountry(accountSummaryData.entities[0].addresses[0].country);
            setPostalCode(
              accountSummaryData.entities[0].addresses[0].postal_code
            );
          } else {
            accountSummaryData.entities[0].addresses.forEach((addressEntry) => {
              setAddressLine1(addressEntry.address_line_1);
              setAddressLine2(addressEntry.address_line_2);
              setCity(addressEntry.city);
              setState(addressEntry.state);
              setCountry(addressEntry.country);
              setPostalCode(addressEntry.postal_code);
            });
          }
        }

        if (
          user &&
          accountSummaryData &&
          user.accountListData.length !== 0 &&
          accountSummaryData.entities.length !== 0 &&
          accountSummaryData.entities[0].tax_info.length !== 0
        ) {
          setIDNumber(accountSummaryData.entities[0].tax_info[0].id_number);
          let taxTypes = {
            FTID: "Foreign Tax ID",
            EIN: "EIN",
            ITIN: "ITIN",
          };
          setIDType(
            taxTypes[accountSummaryData.entities[0].tax_info[0].id_type]
          );
        }

        setCountryOfFormation(
          user
            ? user.accountListData.length !== 0
              ? accountSummaryData.entities.length !== 0
                ? accountSummaryData.entities[0].country_of_formation
                : ""
              : ""
            : ""
        );

        let types = {
          TRUST: "Irrevocable Trust",
          LT: "Revocable Trust",
        };

        setTrustType(
          user
            ? user.accountListData.length !== 0
              ? accountSummaryData.entities.length !== 0
                ? accountSummaryData.entities[0].type
                : ""
              : ""
            : ""
        );

        setAccountName(user && accountSummaryData.entities[0].legal_name);

        let accountTrustees = accountSummaryData.related_parties
          .filter((relatedParty) => relatedParty.role === "TST")
          .map((relatedParty) => {
            return {
              relatedPartyType: "Person",
              relatedPartyTypeError: "",
              entityPercentOwned: "",
              entityPercentOwnedError: "",
              entityName: "",
              entityNameError: "",
              entityAddressLine1: "",
              entityAddressLine1Error: "",
              entityAddressLine2: "",
              entityAddressLine2Error: "",
              entityCity: "",
              entityCityError: "",
              entityState: "",
              entityStateError: "",
              entityPostalCode: "",
              entityPostalCodeError: "",
              entityTaxIDNumber: "",
              entityTaxIDNumberError: "",
              entityPhoneNumber: "",
              entityPhoneNumberError: "",
              entityStateOfIncorporation: "",
              entityStateOfIncorporationError: "",
              entityMailingAddressDifferent: "",
              entityMailingAddressDifferentError: "",
              entityMailingAddressLine1: "",
              entityMailingAddressLine1Error: "",
              entityMailingAddressLine2: "",
              entityMailingAddressLine2Error: "",
              entityMailingCity: "",
              entityMailingCityError: "",
              entityMailingState: "",
              entityMailingStateError: "",
              entityMailingPostalCode: "",
              entityMailingPostalCodeError: "",
              entityType: "",
              entityTypeError: "",
              entityControlPersonFirstName: "",
              entityControlPersonFirstNameError: "",
              entityControlPersonLastName: "",
              entityControlPersonLastNameError: "",
              entityControlPersonEmail: "",
              entityControlPersonEmailError: "",
              entityControlPersonIsUSBased: "",
              entityControlPersonIsUSBasedError: "",
              entityControlPersonIsUSCitizen: "",
              entityControlPersonIsUSCitizenError: "",
              entityControlPersonPIIAvailable: "",
              entityControlPersonPIIAvailableError: "",
              entityControlPersonAddressLine1: "",
              entityControlPersonAddressLine1Error: "",
              entityControlPersonAddressLine2: "",
              entityControlPersonAddressLine2Error: "",
              entityControlPersonCity: "",
              entityControlPersonCityError: "",
              entityControlPersonState: "",
              entityControlPersonStateError: "",
              entityControlPersonPostalCode: "",
              entityControlPersonPostalCodeError: "",
              entityControlPersonPhoneNumber: "",
              entityControlPersonPhoneNumberError: "",
              entityControlPersonTaxIDNumber: "",
              entityControlPersonTaxIDNumberError: "",
              entityControlPersonDOB: "",
              entityControlPersonDOBError: "",
              entityCertificateOfFormation: "",
              entityCertificateOfFormationError: "",
              entityProofOfAddress: "",
              entityProofOfAddressError: "",
              entityControlPersonPassportDocument: "",
              entityControlPersonPassportDocumentError: "",
              entityControlPersonProofOfAddress: "",
              entityControlPersonProofOfAddressError: "",
              entityIsTrustManagementCompany: "",
              entityIsTrustManagementCompanyError: "",
              entityHasAlternateSignatory: "",
              entityHasAlternateSignatoryError: "",
              entityAlternateSignatoryFullName: "",
              entityAlternateSignatoryFullNameError: "",
              entityAlternateSignatoryEmail: "",
              entityAlternateSignatoryEmailError: "",
              entityAlternateSignatoryTitle: "",
              entityAlternateSignatoryTitleError: "",
              entityControlPersonRole: "",
              entityControlPersonRoleError: "",
              entityNestedControlEntityTrusteeType: "",
              entityNestedControlEntityTrusteeTypeError: "",
              entityNestedControlEntityName: "",
              entityNestedControlEntityNameError: "",
              entityNestedControlEntityType: "",
              entityNestedControlEntityTypeError: "",
              entityNestedControlEntityPercentOwned: "",
              entityNestedControlEntityPercentOwnedError: "",
              entityNestedControlEntityStateOfIncorporation: "",
              entityNestedControlEntityStateOfIncorporationError: "",
              entityNestedControlEntityTaxIDNumber: "",
              entityNestedControlEntityTaxIDNumberError: "",
              entityNestedControlEntityPhoneNumber: "",
              entityNestedControlEntityPhoneNumberError: "",
              entityNestedControlEntityAddressLine1: "",
              entityNestedControlEntityAddressLine1Error: "",
              entityNestedControlEntityAddressLine2: "",
              entityNestedControlEntityAddressLine2Error: "",
              entityNestedControlEntityCity: "",
              entityNestedControlEntityCityError: "",
              entityNestedControlEntityState: "",
              entityNestedControlEntityStateError: "",
              entityNestedControlEntityPostalCode: "",
              entityNestedControlEntityPostalCodeError: "",
              entityNestedControlEntityIsTrustManagementCompany: "",
              entityNestedControlEntityIsTrustManagementCompanyError: "",
              entityNestedControlEntityAddressType: "",
              entityNestedControlEntityAddressTypeError: "",
              entityNestedControlEntityCertificateOfFormation: "",
              entityNestedControlEntityCertificateOfFormationError: "",
              entityNestedControlEntityProofOfAddress: "",
              entityNestedControlEntityProofOfAddressError: "",
              entityNestedControlEntityControlPersonFirstName: "",
              entityNestedControlEntityControlPersonFirstNameError: "",
              entityNestedControlEntityControlPersonLastName: "",
              entityNestedControlEntityControlPersonLastNameError: "",
              entityNestedControlEntityControlPersonEmail: "",
              entityNestedControlEntityControlPersonEmailError: "",
              entityNestedControlEntityControlPersonIsUSBased: "",
              entityNestedControlEntityControlPersonIsUSBasedError: "",
              entityNestedControlEntityControlPersonIsUSCitizen: "",
              entityNestedControlEntityControlPersonIsUSCitizenError: "",
              entityNestedControlEntityControlPersonPhoneNumber: "",
              entityNestedControlEntityControlPersonPhoneNumberError: "",
              entityNestedControlEntityControlPersonPIIAvailable: "",
              entityNestedControlEntityControlPersonPIIAvailableError: "",
              entityNestedControlEntityControlPersonAddressLine1: "",
              entityNestedControlEntityControlPersonAddressLine1Error: "",
              entityNestedControlEntityControlPersonAddressLine2: "",
              entityNestedControlEntityControlPersonAddressLine2Error: "",
              entityNestedControlEntityControlPersonCity: "",
              entityNestedControlEntityControlPersonCityError: "",
              entityNestedControlEntityControlPersonState: "",
              entityNestedControlEntityControlPersonStateError: "",
              entityNestedControlEntityControlPersonPostalCode: "",
              entityNestedControlEntityControlPersonPostalCodeError: "",
              entityNestedControlEntityControlPersonTaxIDNumber: "",
              entityNestedControlEntityControlPersonTaxIDNumberError: "",
              entityNestedControlEntityControlPersonDOB: "",
              entityNestedControlEntityControlPersonDOBError: "",
              entityNestedControlEntityControlPersonPassportDocument: "",
              entityNestedControlEntityControlPersonPassportDocumentError: "",
              entityNestedControlEntityControlPersonProofOfAddress: "",
              entityNestedControlEntityControlPersonProofOfAddressError: "",
              entityNestedControlEntityControlPersonAddressType: "",
              entityNestedControlEntityControlPersonAddressTypeError: "",
              firstName: relatedParty.first_name,
              lastName: relatedParty.last_name,
              email: relatedParty.email,
              phoneNumber: relatedParty.phone_number,
              addressLine1: relatedParty.addresses[0].address_line_1,
              addressLine2: relatedParty.addresses[0].address_line_2,
              city: relatedParty.addresses[0].city,
              state: relatedParty.addresses[0].state,
              country: relatedParty.addresses[0].country,
              postalCode: relatedParty.addresses[0].postal_code,
              addressType: relatedParty.addresses[0].address_type,
              deliverableAddressLine1: "",
              deliverableAddressLine2: "",
              deliverableCity: "",
              deliverableState: "",
              deliverableCountry: "",
              deliverablePostalCode: "",
              deliverableAddressType: "",
              dateOfBirth: relatedParty.pii[0].date_of_birth,
              dateOfBirthError: "",
              idType: relatedParty.pii[0].id_type,
              idNum: relatedParty.pii[0].id_number,
              idNumError: "",
              domicileCountry: relatedParty.domicile_country,
              firstNameError: "",
              lastNameError: "",
              emailError: "",
              phoneNumberError: "",
              addressLine1Error: "",
              addressLine2Error: "",
              cityError: "",
              stateError: "",
              countryError: "",
              postalCodeError: "",
              deliverableAddressLine1Error: "",
              deliverableAddressLine2Error: "",
              deliverableCityError: "",
              deliverableStateError: "",
              deliverableCountryError: "",
              deliverablePostalCodeError: "",
              primaryAddressDeliverable: "",
              domicileCountryError: "",
              deliverableSelectionError: "",
              percentOwned: relatedParty.percent_owned,
              percentOwnedError: "",
              citizenship: relatedParty.citizenship,
              citizenshipError: "",
            };
          });

        let accountTrusteeEntities = accountSummaryData.related_parties_entities
          .filter((relatedParty) => relatedParty.role === "TST")
          .map((relatedParty) => {
            return {
              relatedPartyType: "Entity",
              relatedPartyTypeError: "",
              entityPercentOwned: relatedParty.entity_percent_owned,
              entityPercentOwnedError: "",
              entityName: relatedParty.entity_name,
              entityNameError: "",
              entityAddressLine1: relatedParty.entity_address_line_1,
              entityAddressLine1Error: "",
              entityAddressLine2: relatedParty.entity_address_line_2,
              entityAddressLine2Error: "",
              entityCity: relatedParty.entity_city,
              entityCityError: "",
              entityState: relatedParty.entity_state,
              entityStateError: "",
              entityPostalCode: relatedParty.entity_postal_code,
              entityPostalCodeError: "",
              entityTaxIDNumber: relatedParty.entity_tax_id_number,
              entityTaxIDNumberError: "",
              entityPhoneNumber: relatedParty.entity_phone_number,
              entityPhoneNumberError: "",
              entityStateOfIncorporation:
                relatedParty.entity_state_of_incorporation,
              entityStateOfIncorporationError: "",
              entityMailingAddressDifferent:
                relatedParty.entity_mailing_address_line_1 !== ""
                  ? "Yes"
                  : "No",
              entityMailingAddressDifferentError: "",
              entityMailingAddressLine1:
                relatedParty.entity_mailing_address_line_1,
              entityMailingAddressLine1Error: "",
              entityMailingAddressLine2:
                relatedParty.entity_mailing_address_line_2,
              entityMailingAddressLine2Error: "",
              entityMailingCity: relatedParty.entity_mailing_city,
              entityMailingCityError: "",
              entityMailingState: relatedParty.entity_mailing_state,
              entityMailingStateError: "",
              entityMailingPostalCode: relatedParty.entity_mailing_postal_code,
              entityMailingPostalCodeError: "",
              entityType: relatedParty.entity_type,
              entityTypeError: "",
              entityControlPersonFirstName:
                relatedParty.entity_control_person_first_name,
              entityControlPersonFirstNameError: "",
              entityControlPersonLastName:
                relatedParty.entity_control_person_last_name,
              entityControlPersonLastNameError: "",
              entityControlPersonEmail:
                relatedParty.entity_control_person_email,
              entityControlPersonEmailError: "",
              entityControlPersonIsUSBased:
                relatedParty.entity_control_person_is_us_based === true ||
                relatedParty.entity_control_person_is_us_based === "Yes"
                  ? "Yes"
                  : "No",
              entityControlPersonIsUSBasedError: "",
              entityControlPersonIsUSCitizen:
                relatedParty.entity_control_person_is_us_citizen === true ||
                relatedParty.entity_control_person_is_us_citizen === "Yes"
                  ? "Yes"
                  : "No",
              entityControlPersonIsUSCitizenError: "",
              entityControlPersonPIIAvailable:
                relatedParty.entity_control_person_address_line_1 !== ""
                  ? "Yes"
                  : "No",
              entityControlPersonPIIAvailableError: "",
              entityControlPersonAddressLine1:
                relatedParty.entity_control_person_address_line_1,
              entityControlPersonAddressLine1Error: "",
              entityControlPersonAddressLine2:
                relatedParty.entity_control_person_address_line_2,
              entityControlPersonAddressLine2Error: "",
              entityControlPersonCity: relatedParty.entity_control_person_city,
              entityControlPersonCityError: "",
              entityControlPersonState:
                relatedParty.entity_control_person_state,
              entityControlPersonStateError: "",
              entityControlPersonPostalCode:
                relatedParty.entity_control_person_postal_code,
              entityControlPersonPostalCodeError: "",
              entityControlPersonPhoneNumber:
                relatedParty.entity_control_person_phone_number,
              entityControlPersonPhoneNumberError: "",
              entityControlPersonTaxIDNumber:
                relatedParty.entity_control_person_tax_id_number,
              entityControlPersonTaxIDNumberError: "",
              entityControlPersonDOB: relatedParty.entity_control_person_dob,
              entityControlPersonDOBError: "",
              entityCertificateOfFormation: "",
              entityCertificateOfFormationError: "",
              entityProofOfAddress: "",
              entityProofOfAddressError: "",
              entityControlPersonPassportDocument: "",
              entityControlPersonPassportDocumentError: "",
              entityControlPersonProofOfAddress: "",
              entityControlPersonProofOfAddressError: "",
              entityIsTrustManagementCompany: "",
              entityIsTrustManagementCompanyError: "",
              entityHasAlternateSignatory: "",
              entityHasAlternateSignatoryError: "",
              entityAlternateSignatoryFullName: "",
              entityAlternateSignatoryFullNameError: "",
              entityAlternateSignatoryEmail: "",
              entityAlternateSignatoryEmailError: "",
              entityAlternateSignatoryTitle: "",
              entityAlternateSignatoryTitleError: "",
              entityControlPersonRole: "",
              entityControlPersonRoleError: "",
              entityNestedControlEntityTrusteeType: "",
              entityNestedControlEntityTrusteeTypeError: "",
              entityNestedControlEntityName: "",
              entityNestedControlEntityNameError: "",
              entityNestedControlEntityType: "",
              entityNestedControlEntityTypeError: "",
              entityNestedControlEntityPercentOwned: "",
              entityNestedControlEntityPercentOwnedError: "",
              entityNestedControlEntityStateOfIncorporation: "",
              entityNestedControlEntityStateOfIncorporationError: "",
              entityNestedControlEntityTaxIDNumber: "",
              entityNestedControlEntityTaxIDNumberError: "",
              entityNestedControlEntityPhoneNumber: "",
              entityNestedControlEntityPhoneNumberError: "",
              entityNestedControlEntityAddressLine1: "",
              entityNestedControlEntityAddressLine1Error: "",
              entityNestedControlEntityAddressLine2: "",
              entityNestedControlEntityAddressLine2Error: "",
              entityNestedControlEntityCity: "",
              entityNestedControlEntityCityError: "",
              entityNestedControlEntityState: "",
              entityNestedControlEntityStateError: "",
              entityNestedControlEntityPostalCode: "",
              entityNestedControlEntityPostalCodeError: "",
              entityNestedControlEntityIsTrustManagementCompany: "",
              entityNestedControlEntityIsTrustManagementCompanyError: "",
              entityNestedControlEntityAddressType: "",
              entityNestedControlEntityAddressTypeError: "",
              entityNestedControlEntityCertificateOfFormation: "",
              entityNestedControlEntityCertificateOfFormationError: "",
              entityNestedControlEntityProofOfAddress: "",
              entityNestedControlEntityProofOfAddressError: "",
              entityNestedControlEntityControlPersonFirstName: "",
              entityNestedControlEntityControlPersonFirstNameError: "",
              entityNestedControlEntityControlPersonLastName: "",
              entityNestedControlEntityControlPersonLastNameError: "",
              entityNestedControlEntityControlPersonEmail: "",
              entityNestedControlEntityControlPersonEmailError: "",
              entityNestedControlEntityControlPersonIsUSBased: "",
              entityNestedControlEntityControlPersonIsUSBasedError: "",
              entityNestedControlEntityControlPersonIsUSCitizen: "",
              entityNestedControlEntityControlPersonIsUSCitizenError: "",
              entityNestedControlEntityControlPersonPhoneNumber: "",
              entityNestedControlEntityControlPersonPhoneNumberError: "",
              entityNestedControlEntityControlPersonPIIAvailable: "",
              entityNestedControlEntityControlPersonPIIAvailableError: "",
              entityNestedControlEntityControlPersonAddressLine1: "",
              entityNestedControlEntityControlPersonAddressLine1Error: "",
              entityNestedControlEntityControlPersonAddressLine2: "",
              entityNestedControlEntityControlPersonAddressLine2Error: "",
              entityNestedControlEntityControlPersonCity: "",
              entityNestedControlEntityControlPersonCityError: "",
              entityNestedControlEntityControlPersonState: "",
              entityNestedControlEntityControlPersonStateError: "",
              entityNestedControlEntityControlPersonPostalCode: "",
              entityNestedControlEntityControlPersonPostalCodeError: "",
              entityNestedControlEntityControlPersonTaxIDNumber: "",
              entityNestedControlEntityControlPersonTaxIDNumberError: "",
              entityNestedControlEntityControlPersonDOB: "",
              entityNestedControlEntityControlPersonDOBError: "",
              entityNestedControlEntityControlPersonPassportDocument: "",
              entityNestedControlEntityControlPersonPassportDocumentError: "",
              entityNestedControlEntityControlPersonProofOfAddress: "",
              entityNestedControlEntityControlPersonProofOfAddressError: "",
              entityNestedControlEntityControlPersonAddressType: "",
              entityNestedControlEntityControlPersonAddressTypeError: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              country: "",
              postalCode: "",
              addressType: "",
              deliverableAddressLine1: "",
              deliverableAddressLine2: "",
              deliverableCity: "",
              deliverableState: "",
              deliverableCountry: "",
              deliverablePostalCode: "",
              deliverableAddressType: "",
              dateOfBirth: "",
              dateOfBirthError: "",
              idType: "",
              idNum: "",
              idNumError: "",
              domicileCountry: "",
              firstNameError: "",
              lastNameError: "",
              emailError: "",
              phoneNumberError: "",
              addressLine1Error: "",
              addressLine2Error: "",
              cityError: "",
              stateError: "",
              countryError: "",
              postalCodeError: "",
              deliverableAddressLine1Error: "",
              deliverableAddressLine2Error: "",
              deliverableCityError: "",
              deliverableStateError: "",
              deliverableCountryError: "",
              deliverablePostalCodeError: "",
              primaryAddressDeliverable: "",
              domicileCountryError: "",
              deliverableSelectionError: "",
              percentOwned: "",
              percentOwnedError: "",
              citizenship: "",
              citizenshipError: "",
            };
          });

        setRelatedAccounts(accountTrustees.concat(accountTrusteeEntities));

        let accountBeneficialOwnerPersons = accountSummaryData.related_parties
          .filter((relatedParty) => relatedParty.role === "BO")
          .map((relatedParty) => {
            return {
              relatedPartyType: "Person",
              relatedPartyTypeError: "",
              entityPercentOwned: "",
              entityPercentOwnedError: "",
              entityName: "",
              entityNameError: "",
              entityAddressLine1: "",
              entityAddressLine1Error: "",
              entityAddressLine2: "",
              entityAddressLine2Error: "",
              entityCity: "",
              entityCityError: "",
              entityState: "",
              entityStateError: "",
              entityPostalCode: "",
              entityPostalCodeError: "",
              entityTaxIDNumber: "",
              entityTaxIDNumberError: "",
              entityPhoneNumber: "",
              entityPhoneNumberError: "",
              entityStateOfIncorporation: "",
              entityStateOfIncorporationError: "",
              entityMailingAddressDifferent: "",
              entityMailingAddressDifferentError: "",
              entityMailingAddressLine1: "",
              entityMailingAddressLine1Error: "",
              entityMailingAddressLine2: "",
              entityMailingAddressLine2Error: "",
              entityMailingCity: "",
              entityMailingCityError: "",
              entityMailingState: "",
              entityMailingStateError: "",
              entityMailingPostalCode: "",
              entityMailingPostalCodeError: "",
              entityType: "",
              entityTypeError: "",
              entityControlPersonFirstName: "",
              entityControlPersonFirstNameError: "",
              entityControlPersonLastName: "",
              entityControlPersonLastNameError: "",
              entityControlPersonEmail: "",
              entityControlPersonEmailError: "",
              entityControlPersonIsUSBased: "",
              entityControlPersonIsUSBasedError: "",
              entityControlPersonIsUSCitizen: "",
              entityControlPersonIsUSCitizenError: "",
              entityControlPersonPIIAvailable: "",
              entityControlPersonPIIAvailableError: "",
              entityControlPersonAddressLine1: "",
              entityControlPersonAddressLine1Error: "",
              entityControlPersonAddressLine2: "",
              entityControlPersonAddressLine2Error: "",
              entityControlPersonCity: "",
              entityControlPersonCityError: "",
              entityControlPersonState: "",
              entityControlPersonStateError: "",
              entityControlPersonPostalCode: "",
              entityControlPersonPostalCodeError: "",
              entityControlPersonPhoneNumber: "",
              entityControlPersonPhoneNumberError: "",
              entityControlPersonTaxIDNumber: "",
              entityControlPersonTaxIDNumberError: "",
              entityControlPersonDOB: "",
              entityControlPersonDOBError: "",
              entityCertificateOfFormation: "",
              entityCertificateOfFormationError: "",
              entityProofOfAddress: "",
              entityProofOfAddressError: "",
              entityControlPersonPassportDocument: "",
              entityControlPersonPassportDocumentError: "",
              entityControlPersonProofOfAddress: "",
              entityControlPersonProofOfAddressError: "",
              entityIsTrustManagementCompany: "",
              entityIsTrustManagementCompanyError: "",
              entityHasAlternateSignatory: "",
              entityHasAlternateSignatoryError: "",
              entityAlternateSignatoryFullName: "",
              entityAlternateSignatoryFullNameError: "",
              entityAlternateSignatoryEmail: "",
              entityAlternateSignatoryEmailError: "",
              entityAlternateSignatoryTitle: "",
              entityAlternateSignatoryTitleError: "",
              entityControlPersonRole: "",
              entityControlPersonRoleError: "",
              entityNestedControlEntityTrusteeType: "",
              entityNestedControlEntityTrusteeTypeError: "",
              entityNestedControlEntityName: "",
              entityNestedControlEntityNameError: "",
              entityNestedControlEntityType: "",
              entityNestedControlEntityTypeError: "",
              entityNestedControlEntityPercentOwned: "",
              entityNestedControlEntityPercentOwnedError: "",
              entityNestedControlEntityStateOfIncorporation: "",
              entityNestedControlEntityStateOfIncorporationError: "",
              entityNestedControlEntityTaxIDNumber: "",
              entityNestedControlEntityTaxIDNumberError: "",
              entityNestedControlEntityPhoneNumber: "",
              entityNestedControlEntityPhoneNumberError: "",
              entityNestedControlEntityAddressLine1: "",
              entityNestedControlEntityAddressLine1Error: "",
              entityNestedControlEntityAddressLine2: "",
              entityNestedControlEntityAddressLine2Error: "",
              entityNestedControlEntityCity: "",
              entityNestedControlEntityCityError: "",
              entityNestedControlEntityState: "",
              entityNestedControlEntityStateError: "",
              entityNestedControlEntityPostalCode: "",
              entityNestedControlEntityPostalCodeError: "",
              entityNestedControlEntityIsTrustManagementCompany: "",
              entityNestedControlEntityIsTrustManagementCompanyError: "",
              entityNestedControlEntityAddressType: "",
              entityNestedControlEntityAddressTypeError: "",
              entityNestedControlEntityCertificateOfFormation: "",
              entityNestedControlEntityCertificateOfFormationError: "",
              entityNestedControlEntityProofOfAddress: "",
              entityNestedControlEntityProofOfAddressError: "",
              entityNestedControlEntityControlPersonFirstName: "",
              entityNestedControlEntityControlPersonFirstNameError: "",
              entityNestedControlEntityControlPersonLastName: "",
              entityNestedControlEntityControlPersonLastNameError: "",
              entityNestedControlEntityControlPersonEmail: "",
              entityNestedControlEntityControlPersonEmailError: "",
              entityNestedControlEntityControlPersonIsUSBased: "",
              entityNestedControlEntityControlPersonIsUSBasedError: "",
              entityNestedControlEntityControlPersonIsUSCitizen: "",
              entityNestedControlEntityControlPersonIsUSCitizenError: "",
              entityNestedControlEntityControlPersonPhoneNumber: "",
              entityNestedControlEntityControlPersonPhoneNumberError: "",
              entityNestedControlEntityControlPersonPIIAvailable: "",
              entityNestedControlEntityControlPersonPIIAvailableError: "",
              entityNestedControlEntityControlPersonAddressLine1: "",
              entityNestedControlEntityControlPersonAddressLine1Error: "",
              entityNestedControlEntityControlPersonAddressLine2: "",
              entityNestedControlEntityControlPersonAddressLine2Error: "",
              entityNestedControlEntityControlPersonCity: "",
              entityNestedControlEntityControlPersonCityError: "",
              entityNestedControlEntityControlPersonState: "",
              entityNestedControlEntityControlPersonStateError: "",
              entityNestedControlEntityControlPersonPostalCode: "",
              entityNestedControlEntityControlPersonPostalCodeError: "",
              entityNestedControlEntityControlPersonTaxIDNumber: "",
              entityNestedControlEntityControlPersonTaxIDNumberError: "",
              entityNestedControlEntityControlPersonDOB: "",
              entityNestedControlEntityControlPersonDOBError: "",
              entityNestedControlEntityControlPersonPassportDocument: "",
              entityNestedControlEntityControlPersonPassportDocumentError: "",
              entityNestedControlEntityControlPersonProofOfAddress: "",
              entityNestedControlEntityControlPersonProofOfAddressError: "",
              entityNestedControlEntityControlPersonAddressType: "",
              entityNestedControlEntityControlPersonAddressTypeError: "",
              firstName: relatedParty.first_name,
              lastName: relatedParty.last_name,
              email: relatedParty.email,
              phoneNumber: relatedParty.phone_number,
              addressLine1: relatedParty.addresses[0].address_line_1,
              addressLine2: relatedParty.addresses[0].address_line_2,
              city: relatedParty.addresses[0].city,
              state: relatedParty.addresses[0].state,
              country: relatedParty.addresses[0].country,
              postalCode: relatedParty.addresses[0].postal_code,
              addressType: relatedParty.addresses[0].address_type,
              deliverableAddressLine1: "",
              deliverableAddressLine2: "",
              deliverableCity: "",
              deliverableState: "",
              deliverableCountry: "",
              deliverablePostalCode: "",
              deliverableAddressType: "",
              dateOfBirth: relatedParty.pii[0].date_of_birth,
              dateOfBirthError: "",
              idType: relatedParty.pii[0].id_type,
              idNum: relatedParty.pii[0].id_number,
              idNumError: "",
              domicileCountry: relatedParty.domicile_country,
              firstNameError: "",
              lastNameError: "",
              emailError: "",
              phoneNumberError: "",
              addressLine1Error: "",
              addressLine2Error: "",
              cityError: "",
              stateError: "",
              countryError: "",
              postalCodeError: "",
              deliverableAddressLine1Error: "",
              deliverableAddressLine2Error: "",
              deliverableCityError: "",
              deliverableStateError: "",
              deliverableCountryError: "",
              deliverablePostalCodeError: "",
              primaryAddressDeliverable: "",
              domicileCountryError: "",
              deliverableSelectionError: "",
              percentOwned: relatedParty.percent_owned,
              percentOwnedError: "",
              citizenship: relatedParty.citizenship,
              citizenshipError: "",
            };
          });

        let accountBeneficialOwnerEntities =
          accountSummaryData.related_parties_entities
            .filter((relatedParty) => relatedParty.role === "BO")
            .map((relatedParty) => {
              return {
                relatedPartyType: "Entity",
                relatedPartyTypeError: "",
                entityPercentOwned: relatedParty.entity_percent_owned,
                entityPercentOwnedError: "",
                entityName: relatedParty.entity_name,
                entityNameError: "",
                entityAddressLine1: relatedParty.entity_address_line_1,
                entityAddressLine1Error: "",
                entityAddressLine2: relatedParty.entity_address_line_2,
                entityAddressLine2Error: "",
                entityCity: relatedParty.entity_city,
                entityCityError: "",
                entityState: relatedParty.entity_state,
                entityStateError: "",
                entityPostalCode: relatedParty.entity_postal_code,
                entityPostalCodeError: "",
                entityTaxIDNumber: relatedParty.entity_tax_id_number,
                entityTaxIDNumberError: "",
                entityPhoneNumber: relatedParty.entity_phone_number,
                entityPhoneNumberError: "",
                entityStateOfIncorporation:
                  relatedParty.entity_state_of_incorporation,
                entityStateOfIncorporationError: "",
                entityMailingAddressDifferent:
                  relatedParty.entity_mailing_address_line_1 !== ""
                    ? "Yes"
                    : "No",
                entityMailingAddressDifferentError: "",
                entityMailingAddressLine1:
                  relatedParty.entity_mailing_address_line_1,
                entityMailingAddressLine1Error: "",
                entityMailingAddressLine2:
                  relatedParty.entity_mailing_address_line_2,
                entityMailingAddressLine2Error: "",
                entityMailingCity: relatedParty.entity_mailing_city,
                entityMailingCityError: "",
                entityMailingState: relatedParty.entity_mailing_state,
                entityMailingStateError: "",
                entityMailingPostalCode:
                  relatedParty.entity_mailing_postal_code,
                entityMailingPostalCodeError: "",
                entityType: relatedParty.entity_type,
                entityTypeError: "",
                entityControlPersonFirstName:
                  relatedParty.entity_control_person_first_name,
                entityControlPersonFirstNameError: "",
                entityControlPersonLastName:
                  relatedParty.entity_control_person_last_name,
                entityControlPersonLastNameError: "",
                entityControlPersonEmail:
                  relatedParty.entity_control_person_email,
                entityControlPersonEmailError: "",
                entityControlPersonIsUSBased:
                  relatedParty.entity_control_person_is_us_based === true ||
                  relatedParty.entity_control_person_is_us_based === "Yes"
                    ? "Yes"
                    : "No",
                entityControlPersonIsUSBasedError: "",
                entityControlPersonIsUSCitizen:
                  relatedParty.entity_control_person_is_us_citizen === true ||
                  relatedParty.entity_control_person_is_us_citizen === "Yes"
                    ? "Yes"
                    : "No",
                entityControlPersonIsUSCitizenError: "",
                entityControlPersonPIIAvailable:
                  relatedParty.entity_control_person_address_line_1 !== ""
                    ? "Yes"
                    : "No",
                entityControlPersonPIIAvailableError: "",
                entityControlPersonAddressLine1:
                  relatedParty.entity_control_person_address_line_1,
                entityControlPersonAddressLine1Error: "",
                entityControlPersonAddressLine2:
                  relatedParty.entity_control_person_address_line_2,
                entityControlPersonAddressLine2Error: "",
                entityControlPersonCity:
                  relatedParty.entity_control_person_city,
                entityControlPersonCityError: "",
                entityControlPersonState:
                  relatedParty.entity_control_person_state,
                entityControlPersonStateError: "",
                entityControlPersonPostalCode:
                  relatedParty.entity_control_person_postal_code,
                entityControlPersonPostalCodeError: "",
                entityControlPersonPhoneNumber:
                  relatedParty.entity_control_person_phone_number,
                entityControlPersonPhoneNumberError: "",
                entityControlPersonTaxIDNumber:
                  relatedParty.entity_control_person_tax_id_number,
                entityControlPersonTaxIDNumberError: "",
                entityControlPersonDOB: relatedParty.entity_control_person_dob,
                entityControlPersonDOBError: "",
                entityCertificateOfFormation: "",
                entityCertificateOfFormationError: "",
                entityProofOfAddress: "",
                entityProofOfAddressError: "",
                entityControlPersonPassportDocument: "",
                entityControlPersonPassportDocumentError: "",
                entityControlPersonProofOfAddress: "",
                entityControlPersonProofOfAddressError: "",
                entityIsTrustManagementCompany: "",
                entityIsTrustManagementCompanyError: "",
                entityHasAlternateSignatory: "",
                entityHasAlternateSignatoryError: "",
                entityAlternateSignatoryFullName: "",
                entityAlternateSignatoryFullNameError: "",
                entityAlternateSignatoryEmail: "",
                entityAlternateSignatoryEmailError: "",
                entityAlternateSignatoryTitle: "",
                entityAlternateSignatoryTitleError: "",
                entityControlPersonRole: "",
                entityControlPersonRoleError: "",
                entityNestedControlEntityTrusteeType:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_related_party_type
                    : "",
                entityNestedControlEntityTrusteeTypeError: "",
                entityNestedControlEntityName:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_name
                    : "",
                entityNestedControlEntityNameError: "",
                entityNestedControlEntityType:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_type
                    : "",
                entityNestedControlEntityTypeError: "",
                entityNestedControlEntityPercentOwned:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_percent_owned
                    : "",
                entityNestedControlEntityPercentOwnedError: "",
                entityNestedControlEntityStateOfIncorporation:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_state_of_incorporation
                    : "",
                entityNestedControlEntityStateOfIncorporationError: "",
                entityNestedControlEntityTaxIDNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_tax_id_number
                    : "",
                entityNestedControlEntityTaxIDNumberError: "",
                entityNestedControlEntityPhoneNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_phone_number
                    : "",
                entityNestedControlEntityPhoneNumberError: "",
                entityNestedControlEntityAddressLine1:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_address_line_1
                    : "",
                entityNestedControlEntityAddressLine1Error: "",
                entityNestedControlEntityAddressLine2:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_address_line_2
                    : "",
                entityNestedControlEntityAddressLine2Error: "",
                entityNestedControlEntityCity:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_city
                    : "",
                entityNestedControlEntityCityError: "",
                entityNestedControlEntityState:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_state
                    : "",
                entityNestedControlEntityStateError: "",
                entityNestedControlEntityPostalCode:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_postal_code
                    : "",
                entityNestedControlEntityPostalCodeError: "",
                entityNestedControlEntityIsTrustManagementCompany:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_is_trust_management_company
                    : "",
                entityNestedControlEntityIsTrustManagementCompanyError: "",
                entityNestedControlEntityAddressType:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_address_type
                    : "",
                entityNestedControlEntityAddressTypeError: "",
                entityNestedControlEntityCertificateOfFormation: "",
                entityNestedControlEntityCertificateOfFormationError: "",
                entityNestedControlEntityProofOfAddress: "",
                entityNestedControlEntityProofOfAddressError: "",
                entityNestedControlEntityControlPersonFirstName:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_first_name
                    : "",
                entityNestedControlEntityControlPersonFirstNameError: "",
                entityNestedControlEntityControlPersonLastName:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_last_name
                    : "",
                entityNestedControlEntityControlPersonLastNameError: "",
                entityNestedControlEntityControlPersonEmail:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_email
                    : "",
                entityNestedControlEntityControlPersonEmailError: "",
                entityNestedControlEntityControlPersonIsUSBased:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_is_us_based
                    : "",
                entityNestedControlEntityControlPersonIsUSBasedError: "",
                entityNestedControlEntityControlPersonIsUSCitizen:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_is_us_citizen
                    : "",
                entityNestedControlEntityControlPersonIsUSCitizenError: "",
                entityNestedControlEntityControlPersonPhoneNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_phone_number
                    : "",
                entityNestedControlEntityControlPersonPhoneNumberError: "",
                entityNestedControlEntityControlPersonPIIAvailable:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_address_line_1 !==
                      ""
                      ? "Yes"
                      : "No"
                    : "",
                entityNestedControlEntityControlPersonPIIAvailableError: "",
                entityNestedControlEntityControlPersonAddressLine1:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_address_line_1
                    : "",
                entityNestedControlEntityControlPersonAddressLine1Error: "",
                entityNestedControlEntityControlPersonAddressLine2:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_address_line_2
                    : "",
                entityNestedControlEntityControlPersonAddressLine2Error: "",
                entityNestedControlEntityControlPersonCity:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_city
                    : "",
                entityNestedControlEntityControlPersonCityError: "",
                entityNestedControlEntityControlPersonState:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_state
                    : "",
                entityNestedControlEntityControlPersonStateError: "",
                entityNestedControlEntityControlPersonPostalCode:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_postal_code
                    : "",
                entityNestedControlEntityControlPersonPostalCodeError: "",
                entityNestedControlEntityControlPersonTaxIDNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_tax_id_number
                    : "",
                entityNestedControlEntityControlPersonTaxIDNumberError: "",
                entityNestedControlEntityControlPersonDOB:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_dob
                    : "",
                entityNestedControlEntityControlPersonDOBError: "",
                entityNestedControlEntityControlPersonPassportDocument: "",
                entityNestedControlEntityControlPersonPassportDocumentError: "",
                entityNestedControlEntityControlPersonProofOfAddress: "",
                entityNestedControlEntityControlPersonProofOfAddressError: "",
                entityNestedControlEntityControlPersonAddressType: "",
                entityNestedControlEntityControlPersonAddressTypeError: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                country: "",
                postalCode: "",
                addressType: "",
                deliverableAddressLine1: "",
                deliverableAddressLine2: "",
                deliverableCity: "",
                deliverableState: "",
                deliverableCountry: "",
                deliverablePostalCode: "",
                deliverableAddressType: "",
                dateOfBirth: "",
                dateOfBirthError: "",
                idType: "",
                idNum: "",
                idNumError: "",
                domicileCountry: "",
                firstNameError: "",
                lastNameError: "",
                emailError: "",
                phoneNumberError: "",
                addressLine1Error: "",
                addressLine2Error: "",
                cityError: "",
                stateError: "",
                countryError: "",
                postalCodeError: "",
                deliverableAddressLine1Error: "",
                deliverableAddressLine2Error: "",
                deliverableCityError: "",
                deliverableStateError: "",
                deliverableCountryError: "",
                deliverablePostalCodeError: "",
                primaryAddressDeliverable: "",
                domicileCountryError: "",
                deliverableSelectionError: "",
                percentOwned: "",
                percentOwnedError: "",
                citizenship: "",
                citizenshipError: "",
              };
            });

        setBeneficialOwnerRelatedAccounts(
          accountBeneficialOwnerPersons.concat(accountBeneficialOwnerEntities)
        );
        // setRelatedAccounts(
        //   accountSummaryData.related_parties
        //     .filter(
        //       (relatedParty) =>
        //         relatedParty.role === "TST" || relatedParty.role === "BO"
        //     )
        //     .map((relatedParty) => ({
        //       firstName: relatedParty.first_name,
        //       lastName: relatedParty.last_name,
        //       email: relatedParty.email,
        //       phoneNumber: relatedParty.phone_number,
        //       addressLine1: relatedParty.addresses[0].address_line_1,
        //       addressLine2: relatedParty.addresses[0].address_line_2,
        //       city: relatedParty.addresses[0].city,
        //       country: relatedParty.addresses[0].country,
        //       state: relatedParty.addresses[0].state,
        //       postalCode: relatedParty.addresses[0].postal_code,
        //       dateOfBirth: relatedParty.pii[0].date_of_birth,
        //       idNum: relatedParty.pii[0].id_number,
        //       domicileCountry: relatedParty.domicile_country,
        //       idType: relatedParty.pii[0].id_type,
        //       percentOwned: relatedParty.percent_owned,
        //       citizenship: relatedParty.citizenship
        //     }))
        // );
      } else if (accountSummaryData.account_type === "ENT") {
        if (
          user &&
          accountSummaryData &&
          user.accountListData.length !== 0 &&
          accountSummaryData.entities.length !== 0 &&
          accountSummaryData.entities[0].addresses.length !== 0
        ) {
          if (accountSummaryData.entities[0].addresses.length === 1) {
            setAddressLine1(
              accountSummaryData.entities[0].addresses[0].address_line_1
            );
            setAddressLine2(
              accountSummaryData.entities[0].addresses[0].address_line_2
            );
            setCity(accountSummaryData.entities[0].addresses[0].city);
            setState(accountSummaryData.entities[0].addresses[0].state);
            setCountry(accountSummaryData.entities[0].addresses[0].country);
            setPostalCode(
              accountSummaryData.entities[0].addresses[0].postal_code
            );
          } else {
            accountSummaryData.entities[0].addresses.forEach((addressEntry) => {
              setAddressLine1(addressEntry.address_line_1);
              setAddressLine2(addressEntry.address_line_2);
              setCity(addressEntry.city);
              setState(addressEntry.state);
              setCountry(addressEntry.country);
              setPostalCode(addressEntry.postal_code);
            });
          }
        }

        if (
          user &&
          accountSummaryData &&
          user.accountListData.length !== 0 &&
          accountSummaryData.entities.length !== 0 &&
          accountSummaryData.entities[0].tax_info.length !== 0
        ) {
          setIDNumber(accountSummaryData.entities[0].tax_info[0].id_number);
          let taxTypes = {
            FTID: "Foreign Tax ID",
            EIN: "EIN",
            ITIN: "ITIN",
          };
          setIDType(
            taxTypes[accountSummaryData.entities[0].tax_info[0].id_type]
          );
        }

        setCountryOfFormation(
          user
            ? user.accountListData.length !== 0
              ? accountSummaryData.entities.length !== 0
                ? accountSummaryData.entities[0].country_of_formation
                : ""
              : ""
            : ""
        );

        let types = {
          TRUST: "Irrevocable Trust",
          LT: "Revocable Trust",
          LLC: "Limited Liability Corporation",
          "C-CORP": "C-Corporation",
          "S-CORP": "S-Corporation",
          LP: "Limited Partnership",
          GP: "General Partnership",
          FE: "Foreign Entity",
          JOINT: "Joint",
        };

        setTrustType(
          user
            ? user.accountListData.length !== 0
              ? accountSummaryData.entities.length !== 0
                ? accountSummaryData.entities[0].type
                : ""
              : ""
            : ""
        );
        setAccountName(user && accountSummaryData.entities[0].legal_name);

        let accountBeneficialOwnerPersons = accountSummaryData.related_parties
          .filter((relatedParty) => relatedParty.role === "BO")
          .map((relatedParty) => {
            return {
              relatedPartyType: "Person",
              relatedPartyTypeError: "",
              entityPercentOwned: "",
              entityPercentOwnedError: "",
              entityName: "",
              entityNameError: "",
              entityAddressLine1: "",
              entityAddressLine1Error: "",
              entityAddressLine2: "",
              entityAddressLine2Error: "",
              entityCity: "",
              entityCityError: "",
              entityState: "",
              entityStateError: "",
              entityPostalCode: "",
              entityPostalCodeError: "",
              entityTaxIDNumber: "",
              entityTaxIDNumberError: "",
              entityPhoneNumber: "",
              entityPhoneNumberError: "",
              entityStateOfIncorporation: "",
              entityStateOfIncorporationError: "",
              entityMailingAddressDifferent: "",
              entityMailingAddressDifferentError: "",
              entityMailingAddressLine1: "",
              entityMailingAddressLine1Error: "",
              entityMailingAddressLine2: "",
              entityMailingAddressLine2Error: "",
              entityMailingCity: "",
              entityMailingCityError: "",
              entityMailingState: "",
              entityMailingStateError: "",
              entityMailingPostalCode: "",
              entityMailingPostalCodeError: "",
              entityType: "",
              entityTypeError: "",
              entityControlPersonFirstName: "",
              entityControlPersonFirstNameError: "",
              entityControlPersonLastName: "",
              entityControlPersonLastNameError: "",
              entityControlPersonEmail: "",
              entityControlPersonEmailError: "",
              entityControlPersonIsUSBased: "",
              entityControlPersonIsUSBasedError: "",
              entityControlPersonIsUSCitizen: "",
              entityControlPersonIsUSCitizenError: "",
              entityControlPersonPIIAvailable: "",
              entityControlPersonPIIAvailableError: "",
              entityControlPersonAddressLine1: "",
              entityControlPersonAddressLine1Error: "",
              entityControlPersonAddressLine2: "",
              entityControlPersonAddressLine2Error: "",
              entityControlPersonCity: "",
              entityControlPersonCityError: "",
              entityControlPersonState: "",
              entityControlPersonStateError: "",
              entityControlPersonPostalCode: "",
              entityControlPersonPostalCodeError: "",
              entityControlPersonPhoneNumber: "",
              entityControlPersonPhoneNumberError: "",
              entityControlPersonTaxIDNumber: "",
              entityControlPersonTaxIDNumberError: "",
              entityControlPersonDOB: "",
              entityControlPersonDOBError: "",
              entityCertificateOfFormation: "",
              entityCertificateOfFormationError: "",
              entityProofOfAddress: "",
              entityProofOfAddressError: "",
              entityControlPersonPassportDocument: "",
              entityControlPersonPassportDocumentError: "",
              entityControlPersonProofOfAddress: "",
              entityControlPersonProofOfAddressError: "",
              entityIsTrustManagementCompany: "",
              entityIsTrustManagementCompanyError: "",
              entityHasAlternateSignatory: "",
              entityHasAlternateSignatoryError: "",
              entityAlternateSignatoryFullName: "",
              entityAlternateSignatoryFullNameError: "",
              entityAlternateSignatoryEmail: "",
              entityAlternateSignatoryEmailError: "",
              entityAlternateSignatoryTitle: "",
              entityAlternateSignatoryTitleError: "",
              entityControlPersonRole: "",
              entityControlPersonRoleError: "",
              entityNestedControlEntityTrusteeType: "",
              entityNestedControlEntityTrusteeTypeError: "",
              entityNestedControlEntityName: "",
              entityNestedControlEntityNameError: "",
              entityNestedControlEntityType: "",
              entityNestedControlEntityTypeError: "",
              entityNestedControlEntityPercentOwned: "",
              entityNestedControlEntityPercentOwnedError: "",
              entityNestedControlEntityStateOfIncorporation: "",
              entityNestedControlEntityStateOfIncorporationError: "",
              entityNestedControlEntityTaxIDNumber: "",
              entityNestedControlEntityTaxIDNumberError: "",
              entityNestedControlEntityPhoneNumber: "",
              entityNestedControlEntityPhoneNumberError: "",
              entityNestedControlEntityAddressLine1: "",
              entityNestedControlEntityAddressLine1Error: "",
              entityNestedControlEntityAddressLine2: "",
              entityNestedControlEntityAddressLine2Error: "",
              entityNestedControlEntityCity: "",
              entityNestedControlEntityCityError: "",
              entityNestedControlEntityState: "",
              entityNestedControlEntityStateError: "",
              entityNestedControlEntityPostalCode: "",
              entityNestedControlEntityPostalCodeError: "",
              entityNestedControlEntityIsTrustManagementCompany: "",
              entityNestedControlEntityIsTrustManagementCompanyError: "",
              entityNestedControlEntityAddressType: "",
              entityNestedControlEntityAddressTypeError: "",
              entityNestedControlEntityCertificateOfFormation: "",
              entityNestedControlEntityCertificateOfFormationError: "",
              entityNestedControlEntityProofOfAddress: "",
              entityNestedControlEntityProofOfAddressError: "",
              entityNestedControlEntityControlPersonFirstName: "",
              entityNestedControlEntityControlPersonFirstNameError: "",
              entityNestedControlEntityControlPersonLastName: "",
              entityNestedControlEntityControlPersonLastNameError: "",
              entityNestedControlEntityControlPersonEmail: "",
              entityNestedControlEntityControlPersonEmailError: "",
              entityNestedControlEntityControlPersonIsUSBased: "",
              entityNestedControlEntityControlPersonIsUSBasedError: "",
              entityNestedControlEntityControlPersonIsUSCitizen: "",
              entityNestedControlEntityControlPersonIsUSCitizenError: "",
              entityNestedControlEntityControlPersonPhoneNumber: "",
              entityNestedControlEntityControlPersonPhoneNumberError: "",
              entityNestedControlEntityControlPersonPIIAvailable: "",
              entityNestedControlEntityControlPersonPIIAvailableError: "",
              entityNestedControlEntityControlPersonAddressLine1: "",
              entityNestedControlEntityControlPersonAddressLine1Error: "",
              entityNestedControlEntityControlPersonAddressLine2: "",
              entityNestedControlEntityControlPersonAddressLine2Error: "",
              entityNestedControlEntityControlPersonCity: "",
              entityNestedControlEntityControlPersonCityError: "",
              entityNestedControlEntityControlPersonState: "",
              entityNestedControlEntityControlPersonStateError: "",
              entityNestedControlEntityControlPersonPostalCode: "",
              entityNestedControlEntityControlPersonPostalCodeError: "",
              entityNestedControlEntityControlPersonTaxIDNumber: "",
              entityNestedControlEntityControlPersonTaxIDNumberError: "",
              entityNestedControlEntityControlPersonDOB: "",
              entityNestedControlEntityControlPersonDOBError: "",
              entityNestedControlEntityControlPersonPassportDocument: "",
              entityNestedControlEntityControlPersonPassportDocumentError: "",
              entityNestedControlEntityControlPersonProofOfAddress: "",
              entityNestedControlEntityControlPersonProofOfAddressError: "",
              entityNestedControlEntityControlPersonAddressType: "",
              entityNestedControlEntityControlPersonAddressTypeError: "",
              firstName: relatedParty.first_name,
              lastName: relatedParty.last_name,
              email: relatedParty.email,
              phoneNumber: relatedParty.phone_number,
              addressLine1: relatedParty.addresses[0].address_line_1,
              addressLine2: relatedParty.addresses[0].address_line_2,
              city: relatedParty.addresses[0].city,
              state: relatedParty.addresses[0].state,
              country: relatedParty.addresses[0].country,
              postalCode: relatedParty.addresses[0].postal_code,
              addressType: relatedParty.addresses[0].address_type,
              deliverableAddressLine1: "",
              deliverableAddressLine2: "",
              deliverableCity: "",
              deliverableState: "",
              deliverableCountry: "",
              deliverablePostalCode: "",
              deliverableAddressType: "",
              dateOfBirth: relatedParty.pii[0].date_of_birth,
              dateOfBirthError: "",
              idType: relatedParty.pii[0].id_type,
              idNum: relatedParty.pii[0].id_number,
              idNumError: "",
              domicileCountry: relatedParty.domicile_country,
              firstNameError: "",
              lastNameError: "",
              emailError: "",
              phoneNumberError: "",
              addressLine1Error: "",
              addressLine2Error: "",
              cityError: "",
              stateError: "",
              countryError: "",
              postalCodeError: "",
              deliverableAddressLine1Error: "",
              deliverableAddressLine2Error: "",
              deliverableCityError: "",
              deliverableStateError: "",
              deliverableCountryError: "",
              deliverablePostalCodeError: "",
              primaryAddressDeliverable: "",
              domicileCountryError: "",
              deliverableSelectionError: "",
              percentOwned: relatedParty.percent_owned,
              percentOwnedError: "",
              citizenship: relatedParty.citizenship,
              citizenshipError: "",
            };
          });

        let accountBeneficialOwnerEntities =
          accountSummaryData.related_parties_entities
            .filter((relatedParty) => relatedParty.role === "BO")
            .map((relatedParty) => {
              return {
                relatedPartyType: "Entity",
                relatedPartyTypeError: "",
                entityPercentOwned: relatedParty.entity_percent_owned,
                entityPercentOwnedError: "",
                entityName: relatedParty.entity_name,
                entityNameError: "",
                entityAddressLine1: relatedParty.entity_address_line_1,
                entityAddressLine1Error: "",
                entityAddressLine2: relatedParty.entity_address_line_2,
                entityAddressLine2Error: "",
                entityCity: relatedParty.entity_city,
                entityCityError: "",
                entityState: relatedParty.entity_state,
                entityStateError: "",
                entityPostalCode: relatedParty.entity_postal_code,
                entityPostalCodeError: "",
                entityTaxIDNumber: relatedParty.entity_tax_id_number,
                entityTaxIDNumberError: "",
                entityPhoneNumber: relatedParty.entity_phone_number,
                entityPhoneNumberError: "",
                entityStateOfIncorporation:
                  relatedParty.entity_state_of_incorporation,
                entityStateOfIncorporationError: "",
                entityMailingAddressDifferent:
                  relatedParty.entity_mailing_address_line_1 !== ""
                    ? "Yes"
                    : "No",
                entityMailingAddressDifferentError: "",
                entityMailingAddressLine1:
                  relatedParty.entity_mailing_address_line_1,
                entityMailingAddressLine1Error: "",
                entityMailingAddressLine2:
                  relatedParty.entity_mailing_address_line_2,
                entityMailingAddressLine2Error: "",
                entityMailingCity: relatedParty.entity_mailing_city,
                entityMailingCityError: "",
                entityMailingState: relatedParty.entity_mailing_state,
                entityMailingStateError: "",
                entityMailingPostalCode:
                  relatedParty.entity_mailing_postal_code,
                entityMailingPostalCodeError: "",
                entityType: relatedParty.entity_type,
                entityTypeError: "",
                entityControlPersonFirstName:
                  relatedParty.entity_control_person_first_name,
                entityControlPersonFirstNameError: "",
                entityControlPersonLastName:
                  relatedParty.entity_control_person_last_name,
                entityControlPersonLastNameError: "",
                entityControlPersonEmail:
                  relatedParty.entity_control_person_email,
                entityControlPersonEmailError: "",
                entityControlPersonIsUSBased:
                  relatedParty.entity_control_person_is_us_based === true ||
                  relatedParty.entity_control_person_is_us_based === "Yes"
                    ? "Yes"
                    : "No",
                entityControlPersonIsUSBasedError: "",
                entityControlPersonIsUSCitizen:
                  relatedParty.entity_control_person_is_us_citizen === true ||
                  relatedParty.entity_control_person_is_us_citizen === "Yes"
                    ? "Yes"
                    : "No",
                entityControlPersonIsUSCitizenError: "",
                entityControlPersonPIIAvailable:
                  relatedParty.entity_control_person_address_line_1 !== ""
                    ? "Yes"
                    : "No",
                entityControlPersonPIIAvailableError: "",
                entityControlPersonAddressLine1:
                  relatedParty.entity_control_person_address_line_1,
                entityControlPersonAddressLine1Error: "",
                entityControlPersonAddressLine2:
                  relatedParty.entity_control_person_address_line_2,
                entityControlPersonAddressLine2Error: "",
                entityControlPersonCity:
                  relatedParty.entity_control_person_city,
                entityControlPersonCityError: "",
                entityControlPersonState:
                  relatedParty.entity_control_person_state,
                entityControlPersonStateError: "",
                entityControlPersonPostalCode:
                  relatedParty.entity_control_person_postal_code,
                entityControlPersonPostalCodeError: "",
                entityControlPersonPhoneNumber:
                  relatedParty.entity_control_person_phone_number,
                entityControlPersonPhoneNumberError: "",
                entityControlPersonTaxIDNumber:
                  relatedParty.entity_control_person_tax_id_number,
                entityControlPersonTaxIDNumberError: "",
                entityControlPersonDOB: relatedParty.entity_control_person_dob,
                entityControlPersonDOBError: "",
                entityCertificateOfFormation: "",
                entityCertificateOfFormationError: "",
                entityProofOfAddress: "",
                entityProofOfAddressError: "",
                entityControlPersonPassportDocument: "",
                entityControlPersonPassportDocumentError: "",
                entityControlPersonProofOfAddress: "",
                entityControlPersonProofOfAddressError: "",
                entityIsTrustManagementCompany: "",
                entityIsTrustManagementCompanyError: "",
                entityHasAlternateSignatory: "",
                entityHasAlternateSignatoryError: "",
                entityAlternateSignatoryFullName: "",
                entityAlternateSignatoryFullNameError: "",
                entityAlternateSignatoryEmail: "",
                entityAlternateSignatoryEmailError: "",
                entityAlternateSignatoryTitle: "",
                entityAlternateSignatoryTitleError: "",
                entityControlPersonRole: "",
                entityControlPersonRoleError: "",
                entityNestedControlEntityTrusteeType:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_related_party_type
                    : "",
                entityNestedControlEntityTrusteeTypeError: "",
                entityNestedControlEntityName:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_name
                    : "",
                entityNestedControlEntityNameError: "",
                entityNestedControlEntityType:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_type
                    : "",
                entityNestedControlEntityTypeError: "",
                entityNestedControlEntityPercentOwned:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_percent_owned
                    : "",
                entityNestedControlEntityPercentOwnedError: "",
                entityNestedControlEntityStateOfIncorporation:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_state_of_incorporation
                    : "",
                entityNestedControlEntityStateOfIncorporationError: "",
                entityNestedControlEntityTaxIDNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_tax_id_number
                    : "",
                entityNestedControlEntityTaxIDNumberError: "",
                entityNestedControlEntityPhoneNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_phone_number
                    : "",
                entityNestedControlEntityPhoneNumberError: "",
                entityNestedControlEntityAddressLine1:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_address_line_1
                    : "",
                entityNestedControlEntityAddressLine1Error: "",
                entityNestedControlEntityAddressLine2:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_address_line_2
                    : "",
                entityNestedControlEntityAddressLine2Error: "",
                entityNestedControlEntityCity:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_city
                    : "",
                entityNestedControlEntityCityError: "",
                entityNestedControlEntityState:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_state
                    : "",
                entityNestedControlEntityStateError: "",
                entityNestedControlEntityPostalCode:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_postal_code
                    : "",
                entityNestedControlEntityPostalCodeError: "",
                entityNestedControlEntityIsTrustManagementCompany:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_is_trust_management_company
                    : "",
                entityNestedControlEntityIsTrustManagementCompanyError: "",
                entityNestedControlEntityAddressType:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_address_type
                    : "",
                entityNestedControlEntityAddressTypeError: "",
                entityNestedControlEntityCertificateOfFormation: "",
                entityNestedControlEntityCertificateOfFormationError: "",
                entityNestedControlEntityProofOfAddress: "",
                entityNestedControlEntityProofOfAddressError: "",
                entityNestedControlEntityControlPersonFirstName:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_first_name
                    : "",
                entityNestedControlEntityControlPersonFirstNameError: "",
                entityNestedControlEntityControlPersonLastName:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_last_name
                    : "",
                entityNestedControlEntityControlPersonLastNameError: "",
                entityNestedControlEntityControlPersonEmail:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_email
                    : "",
                entityNestedControlEntityControlPersonEmailError: "",
                entityNestedControlEntityControlPersonIsUSBased:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_is_us_based
                    : "",
                entityNestedControlEntityControlPersonIsUSBasedError: "",
                entityNestedControlEntityControlPersonIsUSCitizen:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_is_us_citizen
                    : "",
                entityNestedControlEntityControlPersonIsUSCitizenError: "",
                entityNestedControlEntityControlPersonPhoneNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_phone_number
                    : "",
                entityNestedControlEntityControlPersonPhoneNumberError: "",
                entityNestedControlEntityControlPersonPIIAvailable:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_address_line_1 !==
                      ""
                      ? "Yes"
                      : "No"
                    : "",
                entityNestedControlEntityControlPersonPIIAvailableError: "",
                entityNestedControlEntityControlPersonAddressLine1:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_address_line_1
                    : "",
                entityNestedControlEntityControlPersonAddressLine1Error: "",
                entityNestedControlEntityControlPersonAddressLine2:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_address_line_2
                    : "",
                entityNestedControlEntityControlPersonAddressLine2Error: "",
                entityNestedControlEntityControlPersonCity:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_city
                    : "",
                entityNestedControlEntityControlPersonCityError: "",
                entityNestedControlEntityControlPersonState:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_state
                    : "",
                entityNestedControlEntityControlPersonStateError: "",
                entityNestedControlEntityControlPersonPostalCode:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_postal_code
                    : "",
                entityNestedControlEntityControlPersonPostalCodeError: "",
                entityNestedControlEntityControlPersonTaxIDNumber:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_tax_id_number
                    : "",
                entityNestedControlEntityControlPersonTaxIDNumberError: "",
                entityNestedControlEntityControlPersonDOB:
                  relatedParty.nested_related_party_type !== null &&
                  relatedParty.nested_related_party_type !== undefined &&
                  relatedParty.nested_related_party_type !== ""
                    ? relatedParty.nested_entity_control_person_dob
                    : "",
                entityNestedControlEntityControlPersonDOBError: "",
                entityNestedControlEntityControlPersonPassportDocument: "",
                entityNestedControlEntityControlPersonPassportDocumentError: "",
                entityNestedControlEntityControlPersonProofOfAddress: "",
                entityNestedControlEntityControlPersonProofOfAddressError: "",
                entityNestedControlEntityControlPersonAddressType: "",
                entityNestedControlEntityControlPersonAddressTypeError: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                country: "",
                postalCode: "",
                addressType: "",
                deliverableAddressLine1: "",
                deliverableAddressLine2: "",
                deliverableCity: "",
                deliverableState: "",
                deliverableCountry: "",
                deliverablePostalCode: "",
                deliverableAddressType: "",
                dateOfBirth: "",
                dateOfBirthError: "",
                idType: "",
                idNum: "",
                idNumError: "",
                domicileCountry: "",
                firstNameError: "",
                lastNameError: "",
                emailError: "",
                phoneNumberError: "",
                addressLine1Error: "",
                addressLine2Error: "",
                cityError: "",
                stateError: "",
                countryError: "",
                postalCodeError: "",
                deliverableAddressLine1Error: "",
                deliverableAddressLine2Error: "",
                deliverableCityError: "",
                deliverableStateError: "",
                deliverableCountryError: "",
                deliverablePostalCodeError: "",
                primaryAddressDeliverable: "",
                domicileCountryError: "",
                deliverableSelectionError: "",
                percentOwned: "",
                percentOwnedError: "",
                citizenship: "",
                citizenshipError: "",
              };
            });

        setRelatedAccounts(
          accountBeneficialOwnerPersons.concat(accountBeneficialOwnerEntities)
        );

        // setRelatedAccounts(
        //   accountSummaryData.related_parties
        //     .filter((relatedParty) => relatedParty.role === "BO")
        //     .map((relatedParty) => ({
        //       firstName: relatedParty.first_name,
        //       lastName: relatedParty.last_name,
        //       email: relatedParty.email,
        //       phoneNumber: relatedParty.phone_number,
        //       addressLine1: relatedParty.addresses[0].address_line_1,
        //       addressLine2: relatedParty.addresses[0].address_line_2,
        //       city: relatedParty.addresses[0].city,
        //       country: relatedParty.addresses[0].country,
        //       state: relatedParty.addresses[0].state,
        //       postalCode: relatedParty.addresses[0].postal_code,
        //       dateOfBirth: relatedParty.pii[0].date_of_birth,
        //       idNum: relatedParty.pii[0].id_number,
        //       domicileCountry: relatedParty.domicile_country,
        //       idType: relatedParty.pii[0].id_type,
        //       percentOwned: relatedParty.percent_owned,
        //       citizenship: relatedParty.citizenship,
        //     }))
        // );
      }
      if (accountSummaryData.account_type === "JOINT") {
        //
        const response = await getAccountSummary(id);
        console.log(response.data[0].secondary_investor_profile);

        const mailing =
          response.data[0].secondary_investor_profile.investor_addresses.find(
            (s) => s.address_type === "RM"
          );

        setJointFirstName(
          response.data[0].secondary_investor_profile.first_name
        );
        setJointLastName(response.data[0].secondary_investor_profile.last_name);
        setJointEmail(response.data[0].secondary_investor_profile.email);
        setJointPhoneNumber(
          response.data[0].secondary_investor_profile.phone_number
        );
        setJointDomicileCountry(
          response.data[0].secondary_investor_profile.country
        );

        setJointDob(
          response.data[0].secondary_investor_profile.investor_pii[0]
            .date_of_birth
        );

        setJointAddressLine1(mailing.address_line_1);
        setJointAddressLine2(mailing.address_line_2);
        setJointCity(mailing.city);

        setJointCountry(mailing.country);

        setJointState(mailing.state);

        setJointPostalCode(mailing.postal_code);

        setJointIDNumber(
          response.data[0].secondary_investor_profile.investor_pii[0].id_num
        );

        setJointIDType(
          response.data[0].secondary_investor_profile.investor_pii[0].id_type
        );
      }
    }
    parseAccountSummaryData();
  }, [user]);

  return (
    <React.Fragment>
      <Header activePage={"investment-accounts"} />
      <div className="container-xxl mt-5 pt-1 pb-5">
        {["IND", "JOINT"].includes(accountType) && (
          <IndividualAccountDetails
            joint={accountType === "JOINT"}
            accountNumber={accountNumber}
            dateAdded={dateAdded}
            firstName={firstName}
            setFirstName={setFirstName}
            firstNameError={firstNameError}
            setFirstNameError={setFirstNameError}
            lastName={lastName}
            setLastName={setLastName}
            lastNameError={lastNameError}
            setLastNameError={setLastNameError}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            domicileCountry={domicileCountry}
            setDomicileCountry={setDomicileCountry}
            domicileCountryError={domicileCountryError}
            setDomicileCountryError={setDomicileCountryError}
            qualificationLevel={qualificationLevel}
            verificationStatus={verificationStatus}
            dob={dob}
            setDob={setDob}
            dobError={dobError}
            setDobError={setDobError}
            idNumber={idNumber}
            setIDNumber={setIDNumber}
            idNumberError={idNumberError}
            setIDNumberError={setIDNumberError}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine1Error={addressLine1Error}
            setAddressLine1Error={setAddressLine1Error}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine2Error={addressLine2Error}
            setAddressLine2Error={setAddressLine2Error}
            city={city}
            setCity={setCity}
            cityError={cityError}
            setCityError={setCityError}
            state={state}
            setState={setState}
            stateError={stateError}
            setStateError={setStateError}
            country={country}
            setCountry={setCountry}
            countryError={countryError}
            setCountryError={setCountryError}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
            jointInfo={jointInfo}
          />
        )}
        {accountType === "TRT" && (
          <TrustAccountDetails
            accountNumber={accountNumber}
            dateAdded={dateAdded}
            qualificationLevel={qualificationLevel}
            verificationStatus={verificationStatus}
            trustType={trustType}
            setTrustType={setTrustType}
            trustTypeError={trustTypeError}
            setTrustTypeError={setTrustTypeError}
            accountName={accountName}
            setAccountName={setAccountName}
            accountNameError={accountNameError}
            setAccountNameError={setAccountNameError}
            countryOfFormation={countryOfFormation}
            setCountryOfFormation={setCountryOfFormation}
            countryOfFormationError={countryOfFormationError}
            setCountryOfFormationError={setCountryOfFormationError}
            idType={idType}
            setIDType={setIDType}
            idTypeError={idTypeError}
            setIDTypeError={setIDTypeError}
            idNumber={idNumber}
            setIDNumber={setIDNumber}
            idNumberError={idNumberError}
            setIDNumberError={setIDNumberError}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine1Error={addressLine1Error}
            setAddressLine1Error={setAddressLine1Error}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine2Error={addressLine2Error}
            setAddressLine2Error={setAddressLine2Error}
            city={city}
            setCity={setCity}
            cityError={cityError}
            setCityError={setCityError}
            state={state}
            setState={setState}
            stateError={stateError}
            setStateError={setStateError}
            country={country}
            setCountry={setCountry}
            countryError={countryError}
            setCountryError={setCountryError}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
            grantorFirstName={grantorFirstName}
            setGrantorFirstName={setGrantorFirstName}
            grantorFirstNameError={grantorFirstNameError}
            setGrantorFirstNameError={setGrantorFirstNameError}
            grantorLastName={grantorLastName}
            setGrantorLastName={setGrantorLastName}
            grantorLastNameError={grantorLastNameError}
            setGrantorLastNameError={setGrantorLastNameError}
            grantorPhoneNumber={grantorPhoneNumber}
            setGrantorPhoneNumber={setGrantorPhoneNumber}
            grantorPhoneNumberError={grantorPhoneNumberError}
            setGrantorPhoneNumberError={setGrantorPhoneNumberError}
            grantorEmail={grantorEmail}
            setGrantorEmail={setGrantorEmail}
            grantorEmailError={grantorEmailError}
            setGrantorEmailError={setGrantorEmailError}
            grantorDomicileCountry={grantorDomicileCountry}
            setGrantorDomicileCountry={setGrantorDomicileCountry}
            grantorDomicileCountryError={grantorDomicileCountryError}
            setGrantorDomicileCountryError={setGrantorDomicileCountryError}
            grantorAddressLine1={grantorAddressLine1}
            setGrantorAddressLine1={setGrantorAddressLine1}
            grantorAddressLine1Error={grantorAddressLine1Error}
            setGrantorAddressLine1Error={setGrantorAddressLine1Error}
            grantorAddressLine2={grantorAddressLine2}
            setGrantorAddressLine2={setGrantorAddressLine2}
            grantorAddressLine2Error={grantorAddressLine2Error}
            setGrantorAddressLine2Error={setGrantorAddressLine2Error}
            grantorCity={grantorCity}
            setGrantorCity={setGrantorCity}
            grantorCityError={grantorCityError}
            setGrantorCityError={setGrantorCityError}
            grantorState={grantorState}
            setGrantorState={setGrantorState}
            grantorStateError={grantorStateError}
            setGrantorStateError={setGrantorStateError}
            grantorCountry={grantorCountry}
            setGrantorCountry={setGrantorCountry}
            grantorCountryError={grantorCountryError}
            setGrantorCountryError={setGrantorCountryError}
            grantorPostalCode={grantorPostalCode}
            setGrantorPostalCode={setGrantorPostalCode}
            grantorPostalCodeError={grantorPostalCodeError}
            setGrantorPostalCodeError={setGrantorPostalCodeError}
            grantorDob={grantorDob}
            setGrantorDob={setGrantorDob}
            grantorDobError={grantorDobError}
            setGrantorDobError={setGrantorDobError}
            grantorIDNumber={grantorIDNumber}
            setGrantorIDNumber={setGrantorIDNumber}
            grantorIDNumberError={grantorIDNumberError}
            setGrantorIDNumberError={setGrantorIDNumberError}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
            beneficialOwnerRelatedAccounts={beneficialOwnerRelatedAccounts}
            setBeneficialOwnerRelatedAccounts={
              setBeneficialOwnerRelatedAccounts
            }
          />
        )}
        {accountType === "ENT" && (
          <EntityAccountDetails
            accountNumber={accountNumber}
            dateAdded={dateAdded}
            qualificationLevel={qualificationLevel}
            verificationStatus={verificationStatus}
            entityType={entityType}
            setEntityType={setEntityType}
            entityTypeError={entityTypeError}
            setEntityTypeError={setEntityTypeError}
            accountName={accountName}
            setAccountName={setAccountName}
            accountNameError={accountNameError}
            setAccountNameError={setAccountNameError}
            countryOfFormation={countryOfFormation}
            setCountryOfFormation={setCountryOfFormation}
            countryOfFormationError={countryOfFormationError}
            setCountryOfFormationError={setCountryOfFormationError}
            idType={idType}
            setIDType={setIDType}
            idTypeError={idTypeError}
            setIDTypeError={setIDTypeError}
            idNumber={idNumber}
            setIDNumber={setIDNumber}
            idNumberError={idNumberError}
            setIDNumberError={setIDNumberError}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine1Error={addressLine1Error}
            setAddressLine1Error={setAddressLine1Error}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine2Error={addressLine2Error}
            setAddressLine2Error={setAddressLine2Error}
            city={city}
            setCity={setCity}
            cityError={cityError}
            setCityError={setCityError}
            state={state}
            setState={setState}
            stateError={stateError}
            setStateError={setStateError}
            country={country}
            setCountry={setCountry}
            countryError={countryError}
            setCountryError={setCountryError}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            postalCodeError={postalCodeError}
            setPostalCodeError={setPostalCodeError}
            relatedAccounts={relatedAccounts}
            setRelatedAccounts={setRelatedAccounts}
          />
        )}
      </div>
    </React.Fragment>
  );
}
export default AccountDetailsPage;
