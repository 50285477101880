import React, { useState, useEffect } from "react";
import TabsV2 from "../../../../../../Components/GlobalComponents/TabsV2/TabsV2";
import TabV2 from "../../../../../../Components/GlobalComponents/TabsV2/TabV2";
import EntityAccountDetailsGeneralInfo from "./EntityAccountDetailsGeneralInfo/EntityAccountDetailsGeneralInfo";
import EntityAccountDetailsTaxInfo from "./EntityAccountDetailsTaxInfo/EntityAccountDetailsTaxInfo";
import EntityAccountDetailsBeneficialOwnerInfo from "./EntityAccountDetailsBeneficialOwnerInfo/EntityAccountDetailsBeneficialOwnerInfo";
// import TrustAccountDetailsGeneralInfo from "./TrustAccountDetailsGeneralInfo/TrustAccountDetailsGeneralInfo";
// import TrustAccountDetailsTaxInfo from "./TrustAccountDetailsTaxInfo/TrustAccountDetailsTaxInfo";
// import TrustAccountDetailsGrantorInfo from "./TrustAccountDetailsGrantorInfo/TrustAccountDetailsGrantorInfo";

function EntityAccountDetailsInfo({
  accountNumber,
  dateAdded,
  qualificationLevel,
  verificationStatus,
  entityType,
  setEntityType,
  entityTypeError,
  setEntityTypeError,
  accountName,
  setAccountName,
  accountNameError,
  setAccountNameError,
  countryOfFormation,
  setCountryOfFormation,
  countryOfFormationError,
  setCountryOfFormationError,
  idType,
  setIDType,
  idTypeError,
  setIDTypeError,
  idNumber,
  setIDNumber,
  idNumberError,
  setIDNumberError,
  addressLine1,
  setAddressLine1,
  addressLine1Error,
  setAddressLine1Error,
  addressLine2,
  setAddressLine2,
  addressLine2Error,
  setAddressLine2Error,
  city,
  setCity,
  cityError,
  setCityError,
  state,
  setState,
  stateError,
  setStateError,
  country,
  setCountry,
  countryError,
  setCountryError,
  postalCode,
  setPostalCode,
  postalCodeError,
  setPostalCodeError,
  relatedAccounts,
  setRelatedAccounts,
}) {
  const [generalInformationSelected, setGeneralInformationSelected] =
    useState(true);
  const [taxInformationSelected, setTaxInformationSelected] = useState(false);
  const [
    beneficialOwnerInformationSelected,
    setBeneficialOwnerInformationSelected,
  ] = useState(false);

  useEffect(() => {
    if (generalInformationSelected) {
      setTaxInformationSelected(false);
      setBeneficialOwnerInformationSelected(false);
    }
  }, [generalInformationSelected]);

  useEffect(() => {
    if (taxInformationSelected) {
      setGeneralInformationSelected(false);
      setBeneficialOwnerInformationSelected(false);
    }
  }, [taxInformationSelected]);

  useEffect(() => {
    if (beneficialOwnerInformationSelected) {
      setTaxInformationSelected(false);
      setGeneralInformationSelected(false);
    }
  }, [beneficialOwnerInformationSelected]);

  return (
    <React.Fragment>
      <div className="row g-0 w-100">
        <TabsV2>
          <TabV2
            title="General Information"
            selected={generalInformationSelected}
            setSelected={setGeneralInformationSelected}
          >
            <EntityAccountDetailsGeneralInfo
              accountNumber={accountNumber}
              dateAdded={dateAdded}
              qualificationLevel={qualificationLevel}
              verificationStatus={verificationStatus}
              entityType={entityType}
              setEntityType={setEntityType}
              entityTypeError={entityTypeError}
              setEntityTypeError={setEntityTypeError}
              accountName={accountName}
              setAccountName={setAccountName}
              accountNameError={accountNameError}
              setAccountNameError={setAccountNameError}
              countryOfFormation={countryOfFormation}
              setCountryOfFormation={setCountryOfFormation}
              countryOfFormationError={countryOfFormationError}
              setCountryOfFormationError={setCountryOfFormationError}
            />
          </TabV2>
          <TabV2
            title="Tax Information"
            selected={taxInformationSelected}
            setSelected={setTaxInformationSelected}
          >
            <EntityAccountDetailsTaxInfo
              countryOfFormation={countryOfFormation}
              idType={idType}
              setIDType={setIDType}
              idTypeError={idTypeError}
              setIDTypeError={setIDTypeError}
              idNumber={idNumber}
              setIDNumber={setIDNumber}
              idNumberError={idNumberError}
              setIDNumberError={setIDNumberError}
              addressLine1={addressLine1}
              setAddressLine1={setAddressLine1}
              addressLine1Error={addressLine1Error}
              setAddressLine1Error={setAddressLine1Error}
              addressLine2={addressLine2}
              setAddressLine2={setAddressLine2}
              addressLine2Error={addressLine2Error}
              setAddressLine2Error={setAddressLine2Error}
              city={city}
              setCity={setCity}
              cityError={cityError}
              setCityError={setCityError}
              state={state}
              setState={setState}
              stateError={stateError}
              setStateError={setStateError}
              country={country}
              setCountry={setCountry}
              countryError={countryError}
              setCountryError={setCountryError}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              postalCodeError={postalCodeError}
              setPostalCodeError={setPostalCodeError}
            />
          </TabV2>
          <TabV2
            title="Beneficial Owner Information"
            selected={beneficialOwnerInformationSelected}
            setSelected={setBeneficialOwnerInformationSelected}
          >
            <EntityAccountDetailsBeneficialOwnerInfo
              accountType={entityType}
              relatedAccounts={relatedAccounts}
              setRelatedAccounts={setRelatedAccounts}
            />
          </TabV2>
        </TabsV2>
      </div>
    </React.Fragment>
  );
}
export default EntityAccountDetailsInfo;
