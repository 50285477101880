import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import LoadingAnimation from "../../../../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation";
import {
  VerifyFeederSignupAccess,
  GetAccreditedInvestorPermissions,
} from "../../../../../Adapters/FeederFundSignupAdapters";
import FlowNotAccessible from "./FlowNotAccessible/FlowNotAccessible";
import SignupSteps from "./SignupSteps/SignupSteps";

function FirmAuthorizedSignup() {
  const [linkVerificationLoading, setLinkVerificationLoading] = useState(true);
  const [
    accreditedInvestorPermissionsLoading,
    setAccreditedInvestorPermissionsLoading,
  ] = useState(true);
  const [time, setTime] = useState(0);
  const [newTime, setNewTime] = useState(0);
  const { feederSignupAccess } = VerifyFeederSignupAccess(
    time,
    setLinkVerificationLoading
  );

  const { accreditedAllowed } = GetAccreditedInvestorPermissions(
    newTime,
    setAccreditedInvestorPermissionsLoading
  );

  return (linkVerificationLoading === true &&
    feederSignupAccess !== null &&
    feederSignupAccess !== undefined) ||
    accreditedInvestorPermissionsLoading === true ? (
    <div className="row g-0 w-100">
      <LoadingAnimation loading={linkVerificationLoading} />
    </div>
  ) : feederSignupAccess === "Allowed" ? (
    <div className="row g-0 w-100">
      <SignupSteps />
    </div>
  ) : (
    <FlowNotAccessible />
  );
}
export default FirmAuthorizedSignup;
