import React from "react";
import { useHistory } from "react-router";

function AccountComplete({ accountID }) {
  let history = useHistory();
  return (
    <div className="row g-0 w-100 add-account-wizard-container">
      <div className="col-12 add-account-outer-column-padded">
        <div className="row g-0 w-100">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4 text-start">
              <div className="d-flex">
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  Account status:
                </span>

                <span
                  className="ms-2 mt-auto mb-auto"
                  style={{
                    color: "#00AA26",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  Complete
                </span>
              </div>
            </div>
            <div className="row g-0 w-100">
              <div className="col">
                <div className="d-flex">
                  <button
                    type="button"
                    class="btn btn-lg btn-class"
                    onClick={() => {
                      if (accountID !== undefined) {
                        history.push(
                          "/investment-accounts/account-details/" + accountID
                        );
                      }
                    }}
                  >
                    <div className="row g-0 w-100">
                      <div className="col d-flex align-items-center">
                        <span className="justify-self-start align-self-center">
                          View Account Details
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AccountComplete;
