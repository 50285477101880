import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./CompleteAccountComponentStyles/CompleteAccountComponentStyles.scss";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";

function CompleteAccountComponent({
  accountType,
  investorProfileComplete,
  entityComplete,
  id,
  investmentAccountsPage
}) {
  let history = useHistory();
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);

  useEffect(() => {
    if (accountType === "IND") {
      setChecked(investorProfileComplete);
      setChecked2(investorProfileComplete);
    } else {
      setChecked(investorProfileComplete);
      setChecked2(entityComplete);
    }
  }, [accountType]);

  return (
    // <div className="row g-0 w-100 justify-content-center mb-2">
    <div className="component-container d-flex">
      <div className="message-container d-flex align-items-center">
        <span className="investment-instructions-text">
          To invest in a fund, please complete the following steps:
        </span>
      </div>
      <div className="complete-steps-container w-100">
        <div className="step-1-container bottom-border-only d-flex align-items-center">
          <Checkbox
            disabled={true}
            size={16}
            checked={checked}
            borderRadius={4}
            borderWidth={1}
            borderColor={"#E2E6ED"}
            icon={
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  backgroundColor: "#0D47A1",
                  borderRadius: "4px",
                }}
              >
                <Icon.FiCheck color="white" size={16} />
              </div>
            }
          />
          <span className="ms-2 complete-steps-text">
            Complete your{" "}
            <span className="complete-steps-weighted-text">
              Investor Information
            </span>
          </span>
          {!checked && (
            <div
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/profile")}
            >
              <span
                className="complete-text"
                style={{ marginLeft: "8px", cursor: "pointer" }}
              >
                Complete
              </span>
              <img
                src="https://d1swyfveypj49y.cloudfront.net/complete-right-arrow.svg"
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
        <div className="step-2-container align-items-center d-flex">
         {!investmentAccountsPage && checked &&  <Checkbox
            disabled={true}
            size={16}
            checked={checked2}
            borderRadius={4}
            borderWidth={1}
            borderColor={"#E2E6ED"}
            icon={
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  backgroundColor: "#0D47A1",
                  borderRadius: "4px",
                }}
              >
                <Icon.FiCheck color="white" size={16} />
              </div>
            }
          /> }
          <span className="ms-2 complete-steps-text">
            {! (investmentAccountsPage) &&(checked) ? <>Complete your <span className="complete-steps-weighted-text">Account</span></> :<>Complete an account before investing.</> } 
          </span>
          {!checked2 && (!investmentAccountsPage) && checked &&  (
            <div
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/investment-accounts/add/" + id)}
            >
              <span
                className="complete-text"
                style={{ marginLeft: "8px", cursor: "pointer" }}
              >
                Complete{" "}
              </span>
              <img
                src="https://d1swyfveypj49y.cloudfront.net/complete-right-arrow.svg"
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default CompleteAccountComponent;