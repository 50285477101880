import React, { useState, useContext, useEffect } from "react";
import "./Styles/Styles.scss";
import MuiButton from "../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import TextInputField from "../../../../../../../Components/GlobalComponents/TextInputField/TextInputField";
import { verifyMagicCode } from "../../../../../../../Api/FeederFundSignupApi";
import AlertSnackBar from "../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import { AccessCodeContext } from "../../../../../../../GlobalContexts/AccesCodeContext";
import { useRecoilState } from "recoil";
import { accessCodeVal } from "../../../../../../../atoms";

function AccessCode({ setActiveSlide }) {
  //   const [accessCodeError, setAccessCodeError] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeError, setAccessCodeError] = useState("");
  const [codeNotProvidedError, setCodeNotProvidedError] = useState(false);
  const [codeInvalidError, setCodeInvalidError] = useState(false);

  const [accessCodeValue, setAccessCodeValue] = useRecoilState(accessCodeVal);

  //   const { setAccessCodeValue } = useContext(AccessCodeContext);

  const submitCode = async () => {
    var error = false;

    if (accessCode === "") {
      error = true;
      setCodeNotProvidedError(true);
    }

    if (!error) {
      try {
        await verifyMagicCode(accessCode);
        console.log("Success.");
        setActiveSlide((curSlide) => curSlide + 1);
      } catch (err) {
        console.log("Error is: " + err);
        setCodeInvalidError(true);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    setAccessCodeValue(accessCode);
  }, [accessCode]);

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <h6>Please enter the access code you were provided below</h6>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col">
          <div className="d-flex">
            <TextInputField
              value={accessCode}
              setValue={setAccessCode}
              valueError={accessCodeError}
              setValueError={setAccessCodeError}
              required={true}
              label={"Access Code"}
              size={"small"}
              shrink={true}
              fullWidth={true}
            />
          </div>
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col">
          <div className="d-flex">
            <MuiButton
              buttonText={"Submit"}
              onClick={() => submitCode()}
              size={"medium"}
            />
          </div>
        </div>
      </div>
      <AlertSnackBar
        snackbarState={codeNotProvidedError}
        setSnackbarState={setCodeNotProvidedError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must provide an access code to continue."}
      />
      <AlertSnackBar
        snackbarState={codeInvalidError}
        setSnackbarState={setCodeInvalidError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Please enter a valid access code."}
      />
    </React.Fragment>
  );
}
export default React.memo(AccessCode);
