import { useState, useEffect, useRef } from "react";

export default function useDelayUnmount(isMounted, delayTime, delayMount) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      //   setShouldRender(true);
      if (delayMount) {
        timeoutId = setTimeout(() => setShouldRender(true), delayTime);
      } else {
        setShouldRender(true);
      }
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}
