import React, { useState } from "react";
import "./Styles/Styles.scss";
import { QualifiedPurchaserModal } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { QualifiedPurchaserLink } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import MuiButton from "../../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";
import { useRecoilState } from "recoil";
import {
  qualificationDateVal,
  trustAssets5M,
  trustSophisticatedInvestor,
  trustFormationNotSpecificInvestment,
  trustGrantorsAccredited,
  trustOwnedByFamily,
  trustGrantorQP,
} from "../../../../../../../../atoms";
import AlertSnackBar from "../../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import RadioInputGroup from "../../../../../../../../Components/GlobalComponents/RadioInputs/RadioInputGroup";
import RadioInput from "../../../../../../../../Components/GlobalComponents/RadioInputs/RadioInput/RadioInput";
import AccreditedInvestorModal from "../IndividualQualification/AccreditedInvestorModal/AccreditedInvestorModal";
import { AccreditedInvestorLink } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";

function TrustAccreditationQualification({ setActiveSlide }) {
  const [qualificationDate, setQualificationDate] =
    useRecoilState(qualificationDateVal);

  const [trust5MAssets, setTrust5MAssets] = useRecoilState(trustAssets5M);
  const [hasSophisticatedInvestor, setHasSophisticatedInvestor] =
    useRecoilState(trustSophisticatedInvestor);
  const [notFormedForInvestment, setNotFormedForInvestment] = useRecoilState(
    trustFormationNotSpecificInvestment
  );
  const [grantorsAccredited, setGrantorsAccredited] = useRecoilState(
    trustGrantorsAccredited
  );
  const [ownedByFamily, setOwnedByFamily] = useRecoilState(trustOwnedByFamily);
  const [grantorQP, setGrantorQP] = useRecoilState(trustGrantorQP);

  const [showQPModal, setShowQPModal] = useState(false);
  const [showAccreditedModal, setShowAccreditedModal] = useState(false);

  const [notAllQuestionsAnsweredError, setNotAllQuestionsAnsweredError] =
    useState(false);

  const [notAccreditedError, setNotAccreditedError] = useState(false);

  const validateAccreditationStatus = () => {
    var errors = false;
    var isAccredited = true;

    if (
      trust5MAssets === "" ||
      hasSophisticatedInvestor === "" ||
      notFormedForInvestment === "" ||
      grantorsAccredited === "" ||
      ownedByFamily === "" ||
      grantorQP === ""
    ) {
      errors = true;
    } else if (
      !(
        trust5MAssets === true &&
        hasSophisticatedInvestor === true &&
        notFormedForInvestment === true
      ) &&
      !(grantorsAccredited === true)
    ) {
      isAccredited = false;
    }

    if (errors === true) {
      setNotAllQuestionsAnsweredError(true);
    } else if (isAccredited === false) {
      setNotAccreditedError(true);
    } else {
      var utcDate = getDateUTC();
      setQualificationDate(utcDate.toISOString());
      // navigation.next();
      setActiveSlide((curSlide) => curSlide + 1);
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal
        show={showQPModal}
        setShowModal={setShowQPModal}
      />
      <AccreditedInvestorModal
        show={showAccreditedModal}
        setShowModal={setShowAccreditedModal}
      />
      <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-5">
        <div className="row g-0 w-100 mt-2 h-100 align-items-center justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4">
              <div className="col-12">
                <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="accredited-investor-questionnaire-section-header-text">
                  Trust Qualification
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="accredited-investor-questionnaire-gray-text">
                  The Securities and Exchange Commision (SEC) requires all Aqua
                  users to be Accredited Investors.
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={"Does the trust have more than $5M in assets?"}
              >
                <RadioInput
                  checked={trust5MAssets === true}
                  setValue={setTrust5MAssets}
                  radioValue={true}
                  label={"Yes"}
                  index={0}
                />
                <RadioInput
                  checked={trust5MAssets === false}
                  setValue={setTrust5MAssets}
                  radioValue={false}
                  label={"No"}
                  index={1}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Is a sophisticated person directing the trust’s investments?"
                }
              >
                <RadioInput
                  checked={hasSophisticatedInvestor === true}
                  setValue={setHasSophisticatedInvestor}
                  radioValue={true}
                  label={"Yes"}
                  index={2}
                />
                <RadioInput
                  checked={hasSophisticatedInvestor === false}
                  setValue={setHasSophisticatedInvestor}
                  radioValue={false}
                  label={"No"}
                  index={3}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Was the trust formed for the specific purpose of investing in opportunities offered on our platform?"
                }
              >
                <RadioInput
                  checked={notFormedForInvestment === false}
                  setValue={setNotFormedForInvestment}
                  radioValue={false}
                  label={"Yes"}
                  index={4}
                />
                <RadioInput
                  checked={notFormedForInvestment === true}
                  setValue={setNotFormedForInvestment}
                  radioValue={true}
                  label={"No"}
                  index={5}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Is each grantor of the trust an accredited investor and can the trust may be amended or revoked at any time by the grantors?"
                }
              >
                <RadioInput
                  checked={grantorsAccredited === true}
                  setValue={setGrantorsAccredited}
                  radioValue={true}
                  label={"Yes"}
                  index={6}
                />
                <RadioInput
                  checked={grantorsAccredited === false}
                  setValue={setGrantorsAccredited}
                  radioValue={false}
                  label={"No"}
                  index={7}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Is the trust owned by two or more close family members (spouses, siblings, descendants, and/or their respective spouses)?"
                }
              >
                <RadioInput
                  checked={ownedByFamily === true}
                  setValue={setOwnedByFamily}
                  radioValue={true}
                  label={"Yes"}
                  index={8}
                />
                <RadioInput
                  checked={ownedByFamily === false}
                  setValue={setOwnedByFamily}
                  radioValue={false}
                  label={"No"}
                  index={9}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mt-3 mb-3">
              <RadioInputGroup
                radioText={
                  "Are the trustee(s) and persons granting assets to the trust qualified purchasers (*Definition below)?"
                }
              >
                <RadioInput
                  checked={grantorQP === true}
                  setValue={setGrantorQP}
                  radioValue={true}
                  label={"Yes"}
                  index={10}
                />
                <RadioInput
                  checked={grantorQP === false}
                  setValue={setGrantorQP}
                  radioValue={false}
                  label={"No"}
                  index={11}
                />
              </RadioInputGroup>
            </div>
            <div className="row g-0 w-100 mt-4 mb-5 pe-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Next step"}
                        onClick={() => validateAccreditationStatus()}
                        size={"medium"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mt-5 pe-5">
              <div className="d-flex">
                <div className="me-5">
                  <AccreditedInvestorLink
                    setShowModal={setShowAccreditedModal}
                  />
                </div>
                <QualifiedPurchaserLink setShowModal={setShowQPModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertSnackBar
        snackbarState={notAccreditedError}
        setSnackbarState={setNotAccreditedError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Sorry, your account does not qualify as an accredited investor, and we only allow accredited investors on our platform at this time."
        }
      />
      <AlertSnackBar
        snackbarState={notAllQuestionsAnsweredError}
        setSnackbarState={setNotAllQuestionsAnsweredError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must answer all of the questions below."}
      />
    </React.Fragment>
  );
}
export default TrustAccreditationQualification;
