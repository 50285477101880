import React from "react";
import "./ConfirmInvestmentProfileIncompleteSectionStyles/ConfirmInvestmentProfileIncompleteSectionStyles.scss";

function ConfirmInvestmentProfileIncompleteSection({
  history,
  accountType,
  investorProfileComplete,
  entityComplete,
  accountID,
}) {
  let types = {
    TRT: "Trust",
    ENT: "Entity",
  };
  return (
    <React.Fragment>
      {/* <div className="row g-0 w-100 mb-1">
        <div className="col-10">
          <div className="row g-0 w-100">
            <div className="col ps-5">
              <img
                src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                alt="error"
                width="15"
                height="15"
              />
              <span className="ms-3 qp-error-text">
                Unfortunately, you cannot invest yet.
              </span>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row g-0 w-100 pb-5">
        <div className="col-10">
          {!investorProfileComplete && (
            <div className="row g-0 w-100">
              <div className="col-12">
                <div
                  className="col ps-5"
                  onClick={() => history.push("/profile")}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                      alt="error"
                      width="15"
                      height="15"
                    />
                    <span className="ms-3 qp-error-text">
                      Investor Information Incomplete
                    </span>
                    <span
                      className="ms-2 qp-error-text"
                      style={{
                        cursor: "pointer",
                        color: "#0D47A1",
                        fontWeight: 700,
                      }}
                      onClick={() => history.push("/profile")}
                    >
                      Complete >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(accountType === "ENT" || accountType === "TRT") && !entityComplete && investorProfileComplete && (
            <div className="row g-0 w-100">
              <div className="col-12">
                <div
                  className="col ps-5 align-items-center"
                  onClick={() =>
                    history.push("/investment-accounts/add/" + accountID)
                  }
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="https://d1swyfveypj49y.cloudfront.net/ErrorIcon.svg"
                      alt="error"
                      width="15"
                      height="15"
                    />
                    <span className="ms-3 qp-error-text">
                      {types[accountType] + " Information Incomplete"}
                    </span>
                    <span
                      className="ms-2 qp-error-text"
                      style={{
                        cursor: "pointer",
                        color: "#0D47A1",
                        fontWeight: 700,
                      }}
                      onClick={() =>
                        history.push("/investment-accounts/add/" + accountID)
                      }
                    >
                      Complete >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
export default ConfirmInvestmentProfileIncompleteSection;
