import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { useRecoilState } from "recoil";
import {
  accountTypeIndividualVal,
  accountTypeTrustVal,
  accountTypeEntityVal,
  accountTypeJointVal,
  accountTypeSMLLCVal,
} from "../../../../../../../atoms";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AlertSnackBar from "../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import MuiButton from "../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";

function AccountTypeSelection({ setActiveSlide }) {
  const [accountTypeIndividual, setAccountTypeIndividual] = useRecoilState(
    accountTypeIndividualVal
  );
  const [accountTypeRetirement, setAccountTypeRetirement] = useState(false);
  const [accountTypeSMLLC, setAccountTypeSMLLC] =
    useRecoilState(accountTypeSMLLCVal);
  const [accountTypeTrust, setAccountTypeTrust] =
    useRecoilState(accountTypeTrustVal);
  const [accountTypeEntity, setAccountTypeEntity] =
    useRecoilState(accountTypeEntityVal);
  const [accountTypeJoint, setAccountTypeJoint] =
    useRecoilState(accountTypeJointVal);
  const [accountTypeIndividualFocus, setAccountTypeIndividualFocus] =
    useState(false);
  const [accountTypeTrustFocus, setAccountTypeTrustFocus] = useState(false);
  const [accountTypeSMLLCFocus, setAccountTypeSMLLCFocus] = useState(false);
  const [accountTypeEntityFocus, setAccountTypeEntityFocus] = useState(false);
  const [accountTypeRetirementFocus, setAccountTypeRetirementFocus] =
    useState(false);
  const [accountTypeJointFocus, setAccountTypeJointFocus] = useState(false);
  const [accountTypeIndividualDivClass, setAccountTypeIndividualDivClass] =
    useState("selection-1");
  const [accountTypeTrustDivClass, setAccountTypeTrustDivClass] =
    useState("selection-1");
  const [accountTypeEntityDivClass, setAccountTypeEntityDivClass] =
    useState("selection-1");
  const [accountTypeSMLLCDivClass, setAccountTypeSMLLCDivClass] =
    useState("selection-1");
  const [accountTypeRetirementDivClass, setAccountTypeRetirementDivClass] =
    useState("selection-1");
  const [accountTypeJointDivClass, setAccountTypeJointDivClass] =
    useState("selection-1");
  const [accountSelectionError, setAccountSelectionError] = useState("");

  const [accountTypeError, setAccountTypeError] = useState(false);

  let history = useHistory();

  useEffect(() => {
    setAccountTypeIndividualFocus(accountTypeIndividual);
    setAccountTypeTrustFocus(accountTypeTrust);
    setAccountTypeEntityFocus(accountTypeEntity);
    setAccountTypeRetirementFocus(accountTypeRetirement);
    setAccountTypeJointFocus(accountTypeJoint);
    setAccountTypeSMLLCFocus(accountTypeSMLLC);
  }, []);

  useEffect(() => {
    setAccountTypeIndividualDivClass(
      accountTypeIndividualFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeIndividual(accountTypeIndividualFocus);
    setAccountSelectionError("");
  }, [accountTypeIndividualFocus, setAccountTypeIndividual]);

  useEffect(() => {
    setAccountTypeSMLLCDivClass(
      accountTypeSMLLCFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeSMLLC(accountTypeSMLLCFocus);
    setAccountSelectionError("");
  }, [accountTypeSMLLCFocus, setAccountTypeSMLLC]);

  useEffect(() => {
    setAccountTypeTrustDivClass(
      accountTypeTrustFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeTrust(accountTypeTrustFocus);
    setAccountSelectionError("");
  }, [accountTypeTrustFocus, setAccountTypeTrust]);

  useEffect(() => {
    setAccountTypeEntityDivClass(
      accountTypeEntityFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeEntity(accountTypeEntityFocus);
    setAccountSelectionError("");
  }, [accountTypeEntityFocus, setAccountTypeEntity]);

  useEffect(() => {
    setAccountTypeRetirementDivClass(
      accountTypeRetirementFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeRetirement(accountTypeRetirementFocus);
    setAccountSelectionError("");
  }, [accountTypeRetirementFocus, setAccountTypeRetirement]);

  useEffect(() => {
    setAccountTypeJointDivClass(
      accountTypeJointFocus ? "selection-1-selected" : "selection-1"
    );
    setAccountTypeJoint(accountTypeJointFocus);
    setAccountSelectionError("");
  }, [accountTypeJointFocus, setAccountTypeJoint]);

  const validateAccountSelection = () => {
    let isValid = true;

    if (
      !accountTypeIndividual &&
      !accountTypeTrust &&
      !accountTypeEntity &&
      !accountTypeRetirement &&
      !accountTypeJoint &&
      !accountTypeSMLLC
    ) {
      isValid = false;
    }

    if (isValid) {
      //   navigation.next();
      setActiveSlide((curSlide) => curSlide + 1);
    } else {
      setAccountTypeError(true);
      setAccountSelectionError("Please select an account type from above.");
    }
  };

  return (
    <div className="container-fluid g-0 h-100 align-items-center ms-5 pe-3">
      <div className="row g-0 w-100 h-100 align-items-center justify-content-center">
        <div className="col-12 g-0">
          <div className="row g-0 w-100 mb-4">
            <div className="col-11">
              <span className="sub-text pe-5">
                Please select an account type from below:
              </span>
            </div>
          </div>
          <div className="row g-0 w-90 mb-3 justify-content-start">
            <div className="col-7">
              <div
                className={
                  accountTypeIndividualDivClass + " pt-3 pb-3 ps-3 pe-3"
                }
                onClick={() => {
                  setAccountTypeTrustFocus(false);
                  setAccountTypeSMLLCFocus(false);
                  setAccountTypeEntityFocus(false);
                  setAccountTypeJointFocus(false);
                  setAccountTypeRetirementFocus(false);
                  setAccountTypeIndividualFocus(!accountTypeIndividualFocus);
                }}
              >
                <div className="row g-0 w-100 align-items-center">
                  <div className="col align-items-center">
                    <div className="row g-0 w-100">
                      <img
                        src="https://d1swyfveypj49y.cloudfront.net/individual-account.svg"
                        alt="individual-account"
                        style={{ width: 25 }}
                        className="me-2"
                      />
                      <div className="col d-flex align-items-center">
                        <span className="align-self-center new-selection-1-text ps-2 pe-2">
                          As an individual
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-90 mb-3 justify-content-start">
            <div className="col-7">
              <div
                className={accountTypeSMLLCDivClass + " pt-3 pb-3 ps-3 pe-3"}
                onClick={() => {
                  setAccountTypeTrustFocus(false);
                  setAccountTypeSMLLCFocus(!accountTypeSMLLCFocus);
                  setAccountTypeEntityFocus(false);
                  setAccountTypeJointFocus(false);
                  setAccountTypeRetirementFocus(false);
                  setAccountTypeIndividualFocus(false);
                }}
              >
                <div className="row g-0 w-100 align-items-center">
                  <div className="col align-items-center">
                    <div className="row g-0 w-100">
                      <img
                        src="https://d1swyfveypj49y.cloudfront.net/individual-account.svg"
                        alt="individual-account"
                        style={{ width: 25 }}
                        className="me-2"
                      />
                      <div className="col d-flex align-items-center">
                        <span className="align-self-center new-selection-1-text ps-2 pe-2">
                          Through a Single-member LLC
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row g-0 w-90 mb-3 justify-content-start">
            <div className="col-7">
              <div
                className={accountTypeJointDivClass + " pt-3 pb-3 ps-3 pe-3"}
                onClick={() => {
                  setAccountTypeTrustFocus(false);
                  setAccountTypeSMLLCFocus(false);
                  setAccountTypeEntityFocus(false);
                  setAccountTypeRetirementFocus(false);
                  setAccountTypeIndividualFocus(false);
                  setAccountTypeJointFocus(!accountTypeJointFocus);
                }}
              >
                <div className="row g-0 w-100 align-items-center">
                  <div className="col align-items-center">
                    <div className="row g-0 w-100">
                      <img
                        src="https://d1swyfveypj49y.cloudfront.net/trust-account.svg"
                        alt="joint-account"
                        style={{ width: 25 }}
                        className="me-2"
                      />
                      <div className="col d-flex align-items-center">
                        <span className="align-self-center new-selection-1-text ps-2 pe-2">
                          Through a joint account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row g-0 w-90 mb-3 justify-content-start">
            <div className="col-7">
              <div
                className={accountTypeTrustDivClass + " pt-3 pb-3 ps-3 pe-3"}
                onClick={() => {
                  setAccountTypeTrustFocus(!accountTypeTrustFocus);
                  setAccountTypeSMLLCFocus(false);
                  setAccountTypeEntityFocus(false);
                  setAccountTypeRetirementFocus(false);
                  setAccountTypeIndividualFocus(false);
                  setAccountTypeJointFocus(false);
                }}
              >
                <div className="row g-0 w-100 align-items-center">
                  <div className="col align-items-center">
                    <div className="row g-0 w-100">
                      <img
                        src="https://d1swyfveypj49y.cloudfront.net/trust-account.svg"
                        alt="individual-account"
                        style={{ width: 25 }}
                        className="me-2"
                      />
                      <div className="col d-flex align-items-center">
                        <span className="align-self-center new-selection-1-text ps-2 pe-2">
                          Through a trust account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-90 mb-3 justify-content-start">
            <div className="col-7">
              <div
                className={accountTypeEntityDivClass + " pt-3 pb-3 ps-3 pe-3"}
                onClick={() => {
                  setAccountTypeTrustFocus(false);
                  setAccountTypeEntityFocus(!accountTypeEntityFocus);
                  setAccountTypeRetirementFocus(false);
                  setAccountTypeSMLLCFocus(false);
                  setAccountTypeIndividualFocus(false);
                  setAccountTypeJointFocus(false);
                }}
              >
                <div className="row g-0 w-100 align-items-center">
                  <div className="col align-items-center">
                    <div className="row g-0 w-100">
                      <img
                        src="https://d1swyfveypj49y.cloudfront.net/entity-account.svg"
                        alt="entity-account"
                        style={{ width: 25 }}
                        className="me-2"
                      />
                      <div className="col d-flex align-items-center">
                        <span className="align-self-center new-selection-1-text ps-2 pe-2">
                          Through another type of entity
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-5">
            <div className="col-10">
              <div className="row g-0 w-100">
                <div className="col ms-0 me-auto">
                  <div className="d-flex" style={{ cursor: "pointer" }}>
                    <MuiButton
                      buttonText={"Next step"}
                      onClick={() => validateAccountSelection()}
                      size={"medium"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AlertSnackBar
            snackbarState={accountTypeError}
            setSnackbarState={setAccountTypeError}
            vertical={"top"}
            horizontal={"right"}
            duration={5000}
            sxProps={{ paddingLeft: "3px" }}
            severity={"warning"}
            message={"Please select a valid account type."}
          />
        </div>
      </div>
    </div>
  );
}
export default AccountTypeSelection;
