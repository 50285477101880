import React, { useState, useEffect } from "react";
//import "./Styles/Styles.scss";
import HelloSign from "hellosign-embedded";
import {
  getSubdocLinkFromCode,
  resendMagicLink,
  clickToSign,
} from "../../Api/DocumentsApi";
//import SnackBar from "./../../Components/Alerts/SnackBar/SnackBar";
import Button from "@mui/material/Button";
import { PDFDocument } from "./../Fund/FundDetailsPage/FundDetailsPageMainContentSection/DeckSection/DeckSection.js";
import { getSignedURL } from "../../Api/GlobalFunctions/SignedURL";

import { useParams } from "react-router-dom";
import LoadingAnimation from "../../Components/GlobalComponents/LoadingAnimation/LoadingAnimation";
import Modal from "react-bootstrap/Modal";

const clickSign = async (code) => {
  const resp = await clickToSign(code);
};

const SydecarSigModal = ({
  showModal,
  embeddedLink,
  setShowModal,
  code,
  setDocsSigned,
}) => (
  <Modal
    show={showModal}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={() => setShowModal(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title
        id="contained-modal-title-vcenter"
        style={{ fontFamily: "Montserrat" }}
      >
        Subscription Documents
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ textAlign: "center" }}>
        <iframe height={"600px"} width={"600px"} src={`${embeddedLink}`} />
      </div>
    </Modal.Body>
    <Modal.Footer>
      {window.location.host === "localhost" ||
      window.location.host === "florencecapitaldev.investwithaqua.com" ||
      window.location.host === "florencecapital.investwithaqua.com" ? (
        <React.Fragment>
          <div className="row g-0 w-100 mb-2">
            <span className="text-center">
              Please be sure to review the document riders included in the
              subscription packet prior to signing.
            </span>
          </div>
          <div className="row g-0 w-100 mb-2">
            <button
              type="buton"
              class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
              onClick={() => {
                clickSign(code);
                setShowModal(false);
                setDocsSigned(true);
              }}
              size={"large"}
            >
              Click to Sign
            </button>
          </div>
        </React.Fragment>
      ) : (
        <button
          type="buton"
          class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
          onClick={() => {
            clickSign(code);
            setShowModal(false);
            setDocsSigned(true);
          }}
          size={"large"}
        >
          Click to Sign
        </button>
      )}
    </Modal.Footer>
  </Modal>
);

function SubDocs() {
  const { code, sigId, token } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [linkResent, setLinkResent] = useState(false);
  const [embeddedLink, setEmbeddedLink] = useState("");

  const [docsSigned, setDocsSigned] = useState(false);

  const version = window.location.pathname.split("/")[1];

  const [sydecar, setSydecar] = useState(false);
  const [showModal, setShowModal] = useState(false);

  /*

            <div className="row g-0 w-100 mt-4">
              <div className="d-flex justify-content-end pe-3 pb-3">
                <button
                  type="button"
                  class="btn btn-lg btn-class justify-self-start"
                  onClick={() => {
                    setShowSignatureModal(false);
                    history.push("/invest");
                  }}
                >
                  Back to Opportunities
                </button>
              </div>
            </div> */
  const clientId =
    process.env.REACT_APP_API_ENDPOINT ===
    "https://api.staging.investwithaqua.com"
      ? "9c4a35463ca1aaffef6e10f29104b2b4"
      : process.env.REACT_APP_API_ENDPOINT === "https://api.investwithaqua.com"
      ? "78ef255f844acfc6eb47c48e71e28bb2"
      : process.env.REACT_APP_API_ENDPOINT ===
        "https://api.wm-demo.investwithaqua.com"
      ? "1eb021ef3831c851cffe3ad1f80e94ee"
      : "ee92b78dc5a4c9124758eb38095d1e82";

  const client = new HelloSign({
    clientId: clientId,
    element: document.getElementById("dropbox-sign-client"),
    testMode: true,
    skipDomainVerification:
      process.env.REACT_APP_API_ENDPOINT !==
        "https://api.staging.investwithaqua.com" &&
      process.env.REACT_APP_API_ENDPOINT !== "https://api.investwithaqua.com"
        ? true
        : false,
  });

  const callSubDocsEndpoint = async (setDocsSigned) => {
    try {
      setLoading(true);
      setError(false);
      const resp = await getSubdocLinkFromCode(code);
      if (resp.data.msg === "Link is invalid.") {
        setError(true);
      } else {
        if (!resp.data.msg.startsWith("https://aqua-api")) {
          setEmbeddedLink(resp.data.msg);
          client.open(resp.data.msg, {
            clientId: clientId,
            container: document.getElementById("dropbox-sign-client"),
          });

          client.on("finish", () => {
            window.close();
            setDocsSigned(true);
          });
        } else {
          /*async function getSignedURL() {
            var tempURL = await Storage.get(resp.data.msg, {
              level: "private",
            });
            setEmbeddedLink(tempURL);
         }
          getSignedURL();*/
          setEmbeddedLink(resp.data.msg);
          setSydecar(true);
          setShowModal(true);
        }
      }
    } catch (e) {
      console.log(e);
      setError(true);
      console.log("Error");
    }
    setLoading(false);
  };

  const openModal = async () => {
    client.open(
      `https://app.hellosign.com/editor/embeddedSign?signature_id=${sigId}&token=${token}`,
      {
        clientId: clientId,
        container: document.getElementById("dropbox-sign-client"),
      }
    );
    setLoading(false);
  };

  const resend = async () => {
    setLinkResent(true);
    const resp = await resendMagicLink(code);
  };

  const [investmentData, setInvestmentData] = useState();

  useEffect(() => {
    async function getInvData() {
      const resp = await getSubdocLinkFromCode(code);
      resp &&
        resp.data &&
        resp.data.msg &&
        resp.data.investment_details &&
        setInvestmentData(resp.data.investment_details);
    }

    getInvData();
  });

  return (
    <div className="container-xxl container-fluid w-sm-75 w-100 mt-3 pt-5 justify-content-center">
      {!docsSigned ? (
        <div className="row g-0 w-100 mt-5 mb-2 text-center">
          <h2>Review and sign subscription documents</h2>
          {investmentData && (
            <>
              <h4>Investment Details</h4>
              <p>
                Investment Amount: $
                {investmentData.amount &&
                  investmentData.amount.toLocaleString()}{" "}
                <br />
                Subscriber Name: {investmentData.subscriber}
                <br />
                Opportunity Name: {investmentData.fund}{" "}
              </p>{" "}
            </>
          )}
          {/*<SnackBar
          snackbarState={linkResent}
          setSnackbarState={setLinkResent}
          vertical={"top"}
          horizontal={"right"}
          duration={5000}
          sxProps={{ paddingLeft: "3px" }}
          severity={"success"}
          message={"Magic link resent."}
  /> */}
          <div>
            {
              <button
                type="buton"
                class="btn btn-lg btn-class justify-self-start me-md-5 me-3"
                onClick={() => {
                  version === "magiclink" ? callSubDocsEndpoint() : openModal();
                }}
                size={"large"}
              >
                Open Subdocs
              </button>
            }
          </div>
          {loading && <LoadingAnimation loading={true} />}
          {sydecar === false && !error && embeddedLink !== "" && (
            <div id="dropbox-sign-client" />
          )}
          {sydecar === true && (
            <SydecarSigModal
              setDocsSigned={setDocsSigned}
              embeddedLink={embeddedLink}
              showModal={showModal}
              setShowModal={setShowModal}
              code={code}
            />
          )}
          {error && (
            <span>
              Sorry, this link is either invalid or expired. Click{" "}
              <a onClick={resend}>here</a> to resend.{" "}
            </span>
          )}
        </div>
      ) : (
        <>Docs are successfully signed, wire instructions will be sent.</>
      )}
    </div>
  );
}

export default SubDocs;
