import React, {useEffect, useState} from 'react';
import GreyBorderWidget from './GreyBorderWidget';
import useWindowDimensions from '../WindowDimensions/useWindowDimensions';

/* Display cards/widgets with border spacing in rows of 3
-- Input: Array of objects
    objects must include:
    -id
    -definition
    -term
-- output: row with sets of 3 or less cards
*/
const GreyBorderWidgetRow = ({cardItems}) => {
    const [rowClass, setRowClass] = useState("row content row-cols-3")
    let { width } = useWindowDimensions();
    
    useEffect(() => {
        //let viewWidth = {width}
        if(cardItems.length == 1 || width <= 1030) {
            setRowClass("row content row-cols-1")
        }

        if(cardItems.length%3==0){
            setRowClass("row content row-cols-3")
        }
    },[])

    let mobileClass = "row content row-cols-1 "
  
    let itemsRow = (
        <>
        {cardItems.length > 0 ? (
            <>
            {width <=1030 ? (
                <div className={mobileClass}>
                    {cardItems.map(item => 
                        <GreyBorderWidget cardItem={item} key={item.id}/>
                    )}
                </div>
            ) : (
                <div className={rowClass}>
                {cardItems.map(item => 
                    <GreyBorderWidget cardItem={item} key={item.id}/>
                )}
            </div>
            )}
            </>
        ): null}
        </>
    )
  
    return (
        <> 
          <div className="col-12">
              {itemsRow}
          </div>
        </>) ;
};

export default GreyBorderWidgetRow;
