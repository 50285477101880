import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Media from "react-media";
import PhoneInvestmentGrid from "./PhoneInvestmentGrid/PhoneInvestmentGrid";
import "./InvestmentTableStyles/InvestmentTableStyles.css";
import Button from "../../../GlobalComponents/Button/Button.js";
import { useHistory } from "react-router-dom";
import * as dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");

function InvestmentTable({ investments, distributions }) {
  const [fundInvestments, setFundInvestments] = useState([]);

  useEffect(() => {
    if (
      investments !== null &&
      investments !== undefined &&
      investments.length !== 0
    ) {
      const sortedInvestments = investments.sort((a, b) =>
        a.investment_date.localeCompare(b.investment_date)
      );
      let hashedFundInvestment = new Map();

      sortedInvestments.forEach(
        ({
          investment_date,
          investment_amount,
          status,
          fund,
          account_name,
          capital_call,
          marked_value
        }) => {
          let unixInvestmentDateNoTime = dayjs(
            dayjs(investment_date).format("MMM D, YYYY")
          );
          let unixInvestmentDate = dayjs(unixInvestmentDateNoTime).unix();
          let unixInvestmentDateISO = dayjs
            .unix(unixInvestmentDate)
            .toISOString();
          let fundClosingDate = "N/A";

          if (
            fund.fund_closing_date !== null &&
            fund.fund_closing_date !== undefined &&
            fund.fund_closing_date !== ""
          ) {
            let unixFundClosingDateNoTime = dayjs(
              dayjs(fund.fund_closing_date).format("MMM D, YYYY")
            );
            let unixFundClosingDate = dayjs(unixFundClosingDateNoTime).unix();
            fundClosingDate = unixFundClosingDate;
          }

          if (!hashedFundInvestment.has(fund.name)) {
            let hashedAccountFundInvestment = new Map();
            hashedAccountFundInvestment.set(account_name, {
              investmentAmount: investment_amount,
              investmentDate: unixInvestmentDate,
              markedValue: marked_value,
              capitalCall: capital_call,
              opportunityStatus: fund.investment_status,
              accountName: account_name,
              investmentStage: status,
              firmName: fund.originator_firm.name,
              link: fund.link,
              logoLink: fund.originator_firm.logo_link,
              strategy: fund.strategy_type.type,
              fundClosingDate:
                fundClosingDate !== "N/A" ? fundClosingDate : "N/A",
              targetFundLength: "N/A",
              lastDistributionAmount: 0,
              totalDistributed: 0,
            });

            hashedFundInvestment.set(fund.name, hashedAccountFundInvestment);
          } else if (
            hashedFundInvestment.has(fund.name) &&
            !hashedFundInvestment.get(fund.name).has(account_name)
          ) {
            hashedFundInvestment.get(fund.name).set(account_name, {
              investmentAmount: investment_amount,
              investmentDate: unixInvestmentDate,
              opportunityStatus: fund.investment_status,
              accountName: account_name,
              investmentStage: status,
              markedValue: marked_value,
              capitalCall: capital_call,
              firmName: fund.originator_firm.name,
              link: fund.link,
              logoLink: fund.originator_firm.logo_link,
              strategy: fund.strategy_type.type,
              fundClosingDate:
                fundClosingDate !== "N/A" ? fundClosingDate : "N/A",
              targetFundLength: "N/A",
              lastDistributionAmount: 0,
              totalDistributed: 0,
            });
          } else {
            var currentAccountFundInvestment = hashedFundInvestment
              .get(fund.name)
              .get(account_name).investmentAmount;
            var newInvestment =
              currentAccountFundInvestment + investment_amount;
            hashedFundInvestment
              .get(fund.name)
              .get(account_name).investmentAmount = newInvestment;
          }

          // if (
          //   hashedFundInvestment[fund.name][account_name] === null ||
          //   hashedFundInvestment[fund.name][account_name] === undefined
          // ) {
          //   hashedFundInvestment[fund.name][account_name] = {
          //     investmentAmount: 0,
          //     investmentDate: "",
          //     lastDistributionDate: "N/A",
          //     lastDistributionAmount: 0,
          //     opportunityStatus: "N/A",
          //     accountName: account_name,
          //     investmentStage: "N/A",
          //     fundClosingDate: "N/A",
          //     firmName: "",
          //     link: "",
          //     logoLink: "",
          //     strategy: "",
          //     targetFundLength: "N/A",
          //   };
          // }
          // hashedFundInvestment[fund.name][account_name].investmentAmount +=
          //   investment_amount;
          // hashedFundInvestment[fund.name][account_name].investmentDate =
          //   unixInvestmentDate;
          // hashedFundInvestment[fund.name][account_name].opportunityStatus =
          //   fund.investment_status;
          // hashedFundInvestment[fund.name][account_name].investmentStage =
          //   status;
          // hashedFundInvestment[fund.name][account_name].firmName =
          //   fund.originator_firm.name;
          // hashedFundInvestment[fund.name][account_name].logoLink =
          //   fund.originator_firm.logo_link;
          // hashedFundInvestment[fund.name][account_name].strategy =
          //   fund.strategy_type.type;
          // hashedFundInvestment[fund.name][account_name].fundClosingDate =
          //   fundClosingDate !== "N/A" ? fundClosingDate : "N/A";
          // hashedFundInvestment[fund.name][account_name].link = fund.link;
        }
      );

      if (
        distributions !== null &&
        distributions !== undefined &&
        distributions.length !== 0
      ) {
        const sortedDistributions = distributions.sort((a, b) =>
          a.distribution_date.localeCompare(b.distribution_date)
        );

        sortedDistributions.forEach(
          ({ distribution_date, distribution_amount, fund, account_name }) => {
            let unixDistributionDateNoTime = dayjs(
              dayjs(distribution_date).format("MMM D, YYYY")
            );
            let unixDistributionDate = dayjs(unixDistributionDateNoTime).unix();
            let unixDistributionDateISO = dayjs
              .unix(unixDistributionDate)
              .toISOString();
            let fundClosingDate = null;

            if (
              fund.fund_closing_date !== null &&
              fund.fund_closing_date !== undefined &&
              fund.fund_closing_date !== ""
            ) {
              let unixFundClosingDateNoTime = dayjs(
                dayjs(fund.fund_closing_date).format("MMM D, YYYY")
              );
              let unixFundClosingDate = dayjs(unixFundClosingDateNoTime).unix();
              fundClosingDate = unixFundClosingDate;
            }

            //Replicate the logic that was used for the investment array

            if (!hashedFundInvestment.has(fund.name)) {
              let hashedAccountFundDistribution = new Map();
              hashedAccountFundDistribution.set(account_name, {
                investmentAmount: 0,
                investmentDate: "",
                accountName: account_name,
                lastDistributionDate: unixDistributionDate,
                lastDistributionAmount: distribution_amount,
                opportunityStatus: fund.investment_status,
                investmentStage: "N/A",
                fundClosingDate:
                  fundClosingDate !== null ? fundClosingDate : "N/A",
                firmName: fund.originator_firm.name,
                link: fund.link,
                logoLink: fund.originator_firm.logo_link,
                strategy: fund.strategy_type.type,
                targetFundLength: "N/A",
                totalDistributed: distribution_amount,
              });
            } else if (
              hashedFundInvestment.has(fund.name) &&
              !hashedFundInvestment.get(fund.name).has(account_name)
            ) {
              hashedFundInvestment.get(fund.name).set(account_name, {
                investmentAmount: 0,
                investmentDate: "",
                accountName: account_name,
                lastDistributionDate: unixDistributionDate,
                lastDistributionAmount: distribution_amount,
                opportunityStatus: fund.investment_status,
                investmentStage: "N/A",
                fundClosingDate:
                  fundClosingDate !== null ? fundClosingDate : "N/A",
                firmName: fund.originator_firm.name,
                link: fund.link,
                logoLink: fund.originator_firm.logo_link,
                strategy: fund.strategy_type.type,
                targetFundLength: "N/A",
                totalDistributed: distribution_amount,
              });
            } else {
              var currentAccountFundDistribution = hashedFundInvestment
                .get(fund.name)
                .get(account_name).totalDistributed;
              var newDistributed =
                currentAccountFundDistribution + distribution_amount;
              hashedFundInvestment
                .get(fund.name)
                .get(account_name).totalDistributed = newDistributed;
              hashedFundInvestment
                .get(fund.name)
                .get(account_name).lastDistributionAmount = distribution_amount;
              hashedFundInvestment
                .get(fund.name)
                .get(account_name).lastDistributionDate = unixDistributionDate;
            }

            // if (
            //   hashedFundInvestment[fund.name][account_name] === null ||
            //   hashedFundInvestment[fund.name][account_name] === undefined
            // ) {
            //   hashedFundInvestment[fund.name][account_name] = {
            //     investmentAmount: 0,
            //     investmentDate: "",
            //     accountName: account_name,
            //     lastDistributionDate: "N/A",
            //     lastDistributionAmount: 0,
            //     opportunityStatus: "N/A",
            //     investmentStage: "N/A",
            //     fundClosingDate: "N/A",
            //     firmName: "",
            //     link: "",
            //     logoLink: "",
            //     strategy: "",
            //     targetFundLength: "N/A",
            //   };
            // }
            // hashedFundInvestment[fund.name][account_name].totalDistributed +=
            //   distribution_amount;
            // hashedFundInvestment[fund.name][
            //   account_name
            // ].lastDistributionAmount = distribution_amount;
            // hashedFundInvestment[fund.name][account_name].lastDistributionDate =
            //   unixDistributionDate;
            // hashedFundInvestment[fund.name][account_name].opportunityStatus =
            //   fund.investment_status;
            // hashedFundInvestment[fund.name][account_name].firmName =
            //   fund.originator_firm.name;
            // hashedFundInvestment[fund.name][account_name].link = fund.link;
            // hashedFundInvestment[fund.name][account_name].logoLink =
            //   fund.originator_firm.logo_link;
            // hashedFundInvestment[fund.name][account_name].strategy =
            //   fund.strategy_type.type;
            // hashedFundInvestment[fund.name][account_name].fundClosingDate =
            //   fundClosingDate !== null ? fundClosingDate : "";
          }
        );
      }

      let keyArray = Array.from(hashedFundInvestment.keys());

      const valueArr = keyArray.map((keyElement) => {
        let valuesArray = Array.from(
          hashedFundInvestment.get(keyElement).values()
        );

        let resultantArray = valuesArray.map((value, idx) => {
          return {
            id: idx,
            fundName: keyElement,
            ...value,
          };
        });

        return resultantArray;
      });

      let flattenedArray = valueArr.reduce((acc, val) => acc.concat(val), []);

      // const userInvestments = Object.entries(hashedFundInvestment).map(
      //   (
      //     [
      //       key,
      //       {
      //         investmentAmount,
      //         investmentDate,
      //         lastDistributionDate,
      //         lastDistributionAmount,
      //         totalDistributed,
      //         accountName,
      //         opportunityStatus,
      //         investmentStage,
      //         fundClosingDate,
      //         firmName,
      //         link,
      //         logoLink,
      //         strategy,
      //         targetFundLength,
      //       },
      //     ],
      //     index
      //   ) => {
      //     return {
      //       id: index,
      //       fundName: key,
      //       investmentAmount: investmentAmount,
      //       investmentDate: investmentDate,
      //       lastDistributionDate: lastDistributionDate,
      //       accountName: accountName,
      //       lastDistributionAmount: lastDistributionAmount,
      //       totalDistributed: totalDistributed,
      //       opportunityStatus: opportunityStatus,
      //       investmentStage: investmentStage,
      //       fundClosingDate: fundClosingDate,
      //       firmName: firmName,
      //       link: link,
      //       logoLink: logoLink,
      //       strategy: strategy,
      //       targetFundLength: targetFundLength,
      //     };
      //   }
      // );

      setFundInvestments(
        flattenedArray.map((elem, idx) => ({ ...elem, id: idx }))
      );
    }
  }, [investments, distributions]);

  let history = useHistory();

  function moneyFormatter(cell) {
    if (cell === undefined || cell === null) {
      return "$ 0";
    }
    var integer = parseInt(cell.toString(), 10);
    return "$ " + integer.toLocaleString("en-US");
  }

  function fundStrategyFormatter(cell) {
    let fundStrategies = {
      "Private Equity": "PRIVATE_EQUITY",
      "Direct Landing": "DIRECT_LANDING",
      "CLO Management": "CLO_MANAGEMENT",
      "Structured Credit": "STRUCTURED_CREDIT",
      "Co-Investment": "CO_INVESTMENT",
      "Fund of Funds": "FUND_OF_FUNDS",
      "Buyout": "BUYOUT",
      "Credit": "CREDIT",
      "Growth": "GROWTH",

    };
    return fundStrategies[cell];
  }

  function investmentStatusFormatter(cell) {
    if (cell !== undefined && cell !== null) {
      let investmentStatusMap = {
        P: "Pending Approval",
        PD: "Pending Sub Docs",
        PF: "Pending Funding",
        F: "Funded",
        A: "Approved",
        R: "Rejected",
      };
      return investmentStatusMap[cell.toString()];
    }
    return "";
  }

  const newColumns = [
    {
      dataField: "fundName",
      text: "Opportunity Name",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "investmentAmount",
      text: "Investment Amount",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: moneyFormatter,
    },
    {
      dataField: "totalDistributed",
      text: "Total Distributed",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: moneyFormatter,
    },
    {
      dataField: "opportunityStatus",
      text: "Opportunity Status",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "investmentStage",
      text: "Investment Stage",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: investmentStatusFormatter,
    },
    {
      dataField: "accountName",
      text: "Account",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
    },
    {
      dataField: "markedValue",
      text: "Marked Value",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: moneyFormatter,
    },
    {
      dataField: "capitalCall",
      text: "Capital Called",
      classes: "table-data-style",
      headerClasses: "start-padding table-header-text",
      formatter: moneyFormatter
    },
  ];

  const expandRow = {
    parentClassName: (isExpanded, row, rowIndex) => {
      if (isExpanded) return "table-row-style-focus";
      return "table-row-style";
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        // setExpanded([row.id]);
        // expanded: [...expanded, row.id];
        //setFundName(row.fundName);
      } else if (!isExpand) {
        // this.setState(() => ({
        //   expanded: [],
        // }));
        //setExpanded([]);
        //setFundName("All");
      }
    },
    onlyOneExpanding: true,
    renderer: (row, isExpanded, expanded) => (
      <div className="container-fluid w-90">
        <div className="row g-0 w-100 mt-4 mb-4 d-flex justify-content-center">
          <div className="row g-0 w-100 mb-3">
            <div className="col-12 table-row-secondary-information-container">
              <div className="row g-0 w-100">
                <div className="col-6 table-row-secondary-information-divider">
                  <div className="row g-0 w-100 mt-2 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Firm Name
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {row.firmName}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Fund Close Date
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {row.fundClosingDate === "N/A"
                          ? "N/A"
                          : dayjs
                              .unix(row.fundClosingDate)
                              .format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Target Fund Length
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        10 years
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Fund Strategy
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {fundStrategyFormatter(row.strategy)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row g-0 w-100 mt-2 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Investment Date
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {dayjs.unix(row.investmentDate).format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Last Distribution Date
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {row.lastDistributionDate === "N/A" ||
                        row.lastDistributionDate === undefined
                          ? "N/A"
                          : dayjs
                              .unix(row.lastDistributionDate)
                              .format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Last Distribution Amount
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {"$ " +
                          row.lastDistributionAmount.toLocaleString("en-US")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-xxl-6 col-lg-8">
              <div className="row g-0 w-100 mt-4 align-items-center">
                <div
                  className="col-md-5 col-sm-6"
                  onClick={() => history.push("/invest/" + row.link)}
                >
                  <Button text={"Open Fund Details Page"} />
                </div>
                {/* <div className="col-md-4 col-12 ms-md-4 ms-0 mt-md-0 mt-4 h-100 d-flex">
                  <div
                    className="table-row-secondary-information-section-value align-self-center"
                    style={{ cursor: "pointer" }}
                  >
                    <u>Close</u>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div className="row g-0 w-100 mb-3">
      <div className="col-12">
        <div className="row g-0 w-100 mb-3">
          <div className="col-12 pe-4">
            <div className="row g-0 w-100 mb-4 align-items-center">
              <div className=" col-xl-2 col-lg-2 col-12 me-md-auto mb-lg-0 mb-3">
                <span className="portfolio-title-text">Investments</span>
              </div>
            </div>
            <Media
              queries={{
                medium: { maxWidth: 768 },
                large: { minWidth: 992 },
              }}
            >
              {(matches) =>
                matches.medium ? (
                  <PhoneInvestmentGrid fundInvestments={fundInvestments} />
                ) : (
                  <div className="row g-0 w-100 mb-3">
                    <div className="table-style pt-3 pe-2 d-flex justify-content-center">
                      <div className="w-98">
                        <BootstrapTable
                          keyField="id"
                          data={fundInvestments}
                          columns={newColumns}
                          bordered={false}
                          rowClasses={"table-row-style"}
                          expandRow={expandRow}
                        />
                        {fundInvestments.length === 0 && (
                          <div className=" w-100 pt-5 pb-5 text-center">
                            <span className="no-transactions-text">
                              You don't have any investments to show.
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InvestmentTable;
