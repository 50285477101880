import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import HelloSign from "hellosign-embedded";
import {
  getSubdocLink,
  getSubdocSignature,
} from "../../../../Api/DocumentsApi.js";
import {
  pollDocs,
  pollSignatureCompletion,
} from "../../../../Functions/functions";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";

const LoadingSpinnerAnimation = () => {
  return (
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const ErrorRenderingSubscriptionDocumentsMessage = () => {
  return (
    <div className="row g-0 w-100 mb-2">
      <div className="col-12">
        <div
          className="d-flex  p-3 align-items-center"
          style={{
            borderRadius: "4px",
            backgroundColor: "rgba(3, 27, 78, 0.04)",
          }}
        >
          <img
            src="/caution.svg"
            className="me-4"
            style={{ height: "50px", width: "50px" }}
          />

          <div className="col">
            <div className="row g-0 w-100 mb-1">
              <span className="response-text">
                Sorry, there was an issue generating your subscription documents
                automatically. A member of our team will reach out shortly with
                your subscription documents. We apologize for the inconvenience.
              </span>
            </div>
            <div className="row g-0 w-100">
              <span className="response-text" style={{ fontSize: "12px" }}>
                *If you don't hear from us within 48hrs, please contact us at
                ops@investwithaqua.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResponseTakingTooLongMessage = ({ setShowSignatureModal }) => {
  let history = useHistory();

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/clock.svg"
          style={{ maxWidth: "100px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center text-center ps-3 pe-3">
        <p className="modal-body-text">
          {"It's taking longer than expected to confirm your signature."}
        </p>
        <p className="modal-body-text">
          {"We'll let you know if anything else is needed from your end."}
        </p>
      </div>
      <div className="row g-0 w-100 mt-4">
        <div className="d-flex justify-content-end pe-3 pb-3">
          <button
            type="button"
            class="btn btn-lg btn-class justify-self-start"
            onClick={() => {
              setShowSignatureModal(false);
              history.push("/invest");
            }}
          >
            Back to Opportunities
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const ErrorConfirmingSignature = ({ setShowSignatureModal }) => {
  let history = useHistory();

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/error-icon.svg"
          style={{ maxWidth: "75px" }}
        />
      </div>
      <div className="row g-0 w-100 mt-4 justify-content-center text-center ps-3 pe-3">
        <p className="modal-body-text">
          {"Sorry, there was an error confirming your signature."}
        </p>
        <p className="modal-body-text">
          {
            "We'll try sorting the issue out on our end and let you know if anything else is needed from your end."
          }
        </p>
      </div>
      <div className="row g-0 w-100 mt-4">
        <div className="d-flex justify-content-end pe-3 pb-3">
          <button
            type="button"
            class="btn btn-lg btn-class justify-self-start"
            onClick={() => {
              setShowSignatureModal(false);
              history.push("/invest");
            }}
          >
            Back to Opportunities
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const SuccessfullyConfirmedSignature = () => {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mt-4 justify-content-center">
        <img
          src="https://d1swyfveypj49y.cloudfront.net/green-check.svg"
          style={{ maxWidth: "75px" }}
        />
      </div>
      <div className="row g-0 w-100 justify-content-center text-center mt-4 ps-3 pe-3">
        <p className="modal-body-text">
          Your subscription document signature has been successfully confirmed,
          and you will now be able to proceed through the investment process.
        </p>
      </div>
    </React.Fragment>
  );
};

function SubDocs({ summary, accountID, fundSubscriptionInfo, setRefresh }) {
  const [loading, setLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showSigButton, setShowSigButton] = useState(true);

  const client = new HelloSign({
    clientId:
      process.env.REACT_APP_API_ENDPOINT ===
      "https://api.staging.investwithaqua.com"
        ? "9c4a35463ca1aaffef6e10f29104b2b4"
        : process.env.REACT_APP_API_ENDPOINT ===
          "https://api.investwithaqua.com"
        ? "78ef255f844acfc6eb47c48e71e28bb2"
        : "ee92b78dc5a4c9124758eb38095d1e82",
    testMode: true,
    element: document.getElementById("dropbox-sign-client"),
    skipDomainVerification: true,
  });

  client.on("sign", (data) => {
    //Poll results should go here
    setSignatureLoading(true);
    setShowSignatureModal(true);

    // setRefresh((refresh) => refresh + 1);
  });

  const [pollSubDocsResponse, setPollSubDocsResponse] = useState("");
  const [pollSubDocsSignatureResponse, setPollSubDocsSignatureResponse] =
    useState("");

  const pollSubDocsEndpoint = async (attempts) => {
    return await getSubdocLink(accountID, fundSubscriptionInfo.id, attempts);
  };

  const pollSignatureEndpoint = async (attempts) => {
    return await getSubdocSignature(
      accountID,
      fundSubscriptionInfo.id,
      attempts
    );
  };

  const runPolling = () => {
    try {
      const response = pollDocs({
        fn: pollSubDocsEndpoint,
        interval: 3000,
        maxAttempts: 20,
        setResultState: setPollSubDocsResponse,
      });
    } catch (error) {
      console.log("Run polling failed.");
      console.log("Error is: " + error);
    }
  };

  const runSignaturePolling = () => {
    try {
      const signatureResponse = pollSignatureCompletion({
        fn: pollSignatureEndpoint,
        interval: 1000,
        maxAttempts: 10,
        setResultState: setPollSubDocsSignatureResponse,
      });
    } catch (error) {
      console.log("Run signature polling failed.");
      console.log("Error is: " + JSON.stringify(error));
    }
  };

  useEffect(() => {
    //runPolling();
  }, []);

  useEffect(() => {
    if (
      pollSubDocsResponse !== "PARTIAL" &&
      pollSubDocsResponse !== "" &&
      pollSubDocsResponse !== "No subdoc exists." &&
      pollSubDocsResponse !== "Enqueued" &&
      pollSubDocsResponse !== "MR" &&
      pollSubDocsResponse !== "E" &&
      pollSubDocsResponse !== "Waiting"
    ) {
      console.log("The inner condition is hit.");
      setLoading(false);
      setShowSigButton(true);
      /*client.open(pollSubDocsResponse, {
        testMode: true,
        clientId:
          process.env.REACT_APP_API_ENDPOINT ===
          "https://api.staging.investwithaqua.com"
            ? "9c4a35463ca1aaffef6e10f29104b2b4"
            : "ee92b78dc5a4c9124758eb38095d1e82",
        container: document.getElementById("dropbox-sign-client"),
      });*/
    } else if (pollSubDocsResponse === "MR") {
      setLoading(false);
    } else if (pollSubDocsResponse === "E") {
      setLoading(false);
    } else if (pollSubDocsResponse === "PARTIAL") {
      setLoading(false);
    }
  }, [pollSubDocsResponse]);

  useEffect(() => {
    console.log(
      "Poll sub docs signature is: " +
        JSON.stringify(pollSubDocsSignatureResponse)
    );
    if (pollSubDocsSignatureResponse === "Signed") {
      setSignatureLoading(false);
      setRefresh((refresh) => refresh + 1);
      setShowSignatureModal(false);
    } else if (
      pollSubDocsSignatureResponse === "MR" ||
      pollSubDocsSignatureResponse === "E"
    ) {
      setSignatureLoading(false);
    }
  }, [pollSubDocsSignatureResponse]);

  return (
    <div className="container-fluid ps-4 pe-4 h-100">
      <div className="row g-0 w-100">
        <div className="col-12 ps-2">
          <div className="row g-0 w-100">
            <div className="col-lg-12 col-12 h-100 pt-0">
              <div className="col-12 justify-content-center">
                <div className="row g-0 w-100">
                  <div className="col-xxl-12 col-12 ps-0">
                    <div className="row gx-0 w-100 mb-2">
                      <span className="pt-4 pb-3 request-received-text">
                        View and Sign Subscription Documents
                      </span>
                    </div>
                    {loading && (
                      <div className="row g-0 w-100 mt-3 mb-3 justify-content-center">
                        <LoadingSpinnerAnimation />
                        <br />
                        <div className="row g-0 w-100 mt-3 mb-3 justify-content-center">
                          Generating subscription documents. This may take up to
                          30 seconds.
                        </div>
                      </div>
                    )}
                    {pollSubDocsResponse === "PARTIAL" && (
                      <>
                        Second signature required.{" "}
                        {summary.data[0].secondary_investor_profile.email}{" "}
                      </>
                    )}
                    {
                      showSigButton && (
                        <>
                          Subscription documents will be sent from noreply@mail.hellosign.com.
                        </>
                      )
                      /*(
                      <>
                        <a
                          href={
                            "/subdocs/" +
                            pollSubDocsResponse
                              .split("signature_id=")[1]
                              .split("&token=")[0] +
                            "/" +
                            pollSubDocsResponse
                              .split("signature_id=")[1]
                              .split("&token=")[1]
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            type="button"
                            class="btn btn-lg btn-class justify-self-start me-md-5 me-3 "
                            onClick={async () => {}}
                          >
                            Sign subdocs
                          </button>
                        </a>
                      </>
                        )*/
                    }
                    {(pollSubDocsResponse === "MR" ||
                      pollSubDocsResponse === "E") && (
                      <ErrorRenderingSubscriptionDocumentsMessage />
                    )}
                    <div className="row g-0 w-100 mb-3 justify-content-center">
                      <div id="dropbox-sign-client" className="h-100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSignatureModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={() => setShowSignatureModal(false)}
        onEntered={() => runSignaturePolling()}
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <span className="modal-title-font">
              {pollSubDocsSignatureResponse === "" &&
              pollSubDocsSignatureResponse === "Pending"
                ? "Your Signature is being confirmed."
                : pollSubDocsResponse === "MR"
                ? "Sorry, it's taking longer than expected to confirm your signature."
                : pollSubDocsSignatureResponse === "E"
                ? "Sorry, there was an issue confirming your signature."
                : pollSubDocsSignatureResponse === "Signed"
                ? "Your signature was successfully confirmed."
                : pollSubDocsSignatureResponse === "Secondary required"
                ? "Waiting on secondary accountholder"
                : "Your Signature is being confirmed."}
            </span>
          </div>
        </Modal.Header>
        <div className="col-12">
          {signatureLoading === true ? (
            <div className="row g-0 w-100 mt-3 mb-3 justify-content-center">
              <LoadingSpinnerAnimation />
            </div>
          ) : (
            <React.Fragment>
              {pollSubDocsSignatureResponse === "MR" && (
                <ResponseTakingTooLongMessage
                  setShowSignatureModal={setShowSignatureModal}
                />
              )}
              {pollSubDocsSignatureResponse === "E" && (
                <ErrorConfirmingSignature
                  setShowSignatureModal={setShowSignatureModal}
                />
              )}
              {pollSubDocsSignatureResponse === "Signed" && (
                <>Wire instruction will be shared shortly through email.</>
              )}
            </React.Fragment>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default SubDocs;
