import React, { useState, useEffect } from "react";
import {IndividualQualificationSection} from "./IndividualQualificationSection/IndividualQualificationSection";
import TrustQualificationSection from "./TrustQualificationSection/TrustQualificationSection";
import EntityQualificationSection from "./EntityQualificationSection/EntityQualificationSection";
import "./QualificationSectionStyles/QualificationSectionStyles.scss";

function QualificationSection({
  navigation,
  accountTypeIndividual,
  accountTypeTrust,
  accountTypeEntity,
  accountTypeJoint,
  accountTypeRetirement,
  individual5mInvestments,
  setIndividual5mInvestments,
  individualNot5mInvestments,
  setIndividualNot5mInvestments,
  trustQualificationQ1,
  trustQualificationQ2,
  trustQualificationQ3,
  trustQualificationQ4,
  setTrustQualificationQ1,
  setTrustQualificationQ2,
  setTrustQualificationQ3,
  setTrustQualificationQ4,
  entityQualificationQ1,
  entityQualificationQ2,
  entityQualificationQ3,
  entityQualificationQ4,
  entityQualificationQ5,
  setEntityQualificationQ1,
  setEntityQualificationQ2,
  setEntityQualificationQ3,
  setEntityQualificationQ4,
  setEntityQualificationQ5,
  setQualificationDate,
}) {
  return accountTypeIndividual ? (
    <IndividualQualificationSection
      navigation={navigation}
      selection1={individual5mInvestments}
      setSelection1={setIndividual5mInvestments}
      selection2={individualNot5mInvestments}
      setSelection2={setIndividualNot5mInvestments}
      setQualificationDate={setQualificationDate}
    />
  ) : accountTypeTrust ? (
    <TrustQualificationSection
      navigation={navigation}
      trustQualificationQ1={trustQualificationQ1}
      trustQualificationQ2={trustQualificationQ2}
      trustQualificationQ3={trustQualificationQ3}
      trustQualificationQ4={trustQualificationQ4}
      setTrustQualificationQ1={setTrustQualificationQ1}
      setTrustQualificationQ2={setTrustQualificationQ2}
      setTrustQualificationQ3={setTrustQualificationQ3}
      setTrustQualificationQ4={setTrustQualificationQ4}
      setQualificationDate={setQualificationDate}
    />
  ) : accountTypeEntity ? (
    <EntityQualificationSection
      navigation={navigation}
      entityQualificationQ1={entityQualificationQ1}
      entityQualificationQ2={entityQualificationQ2}
      entityQualificationQ3={entityQualificationQ3}
      entityQualificationQ4={entityQualificationQ4}
      entityQualificationQ5={entityQualificationQ5}
      setEntityQualificationQ1={setEntityQualificationQ1}
      setEntityQualificationQ2={setEntityQualificationQ2}
      setEntityQualificationQ3={setEntityQualificationQ3}
      setEntityQualificationQ4={setEntityQualificationQ4}
      setEntityQualificationQ5={setEntityQualificationQ5}
      setQualificationDate={setQualificationDate}
    />
  ) : 
  accountTypeJoint ? (
    <IndividualQualificationSection
      navigation={navigation}
      selection1={individual5mInvestments}
      setSelection1={setIndividual5mInvestments}
      selection2={individualNot5mInvestments}
      setSelection2={setIndividualNot5mInvestments}
      setQualificationDate={setQualificationDate}
    />
  ) :  
  
  null;
}
export default QualificationSection;
