import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { QualifiedPurchaserModal } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import { QualifiedPurchaserLink } from "../../../../../../SignupPage/QualificationSection/IndividualQualificationSection/IndividualQualificationSection";
import MuiButton from "../../../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import { getDateUTC } from "../../../../../../../../GlobalFunctions/globalFunctions";
import { useRecoilState } from "recoil";
import {
  qualificationDateVal,
  trustQpQ1,
  trustQpQ2,
  trustQpQ3,
  trustQpQ4,
} from "../../../../../../../../atoms";
import AlertSnackBar from "../../../../../../../../GlobalComponents/AlertSnackBar/AlertSnackBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function TrustQualification({ setActiveSlide }) {
  let history = useHistory();
  const [trustQualificationQ1, setTrustQualificationQ1] =
    useRecoilState(trustQpQ1);
  const [trustQualificationQ2, setTrustQualificationQ2] =
    useRecoilState(trustQpQ2);
  const [trustQualificationQ3, setTrustQualificationQ3] =
    useRecoilState(trustQpQ3);
  const [trustQualificationQ4, setTrustQualificationQ4] =
    useRecoilState(trustQpQ4);
  const [qualificationDate, setQualificationDate] =
    useRecoilState(qualificationDateVal);
  const [showModal, setShowModal] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [trustQualificationError, setTrustQualificationError] = useState("");
  const [q1Yes, setQ1Yes] = useState(
    trustQualificationQ1
      ? trustQualificationQ1 === "Yes"
        ? true
        : false
      : false
  );
  const [q1Empty, setQ1Empty] = useState(trustQualificationQ1 ? false : true);
  const [q1No, setQ1No] = useState(
    trustQualificationQ1
      ? trustQualificationQ1 === "Yes"
        ? false
        : true
      : false
  );
  const [q2Yes, setQ2Yes] = useState(
    trustQualificationQ2
      ? trustQualificationQ2 === "Yes"
        ? true
        : false
      : false
  );
  const [q2Empty, setQ2Empty] = useState(trustQualificationQ2 ? false : true);
  const [q2No, setQ2No] = useState(
    trustQualificationQ2
      ? trustQualificationQ2 === "Yes"
        ? false
        : true
      : false
  );
  const [q3Yes, setQ3Yes] = useState(
    trustQualificationQ3
      ? trustQualificationQ3 === "Yes"
        ? true
        : false
      : false
  );
  const [q3Empty, setQ3Empty] = useState(trustQualificationQ3 ? false : true);
  const [q3No, setQ3No] = useState(
    trustQualificationQ3
      ? trustQualificationQ3 === "Yes"
        ? false
        : true
      : false
  );
  const [q4Yes, setQ4Yes] = useState(
    trustQualificationQ4
      ? trustQualificationQ4 === "Yes"
        ? true
        : false
      : false
  );
  const [q4Empty, setQ4Empty] = useState(trustQualificationQ4 ? false : true);
  const [q4No, setQ4No] = useState(
    trustQualificationQ4
      ? trustQualificationQ4 === "Yes"
        ? false
        : true
      : false
  );

  const [notAllQuestionsAnsweredError, setNotAllQuestionsAnsweredError] =
    useState(false);
  const [notQPError, setNotQPError] = useState(false);

  useEffect(() => {
    setQ1Yes(
      trustQualificationQ1
        ? trustQualificationQ1 === "Yes"
          ? true
          : false
        : false
    );
    setQ1No(
      trustQualificationQ1
        ? trustQualificationQ1 === "Yes"
          ? false
          : true
        : false
    );
    setQ1Empty(trustQualificationQ1 ? false : true);
    setTrustQualificationError("");
  }, [trustQualificationQ1]);

  useEffect(() => {
    setQ2Yes(
      trustQualificationQ2
        ? trustQualificationQ2 === "Yes"
          ? true
          : false
        : false
    );
    setQ2No(
      trustQualificationQ2
        ? trustQualificationQ2 === "Yes"
          ? false
          : true
        : false
    );
    setQ2Empty(trustQualificationQ2 ? false : true);
    setTrustQualificationError("");
  }, [trustQualificationQ2]);

  useEffect(() => {
    setQ3Yes(
      trustQualificationQ3
        ? trustQualificationQ3 === "Yes"
          ? true
          : false
        : false
    );
    setQ3No(
      trustQualificationQ3
        ? trustQualificationQ3 === "Yes"
          ? false
          : true
        : false
    );
    setQ3Empty(trustQualificationQ3 ? false : true);
    setTrustQualificationError("");
  }, [trustQualificationQ3]);
  useEffect(() => {
    setQ4Yes(
      trustQualificationQ4
        ? trustQualificationQ4 === "Yes"
          ? true
          : false
        : false
    );
    setQ4No(
      trustQualificationQ4
        ? trustQualificationQ4 === "Yes"
          ? false
          : true
        : false
    );
    setQ4Empty(trustQualificationQ4 ? false : true);
    setTrustQualificationError("");
  }, [trustQualificationQ4]);

  const validateTrustQPStatus = () => {
    let isQp = false;

    if (
      trustQualificationQ1 !== "" &&
      trustQualificationQ2 !== "" &&
      trustQualificationQ3 !== "" &&
      trustQualificationQ4 !== ""
    ) {
      if (q1Yes && q2Yes) {
        isQp = true;
        var utcDate = getDateUTC();
        setQualificationDate(utcDate.toISOString());
        // navigation.next();
        setActiveSlide((curSlide) => curSlide + 1);
      } else if (q3No && q4Yes) {
        isQp = true;
        var utcDate = getDateUTC();
        setQualificationDate(utcDate.toISOString());
        // navigation.next();
        setActiveSlide((curSlide) => curSlide + 1);
      } else {
        isQp = false;
        setTrustQualificationError(
          "Sorry, your trust doesn't qualify as a Qualified Purchaser. Unfortunately, our opportunities are only open to qualified purchasers at this time, but we'll have investment opportunities for you very soon."
        );
        setNotQPError(true);
      }
    } else {
      setTrustQualificationError("Please answer all qualification questions.");
      setNotAllQuestionsAnsweredError(true);
    }
  };

  return (
    <React.Fragment>
      <QualifiedPurchaserModal show={showModal} setShowModal={setShowModal} />
      <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-5">
        <div className="row g-0 w-100 mt-2 h-100 align-items-center justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mb-4">
              <div className="col-12">
                <div onClick={() => setActiveSlide((curSlide) => curSlide - 1)}>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.64018 0.231804C6.21591 -0.12176 5.58534 -0.0644362 5.23178 0.359841L0.23598 6.3548C0.088756 6.52895 0 6.75412 0 7.00003C0 7.24592 0.0887513 7.47109 0.235968 7.64524L5.23178 13.6402L5.31807 13.7316C5.68113 14.0709 6.24854 14.0946 6.64018 13.7682L6.73156 13.682C7.07085 13.3189 7.09459 12.7515 6.76822 12.3598L3.13528 8.00002H15C15.5523 8.00002 16 7.55231 16 7.00003C16 6.44774 15.5523 6.00003 15 6.00003H3.13528L6.76822 1.64021L6.84254 1.53886C7.1108 1.12056 7.03182 0.558171 6.64018 0.231804Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="accredited-investor-questionnaire-section-header-text">
                  Trust Qualification
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="accredited-investor-questionnaire-gray-text">
                  The Securities and Exchange Commision (SEC) requires all Aqua
                  users to be Qualified Purchasers.
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Does the trust have at least $5M in investments?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField"}
                        id={"myRadioId"}
                        className={"radio__input"}
                        checked={q1Yes}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ1("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId2"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField2"}
                        id={"myRadioId2"}
                        className={"radio__input"}
                        checked={q1No}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ1("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Is the trust owned by two or more close family members
                  (spouses, siblings, descendants, and/or their respective
                  spouses)?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId3"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField3"}
                        id={"myRadioId3"}
                        className={"radio__input"}
                        checked={q2Yes}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ2("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId4"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField4"}
                        id={"myRadioId4"}
                        className={"radio__input"}
                        checked={q2No}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ2("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Was the trust formed for the specific purpose of investing in
                  a particular fund?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId5"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField5"}
                        id={"myRadioId5"}
                        className={"radio__input"}
                        checked={q3Yes}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ3("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId6"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField6"}
                        id={"myRadioId6"}
                        className={"radio__input"}
                        checked={q3No}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ3("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <span className="question-text">
                  Are the trustee(s) and persons granting assets to the trust
                  qualified purchasers (*Definition below)?
                </span>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col-6">
                    <label for={"myRadioId7"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField7"}
                        id={"myRadioId7"}
                        className={"radio__input"}
                        checked={q4Yes}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ4("Yes");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">Yes</span>
                    </label>
                  </div>
                  <div className="col-6">
                    <label for={"myRadioId8"} className={"radio"}>
                      <input
                        type={"radio"}
                        name={"myRadioField8"}
                        id={"myRadioId8"}
                        className={"radio__input"}
                        checked={q4No}
                        readOnly={true}
                        onClick={() => {
                          setTrustQualificationQ4("No");
                        }}
                      />
                      <div className="radio__radio"></div>
                      <span className="selection-text ms-3">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mt-4 mb-5 pe-3">
              <div className="col-11">
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className="d-flex">
                      <MuiButton
                        buttonText={"Next step"}
                        onClick={() => validateTrustQPStatus()}
                        size={"medium"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mt-5 pe-5">
              <div className="col-11">
                <QualifiedPurchaserLink setShowModal={setShowModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertSnackBar
        snackbarState={notQPError}
        setSnackbarState={setNotQPError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={
          "Sorry, your account does not qualify as a QP, and we only allow QPs on our platform at this time."
        }
      />
      <AlertSnackBar
        snackbarState={notAllQuestionsAnsweredError}
        setSnackbarState={setNotAllQuestionsAnsweredError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"You must answer all questions."}
      />
    </React.Fragment>
  );
}
export default TrustQualification;
