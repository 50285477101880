import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import "./ConfirmInvestmentAccountDropdownStyles/ConfirmInvestmentAccountDropdownStyles.scss";
import { UserContext } from "../../GlobalContexts/UserContext";
import { ConsoleLogger } from "@aws-amplify/core";

const StyledMenu = styled((props) => <Menu {...props} />)(({}) => ({
  "& .MuiPaper-root": {
    width: 350,
    borderRadius: 4,
    // top: "200px !important",
  },
}));

const AccountMenuItem = ({ account }) => (
  <>
    <div class="menu-item-text-name">
      {account.accountName}
      {account.primary && (
        <Avatar
          sx={{
            width: 10,
            height: 10,
            left: 4,
            paddingTop: "2px",
            fontSize: "7px",
            display: "inline-block",
            paddingLeft: "3px",
          }}
        >
          P
        </Avatar>
      )}
    </div>
    <div class="menu-item-subtitle" style={{ fontSize: "12px" }}>
      {account.accountNumberObscured} • {account.accountType}{" "}
    </div>
  </>
);

export default function ConfirmInvestmentAccountDropdown({ width, accounts }) {
  //   const [anchorElem, setAnchorElem] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [newAnchorElem, setNewAnchorElem] = useState(null);
  const handleClick = (event) => {
    setNewAnchorElem(event.currentTarget);
  };

  const handleClose = () => {
    setNewAnchorElem(false);
  };

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") console.log("Updated user is: " + JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (
      user &&
      user.selectedAccountID !== "" &&
      user.accountListData.length !== 0
    ) {
      user.accountListData.forEach((accountInfo, index) => {
        if (accountInfo.id === user.selectedAccountID) {
          setSelectedAccount(index);
        }
      });
    }
  }, [user]);

  const handleSelectedAccountUpdate = (selectedAccountID) => {
    setUser((user) => ({ ...user, selectedAccountID: selectedAccountID }));
  };

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") console.log("User is now:" + JSON.stringify(user));
  }, [user]);


  return (
    <>
      { (accounts.length > selectedAccount) && <>
      <div
        className="component-container d-flex justify-content-start"
        style={{ width: width + "px", cursor: "pointer" }}
        onClick={handleClick}
      >
        <div className="complete-steps-container d-flex w-100">
          {/* <div className="step-1-container bottom-border-only d-flex align-items-center">
            <span className="ms-2 complete-steps-text">
              <img
                src="/i.svg"
                style={{ height: "10px", paddingRight: "5px" }}
              />
              You are currently investing as:
            </span>
          </div> */}
          <div
            className="step-2-container align-items-center justify-content-start text-start d-flex"
            // style={{ width: width * 0.85 + "px" }}
            style={{ paddingTop: "0px", width: width + "px" }}
          >
            <span className="ps-2 complete-steps-text justify-self-start w-100">
              Account:{" "}
              <b>
                {accounts.length > selectedAccount
                  ? accounts[selectedAccount].accountName
                  : ""}
              </b>
            </span>
            <span
              className="justify-self-end ms-auto me-0 align-items-center"
              // style={{ marginLeft: "10px" }}
            >
              {newAnchorElem ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </span>
          </div>
        </div>
      </div>
      <StyledMenu
        width={width}
        anchorEl={newAnchorElem}
        open={newAnchorElem}
        onClose={handleClose}
      >
        {accounts.map((account, index) => (
          <MenuItem
            sx={{ height: "50px", fontFamily: "Montserrat", display: "block" }}
            onClick={() => {
              handleClose();
              setSelectedAccount(index);
              handleSelectedAccountUpdate(account.id);
            }}
            selected={index === selectedAccount}
            disableRipple
          >
            <AccountMenuItem account={account} />
          </MenuItem>
        ))}
      </StyledMenu> 
    </> } </>
  );
}
