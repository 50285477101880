import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import IndividualAccountBasicInformationButton from "./IndividualAccountBasicInformationButton/IndividualAccountBasicInformationButton";
import IndividualAccountBasicInformationQualificationSection from "./IndividualAccountBasicInformationQualificationSection/IndividualAccountBasicInformationQualificationSection";
import { getDateUTC } from "../../../../../../GlobalFunctions/globalFunctions.js";
import { newAccount, getAccountList } from "../../../../../../Api/ProfileApi";
import { UserContext } from "../../../../../../GlobalContexts/UserContext";
import SelectField from "../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import StyledTextInputField from "../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import StyledPhoneInputField from "../../../../../../GlobalComponents/InputField/StyledPhoneInputField/StyledPhoneInputField";

function IndividualAccountBasicInformation({
  countryOfFormation,
  setCountryOfFormation,
  processedCountryList,
  countryOfFormationError,
  setCountryOfFormationError,
  setAccountID,
  legalAccountName,
  setLegalAccountNameError,
  investorProfileComplete,
  shouldRenderIndividualQualificationSection,
  showIndividualQualificationSection,
  mountedStyle,
  unmountedStyle,
  accountType,
  individualQualificationQ1,
  setIndividualQualificationQ1,
  setActiveSlide,
}) {
  const { user, setUser } = useContext(UserContext);

  let history = useHistory();

  const [
    individualAccountBasicInformationError,
    setIndividualAccountBasicInformationError,
  ] = useState("");

  const jointList = [
    "Tenants in Common",
    "Tenants with Rights of Survivorship",
    "Community Property",
  ];

  const listOfJointTypes = jointList.map((s) => ({
    value: s.toLowerCase(),
    label: s,
  }));

  const [jointAccountType, setJointAccountType] = useState("");
  const [jointAccountTypeError, setJointAccountTypeError] = useState("");
  const [qualificationDate, setQualificationDate] = useState("");
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    if (individualQualificationQ1 !== "") {
      setIndividualAccountBasicInformationError("");
      if (individualQualificationQ1) {
        var utcDate = getDateUTC();
        setQualificationDate(utcDate);
      } else {
        setQualificationDate("");
      }
    }
  }, [individualQualificationQ1]);

  const submitEntityAccountBasicInformation = async () => {
    var errors = false;
    setSubmissionInProgress(true);

    if (legalAccountName === "") {
      errors = true;
      setLegalAccountNameError("This field is required."); }
    
    if (countryOfFormation === "") {
      setCountryOfFormationError("This field is required.")
    }



    if (!errors) {
      // Code to make request to the backend goes here
      const newAccountResp = await newAccount({
        account_type: "ENT",
        qualification_date: getDateUTC(),
        legal_name: legalAccountName,
        entity_type: "SM_LLC",
        country_of_formation: countryOfFormation,
        individual_q1_qp_5m_investments: individualQualificationQ1,
        qualification_date: qualificationDate,
      });
      setAccountID(newAccountResp.data.detail);
      const accountList = await getAccountList();
      setUser((user) => ({ ...user, accountListData: accountList.data }));

      setActiveSlide((prevState) => prevState + 1);
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  const submit = () => {
    accountType === "IND" && submitIndividualAccountBasicInformation();
    accountType === "JOINT" && submitJointAccountBasicInformation();
    accountType === "SM_LLC" && submitEntityAccountBasicInformation();
  };

  const submitJointAccountBasicInformation = async () => {
    var errors = false;
    setSubmissionInProgress(true);


    if (jointAccountType === "") {
      setJointAccountTypeError("Required.")
    }

    if (email === "") {
      setEmailError("Required.")
    }
  
    if (firstName === "") {
      setFirstNameError("Required.")
    }

      
    if (lastName === "") {
      setLastNameError("Required.")
    }
          
    if (phone === "") {
      setPhoneError("Required.")
    }



    if (individualQualificationQ1 === "") {
      errors = true;
      setIndividualAccountBasicInformationError("Please answer all questions.");
    } else if (!individualQualificationQ1) {
      errors = true;
      setIndividualAccountBasicInformationError(
        "Sorry, you do not qualify as a qualified purchaser. We only allow Qualified Purchasers on our platform at this time, so you will be unable to add this account."
      );
    }

    if (!errors) {
      //Code to make a request to the backend will go here.
      //May also need to track qualification date here.
      if (!investorProfileComplete) {
        const newAccountResp = await newAccount({
          account_type: accountType,
          joint_account_type: jointAccountType,
          individual_q1_qp_5m_investments: individualQualificationQ1,
          qualification_date: qualificationDate,
          first_name: firstName,
          last_name: lastName,
          phone: phone, 
          email: email,
        });
        setAccountID(newAccountResp.data.detail);
        const accountList = await getAccountList();
        setUser((user) => ({ ...user, accountListData: accountList.data }));

        if (
          user &&
          user.investorSummaryData &&
          user.investorSummaryData.address_complete &&
          user.investorSummaryData.pii_complete
        ) {
          setActiveSlide((prevState) => prevState + 1); // Fill in joint account info
        } else {
          setActiveSlide((prevState) => prevState + 1);
        }
      } else {
        history.push("/investment-accounts");
      }
      // setSubmissionInProgress(false);
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  const submitIndividualAccountBasicInformation = async () => {
    var errors = false;
    setSubmissionInProgress(true);

    if (individualQualificationQ1 === "") {
      errors = true;
      setIndividualAccountBasicInformationError("Please answer all questions.");
    } else if (!individualQualificationQ1) {
      errors = true;
      setIndividualAccountBasicInformationError(
        "Sorry, you do not qualify as a qualified purchaser. We only allow Qualified Purchasers on our platform at this time, so you will be unable to add this account."
      );
    }

    if (!errors) {
      //Code to make a request to the backend will go here.
      //May also need to track qualification date here.
      if (!investorProfileComplete) {
        const newAccountResp = await newAccount({
          account_type: accountType,
          individual_q1_qp_5m_investments: individualQualificationQ1,
          qualification_date: qualificationDate,
        });
        setAccountID(newAccountResp.data.detail);
        const accountList = await getAccountList();
        setUser((user) => ({ ...user, accountListData: accountList.data }));

        if (
          user &&
          user.investorSummaryData &&
          user.investorSummaryData.address_complete &&
          user.investorSummaryData.pii_complete
        ) {
          history.push("/investment-accounts");
        } else {
          setActiveSlide((prevState) => prevState + 1);
        }
      } else {
        history.push("/investment-accounts");
      }
      // setSubmissionInProgress(false);
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  return (
    shouldRenderIndividualQualificationSection && (
      <React.Fragment>
        {accountType === "SM_LLC" && (
          <div className="col-6 pe-2">
            <SelectField
              value={countryOfFormation}
              setValue={setCountryOfFormation}
              valueError={countryOfFormationError}
              setValueError={setCountryOfFormationError}
              selectOptions={processedCountryList}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"formation-country-select"}
              label={"Country of Formation"}
            />
          </div>
        )}
        {accountType === "JOINT" && (
          <>
            <div className="row g-0 w-100 mb-4">
              <div className="col pe-2">
                <StyledTextInputField
                  value={firstName}
                  setValue={setFirstName}
                  valueError={firstNameError}
                  setValueError={setFirstNameError}
                  label={"First Name"}
                  required={false}
                  fullWidth={true}
                  disabled={false}
                  size={"small"}
                  id={"individual-first-name-input"}
                  shrink={true}
                />
              </div>
              <div className="col ps-2 pe-0">
                <StyledTextInputField
                  value={lastName}
                  setValue={setLastName}
                  valueError={lastNameError}
                  setValueError={setLastNameError}
                  label={"Last Name"}
                  required={false}
                  fullWidth={true}
                  disabled={false}
                  size={"small"}
                  id={"individual-last-name-input"}
                  shrink={true}
                />
              </div>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="col ps-0 pe-0">
                <StyledTextInputField
                  value={email}
                  setValue={setEmail}
                  valueError={emailError}
                  setValueError={setEmailError}
                  label={"Email"}
                  required={false}
                  fullWidth={true}
                  disabled={false}
                  size={"small"}
                  id={"individual-last-name-input"}
                  shrink={true}
                />
              </div>
              <div className="col ps-2 pe-0">
                <SelectField
                  value={jointAccountType}
                  setValue={setJointAccountType}
                  valueError={jointAccountTypeError}
                  setValueError={setJointAccountTypeError}
                  selectOptions={listOfJointTypes}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  id={"joint-account-type-select"}
                  label={"Joint Account Type"}
                />
              </div>
            </div>{" "}
            <div className="row g-0 w-50 mb-4">
              <div className="col ps-0 pe-0">
              <StyledPhoneInputField
                  value={phone}
                  setValue={setPhone}
                  setValueError={setPhoneError}
                  valueError={phoneError}
                  label={"Phone Number"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  id={"account-name-input"}
                />
              </div>
            </div>
          </>
        )}
        <IndividualAccountBasicInformationQualificationSection
          showIndividualQualificationSection={
            showIndividualQualificationSection
          }
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          accountType={accountType}
          individualQualificationQ1={individualQualificationQ1}
          setIndividualQualificationQ1={setIndividualQualificationQ1}
          individualAccountBasicInformationError={
            individualAccountBasicInformationError
          }
        />
        <IndividualAccountBasicInformationButton
          accountType={accountType}
          loading={submissionInProgress}
          investorProfileComplete={
            user &&
            user.investorSummaryData &&
            user.investorSummaryData.address_complete &&
            user.investorSummaryData.pii_complete
          }
          showIndividualQualificationSection={
            showIndividualQualificationSection
          }
          mountedStyle={mountedStyle}
          unmountedStyle={unmountedStyle}
          onClick={() => submit()}
        />
      </React.Fragment>
    )
  );
}
export default IndividualAccountBasicInformation;
