import React, { useState, useEffect } from "react";
import EntityAccountLegalInformationTaxSection from "./EntityAccountLegalInformationTaxSection/EntityAccountLegalInformationTaxSection";
import EntityAccountLegalInformationAddressSection from "./EntityAccountLegalInformationAddressSection/EntityAccountLegalInformationAddressSection";
import EntityAccountLegalInformationButton from "./EntityAccountLegalInformationButton/EntityAccountLegalInformationButton";
import { updateEntityInformation } from "./../../../../../../Api/ProfileApi.js";

function EntityAccountLegalInformation({
  accountID,
  countryOfFormation,
  idTypeOptions,
  processedStateList,
  processedCountryList,
  setActiveSlide,
  componentAccountType,
}) {
  const [idType, setIDType] = useState("");
  const [idTypeError, setIDTypeError] = useState("");

  const [idNumber, setIDNumber] = useState("");
  const [idNumberError, setIDNumberError] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");

  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const submitEntityAccountLegalInformation = async () => {
    var errors = false;
    setSubmissionInProgress(true);

    if (idType === "" && countryOfFormation !== "United States" && componentAccountType !== "SM_LLC") {
      errors = true;
      setIDTypeError("Please select an id type.");
    }

    if (idNumber === "" && componentAccountType !== "SM_LLC") {
      errors = true;
      setIDNumberError("Please enter an id number");
    }

    if (addressLine1 === "") {
      errors = true;
      setAddressLine1Error("This field is required.");
    }

    if (city === "") {
      errors = true;
      setCityError("Please enter a city.");
    }

    if (country === "United States" && state === "") {
      errors = true;
      setStateError("Please select a state.");
    }

    if (country === "") {
      errors = true;
      setCountryError("Please select a country.");
    }

    if (postalCode === "") {
      errors = true;
      setPostalCodeError("Please enter a postal code.");
    }

    if (!errors) {
      //

      let addressInformation = {};
      addressInformation.address_line_1 = addressLine1;
      // if (addressLine2 !== "") {
      addressInformation.address_line_2 = addressLine2;
      // }
      addressInformation.city = city;
      // if (country === "United States") {
      addressInformation.state = state;
      // }
      addressInformation.country = country;
      addressInformation.postal_code = postalCode;

      addressInformation.address_type = "RM";
      addressInformation.address2_type = "";

      addressInformation.address2_line_1 = "";
      addressInformation.address2_line_2 = "";
      addressInformation.address2_city = "";
      addressInformation.address2_state = "";
      addressInformation.address2_country = "";
      addressInformation.address2_postal_code = "";

      addressInformation.id_type =
        idType === "EIN" ? "EIN" : idType === "ITIN" ? "ITIN" : "FTID";
      addressInformation.id_number = idNumber;
      addressInformation.entity_id = accountID;

      await updateEntityInformation(addressInformation);
      console.log(componentAccountType);
      setActiveSlide((prevState) => prevState + (componentAccountType !== "SM_LLC" ? 1 : 3));
    } else {
      setSubmissionInProgress(false);
      return;
    }
  };

  return (
    <div className="row g-0 w-100 account-legal-information-container">
      <div className="col-12 account-legal-information-outer-column-padded">
        {componentAccountType !== "SM_LLC" && <EntityAccountLegalInformationTaxSection
          countryOfFormation={countryOfFormation}
          idType={idType}
          setIDType={setIDType}
          idTypeError={idTypeError}
          setIDTypeError={setIDTypeError}
          idTypeOptions={idTypeOptions}
          idNumber={idNumber}
          setIDNumber={setIDNumber}
          idNumberError={idNumberError}
          setIDNumberError={setIDNumberError}
          componentAccountType={componentAccountType}
        /> }
        <EntityAccountLegalInformationAddressSection
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine1Error={addressLine1Error}
          setAddressLine1Error={setAddressLine1Error}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          addressLine2Error={addressLine2Error}
          setAddressLine2Error={setAddressLine2Error}
          city={city}
          setCity={setCity}
          cityError={cityError}
          setCityError={setCityError}
          state={state}
          setState={setState}
          stateError={stateError}
          setStateError={setStateError}
          processedStateList={processedStateList}
          country={country}
          setCountry={setCountry}
          countryError={countryError}
          setCountryError={setCountryError}
          processedCountryList={processedCountryList}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
          postalCodeError={postalCodeError}
          setPostalCodeError={setPostalCodeError}
        />
        <EntityAccountLegalInformationButton
          loading={submissionInProgress}
          onClick={() => submitEntityAccountLegalInformation()}
        />
      </div>
    </div>
  );
}
export default EntityAccountLegalInformation;
