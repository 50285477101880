import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  isEmailValid,
  isPasswordValid,
} from "../../../GlobalFunctions/globalFunctions.js";
import ProfileInputField from "../../../Components/GlobalComponents/ProfileInputFields/ProfileInputField.js";
import ProfilePasswordField from "../../../Components/GlobalComponents/ProfileInputFields/ProfilePasswordField/ProfilePasswordField.js";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import StateButtonLoading from "../../../Components/GlobalComponents/StateButtonLoading/StateButtonLoading.js";
import ReactInputVerificationCode from "react-input-verification-code";
import AlertSnackBar from "../../../GlobalComponents/AlertSnackBar/AlertSnackBar.js";
import "./ForgotPasswordPageStyles/ForgotPasswordPageStyles.scss";

function ForgotPasswordPage() {
  let history = useHistory();

  const firmName = "test";
  const [loading, setLoading] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [lastStep, setLastStep] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [confirmCodeError, setConfirmCodeError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [sendCodeError, setSendCodeError] = useState("");
  const [proceed, setProceed] = useState(false);
  const [confirmCodeLoading, setConfirmCodeLoading] = useState(false);

  const [resendCodeError, setResendCodeError] = useState(false);
  const [resendCodeSuccess, setResendCodeSuccess] = useState(false);
  const [resendCodeInProgress, setResendCodeInProgress] = useState(false);

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    setConfirmPasswordError("");
  }, [confirmPassword]);

  useEffect(() => {
    setConfirmCodeError("");
    if (confirmCode.length === 6) {
      setProceed(true);
    }
  }, [confirmCode]);

  const sendCode = async () => {
    var errorsDetected = false;

    if (email.length === 0) {
      setEmailError("This field is required.");
      errorsDetected = true;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email.");
      errorsDetected = true;
    }

    if (errorsDetected) {
      return;
    } else {
      try {
        await Auth.forgotPassword(
          email
          /*{ firm: firmName }*/
        );
        setNextStep(true);
      } catch (err) {
        setSendCodeError("There was an error sending code. Please try again.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
  };

  const resendConfirmationCode = async () => {
    setResendCodeInProgress(true);
    try {
      setResendCodeInProgress(false);
      await Auth.forgotPassword(
        email
        /*{ firm: firmName }*/
      );
      setResendCodeSuccess(true);
    } catch (err) {
      setResendCodeInProgress(false);
      console.log(err);
      setResendCodeError(true);
    }
  };

  const resetPassword = async () => {
    var resetPasswordErrorDetected = false;

    if (password.length === 0) {
      setPasswordError("This field is required.");
      resetPasswordErrorDetected = true;
    } else if (!isPasswordValid(password)) {
      setPasswordError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character."
      );
      resetPasswordErrorDetected = true;
    }
    if (confirmPassword.length === 0) {
      setConfirmPasswordError("This field is required.");
      resetPasswordErrorDetected = true;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Your confirm password does not match.");
      resetPasswordErrorDetected = true;
    }

    if (resetPasswordErrorDetected) {
      return;
    } else {
      setLastStep(true);
    }
  };

  const submitPasswordUpdate = async () => {
    var confirmationCodeErrorDetected = false;

    if (confirmCode.length !== 6) {
      setConfirmCodeError("Please enter a valid confirmation code.");
    }
    if (confirmationCodeErrorDetected) {
      return;
    } else {
      setConfirmCodeLoading(true);
      try {
        console.log(confirmCode);
        await Auth.forgotPasswordSubmit(
          email,
          confirmCode,
          password
          /*{ firm: firmName },*/
        );
        setConfirmCodeLoading(false);
        history.push({
          pathname: "/login",
          state: { passwordResetSuccessful: true },
        });
      } catch (err) {
        setConfirmCodeLoading(false);
        if (err.code === "CodeMismatchException")
          setResetPasswordError("Incorrect verification code.");
        else setResetPasswordError("Reset password failed. Please try again.");
        if (process.env.REACT_APP_DEBUG === "1") console.error(err);
      }
    }
  };

  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-hidden fillScreen align-items-center justify-content-center d-flex">
      <div className="row g-0 w-100 align-items-center justify-content-center">
        <div
          className="col-6 d-none d-sm-none d-md-none d-lg-block"
          style={{ backgroundColor: "#F0F4FD", zIndex: 1 }}
        >
          <img
            src="https://d1swyfveypj49y.cloudfront.net/Group-36.svg"
            style={{
              height: "100vh",
              backgroundSize: "cover !important",
              zIndex: 2,
            }}
            alt="community"
          ></img>
        </div>
        <div
          className="col-xxl-6 col-xl-6 col-lg-6 col-12"
          style={{
            zIndex: 3,
            backgroundColor: "#f7f7fa",
            height: "100vh",
          }}
        >
          <div className="container-fluid h-100 align-items-center g-0 ms-5 pe-3">
            <div className="row g-0 h-100 align-items-center w-100">
              <div className="col-11">
                <div className="row g-0 w-100 mb-2">
                  <div className="col-xxl-11 col-xl-11 col-lg-7 col-12">
                    <span className="forgot-password-header-text">
                      Forgot your password?
                    </span>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-3">
                  <div className="col-xxl-11 col-xl-11 col-lg-7 col-12">
                    <span className="forgot-password-subheader-text">
                      Don't worry, it happens to the best of us
                    </span>
                  </div>
                </div>
                {nextStep ? (
                  lastStep ? (
                    <React.Fragment>
                      <div className="row g-0 w-100 mb-1">
                        <span className="enter-message-text text-lg-start text-center">
                          Enter the 6 digit verification code that was sent to
                          your email
                        </span>
                      </div>
                      <div className="row g-0 w-100 mb-2">
                        <div className="col-11 text-lg-start text-center pe-3">
                          <span
                            className="enter-message-text text-center"
                            style={{ fontSize: "12px", fontWeight: 400 }}
                          >
                            *Please check your spam mail if the code does not
                            appear in your normal inbox.
                          </span>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-3 justify-content-lg-start justify-content-center">
                        <div className="col-10 d-flex justify-content-lg-start justify-content-center">
                          <div className="custom-styles shift-left d-flex justify-content-center">
                            <ReactInputVerificationCode
                              placeholder=""
                              length={6}
                              onChange={(value) => setConfirmCode(value)}
                            />
                            {confirmCodeError && (
                              <span className="email-field-error-text mt-1">
                                {confirmCodeError}
                              </span>
                            )}
                            {resetPasswordError && (
                              <span className="email-field-error-text mt-1">
                                {resetPasswordError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row g-0 w-100 mb-4">
                        <div className="col-11 text-lg-start text-center pe-3">
                          {resendCodeInProgress ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ color: "#0D47A1" }}
                            ></span>
                          ) : (
                            <span
                              className="enter-message-text text-center"
                              style={{ fontSize: "12px", fontWeight: 400 }}
                            >
                              Never received the confirmation code? Click to
                              <span
                                style={{ cursor: "pointer", color: "#0D47A1" }}
                                onClick={() => resendConfirmationCode()}
                              >
                                {" "}
                                resend code.
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row g-0 w-100">
                        <StateButtonLoading
                          loading={confirmCodeLoading}
                          proceed={proceed}
                          onClick={() => {
                            if (proceed) {
                              submitPasswordUpdate();
                            }
                          }}
                        />
                      </div>
                      <AlertSnackBar
                        snackbarState={resendCodeError}
                        setSnackbarState={setResendCodeError}
                        vertical={"top"}
                        horizontal={"right"}
                        duration={10000}
                        sxProps={{ paddingLeft: "3px" }}
                        severity={"error"}
                        message={
                          "There was an error sending a forgot password code to " +
                          email +
                          "."
                        }
                      />
                      <AlertSnackBar
                        snackbarState={resendCodeSuccess}
                        setSnackbarState={setResendCodeSuccess}
                        vertical={"top"}
                        horizontal={"right"}
                        duration={10000}
                        sxProps={{ paddingLeft: "3px" }}
                        severity={"success"}
                        message={
                          "Forgot password code sent successfully to " +
                          email +
                          "."
                        }
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="row g-0 w-100 mb-4">
                        <div className="col-11">
                          <div className="row g-0 w-100 mb-3">
                            <div className="col-12">
                              <span className="email-field-title-text align-self-start">
                                New Password
                              </span>
                              <div className="align-self-end mt-1">
                                <ProfilePasswordField
                                  placeholder={"New Password"}
                                  error={passwordError}
                                  fieldValueChange={setPassword}
                                  initialValue={password}
                                />
                                {passwordError &&
                                  passwordError !==
                                    "This field is required." && (
                                    <div className="email-field-error-text mt-1">
                                      <span className="">{passwordError}</span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="row g-0 w-100 mb-4">
                            <div className="col-12">
                              <span className="email-field-title-text align-self-start">
                                Confirm Password
                              </span>
                              <div className="align-self-end mt-1">
                                <ProfilePasswordField
                                  placeholder={"Confirm New Password"}
                                  error={confirmPasswordError}
                                  fieldValueChange={setConfirmPassword}
                                  initialValue={confirmPassword}
                                />
                                {confirmPasswordError &&
                                  confirmPasswordError !==
                                    "This field is required." && (
                                    <div className="email-field-error-text mt-1">
                                      <span className="">
                                        {confirmPasswordError}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="row g-0 w-100 mb-3">
                            <div className="d-flex">
                              <div className="justify-self-start align-self-center">
                                <button
                                  type="button"
                                  class="btn btn-lg btn-class"
                                  onClick={async () => resetPassword()}
                                >
                                  Next Step
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-11">
                        <span className="email-field-title-text align-self-start">
                          Email
                        </span>
                        <div className="align-self-end mt-1">
                          <ProfileInputField
                            placeholder={"example@investwithaqua.com"}
                            error={emailError}
                            fieldValueChange={setEmail}
                            initialValue={email}
                          />
                          {emailError &&
                            emailError !== "This field is required." && (
                              <span className="email-field-error-text mt-1">
                                {emailError}
                              </span>
                            )}
                          {sendCodeError &&
                            sendCodeError !== "This field is required." && (
                              <span className="email-field-error-text mt-1">
                                {sendCodeError}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 w-100">
                      <div className="col-xxl-11 col-xl-11 col-lg-7 col-12">
                        <div className="row g-0 w-100">
                          <div className="col ms-0 me-auto">
                            <div className="d-flex">
                              <button
                                type="button"
                                class="btn btn-lg btn-class"
                                onClick={() => sendCode()}
                                disabled={loading}
                              >
                                {loading && (
                                  <div className="row g-0 w-100">
                                    <div className="col d-flex align-items-center">
                                      <span className="justify-self-start align-self-center">
                                        Send Code
                                      </span>
                                      <span
                                        class="ms-2 spinner-border spinner-border-sm justify-self-end align-self-center"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </div>
                                  </div>
                                )}
                                {!loading && <span>Send Code</span>}
                              </button>
                            </div>
                          </div>
                          <div className="col-4 align-self-end text-end">
                            <Link to="/login">
                              <span className="signUpText">
                                <u>or Sign In</u>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPasswordPage;
