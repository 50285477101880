import React, { useState, useCallback } from "react";
import "./TabTitleStyles/TabTitleStyles.css";

function TabTitle({ title, setSelectedTab, index, setSelected, selected }) {
  const onClick = useCallback(() => {
    if (process.env.REACT_APP_DEBUG === "1") console.log("Button was clicked.");
    // setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <div className="row gx-0 w-100 h-100 align-items-center justify-content-center">
      <button
        className={"tab-text align-self-center pb-md-4 pb-2 mt-md-0 mt-2"}
        onClick={onClick}
      >
        {title}
      </button>
      <div
        className={
          selected
            ? "active-indicator align-self-end w-75"
            : "inactive-indicator align-self-end w-75"
        }
      ></div>
    </div>
  );
}
export default TabTitle;
