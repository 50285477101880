import React, { useEffect } from "react";
import StyledTextInputField from "../../../../../../../../GlobalComponents/InputField/StyledTextInputField/StyledTextInputField";
import StyledPhoneInputField from "../../../../../../../../GlobalComponents/InputField/StyledPhoneInputField/StyledPhoneInputField";
import SelectField from "../../../../../../../../GlobalComponents/InputField/SelectField/SelectField";
import StyledDateInputField from "../../../../../../../../GlobalComponents/InputField/StyledDateInputField/StyledDateInputField";
import { countryList } from "../../../../../../../../Components/countryList";
import { reducedCountryList } from "../../../../../../../../Components/reducedCountryList";
import { stateList } from "../../../../../../../../Components/GlobalComponents/stateList";
import { formatSSN } from "../../../../../../../../GlobalFunctions/globalFunctions";
import { getAccountSummary } from "./../../../../../../../../Api/ProfileApi";

function GrantorInformation({
  id,
  grantorFirstName,
  setGrantorFirstName,
  grantorFirstNameError,
  setGrantorFirstNameError,
  grantorLastName,
  setGrantorLastName,
  grantorLastNameError,
  setGrantorLastNameError,
  grantorEmail,
  setGrantorEmail,
  grantorEmailError,
  setGrantorEmailError,
  grantorPhoneNumber,
  setGrantorPhoneNumber,
  grantorPhoneNumberError,
  setGrantorPhoneNumberError,
  grantorDomicileCountry,
  setGrantorDomicileCountry,
  grantorDomicileCountryError,
  setGrantorDomicileCountryError,
  grantorAddressLine1,
  setGrantorAddressLine1,
  grantorAddressLine1Error,
  setGrantorAddressLine1Error,
  grantorAddressLine2,
  setGrantorAddressLine2,
  grantorAddressLine2Error,
  setGrantorAddressLine2Error,
  grantorState,
  setGrantorState,
  grantorStateError,
  setGrantorStateError,
  grantorCity,
  setGrantorCity,
  grantorCityError,
  setGrantorCityError,
  grantorCountry,
  setGrantorCountry,
  grantorCountryError,
  setGrantorCountryError,
  grantorPostalCode,
  setGrantorPostalCode,
  grantorPostalCodeError,
  setGrantorPostalCodeError,
  grantorDOB,
  setGrantorDOB,
  grantorDOBError,
  setGrantorDOBError,
  grantorIDNumber,
  setGrantorIDNumber,
  grantorIDNumberError,
  setGrantorIDNumberError,
  supplyPercentOwned,
  grantorCitizenship,
  setGrantorCitizenship,
  grantorCitizenshipError,
  setGrantorCitizenshipError,
  grantorPercentOwned,
  setGrantorPercentOwned,
  grantorPercentOwnedError,
  setGrantorPercentOwnedError,
  ac,
}) {
  const disabled = supplyPercentOwned;

  useEffect(() => {
    // console.log("User" + JSON.stringify(user));

    async function populateData() {
      const accountSummaryResp = await getAccountSummary(id);
      console.log(accountSummaryResp);
      const accountSummaryData = accountSummaryResp.data[0];
      let grantorData = {};
      if (accountSummaryData.related_parties.length !== 0) {
        //   console.log(
        //     JSON.stringify(user.userProfileData.accounts[0].related_parties)
        //   );
        accountSummaryData.related_parties.forEach((relatedParty) => {
          //   console.log("Related party" + JSON.stringify(relatedParty));
          if (relatedParty.role === "G") {
            grantorData = relatedParty;
            // console.log("grantor data " + JSON.stringify(grantorData));
          }
        });
      }
      //   let grantorDataAddress = [];
      if (
        Object.keys(grantorData).length !== 0 &&
        grantorData.addresses.length !== 0
      ) {
        if (grantorData.addresses.length === 1) {
          setGrantorAddressLine1(grantorData.addresses[0].address_line_1);
          setGrantorAddressLine2(grantorData.addresses[0].address_line_2);
          setGrantorCity(grantorData.addresses[0].city);
          setGrantorState(grantorData.addresses[0].state);
          setGrantorCountry(grantorData.addresses[0].country);
          setGrantorPostalCode(grantorData.addresses[0].postal_code);
          //   setGrantorPrimaryAddressDeliverable()
        } else {
          // setGrantorPrimaryAddressDeliverable("No");
          grantorData.addresses.forEach((address) => {
            if (address.address_type === "R" || address.address_type === "RM") {
              setGrantorAddressLine1(address.address_line_1);
              setGrantorAddressLine2(address.address_line_2);
              setGrantorCity(address.city);
              setGrantorState(address.state);
              setGrantorCountry(address.country);
              setGrantorPostalCode(address.postal_code);
            }
          });
        }
      }

      if (Object.keys(grantorData).length !== 0) {
        if (grantorData.pii.length !== 0) {
          setGrantorDOB(grantorData.pii[0].date_of_birth);
          setGrantorIDNumber(grantorData.pii[0].id_number);
        }
        setGrantorFirstName(grantorData.first_name);
        setGrantorLastName(grantorData.last_name);
        setGrantorEmail(grantorData.email);
        setGrantorPhoneNumber(grantorData.phone_number);
        setGrantorDomicileCountry(grantorData.domicile_country);
      }
    }
    populateData();
  }, []);

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">Grantor Information</span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <StyledTextInputField
            value={grantorFirstName}
            setValue={setGrantorFirstName}
            valueError={grantorFirstNameError}
            setValueError={setGrantorFirstNameError}
            label={"First Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-first-name-input"}
            disabled={supplyPercentOwned}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorLastName}
            setValue={setGrantorLastName}
            valueError={grantorLastNameError}
            setValueError={setGrantorLastNameError}
            label={"Last Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-last-name-input"}
            disabled={supplyPercentOwned}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <StyledTextInputField
            value={grantorEmail}
            setValue={setGrantorEmail}
            valueError={grantorEmailError}
            setValueError={setGrantorEmailError}
            label={"Email"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-email-input"}
            disabled={supplyPercentOwned}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledPhoneInputField
            value={grantorPhoneNumber}
            setValue={setGrantorPhoneNumber}
            setValueError={setGrantorPhoneNumberError}
            valueError={grantorPhoneNumberError}
            label={"Phone Number"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-phone-number-input"}
            disabled={supplyPercentOwned}
          />
          {grantorPhoneNumberError && (
            <span
              className="mt-1 mb-1"
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: "11px",
                color: "#D32F2F",
              }}
            >
              {grantorPhoneNumberError}
            </span>
          )}
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-0">
          <SelectField
            value={grantorDomicileCountry}
            setValue={setGrantorDomicileCountry}
            valueError={grantorDomicileCountryError}
            setValueError={setGrantorDomicileCountryError}
            label={"Domicile Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-domicile-country-input"}
            disabled={supplyPercentOwned}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">Mailing Address</span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <StyledTextInputField
            value={grantorAddressLine1}
            setValue={setGrantorAddressLine1}
            valueError={grantorAddressLine1Error}
            setValueError={setGrantorAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-address-line-1-input"}
            disabled={supplyPercentOwned}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorAddressLine2}
            setValue={setGrantorAddressLine2}
            valueError={grantorAddressLine2Error}
            setValueError={setGrantorAddressLine2Error}
            label={"Address Line 2"}
            required={false}
            fullWidth={true}
            size={"small"}
            id={"grantor-address-line-2-input"}
            disabled={supplyPercentOwned}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div
          className={
            grantorCountry === "United States" ? "col pe-2" : "col pe-0"
          }
        >
          <StyledTextInputField
            value={grantorCity}
            setValue={setGrantorCity}
            valueError={grantorCityError}
            setValueError={setGrantorCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-city-input"}
            disabled={supplyPercentOwned}
          />
        </div>
        {grantorCountry === "United States" && (
          <div className="col ps-2 pe-0">
            <SelectField
              value={grantorState}
              setValue={setGrantorState}
              valueError={grantorStateError}
              setValueError={setGrantorStateError}
              selectOptions={stateList}
              label={"State"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"grantor-state-input"}
              disabled={supplyPercentOwned}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-2">
          <SelectField
            value={grantorCountry}
            setValue={setGrantorCountry}
            valueError={grantorCountryError}
            setValueError={setGrantorCountryError}
            label={"Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-address-country-input"}
            disabled={supplyPercentOwned}
          />
        </div>
        <div className="col ps-2 pe-0">
          <StyledTextInputField
            value={grantorPostalCode}
            setValue={setGrantorPostalCode}
            valueError={grantorPostalCodeError}
            setValueError={setGrantorPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"grantor-postal-code-input"}
            disabled={supplyPercentOwned}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <span className="add-account-step-subheader">
          Grantor Personal Information
        </span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <StyledDateInputField
            value={grantorDOB}
            setValue={setGrantorDOB}
            valueError={grantorDOBError}
            setValueError={setGrantorDOBError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"grantor-dob-input"}
            disabled={supplyPercentOwned}
          />
        </div>
        <div className="col ps-2 pe-0">
          {grantorDomicileCountry === "United States" && (
            <StyledTextInputField
              value={grantorIDNumber}
              setValue={setGrantorIDNumber}
              valueError={grantorIDNumberError}
              setValueError={setGrantorIDNumberError}
              label={"SSN"}
              required={true}
              disabled={supplyPercentOwned}
              fullWidth={true}
              size={"small"}
              mask={{
                maskFormatter: formatSSN,
                maskPattern: "999-99-9999",
              }}
              id={"grantor-ssn-input"}
            />
          )}
          {grantorDomicileCountry !== "United States" && (
            <StyledTextInputField
              value={grantorIDNumber}
              setValue={setGrantorIDNumber}
              valueError={grantorIDNumberError}
              setValueError={setGrantorIDNumberError}
              label={"Passport Number"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"grantor-passport-input"}
              disabled={supplyPercentOwned}
            />
          )}
        </div>
      </div>

      <div className="row g-0 w-100 mb-3">
        <div className="col-12">
          <span className="infinite-input-component-section-header">
            Additional Info
          </span>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <div className="align-self-end mt-1">
            <StyledTextInputField
              value={grantorPercentOwned}
              setValue={setGrantorPercentOwned}
              setValueError={setGrantorPercentOwnedError}
              valueError={grantorPercentOwnedError}
              label={"Percent Owned"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={supplyPercentOwned ? false : disabled}
              shrink={true}
              id={"postal-code-name-input"}
              mask={{
                maskPattern: "999",
                maskFormatter: (e) => (parseInt(e) <= 100 ? e : e.slice(0, 2)),
              }}
            />
          </div>
        </div>
        <div className="col pe-2">
          <div className="align-self-end mt-1">
            <SelectField
              value={grantorCitizenship}
              setValue={setGrantorCitizenship}
              valueError={grantorCitizenshipError}
              setValueError={setGrantorCitizenshipError}
              selectOptions={reducedCountryList}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={supplyPercentOwned ? false : disabled}
              shrink={true}
              id={"formation-country-select"}
              label={"Citizenship"}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default GrantorInformation;
