import React from "react";
import Header from "../../../Components/GlobalComponents/Header.js";
import "./MessagesStyles/MessagesStyles.css";

function Messages() {
	return (
		<React.Fragment>
			<Header activePage={"messages"} />
			<div className="container-xxl container-fluid pt-5 mt-5 ps-xxl-0 pe-xxl-0 ps-5 pe-5">
				<div className="row g-0 w-100">
					<div className="col-12">
						<div className="row g-0 w-100 mb-3">
							<span className="page-header">Messages</span>
						</div>
						<div className="row g-0 w-100">
							<div className="coming-soon-container h-100 align-self-center pb-5">
								<div className="row g-0 w-100 h-100 justify-content-center text-center">
									<div className="col">
										<div className="row d-flex align-items-center align-self-center justify-content-center text-center">
											<span className="coming-soon-text mt-5 h-25">
												Coming Soon
											</span>
											<span className="message mt-3 w-75">
												The messages functionality is
												currently being built out by our
												engineering team and will be
												released soon.
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
export default Messages;
