import { useState, useEffect } from "react";
import { getAccountSummary } from "../Api/ProfileApi";
import { useContext } from "react";
import { UserContext } from "../GlobalContexts/UserContext";

export default function GetIncompleteInvestmentAccountAdapter(
  investorProfileComplete,
  setInvestorProfileComplete,
  investmentAccountID,
  setLoading,
  accountType,
  setAccountType,
  activeSlide,
  setActiveSlide,
  legalAccountName,
  setLegalAccountName,
  domicileCountry,
  setDomicileCountry,
  countryOfFormation,
  setCountryOfFormation,
  accountID,
  setAccountID,
  setSupplyPercentOwned
) {
  const { user } = useContext(UserContext);

  console.log("Here");

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log("The user is: " + JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (investmentAccountID !== undefined) {
      console.log("Inside second use effect");
      async function getIncompleteInvestmentAccountInfo() {
        if (process.env.REACT_APP_DEBUG === "1") {
          console.log("Call occurred here.");
        }
        setLoading(true);
        try {
          const response = await getAccountSummary(investmentAccountID);
          if (process.env.REACT_APP_DEBUG === "1") {
            console.log(
              "Response in the get incomplete investment account adapter is: " +
                JSON.stringify(response.data)
            );
          }
          if (response.data[0].account_type === "IND") {
            setAccountType("IND");
            setAccountID(response.data[0].id);
            if (user !== null && user.investorSummaryData !== null) {
              setDomicileCountry(user.investorSummaryData.country);
            }
            setInvestorProfileComplete(
              response.data[0].investor_profile_complete
            );

            if (response.data[0].investor_profile_complete) {
              setActiveSlide(3);
            } else if (
              !response.data[0].investor_profile_complete &&
              (!response.data[0].investor_address_complete ||
                !response.data[0].investor_pii_complete)
            ) {
              setActiveSlide(1);
            }
          } else if (response.data[0].account_type === "JOINT") {
            setAccountType("JOINT");
            setAccountID(response.data[0].id);
            if (user !== null && user.investorSummaryData !== null) {
              setDomicileCountry(user.investorSummaryData.country);
            }
            setInvestorProfileComplete(
              response.data[0].investor_profile_complete
            );
            if (
              response.data[0].secondary_investor_profile.address_complete &&
              response.data[0].secondary_investor_profile.pii_complete
            ) {
              setActiveSlide(3);
            } else {
              setActiveSlide(1);
            }
          } else {
            console.log("Inside ent block");
            console.log(
              response.data[0].entities[0].type !== "SM_LLC",
              !response.data[0].entity_complete,
              response.data[0].entities.length !== 0,
              !response.data[0].entities[0].related_party_complete
            );
            if (response.data[0].entities.length !== 0) {
              setAccountType(response.data[0].entities[0].type);
              setLegalAccountName(response.data[0].entities[0].legal_name);
              setCountryOfFormation(
                response.data[0].entities[0].country_of_formation
              );
              setAccountID(response.data[0].id);
              if (response.data[0].entity_complete) {
                setActiveSlide(3);
              } else if (
                !response.data[0].entity_complete &&
                response.data[0].entities.length !== 0 &&
                !response.data[0].entities[0].address_complete
              ) {
                setActiveSlide(1);
              } else if (
                response.data[0].entities[0].type !== "SM_LLC" &&
                !response.data[0].entity_complete &&
                response.data[0].entities.length !== 0 &&
                !response.data[0].entities[0].related_party_complete
              ) {
                console.log("Inside related party block");
                if (
                  response.data[0].related_parties.some(
                    (e) =>
                      e.citizenship === "None" || e.percent_ownership === "None"
                  )
                ) {
                  setSupplyPercentOwned(true);
                }
                setSupplyPercentOwned(false);
                setActiveSlide(2);
                console.log("Active slide is set to 2");
              } else if (
                response.data[0].entities[0].type === "SM_LLC" &&
                !response.data[0].entity_complete &&
                response.data[0].entities.length !== 0 &&
                !response.data[0].entities[0].related_party_complete
              )
                setActiveSlide(4);
              else if (!response.data[0].entity_complete) {
                setActiveSlide(4);
              }
            }
          }

          setLoading(false);
          // console.log(
          //   "Function complete. Loading should be set to correct state now."
          // );
        } catch (err) {
          if (process.env.REACT_APP_DEBUG === "1") console.log(err);
        }
      }
      getIncompleteInvestmentAccountInfo();
    }
  }, [investmentAccountID]);

  return 0;
}
