import React, { useState, useCallback } from "react";
import "./VerticalTabTitleStyles/VerticalTabTitleStyles.css";

function VerticalTabTitle({
  title,
  setSelectedTab,
  index,
  setSelected,
  selected,
}) {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <div onClick={onClick}>
      <div
        className={
          selected
            ? "pt-2 pb-2 ps-3 pe-2 w-100 text-start fillContent-selected"
            : "pt-2 pb-2 ps-3 pe-2 w-100 text-start fillContent-unselected"
        }
        onClick={() => setSelected(true)}
      >
        <div className="section-label">{title}</div>
      </div>
    </div>
  );
}
export default VerticalTabTitle;
