import { useState, useEffect, useContext } from "react";
import { UserContext } from "../GlobalContexts/UserContext";
import { getAccountQualificationVerification } from "../Api/ConfirmInvestmentApi/ConfirmInvestmentApi.js";

export default function GetAccountQualificationVerification(
  refresh,
  setLoading
) {
  const [
    accountQualificationVerification,
    setAccountQualificationVerification,
  ] = useState({});

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === "1") {
      console.log(
        "User within the get account qualification verification is: " +
          JSON.stringify(user)
      );
    }
  }, [user]);

  useEffect(() => {
    async function getAccountQualificationVerificationInfo() {
      if (process.env.REACT_APP_DEBUG === "1") {
        console.log("Call occurred here.");
      }
      setLoading(true);
      try {
        const response = await getAccountQualificationVerification(
          user ? user.selectedAccountID : null
        );
        // console.log("Call within adapter is successful.");
        // console.log(
        //   "Data from within the adapter is: " + JSON.stringify(response.data)
        // );
        const accountQualificationVerificationData = response.data;
        setAccountQualificationVerification({
          id:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0].id
              : "",
          number:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0].number
              : "",
          entityComplete:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0].entity_complete
              : "",
          investorProfileComplete:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0]
                  .investor_profile_complete
              : "",
          accountType:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0].account_type
              : "",
          qualificationType:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0]
                  .account_qualifications[0].qualification_type
              : "",
          verificationStatus:
            accountQualificationVerificationData.length !== 0
              ? accountQualificationVerificationData[0].account_verifications[0]
                  .status
              : "",
        });
        setLoading(false);
        // console.log(
        //   "Function complete. Loading should be set to correct state now."
        // );
      } catch (err) {
        if (process.env.REACT_APP_DEBUG === "1") console.log(err);
      }
    }
    getAccountQualificationVerificationInfo();
  }, [refresh, user]);

  return { accountQualificationVerification };
}
