import React from "react";
import PhoneInvestmentCard from "./PhoneInvestmentCard/PhoneInvestmentCard";
import "./PhoneInvestmentGridStyles/PhoneInvestmentGridStyles.css";

function PhoneInvestmentGrid({fundInvestments}) {

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row g-0 w-100">
                    <div className="col-12">
                        {fundInvestments.length !== 0 && (fundInvestments !== null || fundInvestments !== undefined) && (
                            fundInvestments.map((investment, index) => {
                                return <PhoneInvestmentCard key={investment.id} investment={investment} />
                            })
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default PhoneInvestmentGrid;