import React, { useState } from "react";
import "./Styles/Styles.scss";
import CoverImage from "./CoverImage/CoverImage";
import SignupContent from "./SignupContent/SignupContent";

function FeederFundSignup() {
  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-hidden cover-background align-items-center justify-content-center d-flex">
      <div
        className="row g-0 w-100 align-items-center justify-content-center"
        style={{ overflowY: "auto" }}
      >
        <CoverImage />
        <SignupContent />
      </div>
    </div>
  );
}
export default FeederFundSignup;
