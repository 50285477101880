import React, { useRef, useState, useEffect, useContext } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./DeckSectionStyles/DeckSectionStyles.css";
import { UserContext } from "../../../../../GlobalContexts/UserContext";
import { useMediaQuery } from "react-responsive";
import GetFundDocs from "../../../../../Adapters/DocsSectionAdapter";
import DocsSection from "../DocsSection/DocsSection.js";

function PDFDocument({ fund, url }) {
  const [numPages, setNumPages] = useState(null);
  const [loadedDocument, setLoadedDocument] = useState(false);

  const hostName = window.location.hostname;

  useEffect(() => {
    console.log("Host name at this point is: " + hostName);
  }, []);

  return (
    <div>
      <Document
        onLoadSuccess={(PDFDocumentProxy) => {
          setNumPages(PDFDocumentProxy._pdfInfo.numPages);
          setLoadedDocument(true);
        }}
        file={!url ? fund.deckLink : url}
      >
        {" "}
        <PDFPage numPages={numPages} />{" "}
      </Document>
      {loadedDocument === true &&
        hostName !== null &&
        hostName !== undefined &&
        (hostName === "localhost" ||
          hostName === "localhost:3000" ||
          hostName === "accollective.investwithaqua.com" ||
          hostName === "abcollective.investwithaqua.com") && 
          ( fund && fund.link && fund.link !== 'laude_venture_i' ) && 
          (
          <div className="row g-0 mt-4 w-100 justify-content-center">
            <div
              className="align-items-center justify-content-center p-3"
              style={{
                "min-height": "40px",
                "background-color": "#F3F5F7",
                "border-radius": "8px",
                width: "fit-content",
              }}
            >
              <div className="d-flex">
                <img
                  src="https://d1swyfveypj49y.cloudfront.net/newInfo.svg"
                  style={{ marginRight: "10px" }}
                />
                <span>
                  You can find the PDF version of the deck above with clickable
                  hyperlinks in the docs section.
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

// We can move this to a folder containing common components
function ArrowButton({ direction, onClick }) {
  const DIRECTIONS = { left: "Left-Arrow.svg", right: "Right-Arrow.svg" };

  return (
    <img
      src={`/${DIRECTIONS[direction]}`}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    />
  );
}

function PDFPage({ numPages }) {
  const [page, setPage] = useState(1);
  const canvas = useRef();
  const { user } = useContext(UserContext);

  function onRenderSuccess() {
    if (canvas.current) {
      const context = canvas.current.getContext("2d");

      const { height } = canvas.current;

      context.globalAlpha = 0.5;
      context.textAlign = "left";
      context.font = height / 35 + "px sans-serif";

      // Watermark displaying full name and email
      context.fillStyle = "rgba(255, 255, 255, 0.5)";
      context.translate(0, -15);
      context.fillText(
        `${user.investorSummaryData.first_name} ${user.investorSummaryData.last_name} / ${user.id}`,
        0,
        height / 16
      );

      // context.translate(-2, -2);

      context.fillStyle = "rgba(0,0,0,0.5)";
      context.translate(0, 0);
      context.fillText(
        `${user.investorSummaryData.first_name} ${user.investorSummaryData.last_name} / ${user.id}`,
        0,
        height / 16
      );
      context.save();

      // Page numbering
      context.font = height / 40 + "px sans-serif";
      context.translate(0, (height * 9) / 10);
      context.fillText(`Page ${page} of ${numPages}`, 0, height / 16);
      context.fillStyle = "white";
      context.translate(-2, -2);
      context.fillText(`Page ${page} of ${numPages}`, 0, height / 16);
    }
  }

  return (
    <div className="row g-0 ms-4 me-4 mt-4">
      <div className="col text-center arrow-buttons">
        {page > 1 && (
          <ArrowButton
            direction="left"
            onClick={() => {
              setPage(page - 1);
            }}
          />
        )}
      </div>
      <div className="col d-flex align-items-center">
        <Page
          canvasRef={canvas}
          onRenderSuccess={onRenderSuccess}
          height={500}
          pageNumber={page}
        />
      </div>
      <div className="col text-center arrow-buttons">
        {page < numPages && (
          <ArrowButton
            direction="right"
            onClick={() => {
              setPage(page + 1);
            }}
          />
        )}
      </div>
    </div>
  );
}

function DeckSection({ fund }) {
  // Screens exceeding this width will be provided a signed URL to the deck pdf
  const isMobile = useMediaQuery({ query: `(max-width: 1280px)` });

  return (
    <div className="row g-0 ms-4 me-4 mt-4">
      <div className="col-12 docs-header-text">Deck Preview</div>
      <div className="container">
        <div className="row mt-2 gx-3 gy-2">
          {!isMobile ? (
            <PDFDocument fund={fund} />
          ) : (
            <DocsSection fund={fund} deckOnly={true} />
          )}
        </div>
      </div>
    </div>
  );
}
export { PDFDocument, PDFPage };
export default DeckSection;
