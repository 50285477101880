import React, { useState, useEffect } from "react";
import MobileTransactionSection from "../MobileTransactionSection";
import { formatInvestmentAmount } from "../../../../../GlobalFunctions/globalFunctions.js";
import "./MobileTransactionCardStyles/MobileTransactionCardStyles.css";

function MobileTransactionCard({ itemNum, transaction }) {
  const [transactionTypeDiv, setTransactionTypeDiv] = useState("");
  useEffect(() => {
    if (transaction && transaction.transactionType === "Deposit") {
      setTransactionTypeDiv("deposit-text");
    } else if (transaction && transaction.transactionType === "Disbursement") {
      setTransactionTypeDiv("disbursement-text");
    }
  }, [transaction]);
  return (
    <div className="mobile-transaction-card mb-2 pt-3 ps-3 pe-3 pb-3">
      <div className="col-12">
        <div className="row g-0 w-100 mb-2">
          <div className="col">
            <div className="d-flex">
              <span className="justify-self-start transaction-id me-0">
                #{itemNum + 1}
              </span>
            </div>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Fund Name:</span>
          </div>
          <div className="col">
            <span className="transaction-section-value">
              {transaction.fundName}
            </span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Firm Name:</span>
          </div>
          <div className="col">
            <span className="transaction-section-value">
              {transaction.firmName}
            </span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Type:</span>
          </div>
          <div className="col">
            <span className="transaction-section-value">
              {transaction.transactionType}
            </span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Account:</span>
          </div>
          <div className="col">
            <span className="transaction-section-value">
              {transaction.transactionAquaAccount}
            </span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Status:</span>
          </div>
          <div className="col">
            <span className="transaction-section-value">
              {transaction.transactionStatus}
            </span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Method:</span>
          </div>
          <div className="col">
            <span className="transaction-section-value">
              {transaction.transactionMethod}
            </span>
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          <div className="col-md-2 col-sm-3 col-5">
            <span className="transaction-section-header">Amount:</span>
          </div>
          <div className="col">
            <span className={transactionTypeDiv}>
              {" "}
              {transactionTypeDiv !== ""
                ? transactionTypeDiv === "deposit-text"
                  ? "- " +
                    formatInvestmentAmount(transaction.transactionAmount + "")
                  : "+ " +
                    formatInvestmentAmount(transaction.transactionAmount + "")
                : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MobileTransactionCard;
